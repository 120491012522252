.prop-65-warning {
  margin: 20px 0 30px;
}

.warning-icon {
  padding-left: 40px;
  background-image: url("/assets/images/icons/attention-icon-cart-process.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 20px 0;
  min-height: 30px;

  .bigger {
    font-size: 24px;
    line-height: 1.3;
  }

  p {
    margin: 0;
  }
}
.warning-header{
  font-weight: bold;
  margin: 5px;
}
#item-warning-color{
  color: red;
  font-size: 12px;
  padding-top: 11px;
  padding-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

#item-warning-color-mobile{
  color: red;
  font-size: 12px;
  cursor: pointer;
  padding-top: 11px;
}
.item-warning-message{
  margin: 10px;
  .warning-close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

order-summary-list{
  md-dialog{
    min-width: 460px;
    max-width: 500px ;
  }
}
