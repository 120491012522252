hero {
  margin-top: 0;
  width: 100%;
}
@media (max-width: 959px) {
  hero {
    margin-top: -137px;
  }
}
.hero-fma {
  height: 668px;
  position: relative;
  overflow: hidden;

  &.hero-fma-area {
    &.homepageStyle {}
  }

  .hero-image {
    left: 0;
    position: absolute;
    right: 0;
    height: 668px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .hero-unit {
    position: relative;
    padding: 30px;
    color: #000000;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    max-width: 1140px;
    height: 668px;
    margin: 0 auto;

    h1 {
      font-size: 60px;
      font-weight: 300;
      line-height: 1.3;
      color: #333333;
      margin-top: 150px;
      line-height: 1;
    }

    h2 {
      font-size: 28px;
      font-weight: 300;
      line-height: 1.3;
      color: #333333;
    }
    h2.headertitle{
      font-size: 60px;
      font-weight: 300;
      line-height: 1.3;
      color: #333333;
      margin-top: 150px;
      line-height: 1;
    }
    .hero-copy{
      h1 {
        margin-bottom: 5px;
      }

      h2 {
        margin-top: 0;
      }
      h2.headertitle {
        margin-bottom: 5px;
        margin-top: 150px;
      }
    }

    .hero-buttons {
      margin-top: 50px;
    }
  }

  .hero-carousel {
    height: 668px;
    margin-top: 0;
  }
}

hero {
  display: block;
  height: 688px;
  overflow: hidden;
  position: relative;
}

hero .hero-button {
  display: inline;

  a.md-button {
    width: 133px;
  }
}

hero .dots-nav {
  text-align: center;
  position: absolute;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
  bottom: 50px;
}

hero .dots-nav .dots {
  padding-left: 0;
}

hero .dots li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: #999;
  border: 1px solid #fff;
  border-radius: 10px;
}

hero .dots li.active,
hero .dots li.hover {
  background-color: #000 \9;
  background-color: #444;
  border: 1px solid #fff;
}

hero .dots li:focus {
  outline: 0;
}

hero .scroll-arrow {
  background: url('/assets/images/scroll-arrow.png') no-repeat 50% 0;
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.hero-fma .hero-carousel li {
  opacity: 0 !important;
}

.hero-fma .hero-carousel li.active {
  opacity: 1 !important;
}

.hero-fma .slide.ng-hide {
  left: 0;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

.slide.ng-hide-add,
.slide.ng-hide-remove {
  -webkit-transition: all linear 0.5s;
  -moz-transition: all linear 0.5s;
  -o-transition: all linear 0.5s;
  transition: all linear 0.5s;
  display: block!important;
}

.slide.ng-hide-add.ng-hide-add-active,
.slide.ng-hide-remove {
  opacity: 0;
}

.slide.ng-hide-add,
.slide.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/*animations*/
.animate-enter,
.animate-leave {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

.animate-enter {
  left: -100%;
}

.animate-enter.animate-enter-active {
  left: 0;
}

.animate-leave {
  left: 0;
}

.animate-leave.animate-leave-active {
  left: 100%;
}
/*Carousel CSS*/
ul.hero-carousel > li {
  color: #000;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: visible;
  vertical-align: top;
  position: absolute;
  left: 0;
  right: 0;
  white-space: normal;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  -ms-touch-action: pan-y !important;
  touch-action: pan-y !important;
}

input[type=range] {
  width: 300px;
}
@media (max-width: 1390px) {
  hero {}

  .hero-fma {}
}
@media (max-width: 1279px) {
  .hero-fma {
    .hero-unit {
      h1 {
        font-size: 50px;
      }
      h2 {
        font-size: 20px;
      }
      h2.headertitle {
        font-size: 50px;
      }
    }
  }
}
/*for tablet and mobile device*/
@media (max-device-width: 1024px) {
  hero .dots li.hover {
    background-color: #000 \9;
    background-color: #999;
    border: 1px solid #fff;
  }

  hero .dots li.active {
    background-color: #000 \9;
    background-color: #444;
    border: 1px solid #fff;
  }

  .hero-fma .hero-unit h1, h2.headertitle {
    font-size: 36px;
  }
}
@media (max-width: 959px) {
  hero {
    height: 450px;
  }

  .hero-fma {
    height: 450px;

    .hero-carousel {
      height: 450px;
    }

    .hero-image {
      height: 450px;
      left: 0;
      right: 0;
    }

    .hero-unit {
      height: 450px;
      h1 {
        font-size: 24px;
        line-height: 1.3;
      }

      h2 {
        font-size: 14px;
      }
      h2.headertitle{
        font-size: 24px;
        line-height: 1.3;
      }
    }
  }

  .hero-fma .hero-image .image {
    text-align: center;
  }

  .hero-fma .hero-image .image img {
    width: auto;
  }

  .hero-fma {
    min-height: initial;
  }

  .hero-fma .hero-unit {
    padding: 30px;
  }

  hero .dots li {
    width: 20px;
    height: 20px;
    margin: 1px 10px;
    background-color: #000 \9;
    background-color: #999;
    border: 1px solid #fff;
  }

  hero .dots-nav {
    top: 90%;
  }

  hero .dots li.active {
    background-color: #000 \9;
    background-color: #444;
    border: 1px solid #fff;
  }
}
@media (max-width: 959px) and (orientation: landscape) {
  hero {
    height: 450px;
  }

  .hero-fma {
    height: 450px;

    .hero-carousel {
      height: 450px;
    }
  }
}
@media (max-width: 599px) {
  hero {
    height: 450px;
  }

  .hero-fma {
    height: 450px;

    .hero-carousel {
      height: 450px;
    }
  }

  hero .hero-button {
    a.md-button {
      width: 110px;
    }
  }
}
@media (max-width: 599px) and (orientation: landscape) {
  hero {
    height: 450px;
  }

  .hero-fma {
    height: 450px;

    .hero-carousel {
      height: 450px;
    }
  }

  hero .dots-nav {
    top: 90%;
  }
}
@media screen and (max-width: 767px){
  hero .dots-nav {
    left: 56%;
  }
}

@media screen and (max-width: 360px){
  hero .hero-button a.md-button {
    width: 90px;
  }
  .hero-fma .hero-unit h1 {
    margin-bottom: 5px;
  }
  .hero-fma .hero-unit h2 {
    margin: 5px 0;
  }
  .hero-fma .hero-unit h2.headertitle {
    margin-bottom: 5px;
  }
}
