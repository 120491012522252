.progress-linear {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #cbcbcb;
  background-clip: padding-box;
  overflow: hidden;

  /* We might use this later */
  //&.determinate {
  //  position: absolute;
  //  background-color: inherit;
  //  top: 0;
  //  bottom: 0;
  //  background-color: #76bd23;
  //  transition: width .3s linear;
  //}

  .indeterminate {
    background-color: #76bd23;
  }

  .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: indeterminate 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }

  .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: indeterminate-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 2.15s;
  }

  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
}
