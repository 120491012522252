.lowercase {
  text-transform: none;
}
.shipping-option-row{
  border: 1px solid #d0d0d0;
  border-radius: 15px;
  padding: 20px;
}
.selected{
  border: 2px solid #79c143;
  border-radius: 15px;
  margin-bottom: 20px;
}
.ship-via-text{
  display: block;
  text-transform: none;
  font-weight: 300;
  font-size: 13px;
}

delivery-options{
  md-radio-button .md-off{
    left: 10px;
  }
  md-radio-button .md-on{
    left: 10px;
  }
}
