@actions-tab-primary-color: #79c143;
@actions-tab-secondary-color: #4399c5;

.cart-checkout-wrapper {
  border-bottom: none !important;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.cart-item-list-wrapper {
  /* max-width: 796px;*/
}

.checkout-actions-wrapper {
  &.push-top {
    margin-top: 35px;
  }
  .mobile-express-checkout{
    md-progress-circular path, 
    md-progress-circular.md-default-theme path{
    stroke: #ffffff;
    }
  }
}

.checkout-actions-tab {
	position: fixed;
	bottom: 0;
  left: 0;
	background-color: #f6f6f6;//#333333;
	width: 100%;
	text-align: center;
	z-index: 10;
  border-top: 1px solid rgba(0,0,0,.08);

  &.replacement-actions-tab {
    z-index: 9; // one less than the fixed position tab
    border-top: none;
    bottom: auto;
    padding: 10px 0;

    ul li:first-of-type {
      margin-left: 0;
    }

    a, button {
      text-align: center;
    }
  }

	ul {
		display: inline-block;
    margin: 10px 0;
    padding-left: 0;
		li {
			list-style: none;
			float: left;
			margin: 0 10px;

      button[disabled],
      .disabled {
        color: @disabled-color-dark;
        background-color: @disabled-color-dark-btn-bg;
      }

			a {
				min-width: 200px;
				height: 40px;
				padding: 0 20px;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.4;
				display: table-cell;
				background-color: @actions-tab-primary-color;
				color: #ffffff;
				vertical-align: middle;
				border: solid 1px @actions-tab-primary-color;
				text-transform: uppercase;
				box-sizing: border-box;
        outline: none;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          border: 1px solid @disabled-color-btn-bg;
          color: @disabled-color-dark;
          background-color: @disabled-color-dark-btn-bg;
        }

				&.back-btn {
					background-color: #fff;//transparent;
					color: @actions-tab-primary-color;
				}
        &:hover {
          text-decoration: none;
        }
        &.md-secondary {
          border: solid 1px @actions-tab-secondary-color;
          background-color: @actions-tab-secondary-color;
        }
			}

		}
	}
}
.checkout-step-wrapper {
	/* margin-top: 40px;*/
}

.step-heading {
  //border-bottom: solid 1px #333333;
  margin: 20px 0;
}

@media only screen and (max-width: 959px)  {
  .checkout-step-wrapper {
      margin-top: 0;
      padding: 0;

      .step-wrapper {
        padding: 30px 15px 0 15px;
        box-sizing: border-box;
      }

      &.order-thankyou-page {
        .step-wrapper {
          padding: 30px 0 15px 0;
        }
      }
  }
  .checkout-actions-tab {

    ul {
      padding: 0;
      margin: 0;
      width: 100%;
      border: solid 1px #b7b7b7;
      box-sizing: border-box;
      li {
        width: 50%;
        margin: 0;
        padding: 10px;
        box-sizing: border-box;
        border-right: solid 1px #b7b7b7;

        &:last-child {
          border-right: 0;
        }
        a {
          min-width: 100%;
          width: 100%;
          display: block;
          padding: 10px;
          margin: 0;
          box-sizing: border-box;
        }
      }
    }
  }
  .co-op-banner-bottom, .promo-banner-side-col  {
     width: 100%;
  }
  .cart-item-list-wrapper {
    padding: 0;
    max-width: 100%;
  }

  .co-op-banner-bottom {
		img {
		 width: 90%;
		}
  }
}
.loading-wrapper {
  &.no-padding {
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width:360px)  {
  .checkout-actions-tab {
    ul {
      li {
        &:last-child {

        }
        a {
          font-size: 11px;
          padding: 13px 5px;
        }
      }
    }
  }
}
