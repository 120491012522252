.hero-global {
  margin-top: -135px;
  width: 100%;
  overflow:hidden;
  height : 400px;
  .hero-image{
    @media screen and (min-width: 959px) {
      width:100%;
    }
  }

  }

.hero-container {
  position:relative;
}

.hero-unit-global {
  position: relative;
  color: #333;
  h1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.3;
    line-height: 1;
    position: absolute;
    top: 30%;
    right: 0;
    width: 50%;
    text-align: center;

    span {
      font-size: 28px;
      font-weight: 300;
      line-height: 1.3;
    }

    @media screen and (max-width: 959px) {
      width: 90%;
      font-size: 24px;
      left: 0;
      margin: 0 auto;
    }
  }
  h2 {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.3;
    color: #333;
  }
}
.education-table-container {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  margin: 30px auto 0;

  p {
    color:#999;
  }
  .table-left {
    border-right: 1px solid #ddd;

    ul {
      padding: 0px;
      margin-right: 20px;

      li {
        border-bottom: 1px solid #ddd;
        padding: 10px;
      }
    }
  }

  .table-right {
    ul {
      padding: 0px;
      margin-left: 20px;
      li {
        border-bottom: 1px solid #ddd;
        padding: 10px;
      }
    }
  }

  ul {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    li {
      list-style: none;
      height: 70px;
      p {
        margin:0;
        font-size: 12px;
        font-weight: 400;
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
}

.flex-row{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.align-row(@position){
  -webkit-box-align:@position;
  -webkit-align-items:@position;
      -ms-flex-align:@position;
          align-items:@position;
}
.wrap-flex(@val){
  -webkit-flex-wrap:@val;
      -ms-flex-wrap:@val;
          flex-wrap:@val;
}
.position-row(@position){
  -webkit-box-pack:@position;
  -webkit-justify-content:@position;
      -ms-flex-pack:@position;
          justify-content:@position;
}
.elem-translate(@x, @y){
  -webkit-transform:translate(@x,@y);
      -ms-transform:translate(@x,@y);
          transform:translate(@x,@y);
}

.elem-box-sizing(@val){
  -webkit-box-sizing: @val;
          box-sizing: @val;
}
/* Common styling for level three pages */

.update-element-header-div{
  margin-top: 40px;
  padding-bottom: 40px;
}

.update-element-header-href{
  color: #5DADE2;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.update-element-hearder-content-div{
  padding: 40px 0 45px;
  width: 60%;
  margin: 0 auto;
  @media screen and (max-width: 959px) {
    width: 90%;
  }
}
.update-bottom-div{
  border-bottom: 2px solid #ddd;
  padding: 40px 0;
}

update-element-bottom-div{
  border-bottom: 3px solid #ddd;
  padding: 40px 0;
}

.updates-h2{
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #5DADE2;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.updates-h2::after {
  content: ">";
  color: #ddd;
  float: right;
  font-weight: 400;
}

.element-h2{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #333333;
  font-family: 'Roboto', sans-serif;
  background: #f5f5f5;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid #76bd23;
}

.clinical-update-sub-nav {
  width: 71%;
  margin: 0px auto 0;
  p {
    color: #666;
    text-align: justify;
    margin-top: 0px;
    font-family: 'Roboto', sans-serif;
    width: 90%;
    font-size: 18px;
    font-weight: 300;
  }
}

.experts-pagination {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
}
.experts-center {
  text-align: center;
}

.author-table {
  width: 100%;
  border-bottom: 1px solid #000;
  margin: 0 auto;
}

.author-th {
  border:none;
  text-align: left;
  padding: 20px 0;
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #626567;
}

.author-td {
  border:none;
  text-align: left;
  padding: 15px 40px 15px 0px;
  color : #626567;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  &:last-child{
  padding-right: 0px;
  }
  @media screen and (max-width: 959px) {
    padding: 15px 4px;
    font-size: 13px;
  }
}

.row-tr {
  border-bottom:1px solid #ddd;
}

.row-tr:hover {
  background-color: #f5f5f5;
}
.row-th {
  border-bottom: 1px solid #000;
}

.education-partners-img-href {
  width: 100%;
}

#resources-by-supplements {
  margin : 0 auto;
  md-tabs{
      min-height:1270px;
    }
    md-tabs-canvas{
      width:45%;
      margin: 0 auto;
    }
    md-tab-item{
      @media screen and (max-width: 599px) {
        width:100%;
      }
    }
  md-tab-item.md-tab {
    background-color: #f5f5f5;
    box-sizing: border-box;
    float: left;
    text-align: center;
    color: #999999;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    padding: 7px 24px;
    &.md-active {
      color: #fff;
      background-color: #4399c5;
    }
  }
}
html body md-toast[class*="md"]{
  position: fixed;
  top: 50%;
  bottom: auto;
  .elem-translate(0, -50%);
}

.account-toggle-btn {
  float: right;
  &.active { transform: rotate(-180deg); }
}

.account-toggle-btn-header {
  margin: auto;
}

.account-toggle-btn-a {
  margin: 30%;
}


._720kb-datepicker-calendar-day._720kb-datepicker-today {
  background:#0e98df;
  color:white;
}

datepicker a, [datepicker] a, .datepicker a{
  color:inherit;
  text-decoration:none;
}
datepicker a:hover, [datepicker] a:hover, .datepicker a:hover{
  text-decoration:none;
}
datepicker select, datepicker select:focus, datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover,
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover{
  width:100%;
   overflow: hidden;
   background:none;
   color:#fff;
   background-color: #138EFA;
   border-radius:2px;
   border: 0;
   margin-top:5px;
}
datepicker, .datepicker, [datepicker],
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-years-pagination-pages {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 13.5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  float: left;
  clear: right;
  position: relative;
}
._720kb-datepicker-calendar {
  background: white;
  color: #333;
  position: absolute;
  z-index: 999;
  min-width: 220px;
  margin: 0 auto;
  width: 101%;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  visibility: hidden;
  overflow:hidden;
  margin-left:-0.5%;
  padding: 0 0 2% 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
._720kb-datepicker-calendar._720kb-datepicker-open,._720kb-datepicker-calendar._720kb-datepicker-forced-to-open {
  visibility: visible;
}
._720kb-datepicker-calendar-header {
  text-align: center;
  font-size: 15px;
  line-height: 40px;
}
._720kb-datepicker-calendar-header:nth-child(odd) {
  background: #138EFA;
}
._720kb-datepicker-calendar-header:nth-child(even) {
  background: #7BC6FC;
}
._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
  width: 15%;
  float: left;
}
._720kb-datepicker-calendar-header-middle {
  width: 70%;
}

._720kb-datepicker-calendar-header-closed-pagination::after {
  content: " \25BE";
}

._720kb-datepicker-calendar-header-opened-pagination::after {
  content: " \25BE";
  margin-left: 4px;
  position: relative;
  bottom: -3px;
  display:inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
._720kb-datepicker-calendar-body {
  width: 96%;
  margin: 2%;
  text-align: center;
}
._720kb-datepicker-calendar-day {
  cursor: pointer;
  font-size: 12.5px;
  width: 12.2%;
  margin:5px 1%;
  padding: 1.5% 0;
  float: left;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
._720kb-datepicker-calendar-day:hover,
._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background: rgba(0, 0, 0, 0.03);
}
._720kb-datepicker-calendar-header a, ._720kb-datepicker-calendar-header a:hover {
  text-decoration:none;
  padding:3% 9% 4% 9%;
  font-size: 13.5px;
  color:rgba(0, 0, 0, 0.55);
  font-weight: bold;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
._720kb-datepicker-calendar-header a:hover {
  color:rgba(0, 0, 0, 0.9);
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-month {
  color:#fff;
}
._720kb-datepicker-calendar-month span {
  font-size: 13px;
  color:rgba(0, 0, 0, 0.4);
}
._720kb-datepicker-calendar-month a span  i {
  font-style: normal;
  font-size:15px;
}
._720kb-datepicker-calendar-month a, ._720kb-datepicker-calendar-month a:hover {
  padding: 3px;
  margin-left:1%;
}
._720kb-datepicker-calendar-years-pagination{
  padding:2% 0 0 0;
  float:left;
  clear: right;
  width: 100%;
}
._720kb-datepicker-calendar-years-pagination a, ._720kb-datepicker-calendar-years-pagination a:hover {
  font-size:12px;
  padding:0 7px;
  font-weight: normal;
  margin:3px 1% 0 1%;
  line-height: 20px;
  display: inline-block;
}
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
  color:rgba(0, 0, 0, 0.9);
  font-weight: 500;
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-years-pagination-pages a,._720kb-datepicker-calendar-years-pagination-pages a:hover{
  padding:5px 10px;
}
._720kb-datepicker-calendar-days-header{
  max-width: 100%;
  margin:0 auto;
  padding:0 2% 0 2%;
  background: rgba(19, 142, 250, 0.08);
  border-bottom:1px solid rgba(0,0,0,0.02);
}
._720kb-datepicker-calendar-days-header div{
  width: 14.18%;
  font-weight: 500;
  font-size: 11.5px;
  padding:10px 0;
  float:left;
  text-align: center;
  color:rgba(0,0,0,0.7);
}
._720kb-datepicker-calendar-days
._720kb-datepicker-default-button{
  font-size: 18.5px;
  position: relative;
  bottom:-0.5px;
}
._720kb-datepicker-default-button{
  padding:0 4.5px;
}
._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item{
  width:95%;
  float:none;
  margin:0 auto;
}
._720kb-datepicker-item-hidden{
  visibility:hidden;
}
._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover{
  color:rgba(0,0,0,0.2);
  background: rgba(25,2,0,0.02);
  cursor: default;
}
md-dialog.md-transition-in{
  -webkit-transform: translate3d(0,0,0) scale(1);
          transform: translate3d(0,0,0) scale(1);
}

.strikeOriginalShipPrice {
  text-decoration: line-through;
  font-style: normal;
}

.ee-free {
  display: block;
  font-style: normal;
}
[ng-messages] div[ng-message],
div.ccexpired-error {
    font-weight: bold;
    font-size: 14px;
}
