ee-autocomplete {
  width: 100%;

  .autocomplete {
    position: relative;
  }

  form {
    display: flex;
  }

  /* Get rid of IE "X" button on text box */
  #searchAutocomplete::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  input[type="search"] {
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    width: 100%;
    padding: 0 15px;
    line-height: 50px;
    height: 50px;
    max-width: 728px;
    &:focus {
      border: none;
    }
    &::placeholder {
      opacity: 0.85;
    }
  }

  .clear-search-btn {
    border: none;
    background: none;
    font-size: 30px;
    font-weight: 300;
    color: rgba(0, 0, 0, .87);

    &:focus {
      outline: 0;
    }
  }

  .progress-linear-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.dropdown-item-template {
  position: absolute;
  background: #fff;
  z-index: 10000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;

  &:focus {
    outline: 0;
  }

  ul.autocomplete-suggestions {
    padding: 0;
    margin: 0;
    font-size: 14px;

    li {
      list-style: none;
      line-height: 10px;
      margin: 0;
      padding: 0;
      cursor: pointer;
      &:last-of-type {
        padding-bottom: 10px;
      }

      .item-metadata {
        margin: 0;
        padding: 5px;
        padding-left: 10px;
        color: #693;
        line-height: 1;

        &:hover {
          background-color: #f5f5f5;
          .item-metastat .show-related-submenu {
            display: block;
          }
        }

        &:focus {
          outline: 0;
        }

        .item-metastat {
          padding: 0;
          margin: 0;

          a:hover {
            text-decoration: none;
          }

          div {
            color: #693;
            padding: 0;
            margin: 0;
            span.highlight {
              font-weight: 700;
            }
          }
        }
      }

      .show-list-search, .show-recent-search {
        .left {
          border-right: 1px solid #e6e6e6;
          overflow: hidden;
          padding: 0;
          margin: 0;
        }

        .title {
          margin: 5px;
          padding: 8px 5px 5px;
          color: #666;
          cursor: default;
        }
      }
    }
  }
}
