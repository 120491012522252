.info-section {
	margin-bottom: 20px;
}

/*credit card*/
md-dialog {
  credit-card-form {
    md-input-container {
      margin-right: 20px;
    }


  }
  &.ccForm {
  	.md-button.md-submit {
    	min-width: 200px;
    	display: block;
    	margin: 0 auto;
    }
    .mtn {
    	margin-top: 0 !important;
    }
    md-dialog-content {
      page-level-alert-messaging {
        display: none;
      }
    }
  }
}
.credit-card-expired {
  color: @warn-color;
}

.credit-cart-billing-address {
  border-bottom: dashed 1px #999999;
}

.cart-type-error{
  font-weight: bold;
  font-size: 14px;
  color: #cc3366;
}