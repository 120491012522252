.message-alert-wrapper {
  margin-top: 20px;
  .page-level-alert {
    .ngTruncateToggleText {
      color: #ccc;
      font-size: 90%;
      font-style: italic;
    }
  }
}
.empty-state{
  text-align: center;
}
#order-filter-contianer {
  .time-duration {
    margin: 25px 0 10px 0;
    li {
      display: inline-block;
      margin-bottom: 0;
      list-style: none;
      border-right: 1px solid #999999;

      &:last-child {
        border-right: 0;
      }
      a {
        padding: 0 10px;
        color: #4399c5;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          cursor: pointer;
        }
        &:active, &.selected {
          color: #000;
        }
      }
    }
  }
}
.track-order-wrapper {
  .tracking-numbers {
    display: none;
    bottom: 30px !important;
    &.open {
      display: block;
      bottom: 30px;
    }
  }
  &.md-open {
    .tracking-numbers {
      display: block;
      bottom: 30px;
    }
  }
  .tracking-numbers {
    position: relative;

    p {
      margin: 0 0 10px 0;
      font-size: 12px;
    }

    &:after {
      background-image: url(/assets/images/triangle.png);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
      content: '';
      width: 100%;
      height: 13px;
      position: absolute;
      bottom: -12px;
      background-position: top center;
      background-repeat: no-repeat;
      left: 0;
    }
  }
}
.main-content {
  .view-more-btn {
    &:before {
      background-image: url(/assets/images/blue-arrow-icon.png);
    }
    color : #669933;
  }
}
.prn {
  padding-right: 0 !important;
}

/*Track order*/
/*dropdown*/
.dropdownToggleMouseover {
  position: relative;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    bottom: 30px;
    left: -10%;
    background-color: #ffffff;
    text-align: left;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
    border: solid 1px #d7d7d7;
    padding: 20px;
    z-index: 1;

    .tracking-number {
      margin: 0;
      padding: 0;

      p {
        margin: 10px 15px;
        font-size: 12px;
        color: #333333;
      }
      li {
        margin: 10px 0;
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;

        a {
          font-size: 15px;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:after {
      background-image: url(/assets/images/triangle.png);
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
      content: '';
      width: 100%;
      height: 13px;
      position: absolute;
      bottom: -12px;
      background-position: top center;
      background-repeat: no-repeat;
      left: 0;
    }
  }
  &.dropdown-active {
    .dropdown-content {
      display: block;
    }
  }
}
#my-orders-history {
  tbody {
    tr {
      border-bottom: solid 1px #eeeeee;
      &.lg-screen {
        display: flex;
      }
      &.sm-screen {
        display: none;
      }

    }
  }
}


/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 959px){

  #my-orders-history {
    border-top: 1px solid #333;
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody tr {
      margin: 0;
      padding: 15px 0;
      &.lg-screen {
        display: none;
      }
      &.sm-screen {
        display: block;
      }
      td {
        border-bottom: 0;
        color: #333333;
        text-align: left;
        padding: 5px;
        &.transaction-number {
          font-size: 14px;
        	color: #000000;
          a.button {
            color: @primary-color;
          }
        }
        &.date {
          font-size: 14px;
        	// line-height: 2.0;
        	color: #999999;
        }
        &.sub-title-col {
          font-size: 12px;
        	line-height: 1;
        }
        &.order-by {
          font-size: 14px;
        	color: #4399c5;
          line-height: 1;
        }
        font-size: 14px;
        // line-height: 2.0;
        color: #999999;
        width:100%;
      }
      .left-col{
        text-align:left;
      }
      .right-col{
        text-align:left;
        padding-left:49%;

        .arrow{
          float: right;
        }
      }
    }
  }

  #order-filter-contianer {
    margin: 0 !important;
    .duration-wrapper {
      margin: 0 0 10px 0;
      padding: 0;
      text-align: right;
      md-select {
        border: none;
        color: @secondary-color;
        font-weight: 500;
        &.md-no-underline {
          background-color: transparent;
          border-color: #fff;
          margin: 15px 0 0 0;
          .md-select-value {
            border: solid 1px transparent;
            border-radius: 0;
            padding: 5px 0 0 0;
          }

        }
      }
    }
  }

  a.view-more-btn {
    font-size: 16px;
    position: relative;
    color: @primary-color;
    &:before {
      top: 2px;
      background-image: url(/assets/images/green-arrow-icon.png);
    }
  }

}

@media only screen and (max-width: 599px){
  #my-orders-history tbody tr {
    .right-col{
      padding-left:60px;
    }

    .order-value {
      margin-left: -10px;
    }

  }

}

@media only screen and (max-width: 399px){
  #my-orders-history tbody tr {
    td.date {
      a {
        margin-left: 5px;;
      }
    }

    .right-col{
      padding-left: 0;
      padding-right: 15px;
      text-align: right;
    }

    .order-value {
      margin-left: 0;
    }
  }
}

.invoices-link {
  margin-right: 10px;
}