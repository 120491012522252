.dropdown {
    float:left;
    display:block;
    width:250px;

    div {
        float:left;
        width:100%;

    &.dropdown-active {
      div.dropdown-display {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

        div.dropdown-display {
            float:left;
            width:100%;
            background:white;
            cursor:pointer;
            border:solid 1px #ccc;
            box-sizing:border-box;
            border-radius: 7px;
            -moz-border-radius: 7px;
            -webkit-border-radius: 7px;
            padding: 6px 11px;
            font-size: 16px;
            color: @secondary-color;
            position: relative;

            &:after {
              content: '›';
              position: absolute;
              top: 50%;
              right: 5px;
              height: 5px;
              width: 10px;
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -o-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
              font-size: 1.5rem;
            }
        }

        div.dropdown-list {
            float:left;
            width:100%;
            position:relative;
            width:100%;
            -webkit-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            transform:scale(1, 0);
            transition:transform ease 250ms;
            z-index:999;

            > div {
                position:absolute;
                width:100%;
                z-index:2;
                cursor:pointer;
                background:white;
                border:solid 1px #ccc;
                outline: none;
                box-sizing: border-box;

                div {
                    float:left;
                    width:100%;
                    padding:11px;
                    font-size:12px;
                    box-sizing:border-box;
                    border-top:none;
                    position:relative;
                    -webkit-box-shadow:none;
                    -moz-box-shadow:none;
                    box-shadow:none;
                    border-radius:0;
                    -moz-border-radius:0;
                    -webkit-border-radius:0;
                    outline: none;
                    font-weight: 300;

                    &:hover {
                        background:#f6f6f6;
                    }



                    span {
                        float:left;
                        width:100%;
                        position:relative;
                        padding-right:5px;
                        box-sizing:border-box;
                        color:@secondary-color;
                        font-size:16px;
                    }
                }
            }
        }
    }

    div.dropdown-active div.dropdown-list {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1);
    }

  &.small {
    min-width: 101px;
    width: auto;
    max-width: 150px;
    min-height: 22px;

    div {
      div.dropdown-display {
        font-size: 12px;
        padding: 3px 7px;

        &:after {
          right: 0;
        }

      }
      div.dropdown-list {
        > div {
          div {
            font-size: 12px;
            padding: 3px 7px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &.green {
    div {
      div.dropdown-display {
        color: @link-color-green;
        > div {
          div {
            span {
              color: @link-color-green;
            }
          }
        }
      }
      div.dropdown-list {
        > div {
          div {
            span {
              color: @link-color-green;
            }
          }
        }
      }
    }

  }
  &.search {
    // min-width: 101px;
    width: 100%;
    // max-width: 150px;
    height: 50px;

    div {
      div.dropdown-display {
        font-size: 16px;
        padding: 16px 25px;
        height: 50px;
        text-transform: uppercase;
        border-radius: 0;
        margin-top: 0;
        border-top: 0;
        border-right: 0;
        box-sizing: border-box;
        color: #ffffff;
        background-color: @primary-color;
        text-transform: uppercase;
        &:after {
          right: 0;
        }

      }
      div.dropdown-list {
        > div {
          color: #ffffff;
          background-color: @primary-color;
          div {
            font-size: 16px;
            padding: 16px 25px;
            color: @primary-color;
            text-transform: uppercase;
            &:hover {
              background: #cccccc;
            }
            span {
              font-size: 16px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  &.search-m {
    // min-width: 101px;
    width: 100%;
    // max-width: 150px;
    height: 50px;

    div {
      div.dropdown-display {
        font-size: 12px;
        padding: 14px 10px 10px 10px;
        height: 40px;
        text-transform: uppercase;
        border-radius: 0;
        margin-top: 0;
        border-top: 0;
        border-right: 0;
        box-sizing: border-box;
        color: #ffffff;
        background-color: @primary-color;
        text-transform: uppercase;
        text-align: left;
        margin-top: 5px;
        &:after {
          right: 0;
        }

      }
      div.dropdown-list {
        > div {
          color: #ffffff;
          background-color: @primary-color;
          text-align: left;
          div {
            font-size: 12px;
            padding: 14px 10px 10px 10px;
            color: @primary-color;
            text-transform: uppercase;
            &:hover {
              background: #cccccc;
            }
            span {
              font-size: 12px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
