#order-filter-contianer {
	margin: 15px 0;

	h2 {
    font-size: 25.5px;
		margin: 15px 0 10px 0;
	}
  .time-duration {
    margin: 25px 0 10px 0;
    li {
      display: inline-block;
      margin-bottom: 0;
      list-style: none;
      border-right: 1px solid #999999;

      &:last-child {
        border-right: 0;
      }
      a {
        padding: 0 10px;
        color: #4399c5;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          cursor: pointer;
        }
        &:active, &.selected {
          color: #000;
        }
      }
    }
  }
	md-input-container {
		margin: 18px 0 10px 0;
		height: 30px;
	}
	a {
		color: @secondary-color;
	}
}
.reorder-products-wrapper {
  .product-img {
    height: 85px;
  }
  .table {
    thead {
      font-size: 12px;
    	line-height: 4.08;
    	text-align: left;
    	color: #333333;
      .checkbox-all {
        padding-left: 4%;
      }
      tr {
        padding: 0 15px;
      }
      td {
        padding-bottom: 15px;
        &.total-quantity {
          padding-left: 2px;
        }
        a {
          font-weight: normal;
        }
      }
    }
    tbody {
      .product-warning-tr {
        border-bottom: 0 !important;
      }
      tr {
        padding: 0 15px;
        outline: none;
        border-bottom: 0;
        td {
          font-size: 15px;
        }
        p {
          margin: 0;
        }
        .hilight-row {
          background-color: #f6f6f6;
        }
        .checkbox-reorder {
          padding-left: 4%;
          .item-checkbox {
            display: table !important;
            height: 100%;
            vertical-align: middle;
            md-checkbox {
              display: table-cell;
            }
          }
        }
        .last-ordered {
          font-size: 15px;
        	letter-spacing: normal;
        	text-align: left;
        	color: #454553;
        }
        .total-quantity {
          padding-left: 10px;
        }
        .product-description {
          font-size: 16px;
          .order-name {
            font-weight: bold;
            line-height: 1.12;
            text-align: left;
            color: @link-color-green;
            a {
              color: @link-color-green;
            }
          }
          p.category-title {
            font-size: 14px;
            line-height: 1.14;
            text-align: left;
            font-weight: normal;
          }
        }

        .added-to-cart-success-msg {
          font-size: 14px;
          color: rgba(0,0,0,.67);
          position: absolute;
          top: 13px;
          left: 25%;

          md-icon {
            margin-left: 0;
            margin-right: 4px;
            color: #76bd23;
          }
        }

        .quantity-adjustment-wrapper {
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: right;
          position: relative;
          padding-right: 0;

          .quantity-adjustment {
            height: 34px;
            text-align: center;
            display: inline-block;
            box-sizing: border-box;
            margin: 0;
            overflow: hidden;
            button {
              font-size: 23px;
              font-weight: 300;
              text-align: center;
              color: #79c143;
              background-color: #ffffff;
              border: 1px solid #999999;
              box-sizing: border-box;
              float: left;
              &.decrement-button {
                font-size: 30px;
                line-height: 24px;
              }

              &.rectangle-quantity {
                width: 28px;
                height: 34px;
                box-sizing: border-box;
                &:focus{
                  outline: none;
                }
              }
            }

            .quantity {
              text-align: right;
              padding-right: 5px;
              width: 44px;
              height: 34px;
              border: 1px solid #999999;
              border-left: 0px;
              border-right: 0px;
              font-size: 16px;
            	font-weight: bold;
            	line-height: 1.0;
            	color: #454553;
              box-sizing: border-box;
              display: inline-block;
              float: left;
              -moz-appearance:textfield;
              &:focus{
                outline: none;
              }
              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
              }
            }
          }
        }
        &.product-warning {
          border-top: 0;
          border-bottom: 0;
          td {
            padding: 0;
          }
          td:nth-of-type(2) {
            border-bottom: 0;
          }
          td:nth-of-type(3) {
            border-top: 1px solid #999999;
          }

          &:last-child {
            border-bottom: 0;
          }
          .warning-content {
            padding: 10px 0;
            text-align: left;
            font-size: 14px;
          	line-height: 1.14;
          	text-align: left;
          	color: #333333;
            .warning-text {
              padding: 5px 0 0 10px;
              width: 100%;
              .warning-m-view {
                display: none;
              }
            }
          }
        }
        .info-m-view {
          display: none;
        }

        td {
          .add-to-cart-td-btn {
            margin-top: 0;
            font-weight: 300;
            letter-spacing: 0.5px;
            min-width: 120px;
            &.md-button {
              height: 34px;
              min-height: 34px;
            }
          }
        }
      }
    }
    &:last-child {
      tr {
        &:last-child {
          border-bottom: 1px solid #999999 !important;
        }
      }
    }
  }
  #product-itemcode {
    font-weight: 500;
    font-size: 15px;
    padding:4px 0 0 0;
  }
  #product-brandname {
    font-weight: 500;
    padding:16px 0 0 0;
  }
}

.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;

  &:before {
    background-image: url('/assets/images/view-more-arrow.png');
    background-repeat: no-repeat;
    content: '';
    width: 12px;
    height: 6px;
    position: absolute;
    left: 0;
  }
}
.time-period {
	li {
		display: inline-block;
		margin-bottom: 0;
    	list-style: none;
    	border-right: 1px solid #999999;

    	&:last-child {
    		border-right: 0;
    	}

    	a {
    		padding: 0 10px;
    	}
	}
}

@media (max-width: 959px) {
  .reorder-products-wrapper .mobile-product {
    padding: 10px 0;
    border-bottom: 1px solid rgba(0,0,0,.12);

    &:first-of-type {
      margin-top: 25px;
    }

    a {
      color: #669933;
    }

    h3 {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    div.image-wrapper {
      img {
        margin-left: -19px;
        height: 125px;
      }
    }

    div.data-info {
      font-weight: 500;
      font-size: 13px;
      color: rgba(0,0,0,.75);
      margin-bottom: 3px;

      &.last-ordered {
        font-weight: 700;
        font-size: 14px;
        padding-left: 10px;
        margin-bottom: 10px;
      }
    }

    .mobile-quantity-adjustment-wrapper {
      margin-top: 10px;

      .product-unit-price {
        font-size: 13px;
        margin-left: 15px;
        color: rgba(0,0,0,.75);
      }

      .quantity-adjustment {
        display: flex;
        align-items: center;

        button {
          font-size: 22px;
          font-weight: 300;
          text-align: center;
          color: #79c143;
          background-color: white;
          border: 1px solid #999;
        }

        button.rectangle-quantity {
          border-radius: 50%;
          width: 35px;
          min-width: 35px;
          height: 35px;
          border-color: rgba(0,0,0,.31);
        }

        input.quantity {
          height: 35px;
          width: 55px;
          padding: 0;
          text-align: center;
          font-size: 15px;
          margin: 0 5px;
          border: 1px solid rgba(0,0,0,.31);
          border-radius: 4px;
          font-weight: 500;
        }
      }

      .add-to-cart-wrapper {
        position: relative;
      }

      button.add-to-cart-td-btn {
        font-weight: 300;
        letter-spacing: 0.8px;
        border-radius: 4px;
      }

      .added-to-cart-success-msg {
        font-size: 13px;
        color: rgba(0,0,0,.67);
        position: absolute;
        top: 11px;
        left: 137px;

        md-icon {
          color: #76bd23;
        }
      }

      .added-to-cart-success-msg-with-alternatives {
        font-size: 13px;
        color: rgba(0,0,0,.67);
        position: absolute;
        top: -5px;
        left: 137px;

        md-icon {
          color: #76bd23;
        }
      }

      .discontinued-msg {
        h4 {
          font-size: 14px;
          margin: 5px 0;
          font-weight: normal;
          color: rgba(0,0,0,.54);
        }
      }
    }
  }
}

.hide-select-all-checkbox{
  visibility : hidden;
}

@media (max-width: 1024px) {
  .reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
    padding: 1px;
  }
}

.reorder-products-wrapper .cartBtnTooltip {
  width: auto;
  height: 100%;
  display: inline-block;
  position: relative;
  .tooltip {
    bottom: 100%;
    margin-bottom: 6px;
  }
}
.inventorymsg {
  font-size: 16px;
}
