#existing-address {
  md-pagination-wrapper {
    width: 100% !important;
  }

  md-toolbar {
    border-bottom: solid 1px #d7d7d7;
    min-height: 180px;
    height: auto;

    p {
      font-size: 18px;
      font-weight: 300;
      padding: 30px 60px 5px;
      margin: 0;
    }

    .shipping-address-list-wrapper {
      margin: 20px 30px 20px 60px;

      .shipping-title {
        display: none;
      }

      .shipping-description {
        display: none;
      }

      md-tabs {
        overflow: visible;

        &.show-a-tab {
          md-tabs-wrapper {
            border-radius: 2px;
            border: 0;
          }
        }
      }

      md-tabs-wrapper {
        border-radius: 3px;
        border: solid 1px #d7d7d7;

        md-tabs-canvas {
          overflow: visible;
          height: 38px;

          md-pagination-wrapper {
            height: 38px;
          }

          md-tab-item {
            overflow: visible;
          }
        }

        .md-tab {
          background-color: #f5f5f5;
          box-sizing: border-box;
          float: left;
          text-align: center;
          color: #999999;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.2px;
          padding: 7px 24px;
          width: 50%;

          a.tooltip-container {
            background: none;
            width: 100%;
            outline: none;
            color: inherit;
            vertical-align: baseline;

            &:hover {
              width: 100%;
              text-decoration: none;
            }

            .tooltip {
              text-align: left;
              height: auto;
              font-size: 12px;
              left: -30px;
              bottom: -124px;
              min-height: 50px;
              width: 250px;

              span {
                word-break: break-word;
                font-size: 14px;
                white-space: initial;
                text-transform: none;

                p {
                  font-size: 14px;
                  font-weight: normal;
                  padding: 0;
                }
              }
            }
          }
        }

        .md-active {
          color: #ffffff;
          background-color: @secondary-color;

          a {
            color: #ffffff;
          }
        }
      }

      .find-wrapper {
        margin: 0 0 0 40px;

        .find-shipping {
          width: 100%;
          float: right;

          .md-errors-spacer {
            min-height: 0;
          }

          .input-message {
            clear: both;
            padding: 10px 0;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.0;
            text-align: left;
            color: #333333;
          }

          label {
            font-size: 18px;
            line-height: 1.56;
            letter-spacing: 0.2px;
            text-align: left;
            color: #666666;
          }

          input {
            color: @secondary-color;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.56;
            letter-spacing: 0.2px;

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: @secondary-color;
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: @secondary-color;
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: @secondary-color;
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: @secondary-color;
            }
          }
        }
      }
    }
  }

  .address-list-wrapper {
    width: 100%;
    cursor:pointer;
  }

  md-list {
    background-color: transparent;
    padding: 0;
  }

  md-list-item {
    background-color: transparent;
    border-bottom: solid 1px #d7d7d7;
      &:hover{
        background:rgba(161, 233, 77, 0.2);
      }
    md-radio-button {
      margin-bottom: 0px;
      padding-left: 10px;
      padding-right: 30px;
      .md-container{
        left:10px;
      }
    }

    p {
      margin: 1em 0;
    }

    .md-button.md-no-style {
      background-color: transparent;

      button {
        background-color: transparent;

        &.md-button.confirm-remove {
          display: inline-block;
          margin: 1em;
          background-color: @primary-color;
        }

        &.cancel-remove {
          display: inline-block;
          margin: 1em;
          background-color: #fff;
          border: 1px solid @primary-color;
          color: @primary-color;
        }
      }
    }

    .address-list,
    .remove-warning-wrapper {
      width: 100%;
    }

    &.bold-text {
      p {
        font-weight: 500;
      }
    }
  }

  md-dialog-content {
    background-color: #f2f2f2;
    padding: 0 0 72px;

    .loading-wrapper {
      margin: 50px auto;
    }

    .address-info-content {
      table {
        width: 100%;
        font-size: 16px;
        text-align: left;
        color: #333333;

        .bold-text {
          font-weight: bold;
        }

        tbody tr {
          border-bottom: solid 1px #d7d7d7;
          padding: 0;
          outline: none;
          box-sizing: border-box;
          cursor: pointer;
        }

        td {
          padding: 15px 10px;
          font-size: 16px;
          line-height: 1.2;
          box-sizing: border-box;

          &:last-child {
            padding: 12px 10px;
          }

          .checked-mark {
            text-align: center;
          }
        }
      }

      .md-label {
        width: 100%;
      }
    }
  }

  .selected-ship-address-button {
    button.md-button {
      height: 40px;
      margin: 15px 0;

      span {
        padding: 0 30px;
      }
    }
  }

  .md-secondary-container {
      width: 40px;
  }
  md-dialog .md-toolbar-tools .md-close.md-button.search-close{
    min-height: 25px;
    right: 37px;
    top: 8px;
    height: 25px;
    width: 25px;
    padding:6px;
  }
  .disable-address-selection {
    background-color: #999999;
    pointer-events: none; 
  }
  .shipping-warning {
    color: #e00603;
  }
}
