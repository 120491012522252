@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.bannerSection {
	width: 100%;
	float: left;
	position: relative;
	z-index: 1;
	text-align: center;
	background: @primary-color;
	color: #fff;
	font-size: 16px;
	display:none;
	top:1px;
	cursor: pointer;
	height: 37px;
    line-height: 18px;
}

.bannerSection.show{
	display: block;
}
.bannerSection.show:hover{
	background-color: #666;
}

.bannerSection.show:hover{
	background-color: #666;
}

.underline{
	text-decoration: underline;
	cursor: pointer;
}
.popup-wrapper h1,
.popup-wrapper h4 {
	padding: 0px;
	margin: 10px;
}

.bannerSection span {
	display: inline-block;
	padding: 10px 0;
}
.popup-wrapper {
	display: none;

}
.backGround-opacity{display: none;}
.backGround-opacity.show{
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	display: block;
    top: 0px;
	z-index: 999;
	left:0px;
}
.popup-wrapper.show {

	width: 100%;
	height: 100%;
	padding: 10% 0;
	text-align: center;
	position: absolute;
	top: 0px;
	left:0px;
	z-index: 999;
	display: block;

}

.popup-wrapper.show.chat{
	width: auto;
	min-width:320px;
	height: 100%;
	padding: 10% 0;
	text-align: center;
	position: absolute;
	top: 0px;
	left:0px;
	z-index: 999;
	display: block;
}


.popup-wrapper .wrapper {
	max-width: 931px;
	margin: 0 auto;
	background: #fff;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
}

.popup-wrapper.chat .wrapper{
	max-width: 320px;
	padding:5%;
}

.popup-wrapper.chat .content-area{
 width:100%;
 float:left;
}



.green-slot {
	background: @secondary-color;
	color: #fff;
	display: inline-block;
	width: 100%;
	top: -5px;
	position: relative;
}

.img-slot img {
	width: 100%;
}

.popup-wrapper .close-icon {
	position: absolute;
	cursor: pointer;
	right: 5px;
	top: 5px;
	z-index: 999;
	background: url('/assets/images/close.png') no-repeat center center;
	width:27px;
	height:27px;
}

.popup-wrapper p {
	padding: 20px;
}
.popup-wrapper .btns{
	width:100%;
	text-align: center;
	margin-bottom:30px;
	a.button{
		border: 1px solid #fff;
		box-shadow: none;
	 background-color: @banner-btn-color;
		color:#fff;
		padding:15px 30px;
		border-radius: 10px;
	 }
}

.popup-wrapper a.button:hover{
	text-decoration: none;
}
span.mobile-view, .img-slot.mobile-view {
	display:none;
}
span.desktop-view,  .img-slot.desktop-view{
	display: inline-block;

}
.img-slot.desktop-view{
	width:100%;
}

.content-area p{
	 text-align: initial;
}

@media only screen and (max-width: 768px) {
   .popup-wrapper .wrapper {
      width:95%;
   }

}

@media only screen and (max-width: 1024px) {

	.bannerSection.show:hover{
		background-color: #76bd23;
	}

}



 @media only screen and (min-width: 320px) and (max-width: 640px)
 {

	span.underline{
		display:none;
	}

	 span.mobile-view, .img-slot.mobile-view{
		display: inline-block;
	}
	.img-slot.mobile-view{
		width:100%;
	}
	span.desktop-view, .img-slot.desktop-view{
		display: none;
	}
 }
