.welcome-splash {
  padding: 5px;

  .layout-column {
    display: flex;
    flex-flow: column;
  }

  button.close-button {
    background: none;
    border: none;
    font-size: 28px;
    line-height: 19px;
    height: 28px;
    width: 28px;
  }

  .welcome-splash-content-wrapper {
    padding: 25px 50px 40px 50px;

    h1 {
      text-transform: capitalize;
      font-weight: 400;
      margin-top: 0;
    }

    p {
      text-align: center;
      width: 60%;
      font-size: 18px;
      margin-bottom: 45px;
    }

    div.video-wrapper {
      margin-bottom: 25px;
    }
  }

  @media(max-width:768px) {
    .welcome-splash-content-wrapper {
      padding: 25px;

      p {
        margin-top: 0;
        margin-bottom: 25px;
        width: 90%;
      }

      div.video-wrapper {
        width: 100%;
        height: 0;
        position: relative;
        padding-bottom: 59%;

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
