/**
 * App-wide Styles
 */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  padding-top: calc(50vh - 48px);
  padding-left: calc(50vw - 48px);
  background-color: rgba(0, 0, 0, 0.32);
}
.pz-zoom-control-position-bottom {
  bottom: 30px !important;
}
#unsupported-browser {
  margin: auto;
  top: 30%;
  background: #FFFFFF;
  width: 450px;
  height: 234px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  text-align: center;
  position: relative;
}
#unsupported-browser #unsupported-browser-close {
  position: absolute;
  right: 18px;
  top: 21px;
  font-size: 12px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.36);
}
#unsupported-browser #unsupported-browser-container {
  margin: 8px 43px;
  width: 364px;
  cursor: default;
}
#unsupported-browser #unsupported-browser-container #unsupported-browser-image {
  width: 84px;
  height: 67px;
  margin: 20px 140px;
}
#unsupported-browser #unsupported-browser-container #unsupported-browser-header {
  color: #1787E0;
  line-height: 22px;
  margin: 0;
}
#unsupported-browser #unsupported-browser-container #unsupported-browser-content {
  size: 14px;
  line-height: 18px;
  color: #212529;
}
@media (max-width: 768px) {
  div.zopim {
    display: none !important;
  }
}
@media print {
  .non-printable {
    display: none;
  }
}
.cx-widget {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.cx-side-button-group {
  width: 180px;
  height: 30px;
  bottom: 0!important;
  right: 10px!important;
  -ms-transform: rotate(0) !important;
  transform: rotate(0deg) !important;
  top: unset !important;
}
.cx-side-button-group .cx-side-button {
  padding: 0 !important;
}
.cx-widget label {
  font-size: 14px!important;
  font-weight: 700!important;
  line-height: 30px !important;
}
.zenesys-chat {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  border: 0;
  background: 0 0;
  overflow: hidden;
  position: fixed;
  z-index: 16000002;
  width: 180px;
  height: 30px;
  bottom: 0;
  right: 10px;
}
.zenesys-chat_chatButton_Button {
  min-width: 180px;
  width: 180px;
  max-width: 300px;
  height: 30px;
  color: #fff;
  background: #76bd23;
  box-shadow: chatButtonShadow;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px 5px 0 0;
}
.zenesys-chat-greeting {
  font-weight: 700;
  line-height: 30px;
  padding-left: 50px;
  padding-right: 10px;
  cursor: pointer;
}
.zenesys-chat-chatButton-favicon {
  position: absolute;
  height: 100%;
  left: 0;
  vertical-align: text-top;
  text-align: center;
  padding-top: 4px;
  width: 36px;
  background: #693;
  color: #fff;
  border-radius: 5px 0 0 0;
}
.zenesys-chat-chatButton-favicon .fa-comment-o {
  font-size: 14px;
  font-weight: 700;
}
.cx-common-container .cx-buttons-window-control button:focus {
  border: none !important;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone1 {
  fill: #76bd23 !important;
}
.cx-widget.cx-theme-light .cx-btn-primary {
  border-color: #76bd23 !important;
  background: #76bd23 !important;
}
/* inject:less */
.custom-404 h2 {
  font-weight: 400;
  margin: 10px 0;
}
.custom-404 h4 {
  margin-top: 0;
  text-align: center;
  font-weight: 300;
}
.custom-404 a:hover {
  text-decoration: none;
}
.custom-404 .redirect-info-container {
  max-width: 1140px;
  margin: auto;
}
.custom-404 .custom-order-404-info {
  max-width: 420px;
  margin: auto;
  margin-bottom: -50px;
  padding: 0 10px 0 10px;
  text-align: center;
  font-size: 16px;
  line-height: 120%;
  z-index: 1;
  position: relative;
}
.custom-404 .redirect-info-list {
  margin: 0;
  padding: 0 10px 0 10px;
  list-style-type: none;
  font-size: 16px;
  line-height: 120%;
}
.custom-404 .redirect-info-list li {
  margin-bottom: 15px;
}
.custom-404 .generic-redirect-image {
  max-width: 100%;
}
.custom-404 .redirect-image {
  max-width: 100%;
}
.custom-404 .redirect-image-container {
  text-align: center;
}
.custom-404 .redirect-image-container .redirect-image {
  width: 500px;
  max-width: 100%;
  display: inline-block;
}
md-tab-content.md-active {
  transform: none;
}
.faqs .mt {
  margin-top: 20px;
}
.myAccount-wrapper {
  /*tab*/
}
.myAccount-wrapper h2 {
  font-size: 32px;
  font-weight: 300;
  color: #333333;
  text-align: left;
}
.myAccount-wrapper .header-title {
  border-bottom: 1px solid #000000;
  font-size: 16px;
}
.myAccount-wrapper .sub-title {
  margin-top: 30px;
}
.myAccount-wrapper p {
  color: #333333;
}
.myAccount-wrapper md-tabs[md-border-top] md-tabs-wrapper {
  margin: 0;
}
.bottom-line-divider {
  border-bottom: solid 1px #333333;
  margin-bottom: 30px;
}
.main-tab {
  width: 100%;
  background-color: #f6f6f6;
  text-align: center;
  overflow: hidden;
}
.main-tab h1 {
  padding: 22px 0;
  margin: 0;
  font-size: 18px;
  color: #333333;
  line-height: 1.2;
}
.main-content {
  padding: 50px 0;
}
.actions-wrapper h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.1;
  color: #666666;
}
.actions-list {
  overflow: hidden;
  margin-left: 0;
  padding-left: 0;
}
.actions-list li {
  width: 25%;
  float: left;
  margin-bottom: 10px;
  padding-left: 0;
  list-style: none;
  padding-right: 20px;
  box-sizing: border-box;
}
.actions-list a.active {
  color: #000000;
  text-decoration: none;
}
md-icon.trashcan {
  background-image: url('/assets/images/delete.png');
  background-repeat: no-repeat;
  background-position: center;
}
md-icon.trashcan:hover {
  background-image: url('/assets/images/delete-hover.png');
}
md-icon.pencil {
  background-image: url('/assets/images/edit.png');
  background-repeat: no-repeat;
  background-position: center;
}
md-icon.pencil:hover {
  background-image: url('/assets/images/edit-hover.png');
}
.unprocessed_orders {
  color: #999999;
}
.resubmit-failed-order .loading {
  margin-top: 30px;
}
.resubmit-failed-order .page-msg {
  text-align: center;
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 18px;
}
@media (min-width: 959px) {
  .resubmit-failed-order .page-msg {
    padding-left: 50px;
    padding-right: 400px;
  }
}
.resubmit-failed-order .payment-credit-card {
  min-height: unset !important;
}
.resubmit-failed-order .order-summary-wrapper {
  min-height: unset !important;
  margin-top: 45px;
}
.resubmit-failed-order .order-summary-wrapper .order-summary-title h3 {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 28px;
  line-height: 1.2;
}
.resubmit-failed-order .order-summary-wrapper .order-summary-table {
  margin-top: -21px;
}
@media (max-width: 959px) {
  .resubmit-failed-order {
    padding: 25px;
  }
  .resubmit-failed-order div.cart-summary-wrapper {
    margin-left: -25px;
    margin-right: -25px;
  }
  .resubmit-failed-order div.checkout-actions-tab ul {
    width: unset;
    border: none;
  }
  .resubmit-failed-order div.checkout-actions-tab ul li {
    width: unset;
    float: unset;
  }
}
.my-orders-detail-wrapper .summary {
  padding: 0 0 0 20px;
  font-size: 16px;
  line-height: 1.4;
  color: #454553;
}
.my-orders-detail-wrapper .detail-summary h4 {
  font-size: 12px;
  line-height: 1.3;
  color: #333333;
}
.my-orders-detail-wrapper .detail-summary p {
  font-size: 16px;
  line-height: 1.4;
  color: #454553;
}
.my-orders-detail-wrapper .detail-summary .layout-padding {
  padding: 0;
}
.my-orders-detail-wrapper .detail-summary .layout-padding > .flex {
  padding: 15px 20px 15px 0;
}
.my-orders-detail-wrapper .main-content {
  padding-bottom: 0;
}
.dash-border-bottom {
  border-bottom: dashed 1px #000000;
}
.light-gray-border-top {
  border-top: solid 1px #999999;
}
.light-gray-border-bottom {
  border-bottom: solid 1px #999999;
}
.package-detail {
  margin: 20px 0 40px;
}
.package-detail .header-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.package-detail h4 {
  font-size: 12px;
  line-height: 1.3;
  color: #333333;
}
.package-detail p {
  font-size: 16px;
  line-height: 1.4;
  color: #454553;
}
.package-detail:last-child table.order-detail {
  border-bottom: solid 1px #999999;
}
.order-detail {
  margin: 30px 0;
}
.order-detail h5 {
  margin: 0 0 10px 0;
}
.order-detail p {
  margin: 0 0 10px 0;
}
.order-detail md-checkbox {
  margin: 0;
}
.order-detail img {
  width: 75px;
}
.order-detail.table tbody tr {
  border-bottom: solid 1px #999999;
}
.order-detail.table tbody tr td {
  padding: 15px 40px 15px 0;
  color: #333333;
}
.order-detail.table tbody tr td:nth-of-type(1) {
  padding: 15px;
}
.order-detail.table tbody tr td:nth-of-type(2) {
  padding-right: 67px;
}
.order-detail.table tbody tr td:nth-of-type(5) {
  padding-right: 0;
}
.order-detail.table tbody tr td:nth-of-type(5) span {
  width: 70px;
  float: right;
}
.order-detail.table tbody .item-detail {
  font-size: 14px;
}
.order-detail.table tbody a {
  color: #669933;
  font-size: 16px;
  line-height: 1.1;
  text-decoration: none;
  cursor: default;
}
.order-detail.table tbody a:hover {
  text-decoration: underline;
}
.order-detail.table thead td:nth-of-type(5) {
  text-align: right;
  padding-right: 0;
}
.package-detail {
  margin: 20px 0 40px;
}
.package-detail .header-title {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  border-bottom: solid 0 !important;
}
.package-detail .header-title > button {
  margin-top: 0;
  border-width: 2px;
  border-style: solid;
}
.package-detail .invoice-button-holder {
  padding: 10px 0 20px 0;
}
.package-detail .invoice-button-holder > a {
  font-size: 18px;
  line-height: 21px;
}
.package-detail h4 {
  font-size: 12px;
  line-height: 1.3;
  color: #333333;
  margin: 10px 10px 0 0;
}
.package-detail p {
  font-size: 16px;
  line-height: 1.4;
  color: #454553;
  margin: 10px 10px 0 0;
}
.package-detail:last-child table.order-detail {
  border-bottom: solid 1px #999999;
}
.package-detail .disable-tracking-link {
  pointer-events: none;
  color: #666666;
}
.back-btn {
  text-transform: uppercase;
  font-weight: bold;
}
.order-number-header {
  margin-top: 80px;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.5;
  color: #333333;
}
/*Responsive table*/
@media only screen and (max-width: 959px) {
  div.top-border.detail-summary.layout-xs-column.layout-sm-column.layout-row {
    display: block;
  }
  .order-number-header {
    margin: 20px 0 10px 0;
    font-size: 20px;
  }
  .my-orders-detail-wrapper .main-content {
    padding-top: 20px;
  }
  .my-orders-detail-wrapper .summary {
    padding-left: 0;
    font-size: 14px;
    border-top: 0;
  }
  .my-orders-detail-wrapper .summary.layout-margin {
    margin-left: 0;
    margin-right: 0;
    padding-top: 10px;
    padding-left: 0;
  }
  .my-orders-detail-wrapper .summary.layout-margin * {
    margin-left: 0;
    margin-right: 0;
  }
  .my-orders-detail-wrapper .detail-summary .layout-padding > .flex {
    padding: 10px 0px 0px 0;
  }
  .my-orders-detail-wrapper .detail-summary .layout-padding > .flex p {
    font-size: 14px;
    color: #333333;
    font-weight: 300;
  }
  .my-orders-detail-wrapper .detail-summary .layout-padding > .flex p:last-child {
    margin-bottom: 0;
  }
  .my-orders-detail-wrapper .detail-summary .layout-padding > .flex h4 {
    font-size: 16px;
  }
  .my-orders-detail-wrapper .detail-summary .layout-padding > .flex:last-child {
    padding-bottom: 10px;
  }
  .order-detail {
    border-top: 2px solid #eee;
  }
  .order-detail.table {
    /* Hide table headers (but not display: none;, for accessibility) */
  }
  .order-detail.table thead {
    display: none !important;
  }
  .order-detail.table tbody tr {
    margin: 0;
    padding: 15px 0;
  }
  .order-detail.table tbody tr td {
    border-bottom: 0;
    font-size: 14px;
    flex: 1 0 auto !important;
  }
  .order-detail.table tbody tr td:nth-of-type(1) {
    padding: 0;
  }
  .order-detail.table td {
    border: none;
    position: relative;
    padding-left: 0;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #333333;
  }
  .order-detail.table .track-order {
    display: none;
  }
  .order-detail.table .prn {
    padding-right: 0 !important;
  }
  .order-detail.table .pr10 {
    padding-right: 10px !important;
    padding-left: 15px;
  }
  .order-detail.table img {
    width: 100%;
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  .view-more-btn {
    font-size: 14px;
    position: relative;
  }
  .view-more-btn:before {
    top: 2px;
  }
  .package-detail p {
    font-size: 14px;
  }
}
.reorder-tooltip {
  height: auto;
  font-size: 12px;
}
.message-alert-wrapper {
  margin-top: 20px;
}
.message-alert-wrapper .page-level-alert .ngTruncateToggleText {
  color: #ccc;
  font-size: 90%;
  font-style: italic;
}
.empty-state {
  text-align: center;
}
#order-filter-contianer .time-duration {
  margin: 25px 0 10px 0;
}
#order-filter-contianer .time-duration li {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  border-right: 1px solid #999999;
}
#order-filter-contianer .time-duration li:last-child {
  border-right: 0;
}
#order-filter-contianer .time-duration li a {
  padding: 0 10px;
  color: #4399c5;
  font-weight: 500;
  text-decoration: none;
}
#order-filter-contianer .time-duration li a:hover {
  cursor: pointer;
}
#order-filter-contianer .time-duration li a:active,
#order-filter-contianer .time-duration li a.selected {
  color: #000;
}
.track-order-wrapper .tracking-numbers {
  display: none;
  bottom: 30px !important;
}
.track-order-wrapper .tracking-numbers.open {
  display: block;
  bottom: 30px;
}
.track-order-wrapper.md-open .tracking-numbers {
  display: block;
  bottom: 30px;
}
.track-order-wrapper .tracking-numbers {
  position: relative;
}
.track-order-wrapper .tracking-numbers p {
  margin: 0 0 10px 0;
  font-size: 12px;
}
.track-order-wrapper .tracking-numbers:after {
  background-image: url(/assets/images/triangle.png);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  content: '';
  width: 100%;
  height: 13px;
  position: absolute;
  bottom: -12px;
  background-position: top center;
  background-repeat: no-repeat;
  left: 0;
}
.main-content .view-more-btn {
  color: #669933;
}
.main-content .view-more-btn:before {
  background-image: url(/assets/images/blue-arrow-icon.png);
}
.prn {
  padding-right: 0 !important;
}
/*Track order*/
/*dropdown*/
.dropdownToggleMouseover {
  position: relative;
  cursor: pointer;
}
.dropdownToggleMouseover .dropdown-content {
  display: none;
  position: absolute;
  bottom: 30px;
  left: -10%;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  padding: 20px;
  z-index: 1;
}
.dropdownToggleMouseover .dropdown-content .tracking-number {
  margin: 0;
  padding: 0;
}
.dropdownToggleMouseover .dropdown-content .tracking-number p {
  margin: 10px 15px;
  font-size: 12px;
  color: #333333;
}
.dropdownToggleMouseover .dropdown-content .tracking-number li {
  margin: 10px 0;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.dropdownToggleMouseover .dropdown-content .tracking-number li a {
  font-size: 15px;
  cursor: pointer;
}
.dropdownToggleMouseover .dropdown-content .tracking-number li:last-child {
  margin-bottom: 0;
}
.dropdownToggleMouseover .dropdown-content:after {
  background-image: url(/assets/images/triangle.png);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  content: '';
  width: 100%;
  height: 13px;
  position: absolute;
  bottom: -12px;
  background-position: top center;
  background-repeat: no-repeat;
  left: 0;
}
.dropdownToggleMouseover.dropdown-active .dropdown-content {
  display: block;
}
#my-orders-history tbody tr {
  border-bottom: solid 1px #eeeeee;
}
#my-orders-history tbody tr.lg-screen {
  display: flex;
}
#my-orders-history tbody tr.sm-screen {
  display: none;
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 959px) {
  #my-orders-history {
    border-top: 1px solid #333;
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
  }
  #my-orders-history table,
  #my-orders-history thead,
  #my-orders-history tbody,
  #my-orders-history th,
  #my-orders-history td,
  #my-orders-history tr {
    display: block;
  }
  #my-orders-history thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #my-orders-history tbody tr {
    margin: 0;
    padding: 15px 0;
  }
  #my-orders-history tbody tr.lg-screen {
    display: none;
  }
  #my-orders-history tbody tr.sm-screen {
    display: block;
  }
  #my-orders-history tbody tr td {
    border-bottom: 0;
    color: #333333;
    text-align: left;
    padding: 5px;
    font-size: 14px;
    color: #999999;
    width: 100%;
  }
  #my-orders-history tbody tr td.transaction-number {
    font-size: 14px;
    color: #000000;
  }
  #my-orders-history tbody tr td.transaction-number a.button {
    color: #76bd23;
  }
  #my-orders-history tbody tr td.date {
    font-size: 14px;
    color: #999999;
  }
  #my-orders-history tbody tr td.sub-title-col {
    font-size: 12px;
    line-height: 1;
  }
  #my-orders-history tbody tr td.order-by {
    font-size: 14px;
    color: #4399c5;
    line-height: 1;
  }
  #my-orders-history tbody tr .left-col {
    text-align: left;
  }
  #my-orders-history tbody tr .right-col {
    text-align: left;
    padding-left: 49%;
  }
  #my-orders-history tbody tr .right-col .arrow {
    float: right;
  }
  #order-filter-contianer {
    margin: 0 !important;
  }
  #order-filter-contianer .duration-wrapper {
    margin: 0 0 10px 0;
    padding: 0;
    text-align: right;
  }
  #order-filter-contianer .duration-wrapper md-select {
    border: none;
    color: #4399c5;
    font-weight: 500;
  }
  #order-filter-contianer .duration-wrapper md-select.md-no-underline {
    background-color: transparent;
    border-color: #fff;
    margin: 15px 0 0 0;
  }
  #order-filter-contianer .duration-wrapper md-select.md-no-underline .md-select-value {
    border: solid 1px transparent;
    border-radius: 0;
    padding: 5px 0 0 0;
  }
  a.view-more-btn {
    font-size: 16px;
    position: relative;
    color: #76bd23;
  }
  a.view-more-btn:before {
    top: 2px;
    background-image: url(/assets/images/green-arrow-icon.png);
  }
}
@media only screen and (max-width: 599px) {
  #my-orders-history tbody tr .right-col {
    padding-left: 60px;
  }
  #my-orders-history tbody tr .order-value {
    margin-left: -10px;
  }
}
@media only screen and (max-width: 399px) {
  #my-orders-history tbody tr td.date a {
    margin-left: 5px;
  }
  #my-orders-history tbody tr .right-col {
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
  }
  #my-orders-history tbody tr .order-value {
    margin-left: 0;
  }
}
.invoices-link {
  margin-right: 10px;
}
.action-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333333;
  padding: 0 10px;
  color: #ffffff;
}
.action-bar .items-selected {
  padding: 30px 0 0 16%;
  position: absolute;
  left: 0;
  text-transform: uppercase;
}
.action-bar a {
  color: #ffffff;
  background-color: #79c143;
  padding: 10px 20px;
  min-width: 200px;
  display: table-cell;
  text-align: center;
}
.action-bar a.add-to-cart-btn {
  background-color: #4399c5;
}
.action-bar a.add-to-cart-btn img {
  padding-right: 8px;
}
.action-bar a:hover {
  text-decoration: none;
  color: #ffffff;
}
.order-list td:last-child {
  padding-right: 0;
}
my-orders-actions-menu .actions-wrapper {
  margin-top: 20px;
  margin-bottom: 30px;
}
@media screen and (max-width: 959px) {
  my-orders-actions-menu .actions-wrapper {
    clear: both;
  }
}
my-orders-actions-menu .actions-wrapper .actions-list li {
  width: 100%;
}
@media (max-width: 959px) {
  .interier-wrapper {
    padding-top: 0;
  }
  .action-bar .items-selected {
    display: none;
  }
  .action-bar a {
    padding: 5px 20px;
    min-width: 150px;
    font-size: 14px;
  }
  .order-list tr {
    font-size: 14px;
    line-height: 1.3;
  }
}
/*Responsive table*/
.reorder-products-quick-order .quick-order {
  position: relative;
}
#order-filter-contianer {
  margin: 15px 0;
}
#order-filter-contianer h2 {
  font-size: 25.5px;
  margin: 15px 0 10px 0;
}
#order-filter-contianer .time-duration {
  margin: 25px 0 10px 0;
}
#order-filter-contianer .time-duration li {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  border-right: 1px solid #999999;
}
#order-filter-contianer .time-duration li:last-child {
  border-right: 0;
}
#order-filter-contianer .time-duration li a {
  padding: 0 10px;
  color: #4399c5;
  font-weight: 500;
  text-decoration: none;
}
#order-filter-contianer .time-duration li a:hover {
  cursor: pointer;
}
#order-filter-contianer .time-duration li a:active,
#order-filter-contianer .time-duration li a.selected {
  color: #000;
}
#order-filter-contianer md-input-container {
  margin: 18px 0 10px 0;
  height: 30px;
}
#order-filter-contianer a {
  color: #4399c5;
}
.reorder-products-wrapper .product-img {
  height: 85px;
}
.reorder-products-wrapper .table thead {
  font-size: 12px;
  line-height: 4.08;
  text-align: left;
  color: #333333;
}
.reorder-products-wrapper .table thead .checkbox-all {
  padding-left: 4%;
}
.reorder-products-wrapper .table thead tr {
  padding: 0 15px;
}
.reorder-products-wrapper .table thead td {
  padding-bottom: 15px;
}
.reorder-products-wrapper .table thead td.total-quantity {
  padding-left: 2px;
}
.reorder-products-wrapper .table thead td a {
  font-weight: normal;
}
.reorder-products-wrapper .table tbody .product-warning-tr {
  border-bottom: 0 !important;
}
.reorder-products-wrapper .table tbody tr {
  padding: 0 15px;
  outline: none;
  border-bottom: 0;
}
.reorder-products-wrapper .table tbody tr td {
  font-size: 15px;
}
.reorder-products-wrapper .table tbody tr p {
  margin: 0;
}
.reorder-products-wrapper .table tbody tr .hilight-row {
  background-color: #f6f6f6;
}
.reorder-products-wrapper .table tbody tr .checkbox-reorder {
  padding-left: 4%;
}
.reorder-products-wrapper .table tbody tr .checkbox-reorder .item-checkbox {
  display: table !important;
  height: 100%;
  vertical-align: middle;
}
.reorder-products-wrapper .table tbody tr .checkbox-reorder .item-checkbox md-checkbox {
  display: table-cell;
}
.reorder-products-wrapper .table tbody tr .last-ordered {
  font-size: 15px;
  letter-spacing: normal;
  text-align: left;
  color: #454553;
}
.reorder-products-wrapper .table tbody tr .total-quantity {
  padding-left: 10px;
}
.reorder-products-wrapper .table tbody tr .product-description {
  font-size: 16px;
}
.reorder-products-wrapper .table tbody tr .product-description .order-name {
  font-weight: bold;
  line-height: 1.12;
  text-align: left;
  color: #669933;
}
.reorder-products-wrapper .table tbody tr .product-description .order-name a {
  color: #669933;
}
.reorder-products-wrapper .table tbody tr .product-description p.category-title {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  font-weight: normal;
}
.reorder-products-wrapper .table tbody tr .added-to-cart-success-msg {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.67);
  position: absolute;
  top: 13px;
  left: 25%;
}
.reorder-products-wrapper .table tbody tr .added-to-cart-success-msg md-icon {
  margin-left: 0;
  margin-right: 4px;
  color: #76bd23;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: right;
  position: relative;
  padding-right: 0;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment {
  height: 34px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment button {
  font-size: 23px;
  font-weight: 300;
  text-align: center;
  color: #79c143;
  background-color: #ffffff;
  border: 1px solid #999999;
  box-sizing: border-box;
  float: left;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment button.decrement-button {
  font-size: 30px;
  line-height: 24px;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
  width: 28px;
  height: 34px;
  box-sizing: border-box;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity:focus {
  outline: none;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment .quantity {
  text-align: right;
  padding-right: 5px;
  width: 44px;
  height: 34px;
  border: 1px solid #999999;
  border-left: 0px;
  border-right: 0px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #454553;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  -moz-appearance: textfield;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment .quantity:focus {
  outline: none;
}
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment .quantity::-webkit-inner-spin-button,
.reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment .quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.reorder-products-wrapper .table tbody tr.product-warning {
  border-top: 0;
  border-bottom: 0;
}
.reorder-products-wrapper .table tbody tr.product-warning td {
  padding: 0;
}
.reorder-products-wrapper .table tbody tr.product-warning td:nth-of-type(2) {
  border-bottom: 0;
}
.reorder-products-wrapper .table tbody tr.product-warning td:nth-of-type(3) {
  border-top: 1px solid #999999;
}
.reorder-products-wrapper .table tbody tr.product-warning:last-child {
  border-bottom: 0;
}
.reorder-products-wrapper .table tbody tr.product-warning .warning-content {
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: #333333;
}
.reorder-products-wrapper .table tbody tr.product-warning .warning-content .warning-text {
  padding: 5px 0 0 10px;
  width: 100%;
}
.reorder-products-wrapper .table tbody tr.product-warning .warning-content .warning-text .warning-m-view {
  display: none;
}
.reorder-products-wrapper .table tbody tr .info-m-view {
  display: none;
}
.reorder-products-wrapper .table tbody tr td .add-to-cart-td-btn {
  margin-top: 0;
  font-weight: 300;
  letter-spacing: 0.5px;
  min-width: 120px;
}
.reorder-products-wrapper .table tbody tr td .add-to-cart-td-btn.md-button {
  height: 34px;
  min-height: 34px;
}
.reorder-products-wrapper .table:last-child tr:last-child {
  border-bottom: 1px solid #999999 !important;
}
.reorder-products-wrapper #product-itemcode {
  font-weight: 500;
  font-size: 15px;
  padding: 4px 0 0 0;
}
.reorder-products-wrapper #product-brandname {
  font-weight: 500;
  padding: 16px 0 0 0;
}
.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;
}
.view-more-btn:before {
  background-image: url('/assets/images/view-more-arrow.png');
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  left: 0;
}
.time-period li {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  border-right: 1px solid #999999;
}
.time-period li:last-child {
  border-right: 0;
}
.time-period li a {
  padding: 0 10px;
}
@media (max-width: 959px) {
  .reorder-products-wrapper .mobile-product {
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .reorder-products-wrapper .mobile-product:first-of-type {
    margin-top: 25px;
  }
  .reorder-products-wrapper .mobile-product a {
    color: #669933;
  }
  .reorder-products-wrapper .mobile-product h3 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .reorder-products-wrapper .mobile-product div.image-wrapper img {
    margin-left: -19px;
    height: 125px;
  }
  .reorder-products-wrapper .mobile-product div.data-info {
    font-weight: 500;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 3px;
  }
  .reorder-products-wrapper .mobile-product div.data-info.last-ordered {
    font-weight: 700;
    font-size: 14px;
    padding-left: 10px;
    margin-bottom: 10px;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper {
    margin-top: 10px;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .product-unit-price {
    font-size: 13px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.75);
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .quantity-adjustment {
    display: flex;
    align-items: center;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .quantity-adjustment button {
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    color: #79c143;
    background-color: white;
    border: 1px solid #999;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
    border-radius: 50%;
    width: 35px;
    min-width: 35px;
    height: 35px;
    border-color: rgba(0, 0, 0, 0.31);
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .quantity-adjustment input.quantity {
    height: 35px;
    width: 55px;
    padding: 0;
    text-align: center;
    font-size: 15px;
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.31);
    border-radius: 4px;
    font-weight: 500;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .add-to-cart-wrapper {
    position: relative;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper button.add-to-cart-td-btn {
    font-weight: 300;
    letter-spacing: 0.8px;
    border-radius: 4px;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .added-to-cart-success-msg {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.67);
    position: absolute;
    top: 11px;
    left: 137px;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .added-to-cart-success-msg md-icon {
    color: #76bd23;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .added-to-cart-success-msg-with-alternatives {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.67);
    position: absolute;
    top: -5px;
    left: 137px;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .added-to-cart-success-msg-with-alternatives md-icon {
    color: #76bd23;
  }
  .reorder-products-wrapper .mobile-product .mobile-quantity-adjustment-wrapper .discontinued-msg h4 {
    font-size: 14px;
    margin: 5px 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);
  }
}
.hide-select-all-checkbox {
  visibility: hidden;
}
@media (max-width: 1024px) {
  .reorder-products-wrapper .table tbody tr .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
    padding: 1px;
  }
}
.reorder-products-wrapper .cartBtnTooltip {
  width: auto;
  height: 100%;
  display: inline-block;
  position: relative;
}
.reorder-products-wrapper .cartBtnTooltip .tooltip {
  bottom: 100%;
  margin-bottom: 6px;
}
.inventorymsg {
  font-size: 16px;
}
.myAccount-wrapper {
  /*tab*/
}
.myAccount-wrapper .primary-title {
  margin-bottom: 15px;
}
.myAccount-wrapper .primary-title h2 {
  font-size: 30px;
  font-weight: 300;
  color: #333333;
  text-align: left;
  clear: both;
  margin: 0;
}
.myAccount-wrapper .primary-title .required-wrapper {
  font-size: 14px;
  line-height: 2;
  text-align: right;
  color: #333333;
  margin-top: 20px;
}
.myAccount-wrapper .primary-title .md-button {
  margin-top: -10px;
}
.myAccount-wrapper .header-title {
  border-bottom: 1px solid #000000;
  font-size: 16px;
}
.myAccount-wrapper .header-title.no-underline {
  border-bottom: 0;
}
.myAccount-wrapper .header-title.header-for-credit-card-list {
  margin-top: 40px;
  margin-bottom: 0;
}
.myAccount-wrapper .sub-title {
  margin-top: 30px;
}
.myAccount-wrapper p {
  color: #333333;
}
.myAccount-wrapper md-tabs[md-border-top] md-tabs-wrapper {
  margin: 20px 0;
}
.myAccount-wrapper md-tabs[md-border-top] .md-tab {
  width: 277px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
}
.myAccount-wrapper md-tabs[md-border-top] .md-tab .ng-scope {
  color: #999999;
}
.myAccount-wrapper md-checkbox {
  border-collapse: separate;
  border-spacing: 0;
}
.myAccount-wrapper md-tab-content {
  min-height: 600px;
  padding-right: 3px;
}
.bottom-line-divider {
  border-bottom: solid 1px #333333;
  margin-bottom: 30px;
}
.main-tab {
  width: 100%;
  background-color: #f6f6f6;
  text-align: center;
  overflow: hidden;
}
.main-tab h1 {
  padding: 22px 0;
  margin: 0;
  font-size: 18px;
  color: #333333;
  line-height: 1.2;
}
.main-content {
  padding: 50px 0 50px 0;
}
.main-content .md-button {
  color: #ffffff;
}
.main-content .md-button a:hover {
  color: #ffffff;
  text-decoration: none;
}
.welcome-user-module {
  overflow: hidden;
  margin: 10px 0;
  display: block;
}
.welcome-user-module .welcome-user-header {
  display: inline-block;
  font-size: 32px;
  font-weight: 500 !important;
  word-break: break-all;
  box-sizing: border-box;
  width: 70%;
  vertical-align: top;
}
.welcome-user-module .welcome-user-header .text-medium {
  font-size: 16px;
}
.welcome-user-module .welcome-user-header .not-you-sign-out {
  margin-top: 10px;
  font-weight: 300;
}
.welcome-user-module .welcome-user-header .sign-out {
  color: #669933;
}
.welcome-user-module .welcome-user-header .sign-out:hover {
  color: #000000;
  text-decoration: underline;
}
.welcome-user-module .welcome-user-header .welcome-name {
  display: inline;
  font-size: 32px;
  font-weight: 500;
}
.actions-wrapper {
  margin-top: 20px;
  margin-bottom: 40px;
}
.actions-wrapper h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.1;
  color: #666666;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}
.actions-list-wrapper {
  margin-right: 265px;
}
.actions-list-wrapper .actions-list {
  overflow: hidden;
  margin-left: 0;
  padding-left: 0;
}
.actions-list-wrapper .actions-list li {
  width: 33.33%;
  float: left;
  margin-bottom: 10px;
  padding-left: 0;
  list-style: none;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;
}
.actions-list-wrapper .actions-list li a {
  position: relative;
  /*padding-left: 10px;*/
  padding-left: 0;
  display: inline-block;
  text-decoration: none;
  letter-spacing: 0.2px;
  color: #4399c5;
}
.actions-list-wrapper .actions-list li a:before {
  /*content: '-';
	    		margin-right: 5px;
	    		display: block;
	    		position: absolute;
	    		left: 0;
	    		text-decoration: none;*/
}
.actions-list-wrapper .actions-list li:nth-of-type(4) {
  clear: left;
}
.actions-list-wrapper .actions-list li:nth-of-type(7) {
  clear: left;
}
.actions-list-wrapper .actions-list a.active {
  color: #000000;
  text-decoration: none;
}
.tabs-section {
  padding: 20px 0 24px 0;
}
.tabs-section ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 0;
}
.tabs-section ul li {
  display: inline-block;
  width: 277px;
  height: 40px;
  font-size: 18px;
  border-top: solid 1px #76bd23;
  list-style: none;
  padding: 0;
  margin: 0;
}
.tabs-section ul li a {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  padding-top: 11px;
  color: #999999;
  -webkit-transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.tabs-section ul li a:hover {
  cursor: pointer;
}
.tabs-section ul li a:active,
.tabs-section ul li a:focus {
  background-color: #ccc;
  color: #666;
}
.tabs-section ul li.active {
  border-top: solid 4px #76bd23;
}
.tabs-section ul li.active a {
  color: #000;
}
.my-orders-list.actions-list li {
  float: none;
}
md-icon.trashcan {
  background-image: url('/assets/images/delete.png');
  background-repeat: no-repeat;
  background-position: center;
}
md-icon.trashcan:hover {
  background-image: url('/assets/images/delete-hover.png');
}
md-icon.trashcan.gray {
  background-image: url('/assets/images/icons/delete-gray-color.png');
  background-size: 12px 16px;
}
md-icon.pencil {
  background-image: url('/assets/images/edit.png');
  background-repeat: no-repeat;
  background-position: center;
}
md-icon.pencil:hover {
  background-image: url('/assets/images/edit-hover.png');
}
.autoheight {
  height: 350px;
}
.quick-order {
  right: 0;
  width: 422px;
  background-color: #f6f6f6;
  z-index: 2;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  float: right;
  border-radius: 0;
  box-sizing: border-box;
}
.quick-order .quick-order-mod {
  padding: 20px;
  padding-top: 0;
}
.quick-order .quick-order-mod form {
  margin-bottom: 10px;
}
.quick-order .quick-order-mod .unit {
  align-self: flex-end;
}
.quick-order .quick-order-mod .unit:last-child {
  margin-right: 0;
}
.quick-order .quick-order-mod .unit button {
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
}
@media screen and (max-width: 599px) {
  .quick-order .quick-order-mod .unit a.add-to-cart-btn {
    margin-left: 0px;
  }
}
.quick-order .quick-order-mod .product-detail {
  clear: both;
  padding: 20px 0;
}
.quick-order .quick-order-mod .product-detail .displayItem-name {
  padding-right: 15px;
}
.quick-order .quick-order-mod .product-detail .product-image-wrapper {
  text-align: center;
}
.quick-order .quick-order-mod .product-detail .product-image-wrapper .product-image {
  width: 100%;
}
.quick-order .quick-order-mod .product-detail .divider-line {
  padding-left: 10px;
  border-left: solid 1px #ccc;
}
.quick-order .quick-order-mod .product-code-input {
  width: 105px;
  margin-right: 25px;
}
.quick-order .quick-order-mod .quantity-input {
  width: 50px;
  margin-right: 15px;
}
.quick-order .quick-order-mod .warning-message-wrapper {
  padding-left: 45px;
  font-size: 14px;
  line-height: 1.14;
  color: #333333;
  text-align: left;
}
.quick-order .quick-order-mod .warning-message-wrapper:before {
  position: absolute;
  content: '';
  top: 14px;
  left: 7px;
  background-image: url('/assets/images/attention.png');
  background-repeat: no-repeat;
  width: 27px;
  height: 25px;
}
.quick-order .quick-order-mod md-progress-circular {
  margin-top: 5px;
}
.quick-order .added-to-cart {
  background-color: #000;
  color: #fff;
  width: 100%;
  text-align: center;
  height: 50px;
}
.quick-order .added-to-cart .added-to-cart-text {
  margin: auto;
}
.quick-order .probidden-to-add {
  background-color: #4399c5;
  color: #fff;
  width: 100%;
  text-align: center;
  min-height: 50px;
  padding: 8px;
}
.quick-order .probidden-to-add .probidden-to-add-text {
  margin: auto;
  padding: 5px 10px;
}
.quick-order .probidden-to-add .error-icon {
  width: 18px;
}
.quick-order .md-button.add-to-cart-btn {
  background-image: url('/assets/images/bag-icon.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 37px;
  border-radius: 10px;
  margin-top: 25px;
}
.quick-order .md-button.add-to-cart-btn .ng-scope {
  font-size: 15px;
  letter-spacing: 1.6px;
  color: #ffffff;
  font-weight: 300;
}
.quick-order h3 {
  margin: 0;
  font-size: 14px;
}
.quick-order md-input-container {
  font-size: 14px;
  margin: 25px 0 0 0;
  height: 30px;
}
.quick-order md-input-container.md-input-has-value label {
  font-size: 12px;
}
.quick-order md-input-container .md-input {
  color: #666666;
  font-size: 18px;
  letter-spacing: 0.2px;
}
.quick-order md-input-container label {
  font-size: 12px;
  line-height: 1.3;
  color: #333333;
}
.quick-order md-input-container.quantity-input .md-input {
  color: #669933;
}
.md-row-3 {
  max-width: 33.33%;
}
email-preferences .unit {
  float: left;
}
email-preferences .unit.first-unit {
  width: 49%;
}
email-preferences .unit.second-unit {
  width: 49%;
  padding-left: 5%;
  box-sizing: border-box;
}
@media (max-width: 959px) {
  .myAccount-wrapper {
    padding-top: 0;
  }
  .myAccount-wrapper .main-content {
    padding-top: 18px;
  }
  .myAccount-wrapper .primary-title {
    margin-bottom: 15px;
  }
  .myAccount-wrapper .primary-title h2 {
    font-size: 24px;
  }
  .myAccount-wrapper h2 {
    font-size: 24px;
  }
  .myAccount-wrapper h2 span {
    font-size: 16px;
  }
  .myAccount-wrapper .settings {
    margin-left: -15px;
    margin-right: -15px;
  }
  .myAccount-wrapper md-tabs[md-border-top] md-tabs-wrapper {
    width: 100%;
  }
  .myAccount-wrapper md-tabs[md-border-top] md-tab-content {
    margin: 0 15px;
  }
  .myAccount-wrapper md-pagination-wrapper {
    width: 100% !important;
    height: 100px;
  }
  .myAccount-wrapper md-tabs.settings md-tabs-wrapper {
    height: 100px;
  }
  .myAccount-wrapper md-tabs.settings md-tabs-canvas {
    height: 100px;
    border-bottom: solid 1px #76bd23;
  }
  .myAccount-wrapper md-tabs[md-border-top] .md-tab {
    width: 100%;
  }
  .myAccount-wrapper .edit-account-info .checkout-actions-tab ul li {
    margin: 0px auto;
    float: none;
  }
  .welcome-user-module {
    overflow: hidden;
    margin: 10px 0;
  }
  .welcome-user-module .welcome-user-header {
    float: left;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-right: 10px;
    font-size: 18px;
    line-height: 1;
  }
  .welcome-user-module .welcome-user-header .text-smaller {
    font-size: 12px;
    font-weight: normal;
  }
  .welcome-user-module .welcome-user-header .text-medium {
    font-size: 14px;
  }
  .welcome-user-module .welcome-user-header .not-you-sign-out {
    margin-top: 10px;
  }
  .welcome-user-module .welcome-user-header .welcome-name {
    display: block;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.3;
  }
  .quick-order {
    width: 100%;
    float: left;
  }
  .actions-wrapper {
    background-color: #f6f6f6;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    margin-top: 15px !important;
  }
  .actions-wrapper h3 {
    padding: 15px 11px 0 11px;
    text-transform: capitalize;
    color: #999999;
  }
  .actions-wrapper .dropdown {
    float: none !important;
    width: 100% !important;
    padding: 10px 10px 15px 10px;
    box-sizing: border-box;
  }
  .actions-wrapper .dropdown > div {
    float: none;
  }
  .actions-wrapper .dropdown > div div.dropdown-display {
    float: none;
    border: 0;
    border-bottom: 1px solid #d1d0d4;
    background-color: transparent;
    border-radius: 0;
    padding: 0 0 10px 0;
  }
  .actions-wrapper .dropdown > div div.dropdown-display .placeholder {
    color: #4399c5;
  }
  .actions-wrapper .dropdown > div div.dropdown-display:after {
    content: '\25BC';
    color: #4399c5;
    top: 2px;
    speak: none;
    font-size: 13px;
    -webkit-transform: scaleY(0.5) scaleX(1);
    transform: scaleY(0.5) scaleX(1);
  }
  .actions-wrapper .dropdown > div div.dropdown-list {
    top: -30px;
  }
  .actions-wrapper .dropdown > div div.dropdown-list .active {
    background-color: #f5f5f5;
    color: #4399c5;
  }
  .actions-wrapper .dropdown > div div.dropdown-list .active span {
    color: #4399c5;
  }
  .actions-wrapper .dropdown > div div.dropdown-list span {
    color: #333333;
  }
  .tabs-section ul {
    width: 100%;
  }
  .tabs-section ul li {
    width: 100%;
  }
  .tabs-section ul li:last-child {
    border-bottom: solid 1px #76bd23;
  }
  .state,
  .country {
    margin-top: -3px;
    padding-top: 0;
  }
  .main-content h2 {
    font-size: 24px;
  }
  .main-content h {
    font-size: 20px;
  }
  email-preferences .unit {
    float: none;
  }
  email-preferences .unit.first-unit {
    width: 100%;
  }
  email-preferences .unit.second-unit {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 599px) {
  .layout-gt-xs-row .md-row-3 {
    max-width: 100%;
  }
  email-preferences .unit {
    flex: 1 0 auto !important;
  }
  email-preferences .unit:last-child {
    margin-left: 0;
  }
  .quick-order .quick-order-mod .probidden-to-add {
    position: relative;
  }
  .quick-order .quick-order-mod {
    padding: 0 8px 16px 8px;
  }
}
@media (max-width: 500px) {
  .suffix {
    width: 94%;
    margin-top: -3px;
    padding-top: 0;
  }
  .states {
    width: 50%;
  }
  .myAccount-wrapper .primary-title .required-wrapper {
    font-size: 12px;
  }
}
@media (max-width: 360px) {
  .welcome-user-module .welcome-user-header .welcome-name {
    font-size: 15px;
  }
  .welcome-user-module .welcome-user-header .text-smaller {
    font-size: 11px;
  }
  .welcome-user-module .welcome-user-header .text-medium {
    font-size: 12px;
  }
}
/*
Valiable created on main.less

@font-family: 'Roboto', sans-serif;
@font-w-normal: 400;
@font-w-bold: 700;
@primary-color: #76bd23;
@secondary-color: #4399c5;
@warn-color:rgb(255,87,34);
@btn-primary-color: #ffffff;
@btn-primary-bg: #76bd23;
@btn-primary-bg-hover: #000000;
@btn-primary-radius: 0;
@btn-secondary-bg: #4399c5;
@btn-falt-color: #76bd23;
@link-color-green : #79c143;
@link-color-secondary : #4399c5;
@disabled-color: #FFFFFF;
@disabled-color-bg: #BEBEBE;
@disabled-color-dark: #E4E4E4;
@disabled-color-dark-bg: #999999;
@invalid-color: #cc3366;
*/
body,
html {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
input::-webkit-input-placeholder {
  color: #a7a7a7;
}
input::-moz-placeholder {
  color: #717171;
}
input:-ms-input-placeholder,
#input_13:-ms-input-placeholder {
  color: #a7a7a7;
}
/*sign up tab*/
.process-tab-wrapper .process-tab {
  border-bottom: 1px solid #76bd23;
}
.process-tab-wrapper .process-tab ul {
  margin: 0;
  padding: 0;
}
.process-tab-wrapper .process-tab ul li {
  list-style: none;
  text-align: center;
  border-right: 0;
  padding: 0;
}
.process-tab-wrapper .process-tab ul li:first-child a {
  padding-left: 0;
  margin-left: 0;
}
.process-tab-wrapper .process-tab ul li a {
  padding: 15px 0;
  margin: 0 15px;
  text-decoration: none;
  color: #cccccc;
  font-size: 18px;
  line-height: 1;
  display: block;
  text-transform: uppercase;
  pointer-events: none;
  cursor: default;
}
.process-tab-wrapper .process-tab ul li a.active {
  color: #000000;
  border-bottom: 4px solid #76bd23;
  font-weight: 700;
}
.process-tab-wrapper.sign-up-process-tabs-wrapper .process-tab ul li {
  width: 261px;
}
/*tab with border bottom*/
md-tabs[md-border-bottom] md-tabs-wrapper {
  width: 554px;
  margin: 0 auto;
  border: 0;
  border-bottom: solid 1px #76bd23;
}
md-tabs[md-border-bottom] .md-tab {
  width: 174px;
  padding: 0;
  border-bottom: solid 4px transparent;
  line-height: 26px;
}
md-tabs[md-border-bottom] .md-tab .ng-scope {
  padding: 10px 5px;
  display: block;
  color: #999999;
}
md-tabs[md-border-bottom] .md-tab.md-active {
  border-bottom: solid 4px #76bd23;
}
md-tabs[md-border-bottom] .md-tab.md-active .ng-scope {
  color: #000000;
}
/*Global Input Styling*/
md-radio-button {
  outline: 0;
}
md-radio-button .md-label {
  color: #4399c5;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
md-radio-button .md-off {
  border-width: 1px;
}
md-radio-button.md-checked .md-on {
  background-color: #4399c5;
}
.form-contrainer {
  min-height: 300px;
  border-bottom: solid 1px #333333;
  padding-bottom: 30px;
}
md-input-container.email {
  clear: both;
  width: 30%;
  display: block;
}
md-input-container .md-input[type="search"] {
  float: left;
  width: 100%;
  height: auto;
}
md-select-menu md-content {
  background-color: #ffffff;
}
md-input-container .md-select-placeholder span {
  color: #D3D3D3;
}
md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  color: #666666;
}
md-select .md-select-value {
  border-color: rgba(0, 0, 0, 0.12);
}
md-select:not([disabled]):focus .md-select-value {
  padding-bottom: 5px;
}
.md-button.md-icon-button.md-datepicker-button {
  background-color: #76bd23;
}
.md-datepicker-calendar-pane {
  background-color: #ffffff;
}
.registration-wrapper {
  /*register input stlying*/
}
.registration-wrapper md-select:not([disabled]):focus .md-select-value {
  padding-bottom: 1px;
  border-bottom-width: 1px;
}
.registration-wrapper md-select-value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.registration-wrapper [name="selectState"] {
  padding-right: 20px;
}
.registration-wrapper .content {
  border-bottom: solid 1px #333333;
  margin-bottom: 30px;
}
.registration-wrapper .content hr {
  border-top: 1px solid #d7d7d7;
}
.registration-wrapper .content .message-wrapper {
  width: 75%;
  margin: 30px auto 60px;
}
.registration-wrapper .content .message-wrapper p {
  text-align: justify;
  line-height: 1.5;
}
.registration-wrapper .content .message-wrapper li {
  text-align: left;
}
.registration-wrapper .content.signup-error {
  min-height: 300px;
}
.registration-wrapper md-input-container .md-input,
.registration-wrapper md-input-container md-select-value {
  border-color: rgba(0, 0, 0, 0.22);
}
.registration-wrapper md-input-container .md-select-placeholder span,
.registration-wrapper md-input-container .md-placeholder,
.registration-wrapper md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  color: #a0a0a0;
}
.registration-wrapper .section {
  padding-top: 44px;
}
.registration-wrapper a {
  color: #4399c5;
}
.registration-wrapper md-tabs[md-border-bottom] md-tabs-wrapper {
  width: 100%;
  background-color: #f6f6f6;
}
.registration-wrapper md-tabs[md-border-bottom] .md-tab {
  font-size: 18px;
  font-weight: 500;
  width: 261px;
}
.registration-wrapper md-tabs[md-border-bottom] .md-tab.md-active .ng-scope {
  color: #333333;
}
.registration-wrapper md-tab-data,
.registration-wrapper md-tabs-canvas {
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
}
.registration-wrapper md-tab-content {
  padding: 30px 0 20px;
}
.registration-wrapper md-tabs[md-border-bottom] .md-tab .ng-scope {
  text-align: left;
}
.registration-wrapper .has-account {
  margin-bottom: 40px;
  cursor: pointer;
}
.registration-wrapper md-input-container {
  margin-right: 35px;
}
.registration-wrapper .customer-type md-radio-button {
  display: inline-block;
  margin-right: 40px;
}
.registration-wrapper .customer-type md-radio-button:last-child {
  margin-right: 0;
}
.registration-wrapper md-input-container.md-input-focused label:not(.md-no-float):not(.md-container-ignore),
.registration-wrapper md-input-container.md-input-has-value label:not(.md-no-float) {
  color: #333333;
}
.registration-wrapper .header-title {
  border-bottom: 1px solid #000000;
  font-size: 16px;
  padding: 0 5px;
}
.registration-wrapper .header-title.no-underline {
  border-bottom: 0;
}
.registration-wrapper .sub-title {
  margin-top: 30px;
}
.registration-wrapper p {
  color: #333333;
}
.registration-wrapper .form-step-wrapper {
  background: transparent;
  position: static;
  width: auto;
  text-align: left;
}
.registration-wrapper .form-step-wrapper .form-step-inner {
  border-bottom: 1px solid #000000;
  padding-bottom: 61px;
  display: block;
}
.registration-wrapper .form-step-wrapper .form-step-inner button {
  min-width: 170px;
}
.registration-wrapper .form-step-wrapper .form-step-inner button[disabled] {
  color: #b1b1b1;
  background-color: #E4E4E4;
}
.registration-wrapper .form-contrainer {
  padding-bottom: 0;
  border-bottom: 0;
}
.form-step-wrapper {
  position: fixed;
  display: block;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #333333;
  text-align: center;
  z-index: 10;
}
@media (max-width: 599px) {
  .form-step-wrapper {
    text-align: center;
  }
}
.form-step-wrapper .form-step-inner {
  display: inline-block;
}
.form-step-wrapper .form-step-inner button[disabled] {
  color: #E4E4E4;
  background-color: #999999;
}
md-input-container.md-input-invalid .md-char-counter,
md-input-container.md-input-invalid [data-ng-message],
md-input-container.md-input-invalid [ng-message],
md-input-container.md-input-invalid [x-ng-message],
md-input-container.md-input-invalid data-ng-message,
md-input-container.md-input-invalid ng-message,
md-input-container.md-input-invalid x-ng-message {
  color: #cc3366;
}
credential-form {
  display: block;
  padding-bottom: 40px;
}
.title-for-dropzone {
  max-width: 653px;
  width: 80%;
  margin: 10px auto;
}
.dropzone {
  border: dashed 1px #4399c5;
  text-align: center;
  padding-bottom: 40px;
  max-width: 653px;
  width: 90%;
  float: right;
}
.dropzone .computer-icon {
  background: url('/assets/images/icons/computer-icon.png') no-repeat 0 0;
  width: 47px;
  height: 41px;
  margin: 55px auto 0;
}
.dropzone.disabled,
.dropzone.dragover {
  background-color: #f6f6f6;
  border: dashed 1px #000000;
}
.dropzone.disabled .computer-icon,
.dropzone.dragover .computer-icon {
  background: url('/assets/images/icons/computer-icon-disabled.png') no-repeat 0 0;
  width: 47px;
  height: 41px;
  margin: 55px auto 0;
}
.dropzone.disabled p,
.dropzone.dragover p {
  color: #999999;
}
.dropzone.disabled p.title,
.dropzone.dragover p.title {
  color: #898989;
}
.dropzone.disabled .md-button,
.dropzone.dragover .md-button {
  background-color: #BEBEBE;
  color: #FFFFFF;
}
.dropzone.disabled.hover .computer-icon,
.dropzone.dragover.hover .computer-icon {
  background-position: 0 0;
}
.dropzone p {
  font-size: 14px;
  color: #999;
}
.dropzone p.title {
  color: #666666;
  font-size: 18px;
}
.dropzone .md-button {
  min-width: 140px;
}
.finished {
  padding: 30px 0;
}
.finished hr {
  margin: 50px auto;
  width: 75%;
}
.finished h2 {
  margin: 30px 0 0;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.12;
}
.finished h3 {
  margin: 0 0 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 1;
}
.finished h4 {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1;
}
.finished p {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
}
.circular-loading-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
}
.circular-loading-overlay .loading-wrapper {
  background-color: #ffffff;
  width: 80%;
  height: 300px;
  max-width: 800px;
  margin: 0 auto;
}
.circular-loading-overlay .loading-wrapper .loading {
  padding: 11% 5%;
  box-sizing: border-box;
}
.circular-loading-overlay .loading-wrapper .loading .loading-content p {
  font-size: 20px;
  color: #666666;
}
.circular-loading-overlay .loading-wrapper .loading .loading-content md-progress-circular {
  margin: 0 auto;
}
.certification-desc ul {
  padding-left: 28px;
}
@media (max-width: 959px) {
  .registration-wrapper md-pagination-wrapper,
  .registration-wrapper md-tabs-canvas {
    height: 47px;
  }
  .registration-wrapper .section {
    padding-top: 15px;
  }
  credential-form {
    position: relative;
  }
  .process-tab-wrapper .process-tab ul li {
    width: 50%;
    font-size: 15px;
  }
  .process-tab-wrapper .process-tab ul li a {
    font-size: 15px;
  }
  .process-tab-wrapper.sign-up-process-tabs-wrapper .process-tab ul li {
    width: 50%;
  }
}
@media (max-width: 588px) {
  .registration-wrapper md-pagination-wrapper,
  .registration-wrapper md-tabs-canvas {
    height: 47px;
  }
  .registration-wrapper md-tabs[md-border-bottom] .md-tab {
    width: 100%;
    clear: both;
    font-size: 16px;
    text-transform: capitalize;
  }
  .dropzone {
    float: none;
    width: initial;
  }
  .title-for-dropzone {
    width: 100%;
  }
  credential-form {
    position: relative;
  }
}
@media (max-width: 320px) {
  .process-tab-wrapper .process-tab ul li a {
    font-size: 12px;
  }
}
.md-select-menu-container md-option .md-text {
  white-space: normal;
}
@media (max-width: 599px) {
  .registration-wrapper md-input-container {
    width: 100%;
    margin-right: 0;
  }
  .registration-wrapper md-input-container.prof-info-ext {
    margin-left: 0%;
  }
  .registration-wrapper .form-step-wrapper .form-step-inner button {
    min-width: 140px;
  }
  .registration-wrapper .checkout-actions-tab {
    position: static;
  }
}
@media (min-width: 650px) {
  .signup-inline {
    display: flex;
  }
  #certification-section .layout-gt-xs-row {
    width: 100%;
  }
}
.form-contrainer md-input-container [type='search'].md-input {
  height: 30px;
}
#resale-message {
  font-size: 18px;
  width: 70%;
}
#resale-message p {
  font-size: 18px;
}
#resale-message h3 {
  color: #0081D8;
  text-align: left;
  font-style: italic;
  font-weight: bold;
}
#resale-message div {
  text-align: left;
}
#resale-message .md-button {
  background-color: #0081D8;
  margin: 0px;
}
#resale-message #resale-link {
  padding-top: 15px;
}
#resale-message #resale-link a {
  color: gray;
  font-size: 12px;
}
#resale-message img {
  height: 60px;
  padding-bottom: 10px;
}
#resale-header p {
  color: gray;
  font-size: 12px;
  text-align: center;
}
.small-gray-text {
  color: gray;
  font-size: 12px;
}
.ga-button-wrapper {
  display: inline-flex;
  outline: none;
}
#resale-confirmation-button {
  background-color: #F6921E;
  color: #ffffff;
  border-radius: 12px;
}
.trash {
  color: #d15b47;
}
.user-list li {
  display: flex;
  border: none;
  border-bottom: 1px lightgray solid;
  margin-bottom: 0;
}
.user-list li:last-child {
  border-bottom: none;
}
.user-list li .user-info {
  flex-grow: 1;
}
.user-list li .trash {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.brands-menu {
  margin: 0;
  padding: 0;
}
.brands-menu li {
  display: inline-block;
  font-size: 16px;
  padding: 0 15px 0 0 !important;
  margin: 0 0 0 15px !important;
}
.brands-menu li.line-divider {
  border-right: solid 1px #d7d7d7 !important;
}
.brands-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}
#herobrnds {
  top: 45%;
}
.hero-desktop {
  display: block;
}
.brands-content {
  text-align: left;
  font-weight: 400;
  font-size: 32px;
  margin: 25px 0;
}
.brands-columns {
  margin-bottom: 70px;
}
.brands-columns ul {
  margin: 0;
  -moz-column-count: 3;
  -moz-column-gap: 50px;
  -webkit-column-count: 3;
  -webkit-column-gap: 50px;
  column-count: 3;
  column-gap: 50px;
  /* Chrome, Safari, Opera */
  /* Firefox */
}
.brands-columns ul > li {
  margin: 0;
  padding: 0;
}
.brands-columns ul > li a {
  color: #55a3c8;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}
@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  .hero-landscape {
    display: block;
    padding-top: 50px;
    width: 100%;
  }
  .hero-portrait {
    display: none;
  }
  .hero-desktop {
    display: none;
  }
}
@media screen and (max-device-width: 1000px) and (orientation: portrait) {
  .hero-portrait {
    display: block;
    width: 100%;
  }
  .hero-landscape {
    display: none;
  }
  .hero-desktop {
    display: none;
  }
}
.cart-checkout-wrapper {
  border-bottom: none !important;
}
.cart-checkout-wrapper:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.cart-item-list-wrapper {
  /* max-width: 796px;*/
}
.checkout-actions-wrapper.push-top {
  margin-top: 35px;
}
.checkout-actions-wrapper .mobile-express-checkout md-progress-circular path,
.checkout-actions-wrapper .mobile-express-checkout md-progress-circular.md-default-theme path {
  stroke: #ffffff;
}
.checkout-actions-tab {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #f6f6f6;
  width: 100%;
  text-align: center;
  z-index: 10;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.checkout-actions-tab.replacement-actions-tab {
  z-index: 9;
  border-top: none;
  bottom: auto;
  padding: 10px 0;
}
.checkout-actions-tab.replacement-actions-tab ul li:first-of-type {
  margin-left: 0;
}
.checkout-actions-tab.replacement-actions-tab a,
.checkout-actions-tab.replacement-actions-tab button {
  text-align: center;
}
.checkout-actions-tab ul {
  display: inline-block;
  margin: 10px 0;
  padding-left: 0;
}
.checkout-actions-tab ul li {
  list-style: none;
  float: left;
  margin: 0 10px;
}
.checkout-actions-tab ul li button[disabled],
.checkout-actions-tab ul li .disabled {
  color: #E4E4E4;
  background-color: #999999;
}
.checkout-actions-tab ul li a {
  min-width: 200px;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  display: table-cell;
  background-color: #79c143;
  color: #ffffff;
  vertical-align: middle;
  border: solid 1px #79c143;
  text-transform: uppercase;
  box-sizing: border-box;
  outline: none;
}
.checkout-actions-tab ul li a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  border: 1px solid #BEBEBE;
  color: #E4E4E4;
  background-color: #999999;
}
.checkout-actions-tab ul li a.back-btn {
  background-color: #fff;
  color: #79c143;
}
.checkout-actions-tab ul li a:hover {
  text-decoration: none;
}
.checkout-actions-tab ul li a.md-secondary {
  border: solid 1px #4399c5;
  background-color: #4399c5;
}
.checkout-step-wrapper {
  /* margin-top: 40px;*/
}
.step-heading {
  margin: 20px 0;
}
@media only screen and (max-width: 959px) {
  .checkout-step-wrapper {
    margin-top: 0;
    padding: 0;
  }
  .checkout-step-wrapper .step-wrapper {
    padding: 30px 15px 0 15px;
    box-sizing: border-box;
  }
  .checkout-step-wrapper.order-thankyou-page .step-wrapper {
    padding: 30px 0 15px 0;
  }
  .checkout-actions-tab ul {
    padding: 0;
    margin: 0;
    width: 100%;
    border: solid 1px #b7b7b7;
    box-sizing: border-box;
  }
  .checkout-actions-tab ul li {
    width: 50%;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    border-right: solid 1px #b7b7b7;
  }
  .checkout-actions-tab ul li:last-child {
    border-right: 0;
  }
  .checkout-actions-tab ul li a {
    min-width: 100%;
    width: 100%;
    display: block;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
  }
  .co-op-banner-bottom,
  .promo-banner-side-col {
    width: 100%;
  }
  .cart-item-list-wrapper {
    padding: 0;
    max-width: 100%;
  }
  .co-op-banner-bottom img {
    width: 90%;
  }
}
.loading-wrapper.no-padding {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 360px) {
  .checkout-actions-tab ul li a {
    font-size: 11px;
    padding: 13px 5px;
  }
}
.review-order-step h1.title {
  text-transform: uppercase;
  border-bottom: solid 1px #333333;
  font-size: 16px;
  margin: 15px 0;
}
.review-order-step .opt-title {
  text-align: left;
  font-size: 16px;
}
.review-order-step .md-margin {
  margin: 10px 25px 15px 0px;
}
.review-order-step .md-margin-right {
  margin: 10px 0px 15px 0px;
}
.review-order-step .progress-loading-wrapper {
  margin: 40px 0 25px 0;
}
.review-order-step .progress-loading-wrapper .layout-column {
  display: flex;
}
.order-text {
  border: none !important;
}
.order-text td {
  padding: 5px 5px 5px 0 !important;
  margin-top: 0;
}
.order-text td h3 {
  margin-top: 0;
}
.cart-checkout-wrapper {
  color: #333333;
}
.cart-checkout-wrapper p {
  font-weight: 300;
}
.cart-checkout-wrapper .tooltip-container p {
  font-weight: normal;
}
.cart-checkout-wrapper .tooltip {
  float: left;
}
.cart-checkout-wrapper .tooltip .info-icon {
  width: 19px;
  height: 19px;
  display: block;
  border: 2px solid #4399c5;
  border-radius: 50%;
  position: relative;
  padding: 1px 6px;
  box-sizing: border-box;
  font-size: 12px;
  color: #4399c5;
  margin-right: 5px;
  font-weight: 700;
  font-size: 11px;
}
.cart-checkout-wrapper .two-col .unit p.title {
  margin: 5px 15px 0 0;
  font-size: 12px;
}
.cart-checkout-wrapper .cvv-confirm .tooltip-container {
  margin-left: 15px;
}
.cart-checkout-wrapper .cvv-confirm .tooltip {
  left: -45px;
}
.cart-checkout-wrapper .cvv-confirm .tooltip.tooltip-up .tooltip-arrow {
  left: 21%;
}
.cart-checkout-wrapper .cvv-confirm .cvv-confirm-input {
  width: 66px;
  margin-top: 3px;
  padding: 0;
}
.cart-checkout-wrapper .cvv-confirm p.title {
  margin: 5px 15px 0 0;
}
.cart-checkout-wrapper .purchase-order-number {
  overflow: hidden;
}
.cart-checkout-wrapper .purchase-order-number p.title {
  margin: 5px 15px 0 0;
  font-size: 12px;
}
.cart-checkout-wrapper .purchase-order-number .purchase-order-number-input {
  width: 165px;
  margin-top: 3px;
  padding: 0;
}
.checkout-step-wrapper .discount-saving p {
  font-size: 32px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  margin: 0 0 40px 0;
}
.checkout-step-wrapper .discount-saving p strong {
  font-weight: 500;
}
.order-summary-wrapper {
  max-width: 796px;
}
.order-summary-wrapper .order-summary-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}
.order-summary-wrapper .order-summary-title h3,
.order-summary-wrapper .order-summary-title p {
  margin: 0;
}
.order-summary-wrapper .order-summary-title h3 {
  letter-spacing: 0.2px;
}
.order-summary-wrapper .order-summary-table table {
  width: 100%;
}
.order-summary-wrapper .order-summary-table table .order-summary-header {
  width: 71%;
}
.order-summary-wrapper .order-summary-table table th,
.order-summary-wrapper .order-summary-table table td {
  padding: 2px 5px 2px 0;
  min-width: 83px;
}
.order-summary-wrapper .order-summary-table table th:last-of-type,
.order-summary-wrapper .order-summary-table table td:last-of-type,
.order-summary-wrapper .order-summary-table table th.no-padding-right,
.order-summary-wrapper .order-summary-table table td.no-padding-right {
  padding-right: 0;
}
.order-summary-wrapper .order-summary-table table p {
  margin: 0;
}
.order-summary-wrapper .order-summary-table table thead {
  display: table-header-group;
}
.order-summary-wrapper .order-summary-table table thead tr {
  border-bottom: solid 1px #333333;
}
.order-summary-wrapper .order-summary-table table tbody {
  display: table-row-group;
}
.order-summary-wrapper .order-summary-table table tbody tr {
  border-bottom: solid 1px #d7d7d7;
}
.order-summary-wrapper .order-summary-table table tbody tr:first-of-type td {
  padding-top: 13px;
}
.order-summary-wrapper .order-summary-table table tbody tr:last-of-type td {
  padding-bottom: 13px;
}
.order-summary-wrapper .order-summary-table table tbody tr td {
  display: table-cell;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.1;
}
.order-summary-wrapper .order-summary-table table tbody tr td p {
  font-size: 16px;
  line-height: 1.1;
  font-weight: 300;
}
.order-summary-wrapper .order-summary-table table tbody tr td p.product-item {
  font-weight: 700;
}
.order-summary-wrapper .order-summary-table table tbody tr td.product-discount {
  text-decoration: line-through;
}
.order-summary-wrapper .order-summary-table table tbody tr a {
  color: #333333;
  font-weight: 700;
}
.order-summary-wrapper .order-summary-table table .review-order-table {
  border-bottom: solid 1px #d7d7d7;
}
.order-summary-wrapper .order-summary-table table .review-order-table tr {
  border-bottom: none;
}
.order-summary-wrapper .order-summary-table table .review-order-table tr td {
  color: #7b7b7b;
}
.order-summary-wrapper .order-summary-table table .review-order-table tr td.out-of-stock {
  color: red;
  font-weight: 400;
  font-size: 12px;
}
.order-summary-wrapper .order-summary-table table .review-order-table tr td.insufficient-quantity {
  color: #f00;
  font-weight: normal;
  font-size: 12px;
  margin-top: 11px;
}
.order-summary-wrapper .order-summary-table table .review-order-table td.text-header,
.order-summary-wrapper .order-summary-table table .review-order-table .item-total {
  color: #333333;
  padding: 0;
}
.order-summary-wrapper .order-summary-table table .review-order-table .review-discount {
  text-decoration: line-through;
}
.order-summary-wrapper .order-summary-table table .review-order-table .review-header {
  line-height: 1.3px;
}
.order-summary-wrapper .order-summary-table .insufficient-quantity {
  color: #f00;
  font-weight: normal;
  font-size: 12px;
  padding-top: 11px;
}
.order-summary-wrapper .order-summary-table .review-order {
  margin-top: 40px;
}
.order-summary-wrapper .order-summary-table .review-item-discount {
  min-width: 24px;
  width: 24px;
  position: absolute;
}
.order-summary-wrapper .order-summary-table .review-item-discount-link {
  color: #5DADE2;
}
.order-summary-wrapper .order-summary-table .review-qty {
  min-width: unset;
}
.order-summary-wrapper .order-summary-table .order-item {
  padding-top: 14px;
}
.order-summary-wrapper .order-summary-table .review-order-product {
  color: #333333;
}
.order-summary-wrapper .order-summary-table td.product-item div {
  padding-top: 4px;
}
.order-summary-wrapper .order-summary-table .discount h3,
.order-summary-wrapper .order-summary-table .discount p {
  margin: 0;
}
.order-summary-wrapper .order-summary-table .discount-number {
  margin: 3px 5px 0 0;
  font-size: 16px;
}
.order-summary-wrapper .order-summary-table .discount-text {
  padding: 3px 5px;
}
.order-summary-wrapper .order-summary-table .discount-highlight {
  background-color: #e9f6ff;
}
.order-summary-wrapper .order-summary-table .total-summary {
  margin-top: 10px;
  display: table;
}
.order-summary-wrapper .order-summary-table .total-summary tr {
  border-bottom: 0;
}
.order-summary-wrapper .order-summary-table .total-summary tr td {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.1;
  padding: 5px 15px 5px 0;
}
.order-summary-wrapper .order-summary-table .total-summary tr.total {
  border-top: solid 1px #d7d7d7;
}
.order-summary-wrapper .order-summary-table .total-summary tr.total td {
  font-weight: 700;
  padding-top: 10px;
}
.order-summary-wrapper .order-summary-table .csa-order-hold-codes {
  margin-bottom: 15px;
}
.order-summary-wrapper .order-summary-table .csa-order-hold-codes .csa-order-hold-codes-list md-checkbox .md-label {
  margin-left: 45px;
}
.order-summary-wrapper .copy-mod {
  margin-bottom: 20px;
}
.order-summary-wrapper .copy-mod h3,
.order-summary-wrapper .copy-mod p {
  font-size: 16px;
}
.order-summary-wrapper .disclaimer {
  margin: 30px 0 40px 0;
}
.copy-mod h3 {
  margin-bottom: 0;
}
.copy-mod p {
  margin: 0 0 15px 0;
}
/* layout-xs */
@media (max-width: 480px) {
  .order-summary-wrapper .order-summary-title {
    margin-bottom: 0;
  }
}
@media (max-width: 599px) {
  .order-summary-wrapper table thead {
    display: table-header-group;
  }
}
/*layout-md & layout-sm*/
@media (max-width: 959px) {
  .checkout-step-wrapper {
    margin-top: 0;
  }
  .checkout-step-wrapper md-input-container {
    margin: 18px 0 0 0;
  }
  .checkout-step-wrapper .discount-saving p {
    font-size: 20px;
    margin: 30px 0;
  }
  .review-order-step .md-margin {
    margin: 10px 25px 0px 0px;
  }
  .review-order-step h1.title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.2px;
    margin-top: 0;
  }
  .review-order-step p {
    font-size: 12px;
  }
  .order-summary-wrapper .order-summary-title h3 {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.2px;
  }
  .order-summary-wrapper .order-summary-title p {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.8;
  }
  .order-summary-wrapper .order-summary-table table tbody tr td {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
  }
  .order-summary-wrapper .order-summary-table thead th {
    font-size: 12px;
  }
  .order-summary-wrapper .order-summary-table .total-summary tr td {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .order-summary-wrapper .order-summary-table .total-summary tr td.padding-bottom {
    padding-bottom: 5px;
  }
  .order-summary-wrapper .order-summary-table .total-summary tr.total td {
    padding-top: 5px;
  }
  .order-summary-wrapper .order-summary-table .discount-number {
    font-size: 13px;
  }
  .order-summary-wrapper .disclaimer .copy-mod h3,
  .order-summary-wrapper .disclaimer .copy-mod p {
    font-size: 12px;
  }
  .order-summary-wrapper .order-summary-list-mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.87);
  }
  .order-summary-wrapper .order-summary-list-mobile .order-summary-item {
    border-bottom: 1px solid #d7d7d7;
    padding: 10px 0;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-summary-wrapper {
    color: rgba(0, 0, 0, 0.67);
    font-size: 13px;
    margin-bottom: 13px;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-summary-wrapper div {
    line-height: 1.3em;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-summary-wrapper a {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table {
    font-size: 12px;
    width: 100%;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table tr {
    border: none;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table tr:first-of-type td {
    padding-top: 0;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table tr:last-of-type td {
    padding-bottom: 0;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table .less-padding-right {
    padding-right: 2px;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table th,
  .order-summary-wrapper .order-summary-list-mobile .item-details-table td {
    text-align: center;
    padding: 0 15px 0 0;
    min-width: unset;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table th:last-of-type,
  .order-summary-wrapper .order-summary-list-mobile .item-details-table td:last-of-type {
    padding-right: 0;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table td {
    color: rgba(0, 0, 0, 0.67);
    font-size: 14px;
    font-weight: normal;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table td:nth-child(4) {
    width: unset;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-table .review-item-discount {
    right: 5px;
  }
  .order-summary-wrapper .order-summary-list-mobile .item-details-header-row {
    border: none;
  }
}
.thank-you-order-wrapper .thank-you-mod {
  text-align: center;
  padding: 50px 0 50px 0;
}
.thank-you-order-wrapper .thank-you-mod h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1;
  margin: 0 0 10px 0;
}
.thank-you-order-wrapper .thank-you-mod h2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  margin: 0;
}
.thank-you-order-wrapper .order-print {
  cursor: pointer;
}
.thank-you-order-wrapper .printorder-img {
  margin-left: 90%;
}
.thank-you-order-wrapper .order-status {
  margin: 0 10px 10px;
}
.place-order-detail {
  overflow: hidden;
  margin: 20px 0 0;
}
.place-order-detail ul {
  margin: 0;
  padding: 0;
}
.place-order-detail ul li {
  list-style: none;
  float: left;
  margin: 0 20px 0 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.order-thankyou-page .discount-saving p {
  font-size: 24px;
}
@media print {
  .summary-side-col {
    float: right !important;
    height: auto !important;
    margin: 0 !important;
    margin-top: 10% !important;
    width: 23% !important;
  }
  .printorder-img {
    margin: 14px 0 -14px 264px !important;
  }
  .print-order-mode {
    display: none !important;
  }
  .navbar-wrapper,
  div#navbar {
    border: 0;
  }
  .site-alert-messaging {
    display: none;
  }
  .place-order-detail {
    margin: 0;
    display: block !important;
  }
  .place-order-detail ul li {
    font-size: 12px;
  }
  .order-summary-table {
    width: 76% !important;
  }
  table.table.hide-xs {
    display: block !important;
  }
  .price-summary .unit {
    width: 50%;
  }
  .order-summary-wrapper .disclaimer {
    width: 130%;
  }
  .printorder-img {
    margin-bottom: 10px;
    margin-left: 85% !important;
  }
  .expirationDate {
    padding-left: 0 !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .printorder-img {
    margin-left: 83% !important;
  }
}
@media (max-width: 959px) {
  .order-thankyou-page {
    padding: 15px;
    margin-top: -25px;
  }
  .ee-container.thank-you-order-wrapper {
    padding: 0;
  }
  .ee-container.thank-you-order-wrapper .thank-you-mod {
    padding: 10px 45px 0px 10px;
  }
  .ee-container.thank-you-order-wrapper .thank-you-mod h1 {
    font-size: 24px;
  }
  .ee-container.thank-you-order-wrapper .thank-you-mod h2 {
    font-size: 12px;
  }
  .ee-container.thank-you-order-wrapper .cart-summary-wrapper {
    border: solid 1px #d7d7d7;
    box-shadow: none;
  }
  .thank-you-no-sm {
    display: none;
  }
  .navbar-wrapper {
    border-bottom: 0 !important;
  }
}
.hero-global {
  margin-top: -135px;
  width: 100%;
  overflow: hidden;
  height: 400px;
}
@media screen and (min-width: 959px) {
  .hero-global .hero-image {
    width: 100%;
  }
}
.hero-container {
  position: relative;
}
.hero-unit-global {
  position: relative;
  color: #333;
}
.hero-unit-global h1 {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.3;
  line-height: 1;
  position: absolute;
  top: 30%;
  right: 0;
  width: 50%;
  text-align: center;
}
.hero-unit-global h1 span {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
}
@media screen and (max-width: 959px) {
  .hero-unit-global h1 {
    width: 90%;
    font-size: 24px;
    left: 0;
    margin: 0 auto;
  }
}
.hero-unit-global h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
  color: #333;
}
.education-table-container {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  margin: 30px auto 0;
}
.education-table-container p {
  color: #999;
}
.education-table-container .table-left {
  border-right: 1px solid #ddd;
}
.education-table-container .table-left ul {
  padding: 0px;
  margin-right: 20px;
}
.education-table-container .table-left ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.education-table-container .table-right ul {
  padding: 0px;
  margin-left: 20px;
}
.education-table-container .table-right ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.education-table-container ul {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.education-table-container ul li {
  list-style: none;
  height: 70px;
}
.education-table-container ul li p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}
.education-table-container ul li:last-child {
  border-bottom: none;
}
.flex-row,
#value-prop-bar .product-summary,
#value-prop-bar .media-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
/* Common styling for level three pages */
.update-element-header-div {
  margin-top: 40px;
  padding-bottom: 40px;
}
.update-element-header-href {
  color: #5DADE2;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}
.update-element-header-href:hover {
  text-decoration: none;
  cursor: pointer;
}
.update-element-hearder-content-div {
  padding: 40px 0 45px;
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 959px) {
  .update-element-hearder-content-div {
    width: 90%;
  }
}
.update-bottom-div {
  border-bottom: 2px solid #ddd;
  padding: 40px 0;
}
update-element-bottom-div {
  border-bottom: 3px solid #ddd;
  padding: 40px 0;
}
.updates-h2 {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #5DADE2;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}
.updates-h2::after {
  content: ">";
  color: #ddd;
  float: right;
  font-weight: 400;
}
.element-h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #333333;
  font-family: 'Roboto', sans-serif;
  background: #f5f5f5;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid #76bd23;
}
.clinical-update-sub-nav {
  width: 71%;
  margin: 0px auto 0;
}
.clinical-update-sub-nav p {
  color: #666;
  text-align: justify;
  margin-top: 0px;
  font-family: 'Roboto', sans-serif;
  width: 90%;
  font-size: 18px;
  font-weight: 300;
}
.experts-pagination {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
}
.experts-center {
  text-align: center;
}
.author-table {
  width: 100%;
  border-bottom: 1px solid #000;
  margin: 0 auto;
}
.author-th {
  border: none;
  text-align: left;
  padding: 20px 0;
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #626567;
}
.author-td {
  border: none;
  text-align: left;
  padding: 15px 40px 15px 0px;
  color: #626567;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
}
.author-td:last-child {
  padding-right: 0px;
}
@media screen and (max-width: 959px) {
  .author-td {
    padding: 15px 4px;
    font-size: 13px;
  }
}
.row-tr {
  border-bottom: 1px solid #ddd;
}
.row-tr:hover {
  background-color: #f5f5f5;
}
.row-th {
  border-bottom: 1px solid #000;
}
.education-partners-img-href {
  width: 100%;
}
#resources-by-supplements {
  margin: 0 auto;
}
#resources-by-supplements md-tabs {
  min-height: 1270px;
}
#resources-by-supplements md-tabs-canvas {
  width: 45%;
  margin: 0 auto;
}
@media screen and (max-width: 599px) {
  #resources-by-supplements md-tab-item {
    width: 100%;
  }
}
#resources-by-supplements md-tab-item.md-tab {
  background-color: #f5f5f5;
  box-sizing: border-box;
  float: left;
  text-align: center;
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 7px 24px;
}
#resources-by-supplements md-tab-item.md-tab.md-active {
  color: #fff;
  background-color: #4399c5;
}
html body md-toast[class*="md"] {
  position: fixed;
  top: 50%;
  bottom: auto;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.account-toggle-btn {
  float: right;
}
.account-toggle-btn.active {
  transform: rotate(-180deg);
}
.account-toggle-btn-header {
  margin: auto;
}
.account-toggle-btn-a {
  margin: 30%;
}
._720kb-datepicker-calendar-day._720kb-datepicker-today {
  background: #0e98df;
  color: white;
}
datepicker a,
[datepicker] a,
.datepicker a {
  color: inherit;
  text-decoration: none;
}
datepicker a:hover,
[datepicker] a:hover,
.datepicker a:hover {
  text-decoration: none;
}
datepicker select,
datepicker select:focus,
datepicker select:hover,
.datepicker select,
.datepicker select:focus,
.datepicker select:hover,
[datepicker] select,
[datepicker] select:focus,
[datepicker] select:hover {
  width: 100%;
  overflow: hidden;
  background: none;
  color: #fff;
  background-color: #138EFA;
  border-radius: 2px;
  border: 0;
  margin-top: 5px;
}
datepicker,
.datepicker,
[datepicker],
._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-body,
._720kb-datepicker-calendar-days-header,
._720kb-datepicker-calendar-years-pagination-pages {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 13.5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  float: left;
  clear: right;
  position: relative;
}
._720kb-datepicker-calendar {
  background: white;
  color: #333;
  position: absolute;
  z-index: 999;
  min-width: 220px;
  margin: 0 auto;
  width: 101%;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  visibility: hidden;
  overflow: hidden;
  margin-left: -0.5%;
  padding: 0 0 2% 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
._720kb-datepicker-calendar._720kb-datepicker-open,
._720kb-datepicker-calendar._720kb-datepicker-forced-to-open {
  visibility: visible;
}
._720kb-datepicker-calendar-header {
  text-align: center;
  font-size: 15px;
  line-height: 40px;
}
._720kb-datepicker-calendar-header:nth-child(odd) {
  background: #138EFA;
}
._720kb-datepicker-calendar-header:nth-child(even) {
  background: #7BC6FC;
}
._720kb-datepicker-calendar-header-left,
._720kb-datepicker-calendar-header-middle,
._720kb-datepicker-calendar-header-right {
  width: 15%;
  float: left;
}
._720kb-datepicker-calendar-header-middle {
  width: 70%;
}
._720kb-datepicker-calendar-header-closed-pagination::after {
  content: " \25BE";
}
._720kb-datepicker-calendar-header-opened-pagination::after {
  content: " \25BE";
  margin-left: 4px;
  position: relative;
  bottom: -3px;
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
._720kb-datepicker-calendar-body {
  width: 96%;
  margin: 2%;
  text-align: center;
}
._720kb-datepicker-calendar-day {
  cursor: pointer;
  font-size: 12.5px;
  width: 12.2%;
  margin: 5px 1%;
  padding: 1.5% 0;
  float: left;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
._720kb-datepicker-calendar-day:hover,
._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background: rgba(0, 0, 0, 0.03);
}
._720kb-datepicker-calendar-header a,
._720kb-datepicker-calendar-header a:hover {
  text-decoration: none;
  padding: 3% 9% 4% 9%;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.55);
  font-weight: bold;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
._720kb-datepicker-calendar-header a:hover {
  color: rgba(0, 0, 0, 0.9);
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-month {
  color: #fff;
}
._720kb-datepicker-calendar-month span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}
._720kb-datepicker-calendar-month a span i {
  font-style: normal;
  font-size: 15px;
}
._720kb-datepicker-calendar-month a,
._720kb-datepicker-calendar-month a:hover {
  padding: 3px;
  margin-left: 1%;
}
._720kb-datepicker-calendar-years-pagination {
  padding: 2% 0 0 0;
  float: left;
  clear: right;
  width: 100%;
}
._720kb-datepicker-calendar-years-pagination a,
._720kb-datepicker-calendar-years-pagination a:hover {
  font-size: 12px;
  padding: 0 7px;
  font-weight: normal;
  margin: 3px 1% 0 1%;
  line-height: 20px;
  display: inline-block;
}
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  background: rgba(255, 255, 255, 0.45);
}
._720kb-datepicker-calendar-years-pagination-pages a,
._720kb-datepicker-calendar-years-pagination-pages a:hover {
  padding: 5px 10px;
}
._720kb-datepicker-calendar-days-header {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2% 0 2%;
  background: rgba(19, 142, 250, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
}
._720kb-datepicker-calendar-days-header div {
  width: 14.18%;
  font-weight: 500;
  font-size: 11.5px;
  padding: 10px 0;
  float: left;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}
._720kb-datepicker-calendar-days ._720kb-datepicker-default-button {
  font-size: 18.5px;
  position: relative;
  bottom: -0.5px;
}
._720kb-datepicker-default-button {
  padding: 0 4.5px;
}
._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item {
  width: 95%;
  float: none;
  margin: 0 auto;
}
._720kb-datepicker-item-hidden {
  visibility: hidden;
}
._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover {
  color: rgba(0, 0, 0, 0.2);
  background: rgba(25, 2, 0, 0.02);
  cursor: default;
}
md-dialog.md-transition-in {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}
.strikeOriginalShipPrice {
  text-decoration: line-through;
  font-style: normal;
}
.ee-free {
  display: block;
  font-style: normal;
}
[ng-messages] div[ng-message],
div.ccexpired-error {
  font-weight: bold;
  font-size: 14px;
}
.csa-stock-info-dialog {
  max-width: 50%;
  min-width: 650px;
  color: rgba(0, 0, 0, 0.87);
}
.csa-stock-info-dialog .layout-column {
  display: flex;
}
.csa-stock-info-dialog .close-button {
  background-color: #fff;
  border: none;
  font-size: 29px;
  width: 27px;
  height: 27px;
  line-height: 2px;
  margin-right: 4px;
  outline: none;
  color: rgba(0, 0, 0, 0.38);
}
.csa-stock-info-dialog .close-button:hover {
  color: rgba(0, 0, 0, 0.54);
}
.csa-stock-info-dialog md-dialog-content {
  padding: 5px 0;
}
.csa-stock-info-dialog md-dialog-actions {
  justify-content: center;
  padding: 0;
  padding-top: 5px;
}
.csa-stock-info-dialog md-dialog-actions > .md-button {
  min-width: 185px;
}
.csa-stock-info-dialog md-dialog-actions a.md-button:hover {
  text-decoration: none;
}
.csa-stock-info-dialog md-dialog-actions .outline-button {
  background-color: #fff;
  color: hsl(88, 69%, 37%);
  border: 1px solid;
}
.csa-stock-info-dialog md-dialog-actions .outline-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.csa-stock-info-dialog .loading-div md-progress-circular {
  margin: 15px auto 0 auto;
}
.csa-stock-info-dialog .loading-div p {
  text-align: center;
}
.csa-stock-info-dialog .error-state-wrapper {
  padding: 24px;
}
.csa-stock-info-dialog .error-state-wrapper h2 {
  font-size: 26px;
  margin-bottom: 0;
}
.csa-stock-info-dialog .error-state-wrapper h4 {
  margin-top: 10px;
}
.csa-stock-info-dialog .stock-info-content h4 {
  margin-top: -18px;
  margin-bottom: 15px;
  text-align: center;
}
.csa-stock-info-dialog .stock-info-content .stock-info-details-wrapper {
  padding: 0 35px 10px 35px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.67);
}
.csa-stock-info-dialog .stock-info-content .stock-info-details-wrapper .stock-info-item-details .data-label {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}
.csa-stock-info-dialog .stock-info-content .stock-info-details-wrapper .stock-info-lot-details {
  display: inline-block;
  margin-right: 40px;
}
.csa-stock-info-dialog .stock-info-content .stock-info-details-wrapper .stock-info-lot-details:not(:last-of-type) {
  margin-bottom: 15px;
}
.ask-our-expert-h2 {
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  color: #333333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 25px;
}
.info-expert {
  text-align: center;
}
.img-circle {
  border-radius: 50%;
  width: 67%;
  display: block;
  margin: 0 auto 10px;
}
.ask-our-expert-container {
  width: 75%;
  margin: 60px auto 0;
}
.ask-our-expert-container div {
  text-align: center;
}
@media screen and (max-width: 959px) {
  .ask-our-expert-container {
    width: 100%;
    margin: 57px auto 0;
  }
}
.ask-our-expert-img-href {
  text-decoration: none;
  color: #5DADE2;
  font-weight: 500;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
}
.ask-our-expert-img-href:hover {
  text-decoration: none;
}
.update-meet-our-team-header-div {
  width: 100%;
  padding-bottom: 40px;
  padding-top: 25px;
}
.meet-our-team-img {
  width: 100%;
}
.meet-our-team-content {
  margin-left: 30px;
  vertical-align: top;
  border-bottom: 1px solid #ddd;
}
.meet-our-team-h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
}
.meet-our-team-content-div {
  margin-bottom: 55px;
}
.meet-our-team-content-p {
  width: 100%;
}
.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: 0.25s ease;
}
.showmore-href {
  text-decoration: none;
  color: #5DADE2;
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}
.showmore-href:hover {
  text-decoration: none;
}
.ask-quest-topic-td,
.ask-quest-date-td,
.ask-quest-subject-td {
  border: none;
  text-align: left;
  padding: 15px 0;
  color: #626567;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  width: 30%;
}
@media screen and (max-width: 959px) {
  .ask-quest-topic-td,
  .ask-quest-date-td,
  .ask-quest-subject-td {
    font-size: 13px;
    padding: 15px 4px;
  }
}
.ask-quest-date-td {
  width: 20%;
}
.ask-quest-subject-td {
  width: 50%;
}
.type-your-qust-input {
  width: 80%;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #C0C0C0;
  background: #F8F8F8;
}
.type-your-qust-input:focus {
  height: 100px;
}
.type-your-qust-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #669933;
  font-size: 25px;
}
.type-your-qust-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #669933;
  font-size: 25px;
}
.type-your-qust-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #669933;
  font-size: 25px;
}
.type-your-qust-input:-moz-placeholder {
  /* Firefox 18- */
  color: #669933;
  font-size: 25px;
}
@media screen and (max-width: 959px) {
  .type-your-qust-input {
    width: 100%;
    height: 100%;
  }
  .type-your-qust-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #669933;
    font-size: 10px;
  }
  .type-your-qust-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #669933;
    font-size: 10px;
  }
  .type-your-qust-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #669933;
    font-size: 10px;
  }
  .type-your-qust-input:-moz-placeholder {
    /* Firefox 18- */
    color: #669933;
    font-size: 10px;
  }
}
.type-your-qust-container {
  background: #F8F8F8;
  width: 71%;
  margin: 50px auto;
}
@media screen and (max-width: 959px) {
  .type-your-qust-container {
    width: 100%;
  }
}
.type-your-qust-wrapper {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding: 55px 0 65px;
}
@media screen and (max-width: 959px) {
  .type-your-qust-wrapper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 36px 0 20px;
  }
}
.type-your-qust-submit {
  float: right;
  vertical-align: bottom;
  background-color: transparent;
  border: none;
  color: #669933;
  font-size: 18px;
  cursor: pointer;
  margin-right: 32px;
  margin-top: 7px;
}
@media screen and (max-width: 959px) {
  .type-your-qust-submit {
    float: none;
    font-size: 14px;
  }
}
.ask-our-expert-pagination-href {
  text-decoration: none;
  color: #5DADE2;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}
.ask-our-expert-pagination-href:hover {
  text-decoration: none;
}
.ask-our-expert-pagination-href.disable {
  color: #ddd;
  cursor: default;
}
.ask-our-expert-pagination-href-p {
  text-decoration: none;
  color: #5DADE2;
}
.ask-our-expert-pagination-href-p.active {
  color: #000;
}
.ask-our-expert-arrow-left {
  transform: rotate(90deg);
  width: 130%;
}
.ask-our-expert-arrow-right {
  transform: rotate(-90deg);
  width: 130%;
  cursor: pointer;
}
.ask-quest-img-td {
  vertical-align: top;
}
.ask-quest-td-img-more {
  width: 30px;
}
.ask-quest-td-img-more.active {
  transform: rotate(-180deg);
}
.ask-quest-span-text {
  font-weight: bold;
}
.ask-quest-table-div {
  padding: 0;
}
.disclaimer-content-p {
  color: #666;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}
.disclaimerContent-head {
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.disclaimerContent-dot {
  color: #666;
  text-decoration: none;
}
.expert-title {
  font-size: 14px;
  display: block;
  margin: 5px 0;
}
.expert-title-bold {
  font-weight: bold;
}
@media only screen and (max-width: 800px) {
  .author-table {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .author-table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .author-table tr {
    display: block;
    position: relative;
  }
  .author-table td {
    display: inline-block;
  }
  .author-table .ask-quest-img-td {
    width: 100%;
    text-align: right;
  }
  .author-table .ask-quest-date-td,
  .ask-quest-topic-td {
    width: 49%;
  }
  .ask-quest-subject-td {
    width: 100%;
    padding-top: 10px;
  }
  .ask-quest-subject-td p {
    margin-top: 0;
  }
}
body.md-toast-animating {
  overflow: auto !important;
}
/* Styling for Health Condition List */
ol.healthConditionList {
  list-style: none;
  padding: 0;
}
ol.healthConditionList li {
  display: inline-block;
  padding: 0 30px;
  text-transform: uppercase;
}
ol.healthConditionList li:first-child {
  padding-left: 0;
}
input[type="search"] {
  float: right;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  height: 100%;
  width: 80%;
}
input[type="search"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #3592c7;
}
input[type="search"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #3592c7;
}
input[type="search"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #3592c7;
}
input[type="search"]:-moz-placeholder {
  /* Firefox 18- */
  color: #3592c7;
}
input[type="search"]:focus {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #d7d7d7;
}
.resource-h2::after {
  content: ">";
  color: #c2c2c2;
  float: right;
  font-weight: 500;
}
.clinical-resource-sub-content-p {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
}
@media screen and (max-width: 959px) {
  .clinical-resource-sub-content-p {
    margin-top: 10px;
  }
}
.update-sub-head-h2 {
  font-weight: 400;
  font-size: 32px;
  padding-top: 10px;
  text-align: center;
}
.education-patners-sub-content-p {
  margin: 0;
}
.clinical-update-sub-page-head {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 18px;
}
.clinical-tools .clinical-practice-tools a.practice-tools-pdf span {
  background: url("../assets/images/icons/download.png") no-repeat;
  background-position: right;
  display: block;
  width: 25px;
  height: 25px;
  float: right;
}
#resources-by-supplements md-pagination-wrapper {
  width: 100% !important;
  /* To override md-pagination-wrapper width which is coming from angular material */
}
@media screen and (max-width: 959px) {
  #resources-by-supplements md-tabs-canvas {
    width: 60%;
  }
}
@media screen and (min-width: 959px) {
  #resources-by-supplements md-tab-item.md-tab {
    width: 50%;
  }
}
@media screen and (max-width: 959px) and (orientation: landscape) {
  #resources-by-supplements md-tabs-canvas {
    width: 67%;
  }
}
@media screen and (max-width: 599px) {
  #resources-by-supplements md-tabs-content-wrapper {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 959px) {
  .clinical-tools .clinical-practice-tools a.practice-tools-pdf span {
    position: absolute;
    right: -4px;
    top: 3px;
    width: 22px;
    height: 27px;
    background-size: contain;
  }
}
.author-href {
  width: 30%;
  color: #5DADE2;
  font-weight: 500;
}
.author-href:hover {
  text-decoration: none;
  cursor: pointer;
}
.update-element-bottom-div {
  border-bottom: 3px solid #ddd;
  padding: 40px 0;
}
.author-table-div {
  padding: 0;
}
.active-page-number.active {
  color: #000;
}
.prev-page-button,
.next-page-button {
  cursor: pointer;
}
.prev-page-button.disable,
.next-page-button.disable {
  color: #ddd;
  cursor: default;
}
.author-table-div .author-th {
  text-transform: none;
}
/*Emerson Element */
.emerson-elememt-container h2 {
  text-align: left;
  font-size: 32px;
  font-weight: 300;
}
.emerson-elememt-container .element-list {
  border-top: 1px solid #ddd;
}
.emerson-elememt-container .element-list div {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px;
}
.emerson-elememt-container .element-list div:nth-child(2n) {
  border-right: 0;
}
.elements-table {
  width: 90%;
  border-bottom: 1px solid #000;
  margin: 0 auto;
}
.elements-th {
  border: none;
  text-align: left;
  padding: 20px 0;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  color: #626567;
  font-weight: 300;
  font-size: 35px;
}
.elements-td {
  border: none;
  text-align: left;
  padding: 15px 0;
  color: #5DADE2;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  font-weight: 500;
}
.elements-td:hover {
  text-decoration: none;
  cursor: pointer;
}
.elements-row-tr {
  border-bottom: 1px solid #ddd;
}
.elements-row-tr:first-child {
  border-top: 2px solid #ddd;
}
.elements-row-tr:hover {
  background-color: #f5f5f5;
}
tr td:nth-child(4) {
  width: 30%;
  color: #5DADE2;
  font-weight: 500;
}
.update-element-bottom-div {
  border-bottom: 3px solid #ddd;
  padding: 40px 0;
}
.elements-p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
}
.elements-download-icon {
  float: right;
}
.elements-download-content {
  float: left;
  width: 85%;
}
.elements-download-table {
  display: block;
}
#tap-integrative {
  padding: 10px;
  margin-bottom: 30px;
}
#tap-integrative .tap-integrative-header {
  padding-left: 10px;
}
#tap-integrative .tap-integrative-header h3 {
  margin: 0 30%;
}
@media screen and (max-width: 959px) {
  #tap-integrative .tap-integrative-header h3 {
    margin: 0 auto;
  }
}
#tap-integrative .tap-integrative-image {
  padding: 10px;
}
#tap-integrative .tap-integrative-image img {
  width: 100%;
}
#tap-integrative .tap-integrative-content-level1 {
  padding: 10px;
}
#tap-integrative .tap-integrative-content-level1 a.become-member {
  color: #3592c7;
  text-decoration: underline;
}
#tap-integrative .tap-integrative-header_level2 h3 {
  margin: 30px auto;
}
#tap-integrative .tap-content-header {
  margin-top: 15px;
}
#tap-integrative .tap-content-header h5 {
  font-size: 18px;
  margin: 0 auto;
}
#tap-integrative .table-left p,
#tap-integrative .table-right p {
  text-align: center;
}
#tap-integrative .education-table-container {
  margin: 0 auto 0;
}
#tap-integrative .education-table-container ul li {
  list-style: none;
  height: 27px;
  padding: 20px;
}
.tap-video video {
  width: 100%;
  height: auto;
}
md-dialog.tap-media-dialog {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  width: 80%;
  height: auto;
}
@media screen and (max-width: 959px) {
  #education-emerson ul li.clinical-resources-layout {
    margin: 0;
  }
}
#education-emerson ul li.clinical-resources-layout h2 {
  font-size: 18px;
  font-weight: 500;
  color: #3592c7;
  border-bottom: 2px solid #c2c2c2;
  text-align: left;
  padding-bottom: 12px;
}
@media screen and (max-width: 959px) {
  #education-emerson ul li.clinical-resources-layout h2 {
    margin: 0;
  }
}
#education-emerson ul li.clinical-resources-layout h2 a {
  color: #3592c7;
  font-weight: 500;
}
#education-emerson ul li.clinical-resources-layout h2 a.education-sub-nav-active {
  color: #000;
  font-weight: 500;
}
#education-emerson .education-sub-nav {
  border-top: 1px solid #ddd;
  width: 75%;
  margin: 30px auto 0;
}
#education-emerson .education-sub-nav ul li .list-flex-content {
  padding: 0;
}
#education-emerson .education-sub-nav ul li h2 {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
#education-emerson .education-sub-nav p {
  color: #666;
  text-align: justify;
  margin-top: 30px;
}
#education-emerson .login-message {
  font-size: 16px;
  font-weight: 400;
}
#education-emerson .login-message,
#education-emerson .alert-message {
  text-align: center;
  margin: 115px 0;
}
#education-emerson .login-message img,
#education-emerson .alert-message img {
  vertical-align: bottom;
}
#education-emerson .login-message a,
#education-emerson .alert-message a {
  cursor: pointer;
}
#heroedu1 {
  top: 50%;
}
#heroedu2 {
  top: 50%;
}
#heroedu3 {
  top: 50%;
}
.education-sub-nav .level-2-content {
  display: none;
}
.resources-by-health,
.resources-by-supplements,
.clinical-practice-tools-head {
  font-weight: 300;
  font-size: 19px;
  width: 75%;
  margin: 0px auto 15px;
  padding-bottom: 15px;
}
.clinical-tools {
  border-bottom: 2px solid #ddd;
}
.clinical-tools h2 {
  text-align: left;
}
@media screen and (max-width: 599px) {
  .clinical-tools h2 {
    margin-left: 10px;
  }
}
.clinical-tools .clinical-practice-tools {
  border-top: 2px solid #ddd;
  margin: 30px auto 0;
}
.clinical-tools .clinical-practice-tools a {
  color: #3592c7;
}
.clinical-tools .clinical-practice-tools ul {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.clinical-tools .clinical-practice-tools ul li {
  list-style: none;
  min-height: 70px;
  position: relative;
}
.clinical-tools .clinical-practice-tools ul li p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
}
.clinical-tools .clinical-practice-tools ul li:last-child {
  border-bottom: none;
}
.clinical-tools .clinical-practice-tools .table-left-part {
  border-right: 1px solid #ddd;
}
.clinical-tools .clinical-practice-tools .table-left-part ul {
  padding: 0px;
  margin-right: 20px;
}
.clinical-tools .clinical-practice-tools .table-left-part ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
@media screen and (max-width: 599px) {
  .clinical-tools .clinical-practice-tools .table-left-part ul li {
    padding: 10px;
  }
}
.clinical-tools .clinical-practice-tools .table-right-part ul {
  padding: 0px;
  margin-left: 20px;
}
.clinical-tools .clinical-practice-tools .table-right-part ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.clinical-tools .clinical-practice-tools li.last-child {
  color: red;
}
.clinical-tools .last-clinical-tool {
  border-bottom: 2px solid #ddd;
}
.clinical-tools .last-clinical-tool ul li:last-child {
  border-bottom: none;
}
.md-tabs-wrapper-margin-top {
  margin: 40px 0;
}
.education-md-tabs-wrapper-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.files-list .file-name {
  padding: 0;
  color: #3592c7;
}
.files-list .download-img {
  height: 35px;
  max-width: 6%;
}
.tabs-wrapper ul.md-tabs-wrapper-margin-top {
  list-style: none;
  width: 95%;
  margin: 40px auto;
  margin-bottom: 0;
  padding-left: 0;
  text-align: center;
}
.tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs {
  border-top: 1px solid #76bd23;
  padding-top: 10px;
  max-width: 174px;
  outline: none;
}
.tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs.active_tab {
  border-top: 3px solid #76bd23;
  position: relative;
  top: -2px;
  padding-top: 12px;
}
.tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs.active_tab a {
  color: #000;
}
.tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs a {
  width: 64%;
  margin: 0 auto;
  display: block;
  white-space: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-top: 0;
  color: #999;
}
.tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs a:hover {
  text-decoration: none;
}
.clinical-resources-subpage-container {
  width: 100%;
}
.clinical-resources-subpage-container iframe {
  border: 0;
  height: 1100px;
  width: 100%;
  border-bottom: 1px solid #999;
}
.clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top {
  list-style: none;
  width: 50%;
  margin: 40px auto;
  padding-left: 0;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top {
    width: 100%;
  }
}
.clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs {
  border-top: 1px solid #76bd23;
  padding-top: 10px;
  max-width: 174px;
  outline: none;
}
@media screen and (max-width: 599px) {
  .clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs {
    padding: 10px 5px;
    max-width: none;
    border-bottom: 1px solid #76bd23;
  }
}
.clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs.active_tab {
  border-top: 3px solid #76bd23;
}
.clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs.active_tab a {
  color: #000;
  outline: none;
}
.clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs a {
  width: 64%;
  margin: 0 auto;
  display: block;
  white-space: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-top: 0;
  color: #999;
}
.clinical-resources-subpage-container .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs a:hover {
  text-decoration: none;
}
@media screen and (max-width: 959px) {
  .education-md-tabs-wrapper-padding.tab-body-wrapper,
  .education-md-tabs-wrapper-padding .list-flex-item {
    padding-left: 0px;
    padding-right: 0px;
  }
  .tabs-wrapper ul.md-tabs-wrapper-margin-top {
    width: auto;
    margin: 40px auto 0px;
    max-width: 525px;
  }
  .tabs-wrapper ul.md-tabs-wrapper-margin-top li.eductaion-tabs {
    padding-bottom: 10px;
    max-width: none;
  }
}
.clinical-page {
  height: 450px;
}
.education-alert-message {
  vertical-align: middle;
  background-repeat: no-repeat;
  padding: 0 0 0 24px;
}
.practice-management-subpage-content p {
  width: 75%;
  color: #666;
  text-align: justify;
  margin: 0 auto 0;
}
.practice-management-subpage-content .education-table-container ul li p {
  width: 100%;
}
@media screen and (max-width: 599px) {
  .practice-management-subpage-content .education-table-container ul li .files-list .file-name {
    font-size: 16px;
  }
}
.practice-management-subpage-content .lt-container.education-table-container .table-right ul li {
  border-bottom: 1px solid #ddd;
}
.practice-management-subpage ul {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.practice-management-subpage ul li {
  padding: 0 20px 20px 20px;
  height: auto;
  min-height: 53px;
}
.practice-management-subpage ul li:nth-child(odd) {
  border-right: 1px solid #ddd;
}
.practice-management-subpage ul li > div {
  padding-top: 20px;
  border-top: 1px solid #ddd;
}
.practice-management-subpage ul li:nth-child(-n+2) > div {
  border-top: 0;
}
.view-video a:after,
.download-file a:after {
  content: '';
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.download-file a:after {
  width: 25px;
  height: 26px;
  background-image: url(/assets/images/icons/download.png);
}
.view-video a:after {
  width: 25px;
  height: 20px;
  background-image: url(/assets/images/icons/play_video.png);
}
.elementstd.file-name {
  position: relative;
  padding-right: 40px;
}
@media screen and (max-width: 599px) {
  .education-table-container ul {
    display: block;
  }
  .education-table-container ul li {
    width: 100%;
    max-width: none;
  }
  .education-table-container ul li:nth-child(odd) {
    border-right: 0;
  }
  .education-table-container ul li:nth-child(2) > div {
    border-top: 1px solid #ddd;
  }
}
/*Input styling*/
md-input-container.md-default-theme .md-input,
md-input-container .md-input {
  color: rgba(0, 0, 0, 0.87);
  border-color: rgba(0, 0, 0, 0.12);
}
md-input-container .md-input {
  color: #666666;
}
md-input-container .md-placeholder,
md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  color: #D3D3D3;
}
md-input-container.md-input-focused label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-input-has-value label:not(.md-no-float) {
  color: #999999;
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon,
md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(0, 0, 0, 0.38);
}
md-checkbox .md-container {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  right: auto;
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon,
md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(0, 0, 0, 0.38);
}
md-checkbox.md-checked .md-icon:after {
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 4.66667px;
  top: 0.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: '';
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon:after,
md-checkbox[disabled].md-checked .md-icon:after {
  border-color: #eeeeee;
}
md-checkbox.md-default-theme[disabled] .md-icon:after,
md-checkbox[disabled] .md-icon:after {
  border-color: rgba(0, 0, 0, 0.38);
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon,
md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(0, 0, 0, 0.38);
}
md-input-container .input-message {
  font-size: 14px;
  color: #333333;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*checkbox*/
md-checkbox .md-icon {
  border-radius: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #4399c5;
}
md-checkbox.md-checked .md-icon:after {
  left: 5.66667px;
  top: 1.22222px;
  color: #4399c5;
}
md-checkbox.md-checked .md-icon {
  border: 1px solid #4399c5;
}
md-checkbox.md-square .md-icon {
  border-radius: 0;
  border: 1px solid #4399c5;
  width: 20px;
  height: 20px;
}
md-checkbox.md-square .md-icon:after {
  left: 5.66667px;
  top: 1.22222px;
}
md-checkbox.md-square .md-label span {
  color: #4399c5;
  font-weight: 300;
}
md-checkbox.md-circle .md-container {
  width: 27px;
  height: 27px;
}
md-checkbox.md-circle .md-icon {
  border-radius: 50%;
  border: 1px solid #4399c5;
  width: 27px;
  height: 27px;
}
md-checkbox.md-circle .md-icon:after {
  left: 8.66667px;
  top: 4.22222px;
}
md-checkbox.md-circle .md-label span {
  color: #4399c5;
  font-weight: 300;
}
md-checkbox.medium .md-container {
  width: 27px;
  height: 27px;
}
md-checkbox.medium .md-icon {
  width: 27px;
  height: 27px;
}
md-checkbox.medium .md-icon:after {
  left: 8.66667px;
  top: 4.22222px;
}
md-checkbox.medium .md-label {
  margin-left: 40px;
}
/*select*/
.md-select-menu-container.md-active {
  z-index: 300;
}
md-select.md-no-underline .md-select-value {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 10px;
  border-radius: 5px;
}
md-select-menu.md-default-theme md-content md-option,
md-select-menu md-content md-option {
  color: rgba(33, 33, 33, 0.87);
}
md-select-menu.md-default-theme md-content md-option[selected],
md-select-menu md-content md-option[selected] {
  color: #666666;
}
md-option {
  color: #999999;
}
md-option:focus,
md-option:hover {
  background-color: #f5f5f5;
}
/*auto complete*/
.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 300;
}
.md-virtual-repeat-container.md-autocomplete-suggestions-container ul li:focus,
.md-virtual-repeat-container.md-autocomplete-suggestions-container ul li:hover {
  background-color: #f5f5f5;
}
.md-virtual-repeat-container.md-autocomplete-suggestions-container ul li.selected {
  color: #0e98df;
  background-color: #f5f5f5;
}
md-input-container textarea.md-input {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
}
@media (max-width: 599px) {
  .md-select-menu-container.md-active {
    margin-top: 140px;
  }
}
body,
html {
  font-family: 'Roboto', sans-serif;
}
p {
  color: #333333;
  margin: 1em 0;
  line-height: 1.3;
}
.primary-color {
  color: #76bd23;
}
.secondary-color {
  color: #4399c5;
}
a,
a.primary {
  color: #669933;
  text-decoration: none;
}
a:hover,
a.primary:hover {
  color: #000000;
  text-decoration: underline;
}
a.disabled,
a.primary.disabled {
  color: #BEBEBE;
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}
a.secondary {
  color: #4399c5;
}
a.secondary.disabled {
  color: #BEBEBE;
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}
.ee-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  .ee-container {
    max-width: 1140px;
    width: 100%;
  }
}
sup {
  font-size: 0.6em !important;
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  bottom: 1ex;
}
.strong,
strong {
  font-weight: 700;
}
.underline {
  text-decoration: underline;
}
.thing-form {
  margin: 20px 0;
}
.word-break {
  word-wrap: break-word;
}
.top-border {
  border-top: solid 1px #333333;
}
.uppercase {
  text-transform: uppercase;
}
#banner {
  border-bottom: none;
}
#banner h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
  margin-top: 150px;
}
.navbar-text {
  margin-left: 15px;
}
.mt120 {
  padding-top: 120px !important;
}
.pt120 {
  padding-top: 120px !important;
}
.nowrap {
  white-space: nowrap;
}
.btn-primary:hover:not(:disabled) {
  background-color: #000000;
  border-color: #000000;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled.unit li {
  display: inline-block;
  margin-right: 20px;
}
.list-unstyled.unit li.left-divider {
  border-left: solid 1px #b7b7b7;
  padding-left: 20px;
}
.interier-wrapper .header-title {
  border-bottom: 1px solid #000000;
  font-size: 16px;
}
.interier-wrapper a {
  color: #4399c5;
}
.interier-wrapper h4 {
  margin: 10px 0;
  font-weight: 400;
}
.interier-wrapper p {
  margin: 10px 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/*layout-sm*/
@media (max-width: 959px) {
  .page-wrapper {
    padding-top: 0;
  }
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.button-center .md-button {
  margin: 0 auto;
}
.button-center-wrapper {
  margin: 0 auto;
}
/*layout-sm*/
@media (max-width: 959px) {
  .text-right-sm {
    text-align: right !important;
  }
  .text-left-sm {
    text-align: left !important;
  }
  .text-center-sm {
    text-align: center !important;
  }
}
/*layout-xs*/
@media (max-width: 599px) {
  .text-right-xs {
    text-align: right !important;
  }
  .text-left-xs {
    text-align: left !important;
  }
  .text-center-xs {
    text-align: center !important;
  }
}
/*media*/
.media-list {
  padding-left: 0;
  list-style: none;
}
.media,
.media-body {
  zoom: 1;
  overflow: hidden;
}
.media:first-child {
  margin-top: 0;
}
.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
/*button*/
.md-button {
  letter-spacing: 0.2px;
}
.md-button,
.md-button.md-primary {
  background-color: #76bd23;
  color: #ffffff;
  border-radius: 0;
  padding: 0 15px;
}
.md-button.md-icon-button,
.md-button.md-primary.md-icon-button {
  background-color: #ffffff;
}
.md-button[disabled],
.md-button.md-primary[disabled] {
  background-color: #BEBEBE;
  color: #FFFFFF;
  pointer-events: none;
  cursor: default;
}
.md-button:hover,
.md-button.md-primary:hover {
  color: #ffffff;
  background-color: #000000;
}
.md-button md-progress-circular path,
.md-button.md-primary md-progress-circular path {
  stroke: #ffffff;
}
.md-button.white {
  background-color: #ffffff;
  color: #76bd23;
  border-radius: 0;
  padding: 0 15px;
  border: 1px solid #76bd23;
}
.md-button.white[disabled] {
  background-color: #BEBEBE;
  color: #FFFFFF;
  border: 1px solid #BEBEBE;
  pointer-events: none;
  cursor: default;
}
.md-button.white:hover {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
}
a.md-button,
a.md-button.md-primary {
  white-space: normal;
  line-height: 1.1;
  padding: 10px;
}
a.md-button:hover,
a.md-button.md-primary:hover {
  color: #ffffff;
  background-color: #000000;
}
.md-button.md-no-bg {
  background-color: transparent;
  color: #669933;
  border-radius: 0;
  box-shadow: none !important;
}
.md-button.md-no-bg:hover {
  color: #000000;
  background-color: transparent;
  text-decoration: underline;
}
.md-button.md-secondary {
  color: #ffffff;
  background-color: #4399c5;
}
.md-button.md-secondary[disabled] {
  background-color: #BEBEBE;
  color: #FFFFFF;
  pointer-events: none;
  cursor: default;
}
.md-button.md-secondary md-progress-circular path {
  stroke: #ffffff;
}
.md-button.cancel-remove,
.md-button.md-cancel {
  background-color: #ffffff;
  color: #76bd23;
  border-radius: 0;
  padding: 0 15px;
  border: 1px solid #76bd23;
}
.md-button.cancel-remove[disabled],
.md-button.md-cancel[disabled] {
  background-color: #BEBEBE;
  color: #FFFFFF;
  border: 1px solid #BEBEBE;
  pointer-events: none;
  cursor: default;
}
.md-button.cancel-remove:hover,
.md-button.md-cancel:hover {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
}
.md-button.confirm-remove {
  background-color: #76bd23;
  color: #ffffff;
  border-radius: 0;
  padding: 0 15px;
}
.md-button.confirm-remove.md-icon-button {
  background-color: #ffffff;
}
.md-button.confirm-remove[disabled] {
  background-color: #BEBEBE;
  color: #FFFFFF;
  pointer-events: none;
  cursor: default;
}
.md-button.confirm-remove:hover {
  color: #ffffff;
  background-color: #000000;
}
.md-flat.md-button {
  background-color: transparent;
  color: #669933;
}
.md-flat.md-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
  color: #000000;
}
.md-flat.md-button[disabled] {
  background-color: transparent;
  color: #BEBEBE;
  pointer-events: none;
  cursor: default;
}
.md-button.add-more-btn {
  background-color: #f6f6f6;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #666666;
  margin: 0;
}
.md-button.no-style {
  min-width: auto;
  width: auto;
  background-color: transparent;
  padding: 0 10px;
  margin: 0;
  min-height: auto;
  height: auto;
}
.upload-button {
  background-color: #4399c5;
  border: 1px solid #4399c5;
  border-radius: 0;
  cursor: pointer;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  min-height: 36px;
  min-width: 140px;
  line-height: 36px;
  display: inline-block;
}
.upload-button:hover {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
}
.upload-button[disabled],
.upload-button.disabled {
  background-color: #BEBEBE;
  color: #FFFFFF;
  border: 1px solid #BEBEBE;
  cursor: default;
}
.upload-button[disabled]:hover,
.upload-button.disabled:hover {
  color: #ffffff;
  background-color: #BEBEBE;
  color: #FFFFFF;
  border: 1px solid #BEBEBE;
  cursor: default;
}
.upload-button input {
  left: -9999px;
  position: absolute;
}
md-dialog-content .md-button {
  text-align: center;
  margin: 0 auto;
  display: block;
}
button[disabled],
html input[type="button"][disabled],
input[type="reset"][disabled],
input[type="submit"][disabled] {
  background-color: #BEBEBE;
  color: #FFFFFF;
}
/*toast*/
.md-toast-content {
  background: #333 url('/assets/images/checkmark-green.png') no-repeat 20px 27px;
  color: #fff;
  width: 217px;
  height: 61px;
  padding: 4px 44px !important;
}
/*datepicker*/
.md-datepicker-button {
  display: none !important;
}
._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: 0;
}
._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  left: 0;
}
.md-datepicker-input-container {
  margin-left: 0 !important;
}
.md-datepicker-calendar-pane.md-pane-open {
  z-index: 201;
}
.md-datepicker-triangle-button.md-button.md-icon-button {
  display: none;
}
.md-datepicker-input {
  width: 100%;
  max-width: initial;
}
._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
  width: 100%;
}
/*tab*/
.md-tab {
  text-transform: capitalize;
  touch-action: initial !important;
}
md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 430px;
}
md-tabs[md-border-top] md-tabs-wrapper {
  width: 554px;
  margin: 0 auto;
}
md-tabs[md-border-top] .md-tab {
  width: 174px;
  padding: 0;
  border-top: solid 3px transparent;
}
md-tabs[md-border-top] .md-tab .ng-scope {
  padding: 10px 5px;
  display: block;
  border-top: solid 1px #76bd23;
  color: #999999;
}
md-tabs[md-border-top] .md-tab.md-active {
  border-top: solid 3px #76bd23;
}
md-tabs[md-border-top] .md-tab.md-active .ng-scope {
  color: #000000;
}
.sub-tab {
  display: inline-block;
  border: solid 1px #cccccc;
  clear: both;
  padding: 0;
  margin: 10px 0;
  border-radius: 3px;
}
.sub-tab li {
  display: inline-block;
  text-align: center;
}
.sub-tab li a {
  padding: 10px;
  margin: 0;
  background-color: #f5f5f5;
  color: #999999;
  display: block;
}
.sub-tab li.active a {
  background-color: #4399c5;
  color: #ffffff;
}
@media (max-width: 599px) {
  md-pagination-wrapper,
  md-tabs-canvas {
    height: 145px;
    border-bottom: solid 1px #76bd23;
  }
  #discover-emerson md-pagination-wrapper,
  #support-for-your-practice md-pagination-wrapper,
  #discover-emerson md-tabs-canvas,
  #support-for-your-practice md-tabs-canvas {
    border-bottom: none;
  }
  md-tabs[md-border-top] .md-tab {
    width: 100%;
    clear: both;
    font-size: 16px;
    text-transform: capitalize;
  }
  md-tabs[md-border-top] .md-tab .ng-scope {
    padding: 10px 5px;
  }
  md-tabs[md-border-top] md-tabs-wrapper {
    width: 100%;
  }
  p {
    font-size: 14px;
  }
}
/* tooltips */
md-tooltip {
  margin-top: 0;
}
md-tooltip {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  border-radius: 4px;
}
md-tooltip .md-content {
  height: auto;
}
md-toolbar.info {
  display: inline;
}
md-toolbar.info .md-button.md-fab {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  width: 13px;
  height: 13px;
  overflow: hidden;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
}
md-toolbar.info .md-button.md-icon-button md-icon,
md-toolbar.info button.md-button.md-fab md-icon {
  background: #fff url('/assets/images/icons/info-icon.png') no-repeat 0 0;
  width: 19px;
  min-width: 19px;
  height: 19px;
  min-height: 19px;
}
md-toolbar.info .md-button.md-icon-button:hover md-icon,
md-toolbar.info button.md-button.md-fab:hover md-icon {
  background-position: 0 -19px;
}
md-tooltip.info {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  border-radius: 4px;
}
md-tooltip.info:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 30px;
  margin-left: -30px;
}
md-tooltip.info .md-content.md-show,
md-tooltip.info .md-content.md-show-add-active {
  width: 232px;
  text-align: center;
  height: 79px;
}
/*progess bar*/
md-progress-linear .md-container,
md-progress-linear.md-default-theme .md-container {
  background-color: #cccccc;
}
md-progress-linear .md-bar,
md-progress-linear.md-default-theme .md-bar {
  background-color: #76bd23;
}
md-progress-linear.md-default-theme.md-warn .md-bar,
md-progress-linear.md-warn .md-bar {
  background-color: #cc3366;
}
md-progress-linear.free-shipping {
  position: relative;
}
md-progress-linear.free-shipping:after {
  content: '';
  display: block;
  width: 38px;
  height: 21px;
  background-image: url("/assets/images/delivery-truck-sprite.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  right: -40px;
  top: -19px;
}
md-progress-linear.free-shipping.active:after {
  background-image: url("/assets/images/delivery-truck-sprite.png");
  background-position: 0 -21px;
}
/*progess Circular*/
md-progress-circular {
  position: relative;
  display: block;
}
md-progress-circular.md-mode-indeterminate svg {
  -webkit-animation: indeterminate-rotate 1568.63ms linear infinite;
  animation: indeterminate-rotate 1568.63ms linear infinite;
}
md-progress-circular svg {
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
}
md-progress-circular path,
md-progress-circular.md-default-theme path {
  stroke: #4399c5;
}
md-input-container.select-field.flex-100 {
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  md-input-container.select-field {
    margin-bottom: 20px;
  }
}
/*Fixed Safari bug on the small screen size*/
table.layout-column tbody.flex-100 {
  display: block;
}
@media (max-width: 959px) {
  .content-wrapper {
    margin: 0 15px;
  }
  .layout-sm-column {
    display: block;
  }
  table.layout-column tbody.flex-100 {
    display: block;
  }
  .layout-sm-column {
    display: block;
  }
  tbody.ng-scope.flex-100 {
    display: block;
  }
  td.flex-sm-100 {
    display: block;
  }
  ul.list-flex {
    display: block;
  }
}
@media (max-width: 599px) {
  .layout-xs-column {
    display: block;
  }
  td.flex-xs-100 {
    display: block;
  }
}
/*end fixed Safari bug*/
/*Flex items*/
.list-flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0;
}
.list-flex-item {
  display: flex;
  padding: 0.5em;
}
.list-flex-item.col {
  margin-left: 0;
}
.row .list-flex-item.col {
  margin-left: 0;
}
.list-flex-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 100%;
}
.list-flex-content p {
  flex: 1 0 auto;
}
img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}
img.align-center {
  display: block;
  margin: 0 auto;
}
.right-divider {
  border-right: solid 1px #b7b7b7;
}
.left-divider {
  border-left: solid 1px #b7b7b7;
}
@media screen and (min-device-width: 320px) and (max-device-width: 700px) {
  md-tabs * {
    touch-action: initial !important;
  }
  .slider * {
    touch-action: initial !important;
  }
}
.nga-default {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.nga-default.ng-hide-add,
.nga-default.ng-hide-remove {
  display: block !important;
}
.nga-slow {
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all 2s ease-in-out;
  -o-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
}
.nga-slow.ng-hide-add,
.nga-slow.ng-hide-remove {
  display: block !important;
}
.nga-fast {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nga-fast.ng-hide-add,
.nga-fast.ng-hide-remove {
  display: block !important;
}
.nga-collapse {
  overflow: hidden;
}
.nga-collapse.ng-enter:not(.ng-enter-active),
.nga-collapse.ng-hide-add.ng-hide-add-active,
.nga-collapse.ng-hide-remove:not(.ng-hide-remove-active),
.nga-collapse.ng-leave.ng-leave-active,
.nga-collapse.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-fade.ng-enter,
.nga-fade.ng-hide-remove {
  opacity: 0;
}
.nga-fade.ng-enter.ng-enter-active,
.nga-fade.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-fade.ng-hide-add.ng-hide-add-active,
.nga-fade.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-fade.ng-hide-add,
.nga-fade.ng-leave {
  opacity: 1;
}
.nga-fade.ng-move {
  opacity: 0;
}
.nga-fade.ng-move.ng-move-active {
  opacity: 1;
}
.nga-rotate-down {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-down.ng-enter,
.nga-rotate-down.ng-hide-remove {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.nga-rotate-down.ng-enter.ng-enter-active,
.nga-rotate-down.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-down.ng-hide-add.ng-hide-add-active,
.nga-rotate-down.ng-leave.ng-leave-active {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.nga-rotate-down.ng-hide-add,
.nga-rotate-down.ng-leave {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-down.ng-move {
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.nga-rotate-down.ng-move.ng-move-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-down.ng-enter,
.nga-rotate-down.ng-hide-remove {
  opacity: 0;
}
.nga-rotate-down.ng-enter.ng-enter-active,
.nga-rotate-down.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-rotate-down.ng-hide-add.ng-hide-add-active,
.nga-rotate-down.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-rotate-down.ng-hide-add,
.nga-rotate-down.ng-leave {
  opacity: 1;
}
.nga-rotate-down.ng-move {
  opacity: 0;
}
.nga-rotate-down.ng-move.ng-move-active {
  opacity: 1;
}
.nga-rotate-left {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-left.ng-enter,
.nga-rotate-left.ng-hide-remove {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
.nga-rotate-left.ng-enter.ng-enter-active,
.nga-rotate-left.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-left.ng-hide-add.ng-hide-add-active,
.nga-rotate-left.ng-leave.ng-leave-active {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
.nga-rotate-left.ng-hide-add,
.nga-rotate-left.ng-leave {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-left.ng-move {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
.nga-rotate-left.ng-move.ng-move-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-left.ng-enter,
.nga-rotate-left.ng-hide-remove {
  opacity: 0;
}
.nga-rotate-left.ng-enter.ng-enter-active,
.nga-rotate-left.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-rotate-left.ng-hide-add.ng-hide-add-active,
.nga-rotate-left.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-rotate-left.ng-hide-add,
.nga-rotate-left.ng-leave {
  opacity: 1;
}
.nga-rotate-left.ng-move {
  opacity: 0;
}
.nga-rotate-left.ng-move.ng-move-active {
  opacity: 1;
}
.nga-rotate-right {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-right.ng-enter,
.nga-rotate-right.ng-hide-remove {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.nga-rotate-right.ng-enter.ng-enter-active,
.nga-rotate-right.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-right.ng-hide-add.ng-hide-add-active,
.nga-rotate-right.ng-leave.ng-leave-active {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.nga-rotate-right.ng-hide-add,
.nga-rotate-right.ng-leave {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-right.ng-move {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.nga-rotate-right.ng-move.ng-move-active {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.nga-rotate-right.ng-enter,
.nga-rotate-right.ng-hide-remove {
  opacity: 0;
}
.nga-rotate-right.ng-enter.ng-enter-active,
.nga-rotate-right.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-rotate-right.ng-hide-add.ng-hide-add-active,
.nga-rotate-right.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-rotate-right.ng-hide-add,
.nga-rotate-right.ng-leave {
  opacity: 1;
}
.nga-rotate-right.ng-move {
  opacity: 0;
}
.nga-rotate-right.ng-move.ng-move-active {
  opacity: 1;
}
.nga-rotate-up {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-up.ng-enter,
.nga-rotate-up.ng-hide-remove {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
}
.nga-rotate-up.ng-enter.ng-enter-active,
.nga-rotate-up.ng-hide-remove.ng-hide-remove-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-up.ng-hide-add.ng-hide-add-active,
.nga-rotate-up.ng-leave.ng-leave-active {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
}
.nga-rotate-up.ng-hide-add,
.nga-rotate-up.ng-leave {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-up.ng-move {
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transform: rotateX(90deg);
}
.nga-rotate-up.ng-move.ng-move-active {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nga-rotate-up.ng-enter,
.nga-rotate-up.ng-hide-remove {
  opacity: 0;
}
.nga-rotate-up.ng-enter.ng-enter-active,
.nga-rotate-up.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-rotate-up.ng-hide-add.ng-hide-add-active,
.nga-rotate-up.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-rotate-up.ng-hide-add,
.nga-rotate-up.ng-leave {
  opacity: 1;
}
.nga-rotate-up.ng-move {
  opacity: 0;
}
.nga-rotate-up.ng-move.ng-move-active {
  opacity: 1;
}
.nga-slide-down {
  position: relative;
  overflow: hidden;
}
.nga-slide-down.ng-enter,
.nga-slide-down.ng-hide-remove {
  top: 50px;
}
.nga-slide-down.ng-enter.ng-enter-active,
.nga-slide-down.ng-hide-remove.ng-hide-remove-active {
  top: 0;
}
.nga-slide-down.ng-hide-add.ng-hide-add-active,
.nga-slide-down.ng-leave.ng-leave-active {
  top: 50px;
}
.nga-slide-down.ng-hide-add,
.nga-slide-down.ng-leave {
  top: 0;
}
.nga-slide-down.ng-move {
  top: 50px;
}
.nga-slide-down.ng-move.ng-move-active {
  top: 0;
}
.nga-slide-down.ng-enter,
.nga-slide-down.ng-hide-remove {
  opacity: 0;
}
.nga-slide-down.ng-enter.ng-enter-active,
.nga-slide-down.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-slide-down.ng-hide-add.ng-hide-add-active,
.nga-slide-down.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-slide-down.ng-hide-add,
.nga-slide-down.ng-leave {
  opacity: 1;
}
.nga-slide-down.ng-move {
  opacity: 0;
}
.nga-slide-down.ng-move.ng-move-active {
  opacity: 1;
}
.nga-slide-down.ng-enter:not(.ng-enter-active),
.nga-slide-down.ng-hide-add.ng-hide-add-active,
.nga-slide-down.ng-hide-remove:not(.ng-hide-remove-active),
.nga-slide-down.ng-leave.ng-leave-active,
.nga-slide-down.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-slide-left {
  position: relative;
  overflow: hidden;
}
.nga-slide-left.ng-enter,
.nga-slide-left.ng-hide-remove {
  right: 100%;
}
.nga-slide-left.ng-enter.ng-enter-active,
.nga-slide-left.ng-hide-remove.ng-hide-remove-active {
  right: 0;
}
.nga-slide-left.ng-hide-add.ng-hide-add-active,
.nga-slide-left.ng-leave.ng-leave-active {
  right: 100%;
}
.nga-slide-left.ng-hide-add,
.nga-slide-left.ng-leave {
  right: 0;
}
.nga-slide-left.ng-move {
  right: 100%;
}
.nga-slide-left.ng-move.ng-move-active {
  right: 0;
}
.nga-slide-left.ng-enter,
.nga-slide-left.ng-hide-remove {
  opacity: 0;
}
.nga-slide-left.ng-enter.ng-enter-active,
.nga-slide-left.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-slide-left.ng-hide-add.ng-hide-add-active,
.nga-slide-left.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-slide-left.ng-hide-add,
.nga-slide-left.ng-leave {
  opacity: 1;
}
.nga-slide-left.ng-move {
  opacity: 0;
}
.nga-slide-left.ng-move.ng-move-active {
  opacity: 1;
}
.nga-slide-left.ng-enter:not(.ng-enter-active),
.nga-slide-left.ng-hide-add.ng-hide-add-active,
.nga-slide-left.ng-hide-remove:not(.ng-hide-remove-active),
.nga-slide-left.ng-leave.ng-leave-active,
.nga-slide-left.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-slide-right {
  position: relative;
  overflow: hidden;
}
.nga-slide-right.ng-enter,
.nga-slide-right.ng-hide-remove {
  left: 100%;
}
.nga-slide-right.ng-enter.ng-enter-active,
.nga-slide-right.ng-hide-remove.ng-hide-remove-active {
  left: 0;
}
.nga-slide-right.ng-hide-add.ng-hide-add-active,
.nga-slide-right.ng-leave.ng-leave-active {
  left: 100%;
}
.nga-slide-right.ng-hide-add,
.nga-slide-right.ng-leave {
  left: 0;
}
.nga-slide-right.ng-move {
  left: 100%;
}
.nga-slide-right.ng-move.ng-move-active {
  left: 0;
}
.nga-slide-right.ng-enter,
.nga-slide-right.ng-hide-remove {
  opacity: 0;
}
.nga-slide-right.ng-enter.ng-enter-active,
.nga-slide-right.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-slide-right.ng-hide-add.ng-hide-add-active,
.nga-slide-right.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-slide-right.ng-hide-add,
.nga-slide-right.ng-leave {
  opacity: 1;
}
.nga-slide-right.ng-move {
  opacity: 0;
}
.nga-slide-right.ng-move.ng-move-active {
  opacity: 1;
}
.nga-slide-right.ng-enter:not(.ng-enter-active),
.nga-slide-right.ng-hide-add.ng-hide-add-active,
.nga-slide-right.ng-hide-remove:not(.ng-hide-remove-active),
.nga-slide-right.ng-leave.ng-leave-active,
.nga-slide-right.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-slide-up {
  position: relative;
  overflow: hidden;
}
.nga-slide-up.ng-enter,
.nga-slide-up.ng-hide-remove {
  bottom: 50px;
}
.nga-slide-up.ng-enter.ng-enter-active,
.nga-slide-up.ng-hide-remove.ng-hide-remove-active {
  bottom: 0;
}
.nga-slide-up.ng-hide-add.ng-hide-add-active,
.nga-slide-up.ng-leave.ng-leave-active {
  bottom: 50px;
}
.nga-slide-up.ng-hide-add,
.nga-slide-up.ng-leave {
  bottom: 0;
}
.nga-slide-up.ng-move {
  bottom: 50px;
}
.nga-slide-up.ng-move.ng-move-active {
  bottom: 0;
}
.nga-slide-up.ng-enter,
.nga-slide-up.ng-hide-remove {
  opacity: 0;
}
.nga-slide-up.ng-enter.ng-enter-active,
.nga-slide-up.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-slide-up.ng-hide-add.ng-hide-add-active,
.nga-slide-up.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-slide-up.ng-hide-add,
.nga-slide-up.ng-leave {
  opacity: 1;
}
.nga-slide-up.ng-move {
  opacity: 0;
}
.nga-slide-up.ng-move.ng-move-active {
  opacity: 1;
}
.nga-slide-up.ng-enter:not(.ng-enter-active),
.nga-slide-up.ng-hide-add.ng-hide-add-active,
.nga-slide-up.ng-hide-remove:not(.ng-hide-remove-active),
.nga-slide-up.ng-leave.ng-leave-active,
.nga-slide-up.ng-move {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-squash-left {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  left: 0;
}
.nga-squash-left.ng-enter,
.nga-squash-left.ng-hide-remove {
  width: 0;
  right: 100%;
}
.nga-squash-left.ng-enter:not(.ng-enter-active),
.nga-squash-left.ng-hide-remove:not(.ng-hide-remove-active) {
  padding-left: 0;
  padding-right: 0;
}
.nga-squash-left.ng-enter.ng-enter-active,
.nga-squash-left.ng-hide-add,
.nga-squash-left.ng-hide-remove.ng-hide-remove-active,
.nga-squash-left.ng-leave {
  width: 100%;
  right: 0;
}
.nga-squash-left.ng-hide-add.ng-hide-add-active,
.nga-squash-left.ng-leave.ng-leave-active {
  width: 0;
  right: 100%;
  padding-left: 0;
  padding-right: 0;
}
.nga-squash-left.ng-move {
  width: 0;
  right: 100%;
}
.nga-squash-left.ng-move.ng-move-active {
  width: 100%;
  right: 0;
}
.nga-squash-right {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  right: 0;
}
.nga-squash-right.ng-enter,
.nga-squash-right.ng-hide-remove {
  width: 0;
  left: 100%;
}
.nga-squash-right.ng-enter:not(.ng-enter-active),
.nga-squash-right.ng-hide-remove:not(.ng-hide-remove-active) {
  padding-left: 0;
  padding-right: 0;
}
.nga-squash-right.ng-enter.ng-enter-active,
.nga-squash-right.ng-hide-add,
.nga-squash-right.ng-hide-remove.ng-hide-remove-active,
.nga-squash-right.ng-leave {
  width: 100%;
  left: 0;
}
.nga-squash-right.ng-hide-add.ng-hide-add-active,
.nga-squash-right.ng-leave.ng-leave-active {
  width: 0;
  left: 100%;
  padding-left: 0;
  padding-right: 0;
}
.nga-squash-right.ng-move {
  width: 0;
  left: 100%;
}
.nga-squash-right.ng-move.ng-move-active {
  width: 100%;
  left: 0;
}
.nga-squash-vertical {
  position: relative;
  overflow: hidden;
  bottom: 0;
}
.nga-squash-vertical.ng-enter,
.nga-squash-vertical.ng-hide-remove {
  top: 100%;
}
.nga-squash-vertical.ng-enter:not(.ng-enter-active),
.nga-squash-vertical.ng-hide-remove:not(.ng-hide-remove-active) {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-squash-vertical.ng-enter.ng-enter-active,
.nga-squash-vertical.ng-hide-add,
.nga-squash-vertical.ng-hide-remove.ng-hide-remove-active,
.nga-squash-vertical.ng-leave {
  top: 0;
}
.nga-squash-vertical.ng-hide-add.ng-hide-add-active,
.nga-squash-vertical.ng-leave.ng-leave-active {
  top: 100%;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.nga-squash-vertical.ng-move {
  top: 100%;
}
.nga-squash-vertical.ng-move.ng-move-active {
  top: 0;
}
.nga-squash-vertical.ng-enter,
.nga-squash-vertical.ng-hide-remove {
  opacity: 0;
}
.nga-squash-vertical.ng-enter.ng-enter-active,
.nga-squash-vertical.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
.nga-squash-vertical.ng-hide-add.ng-hide-add-active,
.nga-squash-vertical.ng-leave.ng-leave-active {
  opacity: 0;
}
.nga-squash-vertical.ng-hide-add,
.nga-squash-vertical.ng-leave {
  opacity: 1;
}
.nga-squash-vertical.ng-move {
  opacity: 0;
}
.nga-squash-vertical.ng-move.ng-move-active {
  opacity: 1;
}
.nga-stagger-add.ng-enter-stagger,
.nga-stagger-leave.ng-leave-stagger,
.nga-stagger-move.ng-move-stagger,
.nga-stagger.ng-enter-stagger,
.nga-stagger.ng-leave-stagger,
.nga-stagger.ng-move-stagger {
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
.nga-stagger-fast.ng-enter-stagger,
.nga-stagger-fast.ng-leave-stagger,
.nga-stagger-fast.ng-move-stagger {
  -webkit-transition-delay: 20ms;
  -moz-transition-delay: 20ms;
  -o-transition-delay: 20ms;
  transition-delay: 20ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
.nga-stagger-slow.ng-enter-stagger,
.nga-stagger-slow.ng-leave-stagger,
.nga-stagger-slow.ng-move-stagger {
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
  -webkit-transition-duration: 0;
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  transition-duration: 0;
}
.add-to-cart-quantity-button {
  width: 103px;
  height: 40px;
  line-height: 30px;
  float: right;
  position: relative;
}
.add-to-cart-quantity-button.disabled {
  background-color: #BEBEBE;
  color: #FFFFFF;
}
.add-to-cart-quantity-button.disabled a {
  text-decoration: none;
}
.add-to-cart-quantity-button .disabled-overlay {
  width: 100%;
  height: 30px;
  position: absolute;
  z-index: 1;
}
.add-to-cart-quantity-button .quantity {
  display: block;
  width: 63px;
  height: 30px;
  line-height: 32px;
  color: #ffffff;
  float: left;
  border-left: 1px solid #ffffff;
  margin: 5px 0 5px 0;
  padding: 0 5px 0 5px;
  box-sizing: border-box;
  position: relative;
}
.add-to-cart-quantity-button .quantity.dropdown {
  min-width: auto;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-left: 1px solid #ffffff;
}
.add-to-cart-quantity-button .quantity.dropdown div {
  float: none;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-display {
  background: transparent;
  border: 0;
  padding: 0;
  color: #ffffff;
  text-align: center;
  outline: none;
  display: block;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-display .placeholder {
  display: inline-block;
  position: relative;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-display .placeholder:before {
  background-image: url(/assets/images/arrow-quantity.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: -11px;
  content: " ";
  width: 7px;
  height: 33px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-display:after {
  content: '';
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list {
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 158px;
  height: 90px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list.close {
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list.dropdown-open {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list > div div {
  padding: 15px 20px 5px 20px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list > div div:hover {
  background: transparent;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list .quantity-adjustment-wrapper {
  background-color: #76bd23;
  border-radius: 10px;
  height: 90px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list .quantity-adjustment-wrapper .quantity-adjustment .unit {
  float: left;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list .quantity-adjustment-wrapper .quantity-adjustment .rectangle-quantity {
  width: 30px;
  height: 30px;
  font-size: 16px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list .quantity-adjustment-wrapper .quantity-adjustment .quantity-input {
  border: 0;
  border-bottom: 1px solid #619a36;
  background: transparent;
  width: 45px;
  height: 25px;
  margin: 5px;
  padding: 0;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list .quantity-adjustment-wrapper .add-to-cart {
  margin: 0;
  padding: 0;
  text-align: center;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list .quantity-adjustment-wrapper .add-to-cart a {
  color: #ffffff;
  padding: 10px;
  font-size: 16px;
}
table {
  border-collapse: collapse;
}
.table {
  display: block;
}
.table thead {
  display: block;
}
.table thead tr {
  border-bottom: solid 2px #999999;
}
.table thead tr td {
  font-size: 12px;
  color: #333333;
  line-height: 1;
  display: block;
}
.table thead tr td a {
  font-size: 12px;
  color: #333333;
  line-height: 1;
}
.table td {
  padding: 15px 15px 15px 0;
  font-size: 18px;
}
.table td a {
  color: #4399c5;
}
.table tbody {
  display: block;
}
.table tbody tr {
  border-bottom: dashed 1px #999999;
}
.table tbody tr td {
  display: block;
}
.table .sort-arrow-down,
.table .sort-arrow-up {
  background-image: url('/assets/images/column-sort-arrow.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 12px;
  height: 16px;
  display: inline-block;
}
.table .sort-arrow-up {
  transform: rotate(-180deg);
  background-position: center top;
}
@media only screen and (max-width: 959px) {
  .table .label {
    font-size: 14px;
  }
}
@media only screen and (max-width: 599px) {
  table thead {
    display: none;
  }
}
.loading-spinner {
  width: 100%;
  text-align: center;
}
.loading-spinner md-progress-circular {
  margin: auto;
}
.eqp-logo-wrapper {
  padding: 0 1em;
  text-align: center;
}
.eqp-logo-wrapper div {
  position: absolute;
  right: -2.5em;
  top: 20px;
}
.eqp-logo-wrapper div .eqp-seal {
  width: 50%;
}
.product-detail-wrapper {
  padding: 0 5%;
  color: #333333;
  text-align: left;
}
.product-detail-wrapper .pos-relative {
  position: relative;
}
.product-detail-wrapper .full {
  width: 100%;
  float: left;
}
.product-detail-wrapper .readyTop {
  text-align: left;
  width: 96%;
  float: left;
  bottom: 0px;
  position: relative;
  font-size: 1.3em;
  margin: 0px 1% 0 0;
}
.product-detail-wrapper .readyTop span {
  margin: 0 5px;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp div {
  padding: 0;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp div p {
  color: #E00603;
  margin-top: 0px;
  font-size: 16px;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp div p span {
  color: #666666;
  font-size: 13px;
  margin: 0px;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp .green-warning-messages p {
  color: green;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp ul {
  padding: 0;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp ul li {
  font-size: 16.5px;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp ul li p {
  padding-left: 60px;
}
.product-detail-wrapper .readyTop .warning-messages.warning-messages-pdp ul li img {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  height: 38px;
  width: 38px;
}
.product-detail-wrapper .readyTop ul {
  padding: 0;
}
.product-detail-wrapper .readyTop ul li {
  font-size: 16.5px;
}
.product-detail-wrapper .readyTop ul li p {
  padding-left: 60px;
}
.product-detail-wrapper .readyTop ul li img {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  height: 38px;
  width: 38px;
}
.product-detail-wrapper .readyTop h4 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 16.5px;
}
.product-detail-wrapper .readyTop h4 img {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  height: 38px;
  width: 38px;
}
.product-detail-wrapper .main-tab {
  width: 100%;
  background-color: #fff;
  text-align: left;
  overflow: hidden;
  padding: 10px 0 10px 0;
}
.product-detail-wrapper .main-tab h1 {
  font-size: 31px;
  font-weight: 400;
  padding: 10px 0;
  color: #666;
}
.product-detail-wrapper .main-tab .greenbdr {
  float: left;
  width: 11%;
  border: 2px solid #76bd23;
  padding: 0;
  margin: 0;
}
.product-detail-wrapper .main-tab .nav-map-wrapper {
  color: #727272;
  padding-top: 5px;
  padding-bottom: 10px;
}
.product-detail-wrapper .main-tab .nav-map-wrapper .nav-map {
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.2px;
  color: #727272;
  padding: 0 2px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}
.product-detail-wrapper .main-tab .nav-map-wrapper span {
  font-size: 11px;
}
.product-detail-wrapper .main-tab .nav-map-wrapper span.item-name {
  color: #666;
  font-weight: 500;
}
.product-detail-wrapper .main-tab .item-code-text {
  font-size: 14.2px;
  color: #666;
  line-height: 2.1;
  letter-spacing: 0.1px;
  white-space: nowrap;
  margin: 0px;
  padding-left: 10px;
}
.product-detail-wrapper .main-tab .item-code {
  font-size: 18.5px;
  font-weight: 500;
  line-height: 1.31;
  letter-spacing: 0.2px;
  padding-top: 0;
  display: block;
  color: #5d5d5d;
  text-transform: uppercase;
}
.product-detail-wrapper .main-tab .share-link-section {
  float: right;
  padding-top: 15px;
  text-align: center;
}
.product-detail-wrapper .main-tab .share-link-section a {
  float: right;
}
.product-detail-wrapper .main-tab .share-link-section a .share-link-wrapper {
  padding-top: 7px;
  width: 60px;
  height: 52px;
  border-radius: 10px;
  background-color: #fff;
  border: solid 1px #999999;
  text-align: center;
  position: relative;
}
.product-detail-wrapper .main-tab .share-link-section a .share-link-wrapper span {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 12.3px;
  font-weight: 300;
  line-height: 1.54;
  letter-spacing: 1.2px;
  color: #669933;
  position: absolute;
  bottom: 5px;
  left: 15%;
}
.product-detail-wrapper .line-divider {
  border-bottom: solid 1px #b7b7b7;
  margin-bottom: 30px;
}
.product-detail-wrapper .main-content {
  padding: 0 0 20px 0;
}
.product-detail-wrapper .main-content .left-content {
  padding-right: 15px;
  background-color: #fff;
}
.product-detail-wrapper .main-content .left-content .item-image {
  padding: 10px 0;
  text-align: center;
  position: relative;
}
.product-detail-wrapper .main-content .left-content .item-image .main-image-wrapper .main-image {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.product-detail-wrapper .main-content .left-content .item-image .main-image-wrapper .main-image .large {
  width: 100%;
  max-width: 358px;
}
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper {
  overflow-x: hidden;
  height: 70px;
  margin: 10px auto;
}
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper {
  float: left;
  display: inline-block;
}
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul {
  padding: 0;
  margin: 0 5px;
}
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul li {
  outline: none;
  list-style-type: none;
  border-radius: 10px;
  border: solid 1px #cccccc;
  height: 50px;
}
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul li.thumbnail:active,
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul li.thumbnail:focus,
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul li.thumbnail:hover {
  border: solid 1px #4399c5;
}
.product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul li.thumbnail img {
  height: 40px;
  width: 40px;
  padding: 5px;
}
.product-detail-wrapper .main-content .left-content .item-warning {
  border-top: solid 1px #b7b7b7;
  padding: 30px 0 20px 0;
}
.product-detail-wrapper .main-content .left-content .item-warning .item-warning-block > div {
  max-width: 50%;
}
.product-detail-wrapper .main-content .left-content .item-warning .item-warning-block .item-warning-icon {
  height: 30px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.product-detail-wrapper .main-content .left-content .item-warning .item-warning-block .item-warning-icon > img {
  margin: 0 auto;
  display: block;
  position: relative;
  top: -25%;
  bottom: -25%;
}
.product-detail-wrapper .main-content .left-content .item-warning .warning-text {
  padding-top: 5px;
  font-size: 12px;
  line-height: 1.33;
  color: #000000;
  text-align: center;
}
.product-detail-wrapper .main-content .left-content .item-tags {
  padding-top: 20px;
  border-top: solid 1px #b7b7b7;
}
.product-detail-wrapper .main-content .left-content .item-tags:after,
.product-detail-wrapper .main-content .left-content .item-tags:before {
  content: " ";
  display: table;
}
.product-detail-wrapper .main-content .left-content .item-tags:after {
  clear: both;
}
.product-detail-wrapper .main-content .left-content .item-tags li {
  padding: 1%;
  list-style-type: disc !important;
  float: left;
  width: 48%;
}
.product-detail-wrapper .main-content .left-content .item-tags li:last-child {
  margin: 0 0 2em;
}
.product-detail-wrapper .main-content .left-content .item-tags .md-chips {
  padding: 0 0 8px 0;
  display: inline-block;
}
.product-detail-wrapper .main-content .left-content .item-tags .md-chips md-chip {
  color: #000000;
  background-color: #cccccc;
  border-radius: 10px;
  margin: 3px 10px 0 0;
  padding: 0 15px;
}
.product-detail-wrapper .main-content .left-content .item-tags.title {
  text-align: center;
  color: #000;
  font-weight: 500;
}
.product-detail-wrapper .main-content .left-content .item-feature {
  float: left;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list {
  margin: 0;
  padding: 0;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li {
  list-style-type: none;
  float: left;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li span {
  text-transform: uppercase;
  height: 23px;
  line-height: 23px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #ffffff;
  padding: 0 0 0 10px;
  width: auto;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li.featured span {
  background-color: #4399c5;
  display: block;
  position: relative;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li.featured span:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  border-color: #4399c5 transparent transparent transparent;
  position: absolute;
  content: '';
  top: 0;
  right: -23px;
  z-index: 1;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li.new span {
  background-color: #666666;
  display: block;
  padding-left: 23px;
  position: relative;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li.new span:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  border-color: #666666 transparent transparent transparent;
  position: absolute;
  content: '';
  top: 0;
  right: -23px;
  z-index: 1;
}
.product-detail-wrapper .main-content .left-content .item-feature .item-feature-list li.new.first-item span {
  padding-left: 10px;
}
.product-detail-wrapper .main-content .right-content {
  padding: 0 0 0 20px;
  position: relative;
}
.product-detail-wrapper .main-content .right-content .chat-slot {
  margin-top: 3px;
  margin-bottom: 6px;
  background-color: #fafafa;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.product-detail-wrapper .main-content .right-content .chat-slot h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
  padding: 0px;
}
.product-detail-wrapper .main-content .right-content .chat-slot p {
  margin: 8px 0;
  padding: 0px;
}
.product-detail-wrapper .main-content .right-content .product-select-item {
  color: #56aa31;
  min-width: 50%;
  border: 1px solid #b7b7b7;
  border-radius: 14px;
  font-size: 14px;
  padding: 2px 20px 2px 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: transparent url('/assets/images/view-more-arrow.png') no-repeat right 7px center;
}
.product-detail-wrapper .main-content .right-content .product-select-item:focus {
  outline: none;
}
.product-detail-wrapper .main-content .right-content .product-select-item.disabled {
  background: none;
}
.product-detail-wrapper .main-content .right-content .brand-logo-wrapper {
  color: #333333;
  padding-right: 10px;
  font-size: 14px;
  text-align: center;
}
.product-detail-wrapper .main-content .right-content .brand-logo-wrapper .brandName-text {
  display: block;
  font-size: 18px;
  text-transform: capitalize;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper {
  margin-top: 15px;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .item-description-text {
  letter-spacing: 0.32px;
  font-stretch: normal;
  font-weight: normal;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  padding-top: 25px;
  font-family: 'Roboto Regular', sans-serif;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .item-description-text.item-desc-hidden {
  padding-bottom: 25px;
  max-width: 300px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .item-description-text.item-desc-hidden * {
  display: inline;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .item-description-text.item-desc-hidden img,
.product-detail-wrapper .main-content .right-content .item-description-wrapper .item-description-text.item-desc-hidden br {
  display: none;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .change-desc-limit {
  font-weight: 500;
  color: #4399c5;
  display: inline-block;
  vertical-align: middle;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .limit-less-btn {
  margin-bottom: 25px;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container {
  margin-top: 15px;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .tabs {
  padding: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-top: solid 4px transparent;
  margin-bottom: 5px;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .tabs li {
  display: block;
  width: 100%;
  border-top: solid 1px #76bd23;
  list-style: none;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .tabs a {
  width: 100%;
  height: 100%;
  display: block;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: center;
  color: #999999;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .tabs a:hover {
  text-decoration: none;
  cursor: pointer;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .tabs a:active,
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .tabs a:focus {
  color: #000;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .selected-section {
  border-top: solid 4px #76bd23;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .tabs-container .selected-section a {
  color: #000;
}
.product-detail-wrapper .main-content .right-content .item-description-wrapper .specification-container {
  text-align: left;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: solid 1px #999999;
}
.product-detail-wrapper .main-content .right-content .link-section {
  border-top: solid 1px #999;
  padding-top: 20px;
  margin-top: 20px;
  display: inline-block;
}
.product-detail-wrapper .main-content .right-content .link-section a {
  color: #669933;
  text-decoration: none;
}
.product-detail-wrapper .main-content .right-content .link-section a:hover {
  color: #669933;
  text-decoration: underline;
}
.product-detail-wrapper .main-content .right-content .link-section .ask-dr-link {
  display: inline-block;
}
.product-detail-wrapper .main-content .right-content .link-section .ask-dr-link a {
  text-decoration: none;
  color: #693;
}
.product-detail-wrapper .main-content .right-content .link-section .ask-dr-link a:hover {
  color: black;
  text-decoration: underline;
}
.product-detail-wrapper .main-content .right-content .item-price-wrapper {
  padding: 5px 0 10px 0;
}
.product-detail-wrapper .main-content .right-content .item-price-wrapper p .your-price {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  padding-left: 5px;
  vertical-align: -5px;
}
.product-detail-wrapper .main-content .right-content .item-price-wrapper p .list-title {
  padding-left: 5px;
}
.product-detail-wrapper .main-content .right-content .non-logged-in {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  text-transform: uppercase;
  color: #666666;
}
.product-detail-wrapper .main-content .right-content .non-logged-in p a {
  color: #669933;
}
.product-detail-wrapper .main-content .right-content .non-logged-in .icon-wrapper {
  padding-top: 15px;
}
.product-detail-wrapper .main-content .right-content .non-logged-in .icon-wrapper img {
  width: 46px;
  height: 39px;
}
.product-detail-wrapper .main-content .right-content .add-to-cart-section {
  background-color: #76bd23;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
}
.product-detail-wrapper .main-content .right-content .add-to-cart-section.disabled {
  background-color: #FFFFFF;
  color: #ffffff;
}
.product-detail-wrapper .main-content .right-content .add-to-cart-section a {
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}
.product-detail-wrapper .main-content .right-content .add-to-cart-section a.add-to-cart {
  display: block;
  width: 100%;
  float: left;
}
.product-detail-wrapper .main-content .right-content .add-to-cart-section a.quantity {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  float: left;
  border-left: 1px solid #ffffff;
  margin: 5px 5px 5px 0;
  padding: 0 10px 0 15px;
  box-sizing: border-box;
}
.product-detail-wrapper .main-content .right-content .add-to-cart-section a.quantity .arrow-up {
  font-size: 10px;
  vertical-align: 2px;
  padding-right: 5px;
}
.product-detail-wrapper .main-content .right-content .eqp-seal {
  width: 65px;
  height: 65px;
  margin-left: 53%;
}
.product-detail-wrapper .main-content .right-content .table {
  padding-bottom: 20px;
}
.product-detail-wrapper .main-content .right-content .table tbody tr {
  border-bottom: 0;
}
.product-detail-wrapper .main-content .right-content .table tbody tr:nth-of-type(2) {
  border-bottom: solid 1px #999999;
  padding-bottom: 10px;
}
.product-detail-wrapper .main-content .right-content .table tbody tr:nth-of-type(3) {
  padding-top: 10px;
}
.product-detail-wrapper .main-content .right-content .table tbody tr:last-child {
  margin-top: 10px;
  padding-bottom: 10px;
  border-top: solid 1px #999999;
  border-bottom: solid 1px #999999;
}
.product-detail-wrapper .main-content .right-content .table tbody tr.serving-detail {
  border-bottom: 0;
}
.product-detail-wrapper .main-content .right-content .table tbody tr.alternate-product-serving-detail {
  border-top: 0;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td {
  margin: 0;
  padding: 10px 0;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td p {
  padding: 0;
  margin: 0;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td .serving-text {
  font-size: 12px;
  line-height: 1.83;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td .serving-amount {
  display: block;
  font-size: 16px;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper {
  text-align: right;
  font-size: 14px;
  line-height: 1.57;
  overflow: visible;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper .tooltip-container {
  background: none;
  width: 75px;
  min-width: 75px;
}
.product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper .tooltip-container .tooltip {
  text-align: left;
  height: auto;
  font-size: 12px;
  left: -90px;
}
.product-detail-wrapper .view-similar-products-button.product-details a.add-cart {
  color: #fff;
  text-decoration: none;
}
.product-detail-wrapper .view-similar-products-button.product-details a.add-cart:hover {
  color: #fff;
}
.product-detail-wrapper .add-to-cart-unit-product-detail {
  position: relative;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .multiple-of-msg {
  color: #333;
  top: initial;
  line-height: normal;
}
.product-detail-wrapper .add-to-cart-unit-product-detail form {
  padding-right: 10px;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .view-similar-items-button-holder {
  margin-top: 16px;
  margin-right: 16px;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .add-to-cart-quantity-button {
  max-width: none;
  color: #ffffff;
  float: none;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .add-to-cart-quantity-button a.add-cart {
  background-image: none;
  width: 108px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .add-to-cart-quantity-button .quantity {
  width: 70px;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .add-to-cart-quantity-button .quantity.dropdown div .dropdown-list {
  width: 229px;
}
.product-detail-wrapper .add-to-cart-unit-product-detail .add-to-cart-quantity-button .quantity-adjustment-wrapper .quantity-adjustment {
  margin: 0 auto;
  width: 155px;
}
.product-detail-wrapper .adding-to-cart-progress-wrapper {
  margin-left: -15px;
}
.product-detail-wrapper .adding-to-cart-progress-wrapper md-progress-circular {
  height: 40px !important;
  width: 40px !important;
}
.product-detail-wrapper .adding-to-cart-progress-wrapper md-progress-circular > svg {
  height: 40px !important;
  width: 40px !important;
  transform-origin: center !important;
}
.product-detail-wrapper .related-products {
  padding-top: 40px;
}
.product-detail-wrapper .related-products h2 {
  font-size: 31px;
  font-weight: 300;
  text-align: left;
}
.product-detail-wrapper .related-products .search-results-container {
  padding-left: 0;
  margin-left: 0;
}
.product-detail-wrapper .related-products .search-results-container .list-flexbox-grid .list-item {
  width: 25%;
}
.product-detail-wrapper .related-products .list-flexbox-grid .list-item {
  width: 25%;
}
.product-detail-wrapper .related-products.non-loggedin .product-item-card .product-item {
  padding-bottom: 0;
}
.product-detail-wrapper .header {
  border-bottom: 1px solid #b8b8b8;
  margin-left: 30px;
}
.product-detail-wrapper .header h2 {
  font-size: 18px;
  font-weight: 300;
  color: #333333;
  text-align: left;
}
.product-detail-wrapper .btn-filter {
  background: #85c766;
  color: #fff;
  padding: 12px 13px;
  margin-top: 0;
}
.product-detail-wrapper .filter {
  font-size: 14px;
  width: 267px;
}
.product-detail-wrapper .filter ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.product-detail-wrapper .filter .category {
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.35);
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
}
.product-detail-wrapper .filter .category-title {
  background: #56a4c8;
  color: #fff;
  padding: 12px 13px;
  display: block;
}
.filter-arrow-down,
.filter-arrow-up {
  background-image: url('/assets/images/column-sort-arrow.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 12px;
  height: 16px;
  display: inline-block;
}
.filter-arrow-up {
  transform: rotate(-180deg);
  background-position: center top;
}
.md-select-value {
  border: none;
}
.vendor-code {
  clear: both;
}
.supplement-container .tooltip-container {
  width: auto;
  height: auto;
  background-position: top right;
  background-repeat: no-repeat;
  padding-right: 23px;
}
.supplement-container .tooltip-container:hover {
  background-position: top -19px right;
}
.layout-column {
  display: block;
}
.thumbnail-layout-column {
  display: inline-block !important;
  position: absolute;
  top: 18.5em;
}
.item-image-layout-column {
  display: inline-block !important;
  position: relative;
  left: 4em;
}
.side-thumb {
  width: 3.5em !important;
}
@media all and (max-width: 959px) {
  .product-detail-wrapper .related-products .search-results-container .list-flexbox-grid .list-item {
    width: 50%;
  }
  .product-detail-wrapper .related-products .list-flexbox-grid .list-item {
    width: 50%;
  }
  .product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper .tooltip-container:hover {
    width: 75px;
  }
  .product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper .tooltip-container .tooltip {
    text-align: left;
    height: auto;
    font-size: 12px;
    left: auto;
    right: 0;
  }
  .tooltip-container .tooltip-up .tooltip-arrow {
    left: auto;
    right: 20%;
  }
}
@media all and (max-width: 599px) {
  .product-detail-wrapper .layout-column-xs,
  .product-detail-wrapper .layout-column {
    display: flex;
  }
  .product-detail-wrapper .item-title-box h1#itemNameTitle {
    font-size: 19px;
    font-weight: 500;
    padding-top: 15px;
  }
  .product-detail-wrapper .item-title-box p.item-code-text {
    margin: 0;
    color: #606060;
    letter-spacing: 0;
    line-height: 1.5;
    font-size: 14px;
  }
  .product-detail-wrapper .item-title-box p.item-code-text > span.item-code {
    display: unset;
    font-size: 13px;
    font-weight: 700;
    color: #5d5d5d;
  }
  .product-detail-wrapper .main-content {
    padding-bottom: 0;
    border-bottom: none;
  }
  .product-detail-wrapper .main-content .left-content {
    padding-right: 0;
  }
  .product-detail-wrapper .main-content .left-content .item-image {
    text-align: unset;
    padding-bottom: 16px;
  }
  .product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper {
    height: unset;
    overflow: hidden;
    margin-bottom: 0;
  }
  .product-detail-wrapper .main-content .left-content .item-image .thumbs-nail-wrapper .thumb-wrapper ul {
    margin-left: 0;
  }
  .product-detail-wrapper .main-content .left-content .item-image .selected-item-wrapper {
    margin-top: 6px;
  }
  .product-detail-wrapper .main-content .left-content .item-image #selectedItemNum {
    font-size: 14px;
    padding: 2px 20px 2px 7px;
    margin-bottom: 6px;
    width: 40%;
  }
  .product-detail-wrapper .main-content .left-content .item-image .item-price-wrapper {
    margin-bottom: 16px;
  }
  .product-detail-wrapper .main-content .left-content .item-image .item-price-wrapper div[layout="row"] {
    margin: 6px 0;
  }
  .product-detail-wrapper .main-content .left-content .item-image .item-price-wrapper div[layout="row"] p {
    color: rgba(0, 0, 0, 0.67);
    letter-spacing: 0.1px;
    margin: 0;
    min-width: 105px;
  }
  .product-detail-wrapper .main-content .left-content .item-image .item-price-wrapper div[layout="row"] span {
    color: rgba(0, 0, 0, 0.67);
    font-size: 14px;
  }
  .product-detail-wrapper .main-content .left-content .item-image .item-price-wrapper div[layout="row"] .your-price {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
  }
  .product-detail-wrapper .main-content .left-content .item-image .add-to-cart-quantity-button {
    height: 45px;
    line-height: 45px;
    border-radius: 4px;
  }
  .product-detail-wrapper .main-content .left-content .item-image md-input-container.product-card-qty input {
    background-color: #fff;
    color: #000;
    padding: 0 4px;
    border-radius: 5px;
    height: auto;
    border: 1px solid #68a81f;
    margin-top: 4.5px;
    max-width: 39px;
    text-align: right;
    -moz-appearance: textfield;
  }
  .product-detail-wrapper .main-content .left-content .item-image md-input-container.product-card-qty input[disabled] {
    background-color: inherit;
    color: #fff;
    padding: 0 4px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
  }
  .product-detail-wrapper .main-content .left-content .item-image a.add-cart {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .product-detail-wrapper .main-content .left-content .item-image a.add-cart .shopping-cart-icon {
    margin-right: 5px;
  }
  .product-detail-wrapper .main-content .left-content .brand-logo-row {
    margin-bottom: 2px;
  }
  .product-detail-wrapper .main-content .left-content .item-tags {
    padding-bottom: 15px;
  }
  .product-detail-wrapper .main-content .left-content .item-tags .md-chips md-chip {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.33);
    font-weight: 300;
    letter-spacing: 0.6px;
    border-radius: 5px;
    font-size: 15px;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp {
    margin-left: 0;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp div {
    padding: 0;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp div p {
    color: #E00603;
    margin-top: 0px;
    font-size: 16px;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp div p span {
    color: #666666;
    font-size: 13px;
    margin: 0px;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp .green-warning-messages p {
    color: green;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp ul {
    padding: 0;
    padding-top: 14px;
    margin: 0;
    list-style: none;
    border-top: 1px dashed #b7b7b7;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp ul:first-of-type {
    border-top-style: solid;
    margin-top: 16px;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp ul li > h4 {
    color: rgba(0, 0, 0, 0.67);
    margin: 0;
    font-size: 18px;
  }
  .product-detail-wrapper .main-content .left-content .warning-messages.warning-messages-pdp ul li.warning-text {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.67);
    line-height: 1.4em;
    font-size: 14px;
  }
  .product-detail-wrapper .main-content .right-content {
    padding: 0;
  }
  .product-detail-wrapper .main-content .right-content .non-logged-in {
    margin-bottom: 10px;
    clear: both;
  }
  .product-detail-wrapper .expansion-panel {
    width: 113%;
    margin-left: -7%;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content {
    padding: 12px 24px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .item-description-text {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.67);
    font-family: 'Roboto Regular', sans-serif;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .item-description-text p,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .item-description-text span,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .item-description-text div {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.67);
    font-family: 'Roboto Regular', sans-serif;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .item-description-text p:first-of-type {
    margin-top: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .item-description-text P:last-of-type {
    margin-bottom: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper {
    border: 1px dashed rgba(0, 0, 0, 0.18);
    padding: 8px;
    margin-bottom: 16px;
    overflow: hidden;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper h3 {
    margin: 0;
    margin-bottom: 4px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper a {
    color: rgba(0, 0, 0, 0.67);
    font-weight: 700;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper.ng-enter,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper.ng-leave {
    -webkit-transition: all 0.16s linear;
    transition: all 0.16s linear;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper.ng-enter,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper.ng-leave.ng-leave-active {
    max-height: 0;
    opacity: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper.ng-leave,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .disclaimer-wrapper.ng-enter.ng-enter-active {
    max-height: 340px;
    opacity: 1;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container p,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container span,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container div {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.67);
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container p:first-of-type {
    margin-top: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container P:last-of-type {
    margin-bottom: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .tooltip-container {
    height: 19px;
    margin-bottom: 16px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container .text-right {
    text-align: right;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table {
    padding: 0;
    color: rgba(0, 0, 0, 0.67);
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table.serving-size-table {
    margin-bottom: 16px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table.serving-size-table thead {
    padding: 0;
    border-bottom: none;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table.serving-size-table tbody tr {
    border: none !important;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table.serving-size-table tbody tr td {
    padding: 0;
    text-align: left;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table.serving-size-table tbody tr td span {
    font-size: 15px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table thead {
    padding-bottom: 6px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.18);
    font-size: 12px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table thead tr {
    border-bottom: none;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr {
    border-bottom: none;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr:nth-of-type(2) {
    border-bottom: none;
    padding-bottom: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr:nth-of-type(3) {
    padding-top: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr.other-ingredients {
    border-top: 1px dashed rgba(0, 0, 0, 0.18);
    border-bottom: none;
    padding-bottom: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr.other-ingredients td {
    padding-bottom: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr td {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px 0;
    padding-right: 6px;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr td:last-of-type {
    padding-right: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr td span,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr td p {
    color: rgba(0, 0, 0, 0.54);
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr td span.serving-text,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .supplement-container table tbody tr td p.serving-text {
    font-weight: 700;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.67);
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container {
    padding-bottom: 16px;
    border-bottom: 1px solid #b7b7b7;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container .ee-container {
    padding: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container p,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container span,
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container div {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.67);
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container p:first-of-type {
    margin-top: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content .specification-container P:last-of-type {
    margin-bottom: 0;
  }
  .product-detail-wrapper .expansion-panel .expansion-panel-content br {
    display: none;
  }
  .product-detail-wrapper .link-section {
    margin-top: 16px;
  }
  .product-detail-wrapper .related-products {
    padding-top: 0;
  }
  .product-detail-wrapper .related-products h2 {
    font-size: 28px;
  }
  .product-detail-wrapper .related-products .search-results-container .list-flexbox-grid .list-item {
    width: 100%;
  }
  .product-detail-wrapper .related-products .list-flexbox-grid .list-item {
    width: 100%;
  }
  .product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper .tooltip-container:hover {
    width: 75px;
  }
  .product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper .tooltip-container .tooltip {
    text-align: left;
    height: auto;
    font-size: 12px;
    left: auto;
    right: 0;
  }
  .tooltip-container .tooltip-up .tooltip-arrow {
    left: auto;
    right: 20%;
  }
  div.showDesc h2 {
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    margin-top: 21px;
  }
  div.showDesc h2 p.desc-dark-greycolor {
    line-height: 20px;
    font-family: 'Roboto Regular', sans-serif;
    font-size: 16px;
  }
}
.warning-message-available-qnty {
  opacity: 1;
  margin-top: 2px;
  color: #c71554;
  font-weight: 500;
}
.warning-messages.warning-messages-pdp ul {
  padding-inline-start: 0px;
}
.warning-messages.warning-messages-pdp ul li > img {
  float: left;
  padding-right: 10px;
}
.warning-messages.warning-messages-pdp ul li > h4 {
  color: rgba(0, 0, 0, 0.67);
  font-size: 16px;
}
.add-to-cart-quantity-button.empty-qty-field,
.empty-qty-field .add-to-cart-quantity-button {
  background-color: #BEBEBE;
  color: #FFFFFF;
}
.add-cart[disabled] {
  pointer-events: none;
}
a.change-desc-limit {
  color: #666666;
  text-decoration: underline;
}
div.showDesc {
  padding: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .product-detail-wrapper {
    padding-top: 30px;
  }
  .product-detail-wrapper .main-tab .item-code-text {
    line-height: 1.3;
    padding-top: 5px;
    white-space: normal;
  }
  .product-detail-wrapper .main-tab {
    padding-bottom: 10px;
  }
  .product-detail-wrapper .main-tab .greenbdr {
    width: 17%;
  }
  .product-detail-wrapper .breadcrumb-bdr {
    width: 430px;
  }
}
#accordian-section {
  padding: 0 5%;
}
#accordian-section .grey-accordian-section {
  background-color: #EFEFEF;
  padding: 15px 0 ;
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
#accordian-section .custom-accordian {
  width: 100%;
  float: left;
  margin-bottom: 3px;
}
#accordian-section .custom-accordian .top-section {
  width: 100%;
  float: left;
  padding: 10px 20px;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
}
#accordian-section .custom-accordian .top-section span {
  display: inline;
}
#accordian-section .custom-accordian .top-section .title {
  float: left;
  color: #666;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
}
#accordian-section .custom-accordian .top-section .expansion {
  float: right;
  font-family: 'Roboto Regular', sans-serif;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  color: #999;
}
#accordian-section .custom-accordian .bottom-section {
  width: 100%;
  float: left;
  padding: 10px 20px;
  background-color: #fff;
  display: none;
  box-sizing: border-box;
}
#accordian-section .custom-accordian .bottom-section .accordianWarningInner {
  padding-top: 20px;
}
#accordian-section .custom-accordian .bottom-section .accordianWarningInner .warningSubTitle {
  color: #666;
  font-size: 20px;
  font-weight: bold;
}
#accordian-section .custom-accordian .bottom-section .warningMessageBottom {
  padding-bottom: 20px;
}
#accordian-section .custom-accordian .bottom-section .table {
  padding-bottom: 20px;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr {
  border-bottom: 0;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr:nth-of-type(2) {
  border-bottom: solid 1px #999999;
  padding-bottom: 10px;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr:nth-of-type(3) {
  padding-top: 10px;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr:last-child {
  margin-top: 10px;
  padding-bottom: 10px;
  border-top: solid 1px #999999;
  border-bottom: solid 1px #999999;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr.serving-detail {
  border-bottom: 0;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr.alternate-product-serving-detail {
  border-top: 0;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td {
  margin: 0;
  padding: 10px 0;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td p {
  padding: 0;
  margin: 0;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td .serving-text {
  font-size: 12px;
  line-height: 1.83;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td .pdv {
  color: #333333;
  font-weight: normal;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td .serving-amount {
  display: block;
  font-size: 16px;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td.disclaimer-wrapper {
  text-align: right;
  font-size: 14px;
  line-height: 1.57;
  overflow: visible;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td.disclaimer-wrapper .tooltip-container {
  background: none;
  width: 75px;
  min-width: 75px;
}
#accordian-section .custom-accordian .bottom-section .table tbody tr td.disclaimer-wrapper .tooltip-container .tooltip {
  text-align: left;
  height: auto;
  font-size: 12px;
  left: -90px;
}
@media (max-width: 959px) {
  #accordian-section .custom-accordian .bottom-section .table tbody tr.ingredient-row {
    margin: 20px 0;
  }
  #accordian-section .custom-accordian .bottom-section .table tbody tr.ingredient-row td {
    padding: 0;
    font-size: smaller;
  }
  #accordian-section .custom-accordian .bottom-section .table tbody tr td.disclaimer-wrapper > p {
    text-align: left;
  }
  #accordian-section .custom-accordian .bottom-section .table tbody tr td.disclaimer-wrapper > p a {
    padding-right: 0;
    width: auto;
    min-width: auto;
  }
  #accordian-section .custom-accordian .bottom-section .table tbody tr td.disclaimer-wrapper > p .tooltip-container .tooltip {
    left: 0;
  }
}
#accordian-section .custom-accordian .bottom-section.show {
  display: block;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .product-detail-wrapper {
    padding-top: 30px;
  }
  .product-detail-wrapper .main-tab .greenbdr {
    width: 17%;
  }
  .product-detail-wrapper .item-title-box p.item-code-text {
    margin-top: 10px;
    padding-left: 0;
  }
  .product-detail-wrapper .readyTop {
    margin: 10px 0 20px;
    width: 100%;
    position: relative;
  }
  .product-detail-wrapper div.showDesc {
    padding: 0px;
  }
}
.dark-greycolor {
  color: #666666 !important;
}
.dark-greycolor p,
.dark-greycolor span,
.dark-greycolor div {
  color: #666666 !important;
}
.mobaccord {
  clear: both;
  margin-top: 10px;
}
.breadcrumb-bdr {
  border-bottom: 1px solid #ececec;
  padding-top: 3px;
  width: 570px;
}
div.showDesc p.dark-greycolor {
  margin-top: 0;
}
.feature-label {
  margin-right: 5px;
  float: left;
  padding: 5px;
  background: #76bd23;
  color: white;
  border-radius: 5px;
  width: 75px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.2px;
  text-decoration: none;
}
div.showDesc {
  color: #666;
  font-size: 16px;
}
div.showDesc h2 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-top: 21px;
}
div.showDesc h2 p.desc-dark-greycolor {
  margin-bottom: 0;
  line-height: 20px;
  font-family: 'Roboto Regular', sans-serif;
  color: #666;
}
div.showDesc span.brandName-text a {
  text-decoration: underline;
  color: #666;
}
div.showDesc div.item-description-text {
  margin-top: 15px;
}
div.showDesc div.item-description-text br:first-child {
  display: none;
}
div.showDesc div.item-description-text p:first-child {
  margin-top: 0;
}
div.showDesc div.item-description-text div:first-child br:first-child {
  display: none;
}
.youmayalsolike-container .flex-margin {
  border: 0;
  padding: 0 0 112.4px 0;
  margin: 0px;
}
.youmayalsolike-container .greenbdr {
  width: 8%;
  border: 2px solid #76bd23;
  padding: 0;
  margin: 0;
}
.alt-related-products h3 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.17;
  color: #707070;
  font-family: roboto;
  text-align: left;
  margin-bottom: 1%;
}
.alt-related-products .list-flexbox-grid .list-item-box {
  width: 266px;
  height: 100%;
  border: 2px solid #fafafa;
  display: inline-block;
  margin-right: 14px;
}
.nav-btn {
  display: flex;
}
.nav-btn.not-visible {
  visibility: hidden;
}
.nav-space {
  width: 65px;
}
.prev-disabled,
.prev-enabled,
.next-disabled,
.next-enabled {
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 45px;
  height: 45px;
  position: relative;
  top: 190px;
  margin: 10px;
}
.prev-disabled {
  background-image: url(/assets/images/prev_disabled.png);
}
.prev-enabled {
  background-image: url(/assets/images/prev_enabled.png);
}
.next-enabled {
  background-image: url(/assets/images/next_enabled.png);
  margin: 10px 10px 10px -5px;
}
.next-disabled {
  background-image: url(/assets/images/next_enabled.png);
  opacity: 0.25;
  margin-left: -5px;
}
.alternate-product-list-holder {
  display: flex;
}
@media screen and (max-width: 1500px) {
  .alternate-product-list-holder {
    zoom: 0.9;
  }
}
@media screen and (max-width: 1400px) {
  .alternate-product-list-holder {
    zoom: 0.8;
  }
}
@media screen and (max-width: 1300px) {
  .alternate-product-list-holder {
    zoom: 0.8;
  }
}
@media screen and (max-width: 1200px) {
  .alternate-product-list-holder {
    zoom: 0.7;
  }
}
@media screen and (max-width: 1100px) {
  .alternate-product-list-holder {
    zoom: 0.6;
  }
}
@media screen and (max-width: 1000px) {
  .alternate-product-list-holder {
    zoom: 0.5;
  }
}
.alternate-product-list-holder > #alternate-product-list {
  width: 100%;
}
#alternate-product-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  text-align: center;
  margin: 0 auto;
}
#alternate-product-list.small-width {
  justify-content: start;
}
#alternate-product-list-mobile {
  display: none;
}
@media screen and (max-width: 959px) {
  .alternate-product-list-holder {
    display: none;
  }
  #alternate-product-list-mobile {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .alt-slide-view::-webkit-scrollbar {
    display: none;
  }
  .alt-slide-view {
    width: 100%;
    border: 0;
    margin: 0;
    overflow-x: scroll;
    overflow-y: auto;
    -ms-overflow-style: none;
  }
  .alt-slide-item {
    width: 16.6em;
    display: inline-block;
    border: 0;
    margin: 0;
  }
}
.print-only-logo {
  display: none;
}
.printable-description {
  display: none;
}
@media print {
  .product-details-page {
    zoom: 0.8;
  }
  .product-details-page .ee-container {
    max-width: none;
  }
  .product-details-page .product-detail-wrapper .main-content {
    padding-bottom: 20px;
  }
  .product-details-page .desc-dark-greycolor {
    margin: 0 !important;
  }
  .product-details-page h2 {
    margin: 0 !important;
  }
  .product-details-page ee-product-actions {
    margin-top: 20px;
  }
  .youmayalsolike-container {
    display: none;
  }
  .nav-map-wrapper {
    display: none;
  }
  .print-only-logo {
    display: block;
  }
  .print-only-logo > img {
    padding: 40px 0;
  }
  .product-detail-wrapper .item-title-box {
    display: table;
    width: 100%;
  }
  .product-detail-wrapper .item-title-box > * {
    display: table-cell !important;
    vertical-align: top;
  }
  .product-detail-wrapper .item-title-box > .item-title {
    width: 100%;
    padding-top: 0;
  }
  .product-detail-wrapper .item-title-box > .item-title > h1#itemNameTitle {
    font-size: 19px;
    font-weight: 500;
    padding-top: 10px;
  }
  .product-detail-wrapper .item-title-box > .item-title > .breadcrumb-bdr {
    display: none;
  }
  .product-detail-wrapper .item-title-box > .item-codes > * {
    display: flex;
    flex-direction: row;
  }
  .product-detail-wrapper .item-title-box > .item-codes > * > div:first-child {
    padding-right: 30px !important;
  }
  .product-detail-wrapper .item-title-box > .item-codes > * .item-code-text {
    padding: 0 !important;
  }
  .product-detail-wrapper .item-title-box > .item-codes > * .item-code-text > * {
    display: none;
  }
  .product-detail-wrapper .item-title-box > .item-codes > * .item-code-text > .item-code {
    display: block !important;
  }
  .main-content > div {
    display: table;
  }
  .main-content > div > * {
    display: table-cell;
    vertical-align: top;
    padding-top: 35px !important;
  }
  .main-content > div > *.left-content {
    width: 30%;
    padding-right: 40px !important;
    padding-right: 5% !important;
  }
  .main-content > div > *.right-content > div {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .main-content > div > *.right-content > div > * {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .main-content > div > *.right-content > div > * > * {
    float: none;
    max-width: 100%;
    width: 100%;
    color: black !important;
  }
  .main-content > div > *.right-content > div > * > *.dark-greycolor p {
    color: black !important;
  }
  .main-content > div > *.right-content > div > * .item-description-text,
  .main-content > div > *.right-content > div > * .change-desc-limit {
    display: none;
  }
  .main-content > div > *.right-content > div > * .printable-description {
    display: block;
  }
  .main-content > div > * .layout-margin,
  .main-content > div > * .layout-margin > * {
    margin: 0 !important;
  }
  .hidden-print {
    display: none !important;
  }
}
.eqp-partners {
  border-bottom: 1px solid #ddd;
}
.eqp-partners h2 {
  text-align: left;
  font-weight: 400;
  padding-top: 40px;
}
.eqp-icon-size {
  width: 7%;
}
.eqp-icon-height {
  height: 50%;
}
.eqp-gold-partners {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}
.eqp-gold-partners .view-more {
  border-bottom: 1px solid #ddd;
  padding: 20px 0 60px 0;
  margin-bottom: 30px;
}
.eqp-gold-partners .view-more a {
  color: #3592c7;
  font-weight: 500;
  font-size: 16px;
}
.eqp-gold-partners .view-more a:hover {
  cursor: pointer;
}
.eqp-gold-partners h4 {
  margin: 0;
  font-weight: 400;
}
.eqp-gold-partners h3 {
  text-align: left;
  font-weight: 300;
  padding-left: 50px;
}
.eqp-gold-partners h2 {
  text-align: left;
}
.gold-partners-content {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 25px 0 25px 50px;
}
.gold-partners-content .gold-partner-img {
  padding-right: 5px;
  margin-right: 5px;
}
.gold-partners-content .gold-partner-img img {
  width: 90%;
}
.gold-partners-content .gold-partners-details {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 5px 0 5px;
}
.gold-partners-content .gold-partners-details .ngTruncateToggleText {
  font-size: 15px;
  display: block;
  padding-top: 10px;
}
.gold-partners-content .gold-partners-head {
  font-weight: 500;
}
.gold-partners-content .gold-partners-description {
  font-weight: 300;
  margin: 0;
}
.gold-partners-content .gold-partners-products {
  font-size: 16px;
  font-weight: 500;
  color: #3592c7;
  padding-left: 5px;
  margin-left: 5px;
}
.gold-partners-content .gold-partners-products a {
  color: #3592c7;
}
.hero-quality {
  margin-top: -135px;
  width: 100%;
  overflow: hidden;
  height: 400px;
}
.hero-unit-quality {
  position: relative;
}
.hero-unit-quality h1 {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.3;
  color: #333;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 50px;
  width: 60%;
  text-align: center;
}
@media screen and (max-width: 959px) {
  .hero-unit-quality h1 {
    width: 90%;
    font-size: 24px;
    left: 0;
    margin: 0 auto;
  }
}
.hero-unit-quality h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
  color: #333;
}
.search-wrapper .search-result-header:before,
.search-wrapper .search-result-header:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.search-wrapper .search-result-header:after {
  clear: both;
}
.search-wrapper .search-result-header .text {
  float: left;
}
.search-wrapper .search-result-header .feedback {
  text-align: right;
  overflow: hidden;
}
.search-wrapper .main-content {
  padding-top: 40px;
  margin-bottom: 30px;
}
.search-wrapper .header {
  margin-left: 30px;
}
.search-wrapper .header h2 {
  font-size: 18px;
  font-weight: 300;
  color: #333333;
  text-align: left;
}
.search-wrapper md-checkbox {
  min-width: 26px;
  min-height: 26px;
}
.search-wrapper md-checkbox .md-container {
  width: 26px;
  height: 26px;
}
.search-wrapper md-checkbox .md-label {
  min-height: 26px;
  padding: 0.5rem;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #666666;
}
.search-wrapper md-checkbox .md-icon {
  background-color: #ffffff;
}
.search-wrapper md-checkbox.md-checked .md-icon:after {
  left: 9.66px;
  top: 5.22px;
}
.search-wrapper .result-header {
  font-size: 18px;
  letter-spacing: 0.2px;
  color: #333333;
  font-weight: 400;
  margin: 25px 0 23px 0;
}
.search-wrapper .btn-filter {
  background-color: #4399c5;
  min-width: 267px;
  color: #fff;
  padding: 0 15px;
  margin-top: 0;
  margin-bottom: 15px;
  cursor: pointer;
  padding-right: 46px;
  position: relative;
  height: 50px;
  line-height: 50px;
  border: solid 1px #4399c5;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-align: left;
}
.search-wrapper .btn-filter:after,
.search-wrapper .btn-filter.btn-open:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 46.5px;
  height: 48px;
  line-height: 48px;
  content: '–';
  background-color: #ffffff;
  color: #4399c5;
  box-sizing: border-box;
  outline: none;
  font-family: Open Sans;
  font-size: 19px;
  letter-spacing: -0.2px;
  text-align: center;
}
.search-wrapper .btn-filter.btn-close:after {
  content: '+';
  font-size: 24px;
}
.search-wrapper .btn-clear {
  background-color: #ffffff;
  border: solid 1px #cccccc;
  color: #4399c5;
  display: block;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0;
  cursor: pointer;
}
.search-wrapper .filter {
  font-size: 14px;
  min-width: 267px;
}
.search-wrapper .filter ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.search-wrapper .filter .category {
  margin-bottom: 11px;
  outline: none;
  border: solid 1px #cccccc;
}
.search-wrapper .filter .category-title {
  background: #fff;
  color: #333;
  padding: 0 10px;
  padding-right: 46px;
  display: block;
  outline: none;
  position: relative;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #666666;
  cursor: pointer;
}
.search-wrapper .filter .category-title:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 46.5px;
  height: 34px;
  line-height: 34px;
  content: '+';
  background-color: #ffffff;
  color: #4399c5;
  box-sizing: border-box;
  outline: none;
  font-family: Open Sans;
  font-size: 19px;
  letter-spacing: -0.2px;
  text-align: center;
}
.search-wrapper .filter .category-title.open {
  border-bottom: solid 1px #cccccc;
}
.search-wrapper .filter .category-title.open:after {
  content: '-';
}
.search-wrapper .filter .category-block {
  max-height: 328px;
  overflow: auto;
}
.search-wrapper .filter .category-list {
  background-color: #fcfcfc;
}
.search-wrapper .filter .category-list.ingredient-chip-list {
  padding: 10px;
}
.search-wrapper .filter .category-list li.excludable-list-item {
  margin-top: 4px;
}
.search-wrapper .filter .category-list li.excludable-list-item:first-of-type {
  margin-top: 0;
}
.search-wrapper .filter .category-list li.excludable-list-item button.delete-item {
  text-align: center;
  width: 21px;
  height: 21px;
  color: #ffffff;
  background-color: #d1cec9;
  display: inline-block;
  border-radius: 50%;
  border: none;
  font-size: 10px;
}
.search-wrapper .filter .category-list li.excludable-list-item span.excludable-list-item {
  font-weight: 300;
  height: 26px;
}
.search-wrapper .filter .category-list li.excludable-list-item .ingredient-chip {
  background-color: rgba(0, 0, 0, 0.06);
  overflow: hidden;
  padding: 4px 26px 4px 8px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.54);
  position: relative;
  font-size: 13px;
}
.search-wrapper .filter .category-list li.excludable-list-item .ingredient-chip button.md-icon-button {
  position: absolute;
  right: -13px;
  top: -7px;
}
.search-wrapper .filter .category-list li.excludable-list-item .ingredient-chip button.md-icon-button md-icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.38);
}
.search-wrapper .filter .category-list li.excludable-list-item .ingredient-chip button.md-icon-button md-icon:hover {
  color: rgba(0, 0, 0, 0.54);
}
.search-wrapper .filter .category-list li:first-child md-checkbox {
  margin-top: 10px;
}
.search-wrapper .filter .category-list li:last-child md-checkbox {
  margin-bottom: 10px;
}
.search-wrapper .filter .category-list li md-checkbox {
  margin: 5px 10px;
}
.search-wrapper .filter .category-list .sub-category-list {
  background-color: #f3f3f3;
}
.search-wrapper .filter .category-list .sub-category-list li md-checkbox {
  margin-left: 30px;
}
.search-wrapper .filter .category-list .sub-category-list .attribute-list {
  background-color: #e9e9e9;
}
.search-wrapper .filter .category-list .sub-category-list .attribute-list li md-checkbox {
  margin-left: 60px;
}
.search-wrapper .filter .category-view-all a {
  display: block;
  height: 34px;
  line-height: 34px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px;
  color: #4399c5;
  text-transform: uppercase;
  border-top: solid 1px #cccccc;
  box-sizing: border-box;
}
.search-wrapper .filter .filter-wrapper .filter-tabs {
  display: block;
  margin: 10px;
  border-radius: 3px;
  border: solid 1px #cccccc;
  overflow: hidden;
}
.search-wrapper .filter .filter-wrapper .filter-tabs li {
  width: 50%;
  background-color: #f5f5f5;
  float: left;
}
.search-wrapper .filter .filter-wrapper .filter-tabs li a {
  padding: 10px;
  display: block;
  text-align: center;
}
.search-wrapper .filter .filter-wrapper .filter-tabs li.active {
  background-color: #4399c5;
}
.search-wrapper .filter .filter-wrapper .filter-tabs li.active a {
  color: #ffffff;
}
.search-wrapper .align-right {
  float: right;
}
/* tooltips */
.product-condition-tooltip .tooltip-container {
  background-size: 100%;
}
.product-condition-tooltip .tooltip-container:hover {
  background-position: 0 0;
}
.product-condition-tooltip .tooltip-container .tooltip {
  height: auto;
  font-size: 12px;
  bottom: 27px;
  left: -117px;
}
.eqp-gold .tooltip-container {
  background: #fff url('/assets/images/icons/e-q-p-gold-icon-no-label.png') no-repeat 0 0;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  background-size: 100%;
}
.eqp-gold .tooltip-container:hover {
  background-position: 0 0;
}
.eqp-gold .tooltip-container .tooltip {
  height: auto;
  font-size: 12px;
}
.eqp-silver .tooltip-container {
  background: #fff url('/assets/images/icons/e-q-p-silver-icon-no-label.png') no-repeat 0 0;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  background-size: 100%;
}
.eqp-silver .tooltip-container:hover {
  background-position: 0 0;
}
.eqp-silver .tooltip-container .tooltip {
  height: auto;
  font-size: 12px;
}
.warning-message .tooltip-container {
  background: #fff url('/assets/images/icons/attention-icon.png') no-repeat 0 0;
  width: 21px;
  min-width: 21px;
  height: 19px;
  min-height: 19px;
  background-size: 100%;
}
.warning-message .tooltip-container:hover {
  background-position: 0 0;
}
.short-shelf-life .tooltip-container {
  background: #fff url('/assets/images/icons/icon_shelf-life.png') no-repeat 0 0;
  width: 22px;
  min-width: 22px;
  height: 19px;
  min-height: 19px;
  background-size: 100%;
  background-position: center center;
}
.short-shelf-life .tooltip-container:hover {
  background-position: center center;
}
.thermo-icon .tooltip-container {
  background: #fff url('/assets/images/icons/thermo-icon.png') no-repeat 0 0;
  width: 10px;
  min-width: 10px;
  height: 27px;
  min-height: 27px;
  background-size: 100%;
  bottom: 8px;
}
.thermo-icon .tooltip-container:hover {
  background-position: 0 0;
}
.thermo-icon .tooltip-container .tooltip {
  left: -122px;
  bottom: 36px;
}
md-toolbar.eqp {
  display: inline;
}
md-toolbar.eqp .md-button.md-fab {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  width: 28px;
  height: 28px;
  overflow: hidden;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
}
md-toolbar.eqp .md-button.md-icon-button md-icon,
md-toolbar.eqp button.md-button.md-fab md-icon {
  background: #fff url('/assets/images/eqp-icon.png') no-repeat 0 0;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  background-size: 100%;
}
.filter-arrow-down,
.filter-arrow-up {
  background-image: url('/assets/images/column-sort-arrow.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 12px;
  height: 16px;
  display: inline-block;
}
.filter-arrow-up {
  transform: rotate(-180deg);
  background-position: center top;
}
.product-item-card .product-body {
  flex: 1 0 auto;
}
.product-item-card .product-banner {
  /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
  *zoom: 1;
  margin-bottom: 20px;
  min-height: 106px;
}
.product-item-card .product-banner:before,
.product-item-card .product-banner:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.product-item-card .product-banner:after {
  clear: both;
}
.product-item-card .product-banner .brand-feature .brand-feature-list {
  margin: 0;
  padding: 0;
  min-height: 23px;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li {
  list-style-type: none;
  float: left;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li span {
  text-transform: uppercase;
  height: 23px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #ffffff;
  padding: 0 0 0 10px;
  width: auto;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li.featured span {
  background: #4399c5;
  background-position: left center;
  display: block;
  position: relative;
  padding-left: 20px;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li.featured span:before {
  background: url('/assets/images/featured-star.png') no-repeat;
  width: 12px;
  height: 11px;
  position: absolute;
  content: '';
  top: 5px;
  left: 5px;
  z-index: 1;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li.featured span:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  border-color: #4399c5 transparent transparent transparent;
  position: absolute;
  content: '';
  top: 0;
  right: -23px;
  z-index: 1;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li.new span {
  background-color: #666666;
  display: block;
  padding-left: 23px;
  position: relative;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li.new span:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  border-color: #666666 transparent transparent transparent;
  position: absolute;
  content: '';
  top: 0;
  right: -23px;
  z-index: 1;
}
.product-item-card .product-banner .brand-feature .brand-feature-list li.new.first-item span {
  padding-left: 10px;
}
.product-item-card .product-banner .brand-banner ul {
  height: 23.33px;
  margin: 10px 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.product-item-card .product-banner .brand-banner ul li {
  list-style-type: none;
  float: left;
  margin-right: 5px;
}
.product-item-card .product-banner .brand-banner .brand-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  color: #666666;
}
.product-item-card .product-banner .eqp .tooltip-container {
  min-width: 23px;
  min-height: 23px;
  width: 23px;
  height: 23px;
}
.product-item-card .product-banner .product-name h3 {
  min-height: 37px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  margin: 10px 0 0 0;
}
.product-item-card .quick-view-overlay {
  position: absolute;
  width: 100%;
  height: 42px;
  z-index: 10;
  bottom: 0;
}
.product-item-card .quick-view-overlay p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 1.4px;
  text-align: center;
  color: #666666;
  background: #dddddd;
  opacity: 0.9;
  cursor: pointer;
  bottom: 0;
  width: 100%;
  padding: 12px;
  margin: 0;
  box-sizing: border-box;
  text-transform: uppercase;
  outline: none;
}
.product-item-card .product-item {
  position: relative;
  height: auto;
}
.product-item-card .product-item .product-thumbnail {
  width: 100%;
}
.product-item-card .product-item .product-thumbnail img {
  max-width: 100%;
  margin: 0 auto;
  display: inherit;
}
.product-item-card .product-item .product-summary h3 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.1;
}
.product-item-card .product-item .product-summary h3 a {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.1;
}
.product-item-card .product-item a {
  color: #669933;
}
.product-item-card .product-buttons .price {
  font-size: 26px;
}
.product-item-card .product-buttons .price p {
  display: inline-block;
  vertical-align: bottom;
}
.product-item-card .product-details {
  margin: 10px 0;
  min-height: 30px;
}
.product-item-card .product-details .product-conditions {
  /*text-align: right;*/
}
.product-item-card .product-details .product-conditions ul {
  padding: 0;
}
.product-item-card .product-details .product-conditions li {
  list-style-type: none;
  display: inline-block;
  font-size: 12px;
  margin-left: 6px;
  float: right;
}
.product-item-card .product-bottom {
  bottom: 0;
  height: 70px;
  margin: 0;
}
.product-item-card .product-footer {
  margin: 10px 0 0 0;
}
.product-item-card .product-footer p {
  font-size: 14px;
  font-weight: 500;
  line-height: 0.8;
  letter-spacing: 0.1px;
  color: #666666;
  margin: 5px 0 0 0;
}
.purchase-notification {
  position: absolute;
  width: 100%;
  text-align: center;
  background: #FFFFFF;
  z-index: 1;
}
.bottom-line-divider {
  border-bottom: solid 1px #333333;
  margin-bottom: 30px;
}
.main-tab {
  width: 100%;
  background-color: #f6f6f6;
  text-align: center;
  overflow: hidden;
}
.main-tab h1 {
  padding: 22px 0;
  margin: 0;
  font-size: 18px;
  color: #333333;
  line-height: 1.2;
}
.main-content {
  padding: 50px 0 50px 0;
}
/*Flex items*/
.list-flexbox-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border-left: 1px solid #b7b7b7;
  margin: 0 0 0 15px;
  box-sizing: border-box;
}
.list-flexbox-grid * {
  box-sizing: border-box;
}
.list-flexbox-grid .list-item {
  display: flex;
  padding: 0;
  width: 100%;
  border-top: 1px solid #b7b7b7;
  border-right: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
  box-sizing: border-box;
  position: relative;
}
.list-flexbox-grid .list-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  width: 100%;
}
.list-flexbox-grid .list-content p {
  flex: 1 0 auto;
  font-size: 13px;
}
.search-results.full-width .list-flexbox-grid {
  margin: 0;
}
.search-results.non-loggedin .product-item-card .product-item {
  padding-bottom: 0;
}
.search-results .no-results {
  border: 0;
}
.coop-banner {
  margin: 0;
  list-style: none;
  width: 100%;
  text-align: center;
  border-right: 1px solid #b7b7b7;
}
.coop-banner img {
  width: 100%;
}
/*product detail modal*/
.show-product-detail-dialog {
  text-align: center;
  position: relative;
}
.product-detail-dialog md-toolbar {
  padding: 0 29px;
}
.product-detail-dialog md-toolbar .md-toolbar-tools {
  margin: 0;
  text-align: left;
  border-bottom: solid 1px #b7b7b7;
  padding: 0;
}
.product-detail-dialog md-toolbar h1 {
  margin: 30px 0;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.3px;
  color: #333333;
}
.product-detail-dialog .item-code-text {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.1px;
  color: #333333;
}
.product-detail-dialog .item-code-text .item-code {
  font-size: 18.5px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.2px;
}
.product-detail-dialog .eqp-logo-wrapper {
  padding: 0 1em;
  text-align: center;
}
.product-detail-dialog td.disclaimer-wrapper .tooltip-container .tooltip {
  left: -122px !important;
}
.product-detail-dialog td.disclaimer-wrapper .tooltip-up .tooltip-arrow {
  left: 65%;
}
.add-to-cart-quantity-button {
  width: 250px;
  float: left;
}
.warning-messages ul li {
  list-style: none;
}
.md-select-value {
  border: none;
}
@media all and (min-width: 960px) {
  .search-results.full-width .list-item {
    width: 25%;
  }
  .search-results.full-width .list-flexbox-grid {
    margin: 0;
  }
  .list-flexbox-grid .list-item {
    width: 33.33%;
  }
}
@media all and (max-width: 959px) {
  .list-flexbox-grid {
    margin: 0;
  }
  .list-flexbox-grid .list-item {
    width: 50%;
  }
}
@media all and (max-width: 599px) {
  .btn-filter {
    width: 267px;
  }
  .list-flexbox-grid .list-item {
    width: 100%;
  }
  .search-wrapper .filter-container {
    display: table;
  }
  .search-wrapper .filter-container .filter-by {
    display: table-footer-group;
  }
  .search-wrapper .filter-container .header {
    margin-left: 0px;
    display: table-header-group;
  }
  .search-wrapper .filter-container .align-right {
    float: none;
  }
  .search-wrapper .filter-container .dropdown-container {
    margin-bottom: 10px;
  }
}
.add-to-cart-notice {
  width: 270px;
  margin-top: 34%;
  line-height: normal;
  z-index: 1;
  text-align: center;
  border-radius: 20px;
  background-color: #999;
  color: white;
  padding: 5px;
  bottom: 100%;
  opacity: 0;
  animation-name: popup;
  animation-duration: 4s;
}
.filter-expand-button {
  float: right;
  width: 30px;
  height: 30px;
  line-height: 34px;
  text-align: center;
  color: #4399c5;
  cursor: pointer;
}
.category-list md-checkbox div.md-label {
  max-width: 150px;
}
.sub-category-list md-checkbox div.md-label {
  max-width: 150px;
}
@keyframes popup {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.support-highlights .hero-image-wrapper {
  height: 668px;
  overflow: hidden;
  margin-top: -137px;
}
.support-highlights .hero-image-wrapper .hero-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 668px;
  background-size: cover;
  background-repeat: no-repeat;
}
.support-highlights .hero-image-wrapper .hero-image-text {
  position: absolute;
  right: 13%;
  top: 230px;
}
.support-highlights .hero-image-wrapper .hero-image-text h2 {
  font-size: 60px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
}
.support-highlights .hero-image-wrapper .hero-image-text p {
  margin: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.56);
  font-weight: 300;
  font-size: 44px;
}
@media (max-width: 1300px) {
  .support-highlights .hero-image-wrapper .hero-image-text {
    right: 4%;
  }
}
@media (max-width: 1161px) {
  .support-highlights .hero-image-wrapper .hero-image-text h2 {
    font-size: 48px;
  }
  .support-highlights .hero-image-wrapper .hero-image-text p {
    font-size: 34px;
  }
}
@media (max-width: 599px) {
  .support-highlights .hero-image-wrapper {
    height: 500px;
  }
  .support-highlights .hero-image-wrapper .hero-image {
    height: 500px;
  }
  .support-highlights .hero-image-wrapper .hero-image-text {
    top: 185px;
  }
}
.support-highlights .hero-image-mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 668px;
  background-color: rgba(255, 255, 255, 0.37);
  z-index: 6;
}
.support-highlights .page-content-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
}
.support-highlights .page-content-wrapper > div {
  max-width: 1140px;
  padding: 0 15px;
  margin-bottom: 40px;
}
.support-highlights .page-content-wrapper .video-description-wrapper {
  flex: 40;
  padding: 0 15px;
}
.support-highlights .page-content-wrapper .video-description-wrapper h2 {
  font-size: 28px;
}
.support-highlights .page-content-wrapper .video-description-wrapper p {
  text-align: center;
}
.support-highlights .page-content-wrapper div.video-wrapper {
  flex: 60;
}
@media (max-width: 959px) {
  .support-highlights .page-content-wrapper {
    margin-top: 33px;
  }
  .support-highlights .page-content-wrapper .video-description-wrapper {
    flex: none;
  }
  .support-highlights .page-content-wrapper .video-description-wrapper h2 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  .support-highlights .hero-image-wrapper .hero-image-text {
    z-index: 10;
  }
  .support-highlights .hero-image-wrapper .hero-image-text h2 {
    font-size: 38px;
    color: rgba(0, 0, 0, 0.85);
  }
  .support-highlights .hero-image-wrapper .hero-image-text p {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
  }
  .support-highlights .layout-sm-column {
    align-items: center;
  }
  .support-highlights div.video-wrapper {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 59%;
    flex: none;
  }
  .support-highlights div.video-wrapper iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .support-highlights .page-content-wrapper {
    align-items: stretch;
  }
  .support-highlights .page-content-wrapper {
    margin-left: 62px;
  }
}
#support-container {
  margin-top: 50px;
  margin-bottom: 30px;
}
#support-container h2 {
  font-size: 35px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
}
#support-container h3 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
#support-container h3 span {
  display: block;
}
@media screen and (max-width: 959px) {
  #support-container h3 span {
    display: inline;
  }
}
#support-container h4 {
  color: #333;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.4;
  height: 60px;
}
@media screen and (max-width: 959px) {
  #support-container h4 {
    height: auto;
  }
}
#support-container p {
  color: #333;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.4;
}
#support-container section {
  padding: 5px;
}
#support-container section.support-1 .support-1-content {
  padding: 10px;
  position: relative;
}
#support-container section.support-1 a.play {
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url('../assets/images/play-video.png');
  width: 59px;
  height: 59px;
  text-indent: -9999px;
  margin: -30px 0 0 -30px;
}
#support-container section.support-2 {
  text-align: center;
  margin-bottom: 40px;
}
#support-container section.support-2 a {
  width: 160px;
}
#support-video {
  width: 100%;
  height: 100%;
}
.adjustment-wrapper .quantity-adjustment-wrapper {
  text-align: center;
  padding: 15px;
  position: relative;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment {
  padding-top: 30px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment.disabled button {
  cursor: not-allowed;
  pointer-events: none;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button {
  font-size: 23px;
  font-weight: 300;
  text-align: center;
  color: #79c143;
  background-color: #ffffff;
  border: 1px solid #999999;
  box-sizing: border-box;
  float: left;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
  width: 25px;
  height: 29px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
    padding: 0;
  }
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity:focus {
  outline: none;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.disableClick {
  cursor: not-allowed;
  border: 1px solid #BEBEBE;
  color: #E4E4E4;
  background-color: #d7d7d7;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment .quantity {
  text-align: right;
  padding-right: 5px;
  width: 44px;
  height: 29px;
  border: 1px solid #999999;
  border-left: 0px;
  border-right: 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: #454553;
  box-sizing: border-box;
  display: inline-block;
  float: left;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment .quantity:focus {
  outline: none;
}
.adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment .disabled-increment {
  color: #333333;
  font-size: 22px;
  background-color: #f2f2f2;
}
.adjustment-wrapper .quantity-adjustment-wrapper .unit-price {
  font-size: 16px;
  padding-top: 17px;
  line-height: 1.12;
  color: #333333;
}
.adjustment-wrapper .quantity-adjustment-wrapper .update-quantity-button {
  opacity: 0;
  position: absolute;
  left: 77%;
  font-size: 14px;
  line-height: 14px;
  min-height: 29px;
  margin: 0;
  cursor: default;
}
.adjustment-wrapper .quantity-adjustment-wrapper .update-quantity-button span {
  font-size: 14px;
  line-height: 14px;
}
.adjustment-wrapper .quantity-adjustment-wrapper .update-quantity-button.active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  cursor: pointer;
}
.adjustment-wrapper .warning-icon {
  position: relative;
  padding-left: 40px;
  font-size: 14px;
  line-height: 1.14;
  color: #333333;
  min-height: 30px;
}
.adjustment-wrapper .warning-content {
  position: relative;
  padding-left: 40px;
  font-size: 14px;
  line-height: 1.14;
  color: #333333;
}
.adjustment-wrapper .warning-content:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background-image: url('/assets/images/flame.png');
  background-repeat: no-repeat;
  width: 27px;
  height: 25px;
}
.adjustment-wrapper .sort-by {
  font-size: 18px;
  line-height: 1.56;
  text-align: left;
  color: #4399c5;
  margin-bottom: -10px;
}
.adjustment-wrapper .sort-by md-select {
  min-width: 170px;
}
.adjustment-wrapper .delete-item {
  text-align: center;
  width: 20px;
  height: 21px;
  color: #cccccc;
  border: none;
  float: right;
  background-color: #ffffff;
}
.adjustment-wrapper .delete-item:focus {
  outline: none;
}
.adjustment-wrapper .item-total-wrapper {
  text-align: right;
  color: #454553;
}
.adjustment-wrapper .item-total-wrapper .total-item {
  font-size: 18px;
  line-height: 1;
  padding-top: 45px;
}
.adjustment-wrapper .item-total-wrapper .total-after-discount {
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
}
.adjustment-wrapper .item-total-wrapper .discount-item {
  font-size: 12px;
}
.adjustment-wrapper .item-total-wrapper .product-discount {
  text-decoration: line-through;
  font-weight: lighter;
  font-size: 16px;
}
.adjustment-wrapper .item-total-wrapper .loading-wrapper {
  padding-top: 45px;
}
.adjustment-wrapper .item-total-wrapper .loading-wrapper.no-padding {
  padding-top: 45px;
}
.adjustment-wrapper .brand-detail {
  margin: -30px 0 10px 0px;
}
.adjustment-wrapper .brand-detail .cart-item-list-table {
  color: #333333;
  font-size: 12px;
}
.adjustment-wrapper .brand-detail thead {
  text-align: center;
}
.adjustment-wrapper .brand-detail thead .text-total {
  text-align: right;
}
.adjustment-wrapper .brand-detail tbody tr {
  border-bottom: solid 1px #999999;
}
.adjustment-wrapper .brand-detail tbody tr.remove-warning-wrapper {
  padding: 50px 0 50px 30px;
}
.adjustment-wrapper .brand-detail tbody tr.remove-warning-wrapper button {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.1px;
}
.adjustment-wrapper .brand-detail tbody tr.remove-warning-wrapper button.cancel-remove {
  background-color: #fff;
  border: solid 1px #79c143;
  color: #79c143;
}
.adjustment-wrapper .brand-detail tbody tr.product-warning-tr {
  border-bottom: 0 !important;
}
.adjustment-wrapper .brand-detail tbody .product-warning {
  border-bottom: 0 !important;
}
.adjustment-wrapper .brand-detail tbody .product-warning td {
  padding: 0;
}
.adjustment-wrapper .brand-detail tbody .product-warning td:nth-of-type(1) {
  border-bottom: 0 !important;
}
.adjustment-wrapper .brand-detail tbody .product-warning td:nth-of-type(2) {
  border-top: 1px solid #999999;
}
.adjustment-wrapper .brand-detail tbody .product-warning:last-child {
  border-bottom: solid 1px #999999 !important;
}
.adjustment-wrapper .category-title {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  font-weight: normal;
}
.adjustment-wrapper .product {
  padding: 10px 0 0 0;
}
.adjustment-wrapper .product .order-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.12;
  text-align: left;
  color: #669933;
}
.adjustment-wrapper .product .order-name h3,
.adjustment-wrapper .product .order-name a {
  color: #669933;
}
.adjustment-wrapper .product .productImg {
  text-align: center;
}
.adjustment-wrapper .product .productImg img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100px;
}
.adjustment-wrapper .empty-items-adjusted {
  font-size: 24px;
}
.adjustment-wrapper .empty-order {
  text-align: center;
}
.adjustment-wrapper .empty-order h1 {
  padding: 100px 0 80px 0;
}
.adjustment-wrapper .empty-order a {
  padding: 15px 40px;
  margin-bottom: 150px;
}
.remove-warning-wrapper button {
  width: 90%;
  max-width: 90%;
  padding: 0 1em;
}
@media only screen and (max-width: 959px) {
  .item-total-wrapper .loading-wrapper {
    padding-top: 0;
  }
  .item-total-wrapper .loading-wrapper.no-padding {
    padding-top: 0;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment {
    padding-top: 0;
    display: block;
    overflow: hidden;
    position: relative;
    min-height: 120px;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
    float: none;
    display: block;
    width: 28px;
    height: 30px;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.decrement-button {
    position: absolute;
    right: 0;
    top: 76px;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment button.increment-button {
    position: absolute;
    right: 0;
    top: 0;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .quantity-adjustment .quantity {
    float: none;
    border-top: 0;
    text-align: center;
    margin: 10px 0;
    display: block;
    position: absolute;
    top: 26px;
    right: 0;
    width: 30px;
    font-size: 14px;
    -webkit-appearance: none;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .unit-price-text {
    display: none;
  }
  .adjustment-wrapper .quantity-adjustment-wrapper .update-quantity-button {
    position: relative !important;
    left: 0;
  }
  .adjustment-wrapper .item-total-wrapper .total-item {
    padding-top: 10px;
    text-align: left;
  }
  .adjustment-wrapper .brand-detail tbody tr.remove-warning-wrapper {
    padding: 30px 0;
  }
}
.alternate-sizes-dialog {
  width: 80%;
  max-width: 400px;
  min-width: 768px;
  color: rgba(0, 0, 0, 0.87);
  padding: 30px;
}
.alternate-sizes-dialog .layout-column {
  display: flex;
}
.alternate-sizes-dialog md-dialog-content {
  padding: 0;
}
.alternate-sizes-dialog md-dialog-content .header {
  list-style-type: none;
  text-align: left;
  padding: 0px;
  margin-bottom: 5px;
}
.alternate-sizes-dialog md-dialog-content .header h2 {
  text-align: left;
  font-weight: 300;
  width: 95%;
  white-space: nowrap;
  margin: 10px 0 10px 0;
}
.alternate-sizes-dialog md-dialog-content .header ul {
  padding: 5px;
}
.alternate-sizes-dialog md-dialog-content .header li.title-wrapper {
  width: 125px;
  border-bottom: 4px solid #76bd23;
  text-align: left;
  overflow-x: visible;
}
.alternate-sizes-dialog md-dialog-content .header li.header-text {
  font-size: 18px;
  text-align: left;
  margin: 15px 0px;
}
.alternate-sizes-dialog md-dialog-content .image-wrapper {
  width: 130px;
  text-align: center;
}
.alternate-sizes-dialog md-dialog-content .image-wrapper img {
  max-width: 130px;
}
.alternate-sizes-dialog .close-button-wrapper {
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
}
.alternate-sizes-dialog .close-button-wrapper .close-button {
  background-color: #fff;
  border: none;
  font-size: 42px;
  width: 42px;
  height: 42px;
  line-height: 2px;
  margin-right: 4px;
  outline: none;
  color: rgba(0, 0, 0, 0.38);
}
.alternate-sizes-dialog .close-button-wrapper .close-button:hover {
  color: rgba(0, 0, 0, 0.54);
}
@media (max-width: 768px) {
  .alternate-sizes-dialog {
    max-width: 400px;
    min-width: 95%;
    font-size: 14px;
    padding: 10px;
  }
}
.alternate-sizes-item {
  padding: 12px 26px 12px 6px;
  margin-top: 15px;
  min-height: 100px;
}
.alternate-sizes-item .image-wrapper img {
  height: 130px;
}
.alternate-sizes-item h3 {
  font-weight: 500;
  font-size: 22px;
}
.alternate-sizes-item .header-item {
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}
.alternate-sizes-item .quantity-wrapper .decrement-button,
.alternate-sizes-item .quantity-wrapper .increment-button {
  border-radius: 25px;
  width: 30px;
  height: 30px;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  color: #79c143;
  background-color: #ffffff;
  border: 1px solid #999;
  margin: 0px 5px 0px 5px;
}
.alternate-sizes-item .quantity-wrapper input {
  background-color: #fff;
  color: #000;
  padding: 0 4px;
  border-radius: 5px;
  height: auto;
  border: 1px solid #68a81f;
  margin-top: 4.5px;
  margin-bottom: 2px;
  max-width: 39px;
  text-align: right;
  -moz-appearance: textfield;
}
.alternate-sizes-item .quantity-wrapper .disable-click {
  cursor: not-allowed;
  border: 1px solid #bebebe;
  color: #e4e4e4;
}
.alternate-sizes-item .add-to-cart-row {
  padding-top: 10px;
  text-align: left;
}
.alternate-sizes-item .add-to-cart-row button {
  margin: 0px;
}
.alternate-sizes-item .unit-price-wrapper {
  margin-left: 15px;
  font-size: 14px;
}
.alternate-sizes-item .unit-price-wrapper .bold {
  font-weight: bold;
}
.alternate-sizes-item .added-to-cart-success-msg {
  margin-left: 15px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.67);
}
.alternate-sizes-item .added-to-cart-success-msg md-icon {
  color: #76bd23;
}
.alternate-sizes-item .quantity-wrapper .quantity {
  text-align: right;
  width: 40px;
  padding-right: 5px;
  height: 29px;
  border: 1px solid #999999;
  border-left: 0;
  border-right: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: #454553;
  box-sizing: border-box;
  -webkit-appearance: none;
}
@media (max-width: 599px) {
  .alternate-sizes-item .quantity-wrapper .quantity {
    height: 35px;
    width: 55px;
    padding: 0;
    text-align: center;
    font-size: 16px;
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.31);
    border-radius: 4px;
  }
}
.alternate-sizes-item .md-icon-button {
  color: rgba(0, 0, 0, 0.54);
}
@media (max-width: 768px) {
  .alternate-sizes-item {
    padding: 8px 8px 4px 8px;
  }
  .alternate-sizes-item .image-wrapper > img {
    height: 125px;
    margin-left: -19px;
  }
  .alternate-sizes-item h3 {
    font-size: 18px;
  }
}
ee-autocomplete {
  width: 100%;
  /* Get rid of IE "X" button on text box */
}
ee-autocomplete .autocomplete {
  position: relative;
}
ee-autocomplete form {
  display: flex;
}
ee-autocomplete #searchAutocomplete::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
ee-autocomplete input[type="search"] {
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 0 15px;
  line-height: 50px;
  height: 50px;
  max-width: 728px;
}
ee-autocomplete input[type="search"]:focus {
  border: none;
}
ee-autocomplete input[type="search"]::placeholder {
  opacity: 0.85;
}
ee-autocomplete .clear-search-btn {
  border: none;
  background: none;
  font-size: 30px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.87);
}
ee-autocomplete .clear-search-btn:focus {
  outline: 0;
}
ee-autocomplete .progress-linear-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.dropdown-item-template {
  position: absolute;
  background: #fff;
  z-index: 10000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;
}
.dropdown-item-template:focus {
  outline: 0;
}
.dropdown-item-template ul.autocomplete-suggestions {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.dropdown-item-template ul.autocomplete-suggestions li {
  list-style: none;
  line-height: 10px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.dropdown-item-template ul.autocomplete-suggestions li:last-of-type {
  padding-bottom: 10px;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata {
  margin: 0;
  padding: 5px;
  padding-left: 10px;
  color: #693;
  line-height: 1;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata:hover {
  background-color: #f5f5f5;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata:hover .item-metastat .show-related-submenu {
  display: block;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata:focus {
  outline: 0;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata .item-metastat {
  padding: 0;
  margin: 0;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata .item-metastat a:hover {
  text-decoration: none;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata .item-metastat div {
  color: #693;
  padding: 0;
  margin: 0;
}
.dropdown-item-template ul.autocomplete-suggestions li .item-metadata .item-metastat div span.highlight {
  font-weight: 700;
}
.dropdown-item-template ul.autocomplete-suggestions li .show-list-search .left,
.dropdown-item-template ul.autocomplete-suggestions li .show-recent-search .left {
  border-right: 1px solid #e6e6e6;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.dropdown-item-template ul.autocomplete-suggestions li .show-list-search .title,
.dropdown-item-template ul.autocomplete-suggestions li .show-recent-search .title {
  margin: 5px;
  padding: 8px 5px 5px;
  color: #666;
  cursor: default;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.bannerSection {
  width: 100%;
  float: left;
  position: relative;
  z-index: 1;
  text-align: center;
  background: #76bd23;
  color: #fff;
  font-size: 16px;
  display: none;
  top: 1px;
  cursor: pointer;
  height: 37px;
  line-height: 18px;
}
.bannerSection.show {
  display: block;
}
.bannerSection.show:hover {
  background-color: #666;
}
.bannerSection.show:hover {
  background-color: #666;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
.popup-wrapper h1,
.popup-wrapper h4 {
  padding: 0px;
  margin: 10px;
}
.bannerSection span {
  display: inline-block;
  padding: 10px 0;
}
.popup-wrapper {
  display: none;
}
.backGround-opacity {
  display: none;
}
.backGround-opacity.show {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: block;
  top: 0px;
  z-index: 999;
  left: 0px;
}
.popup-wrapper.show {
  width: 100%;
  height: 100%;
  padding: 10% 0;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: block;
}
.popup-wrapper.show.chat {
  width: auto;
  min-width: 320px;
  height: 100%;
  padding: 10% 0;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: block;
}
.popup-wrapper .wrapper {
  max-width: 931px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.popup-wrapper.chat .wrapper {
  max-width: 320px;
  padding: 5%;
}
.popup-wrapper.chat .content-area {
  width: 100%;
  float: left;
}
.green-slot {
  background: #4399c5;
  color: #fff;
  display: inline-block;
  width: 100%;
  top: -5px;
  position: relative;
}
.img-slot img {
  width: 100%;
}
.popup-wrapper .close-icon {
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 5px;
  z-index: 999;
  background: url('/assets/images/close.png') no-repeat center center;
  width: 27px;
  height: 27px;
}
.popup-wrapper p {
  padding: 20px;
}
.popup-wrapper .btns {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
.popup-wrapper .btns a.button {
  border: 1px solid #fff;
  box-shadow: none;
  background-color: orange;
  color: #fff;
  padding: 15px 30px;
  border-radius: 10px;
}
.popup-wrapper a.button:hover {
  text-decoration: none;
}
span.mobile-view,
.img-slot.mobile-view {
  display: none;
}
span.desktop-view,
.img-slot.desktop-view {
  display: inline-block;
}
.img-slot.desktop-view {
  width: 100%;
}
.content-area p {
  text-align: initial;
}
@media only screen and (max-width: 768px) {
  .popup-wrapper .wrapper {
    width: 95%;
  }
}
@media only screen and (max-width: 1024px) {
  .bannerSection.show:hover {
    background-color: #76bd23;
  }
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
  span.underline {
    display: none;
  }
  span.mobile-view,
  .img-slot.mobile-view {
    display: inline-block;
  }
  .img-slot.mobile-view {
    width: 100%;
  }
  span.desktop-view,
  .img-slot.desktop-view {
    display: none;
  }
}
.md-toolbar-tools {
  display: block;
  text-align: center;
}
.md-toolbar-tools h2 {
  margin: 20px;
}
.md-toolbar-tools .md-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.md-toolbar-tools .md-close span {
  width: 30px;
  height: 30px;
  color: #000;
  display: block;
}
#brand-agreements md-checkbox {
  margin-bottom: 0;
}
#brand-agreements md-checkbox .md-icon {
  border: 0;
}
#brand-agreements md-checkbox.md-default-theme[disabled].md-checked .md-icon,
#brand-agreements md-checkbox[disabled].md-checked .md-icon {
  background-color: #ffffff;
  border: 0;
}
#brand-agreements md-checkbox.md-default-theme[disabled].md-checked .md-icon:after,
#brand-agreements md-checkbox[disabled].md-checked .md-icon:after {
  border-color: #333333;
}
#brand-agreements td {
  padding: 20px 15px 20px 0;
}
#brand-agreements a {
  outline: 0;
}
.brand-agreement-dialog md-dialog-actions .md-button {
  margin: 0;
  padding: 10px 15px;
  min-height: 0;
  min-width: 0;
}
.brand-agreement-dialog .agreement-actions {
  min-height: 22px;
  text-align: center;
}
.brand-agreement-dialog .md-toolbar-tools h2 {
  font-size: 32px;
  font-weight: 300;
  border-bottom: solid 1px #b7b7b7;
  margin: 0;
  padding: 30px 20px;
}
.brand-agreement-dialog md-dialog-content {
  margin: 20px 80px;
}
.brand-agreement-dialog .md-toolbar-tools {
  padding: 0px 80px;
}
.brand-agreement-dialog .md-dialog-content {
  padding: 0;
}
.brand-agreement-dialog md-dialog-actions {
  border-top: solid 1px #b7b7b7;
  margin: 0 80px;
  padding: 40px 0;
}
.brand-agreement-dialog .md-button.btn-agree {
  background-color: #76bd23;
  color: #ffffff;
  border-radius: 0;
  min-width: 115px;
}
.brand-agreement-dialog .md-dialog-content p:first-child {
  margin-top: 0;
}
.brand-agreement-dialog .right-border {
  border-right: solid 1px #b7b7b7;
  margin-right: 15px !important;
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 959px) {
  #brand-agreements {
    /* Hide table headers (but not display: none;, for accessibility) */
  }
  #brand-agreements thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #brand-agreements tr {
    position: relative;
    padding: 10px 0;
  }
  #brand-agreements td {
    padding: 5px 15px 5px 0;
    flex: 1 0 auto !important;
  }
  #brand-agreements .label {
    font-size: 12px;
    color: #999999;
  }
  #brand-agreements td:nth-of-type(2) a {
    width: 100%;
    display: block;
    position: relative;
    font-weight: 700;
    outline: 0;
  }
  #brand-agreements td:nth-of-type(2) a:after {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    content: ">";
  }
  #brand-agreements td:nth-of-type(3) {
    position: absolute;
    right: 0;
    top: 39px;
  }
  #brand-agreements tr.false td:nth-of-type(1),
  #brand-agreements tr.false td:nth-of-type(3),
  #brand-agreements tr.false td:nth-of-type(4) {
    display: none;
  }
  #brand-agreements tbody {
    border-top: solid 1px #999999;
  }
  #brand-agreements tbody tr:last-child {
    border-bottom: solid 1px #999999;
  }
  .brand-agreement-dialog md-dialog-actions {
    margin: 0;
    padding: 0;
    min-height: 30px;
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    width: 100%;
  }
  .brand-agreement-dialog md-dialog-actions .agreement-actions {
    width: 100%;
  }
  .brand-agreement-dialog md-dialog-actions .agreement-actions .btn-agree,
  .brand-agreement-dialog md-dialog-actions .agreement-actions .btn-cancel {
    width: 50%;
    margin-right: 0 !important;
    border: 0;
    box-sizing: border-box;
    display: block;
    float: left;
  }
  .brand-agreement-dialog .md-toolbar-tools h2 {
    padding: 15px 20px;
  }
  .brand-agreement-dialog md-dialog md-toolbar,
  .brand-agreement-dialog md-toolbar .md-toolbar-tools {
    min-height: 55px;
  }
  .brand-agreement-dialog md-dialog {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
  .brand-agreement-dialog .md-toolbar-tools h2 {
    font-size: 20px;
  }
  .brand-agreement-dialog .md-toolbar-tools {
    padding: 0 20px;
  }
  .brand-agreement-dialog md-dialog-content {
    margin: 20px 20px 50px 20px;
  }
}
.cart-item {
  max-width: 796px;
}
.cart-item .cart-item-list-table {
  color: #333333;
  font-size: 12px;
  padding-bottom: 20px;
}
@media (max-width: 599px) {
  .cart-item .cart-item-list-table {
    min-height: unset;
  }
}
@media (max-width: 1024px) {
  .cart-item .cart-item-list-table {
    max-width: 704px;
  }
}
.cart-item .uppercase-title {
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  color: #333333;
  text-transform: uppercase;
}
.cart-item .quantity-adjustment-wrapper {
  text-align: center;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment {
  padding-top: 30px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment.disabled button {
  cursor: not-allowed;
  pointer-events: none;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment button {
  font-size: 23px;
  font-weight: 300;
  text-align: center;
  color: #79c143;
  background-color: #ffffff;
  border: 1px solid #999999;
  box-sizing: border-box;
  float: left;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity {
  width: 25px;
  height: 29px;
  box-sizing: border-box;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity:focus {
  outline: none;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment button.disableClick {
  cursor: not-allowed;
  border: 1px solid #BEBEBE;
  color: #E4E4E4;
  background-color: #d7d7d7;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment .quantity {
  text-align: right;
  padding-right: 5px;
  width: 40px;
  height: 29px;
  border: 1px solid #999999;
  border-left: 0;
  border-right: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: #454553;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment .quantity::-webkit-inner-spin-button,
.cart-item .quantity-adjustment-wrapper .quantity-adjustment .quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment .quantity:focus {
  outline: none;
}
.cart-item .quantity-adjustment-wrapper .quantity-adjustment .quantity::-webkit-inner-spin-button,
.cart-item .quantity-adjustment-wrapper .quantity-adjustment .quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.cart-item .quantity-adjustment-wrapper .update-quantity-button {
  opacity: 0;
  position: absolute;
  right: -100px;
  font-size: 14px;
  line-height: 14px;
  min-height: 29px;
  margin: 0;
  cursor: default;
}
.cart-item .quantity-adjustment-wrapper .update-quantity-button[disabled] {
  background: #BEBEBE;
  color: #fff;
  pointer-events: none;
}
.cart-item .quantity-adjustment-wrapper .update-quantity-button span {
  font-size: 14px;
  line-height: 14px;
}
.cart-item .quantity-adjustment-wrapper .update-quantity-button.active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  cursor: pointer;
}
.cart-item .quantity-adjustment-wrapper .unit-price-text .unit-price {
  font-size: 16px;
  line-height: 1.12;
  color: #333333;
  padding: 3px 0px;
  margin: 10px auto;
}
.cart-item .quantity-adjustment-wrapper .unit-price-text .override-unit-price {
  font-size: 12px;
  text-transform: capitalize;
}
.cart-item .quantity-adjustment-wrapper .unit-price-text .override-unit-price.md-button {
  padding: 0 5px;
  min-width: 10px;
  min-height: 10px;
  line-height: 20px;
}
.cart-item .quantity-adjustment-wrapper .unit-price-text .override-unit-price.apply.md-button {
  margin-right: 0px;
}
.cart-item .quantity-adjustment-wrapper .unit-price-text .edit-unit-price {
  display: block;
  width: 90px;
  font-size: 14px;
  margin: 10px auto;
  float: none;
}
.cart-item .quantity-adjustment-wrapper .out-of-stock {
  color: red;
  font-size: 10px;
}
.cart-item .warning-content {
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: #333333;
}
.cart-item .warning-content .warning-text {
  padding: 5px 0 0 10px;
  width: 100%;
}
.cart-item .warning-content .warning-text .warning-m-view {
  display: none;
}
.cart-item .sort-by {
  font-size: 18px;
  line-height: 1.56;
  text-align: left;
  color: #4399c5;
  margin-bottom: -10px;
  float: left;
  max-width: 170px;
}
.cart-item .sort-by md-select {
  min-width: 180px;
}
.cart-item .delete-item {
  text-align: center;
  width: 20px;
  height: 21px;
  color: #ff0000;
  border: none;
  float: right;
  background-color: #ffffff;
  background-image: url('/assets/images/light-gray-trash-can-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}
.cart-item .delete-item:focus {
  outline: none;
}
.cart-item .item-total-wrapper {
  text-align: right;
  color: #454553;
}
.cart-item .item-total-wrapper .item-total {
  font-size: 18px;
  line-height: 1.33;
  padding-top: 45px;
}
.cart-item .item-total-wrapper .item-total .total-cost-text {
  display: none;
}
.cart-item .item-total-wrapper .loading-wrapper {
  padding-top: 45px;
}
.cart-item .item-total-wrapper .loading-wrapper.no-padding {
  padding-top: 45px;
}
.cart-item .item-total-wrapper .total-after-discount {
  font-weight: bold;
  font-size: 18px;
  padding: 5px 0;
}
.cart-item .item-total-wrapper .discount-item {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #666666;
}
.cart-item .item-total-wrapper .product-discount {
  text-decoration: line-through;
  font-weight: 300;
  font-size: 16px;
  color: #666666;
}
.cart-item .brand-detail {
  margin: -30px 0 10px;
}
.cart-item .brand-detail thead {
  text-align: center;
}
.cart-item .brand-detail thead .text-total {
  text-align: right;
}
.cart-item .brand-detail tbody tr {
  border-bottom: solid 1px #999999;
}
.cart-item .product {
  padding: 10px 0 0;
}
.cart-item .product .product-description {
  font-size: 16px;
}
.cart-item .product .product-description .order-name {
  font-weight: bold;
  line-height: 1.12;
  text-align: left;
  color: #669933;
}
.cart-item .product .product-description .order-name a {
  color: #669933;
}
.cart-item .product .product-description p.category-title {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  font-weight: normal;
}
.cart-item .product .product-description p.item-code {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  font-weight: normal;
}
.cart-item .product .productImg {
  text-align: center;
}
.cart-item .product .productImg img {
  margin: 0 auto;
  max-width: 100%;
  word-break: break-all;
  font-size: 14px;
}
.cart-item .sorted-by {
  margin-bottom: 30px;
}
.cart-item .sorted-by table {
  margin-bottom: 15px;
}
.cart-item .sorted-by table thead tr {
  margin-top: 5px;
  border-top: solid 2px #999999;
}
.cart-item .sorted-by table thead .text-quantity {
  text-align: center;
}
.cart-item .sorted-by table thead .text-total {
  text-align: right;
  padding-right: 0;
}
.cart-item .sorted-by table tbody tr {
  border-bottom: solid 1px #999999;
}
.cart-item .sorted-by table tbody tr .contentWarning {
  border-top: solid 1px #444;
}
.cart-item .brand-detail tbody tr.product-warning-tr,
.cart-item .sorted-by tbody tr.product-warning-tr {
  border-bottom: 0 !important;
}
.cart-item .brand-detail tbody tr.remove-warning-wrapper,
.cart-item .sorted-by tbody tr.remove-warning-wrapper {
  padding: 50px 0 50px 30px;
}
.cart-item .brand-detail tbody tr.remove-warning-wrapper button,
.cart-item .sorted-by tbody tr.remove-warning-wrapper button {
  padding: 0 45px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.1px;
}
.cart-item .brand-detail tbody tr button.cancel-remove,
.cart-item .sorted-by tbody tr button.cancel-remove {
  background-color: #fff;
  border: solid 1px #79c143;
  color: #79c143;
  margin-right: 0;
}
.cart-item .brand-detail tbody tr.product-warning,
.cart-item .sorted-by tbody tr.product-warning {
  border-bottom: 0 !important;
}
.cart-item .brand-detail tbody tr.product-warning td,
.cart-item .sorted-by tbody tr.product-warning td {
  padding: 0;
}
.cart-item .brand-detail tbody tr.product-warning td:nth-of-type(1),
.cart-item .sorted-by tbody tr.product-warning td:nth-of-type(1) {
  border-bottom: 0 !important;
}
.cart-item .brand-detail tbody tr.product-warning td:nth-of-type(2),
.cart-item .sorted-by tbody tr.product-warning td:nth-of-type(2) {
  border-top: 1px solid #999999;
}
.cart-item .brand-detail tbody tr.product-warning:last-child,
.cart-item .sorted-by tbody tr.product-warning:last-child {
  border-bottom: solid 1px #999999 !important;
}
.cart-item .empty-order {
  text-align: center;
}
.cart-item .empty-order h1 {
  padding: 100px 0 80px;
  margin: 0;
}
.cart-item .empty-order a {
  padding: 15px 40px;
  margin: 0 0 80px;
}
.cart-item .loading-wrapper {
  text-align: center;
  margin: 30px 0;
  padding: 40px 0;
}
.cart-item .loading-wrapper.no-padding {
  padding: 0;
  margin: 0;
}
.cart-item .mobile-express-checkout md-progress-circular path,
.cart-item .mobile-express-checkout md-progress-circular.md-default-theme path {
  stroke: #ffffff;
}
@media only screen and (max-width: 599px) {
  .cart-item .quantity-adjustment-wrapper {
    text-align: right;
  }
  .cart-item .quantity-adjustment-wrapper .out-of-stock {
    margin: 0;
    margin-bottom: 10px;
  }
  .cart-item .quantity-adjustment-wrapper .quantity-adjustment {
    text-align: right;
    padding-top: 0;
  }
  .cart-item .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity,
  .cart-item .quantity-adjustment-wrapper .quantity-adjustment input.quantity {
    display: block;
    float: none;
    margin: 10px auto;
    padding: 0;
    width: 60px;
    text-align: center;
  }
  .cart-item .quantity-adjustment-wrapper .quantity-adjustment button.rectangle-quantity:first-child,
  .cart-item .quantity-adjustment-wrapper .quantity-adjustment input.quantity:first-child {
    margin-top: 0;
  }
  .cart-item .quantity-adjustment-wrapper .quantity-adjustment input.quantity {
    border-left: 1px solid #999;
    border-right: 1px solid #999;
  }
}
@media only screen and (max-width: 959px) {
  .cart-item-list-table {
    margin: 0 15px;
  }
  .cart-item md-progress-circular {
    margin: auto;
  }
  .cart-item .brand-detail {
    padding: 0;
  }
  .cart-item .brand-detail table thead tr:first-child {
    display: none;
  }
  .cart-item .brand-detail table tbody:last-child tr {
    border-bottom: 0;
  }
  .cart-item .sort-by {
    margin-left: 15px;
  }
  .cart-item .sorted-by-wrapper {
    border-top: solid 1px #d7d7d7;
    padding: 5px 0;
  }
  .cart-item .sorted-by {
    padding: 0 15px;
    border-top: solid 1px #d7d7d7;
  }
  .cart-item .sorted-by table thead tr {
    border-top: 0;
    border-bottom: solid 1px #d7d7d7;
    position: relative;
  }
  .cart-item .sorted-by table thead tr td {
    padding-top: 0;
  }
  .cart-item .sorted-by table thead .brand-sub-total {
    float: left;
  }
  .cart-item .sorted-by table thead .items-quantity {
    position: absolute;
    right: 0;
    top: 4%;
  }
  .cart-item .sorted-by table thead .text-quantity,
  .cart-item .sorted-by table thead .text-total {
    display: none;
  }
  .cart-item .sorted-by table tbody tr:last-child {
    border-bottom: 0;
  }
  .cart-item .sorted-by:last-child {
    border-bottom: solid 1px #d7d7d7;
  }
  .cart-item .sorted-by:last-child table tbody tr.product {
    border-bottom: 0;
  }
  .cart-item .sorted-by .cart-item-list-table {
    margin: 0;
  }
  .cart-item .brand-detail,
  .cart-item .sorted-by {
    margin: 10px 0;
    border-top: solid 1px #d7d7d7;
    border-bottom: solid 1px #d7d7d7;
  }
  .cart-item .brand-detail table tbody tr,
  .cart-item .sorted-by table tbody tr {
    border-bottom: solid 1px #b7b7b7;
    position: relative;
  }
  .cart-item .brand-detail table tbody tr.product,
  .cart-item .sorted-by table tbody tr.product {
    min-height: 130px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .cart-item .brand-detail table tbody tr.product .productImg img,
  .cart-item .sorted-by table tbody tr.product .productImg img {
    font-size: 10px;
  }
  .cart-item .brand-detail table tbody tr br,
  .cart-item .sorted-by table tbody tr br {
    display: none;
  }
  .cart-item .brand-detail table tbody tr .product-description,
  .cart-item .sorted-by table tbody tr .product-description {
    font-size: 16px;
  }
  .cart-item .brand-detail table tbody tr .product-description p,
  .cart-item .sorted-by table tbody tr .product-description p {
    padding: 2px 0;
    margin: 2px 0;
  }
  .cart-item .brand-detail table tbody tr .product-description p:last-child,
  .cart-item .sorted-by table tbody tr .product-description p:last-child {
    padding-bottom: 15px;
  }
  .cart-item .brand-detail table tbody tr .product-description p.category-title,
  .cart-item .sorted-by table tbody tr .product-description p.category-title {
    font-size: 14px;
    padding-bottom: 0;
  }
  .cart-item .brand-detail table tbody tr .product-description .item-total,
  .cart-item .sorted-by table tbody tr .product-description .item-total {
    font-size: 14px;
  }
  .cart-item .brand-detail table tbody tr.remove-warning-wrapper,
  .cart-item .sorted-by table tbody tr.remove-warning-wrapper {
    padding: 10px 0;
    border-bottom: solid 1px #b7b7b7;
  }
  .cart-item .brand-detail table tbody .warning-content .warning-text,
  .cart-item .sorted-by table tbody .warning-content .warning-text {
    padding: 0;
  }
  .cart-item .brand-detail table tbody .warning-content .warning-text .warning-m-view,
  .cart-item .sorted-by table tbody .warning-content .warning-text .warning-m-view {
    display: inline-block;
  }
  .cart-item .brand-detail table tbody .warning-content .warning-text .warning-d-view,
  .cart-item .sorted-by table tbody .warning-content .warning-text .warning-d-view {
    display: none;
  }
  .cart-item .brand-detail .quantity-adjustment-wrapper .unit-price-text,
  .cart-item .sorted-by .quantity-adjustment-wrapper .unit-price-text {
    display: none;
  }
  .cart-item .brand-detail .quantity-adjustment-wrapper .update-quantity-button,
  .cart-item .sorted-by .quantity-adjustment-wrapper .update-quantity-button {
    position: relative;
    right: 0;
  }
  .cart-item .sorted-by {
    border-top: 0;
    border-bottom: 0;
  }
  .cart-item .item-total-wrapper {
    text-align: left;
    font-size: 14px;
  }
  .cart-item .item-total-wrapper .delete-item {
    float: left;
  }
  .cart-item .item-total-wrapper .item-total {
    position: absolute;
    bottom: 18%;
    left: 66%;
    font-weight: 700;
  }
  .cart-item .item-total-wrapper .item-total .total-cost-text {
    display: block;
    font-weight: 300;
    line-height: 0.89;
    text-align: right;
    color: #333333;
    padding-bottom: 2px;
  }
  .cart-item .item-total-wrapper .product-discount {
    text-decoration: line-through;
    font-size: 14px;
  }
  .cart-item .item-total-wrapper .discount-item,
  .cart-item .item-total-wrapper .total-after-discount {
    display: none;
  }
  .cart-item .item-total-wrapper .loading-wrapper {
    padding-top: 0;
  }
  .cart-item .item-total-wrapper .loading-wrapper.no-padding {
    padding-top: 0;
  }
  .cart-item .empty-order h1 {
    font-size: 20px;
    line-height: 0.81;
    letter-spacing: 0.3px;
    text-align: center;
    color: #333333;
    padding: 100px 0 80px;
  }
  .cart-item .empty-order a {
    width: 100%;
    padding: 15px 40px;
    margin: 0;
  }
  .bottom-line-divider {
    border-bottom: solid 1px #d7d7d7;
  }
  .empty-order {
    padding: 0 15px;
  }
}
/*more less links*/
.ngTruncateToggleText {
  font-weight: 500;
  color: #4399c5;
}
.ngTruncateToggleText:hover {
  cursor: pointer;
}
.cart-summary-dialog {
  max-width: 80%;
  min-width: 775px;
  color: rgba(0, 0, 0, 0.87);
}
.cart-summary-dialog .layout-column {
  display: flex;
}
.cart-summary-dialog md-dialog-content {
  padding: 5px 0;
}
.cart-summary-dialog .close-button {
  background-color: #fff;
  border: none;
  font-size: 29px;
  width: 27px;
  height: 27px;
  line-height: 2px;
  margin-right: 4px;
  outline: none;
  color: rgba(0, 0, 0, 0.38);
}
.cart-summary-dialog .close-button:hover {
  color: rgba(0, 0, 0, 0.54);
}
.cart-summary-dialog md-dialog-actions {
  justify-content: center;
  padding: 0;
  padding-top: 5px;
}
.cart-summary-dialog md-dialog-actions > .md-button {
  min-width: 185px;
}
.cart-summary-dialog md-dialog-actions a.md-button:hover {
  text-decoration: none;
}
.cart-summary-dialog md-dialog-actions .outline-button {
  background-color: #fff;
  color: hsl(88, 69%, 37%);
  border: 1px solid;
}
.cart-summary-dialog md-dialog-actions .outline-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.cart-summary-dialog .loading-div progress-spinner {
  margin: 15px auto 0 auto;
}
.cart-summary-dialog .loading-div p {
  text-align: center;
}
.cart-summary-dialog .error-state-wrapper {
  padding: 24px;
}
.cart-summary-dialog .error-state-wrapper h2 {
  font-size: 26px;
  margin-bottom: 0;
}
.cart-summary-dialog .error-state-wrapper h4 {
  margin-top: 10px;
}
.cart-summary-dialog .cart-summary-row {
  padding: 0 26px;
}
.cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper {
  padding: 0 20px;
}
.cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper:first-of-type {
  padding-left: 0;
}
.cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper:last-of-type {
  padding-right: 0;
}
.cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .free-shipping-wrapper p.free-shipping-text {
  margin-top: 0;
}
.cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .free-shipping-wrapper md-progress-linear.free-shipping {
  width: 83%;
}
.cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .free-shipping-wrapper md-progress-linear.free-shipping:after {
  top: -13px;
}
.cart-summary-dialog .cart-summary-row .divider {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.cart-summary-dialog .cart-summary-row .cart-summary-number {
  font-weight: 500;
  font-size: 28px;
}
.cart-summary-dialog .quantity-adjustment {
  margin-top: 2px;
}
@media (max-width: 768px) {
  .cart-summary-dialog {
    max-width: 95%;
    min-width: 95%;
    font-size: 14px;
  }
  .cart-summary-dialog md-dialog-actions {
    padding-left: 8px;
    padding-right: 8px;
  }
  .cart-summary-dialog md-dialog-actions > .md-button {
    min-width: unset;
  }
  .cart-summary-dialog md-dialog-actions .outline-button {
    margin-left: 0;
  }
  .cart-summary-dialog .cart-summary-row {
    padding: 0 8px;
    font-size: 16px;
    margin-top: -15px;
  }
  .cart-summary-dialog .cart-summary-row .divider {
    border-right: none;
  }
  .cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper {
    margin-bottom: 10px;
    padding: 0;
  }
  .cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .cart-summary-number {
    font-size: 16px;
    font-weight: 700;
  }
  .cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .free-shipping-wrapper p {
    font-size: 12px;
  }
  .cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .free-shipping-wrapper .free-shipping-progress-bar > md-progress-linear {
    width: 87%;
  }
  .cart-summary-dialog .cart-summary-row .cart-summary-info-wrapper .free-shipping-wrapper .nowrap {
    white-space: normal;
  }
}
.apply-promo-code [ng-messages] {
  font-size: 12px;
  color: #cc3366;
}
.expirationDate {
  padding-left: 30px;
}
.summary-side-col {
  float: right;
  width: 262px;
  height: auto;
  margin: 30px 0 30px 30px;
}
.summary-side-col .cart-summary-wrapper {
  background-color: #f6f6f6;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
}
.summary-side-col .cart-summary-wrapper .item-total {
  padding: 15px 0;
  border-bottom: solid 1px #d7d7d7;
}
.summary-side-col .cart-summary-wrapper .item-total span {
  padding: 0 13px;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  margin-left: 10px;
}
.summary-side-col .cart-summary-wrapper #total-items {
  padding: 15px 0;
  border-bottom: solid 1px #d7d7d7;
}
.summary-side-col .cart-summary-wrapper .item-summary {
  margin-left: 10px;
}
.summary-side-col .cart-summary-wrapper .price-summary {
  padding: 15px 0;
  border-bottom: solid 1px #d7d7d7;
  overflow: hidden;
}
.summary-side-col .cart-summary-wrapper .price-summary .promo-discount,
.summary-side-col .cart-summary-wrapper .price-summary .sub-total {
  margin-bottom: 0;
}
.summary-side-col .cart-summary-wrapper .price-summary .sub-total {
  margin-bottom: 0;
  font-weight: 700;
}
.summary-side-col .cart-summary-wrapper .media {
  padding: 0 13px;
  float: none;
  overflow: hidden;
  margin-bottom: 10px;
}
.summary-side-col .cart-summary-wrapper .media .unit {
  float: left;
  margin: 0;
  font-size: 16px;
}
.summary-side-col .cart-summary-wrapper .media .unit:last-child {
  overflow: hidden;
  float: none;
  text-align: right;
}
.summary-side-col .cart-summary-wrapper .media h2.unit {
  font-weight: 700;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper {
  padding: 15px 0;
  border-bottom: solid 1px #d7d7d7;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper p {
  margin: 0 15px;
  font-size: 16px;
  line-height: 1.3;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper p strong {
  display: inline-block;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper p .shift-left {
  font-size: 15px;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper .free-shipping-progress-bar {
  margin: 18px 15px 15px 15px;
  padding-right: 40px;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper md-progress-linear.free-shipping {
  position: relative;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper md-progress-linear.free-shipping:after {
  content: '';
  display: block;
  width: 38px;
  height: 21px;
  background-image: url(/assets/images/delivery-truck-sprite.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  right: -40px;
  top: -15px;
}
.summary-side-col .cart-summary-wrapper .free-shipping-wrapper md-progress-linear.free-shipping.active:after {
  background-image: url(/assets/images/delivery-truck-sprite.png);
  background-position: 0 -21px;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper {
  padding-bottom: 15px;
  border-bottom: solid 1px #d7d7d7;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper p {
  margin: 0 15px;
  font-size: 16px;
  line-height: 1.3;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper p.smaller {
  font-size: 14px;
  line-height: 1.2;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col {
  overflow: hidden;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col .unit {
  float: left;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col .unit.text-right {
  text-align: right;
  float: right;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col .unit.text-right p {
  margin: 15px 15px 0 0;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col .unit.text-right p .edit-link {
  font-size: 12px;
  line-height: 1.6;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col .unit.text-right p .edit-link.error-edit-link {
  color: #cc3366;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .two-col .unit h3 {
  margin: 15px 2px 10px 15px;
  font-size: 16px;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .discount-code {
  margin: 10px 0;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .discount-code:last-child {
  margin-bottom: 0;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .discount-code .discount-code-text {
  cursor: pointer;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .discount-code .discount-code-text:hover {
  color: #4399c5;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .edit {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .apply-promo-code .apply-promo-code-input {
  margin: 15px;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .apply-promo-code .apply-promo-code-input md-input-container {
  margin: 0;
  width: 100%;
  position: relative;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .apply-promo-code .apply-promo-code-input .remove-promo {
  position: absolute;
  border: 0;
  background-color: transparent;
  color: #999999;
  right: 0;
  top: 5px;
  outline: none;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .apply-promo-code .apply-promo-code-input .remove-promo:hover {
  color: #000000;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .promo-detail-wrapper {
  position: absolute;
  top: 7px;
  right: 20px;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .promo-detail-wrapper .promo-tooltip {
  float: left;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .promo-detail-wrapper .promo-tooltip .discount-info-icon {
  width: 17px;
  height: 17px;
  display: block;
  border: 2px solid #4399c5;
  border-radius: 50%;
  position: relative;
  padding: 1px 6px;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: bold;
  color: #4399c5;
  margin-right: 5px;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .promo-detail-wrapper .promo-detail {
  overflow: hidden;
  font-size: 14px;
  line-height: 1.6;
  font-weight: bold;
}
.summary-side-col .cart-summary-wrapper .discounts-wrapper .add-promo-code {
  color: #0e98df;
  margin: 10px 0;
  display: block;
}
md-tooltip {
  background-color: #fff;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: auto;
  overflow: visible;
}
md-tooltip.discount-code-tooltip h3 {
  font-size: 14px;
  line-height: 1.3;
  margin: 0;
  font-weight: 700;
}
md-tooltip.discount-code-tooltip p {
  font-size: 14px;
  line-height: 1.3;
  margin: 0;
}
.summary-mod {
  border-bottom: solid 1px #d7d7d7;
}
.summary-mod h3 {
  margin: 15px 15px 0 15px;
  font-size: 16px;
}
.summary-mod .sub-title {
  margin: 5px 15px 15px 15px;
  font-size: 14px;
  line-height: 1.3;
}
.summary-mod p {
  margin: 15px;
}
.summary-mod p:last-child {
  margin: 5px 15px 15px 15px;
}
.summary-mod .two-col p {
  margin: 15px 15px 0 15px;
}
.summary-mod .detail {
  font-size: 14px;
  line-height: 1.3;
  margin: 5px 15px 15px 15px;
}
.summary-mod .edit-link {
  font-size: 14px;
  line-height: 1.6;
}
.two-col {
  overflow: hidden;
}
.two-col .unit {
  float: left;
}
.two-col .unit.text-right {
  text-align: right;
}
.two-col .unit:last-child {
  float: none;
  overflow: hidden;
}
.total-summary {
  position: relative;
  display: block;
}
.cart-summary-wrapper .expandable-button {
  padding: 0;
  margin: 0;
  vertical-align: middle;
  line-height: 100%;
  border-left: 1px solid #d7d7d7;
}
.cart-summary-wrapper .expandable-button a {
  background-image: url(/assets/images/down-arrow.png);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: auto;
  width: 100%;
  height: 100%;
  padding: 10%;
  padding-top: 100%;
  display: block;
  box-sizing: border-box;
  background-color: transparent;
}
.cart-summary-wrapper.expandable .expandable-button a {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
.cart-summary-wrapper md-progress-linear.apply-promo {
  top: 31px;
  height: 1px;
  position: absolute;
}
.cart-summary-wrapper md-progress-linear.apply-promo div {
  height: 1px;
}
/*layout-sm*/
@media (max-width: 959px) {
  .cart-checkout-wrapper {
    padding: 0;
  }
  .summary-side-col {
    float: none;
    width: 100%;
    margin: 0;
  }
  .summary-side-col .cart-summary-wrapper {
    background-color: #ffffff;
    box-shadow: 0 4px 2px 0 rgba(22, 22, 22, 0.25);
  }
  .summary-side-col .cart-summary-wrapper .total-summary {
    border-bottom: solid 1px #d7d7d7;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .expandable-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .item-total {
    border-bottom: 0;
    margin: 0;
    padding: 20px 10px;
    border-right: 1px solid #d7d7d7;
    text-align: center;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .item-total span {
    padding: 0;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .price-summary {
    border-bottom: 0;
    margin: 0;
    padding: 20px 10px;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .price-summary .sub-total {
    padding: 0;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .price-summary .promo-discount {
    padding: 8px 0 0 0;
  }
  .summary-side-col .cart-summary-wrapper .expandable-mod {
    display: none;
  }
  .summary-side-col .cart-summary-wrapper.expandable .expandable-mod {
    display: block;
  }
  .summary-side-col .cart-summary-wrapper.expandable-disabled .expandable-mod {
    display: none;
  }
  .summary-side-col .cart-summary-wrapper.expandable-disabled .expandable-mod.mobile-promo {
    display: block;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper {
    margin: 0 10px;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper .free-shipping-progress-bar {
    margin: 15px 40px 15px 0;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper p {
    margin: 0;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper p .shift-left {
    font-size: 12px;
  }
  .summary-side-col .cart-summary-wrapper .discounts-wrapper p {
    margin: 0 10px;
  }
  .summary-side-col .cart-summary-wrapper .discounts-wrapper .apply-promo-code .apply-promo-code-input {
    margin: 0 10px;
  }
  .summary-side-col .cart-summary-wrapper .discounts-wrapper .promo-mobile-input {
    margin-bottom: 10px;
  }
  .summary-side-col .promo-banner-side-col {
    display: block;
  }
}
/*layout-sm*/
@media (max-width: 599px) {
  .summary-side-col .cart-summary-wrapper .item-total span {
    font-size: 15px;
  }
  .summary-side-col .cart-summary-wrapper .total-summary .price-summary {
    font-size: 15px;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper {
    margin: 0 10px;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper .free-shipping-progress-bar {
    margin: 15px 40px 15px 0;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper p {
    margin: 0;
    font-size: 12px;
  }
  .summary-side-col .cart-summary-wrapper .free-shipping-wrapper p .shift-left {
    padding-left: 5px;
  }
  .summary-side-col .cart-summary-wrapper .discounts-wrapper p.smaller {
    font-size: 12px;
  }
  .summary-side-col .cart-summary-wrapper .discounts-wrapper p {
    font-size: 15px;
  }
  .summary-side-col .cart-summary-wrapper .discounts-wrapper .apply-promo-code .apply-promo-code-input md-input-container {
    font-size: 15px;
  }
}
@media print {
  .expandable-mod {
    display: block !important;
  }
}
.apply {
  min-width: 130px !important;
  background-color: #0e98df;
}
.promo-dis {
  padding: 10px 16px;
  width: 70%;
  margin-left: 20px;
}
.promo-dis .promo-chip {
  background-color: rgba(153, 153, 153, 0.28);
  padding: 4px 26px 4px 8px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.54);
  position: relative;
  font-size: 13px;
}
.promo-dis .promo-chip .promo-tip {
  position: absolute;
  right: 32px;
  top: 2px;
}
.promo-dis .promo-chip .promo-btn {
  position: absolute;
  right: -13px;
  top: -8px;
  color: #999999;
}
.apply-promo-code .apply-promo-code-input md-input-container .md-input::-ms-clear {
  display: none;
}
#discount-debugger-dialog md-dialog md-toolbar {
  min-height: 80px;
  border-bottom: solid 1px #ddd;
  /*box-shadow: 0px 10px 20px 0px #f5f5f5;*/
}
#discount-debugger-dialog md-dialog md-toolbar .md-toolbar-tools {
  min-height: 80px;
}
#discount-debugger-dialog md-dialog md-list-item {
  color: #777;
  line-height: 16px;
}
#discount-debugger-dialog md-dialog md-list-item:hover {
  color: black;
  background-color: #ffffee;
}
.checkout-process {
  border-bottom: 1px solid #76bd23;
}
.checkout-process ul {
  margin: 0;
  padding: 0;
}
.checkout-process ul li {
  list-style: none;
  text-align: left;
  border-right: 1px solid #d7d7d7;
  padding: 0;
}
.checkout-process ul li:last-child {
  border-right: 0;
}
.checkout-process ul li a {
  padding: 15px 0;
  margin: 0 15px;
  text-decoration: none;
  color: #cccccc;
  font-size: 18px;
  line-height: 1;
  display: block;
  text-transform: uppercase;
  pointer-events: none;
  cursor: default;
}
.checkout-process ul li a.active {
  color: #000000;
  border-bottom: 4px solid #76bd23;
  font-weight: 700;
}
.checkout-process ul li .clickable {
  font-weight: 700;
  color: #000000;
  pointer-events: auto;
  cursor: pointer;
}
.checkout-process ul li:first-child a {
  padding-left: 0;
  margin-left: 0;
}
/*layout-sm width < 959px*/
@media only screen and (max-width: 959px) {
  .checkout-process {
    border-top: solid 1px #76bd23;
    border-bottom: 1px solid #d7d7d7;
  }
  .checkout-process ul li {
    border-right: 0;
  }
  .checkout-process ul li a {
    font-size: 15px;
    margin: 0 10px 0 0;
    padding-bottom: 0;
    padding-top: 10px;
    border-top: 2px solid transparent;
  }
  .checkout-process ul li a.active {
    border-bottom: 0;
    border-top: 2px solid #76bd23;
  }
  .checkout-process .checkout-process-title-mobile {
    text-align: center;
  }
  .checkout-process .checkout-process-title-mobile h3 {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    margin: 10px;
  }
}
.co-op-promo-banner {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.co-op-promo-banner .promo-banner {
  max-width: 797px;
  width: 100%;
}
.co-op-promo-banner .promo-banner .placeholder {
  border: solid 1px #d7d7d7;
  color: #d7d7d7;
  max-width: 797px;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}
.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;
}
.view-more-btn:before {
  background-image: url('/assets/images/view-more-arrow.png');
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  left: 0;
}
#my-credentials {
  width: 100%;
}
#my-credentials tr.table-wrapper {
  border-bottom: 0;
}
#my-credentials tr.table-wrapper > td {
  padding-right: 0;
}
#my-credentials .dropzone {
  float: none;
  margin: 0 0 0 auto;
}
#my-credentials .dropzone-wrapper {
  padding-right: 0;
}
#my-credentials tbody {
  position: relative;
}
#my-credentials tbody.being-edited {
  border-bottom: dashed 1px #999999;
}
#my-credentials tbody.being-edited .credential-items {
  border-bottom: 0;
}
#my-credentials tbody.being-edited .dropzone-being-edited {
  border: 0;
}
#my-credentials .action-label {
  border-bottom: 0;
}
#my-credentials .credential-status {
  position: relative;
  padding-right: 0;
}
#my-credentials .credential-status-label {
  padding-right: 50px;
}
#my-credentials .credential-action {
  position: absolute;
  right: 0;
  top: 8px;
}
#my-credentials tr.on-adding:last-child {
  border-bottom: 0;
}
.add-another-btn {
  margin: 30px 0;
}
.md-dialog-container#add-credential md-dialog md-dialog-actions {
  display: block;
  width: auto;
  margin: 0px auto 15px auto;
}
/*Responsive table*/
@media only screen and (max-width: 959px) {
  #my-credentials .edit-delete-wrapper {
    padding: 10px 0;
  }
  #my-credentials .edit-delete-wrapper md-icon {
    min-width: 20px;
    width: 20px;
  }
  #my-credentials .dropzone {
    width: 100%;
    box-sizing: border-box;
    margin: auto;
  }
  .md-dialog-container#add-credential md-dialog md-dialog-actions {
    display: block;
    width: auto;
    margin: 0px auto 15px auto;
  }
}
@media only screen and (max-width: 599px) {
  #my-credentials thead {
    display: none;
  }
  #my-credentials-edit {
    border-top: solid 1px #999999;
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
    /*
		Label the data
		*/
    /*td:nth-of-type(1):before { content: "Type"; }
		td:nth-of-type(2):before { content: "Document"; }
		td:nth-of-type(3):before { content: ""; }
    */
  }
  #my-credentials-edit table,
  #my-credentials-edit tbody,
  #my-credentials-edit td,
  #my-credentials-edit th,
  #my-credentials-edit thead,
  #my-credentials-edit tr {
    display: block;
  }
  #my-credentials-edit thead tr {
    display: none;
  }
  #my-credentials-edit tr {
    border: 1px solid #ccc;
  }
  #my-credentials-edit td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 0;
    position: relative;
  }
  #my-credentials-edit tbody {
    position: relative;
  }
  #my-credentials-edit tbody.being-edited .credential-items {
    border-bottom: 0;
  }
  #my-credentials-edit tbody.being-edited .dropzone-being-edited {
    border: 0;
  }
  #my-credentials-edit .credential-items {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: dashed 1px #999999;
  }
  #my-credentials-edit .credential-items td {
    padding-right: 30px;
  }
  #my-credentials-edit .credential-items .label {
    font-size: 14px;
  }
  #my-credentials-edit .credential-items .edit-delete-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 0;
  }
  #my-credentials-edit .action-label {
    border: 0;
  }
  #my-credentials-edit .delete-alert-box {
    border: 0;
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  .md-dialog-container#add-credential md-dialog {
    max-height: 90%;
    min-height: 90%;
  }
  .md-dialog-container#add-credential md-dialog md-dialog-actions {
    display: block;
    width: auto;
    margin: 0px auto 15px auto;
  }
}
#add-credential md-dialog._md.md-transition-in {
  transform: none;
}
.dropzone-container {
  float: right;
  width: 50%;
}
.dropzone-container .dropzone {
  float: none;
  width: 100%;
}
.dropzone-container button {
  width: 120px;
  margin: 20px auto 0;
  display: block;
}
@media screen and (max-device-width: 750px) and (min-device-width: 320px) {
  .dropzone-container {
    padding-left: 15px;
    float: none;
    width: auto;
  }
}
#order-filter-contianer {
  margin: 15px 0;
}
#order-filter-contianer h2 {
  margin: 10px 0;
}
#order-filter-contianer .time-period {
  margin: 25px 0 10px 0;
}
#order-filter-contianer md-input-container {
  margin: 18px 0 10px 0;
  height: 30px;
}
#order-filter-contianer a {
  color: #4399c5;
}
.time-period li {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  border-right: 1px solid #999999;
}
.time-period li:last-child {
  border-right: 0;
}
.time-period li a {
  padding: 0 10px;
}
.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;
}
.view-more-btn:before {
  background-image: url('/assets/images/view-more-arrow.png');
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  left: 0;
}
.my-credit-cards .edit-delete-wrapper {
  padding-right: 0;
}
.my-credit-cards .edit-delete-wrapper button {
  line-height: 1;
}
.my-credit-cards .edit-delete-wrapper button.no-style {
  min-width: 30px;
  width: 30px;
  padding: 5px;
  margin: -5px 5px;
  box-sizing: border-box;
}
/*Track order*/
.track-order-wrapper {
  position: relative;
  text-align: center;
}
.track-order-wrapper p {
  font-size: 12px;
  color: #333333;
}
.track-order-wrapper .tracking-numbers {
  position: absolute;
  bottom: 2px;
  left: -10%;
  z-index: 20;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  padding: 20px;
}
.track-order-wrapper .tracking-numbers li {
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
}
.track-order-wrapper .tracking-numbers li a {
  font-size: 15px;
}
.tracking-numbers md-menu-item {
  height: 30px;
  min-height: 30px;
}
.tracking-numbers a {
  font-size: 15px;
  color: #4399c5;
}
.tracking-numbers p {
  margin: 10px 15px;
  font-size: 12px;
  color: #333333;
}
/*Responsive table*/
@media only screen and (max-width: 959px) {
  div#order-filter-contianer.layout-xs-column.layout-sm-column.layout-row {
    display: block;
  }
  .my-credit-cards {
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
  }
  .my-credit-cards table,
  .my-credit-cards thead,
  .my-credit-cards tbody,
  .my-credit-cards th,
  .my-credit-cards td,
  .my-credit-cards tr {
    display: block;
  }
  .my-credit-cards thead {
    display: none;
  }
  .my-credit-cards tr {
    margin-bottom: 15px;
    position: relative;
  }
  .my-credit-cards td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 0;
  }
  .my-credit-cards td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .my-credit-cards md-input-container {
    margin: 18px 0 0 0;
  }
  .my-credit-cards md-input-container.select-field {
    margin-bottom: 18px;
  }
  .my-credit-cards#default-credit-card-table .credit-card-not-expired {
    display: none;
  }
  .my-credit-cards#default-credit-card-table thead {
    display: none;
  }
  .my-credit-cards .edit-delete-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 95px;
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  md-dialog credit-card-form md-input-container {
    margin: 15px 0 0 0;
  }
  md-dialog credit-card-form md-input-container.select-field {
    margin-bottom: 10px;
  }
  md-dialog credit-card-form md-input-container.md-block.flex-100 {
    margin-right: 0;
  }
}
#delete-modal md-dialog md-dialog-actions {
  margin: 0 auto;
}
md-dialog.ccForm md-dialog-actions {
  display: block;
  margin-bottom: 27px;
}
.require-tla-csa {
  padding: 5px;
}
#csa-options {
  margin-top: 10px;
  width: 98%;
  border-top: 20px solid #76bd23;
  border-bottom: 20px solid #76bd23;
  border-left: 6px solid #76bd23;
  border-right: 6px solid #76bd23;
  max-width: 796px;
}
.csa-panel-section .section-title {
  font-weight: bold;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
  background-color: #c2eb93;
}
.csa-panel-section .section-sub-title {
  font-weight: bold;
  padding-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.csa-panel-section .section-sub-title-value {
  padding-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.csa-panel-section .section-content {
  padding: 5px;
}
.csa-panel-section .order-origin {
  line-height: 30px;
  vertical-align: middle;
}
.hold-codes {
  column-count: 2;
  padding: 8px;
  font-size: 12px;
}
#edit-order-comment textarea {
  width: 99%;
}
#edit-order-comment md-checkbox {
  padding: 5px;
}
.order-comments-select {
  width: 35%;
  max-width: 796px;
}
.order-comments {
  font-size: 12px;
}
#csa-panel {
  clear: both;
}
/*dialog*/
md-backdrop.md-dialog-backdrop {
  background-color: #000000;
  z-index: 200;
}
.md-dialog-container {
  z-index: 201;
}
md-dialog {
  background-color: #ffffff;
  min-width: 80%;
  max-width: 1140px;
}
md-dialog .sub-title {
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  margin-top: 0;
}
md-dialog .uppercase {
  text-transform: uppercase;
}
md-dialog a.md-button {
  background-color: transparent;
  color: #76bd23;
}
md-dialog a.md-button:hover {
  background-color: transparent;
  color: #000000;
  text-decoration: underline;
}
md-dialog md-dialog-actions {
  padding: 20px;
  border-top: 0;
  border-top-width: 0;
}
md-dialog .md-button.left-border {
  border-left: solid 1px #b7b7b7;
}
md-dialog .md-toolbar-tools h2 {
  margin: 0;
  font-size: 32px;
  padding: 20px 20px 0;
  font-size: 300;
}
md-dialog .md-toolbar-tools p {
  font-size: 16px;
  padding: 5px 80px;
  margin: 0 auto;
}
md-dialog .md-toolbar-tools .tooltip p {
  padding: 5px;
}
md-dialog .md-toolbar-tools .md-close.md-button {
  top: 0;
  background-color: #76bd23;
  border-radius: 0;
  margin-right: 0;
  right: 0;
  min-height: 34px;
  height: 34px;
  width: 34px;
  padding: 10px;
}
md-dialog .md-toolbar-tools .md-close.md-button span {
  color: #ffffff;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
}
md-dialog md-toolbar {
  min-height: 130px;
}
md-dialog md-toolbar .md-toolbar-tools {
  min-height: 130px;
}
md-dialog md-dialog-content {
  padding: 20px 60px;
}
md-dialog md-dialog-content .md-dialog-content {
  padding: 0;
}
md-dialog md-dialog-content .md-dialog-content .layout-row td {
  display: block;
}
md-dialog md-input-container {
  margin: 15px 0 0;
}
md-backdrop.md-select-backdrop {
  z-index: 203;
}
@media (max-width: 959px) {
  md-dialog .md-toolbar-tools h2 {
    margin: 0;
    font-size: 20px;
    padding: 20px 20px 0;
  }
  md-dialog .md-toolbar-tools p {
    font-size: 12px;
    padding: 5px 0;
  }
  md-dialog md-dialog-content {
    padding: 10px 20px 20px;
  }
  md-dialog md-toolbar {
    min-height: 125px;
    height: 125px;
  }
  md-dialog md-toolbar .md-toolbar-tools {
    min-height: 125px;
    height: 125px;
  }
  md-dialog.ccForm md-dialog-content {
    padding: 20px;
  }
}
@media (max-width: 599px) {
  md-dialog .md-toolbar-tools {
    padding: 0 5px;
  }
}
.featured-block.border-right li.list-flex-item {
  border-right: 1px solid #ccc;
}
.featured-block.border-right li.list-flex-item:last-child {
  border-right: 0;
}
.mob {
  margin-top: 20%;
}
.tab-body-wrapper {
  padding-top: 30px ;
  padding-bottom: 10px;
}
h2 {
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  color: #333333;
}
.list-inline li {
  padding-left: 15px;
  padding-right: 15px;
}
#discover-emerson md-tabs[md-border-top] .md-tab .ng-scope {
  white-space: normal;
  padding: 10px 40px;
  line-height: normal;
}
.media-body {
  text-align: left;
  padding-left: 33px;
}
.media-body h3 {
  font-size: 20px;
  line-height: 1.2;
  color: #333333;
  font-weight: 400;
  margin-top: 0;
  height: 52px;
}
.media-body a {
  line-height: 1.1;
  font-size: 20px;
  font-weight: 600;
}
.media-body p {
  font-size: 14px;
}
.brand-product-item-layout .logo {
  text-align: center;
  border-bottom: solid 1px #d7d7d7;
  margin-bottom: 15px;
  height: 85px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.brand-product-item-layout .logo .responsive-img {
  max-width: auto;
  max-height: 73px;
}
.brand-product-item-layout .logo .isEqp {
  display: inline-block;
  height: 66px;
  padding: 13px 0;
  box-sizing: border-box;
  margin-left: 10%;
}
.brand-product-item-layout .logo .isEqp .eqp-icon-size {
  display: block;
  width: 100%;
  max-width: 62px;
  padding-left: 20px;
  box-sizing: border-box;
}
.brand-product-item-layout .content {
  flex: 1 0 auto;
  max-width: 600px;
  margin: 0 auto;
}
.brand-product-item-layout .content .product-thumbnail {
  width: 100px;
}
.brand-product-item-layout .content .layout-with-button {
  flex: 1 0 auto;
  position: relative;
  padding: 0 10px 0 10px;
  -webkit-align-self: center;
  align-self: stretch;
}
.brand-product-item-layout .content .layout-with-button.layout-column {
  display: flex;
  flex: 1 0;
}
.brand-product-item-layout .content .layout-with-button p {
  margin-top: 5px;
  margin-bottom: 10px;
}
.brand-product-item-layout .content .layout-with-button .button-bottom-wrapper {
  text-align: center;
  width: 100%;
}
.feature-product .list-flex-content {
  max-width: 260px;
  margin: 0 auto;
  padding: 0 0.5em;
}
.feature-product .list-flex-content p {
  margin-top: 0;
}
.feature-product .h3,
.feature-product h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.1;
}
.feature-product .brandname {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  color: #666666;
  display: table;
  margin: 15px auto 10px;
  min-height: 36px;
}
.feature-product .brandname .eqp-silver {
  padding-left: 45px;
  background-image: url("../assets/images/icons/e-q-p-silver-icon-no-label.png");
  background-repeat: no-repeat;
  background-size: 36px 36px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  min-height: 36px;
  display: table-cell;
}
.feature-product .brandname .eqp-gold {
  padding-left: 45px;
  background-image: url("../assets/images/icons/e-q-p-gold-icon-no-label.png");
  background-repeat: no-repeat;
  background-size: 36px 36px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  min-height: 36px;
}
.feature-product .brandname.text-left {
  margin-left: 0;
  margin-right: 0;
}
.feature-product img {
  max-height: 100px;
}
.condition-item-list {
  text-align: center;
  border-right: 1px solid #ccc;
}
.condition-item-list .condition-item {
  height: 100%;
}
.condition-item-list .condition-item .media-list {
  padding-left: 0;
  list-style: none;
  height: 100%;
  margin-bottom: 0;
}
.condition-item-list .condition-item .media-list .condition {
  height: 100%;
}
.condition-item-list .condition-item .media-list .condition .body {
  text-align: center;
  padding-left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-direction: column;
  /* Safari */
  display: -moz-flex;
  /* mozilla */
  -moz-flex-direction: column;
  /* mozilla */
}
.condition-item-list .condition-item .media-list .condition .body h3 {
  font-size: 22px;
  text-align: center;
  height: auto;
  width: 100%;
  color: #333333;
  font-weight: normal;
}
.condition-item-list .condition-item .media-list .condition .body p {
  font-size: 18px;
  width: 100%;
  color: #666666;
  font-weight: normal;
}
.condition-item-list .condition-item .media-list .condition .body p:last-child {
  margin-top: auto;
}
.condition-item-list .condition-item .media-list .condition .body a {
  text-transform: uppercase;
  width: 100%;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
}
.condition-item-list:last-child {
  border-right: none;
}
.carousel .controls .pagination .page {
  width: 184px;
  display: block;
  padding: 0;
  float: left;
  border-top: solid 3px transparent;
}
.carousel .controls .pagination .page span {
  color: #999999;
  font-weight: 500;
  padding: 5px 0;
  display: block;
  width: 100%;
  height: 100%;
  border-top: solid 1px #76bd23;
}
.carousel .controls .pagination .active {
  border-top: solid 3px #76bd23;
}
.carousel .controls .pagination .active span {
  background-color: transparent;
  color: #000000;
}
#discover-emerson .wrapper .item {
  display: none;
}
.ng-hide,
[ng-cloak] {
  display: none !important;
}
.ng-show {
  display: block !important;
}
#discover-emerson .brand-item .product-thumbnail img {
  height: 73px;
}
#discover-emerson .brand-item .product-thumbnail img.eqp {
  width: 43px;
  height: auto;
  display: block;
  margin: 17px auto 0;
}
@media (max-width: 959px) {
  #discover-emerson .brand-product.media,
  .condition {
    max-width: 300px;
    margin: 0 auto;
  }
  .condition-item-list {
    border-right: none;
  }
  .featured-block {
    max-width: 400px;
    margin: 0 auto;
  }
  .featured-block li.list-flex-item {
    margin-bottom: 20px;
  }
  .featured-block li.list-flex-item.border-right {
    border-right: 0;
  }
  .featured-block li.list-flex-item:last-child {
    margin-bottom: 0;
  }
}
/*layout-xs*/
@media (max-width: 599px) {
  #discover-emerson .tabs-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
  }
  #discover-emerson md-tabs-canvas {
    height: 130px;
  }
  #discover-emerson md-pagination-wrapper,
  #discover-emerson md-tabs-canvas {
    width: 100%;
  }
  .media-body h3 {
    height: initial;
  }
  .icon-list {
    text-align: center;
  }
  #support-for-your-practice .media-right {
    text-align: center;
  }
  #support-for-your-practice .media-right h3 {
    text-align: center;
    margin-top: 30px;
  }
  #support-for-your-practice .media-right .emersonicon-class {
    width: 50%;
    margin: 10px auto;
  }
  .wellevate-mobile {
    width: 300px;
    height: auto;
  }
}
@media (max-width: 599px) and (orientation: landscape) {
  .brand-item-list .media-list {
    width: 38%;
    margin: 0 auto;
  }
  li.brand-product {
    width: 50%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 959px) {
  .education-md-tabs-wrapper-padding .featured-block {
    max-width: none;
    margin: 0px;
  }
}
.tabs-wrapper md-tabs.md-dynamic-height md-tabs-content-wrapper md-content {
  overflow: hidden;
}
.shipping-address-list {
  margin-top: 30px;
}
.shipping-address-list .add-address .search-address {
  margin-top: -65px;
}
@media (max-width: 960px) {
  .shipping-address-list .add-address .search-address {
    margin: 15px 0 10px 0;
  }
}
.dropdown {
  float: left;
  display: block;
  width: 250px;
}
.dropdown div {
  float: left;
  width: 100%;
}
.dropdown div.dropdown-active div.dropdown-display {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown div div.dropdown-display {
  float: left;
  width: 100%;
  background: white;
  cursor: pointer;
  border: solid 1px #ccc;
  box-sizing: border-box;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  padding: 6px 11px;
  font-size: 16px;
  color: #4399c5;
  position: relative;
}
.dropdown div div.dropdown-display:after {
  content: '›';
  position: absolute;
  top: 50%;
  right: 5px;
  height: 5px;
  width: 10px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 1.5rem;
}
.dropdown div div.dropdown-list {
  float: left;
  position: relative;
  width: 100%;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
  transition: transform ease 250ms;
  z-index: 999;
}
.dropdown div div.dropdown-list > div {
  position: absolute;
  width: 100%;
  z-index: 2;
  cursor: pointer;
  background: white;
  border: solid 1px #ccc;
  outline: none;
  box-sizing: border-box;
}
.dropdown div div.dropdown-list > div div {
  float: left;
  width: 100%;
  padding: 11px;
  font-size: 12px;
  box-sizing: border-box;
  border-top: none;
  position: relative;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  outline: none;
  font-weight: 300;
}
.dropdown div div.dropdown-list > div div:hover {
  background: #f6f6f6;
}
.dropdown div div.dropdown-list > div div span {
  float: left;
  width: 100%;
  position: relative;
  padding-right: 5px;
  box-sizing: border-box;
  color: #4399c5;
  font-size: 16px;
}
.dropdown div.dropdown-active div.dropdown-list {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.dropdown.small {
  min-width: 101px;
  width: auto;
  max-width: 150px;
  min-height: 22px;
}
.dropdown.small div div.dropdown-display {
  font-size: 12px;
  padding: 3px 7px;
}
.dropdown.small div div.dropdown-display:after {
  right: 0;
}
.dropdown.small div div.dropdown-list > div div {
  font-size: 12px;
  padding: 3px 7px;
}
.dropdown.small div div.dropdown-list > div div span {
  font-size: 12px;
}
.dropdown.green div div.dropdown-display {
  color: #669933;
}
.dropdown.green div div.dropdown-display > div div span {
  color: #669933;
}
.dropdown.green div div.dropdown-list > div div span {
  color: #669933;
}
.dropdown.search {
  width: 100%;
  height: 50px;
}
.dropdown.search div div.dropdown-display {
  font-size: 16px;
  padding: 16px 25px;
  height: 50px;
  border-radius: 0;
  margin-top: 0;
  border-top: 0;
  border-right: 0;
  box-sizing: border-box;
  color: #ffffff;
  background-color: #76bd23;
  text-transform: uppercase;
}
.dropdown.search div div.dropdown-display:after {
  right: 0;
}
.dropdown.search div div.dropdown-list > div {
  color: #ffffff;
  background-color: #76bd23;
}
.dropdown.search div div.dropdown-list > div div {
  font-size: 16px;
  padding: 16px 25px;
  color: #76bd23;
  text-transform: uppercase;
}
.dropdown.search div div.dropdown-list > div div:hover {
  background: #cccccc;
}
.dropdown.search div div.dropdown-list > div div span {
  font-size: 16px;
  color: #ffffff;
}
.dropdown.search-m {
  width: 100%;
  height: 50px;
}
.dropdown.search-m div div.dropdown-display {
  font-size: 12px;
  padding: 14px 10px 10px 10px;
  height: 40px;
  border-radius: 0;
  margin-top: 0;
  border-top: 0;
  border-right: 0;
  box-sizing: border-box;
  color: #ffffff;
  background-color: #76bd23;
  text-transform: uppercase;
  text-align: left;
  margin-top: 5px;
}
.dropdown.search-m div div.dropdown-display:after {
  right: 0;
}
.dropdown.search-m div div.dropdown-list > div {
  color: #ffffff;
  background-color: #76bd23;
  text-align: left;
}
.dropdown.search-m div div.dropdown-list > div div {
  font-size: 12px;
  padding: 14px 10px 10px 10px;
  color: #76bd23;
  text-transform: uppercase;
}
.dropdown.search-m div div.dropdown-list > div div:hover {
  background: #cccccc;
}
.dropdown.search-m div div.dropdown-list > div div span {
  font-size: 12px;
  color: #ffffff;
}
.toast-head {
  font-weight: bold;
}
.email-description {
  font-size: 15px;
}
#existing-address md-pagination-wrapper {
  width: 100% !important;
}
#existing-address md-toolbar {
  border-bottom: solid 1px #d7d7d7;
  min-height: 180px;
  height: auto;
}
#existing-address md-toolbar p {
  font-size: 18px;
  font-weight: 300;
  padding: 30px 60px 5px;
  margin: 0;
}
#existing-address md-toolbar .shipping-address-list-wrapper {
  margin: 20px 30px 20px 60px;
}
#existing-address md-toolbar .shipping-address-list-wrapper .shipping-title {
  display: none;
}
#existing-address md-toolbar .shipping-address-list-wrapper .shipping-description {
  display: none;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs {
  overflow: visible;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs.show-a-tab md-tabs-wrapper {
  border-radius: 2px;
  border: 0;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper {
  border-radius: 3px;
  border: solid 1px #d7d7d7;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper md-tabs-canvas {
  overflow: visible;
  height: 38px;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper md-tabs-canvas md-pagination-wrapper {
  height: 38px;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper md-tabs-canvas md-tab-item {
  overflow: visible;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab {
  background-color: #f5f5f5;
  box-sizing: border-box;
  float: left;
  text-align: center;
  color: #999999;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 7px 24px;
  width: 50%;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab a.tooltip-container {
  background: none;
  width: 100%;
  outline: none;
  color: inherit;
  vertical-align: baseline;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab a.tooltip-container:hover {
  width: 100%;
  text-decoration: none;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab a.tooltip-container .tooltip {
  text-align: left;
  height: auto;
  font-size: 12px;
  left: -30px;
  bottom: -124px;
  min-height: 50px;
  width: 250px;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab a.tooltip-container .tooltip span {
  word-break: break-word;
  font-size: 14px;
  white-space: initial;
  text-transform: none;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab a.tooltip-container .tooltip span p {
  font-size: 14px;
  font-weight: normal;
  padding: 0;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-active {
  color: #ffffff;
  background-color: #4399c5;
}
#existing-address md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-active a {
  color: #ffffff;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper {
  margin: 0 0 0 40px;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping {
  width: 100%;
  float: right;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping .md-errors-spacer {
  min-height: 0;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping .input-message {
  clear: both;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  text-align: left;
  color: #333333;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping label {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.2px;
  text-align: left;
  color: #666666;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping input {
  color: #4399c5;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0.2px;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4399c5;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping input::-moz-placeholder {
  /* Firefox 19+ */
  color: #4399c5;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping input:-ms-input-placeholder {
  /* IE 10+ */
  color: #4399c5;
}
#existing-address md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping input:-moz-placeholder {
  /* Firefox 18- */
  color: #4399c5;
}
#existing-address .address-list-wrapper {
  width: 100%;
  cursor: pointer;
}
#existing-address md-list {
  background-color: transparent;
  padding: 0;
}
#existing-address md-list-item {
  background-color: transparent;
  border-bottom: solid 1px #d7d7d7;
}
#existing-address md-list-item:hover {
  background: rgba(161, 233, 77, 0.2);
}
#existing-address md-list-item md-radio-button {
  margin-bottom: 0px;
  padding-left: 10px;
  padding-right: 30px;
}
#existing-address md-list-item md-radio-button .md-container {
  left: 10px;
}
#existing-address md-list-item p {
  margin: 1em 0;
}
#existing-address md-list-item .md-button.md-no-style {
  background-color: transparent;
}
#existing-address md-list-item .md-button.md-no-style button {
  background-color: transparent;
}
#existing-address md-list-item .md-button.md-no-style button.md-button.confirm-remove {
  display: inline-block;
  margin: 1em;
  background-color: #76bd23;
}
#existing-address md-list-item .md-button.md-no-style button.cancel-remove {
  display: inline-block;
  margin: 1em;
  background-color: #fff;
  border: 1px solid #76bd23;
  color: #76bd23;
}
#existing-address md-list-item .address-list,
#existing-address md-list-item .remove-warning-wrapper {
  width: 100%;
}
#existing-address md-list-item.bold-text p {
  font-weight: 500;
}
#existing-address md-dialog-content {
  background-color: #f2f2f2;
  padding: 0 0 72px;
}
#existing-address md-dialog-content .loading-wrapper {
  margin: 50px auto;
}
#existing-address md-dialog-content .address-info-content table {
  width: 100%;
  font-size: 16px;
  text-align: left;
  color: #333333;
}
#existing-address md-dialog-content .address-info-content table .bold-text {
  font-weight: bold;
}
#existing-address md-dialog-content .address-info-content table tbody tr {
  border-bottom: solid 1px #d7d7d7;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
#existing-address md-dialog-content .address-info-content table td {
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1.2;
  box-sizing: border-box;
}
#existing-address md-dialog-content .address-info-content table td:last-child {
  padding: 12px 10px;
}
#existing-address md-dialog-content .address-info-content table td .checked-mark {
  text-align: center;
}
#existing-address md-dialog-content .address-info-content .md-label {
  width: 100%;
}
#existing-address .selected-ship-address-button button.md-button {
  height: 40px;
  margin: 15px 0;
}
#existing-address .selected-ship-address-button button.md-button span {
  padding: 0 30px;
}
#existing-address .md-secondary-container {
  width: 40px;
}
#existing-address md-dialog .md-toolbar-tools .md-close.md-button.search-close {
  min-height: 25px;
  right: 37px;
  top: 8px;
  height: 25px;
  width: 25px;
  padding: 6px;
}
#existing-address .disable-address-selection {
  background-color: #999999;
  pointer-events: none;
}
#existing-address .shipping-warning {
  color: #e00603;
}
expansion-panel:last-of-type .expansion-panel-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
expansion-panel:last-of-type .expansion-panel-row.expansion-panel-open {
  border-bottom: none;
}
expansion-panel .expansion-panel {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
expansion-panel .expansion-panel .expansion-panel-row {
  padding: 9px 12px 9px 24px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
expansion-panel .expansion-panel .expansion-panel-row .title {
  font-size: 17px;
}
expansion-panel .expansion-panel .expansion-panel-row .md-icon-button {
  color: rgba(0, 0, 0, 0.54);
}
expansion-panel .expansion-panel .expansion-panel-row .md-icon-button md-icon {
  -webkit-transition: transform 0.16s linear;
  transition: transform 0.16s linear;
}
expansion-panel .expansion-panel .expansion-panel-row .md-icon-button md-icon.flipped {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
expansion-panel .expansion-panel .expansion-panel-content {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 24px;
  overflow: hidden;
}
expansion-panel .expansion-panel .expansion-panel-content.ng-enter,
expansion-panel .expansion-panel .expansion-panel-content.ng-leave {
  -webkit-transform: max-height 0.16s linear;
  transition: max-height 0.16s linear;
}
expansion-panel .expansion-panel .expansion-panel-content.ng-enter,
expansion-panel .expansion-panel .expansion-panel-content.ng-leave.ng-leave-active {
  max-height: 0;
}
expansion-panel .expansion-panel .expansion-panel-content.ng-leave,
expansion-panel .expansion-panel .expansion-panel-content.ng-enter.ng-enter-active {
  max-height: 500px;
}
.address_val_modal .btn-primary {
  background-color: #79c143 !important;
  border: 1px solid #79c143 !important;
  color: #fff !important;
  outline: none;
}
.address_val_modal .btn-primary.skip_btn {
  background-color: #fff !important;
  color: #79c143 !important;
  border: 1px solid #79c143 !important;
  outline: none;
}
.address_val_modal .radio input[type=radio]:checked + .radio-label:before {
  background-color: #0063a5 !important;
  box-shadow: inset 0 0 0 4px #fff !important;
}
.address_val_modal .radio input[type=radio] + .radio-label:before {
  border: 1px solid #000000 !important;
}
.address_val_modal .flexrow {
  margin-left: 0px !important;
}
.express-checkout-wrapper {
  background-color: #f2f2f2;
  padding: 18px 0;
  margin-bottom: 10px;
}
.express-checkout-wrapper .vertical-divider {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  height: 88px;
  margin-right: 20px;
  padding-left: 20px;
}
.express-checkout-wrapper .md-button {
  min-width: 240px;
}
@media (max-width: 959px) {
  .express-checkout-wrapper .md-button {
    min-width: 160px;
  }
  .express-checkout-wrapper .md-button span {
    max-width: 80%;
    display: inline-block;
    word-wrap: break-word;
  }
}
.express-checkout-wrapper h2,
.express-checkout-wrapper p {
  font-size: 14px;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.67);
  font-weight: 500;
}
.express-checkout-wrapper p {
  margin: 0;
  font-weight: normal;
}
.express-checkout-wrapper p.text-capitalize {
  text-transform: capitalize;
}
.express-checkout-wrapper p a {
  font-weight: 500;
}
.express-checkout-wrapper h2 {
  margin-top: 0;
  margin-bottom: 5px;
  text-align: left;
}
.express-checkout-wrapper .uppercase {
  text-transform: uppercase;
}
.express-checkout-wrapper .default-settings-section {
  height: auto;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}
.express-checkout-wrapper .default-setting {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 5px;
}
.express-checkout-wrapper md-input-container {
  padding: 0 12px 0 0;
  margin-top: 14px;
}
.express-checkout-wrapper md-input-container md-select md-select-value span {
  font-size: 14px;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #4399c5;
}
.express-checkout-wrapper .shipping-address {
  margin-top: 0;
  word-wrap: break-word;
}
@media (max-width: 600px) {
  .express-checkout-wrapper .default-settings-section {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 960px) {
  .express-checkout-wrapper {
    margin-top: 10px;
  }
}
hr.gray {
  border-top: solid 1px #b7b7b7;
  margin: 20px 0;
}
.md-button.md-icon-button.md-icon-not-rounded {
  border-radius: 0;
}
.md-button.md-icon-button.md-icon-feedback {
  width: 78px;
  height: 100%;
  line-height: 16px;
}
.md-button.md-icon-button.md-icon-feedback .emersonicon-class {
  color: #4399c5;
}
.md-button.md-icon-button.md-icon-feedback:hover {
  background-color: transparent;
}
.emersonicon.emersonicon-feedback:before {
  background-image: url('/assets/images/feedback-icon.png');
  background-position: 0 0;
  width: 29px;
  height: 29px;
}
.md-icon-button.md-button:hover .emersonicon.emersonicon-feedback:before,
.md-icon-button.md-button.md-open .emersonicon.emersonicon-feedback:before {
  background-image: url('/assets/images/feedback-icon.png');
  background-position: 0 0;
}
#feedback-modal .mtn {
  margin-top: 0 !important;
}
#feedback-modal md-toolbar {
  min-height: 86px;
}
#feedback-modal .md-toolbar-tools {
  min-height: 50px;
  height: 87px;
}
#feedback-modal .md-toolbar-tools .md-toolbar {
  border-bottom: solid 1px #b7b7b7;
}
#feedback-modal .md-toolbar-tools h2 {
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.3px;
  color: #333333;
  text-align: left;
  font-weight: 400;
  padding: 30px 0 20px 0;
}
#feedback-modal md-dialog-content {
  padding: 20px;
}
#feedback-modal md-dialog-content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  color: #333333;
}
#feedback-modal .form-inline md-radio-button {
  float: left;
  margin-right: 20px;
}
#feedback-modal .form-inline md-radio-button .md-label {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
}
#feedback-modal .form-inline md-radio-button .md-off {
  border: solid 1px rgba(218, 218, 218, 0.6);
}
#feedback-modal md-input-container {
  width: 100%;
}
#feedback-modal .right-divider {
  border-right: solid 1px #b7b7b7;
  padding-right: 20px;
  margin-right: 20px;
}
#feedback-modal md-dialog-actions .md-button {
  min-width: 157px;
}
@media (max-width: 959px) {
  #feedback-modal .md-toolbar-tools h2 {
    font-size: 24px;
  }
  #feedback-modal md-dialog-content p {
    font-size: 14px;
  }
  #feedback-modal .right-divider {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}
footer {
  height: auto;
  background-color: #666666;
  width: 100%;
  float: left;
}
footer h3 {
  font-size: 16px;
  line-height: 1.1;
  font-weight: bold;
  color: #FFFFFF;
}
footer a {
  font-size: 16px;
  color: #666666;
}
footer img {
  height: 28px;
  width: 228px;
}
.disclaimer {
  font-size: 12px;
  color: #666666;
  margin-top: 12px;
}
#footer p.emerson-logo-footer {
  margin-bottom: 0px;
  margin-top: 65px;
}
footer.footer {
  text-align: center;
  padding: 30px 0;
  margin-top: 70px;
  border-top: 1px solid #E5E5E5;
}
.ee-list-unstyled {
  margin: 30px 0 30px 0;
  padding-left: 0;
}
.ee-list-unstyled li {
  list-style: none;
  padding: 0;
  margin: 15px 0;
}
.social-media-list ul {
  margin: 13px 0 19px 0;
}
.social-media-list li {
  display: inline-block;
}
hr {
  border-top: 1px solid #999999;
}
.ee-footer-section {
  text-align: center;
  margin: 0px 120px;
  padding: 10px 0;
}
.ee-footer-section ul li {
  display: inline-block;
  margin: 0px 5px 10px 5px;
}
.ee-footer-section ul li a {
  font-size: 16px;
  color: #FFFFFF;
}
.ee-footer-section p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 25px;
}
.ee-footer-section span {
  color: #FFFFFF;
  line-height: 21px;
}
.ee-footer-section .footerLinks {
  margin: 16px 0;
  line-height: 21px;
}
.social-media-list .ee-icon {
  margin: 0 9px;
  opacity: 0.5;
}
.social-media-list .ee-icon a img {
  width: 23px;
  height: 24px;
}
.social-media-list .ee-icon:hover {
  opacity: 1;
}
.social-media-list .ee-icon a {
  display: block;
  width: 100%;
  height: 100%;
}
.social-media-list .ee-icon-blog {
  margin: 0 9px;
  opacity: 0.5;
  padding-top: 10px;
}
.social-media-list .ee-icon-blog a img {
  width: 47px;
  height: 23px;
  vertical-align: bottom;
}
.social-media-list .ee-icon-blog:hover {
  opacity: 1;
}
.social-media-list .ee-icon-blog a {
  display: block;
  width: 100%;
  height: 100%;
}
/*chat*/
.ee-chat {
  position: fixed;
  bottom: 0;
  right: 30px;
  height: 28px;
  width: 165px;
  background: url('/assets/images/chat.png') no-repeat;
  z-index: 2;
}
#footer.padding {
  padding-bottom: 50px;
}
#ee-chat.movePos {
  bottom: 64px !important;
}
#supportkey {
  font-size: 13px;
  color: #666;
}
/*layout-sm*/
@media (min-width: 768px) and (max-width: 1023px) {
  .ee-footer-section {
    margin-right: 0px;
    margin-left: 0px;
  }
  .ee-footer-section ul li a {
    font-size: 14px;
  }
  .ee-footer-section p {
    font-size: 13px;
    line-height: 18px;
  }
  .ee-footer-section .footerLinks {
    line-height: 28px;
  }
  .social-media-list {
    margin-left: -13px;
  }
  .social-media-list ul {
    margin: 15px 0 20px 0;
  }
  .social-media-list .ee-icon {
    margin: 0 8px;
  }
  .social-media-list .ee-icon-blog {
    margin: 0 8px;
  }
  #footer.padding {
    padding-bottom: 90px;
  }
  #footer .copyright {
    margin-bottom: 70px;
  }
  #footer p.emerson-logo-footer {
    margin: 1em 0;
  }
  .ee-chat {
    position: fixed;
    bottom: 0;
    right: 30px;
    height: 41px;
    width: 165px;
    background: url('/assets/images/chat-mobile.png') no-repeat 100% 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .ee-footer-section {
    margin-right: 0px;
    margin-left: 0px;
  }
  .ee-footer-section ul li a {
    font-size: 14px;
  }
  .ee-footer-section .footerLinks {
    margin: 0 0 27px 0;
    line-height: 28px;
  }
  .ee-footer-section p {
    font-size: 13px;
    line-height: 18px;
  }
  .social-media-list ul {
    margin: 15px 0 28px 0;
  }
  .social-media-list .ee-icon {
    margin: 0 8px;
  }
  .social-media-list .ee-icon a img {
    width: 21px;
    height: 22px;
  }
  .social-media-list .ee-icon-blog {
    margin: 0 8px;
  }
  .social-media-list .ee-icon-blog a img {
    width: 44px;
    height: 21px;
  }
}
.info-section {
  margin-bottom: 20px;
}
/*credit card*/
md-dialog credit-card-form md-input-container {
  margin-right: 20px;
}
md-dialog.ccForm .md-button.md-submit {
  min-width: 200px;
  display: block;
  margin: 0 auto;
}
md-dialog.ccForm .mtn {
  margin-top: 0 !important;
}
md-dialog.ccForm md-dialog-content page-level-alert-messaging {
  display: none;
}
.credit-card-expired {
  color: #cc3366;
}
.credit-cart-billing-address {
  border-bottom: dashed 1px #999999;
}
.cart-type-error {
  font-weight: bold;
  font-size: 14px;
  color: #cc3366;
}
.rxa-date-picker input[type=date]::-webkit-calendar-picker-indicator {
  font-size: 10px;
  opacity: 1;
  background-color: #ffffff;
  color: #0e98df;
  cursor: pointer;
}
._720kb-datepicker-calendar {
  top: 33px;
}
._720kb-datepicker-calendar-day._720kb-datepicker-active {
  background: #ddd;
}
.rx-error {
  margin-top: 10px;
}
.rx-error div {
  color: #f00;
  font-size: 14px;
}
table tr.credential-on-edit {
  border-bottom: 0;
}
table tr.dropzone-being-edited {
  padding: 15px 0;
}
.rx-dropzone-container {
  margin-left: 50px;
}
.rx-dropzone-container .dropzone {
  float: none;
  width: 100%;
}
.rx-dropzone-container button {
  width: 120px;
  margin: 20px auto 0;
  display: block;
}
.gift-balance-notice {
  color: #4399c5;
  font-size: 18px;
  padding-top: 8px;
}
hero {
  margin-top: 0;
  width: 100%;
}
@media (max-width: 959px) {
  hero {
    margin-top: -137px;
  }
}
.hero-fma {
  height: 668px;
  position: relative;
  overflow: hidden;
}
.hero-fma .hero-image {
  left: 0;
  position: absolute;
  right: 0;
  height: 668px;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-fma .hero-unit {
  position: relative;
  padding: 30px;
  color: #000000;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  max-width: 1140px;
  height: 668px;
  margin: 0 auto;
}
.hero-fma .hero-unit h1 {
  font-size: 60px;
  font-weight: 300;
  line-height: 1.3;
  color: #333333;
  margin-top: 150px;
  line-height: 1;
}
.hero-fma .hero-unit h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
  color: #333333;
}
.hero-fma .hero-unit h2.headertitle {
  font-size: 60px;
  font-weight: 300;
  line-height: 1.3;
  color: #333333;
  margin-top: 150px;
  line-height: 1;
}
.hero-fma .hero-unit .hero-copy h1 {
  margin-bottom: 5px;
}
.hero-fma .hero-unit .hero-copy h2 {
  margin-top: 0;
}
.hero-fma .hero-unit .hero-copy h2.headertitle {
  margin-bottom: 5px;
  margin-top: 150px;
}
.hero-fma .hero-unit .hero-buttons {
  margin-top: 50px;
}
.hero-fma .hero-carousel {
  height: 668px;
  margin-top: 0;
}
hero {
  display: block;
  height: 688px;
  overflow: hidden;
  position: relative;
}
hero .hero-button {
  display: inline;
}
hero .hero-button a.md-button {
  width: 133px;
}
hero .dots-nav {
  position: absolute;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
  bottom: 50px;
}
hero .dots-nav .dots {
  padding-left: 0;
}
hero .dots li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: #999;
  border: 1px solid #fff;
  border-radius: 10px;
}
hero .dots li.active,
hero .dots li.hover {
  background-color: #000 \9;
  background-color: #444;
  border: 1px solid #fff;
}
hero .dots li:focus {
  outline: 0;
}
hero .scroll-arrow {
  background: url('/assets/images/scroll-arrow.png') no-repeat 50% 0;
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.hero-fma .hero-carousel li {
  opacity: 0 !important;
}
.hero-fma .hero-carousel li.active {
  opacity: 1 !important;
}
.hero-fma .slide.ng-hide {
  left: 0;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.slide.ng-hide-add,
.slide.ng-hide-remove {
  -webkit-transition: all linear 0.5s;
  -moz-transition: all linear 0.5s;
  -o-transition: all linear 0.5s;
  transition: all linear 0.5s;
  display: block!important;
}
.slide.ng-hide-add.ng-hide-add-active,
.slide.ng-hide-remove {
  opacity: 0;
}
.slide.ng-hide-add,
.slide.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
/*animations*/
.animate-enter,
.animate-leave {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.animate-enter {
  left: -100%;
}
.animate-enter.animate-enter-active {
  left: 0;
}
.animate-leave {
  left: 0;
}
.animate-leave.animate-leave-active {
  left: 100%;
}
/*Carousel CSS*/
ul.hero-carousel > li {
  color: #000;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  overflow: visible;
  vertical-align: top;
  position: absolute;
  left: 0;
  right: 0;
  white-space: normal;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  -ms-touch-action: pan-y !important;
  touch-action: pan-y !important;
}
input[type=range] {
  width: 300px;
}
@media (max-width: 1279px) {
  .hero-fma .hero-unit h1 {
    font-size: 50px;
  }
  .hero-fma .hero-unit h2 {
    font-size: 20px;
  }
  .hero-fma .hero-unit h2.headertitle {
    font-size: 50px;
  }
}
/*for tablet and mobile device*/
@media (max-device-width: 1024px) {
  hero .dots li.hover {
    background-color: #000 \9;
    background-color: #999;
    border: 1px solid #fff;
  }
  hero .dots li.active {
    background-color: #000 \9;
    background-color: #444;
    border: 1px solid #fff;
  }
  .hero-fma .hero-unit h1,
  h2.headertitle {
    font-size: 36px;
  }
}
@media (max-width: 959px) {
  hero {
    height: 450px;
  }
  .hero-fma {
    height: 450px;
  }
  .hero-fma .hero-carousel {
    height: 450px;
  }
  .hero-fma .hero-image {
    height: 450px;
    left: 0;
    right: 0;
  }
  .hero-fma .hero-unit {
    height: 450px;
  }
  .hero-fma .hero-unit h1 {
    font-size: 24px;
    line-height: 1.3;
  }
  .hero-fma .hero-unit h2 {
    font-size: 14px;
  }
  .hero-fma .hero-unit h2.headertitle {
    font-size: 24px;
    line-height: 1.3;
  }
  .hero-fma .hero-image .image {
    text-align: center;
  }
  .hero-fma .hero-image .image img {
    width: auto;
  }
  .hero-fma {
    min-height: initial;
  }
  .hero-fma .hero-unit {
    padding: 30px;
  }
  hero .dots li {
    width: 20px;
    height: 20px;
    margin: 1px 10px;
    background-color: #000 \9;
    background-color: #999;
    border: 1px solid #fff;
  }
  hero .dots-nav {
    top: 90%;
  }
  hero .dots li.active {
    background-color: #000 \9;
    background-color: #444;
    border: 1px solid #fff;
  }
}
@media (max-width: 959px) and (orientation: landscape) {
  hero {
    height: 450px;
  }
  .hero-fma {
    height: 450px;
  }
  .hero-fma .hero-carousel {
    height: 450px;
  }
}
@media (max-width: 599px) {
  hero {
    height: 450px;
  }
  .hero-fma {
    height: 450px;
  }
  .hero-fma .hero-carousel {
    height: 450px;
  }
  hero .hero-button a.md-button {
    width: 110px;
  }
}
@media (max-width: 599px) and (orientation: landscape) {
  hero {
    height: 450px;
  }
  .hero-fma {
    height: 450px;
  }
  .hero-fma .hero-carousel {
    height: 450px;
  }
  hero .dots-nav {
    top: 90%;
  }
}
@media screen and (max-width: 767px) {
  hero .dots-nav {
    left: 56%;
  }
}
@media screen and (max-width: 360px) {
  hero .hero-button a.md-button {
    width: 90px;
  }
  .hero-fma .hero-unit h1 {
    margin-bottom: 5px;
  }
  .hero-fma .hero-unit h2 {
    margin: 5px 0;
  }
  .hero-fma .hero-unit h2.headertitle {
    margin-bottom: 5px;
  }
}
.info-card {
  margin: 35px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 25px 16px;
}
.info-card .info-detail-wrapper {
  color: rgba(0, 0, 0, 0.77);
}
.info-card .info-detail-wrapper div[layout="row"] {
  line-height: 1.7em;
  text-transform: capitalize;
}
.info-card .info-detail-wrapper .data-type {
  width: 115px;
}
.info-card .info-detail-wrapper .data-value {
  font-weight: 700;
}
@media (max-width: 768px) {
  .info-card {
    font-size: 14px;
    width: unset;
  }
  .info-card .info-detail-wrapper .data-type {
    width: 80px;
  }
}
item-review-card > .item-review-card {
  background-color: #f8f8f8;
  padding: 12px 26px 12px 6px;
  margin-top: 15px;
  min-height: 100px;
}
item-review-card > .item-review-card.item-removed-row {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
item-review-card > .item-review-card.item-removed-row h2 {
  margin: 0;
  font-size: 30px;
}
item-review-card > .item-review-card img {
  height: 130px;
}
item-review-card > .item-review-card h3 {
  font-weight: 500;
  font-size: 22px;
}
item-review-card > .item-review-card .header-item {
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}
item-review-card > .item-review-card .price-and-remove-wrapper {
  align-self: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}
item-review-card > .item-review-card .price-and-remove-wrapper .total-price-wrapper {
  margin-left: 15px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
item-review-card > .item-review-card .price-and-remove-wrapper .total-price-wrapper .header-item {
  font-size: 26px;
  margin-bottom: 2px;
}
item-review-card > .item-review-card .price-and-remove-wrapper .total-price-wrapper a {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}
item-review-card > .item-review-card .item-warnings {
  max-width: 370px;
}
item-review-card > .item-review-card .item-warnings p {
  color: hsl(0, 55%, 40%);
  font-size: 14px;
  margin: 10px 0 0 0;
}
item-review-card > .item-review-card .md-icon-button {
  color: rgba(0, 0, 0, 0.54);
}
@media (max-width: 768px) {
  item-review-card > .item-review-card {
    padding: 8px 8px 4px 8px;
  }
  item-review-card > .item-review-card .price-and-remove-wrapper {
    background-color: #f8f8f8;
    margin-top: 8px;
    display: block;
  }
  item-review-card > .item-review-card .image-wrapper > img {
    height: 125px;
    margin-left: -19px;
  }
  item-review-card > .item-review-card h3 {
    font-size: 18px;
  }
  item-review-card > .item-review-card.item-removed-row h2 {
    font-size: 24px;
  }
  item-review-card > .item-review-card .total-price-wrapper[hide-xs] {
    display: none;
  }
  item-review-card > .item-review-card .total-price-wrapper[show-xs] {
    margin-top: 10px;
  }
  item-review-card > .item-review-card .total-price-wrapper[show-xs] .header-item {
    font-size: 27px;
    margin-bottom: 0;
    width: 135px;
    text-align: center;
  }
}
.mail-container {
  background-color: #EFEFEF;
  float: left;
  width: 100%;
}
.mail-container .align-center {
  text-align: center;
}
.mail-container .error {
  border: 1px solid #EC1C24;
}
.mail-container .join-our-mailing-list {
  height: 51px;
  padding-top: 30px;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  margin-bottom: 0;
}
.mail-container .info-msg {
  height: 41px;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 11px;
  margin-top: 8px;
}
.mail-container .input-box {
  height: 45px;
  width: 382px;
  color: #666666;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  display: inline;
}
.mail-container input:hover {
  border-color: #115EA5;
  border-width: 2px;
}
.mail-container button:hover {
  border: 2px solid #F6921E;
}
.mail-container .inputbox-error {
  border: 2px solid #EC1C24;
}
.mail-container .signup-button {
  height: 38px;
  width: 160px;
  margin-top: 32px;
  margin-bottom: 39px;
  background-color: black;
  border-radius: 9px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
}
.mail-container .error-msg-mail {
  margin-top: 1px;
  margin-bottom: 0;
  color: #EC1C24;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  display: none;
}
.mail-container .error-msg-mail.has-error {
  display: block;
}
.mail-container .thankyou-msg {
  margin-top: 41px;
  margin-bottom: 49px;
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
}
.mail-container button:disabled,
.mail-container button[disabled] {
  background-color: #666666;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mail-container .join-our-mailing-list {
    font-size: 20px;
    line-height: 26px;
  }
  .mail-container .info-msg {
    font-size: 16px;
    height: 38px;
    margin-bottom: 26px;
  }
  .mail-container .signup-button {
    width: 160px;
    font-size: 16px;
    line-height: 21px;
    height: 38px;
    margin-top: 33px;
  }
  .mail-container .thankyou-msg {
    font-size: 16px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .mail-container .join-our-mailing-list {
    font-size: 20px;
    line-height: 26px;
    height: 38px;
    margin-bottom: 1px;
    padding-top: 20px;
  }
  .mail-container .info-msg {
    font-size: 14px;
    line-height: 22px;
    margin: 0 48px 25px 49px;
  }
  .mail-container .signup-button {
    width: 262px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 32px;
    margin-bottom: 39px;
  }
  .mail-container .input-box {
    height: 38px;
    width: 263px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 25px;
  }
  .mail-container .thankyou-msg {
    font-size: 14px;
    line-height: 22px;
    margin: 25px 48px 0 49px;
  }
}
page-level-alert-messaging {
  position: relative;
  /*top: 121px;*/
  height: 100px;
  width: 100%;
  z-index: 100;
}
#bannerMessage {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 33px;
}
#bannerMessage.posRelative {
  position: relative;
}
#bannerMessage p {
  color: #ffffff;
}
#bannerMessage md-dialog {
  opacity: 1;
  background: #76BD23;
  max-height: initial;
  max-width: initial;
  box-shadow: none;
  width: 100%;
  min-width: initial;
  overflow: hidden;
}
#bannerMessage md-dialog md-dialog-content {
  padding: 0;
}
#bannerMessage md-dialog md-dialog-content .ie-browser-message {
  margin-top: 14px;
}
#bannerMessage md-dialog a {
  color: #333;
}
#bannerMessage md-dialog p {
  margin: 0;
}
#bannerMessage md-dialog p .error-icon {
  vertical-align: middle;
  margin-right: 12px;
}
#bannerMessage md-dialog md-toolbar {
  position: absolute;
  right: 135px;
  top: 14px;
  width: 30px;
  z-index: 2;
  min-height: unset;
}
#bannerMessage md-dialog md-toolbar .md-toolbar-tools {
  min-height: unset;
  height: unset;
}
#bannerMessage md-dialog md-toolbar .md-toolbar-tools .md-button {
  background: none;
  border-radius: 0;
  color: #ffffff;
  font-size: 22px;
}
#bannerMessage .close {
  color: #eef2f5;
  font-size: 19px;
  position: absolute;
  top: 19px;
  right: 134px;
}
.navbar-wrapper {
  background: rgba(255, 255, 255, 0.82);
  border-top: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  position: relative;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: 20;
}
.multi-cart-btn {
  min-height: 0;
  line-height: 24px;
  padding: 0 10px;
}
.navbar-wrapper.sticky-box {
  width: 100%;
}
.ee-navbar {
  position: relative;
}
.ee-navbar > li > a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  padding: 22px 30px 27px 0;
}
.navbar-default {
  border: 0;
  background-color: transparent;
  margin-bottom: 0;
}
.navbar-header {
  height: 96px;
  float: left;
}
.navbar-nav.ee-navbar {
  margin-top: 12px;
  float: left;
}
.navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
}
.navbar-collapse {
  width: auto;
  border-top: 0;
  box-shadow: none;
}
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  content: " ";
  display: table;
}
.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}
/*search*/
.ee-search .icon {
  margin: 0;
}
i.s24,
md-icon[md-font-icon].s24 {
  font-size: 24px!important;
  width: 24px!important;
  height: 24px!important;
  min-width: 24px!important;
  min-height: 24px!important;
  line-height: 24px!important;
}
.icon-magnify:before {
  content: " ";
  display: block;
  background-image: url('/assets/images/search-icon.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 50px;
}
.ee-search {
  height: 50px;
  line-height: 56px;
  background: #FFF;
  border: 1px solid #ccc;
  border-top: 0;
}
.ee-search input {
  color: rgba(0, 0, 0, 0.54);
  padding: 6px 12px;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  height: 49px;
  line-height: 22px;
  margin-top: 0;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  outline: 0;
}
.ee-search .md-search-icon {
  border-left: 1px solid #ccc;
  border-radius: 0;
  padding: 0 10px 0 15px;
  width: 50px;
  height: 20px;
  line-height: 20px;
}
.ee-mobile-nav .toolbar-sub-menu.search input {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  background-image: none;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  font-size: 14px;
}
/*Site Logo*/
.navbar-logo {
  height: auto;
  padding-top: 40px;
  margin-right: 55px;
  display: block;
}
.ee-mobile-nav {
  display: none;
}
.arrow-icon {
  display: inline-block;
  width: 10px;
  height: 6px;
  background-image: url('/assets/images/arrow.png');
  background-repeat: no-repeat;
}
.arrow-icon.green {
  background-image: url('/assets/images/icons/arrow-icon-green.png');
  width: 12px;
  height: 6px;
}
.reorder-flyout-image {
  width: 100%;
}
.reorder-flyout-text {
  margin-top: 40px;
}
.reorder-flyout-text > p {
  font-size: 14px;
}
/* Emerson Main Menu and Dropdown menu*/
.navbar-nav > li {
  float: left;
}
.navbar-nav > li a {
  text-transform: uppercase;
}
.shop-condition-links > a {
  text-transform: none !important;
}
.navbar-nav > li a center {
  text-transform: none;
  color: black;
}
.navbar-nav > li a center:hover {
  text-decoration: none;
}
.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.ee-navbar-main-menu {
  float: left;
  margin-top: 12px;
  margin-left: -75px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown a.ee-main-menu-dropdown-toggle {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  padding: 21px 30px 28px 0;
  display: block;
  color: #777;
  text-decoration: none;
  position: relative;
  z-index: 100;
  cursor: default;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown a.ee-main-menu-dropdown-toggle > span .arrow-icon {
  display: block;
  float: left;
  margin: 0 0 0 10px;
  padding: 0 0 0 15px;
  border-left: solid 1px #b7b7b7;
  background-position: center center;
  height: 24px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown a.ee-main-menu-dropdown-toggle .text {
  float: left;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown a.ee-main-menu-dropdown-toggle > span {
  cursor: pointer;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown a.ee-main-menu-dropdown-toggle > span:hover {
  color: #000000;
  background-color: transparent;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .select-menu {
  color: #000000 !important;
  background-color: transparent !important;
  font-weight: bold !important;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown #shopMenuDropdownToggle {
  padding-left: 75px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown.active a.ee-main-menu-dropdown-toggle > span {
  color: #000000;
  background-color: transparent;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .active-arrow {
  background-image: url('/assets/images/menu-triangle.png');
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 37px;
  height: 13px;
  position: absolute;
  bottom: -2px;
  z-index: 1001;
  margin-left: 10px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown #shopMenuActiveArrow {
  margin-left: 75px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown:last-child a.ee-main-menu-dropdown-toggle {
  padding-right: 15px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown.active > a a.ee-main-menu-dropdown {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  padding: 21px 30px 21px 0;
  display: block;
  color: #777;
  text-decoration: none;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown.active > a.active a.ee-main-menu-dropdown,
.ee-navbar-main-menu li.ee-main-menu-dropdown.active > a:hover a.ee-main-menu-dropdown {
  color: #000000;
  background-color: transparent;
}
.ee-main-menu-dropdown .ee-navbar-sub-menu .sub-menu-padding {
  padding: 0 0 0 25px;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu {
  height: 340px;
  box-sizing: border-box;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu p {
  font-size: 14px;
  line-height: 1.3;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-logo {
  max-width: 213px;
  max-height: 73px;
  height: 73px;
  margin: 0 auto 25px;
  padding-bottom: 10px;
  text-align: center;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-logo img {
  max-width: 213px;
  max-height: 73px;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .btn-align-center {
  margin: 0 auto;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .md-button {
  width: 55%;
  display: table;
  height: 38px;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .md-button span {
  display: table-cell;
  vertical-align: middle;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary p {
  margin: 20px 0;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .product-summary.media-body a,
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .product-summary.media-body h3 {
  padding: 0;
  font-size: 14px;
  line-height: 1.2;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .product-summary.media-body a span,
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item .brand-summary .product-summary.media-body h3 span {
  padding: 0 5px;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item-list {
  margin-top: 30px;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item-list .md-padding {
  border-right: solid 1px #d7d7d7;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item-list .md-padding:last-child {
  border-right: 0;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-product.media {
  text-align: center;
  padding: 0;
  display: inline-block;
  margin: 0 !important;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item-list .product-summary a {
  margin: 0;
  padding: 5px;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .brand-item-list .product-summary.media-body {
  padding-left: 0;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .browse-menu {
  text-align: center;
  margin: 30px 0 0;
  padding: 0;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .browse-menu li {
  display: inline-block;
  font-size: 16px;
  padding: 0 15px 0 0 !important;
  margin: 0 0 0 15px !important;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .browse-menu li.browse {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .browse-menu li.line-divider {
  border-right: solid 1px #d7d7d7 !important;
}
.ee-main-menu-dropdown:nth-child(2) .ee-navbar-sub-menu .browse-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu {
  height: 340px;
  box-sizing: border-box;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu .list-unstyled li {
  margin-bottom: 15px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu .list-unstyled li:last-child {
  margin-bottom: 0;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu .mt-divider {
  margin-top: 35px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu h2 {
  margin-left: 25px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu p {
  font-size: 14px;
  line-height: 1.3;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div {
  border-right: solid 1px #d7d7d7;
  padding-right: 20px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding {
  padding: 0 25px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media {
  width: 230px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media .image {
  border-right: solid 1px #d7d7d7;
  padding-right: 25px;
  margin-right: -25px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media .body {
  border-right: solid 1px #d7d7d7;
  padding-right: 25px;
  margin-right: -25px;
  overflow: hidden;
  height: 140px;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media .body .header {
  color: #79c143;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding:last-child .ee-media .image {
  border-right: 0;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding:last-child .ee-media .body {
  border-right: 0;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu h2 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 0;
}
.ee-main-menu-dropdown:nth-child(3) .ee-navbar-sub-menu .ee-media .body h3 {
  font-weight: 400;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu {
  height: 340px;
  box-sizing: border-box;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu .list-unstyled li {
  margin-bottom: 15px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu .list-unstyled li:last-child {
  margin-bottom: 0;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu .list-unstyled li a.md-button {
  margin: 0;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu .mt-divider {
  margin-top: 35px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu h2 {
  margin-left: 25px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu p {
  font-size: 14px;
  line-height: 1.3;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div {
  border-right: solid 1px #d7d7d7;
  padding-right: 20px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding {
  padding: 0 25px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media {
  width: 230px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media .image {
  border-right: solid 1px #d7d7d7;
  padding-right: 25px;
  margin-right: -25px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media .body {
  border-right: solid 1px #d7d7d7;
  padding-right: 25px;
  margin-right: -25px;
  overflow: hidden;
  height: 140px;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding .ee-media .body .header {
  color: #79c143;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding:last-child .ee-media .image {
  border-right: 0;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding:last-child .ee-media .body {
  border-right: 0;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu h2 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.ee-main-menu-dropdown:nth-child(4) .ee-navbar-sub-menu .ee-media .body h3 {
  font-weight: 400;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu {
  height: 340px;
  box-sizing: border-box;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu .list-unstyled li {
  margin-bottom: 15px;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu .list-unstyled li:last-child {
  margin-bottom: 0;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu p {
  font-size: 14px;
  line-height: 1.3;
  padding: 14px 2px 2px 2px;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu > li > div > div {
  border-right: solid 1px #d7d7d7;
  padding-right: 20px;
  margin-right: 20px;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu > li > div > div:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding {
  padding: 0 25px;
  border-right: solid 1px #d7d7d7;
  margin-top: 20px;
  text-align: center;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu > li > div > div:last-child > div:last-child {
  border-right: 0;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu > li > div > div:last-child > div .md-padding.flex-30 {
  padding-right: 0;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu h2 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.ee-main-menu-dropdown:nth-child(5) .ee-navbar-sub-menu .media-body h3 {
  height: 100%;
  font-weight: 700;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: left;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown {
  position: static;
  /*ee-main-menu-dropdown mouseover*/
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 1140px;
  padding: 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu.add-min-height {
  min-height: 415px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown {
  width: 23%;
  position: relative;
  border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
  border-right: rgba(0, 0, 0, 0.06) 1px solid;
  /*sub menu dropdown inside each Shop by*/
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.sub-menu-hide {
  min-height: 60px;
  border-right: rgba(0, 0, 0, 0.06) 1px solid;
  box-sizing: border-box;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover {
  color: #000000;
  text-decoration: none;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover a.ee-sub-menu-dropdown-toggle {
  color: #000000;
  background-color: #e5e2e2;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active {
  color: #000000;
  text-decoration: none;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active a.ee-sub-menu-dropdown-toggle {
  color: #000000;
  background-color: #e5e2e2;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.inactive {
  text-decoration: none;
  color: #669933;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.inactive a.ee-sub-menu-dropdown-toggle {
  color: #669933;
  background-color: rgba(229, 226, 226, 0.25);
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown a.ee-sub-menu-dropdown-toggle {
  display: block;
  padding: 20px 10px 20px 27px;
  background-color: rgba(229, 226, 226, 0.25);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: #669933;
  border-right: rgba(0, 0, 0, 0.06) 1px solid;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown a.ee-sub-menu-dropdown-toggle:hover {
  color: #000000;
  text-decoration: none;
  background-color: #e5e2e2;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown a.ee-sub-menu-dropdown-toggle .arrow-icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  float: right;
  margin: 6px 0 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  padding: 25px 30px;
  margin: 0;
  width: 334%;
  max-width: 874px;
  min-height: 355px;
  max-height: 355px;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none;
  box-sizing: border-box;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul li,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul li {
  border: 0;
  margin: 15px 20px 0 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul li ul,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul li ul {
  list-style-type: none;
  padding-left: 15px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul li ul li,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul li ul li {
  margin: 5px 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .condition-columns ul,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .condition-columns ul {
  margin: 0;
  -moz-column-count: 3;
  -moz-column-gap: 50px;
  -webkit-column-count: 3;
  -webkit-column-gap: 50px;
  column-count: 3;
  column-gap: 50px;
  -webkit-column-rule: 1px outset #d7d7d7;
  /* Chrome, Safari, Opera */
  -moz-column-rule: 1px outset #d7d7d7;
  /* Firefox */
  column-rule: 1px outset #d7d7d7;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .condition-columns ul > li,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .condition-columns ul > li {
  margin: 0;
  padding: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .condition-columns ul > li a,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .condition-columns ul > li a {
  display: block;
  margin-bottom: 15px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul.categories,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul.categories {
  font-size: 15px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul.categories a,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul.categories a {
  text-transform: capitalize;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul.categories .title,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul.categories .title {
  font-weight: bold;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items ul.categories .subcategories,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items ul.categories .subcategories {
  margin-top: 10px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .addl-categories,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .addl-categories {
  font-size: 15px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .addl-categories h3,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .addl-categories h3 {
  text-align: center;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .addl-categories .condition-columns ul,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .addl-categories .condition-columns ul {
  border-left: solid 1px #d7d7d7;
  padding-bottom: 5px;
  -webkit-column-count: 2;
  /* Chrome, Safari, Opera */
  -moz-column-count: 2;
  /* Firefox */
  column-count: 2;
  -webkit-column-gap: 132px;
  /* Chrome, Safari, Opera */
  -moz-column-gap: 132px;
  /* Firefox */
  column-gap: 132px;
  -webkit-column-rule: 0;
  /* Chrome, Safari, Opera */
  -moz-column-rule: 0;
  /* Firefox */
  column-rule: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .addl-categories ul,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .addl-categories ul {
  padding-left: 27px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .addl-categories ul li:first-child,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .addl-categories ul li:first-child {
  margin-top: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .addl-categories ul a,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .addl-categories ul a {
  text-transform: capitalize;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .list-flex li,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .list-flex li {
  margin-top: 0;
  margin-left: 0;
  padding-top: 0;
  padding-left: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .list-flex li .list-flex-content,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .list-flex li .list-flex-content {
  padding-left: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .featured-block.border-right li.list-flex-item,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .featured-block.border-right li.list-flex-item {
  border-right: 1px solid #cccccc;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .featured-block.border-right li.list-flex-item:last-child,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .featured-block.border-right li.list-flex-item:last-child {
  border-right: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown.active .ee-sub-menu-items .view-all-btn,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:hover .ee-sub-menu-items .view-all-btn {
  margin-top: 25px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-navbar-sub-menu .ee-sub-menu-dropdown:nth-child(1) .ee-sub-menu-items {
  padding: 45px 30px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .quality {
  text-align: center;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .quality img {
  margin-right: 10px;
  width: 93px;
  height: auto;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .quality a {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  display: block;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-logo {
  max-width: 213px;
  max-height: 73px;
  height: 73px;
  margin: 0 auto 25px;
  padding-bottom: 10px;
  text-align: center;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-logo img {
  max-width: 213px;
  max-height: 73px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .btn-align-center {
  margin: 0 auto;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .md-button {
  width: 55%;
  display: table;
  height: 38px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .md-button span {
  display: table-cell;
  vertical-align: middle;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary p {
  margin: 20px 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .product-summary.media-body a,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .product-summary.media-body h3 {
  padding: 0;
  font-size: 14px;
  line-height: 1.2;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .product-summary.media-body a span,
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item .brand-summary .product-summary.media-body h3 span {
  padding: 0 5px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-product.media {
  text-align: center;
  padding: 0;
  display: inline-block;
  margin: 0 !important;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item-list .product-summary a {
  margin: 0;
  padding: 5px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item-list .product-summary.media-body {
  padding-left: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .header-title {
  width: 100%;
  text-align: center;
  margin-top: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .browse-menu {
  text-align: center;
  margin: 30px 0 0;
  padding: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .browse-menu li {
  display: inline-block;
  font-size: 16px;
  padding: 0 15px 0 0 !important;
  margin: 0 0 0 15px !important;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .browse-menu li.browse {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .browse-menu li.line-divider {
  border-right: solid 1px #d7d7d7 !important;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .browse-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .md-padding-top-n {
  padding-top: 0 !important;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-items .brand-item-list {
  margin-top: 45px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1) .list-unstyled {
  margin: 0 20px 0 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1) .ee-sub-menu-items {
  padding: 45px 30px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1) .ee-sub-menu-items.right-divider div {
  border-right: 1px solid #f6f4f4;
  margin-right: 20px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1) .ee-sub-menu-items.right-divider div:last-child {
  border-right: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1):hover .ee-sub-menu-items {
  padding: 45px 30px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1):hover h3 {
  font-size: 16px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1):hover .list-inline li {
  padding-left: 0;
  padding-right: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(1):hover .brand-item li {
  margin: 10px 0 !important;
  padding: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(2):hover .ee-sub-menu-items {
  top: -100%;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(2):hover h3 {
  font-size: 16px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(2):hover .list-inline li {
  padding-left: 30px;
  padding-right: 30px;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(2):hover .brand-item .brand-product li {
  margin: 10px 0;
  padding: 0;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(3):hover .ee-sub-menu-items {
  top: -200%;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(4):hover .ee-sub-menu-items {
  top: -300%;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(5):hover .ee-sub-menu-items {
  top: -400%;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(6) a.ee-sub-menu-dropdown-toggle {
  background: #79c143;
  color: #ffffff;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(6):hover a.ee-sub-menu-dropdown-toggle {
  background: #79c143;
  color: #ffffff;
}
.ee-navbar-main-menu li.ee-main-menu-dropdown .ee-sub-menu-dropdown:nth-child(6):hover .ee-sub-menu-items {
  top: -500%;
  min-height: 355px;
  left: 101%;
}
.ee-navbar > li.ee-main-dropdown .ee-dropdown-menu .ee-sub-dropdown:hover .ee-sub-dropdown-menu .brand-item-list > li {
  margin: 0;
}
.ee-navbar-sub-menu h2 {
  text-align: left;
  font-size: 20px;
}
.ee-navbar-sub-menu h2:first-child {
  margin-top: 0;
  margin-left: 0;
}
.ee-navbar-sub-menu h3 {
  text-align: left;
  font-size: 18px;
}
.right-border .item {
  border-right: 1px solid #f6f4f4;
  padding-right: 8px;
  margin-right: 0;
}
.right-border .item:last-child {
  border-right: 0;
}
/*Toolbar*/
.ee-navbar-toolbar {
  float: right;
}
.ee-navbar-toolbar li a {
  display: block;
  padding: 0 10px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.ee-navbar-toolbar li a:hover {
  text-decoration: none;
}
.ee-navbar-toolbar md-menu-bar {
  padding: 15px 0;
}
form[name="loginForm"] {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.dropdown-toggle-wrapper ul {
  margin: 5px 20px;
}
.dropdown-toggle-wrapper ul li {
  margin-bottom: 10px;
}
.dropdown-toggle-wrapper ul li:last-child {
  margin-bottom: 0;
}
md-menu-bar button {
  outline: 0;
}
.ee-navbar-toolbar md-menu-bar button {
  height: 53px;
}
.ee-navbar-toolbar .md-icon-button {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  margin: 0;
  line-height: 1.2;
  border-radius: 0;
}
.ee-navbar-toolbar > .list-unstyled > li {
  display: inline-block;
}
.ee-navbar-toolbar .md-icon-button.md-button {
  background-color: transparent;
}
.ee-navbar-toolbar .toolbar-sub-menu {
  position: absolute;
  z-index: 100;
  display: block;
  background-color: #fff;
  margin: 17px 0 0;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: auto;
  min-width: 150px;
}
.ee-navbar-toolbar .toolbar-sub-menu.support {
  text-align: left;
  right: 0;
}
.ee-navbar-toolbar .toolbar-sub-menu.login-container {
  right: 0;
}
.ee-navbar-toolbar .toolbar-sub-menu.login-container form[name="loginForm"] {
  padding: 0;
}
.ee-navbar-toolbar .toolbar-sub-menu.login-container .log-in-btn-dd.md-button {
  padding: 10px;
}
.ee-navbar-toolbar .toolbar-sub-menu.my-account {
  right: 0;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart {
  min-width: 300px;
  width: 644px;
  right: 0;
  padding: 0;
  color: #333333;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .cart-item {
  font-weight: 500;
  font-size: 28px;
  line-height: 0.6;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .sub-total {
  font-weight: 500;
  font-size: 28px;
  line-height: 0.6;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .sub-total .smaller {
  font-size: 16px;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .divider {
  padding: 20px 10px;
  border-right: solid 1px #ebebeb;
  font-size: 16px;
  line-height: 1.5;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .divider:last-child {
  border-right: 0;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper {
  padding: 0;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper p {
  margin: 0;
  font-size: 12px;
  line-height: 1.3;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper p strong {
  display: inline-block;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper .free-shipping-progress-bar {
  margin: 12px 0 5px;
  padding-right: 40px;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper md-progress-linear.free-shipping {
  position: relative;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper md-progress-linear.free-shipping:after {
  content: '';
  display: block;
  width: 38px;
  height: 21px;
  background-image: url("/assets/images/delivery-truck-sprite.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  right: -40px;
  top: -12px;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .free-shipping-wrapper md-progress-linear.free-shipping.active:after {
  background-image: url("/assets/images/delivery-truck-sprite.png");
  background-position: 0 -21px;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .menu-items a {
  text-align: left;
}
.ee-navbar-toolbar .toolbar-sub-menu .menu-items {
  text-align: left;
}
.ee-navbar-toolbar .toolbar-sub-menu .menu-items li {
  margin-bottom: 10px;
}
.ee-navbar-toolbar .toolbar-sub-menu .menu-items li a {
  padding: 0;
  text-align: center;
}
.ee-navbar-toolbar .toolbar-sub-menu .menu-items li:last-child {
  margin-bottom: 0;
}
.ee-navbar-toolbar .md-button {
  overflow: visible;
}
.ee-navbar-toolbar .md-button.md-open:after,
.ee-navbar-toolbar .md-button.md-open:before {
  border-color: hsla(0, 0%, 84%, 0);
  border-bottom-color: #d7d7d7;
  border-width: 11px;
  margin-left: -11px;
  bottom: -35%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  z-index: 101;
}
.ee-navbar-toolbar .md-button.md-open:after {
  border-color: hsla(0, 0%, 100%, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.ee-navbar-toolbar .md-button.md-open:before {
  border-color: hsla(0, 0%, 84%, 0);
  border-bottom-color: #d7d7d7;
  border-width: 11px;
  margin-left: -11px;
}
.ee-navbar-toolbar .emersonicon.emersonicon-cart .cart-item {
  position: absolute;
  top: 39%;
  font-size: 10px;
  text-align: center;
  width: 20px;
  left: 9px;
}
/*Toolbar Icon*/
.emersonicon {
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
}
.emersonicon:before {
  content: '';
  display: block;
  width: 34px;
  height: 34px;
}
.emersonicon-class {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #999999;
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  text-transform: capitalize;
}
.md-icon-button.md-button,
.md-button.md-primary.md-icon-button {
  background-color: transparent;
}
.md-icon-button.md-button.md-open .emersonicon-class,
.md-icon-button.md-button:hover .emersonicon-class {
  color: #000000;
  text-decoration: none;
}
.emersonicon.emersonicon-wellevate:before {
  background-image: url('/assets/images/wellevate-logo-digital.svg');
  background-repeat: no-repeat;
  width: 108px;
  height: 31px;
}
.md-icon-button.md-button.md-open .emersonicon.emersonicon-wellevate:before,
.md-icon-button.md-button:hover .emersonicon.emersonicon-wellevate:before {
  background-image: url('/assets/images/wellevate-logo-digital-blk.svg');
  background-repeat: no-repeat;
  width: 108px;
  height: 31px;
}
.emersonicon.emersonicon-support:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -2px;
}
.md-icon-button.md-button.md-open .emersonicon.emersonicon-support:before,
.md-icon-button.md-button:hover .emersonicon.emersonicon-support:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -193px;
}
.emersonicon.emersonicon-login:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -54px;
}
.md-icon-button.md-button.md-open .emersonicon.emersonicon-login:before,
.md-icon-button.md-button:hover .emersonicon.emersonicon-login:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -8px -241px;
}
.emersonicon.emersonicon-cart:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -99px;
}
.md-icon-button.md-button .emersonicon.emersonicon-cart span.cart-item {
  color: #ffffff;
}
.md-icon-button.md-button.md-open .emersonicon.emersonicon-cart:before,
.md-icon-button.md-button:hover .emersonicon.emersonicon-cart:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -8px -294px;
}
.md-icon-button.md-button.md-active .emersonicon.emersonicon-cart:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -8px -294px;
}
.md-icon-button.md-button.md-active .emersonicon.emersonicon-cart span.cart-item {
  color: #ffffff;
}
.emersonicon.emersonicon-dispensary:before {
  background-image: url('/assets/images/support-for-your-practice/wellevate/dispensary-icon.png');
}
.emersonicon.emersonicon-relationship:before {
  background-image: url('/assets/images/support-for-your-practice/wellevate/relationship-icon.png');
}
.emersonicon.emersonicon-safe:before {
  background-image: url('/assets/images/support-for-your-practice/wellevate/safe-icon.png');
}
.emersonicon.emersonicon-search:before {
  background-image: url('/assets/images/search-icon.png');
  background-position: 0 0;
  background-size: 100%;
  width: 29px;
  height: 30px;
  margin-top: 2px;
}
.md-icon-button.md-button.md-open .emersonicon.emersonicon-search:before,
.md-icon-button.md-button:hover .emersonicon.emersonicon-search:before {
  background-image: url('/assets/images/search-icon-hover.png');
  background-position: 0 0;
}
.navbar-default .navbar-nav.navbar-right .emersonicon:before {
  width: 34px;
  height: 34px;
}
.ee-navbar > .ee-container {
  position: relative;
}
.md-open-menu-container.md-active {
  background-color: #ffffff;
  border: solid 1px #d7d7d7;
}
.md-open-menu-container.md-active:after,
.md-open-menu-container.md-active:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.md-open-menu-container.md-active:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.md-open-menu-container.md-active:before {
  border-color: rgba(215, 215, 215, 0);
  border-bottom-color: #d7d7d7;
  border-width: 11px;
  margin-left: -11px;
}
.emersonicon.ee-account-profile-picture:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -8px -150px;
}
.md-menu.md-open .emersonicon.ee-account-profile-picture:before,
.md-menu:hover .emersonicon.ee-account-profile-picture:before,
.md-icon-button.md-open .emersonicon.ee-account-profile-picture:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -8px -332px;
}
/*brand*/
.brands-wrapper .brand-logo {
  width: 100px;
  height: 85px;
  border: solid 1px #79c143;
}
.ee-dropdown-menu h2 {
  margin: 10px 0;
  font-size: 20px;
  text-align: left;
}
.ee-dropdown-menu h2:first-child {
  margin-top: 0;
}
.ee-dropdown-menu h3 {
  font-size: 16px;
}
.ee-media .body h3 {
  font-size: 16px;
  margin-top: 10px;
}
.ee-dropdown-menu-padding {
  padding: 20px 0 !important;
}
.image-dummie {
  width: 110px;
  height: 70px;
  border: solid 1px #79c143;
}
/**/
.navbar-toggle {
  display: none;
}
@media (min-width: 768px) {
  .container-fluid > .navbar-collapse,
  .ee-container > .navbar-collapse,
  .ee-container > .navbar-header,
  .ee-container-fluid > .navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}
/*Log in Container*/
.login-container {
  width: 363px;
  position: relative;
}
.login-container md-input-container {
  margin: 10px 0;
  padding: 0;
}
.log-in-btn-dd {
  margin: 0;
  width: 90%;
  background-color: #76bd23;
}
.forgot-password-dd {
  margin: 0;
}
.register-btn-dd {
  display: block;
  margin-top: 10px;
}
/*Welcome User*/
.welcome-user {
  clear: both;
  position: absolute;
  right: 15px;
  top: 135px;
  width: 100%;
  max-width: 455px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  outline: none;
  /*button toggle*/
}
.welcome-user .welcome-user-panel {
  max-height: 600px;
}
.welcome-user .welcome-user-panel .md-padding {
  padding: 12px 0 0;
}
.welcome-user div.title {
  margin: 20px 0 0 15px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.2px;
}
.welcome-user md-tabs.unopen,
.welcome-user md-tabs:not(.md-no-tab-content):not(.md-dynamic-height).unopen {
  min-height: 0;
}
.welcome-user md-tabs.unopen .md-tab.md-active,
.welcome-user md-tabs:not(.md-no-tab-content):not(.md-dynamic-height).unopen .md-tab.md-active {
  border-top: solid 3px transparent;
}
.welcome-user md-tabs.unopen .md-tab.md-active .ng-scope,
.welcome-user md-tabs:not(.md-no-tab-content):not(.md-dynamic-height).unopen .md-tab.md-active .ng-scope {
  color: #999999;
}
.welcome-user md-tabs.unopen .list-item,
.welcome-user md-tabs:not(.md-no-tab-content):not(.md-dynamic-height).unopen .list-item {
  height: 0;
  line-height: 0;
}
.welcome-user md-pagination-wrapper,
.welcome-user md-tabs-canvas {
  height: 40px;
  width: 100% !important;
  padding: 0 20px;
  box-sizing: border-box;
}
.welcome-user md-tabs-content-wrapper {
  top: 40px;
}
.welcome-user md-content.recent-order-content {
  padding: 20px 20px 0;
  box-sizing: border-box;
}
.welcome-user md-tabs[md-border-top] md-tabs-wrapper {
  width: 100%;
}
.welcome-user md-tabs[md-border-top] .md-tab {
  width: 50%;
  text-transform: capitalize;
}
.welcome-user md-tabs[md-border-top] .md-tab .ng-scope {
  padding: 5px;
  line-height: 1.2;
}
.welcome-user md-divider {
  border-top: 1px solid #e1e1e1;
}
.welcome-user md-list-item .md-no-style,
.welcome-user md-list-item.md-no-proxy {
  padding: 0;
}
.welcome-user md-list-item .md-list-item-inner > md-checkbox,
.welcome-user md-list-item > md-checkbox {
  margin-right: 10px;
  width: 27px;
}
.welcome-user md-checkbox .md-icon {
  border: 1px solid #999999;
}
.welcome-user md-checkbox.md-checked .md-icon:after {
  left: 8.66667px;
  top: 4.22222px;
  color: #76bd23;
}
.welcome-user md-tab-content {
  transition: none;
}
.welcome-user input.qty {
  width: 30px;
  text-align: center;
}
.welcome-user md-checkbox .md-container {
  width: 27px;
  height: 27px;
}
.welcome-user md-checkbox .md-icon {
  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.welcome-user .qty-label {
  text-align: right;
  font-size: 12px;
  color: #999999;
  margin-right: 10px;
}
.welcome-user md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 506px;
}
.welcome-user .view-all-btn {
  margin-top: 10px;
  display: block;
}
.welcome-user .ee-search {
  border-top: 1px solid #ccc;
  height: 48px;
  margin-bottom: 15px;
}
.welcome-user .ee-search input {
  height: 46px;
}
.welcome-user .md-secondary-container {
  margin-top: 0;
  margin-bottom: 0;
  height: 52px;
}
.welcome-user .md-button.md-icon-button.md-secondary {
  width: 47px;
  border-left: 1px solid #e1e1e1;
  border-radius: 0;
  height: 50px;
}
.welcome-user .button-toggle {
  width: 21px;
  height: 21px;
  background-image: url('/assets/images/button-toggle-open.png');
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  cursor: pointer;
}
.welcome-user .button-toggle.open {
  background-image: url('/assets/images/button-toggle-close.png');
  width: 29px;
  height: 29px;
  top: 0;
}
.welcome-user .tabs-dummie {
  border-top: solid 1px #76bd23;
  margin: 15px 15px 10px;
  display: block;
}
.welcome-user .tabs-dummie ul {
  padding: 0;
  margin: 5px 0 15px;
}
.welcome-user .tabs-dummie ul li {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: inline-block;
  width: 133px;
  font-size: 14px;
  color: #999999;
  text-align: center;
}
.welcome-user .tabs-dummie.open {
  display: none;
}
.welcome-user .list-item.nothing {
  line-height: 366px;
  text-align: center;
  border-bottom: solid 1px #e1e1e1;
}
.welcome-user .list-item.nothing p {
  color: #999999;
  font-size: 18px;
}
.welcome-user .list-item .item-row {
  padding: 15px 0 0 0;
  border-bottom: solid 1px #ccc;
  text-align: left;
}
.welcome-user .list-item .item-row .item-quantity {
  text-align: right;
}
.welcome-user .list-item .item-row .item-quantity input.qty {
  width: 30px;
  text-align: center;
}
.welcome-user .md-button {
  margin: 20px 10px;
}
.welcome-user .autoheight {
  height: 460px;
}
.welcome-user .quick-order {
  background-color: #fff;
  box-shadow: none;
  width: 100%;
}
.welcome-user .quick-order-wrapper {
  padding-bottom: 62px;
  height: 384px;
  position: relative;
}
.welcome-user .quick-order-wrapper .layout-row,
.welcome-user .quick-order-wrapper .quick-order-items {
  padding: 0 20px;
  box-sizing: border-box;
}
.welcome-user .quick-order-wrapper md-input-container .md-placeholder,
.welcome-user .quick-order-wrapper md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  color: #333333;
}
.welcome-user .quick-order-wrapper md-input-container {
  margin: 18px 25px 18px 0;
}
.welcome-user .quick-order-wrapper md-input-container .md-input {
  color: #76bd23;
}
.welcome-user .quick-order-wrapper .add-to-cart-status-message-bottom {
  height: 62px;
  line-height: 62px;
  width: 100%;
  background-color: #333333;
  position: absolute;
  bottom: 0;
  text-align: center;
}
.welcome-user .quick-order-wrapper .add-to-cart-status-message-bottom .add-to-cart-status-message {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.app-tab-link-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.app-tab-link {
  max-width: 45%;
  flex-grow: 1;
}
/*Welcome User Mobile view*/
@media (max-width: 959px) {
  .welcome-user {
    top: 81px;
    /*hide it for now, will work on it later*/
    display: none;
  }
  #bannerMessage {
    padding-top: 15px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    position: relative;
  }
  #bannerMessage img {
    float: left;
  }
  #bannerMessage md-dialog md-toolbar {
    right: 35px;
  }
  .ee-search {
    border: none;
  }
  .ee-search md-autocomplete.search-auto-m {
    width: 100%;
  }
  .dropdown.search div div.dropdown-display {
    height: 40px;
    margin-top: 5px;
  }
  .md-virtual-repeat-container.search-container-m {
    min-height: 900px;
    height: auto;
    overflow-y: scroll;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller {
    overflow: visible;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions {
    padding-bottom: 40px;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li {
    line-height: 10px;
    margin: 0;
    padding: 0;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li:focus,
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li:hover {
    background-color: #fff;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li:first-child .seperate-line {
    border-top: 0;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left {
    border-right: solid 1px #e6e6e6;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .title {
    margin: 5px;
    padding: 8px 5px 5px;
    color: #666666;
    cursor: default;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata {
    margin: 5px;
    padding: 5px;
    color: #669933;
    line-height: 0.4;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata:focus,
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata:hover {
    background-color: #f5f5f5;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu hr {
    width: 90%;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu .sub-title {
    margin: 0;
    padding: 0 15px;
    line-height: 2;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu .sub-title .sub-title-1 {
    color: #999;
    font-weight: 500;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu .sub-title .sub-title-2 {
    color: #000;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu ul {
    padding: 0 15px;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu ul li {
    line-height: 1.5;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .show-related-submenu .shop-all-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    width: 138px;
    height: 40px;
    background-color: #79c143;
    margin-left: 100px;
    margin-top: 30px;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .item-metastat a {
    text-decoration: none;
    color: #669933;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .item-metastat a:focus,
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .item-metastat a:hover {
    text-decoration: none;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .title {
    margin: 5px;
    padding: 8px 5px 5px;
    color: #666666;
    cursor: default;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata {
    margin: 5px;
    padding: 5px;
    color: #669933;
    line-height: 1.1;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata:focus,
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata:hover {
    background-color: #f5f5f5;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata .item-metastat a {
    text-decoration: none;
    color: #669933;
  }
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata .item-metastat a:focus,
  .md-virtual-repeat-container.search-container-m .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata .item-metastat a:hover {
    text-decoration: none;
  }
  .app-tab-link {
    max-width: 14em;
  }
}
/*END Welcome User*/
/*My Account*/
.my-account-list .list-unstyled {
  margin-top: 0;
}
.my-account-list .list-unstyled li {
  margin-bottom: 10px;
}
/*Support*/
.support-list .list-unstyled {
  margin-top: 0;
}
.support-list .list-unstyled li {
  margin-bottom: 10px;
}
/*Mobile menu*/
.mobile-menu-container {
  width: 100%;
  background-color: #ffffff;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 1px;
  position: absolute;
  padding: 10px 0 70px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  max-height: 1780px;
  z-index: 21;
  /*user support tool*/
}
.mobile-menu-container .user-support {
  float: none;
  width: 100%;
}
.mobile-menu-container .user-support .user-support-lists .wellevate-link {
  float: left;
  margin-left: 10px;
  width: 108px;
}
.mobile-menu-container .user-support .user-support-lists .wellevate-link a {
  width: 108px;
}
.mobile-menu-container .site-menu {
  float: none;
  width: 100%;
}
.mobile-menu-container li {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.mobile-menu-container .mobile-menu-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}
.mobile-menu-container .mobile-menu-wrapper li {
  border-top: rgba(0, 0, 0, 0.06) 1px solid;
}
.mobile-menu-container .mobile-menu-wrapper li:last-child {
  border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
}
.mobile-menu-container .mobile-menu-wrapper li h3 {
  height: auto;
}
.mobile-menu-container .mobile-menu-wrapper li .media-body {
  padding-left: 5px;
}
.mobile-menu-container .mobile-menu-wrapper li a {
  padding: 20px 0;
  margin: 0 15px;
  display: block;
  color: #333333;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}
.mobile-menu-container .mobile-menu-wrapper li a:hover {
  text-decoration: none;
}
.mobile-menu-container .mobile-menu-wrapper li a.md-open {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
}
.mobile-menu-container .mobile-menu-wrapper li a.unopen {
  text-decoration: none;
  color: #777777;
  font-weight: 500;
}
.mobile-menu-container .mobile-menu-wrapper li a .arrow-icon {
  float: right;
  margin-top: 7px;
  display: block;
}
.mobile-menu-container .mobile-menu-wrapper li a .text {
  float: left;
}
.mobile-menu-container .mobile-menu-wrapper li a.md-open .arrow-icon {
  transform: rotate(-180deg);
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items {
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 100%;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .header-title {
  text-align: center;
  margin: 8px 0;
  font-size: 17px;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .mobile-menu-sub-items-div-wrapper {
  padding-right: 7px;
  padding-left: 0;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-right: 8px;
  margin-left: 15px;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .browse {
  font-size: 14px;
  text-align: center;
  margin-bottom: 4px;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .browse-menu {
  margin: 0 0 15px 0;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .browse-menu li > a {
  margin: 0;
  padding: 0;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li {
  border-bottom: 0;
  border-top: 0;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a {
  text-transform: capitalize;
  padding: 10px 0;
  color: #669933;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if],
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if] {
  overflow: hidden;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if].ng-enter,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if].ng-enter,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if].ng-leave,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if].ng-leave {
  transition: max-height 0.22s ease-in;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if].ng-enter,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if].ng-enter,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if].ng-leave.ng-leave-active,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if].ng-leave.ng-leave-active {
  max-height: 0;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if].ng-leave,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if].ng-leave,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + div[ng-if].ng-enter.ng-enter-active,
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.mobile-menu-sub-item-toggle + ul[ng-if].ng-enter.ng-enter-active {
  max-height: 850px;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li:last-of-type > a.mobile-menu-sub-item-toggle {
  border-bottom: none;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li.feature-product a {
  margin-left: 0;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li .mobile-menu-sub-item-links:last-of-type > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li .mobile-menu-sub-item-links.padding-left {
  padding-left: 15px;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li .mobile-menu-sub-item-links a {
  text-transform: uppercase;
  font-size: 13px;
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li .mobile-menu-sub-item-header {
  padding: 10px 0;
  margin: 0 15px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items .brand-item-list .product-summary a {
  padding: 10px 0;
  margin: 0;
  height: 20px;
  display: inline-table;
}
.mobile-menu-container .browse-menu {
  text-align: center;
  margin: 30px 0 0 -30px;
  padding: 0;
}
.mobile-menu-container .browse-menu li {
  display: inline;
  font-size: 16px;
  padding: 0 15px 0 0 !important;
  margin: 0 0 0 15px !important;
}
.mobile-menu-container .browse-menu li.browse {
  display: block;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.mobile-menu-container .browse-menu li.line-divider {
  border-right: solid 1px #d7d7d7 !important;
}
.mobile-menu-container .browse-menu li a {
  display: inline-block;
  color: #79c143;
}
.mobile-menu-container .browse-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.mobile-menu-container .site-menu .arrow-icon {
  float: right;
}
.mobile-menu-container .user-support {
  text-align: right;
}
.mobile-menu-container .user-support a.md-button:hover {
  background-color: transparent;
}
.mobile-menu-container .user-support .md-button {
  overflow: visible;
}
.mobile-menu-container .user-support .md-button.md-open:after,
.mobile-menu-container .user-support .md-button.md-open:before {
  border-color: hsla(0, 0%, 84%, 0);
  border-bottom-color: #d7d7d7;
  border-width: 11px;
  margin-left: -11px;
  bottom: -50%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  z-index: 101;
}
.mobile-menu-container .user-support .md-button.md-open:after {
  border-color: hsla(0, 0%, 100%, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.mobile-menu-container .user-support .md-button.md-open:before {
  border-color: hsla(0, 0%, 84%, 0);
  border-bottom-color: #d7d7d7;
  border-width: 11px;
  margin-left: -11px;
}
.mobile-menu-container .user-support ul.user-support-lists {
  padding: 0;
  margin: 10px 0 20px;
}
.mobile-menu-container .user-support ul.user-support-lists li {
  display: inline;
}
.mobile-menu-container .user-support ul.user-support-lists li .mobile-menu-sub-items {
  margin: 0;
  padding: 0;
}
.mobile-menu-container .user-support ul.user-support-lists li .mobile-menu-sub-items li {
  display: block;
  text-align: left;
  margin-bottom: 15px;
}
.mobile-menu-container .user-support ul.user-support-lists li .mobile-menu-sub-items li a {
  line-height: 1.3;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.mobile-menu-container .user-support ul.user-support-lists li .mobile-menu-sub-items li:last-child {
  margin-bottom: 0;
}
.mobile-menu-container .user-support ul.user-support-lists .user-support-items {
  position: absolute;
  left: 0;
  z-index: 100;
  display: block;
  background-color: #fff;
  margin: 17px 0 0;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.mobile-menu-container .user-support ul.user-support-lists .user-support-items h3 {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.04;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}
.mobile-menu-container .user-support .md-button.md-icon-button {
  padding: 0;
  height: 34px;
}
.mobile-menu-container .user-support .md-menu:hover .emersonicon.emersonicon-support:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -6px;
}
.mobile-menu-container .ee-media .image {
  width: 100%;
}
.ee-mobile-nav .ee-mobile-nav-wrapper {
  height: 56px;
}
.welcome-user-mobile {
  width: 100%;
  float: left;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #999;
  opacity: 1;
  z-index: 20;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.welcome-user-mobile .welcome-wrapper {
  border-top: #999 solid 1px;
  padding: 0 15px;
  height: 41px;
}
.welcome-user-mobile .border-welcome {
  border-bottom: #76bd23 solid 1px;
}
.welcome-user-mobile.expand {
  height: auto;
  min-height: 40px;
}
.welcome-user-mobile h3 {
  margin: 10px 0 0 0;
  font-weight: 400;
}
.welcome-user-mobile .action-menu-section {
  padding: 10px 15px;
}
.welcome-user-mobile .action-menu-section .actions-wrapper {
  margin-bottom: 10px;
  width: 100%;
}
.welcome-user-mobile .button-toggle {
  width: 35px;
  height: 42px;
  background-image: url('/assets/images/button-toggle-open.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  cursor: pointer;
  outline: 0;
}
.welcome-user-mobile .button-toggle.open {
  background-image: url('/assets/images/button-toggle-open.png');
  transform: rotate(-180deg);
  width: 35px;
  height: 42px;
}
.welcome-user-mobile .list-wrapper {
  padding: 15px;
}
.welcome-user-mobile .list-wrapper .qty-label {
  text-align: right;
  font-size: 14px;
  color: #eee;
  margin-right: 10px;
  margin-bottom: 5px;
}
.welcome-user-mobile .list-wrapper .list-item .item-row {
  padding: 10px 0 0 15px;
  border-bottom: solid 1px #ccc;
  text-align: left;
  line-height: 1.45;
}
.welcome-user-mobile .list-wrapper .list-item .item-row .item-quantity {
  text-align: right;
}
.welcome-user-mobile .list-wrapper .list-item .item-row .item-quantity input.qty {
  width: 30px;
  text-align: center;
}
.welcome-user-mobile .list-wrapper .list-item md-checkbox {
  padding-top: 5px;
}
.welcome-user-mobile .list-wrapper .list-item md-checkbox .md-container {
  width: 27px;
  height: 27px;
}
.welcome-user-mobile .list-wrapper .list-item md-checkbox .md-icon {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  border: 1px solid #999999;
}
.welcome-user-mobile .list-wrapper .list-item md-checkbox.md-checked .md-icon:after {
  left: 8.66667px;
  top: 4.22222px;
  color: #76bd23;
}
.welcome-user-mobile .action-buttons {
  padding-bottom: 15px;
}
.welcome-user-mobile .quick-order-wrapper-mobile .quick-order {
  margin-top: -1px;
  background-color: #fff;
  box-shadow: none;
  width: 100%;
}
.welcome-user-mobile .quick-order-wrapper-mobile .quick-order .unit span {
  color: #fff;
}
.welcome-user-mobile .quick-order-wrapper-mobile .autoheight {
  height: 300px;
}
.mobile-items-right-side {
  text-align: right;
  margin: 0 0 0 5px;
  padding-left: 5px;
}
.mobile-items-right-side .cart {
  text-align: left;
}
.mobile-items-right-side .cart .media {
  overflow: hidden;
  margin: 0 0 15px;
}
.mobile-items-right-side .cart .media .unit {
  float: left;
}
.mobile-items-right-side .cart .media .unit:last-child {
  float: none;
  overflow: hidden;
  text-align: right;
}
.mobile-items-right-side .cart .cart-item,
.mobile-items-right-side .cart .sub-total {
  font-weight: 700;
}
.mobile-items-right-side .cart .free-shipping-wrapper {
  padding: 0;
  margin: 0 0 15px;
}
.mobile-items-right-side .cart .free-shipping-wrapper p {
  margin: 0;
  font-size: 12px;
  line-height: 1.3;
}
.mobile-items-right-side .cart .free-shipping-wrapper p .shift-left {
  padding-left: 5px;
}
.mobile-items-right-side .cart .free-shipping-wrapper .free-shipping-progress-bar {
  margin: 15px 0;
  padding-right: 40px;
}
.mobile-items-right-side .cart .free-shipping-wrapper md-progress-linear.free-shipping {
  position: relative;
}
.mobile-items-right-side .cart .free-shipping-wrapper md-progress-linear.free-shipping:after {
  content: '';
  display: block;
  width: 38px;
  height: 21px;
  background-image: url("/assets/images/delivery-truck-sprite.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  right: -40px;
  top: -15px;
}
.mobile-items-right-side .cart .free-shipping-wrapper md-progress-linear.free-shipping.active:after {
  background-image: url("/assets/images/delivery-truck-sprite.png");
  background-position: 0 -21px;
}
.mobile-items-right-side li {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: inline;
}
.mobile-items-right-side li.close-button-menu-toggle {
  height: 55px;
  display: block;
  line-height: 55px;
}
.mobile-items-right-side li.close-button-menu-toggle a {
  color: #cccccc;
  font-size: 30px;
  font-weight: 300;
}
.mobile-items-right-side li.close-button-menu-toggle a:hover {
  text-decoration: none;
}
.mobile-items-right-side .md-button.md-icon-button {
  padding: 0;
}
.mobile-items-right-side .md-cart-icon,
.mobile-items-right-side .md-search-icon {
  width: 34px;
  height: 34px;
  border-radius: 0;
  padding: 0;
  margin: 10px 0;
}
.mobile-items-right-side a.md-button:hover {
  background-color: transparent;
}
.mobile-items-right-side .toolbar-sub-menu {
  position: absolute;
  z-index: 100;
  display: block;
  background-color: #fff;
  margin: 14px 0 0;
  padding: 20px 5px;
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  min-width: 150px;
  left: 0;
  margin: 0;
}
.mobile-items-right-side .toolbar-sub-menu .close-searchbox-button {
  margin-top: 5px;
  padding: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  background-color: #fff;
  color: #76bd23;
}
.mobile-items-right-side .toolbar-sub-menu #searchInputWrapper {
  margin-left: 8px;
}
.mobile-items-right-side .menu-items li {
  display: block;
  margin-bottom: 10px;
}
.mobile-items-right-side .menu-items li:last-child {
  margin-bottom: 0;
}
.mobile-items-right-side .emersonicon.emersonicon-cart .cart-item {
  position: absolute;
  top: 39%;
  font-size: 10px;
  left: 37%;
  text-align: center;
  width: 15px;
}
.border-block {
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}
.link-inline a {
  display: inline-block !important;
}
/*Nav breakpoint*/
@media (max-width: 1140px) {
  .navbar-logo {
    margin-right: 20px;
  }
  .navbar-logo img {
    width: 120px;
  }
}
@media screen and (max-width: 959px) and (orientation: landscape) {
  .navbar-wrapper {
    padding-bottom: 10px;
  }
  .hero-container #herobrnds {
    margin-left: 234px;
    margin-top: 66px;
    font-size: 22px;
  }
  .hero-container #herobrnds span {
    font-size: 16px;
  }
}
@media (max-width: 959px) {
  #navbar.homepageStyle {
    top: 0;
  }
  #navbar.homepageStyle.navbar-wrapper {
    position: relative;
  }
  .navbar-logo {
    margin-right: 20px;
  }
  .navbar-wrapper {
    position: relative;
    top: 0 !important;
  }
  .navbar-toggle {
    display: block;
  }
  .ee-mobile-nav {
    display: block;
    height: 56px;
  }
  .navbar-header {
    display: none;
  }
  #menu_container_0,
  #menu_container_1 {
    width: 100%;
  }
  #menu_container_0 a,
  #menu_container_1 a {
    color: #777777;
  }
  .ee-mobile-nav .navbar-logo {
    padding-top: 15px;
    margin-right: 0;
  }
  .ee-mobile-nav .navbar-logo img {
    width: 100%;
    max-width: 160px;
  }
  .mobile-menu-toggle {
    width: 36px;
    height: 24px;
    margin: 15px 0;
    display: block;
  }
  .ee-mobile-menu-icon {
    padding: 0 !important;
    margin: 20px 0 !important;
  }
  .ee-mobile-hamburger {
    width: 36px;
    height: 24px;
    background-image: url('/assets/images/hamburger-icon.svg');
    background-size: 36px 24px;
  }
  .mobile-menu-toggle.open .ee-mobile-hamburger {
    width: 36px;
    height: 24px;
    background-image: url('/assets/images/hamburger-icon-hover.svg');
    background-size: 36px 24px;
  }
  .mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.md-button {
    color: #ffffff;
    margin: 10px 0;
  }
  .mobile-menu-wrapper h2 {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 0;
  }
  .mobile-menu-wrapper li:nth-child(2) .brand-product .product-thumbnail img {
    width: 100%;
  }
  .mobile-menu-wrapper li:nth-child(2) .brand-product .media-body {
    margin-left: 10%;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media h3 {
    text-align: center;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media .body {
    overflow: hidden;
    padding-left: 10px;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media .body p {
    margin: 0 0 5px;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media .body a {
    color: #79c143;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media .image {
    width: 40%;
    float: left;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media .image img {
    width: 100%;
  }
  .mobile-menu-wrapper li:nth-child(3) .ee-media .media-block {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .mobile-menu-wrapper li:nth-child(4) .ee-media .body {
    display: none;
  }
  .mobile-menu-wrapper li:nth-child(4) .ee-media .image a {
    text-align: center;
  }
  .mobile-menu-wrapper [compile*="wellevate.detail"] ul.ee-media li .body {
    display: block;
  }
  .mobile-menu-wrapper li:nth-child(5) .media-block {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .mobile-menu-wrapper li:nth-child(5) .media-block .image {
    text-align: center;
  }
  .mobile-menu-wrapper li:nth-child(5) .media-block p {
    margin: 10px 0;
  }
  .mobile-menu-wrapper li:nth-child(5) .media-block p a {
    margin: 0;
    padding: 0;
    color: #79c143;
  }
  .mobile-menu-wrapper li:nth-child(5) h2 {
    margin-bottom: 10px;
  }
  .mobile-menu-wrapper li:nth-child(5) div.layout-xs-column.layout-row {
    display: block;
  }
}
.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  background: #ffffff;
}
.type-ahead-search.md-autocomplete-suggestions {
  padding-bottom: 40px;
}
.type-ahead-search.md-autocomplete-suggestions li {
  margin: 5px 0;
}
.type-ahead-search.md-autocomplete-suggestions .title {
  margin: 20px 0 10px;
  color: #666666;
}
.type-ahead-search.md-autocomplete-suggestions .item-metadata {
  color: #4399c5;
  line-height: 1.1;
}
.type-ahead-search.md-autocomplete-suggestions .item-metadata .highlight {
  font-weight: 700;
}
.mobile-items-right-side md-autocomplete button {
  position: absolute;
  right: 5px;
  top: 3px;
}
.mobile-items-right-side .md-whiteframe-1dp,
.mobile-items-right-side .md-whiteframe-z1 {
  box-shadow: none;
}
/*Dialog popup*/
.ee-navbar md-backdrop.md-dialog-backdrop {
  z-index: 101;
  background-color: #000000;
}
.ee-navbar .md-dialog-container {
  z-index: 120;
}
.ee-navbar md-dialog {
  max-width: 90%;
  max-height: 90%;
}
.ee-navbar md-dialog.login-dialog-wrapper .md-dialog-content {
  padding: 0 15px 15px;
}
.ee-navbar md-dialog.login-dialog-wrapper h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}
.ee-navbar md-dialog.login-dialog-wrapper md-toolbar {
  min-height: 40px;
}
.ee-navbar md-dialog.login-dialog-wrapper .md-toolbar-tools {
  height: 40px;
}
.ee-navbar md-dialog.login-dialog-wrapper .md-toolbar-tools .md-close {
  top: 2px;
}
.ee-navbar md-dialog.login-dialog-wrapper .forgot-password-btn-dd {
  padding: 0;
}
.ee-navbar md-dialog.login-dialog-wrapper .fp-submit-btn-dd {
  display: block;
  width: 100%;
  margin: 0;
}
.ee-navbar md-dialog.login-dialog-wrapper form[name="loginForm"] {
  padding: 0;
}
@media (max-width: 320px) {
  #bannerMessage md-dialog-content {
    margin-left: 0;
  }
  #bannerMessage md-dialog md-toolbar {
    right: 15px;
  }
}
@media print {
  .banner-message {
    display: none;
  }
}
.ee-navbar-toolbar .toolbar-sub-menu.cart {
  width: auto;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .divider {
  display: table-cell;
}
.ee-navbar-toolbar .toolbar-sub-menu.cart .divider.price {
  max-width: 100%;
}
.flex-8 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 8.5%;
  max-height: 100%;
  box-sizing: border-box;
}
.mobile-hamburger {
  display: block;
}
.mobile-hamburger-ipad {
  display: none;
}
@media screen and (width: 768px) and (height: 1024px) and (orientation: portrait), screen and (width: 540px) and (height: 720px) and (orientation: portrait), screen and (orientation: landscape) {
  .mobile-hamburger {
    display: none;
  }
  .mobile-hamburger-ipad {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #bannerMessage md-dialog {
    max-height: none;
    max-width: none;
    min-width: 0;
  }
}
@media screen and (max-width: 959px) and (orientation: landscape) {
  .md-virtual-repeat-container.search-container-m {
    min-height: 725px;
  }
}
.register-link {
  vertical-align: bottom;
}
#register-button {
  line-height: 1.1;
  background-color: #0281D7;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  text-transform: none;
}
#register-button:hover {
  color: #ffffff;
  background-color: #000000;
}
#order-filter-contianer {
  margin: 15px 0;
}
#order-filter-contianer h2 {
  margin: 10px 0;
}
#order-filter-contianer .time-period {
  margin: 25px 0 10px 0;
}
#order-filter-contianer md-input-container {
  margin: 18px 0 10px 0;
  height: 30px;
}
#order-filter-contianer a {
  color: #4399c5;
}
.time-period li {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  border-right: 1px solid #999999;
}
.time-period li:last-child {
  border-right: 0;
}
.time-period li a {
  padding: 0 10px;
}
.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;
}
.view-more-btn:before {
  background-image: url('/assets/images/view-more-arrow.png');
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  left: 0;
}
.prop-65-warning {
  margin: 20px 0 30px;
}
.warning-icon {
  padding-left: 40px;
  background-image: url("/assets/images/icons/attention-icon-cart-process.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 20px 0;
  min-height: 30px;
}
.warning-icon .bigger {
  font-size: 24px;
  line-height: 1.3;
}
.warning-icon p {
  margin: 0;
}
.warning-header {
  font-weight: bold;
  margin: 5px;
}
#item-warning-color {
  color: red;
  font-size: 12px;
  padding-top: 11px;
  padding-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
#item-warning-color-mobile {
  color: red;
  font-size: 12px;
  cursor: pointer;
  padding-top: 11px;
}
.item-warning-message {
  margin: 10px;
}
.item-warning-message .warning-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
order-summary-list md-dialog {
  min-width: 460px;
  max-width: 500px ;
}
.page-level-alert {
  position: relative;
}
.page-level-alert .page-level-alert-close-btn {
  position: absolute;
  right: 40px;
  top: 27px;
  background-color: transparent;
  border: 0;
  color: #ffffff;
}
.alert-message-wrapper {
  position: relative;
  text-align: center;
}
.alert-message-wrapper .alert-messaging {
  padding: 10px 25px 10px 35px;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  background-color: #4399c5;
  margin: 0;
  box-sizing: border-box;
  min-height: 79px;
}
.alert-message-wrapper .alert-messaging p {
  position: relative;
  padding: 0 0 0 45px;
  color: #ffffff;
  text-align: left;
}
.alert-message-wrapper .alert-messaging p:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background-image: url('/assets/images/attention.png');
  background-repeat: no-repeat;
  width: 27px;
  height: 25px;
}
.alert-message-wrapper .alert-messaging p a {
  color: white;
  font-weight: bold;
}
.alert-message-wrapper .alert-messaging .alert-me {
  position: relative;
  max-width: 1040px;
  margin: 0 auto;
  display: inline-block;
  padding-right: 40px;
}
.alert-message-wrapper .alert-messaging .alert-me ul {
  margin: 0;
  padding: 0;
}
.alert-message-wrapper .alert-messaging .alert-me ul li {
  list-style-type: none;
}
.alert-message-wrapper .alert-messaging button {
  color: #eef2f5;
  text-align: center;
  width: 17px;
  height: 14px;
  border: none;
  margin-left: 75px;
  right: 0;
  top: 17px;
  position: absolute;
  background-color: #4399c5;
}
.alert-message-wrapper .alert-messaging button:focus {
  outline: none;
}
.alert-message-wrapper .alert-messaging button.page-level-alert-close-btn {
  height: 24px;
}
.alert-message-wrapper .ngTruncateToggleText {
  color: #d7d7d7;
  font-size: 90%;
  font-style: italic;
}
.stick-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.stick-top .alert-me {
  margin: 0 auto;
  max-width: 1050px;
}
hero .page-level-alert {
  top: 136px;
  position: absolute;
  z-index: 100;
  width: 100%;
}
hero .alert-message-wrapper .alert-messaging {
  text-align: center;
}
@media only screen and (max-width: 960px) {
  .alert-message-wrapper {
    z-index: 999;
  }
  .alert-message-wrapper .alert-messaging {
    font-size: 14px;
  }
  hero .page-level-alert {
    top: 0;
  }
}
paging md-dialog .pagination md-input-container {
  margin-top: 0;
}
paging .pagination {
  margin-top: 10px;
  display: block;
}
paging .pagination .page .md-button {
  min-width: 45px;
}
paging .pagination .md-fab {
  line-height: 36px;
  width: 36px;
  height: 36px;
  margin: 0 5px;
  z-index: 2;
}
.payment-info {
  max-width: 796px;
  padding-top: 30px;
}
.payment-info .paymeninfo-title {
  font-size: 16px;
  font-weight: 300;
  color: #333;
  margin: 0;
}
.payment-info p {
  padding: 7px 0;
}
.payment-info .please-enter-msg {
  margin-bottom: 10px;
}
.payment-info .existing-card {
  font-size: 16px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4399c5;
  margin: 0;
  border: 1px solid #4399c5;
  border-radius: 4px;
  background-color: inherit;
}
.payment-info .existing-card .md-select-value {
  height: 36px;
  text-align: center;
  padding: 0 10px;
}
.payment-info .existing-card:not([disabled]):focus .md-select-value {
  padding-bottom: 0;
  border-bottom-width: 0;
}
.payment-info .or-text {
  height: 38px;
  line-height: 38px;
}
.payment-info #addNewCCBtn {
  font-size: 15px;
  box-shadow: none;
  width: 100%;
  text-align: center;
}
.payment-info .info-section-title {
  font-weight: normal;
  font-size: 16px;
  margin: 25px 0 0 0;
}
.payment-info .info-cards-wrapper {
  margin-bottom: 50px;
}
.payment-info .info-cards-wrapper .credit-info-card {
  margin-bottom: 10px;
}
.payment-info .info-cards-wrapper md-input-container {
  margin-left: 15px;
}
.payment-info .info-cards-wrapper .tooltip-wrapper a {
  margin-top: 28px;
}
.payment-info .info-cards-wrapper .ccexpired-error {
  margin-left: 12px;
  margin-top: -25px;
}
.payment-info .actions-row {
  margin: 15px 0;
}
.payment-info #isDefaultPaymentCheckbox {
  display: block;
  margin: -12px 0 40px 16px;
}
.payment-info .disabled-existing-card {
  pointer-events: none;
}
.payment-info .checkbox-card-type span {
  padding-left: 10px;
  color: #333333;
  font-weight: 400;
}
.payment-info .checkbox-set-default {
  margin-left: 30px;
  padding-top: 30px;
}
.payment-info .checkbox-set-default md-checkbox.md-square .md-label span {
  font-size: 16px;
  font-weight: 300;
  line-height: 0.78;
  text-align: left;
  color: #333333;
}
.payment-info .checkbox-set-default md-checkbox.md-square .md-icon {
  border-radius: 0px;
  border: 1px solid #333333;
}
.payment-info .info-required {
  float: right;
  font-size: 14px;
  line-height: 1.7;
}
.payment-info .creditcard-img {
  float: right;
  width: 180px;
}
.card-info {
  max-width: 796px;
  /*credit card*/
}
.card-info .info-title {
  padding: 20px 0 10px 0;
  font-size: 14px;
  line-height: 2;
}
.card-info .info-title .info-sub-title {
  font-size: 16px;
  line-height: 1.5;
}
.card-info .card-info-section p {
  padding: 0 0 5px 0;
}
.card-info .card-info-section .select-exist-address {
  width: 265px;
  margin: 0 0 20px 0;
  background-color: #f6f6f6;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  font-size: 16.3px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4399c5;
}
.card-info .card-info-section .select-exist-address img {
  margin-left: 5px;
  margin-top: 2px;
}
.card-info .card-info-section md-select .md-select-value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.card-info .card-info-section md-select:not([disabled]):focus .md-select-value {
  padding-bottom: 0;
}
.card-info .card-info-section .margin-right-35 {
  margin-right: 35px;
}
@media (max-width: 768px) {
  .card-info .card-info-section .margin-right-35 {
    margin-right: unset;
  }
}
.card-info .card-info-section #nameOnCardInputContainer {
  margin-left: 20px;
}
.card-info .card-info-section .same-as-shipping {
  padding-top: 20px;
}
.card-info .card-info-section .sub-title {
  font-size: 16px;
  line-height: 1.5;
}
.card-info .card-info-section .tooltip-wrapper a {
  margin-top: 30px;
  outline: none;
}
.card-info md-dialog credit-card-form md-input-container {
  margin-right: 20px;
}
.card-info md-dialog.ccForm .md-button.md-submit {
  min-width: 200px;
  display: block;
  margin: 0 auto;
}
.card-info md-dialog.ccForm .mtn {
  margin-top: 0 !important;
}
.card-info .save-card-wrapper {
  margin-bottom: 40px;
}
.card-info .save-card-wrapper md-checkbox.md-square .md-label span {
  font-size: 16px;
  font-weight: 300;
  line-height: 0.78;
  text-align: left;
  color: #333333;
}
.card-info .save-card-wrapper md-checkbox.md-square .md-icon {
  border-radius: 0px;
  border: 1px solid #333333;
}
@media only screen and (max-width: 960px) {
  .payment-info {
    max-width: 100%;
  }
  .payment-info .disabled-existing-card {
    background: rgba(153, 153, 153, 0.11);
  }
  .payment-info .checkbox-card-type span {
    font-weight: 300;
  }
  .payment-info .checkbox-set-default {
    margin-left: 0;
    padding-top: 0;
  }
  .payment-info .creditcard-img {
    float: right;
    width: 170px;
    padding-top: 12px;
  }
  .card-info {
    max-width: 100%;
  }
  .card-info .info-title .info-sub-title-desc {
    float: right;
  }
  .card-info .card-info-section md-input-container {
    padding: 8px 0px;
  }
  .card-info .card-info-section .select-exist-address {
    width: 100%;
    padding-top: 3px;
  }
  .card-info .card-info-section .select-exist-address img {
    float: right;
    margin-top: -2px;
  }
  .card-info .card-info-section #new-address {
    padding-left: 25px;
  }
  .card-info .card-info-section #nameOnCardInputContainer {
    margin-left: 0;
  }
  .card-info .tooltip-wrapper a .tooltip {
    text-align: left;
    height: auto;
    font-size: 12px;
    left: -65px;
    bottom: 25px;
    min-height: 50px;
    width: 120px;
  }
  .card-info .tooltip-wrapper a .tooltip span {
    word-break: break-all;
    white-space: initial;
  }
}
@media (max-width: 599px) {
  .cvv {
    display: inline-block !important;
    width: 93%;
  }
  .tooltip-mob {
    display: inline-block !important;
    width: 5% !important;
  }
  .card-info .tooltip-wrapper a .tooltip {
    left: -278px !important;
    bottom: 26px !important;
    width: 283px !important;
  }
  .tooltip-container .tooltip-up .tooltip-arrow {
    left: auto;
    right: 3%;
  }
}
.ccexpired-error,
.custom-message {
  color: #cc3366;
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
}
.ccexpiry-month,
.ccexpiry-year {
  display: inline-block;
}
.ccexpiry-month md-select md-select-value,
.ccexpiry-year md-select md-select-value {
  min-width: 104px;
}
.selectpayment {
  font-size: 16px;
  text-align: left;
  padding: 10px 0;
}
.add-to-cart-quantity-button {
  max-width: none;
  color: #ffffff;
  position: static;
  background-color: #76bd23;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.add-to-cart-quantity-button a.add-cart {
  background-image: url(/assets/images/add-to-cart-icon.png);
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 100%;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  height: 30px;
  float: right;
  margin-left: -10px;
}
.add-to-cart-quantity-button .quantity {
  width: 63px;
}
.add-to-cart-quantity-button .quantity.dropdown div .dropdown-list {
  width: 158px;
}
.add-to-cart-quantity-button .quantity-adjustment-wrapper .quantity-adjustment {
  margin: 0 auto;
}
.cart-and-price {
  padding-top: 10px;
  border-top: 1px solid #e2e2e2;
  position: relative;
}
.product-price {
  font-size: 21px;
  color: #666;
  font-weight: 500;
}
.product-select-item {
  box-sizing: border-box;
  width: 100%;
  color: #56aa31;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  font-size: 13px;
  padding: 2px 20px 2px 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: transparent url('/assets/images/view-more-arrow.png') no-repeat right 7px center;
}
.product-select-item:focus {
  outline: none;
}
.product-select-item.disabled {
  background: none;
}
.backorder-text {
  color: #999999;
}
.backorder-link {
  color: #0e98df !important;
  cursor: pointer;
  font-size: 15px;
}
select.product-select-item::-ms-expand {
  display: none;
}
md-input-container.product-card-qty {
  margin: 0;
  line-height: normal;
  position: static;
}
md-input-container.product-card-qty input {
  background-color: #fff;
  color: #000;
  padding: 0 1px;
  border-radius: 5px;
  height: 26px;
  border: 1px solid #68a81f;
  margin-right: 5px;
  width: 32px;
  text-align: center;
  font-size: 14px;
  -moz-appearance: textfield;
}
md-input-container.product-card-qty input[disabled] {
  background-color: inherit;
  color: #fff;
  padding: 0 4px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.purchases .md-input-messages-animation {
  padding: 0;
  position: absolute;
  z-index: 2;
  overflow: visible;
  top: 100%;
  width: 100%;
  left: 0;
}
.purchases .md-input-messages-animation > div {
  background: #fff;
}
.multiple-of-msg {
  background: #fff;
  color: #333;
  z-index: 2;
  width: 100%;
  margin-top: 15px;
  left: 0;
  line-height: normal;
  position: absolute;
  top: 100%;
}
product-card .multiple-of-msg {
  box-shadow: 0 0 3px #adadad;
  padding: 10px;
}
.out-of-stock {
  margin-left: 25px;
  margin-top: 11px;
  position: relative;
}
.out-of-stock-message p {
  margin: 0;
}
.product-select-item span.md-select-icon,
.display-sd {
  display: none;
}
.quick-view-product-select-item {
  width: 40%;
  color: #56aa31;
  border: 1px solid #b7b7b7;
  border-radius: 18px;
  font-size: 13px;
  padding: 2px 20px 2px 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: transparent url('/assets/images/view-more-arrow.png') no-repeat right 7px center;
}
.quick-view-product-select-item:focus {
  outline: none;
}
.quick-view-product-select-item.disabled {
  background: none;
}
.quick-view-product-select-item span.md-select-icon,
.display-sd {
  display: none;
}
md-select.quick-view-product-select-item::-ms-expand {
  display: none;
}
.product-details .csa-stock-info-btn {
  background: none;
  margin-left: 14px;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  border: 2px solid hsl(210, 60%, 70%);
  border-radius: 5px;
  padding: 5px 12px;
  min-width: 83px;
}
.product-details .min-height-30 {
  min-height: 30px;
}
.quick-view-close-btn.popup-close.text-right > md-icon {
  margin: 2px 5px 0 0;
}
@media (max-width: 1024px) {
  .display-sd {
    display: block;
  }
  .hide-small-device {
    display: none;
  }
  .md-select-menu-container.md-active {
    margin-top: 0px;
  }
  md-select:not([disabled]):focus .md-select-value {
    border: 0;
  }
}
.main-content .restricted-message a {
  color: #669933;
}
.youmayalsolike-container .alt-product-image {
  height: 225px;
  width: 16.5em;
  padding: 49px 45px;
}
.youmayalsolike-container .alt-product-image .alt-show-product-detail-dialog {
  text-align: center;
  position: relative;
}
.youmayalsolike-container .alt-product-image .alt-show-product-detail-dialog .alt-quick-view-overlay {
  position: relative;
  width: 239px;
  height: 35px;
  top: -25px;
  right: 33px;
  padding: 8px 69px 8px 81px;
  background-color: rgba(221, 221, 221, 0.9);
  z-index: 10;
  bottom: 0;
}
.youmayalsolike-container .alt-product-image .alt-show-product-detail-dialog .alt-quick-view-overlay p {
  line-height: 19px;
  font-family: roboto;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #767676;
  opacity: 0.9;
  cursor: pointer;
  bottom: 0;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  text-transform: uppercase;
  outline: none;
}
.youmayalsolike-container .alt-product-body {
  background-color: #fafafa;
  width: 16.5em;
  flex: 1 0 auto;
}
.youmayalsolike-container .alt-brand-banner {
  padding: 26px 0 0 0;
  display: block;
}
.youmayalsolike-container .alt-brand-banner .alt-eqp-gold .tooltip-container {
  background: #fff url('/assets/images/icons/e-q-p-gold-icon-no-label.png') no-repeat 0 0;
  width: 1.438em;
  height: 22px;
  border-radius: 19px;
  object-fit: contain;
  background-size: 100%;
  margin-left: -12px;
}
.youmayalsolike-container .alt-brand-banner .alt-eqp-gold .tooltip-container:hover {
  background-position: 0 0;
}
.youmayalsolike-container .alt-brand-banner .alt-eqp-gold .tooltip-container .tooltip {
  height: auto;
  font-size: 12px;
}
.youmayalsolike-container .alt-brand-banner .alt-eqp-silver .tooltip-container {
  background: #fff url('/assets/images/icons/e-q-p-silver-icon-no-label.png') no-repeat 0 0;
  width: 1.438em;
  height: 22px;
  border-radius: 19px;
  object-fit: contain;
  background-size: 100%;
  margin-left: -12px;
}
.youmayalsolike-container .alt-brand-banner .alt-eqp-silver .tooltip-container:hover {
  background-position: 0 0;
}
.youmayalsolike-container .alt-brand-banner .alt-eqp-silver .tooltip-container .tooltip {
  height: auto;
  font-size: 12px;
}
.youmayalsolike-container .alt-brand-banner .alt-brand-name {
  font-size: 14px;
  font-family: roboto;
  line-height: 1.14;
  color: #666;
  padding-top: 5px;
}
.youmayalsolike-container .alt-brand-banner ul li {
  list-style-type: none;
  float: left;
  margin-right: 5px;
}
.youmayalsolike-container .alt-product-name {
  min-height: 75px;
}
.youmayalsolike-container .alt-product-name h4 {
  font-size: 22px;
  font-family: roboto;
  font-weight: 500;
  line-height: 1.14;
  color: #6e993c;
  margin-left: 27.8px;
  margin-bottom: 0;
}
.youmayalsolike-container .alt-product-price {
  padding: 25px 0 0 27.8px;
  font-family: roboto;
  font-size: 20px;
  line-height: 1.2;
  color: #666;
  visibility: visible;
  min-height: 40px;
}
.youmayalsolike-container .alt-div-30 {
  width: 30%;
  padding: 5% 1px 17.2px 27.8px;
  box-sizing: border-box;
}
.youmayalsolike-container .alt-div-70 {
  margin: 5% 6% 17.4px 25%;
  box-sizing: border-box;
}
.youmayalsolike-container .alt-increment {
  visibility: visible;
}
.youmayalsolike-container .alt-increment input {
  float: left;
  width: 55px;
  border: transparent;
  height: 37px;
  padding: 0 5px;
}
.youmayalsolike-container .alt-increment .btns {
  width: 10px;
  float: left;
  background-color: #707070;
  height: 37px;
  box-sizing: border-box;
  padding-right: 2px;
}
.youmayalsolike-container .alt-increment .btns a {
  width: 100%;
  float: right;
  width: 16px;
  padding: 1px;
  box-sizing: border-box;
  color: #666;
  height: 10px;
  text-decoration: none;
}
.youmayalsolike-container .alt-btn-slot {
  visibility: visible;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  padding: 0 30.8px 25px 27.8px;
}
.youmayalsolike-container .alt-btn-slot .add-to-cart-button {
  width: 217.4px;
  height: 37.6px;
  text-transform: uppercase;
  outline: none;
}
.youmayalsolike-container .alt-btn-slot .view-similar-btn {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  padding: 8px 20px;
  float: left;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  height: 36px;
  background-color: #76BD23;
  border: none;
}
.youmayalsolike-container .alt-product-item {
  border-radius: 5px;
  color: #666;
  width: 100px;
  height: 37.6px;
  border: solid 1px #707070;
}
.youmayalsolike-container .quantity-adjustment button.rectangle-quantity,
.youmayalsolike-container .quantity-adjustment input.quantity {
  width: 37px;
  height: 37px;
}
.youmayalsolike-container .product-select-item {
  background: transparent url(/assets/images/view-more-arrow-grey.png) no-repeat right 7px center !important;
}
.youmayalsolike-container .backorder-text {
  margin: -20px 0 0 25.3px;
  padding: 0;
}
.youmayalsolike-container .alt-product-price {
  float: left;
  width: 100%;
  height: 40px;
}
.youmayalsolike-container .alt-product-price ul {
  padding: 0;
}
.youmayalsolike-container .alt-product-price .product-conditions li {
  list-style-type: none;
  font-size: 12px;
  margin-left: 6px;
  float: right;
}
.youmayalsolike-container .alt-product-price .alt-product-price-pos-lt {
  float: left;
}
.youmayalsolike-container .alt-product-price .alt-product-price-pos-rt {
  float: right;
  margin-right: 20px;
}
.youmayalsolike-container .alt-drop-down {
  clear: both;
  min-height: 65px;
}
.youmayalsolike-container .thermo-icon .tooltip-container {
  background: #fafafa url(/assets/images/icons/heat-sensitive.png) no-repeat 0 0;
  width: 10px;
  min-width: 10px;
  height: 27px;
  min-height: 27px;
  background-size: 100%;
  top: 0;
}
.youmayalsolike-container .short-shelf-life .tooltip-container {
  background: #fafafa url(/assets/images/icons/short-shell.png) no-repeat 0 0;
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  background-size: 100%;
  background-position: center center;
}
.youmayalsolike-container .product-condition-tooltip h3,
.youmayalsolike-container .alt-related-products h3 {
  font-size: 1.17em;
}
.youmayalsolike-container .alt-related-products h3.alt-title {
  font-size: 30px;
}
.youmayalsolike-container .alt-brand-feature {
  margin: 8px;
}
.youmayalsolike-container .alt-brand-feature .feature-label {
  margin-right: 5px;
  float: left;
  padding: 5px;
  background: #76bd23;
  color: white;
  border-radius: 3px;
  width: 75px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.1px;
  text-decoration: none;
}
.youmayalsolike-container .add-to-cart-notice {
  margin-top: -10px;
}
.youmayalsolike-container .padTop {
  margin-top: 25px;
  padding-top: 20px;
  text-align: center;
}
.progress-linear {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #cbcbcb;
  background-clip: padding-box;
  overflow: hidden;
  /* We might use this later */
}
.progress-linear .indeterminate {
  background-color: #76bd23;
}
.progress-linear .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress-linear .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 2.15s;
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.progress-spinner {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.progress-spinner .progress-spinner-message-wrapper p {
  margin: 5px 0;
}
.progress-spinner .spinner {
  animation: rotator 1.4s linear infinite;
  width: 45px;
  height: 45px;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes ie-rotator {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.progress-spinner .path {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  transform-origin: center;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@media all and (-ms-high-contrast: none) {
  .progress-spinner *::-ms-backdrop,
  .progress-spinner .spinner {
    animation: ie-rotator 1.4s linear infinite;
  }
  .progress-spinner *::-ms-backdrop,
  .progress-spinner .path {
    stroke-dasharray: 180%;
  }
}
@keyframes colors {
  0% {
    stroke: #4399c5;
  }
  100% {
    stroke: #4399c5;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 180;
  }
  50% {
    stroke-dashoffset: 45;
    transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 180;
    transform: rotate(450deg);
    -ms-transform: rotate(450deg);
    -webkit-transform: rotate(450deg);
  }
}
.progress-spinner-icon .spinner {
  height: 25px;
  width: 25px;
}
.progress-spinner-btn .spinner {
  height: 15px;
  width: 15px;
}
.quick-order-spinner .spinner {
  height: 30px;
  width: 30px;
}
#index-spinner .spinner {
  height: 96px;
  width: 96px;
}
.loading-overlay .progress-spinner {
  display: block;
}
.promo-banner-side-col {
  margin: 40px 0 30px 0;
  text-align: center;
}
.promo-banner-side-col .promo-banner a.hover {
  cursor: pointer;
}
quantity-adjustment > .quantity-adjustment button {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  color: #79c143;
  background-color: #fff;
  border: 1px solid #999;
}
quantity-adjustment > .quantity-adjustment button.rectangle-quantity {
  width: 25px;
  height: 29px;
  box-sizing: border-box;
}
quantity-adjustment > .quantity-adjustment button.rectangle-quantity.disableClick {
  color: rgba(0, 0, 0, 0.38);
}
quantity-adjustment > .quantity-adjustment button.update-quantity-button {
  opacity: 0;
  margin-left: 8px;
  min-height: unset;
  height: 29px;
  font-size: 14px;
  line-height: 29px;
}
quantity-adjustment > .quantity-adjustment button.update-quantity-button.active {
  opacity: 1;
}
quantity-adjustment > .quantity-adjustment input.quantity {
  text-align: right;
  width: 40px;
  padding-right: 5px;
  height: 29px;
  border: 1px solid #999999;
  border-left: 0;
  border-right: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: #454553;
  box-sizing: border-box;
  -webkit-appearance: none;
}
quantity-adjustment > .quantity-adjustment p.small-text {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 15px;
}
quantity-adjustment > .quantity-adjustment .updating-quantity-progress-wrapper {
  width: 88px;
  margin-left: 8px;
}
quantity-adjustment > .quantity-adjustment .updating-quantity-progress-wrapper .updating-quantity-progress {
  height: 29px !important;
  width: 29px !important;
  margin: 0 auto;
}
quantity-adjustment > .quantity-adjustment .updating-quantity-progress-wrapper .updating-quantity-progress > svg {
  width: 29px !important;
  height: 29px !important;
  -webkit-transform-origin: center !important;
  -moz-transform-origin: center !important;
  -ms-transform-origin: center !important;
  -o-transform-origin: center !important;
  transform-origin: center !important;
}
@media (max-width: 599px) {
  quantity-adjustment > .quantity-adjustment div.layout-xs-column {
    align-items: center;
    display: flex;
    flex-flow: column;
  }
  quantity-adjustment > .quantity-adjustment button.rectangle-quantity,
  quantity-adjustment > .quantity-adjustment input {
    display: inherit;
  }
  quantity-adjustment > .quantity-adjustment button.rectangle-quantity {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    border-color: rgba(0, 0, 0, 0.31);
  }
  quantity-adjustment > .quantity-adjustment button.rectangle-quantity.decrement-button {
    padding: 0 12px;
    line-height: 31px;
    font-size: 31px;
  }
  quantity-adjustment > .quantity-adjustment button.rectangle-quantity.increment-button {
    padding: 0 10px;
    font-size: 23px;
  }
  quantity-adjustment > .quantity-adjustment input.quantity {
    height: 35px;
    width: 55px;
    padding: 0;
    text-align: center;
    font-size: 16px;
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.31);
    border-radius: 4px;
  }
  quantity-adjustment > .quantity-adjustment p.small-text {
    font-size: 12px;
    margin-top: 4px;
    width: 135px;
    text-align: center;
  }
  quantity-adjustment > .quantity-adjustment .update-quantity-button-wrapper .update-quantity-button {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.31);
  }
}
.quantity-adjustment button {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  background-color: #666666;
  border: 1px solid #999;
}
.quantity-adjustment button.rectangle-quantity {
  width: 30px;
  height: 29px;
  box-sizing: border-box;
}
.quantity-adjustment button.rectangle-quantity.decrement-button {
  border-bottom-left-radius: 25%;
  border-top-left-radius: 25%;
}
.quantity-adjustment button.rectangle-quantity.increment-button {
  border-bottom-right-radius: 25%;
  border-top-right-radius: 25%;
}
.quantity-adjustment button.update-quantity-button {
  opacity: 0;
  margin-left: 8px;
  min-height: unset;
  height: 29px;
  font-size: 14px;
  line-height: 29px;
}
.quantity-adjustment button.update-quantity-button.active {
  opacity: 1;
}
.quantity-adjustment input.quantity {
  text-align: center;
  width: 35px;
  padding-right: 3px;
  height: 29px;
  border: 1px solid #999999;
  border-left: 0;
  border-right: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #454553;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.quantity-adjustment p.small-text {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 15px;
}
.quantity-adjustment .updating-quantity-progress-wrapper {
  width: 88px;
  margin-left: 8px;
}
.quantity-adjustment .updating-quantity-progress-wrapper .updating-quantity-progress {
  height: 29px !important;
  width: 29px !important;
  margin: 0 auto;
}
.quantity-adjustment .updating-quantity-progress-wrapper .updating-quantity-progress > svg {
  width: 29px !important;
  height: 29px !important;
  -webkit-transform-origin: center !important;
  -moz-transform-origin: center !important;
  -ms-transform-origin: center !important;
  -o-transform-origin: center !important;
  transform-origin: center !important;
}
@media (max-width: 599px) {
  .quantity-adjustment div.layout-xs-column {
    align-items: center;
    display: flex;
    flex-flow: column;
  }
  .quantity-adjustment button.rectangle-quantity,
  .quantity-adjustment input {
    display: inline-block;
  }
  .quantity-adjustment button.rectangle-quantity {
    width: 30px;
    padding: 0;
    border-color: rgba(0, 0, 0, 0.31);
    line-height: 27px;
  }
  .quantity-adjustment button.rectangle-quantity.decrement-button {
    border-radius: 0;
    font-size: 31px;
  }
  .quantity-adjustment button.rectangle-quantity.increment-button {
    border-radius: 0;
    font-size: 23px;
  }
  .quantity-adjustment input.quantity {
    padding: 0;
    text-align: center;
    font-size: 14px;
    margin: 0 0;
  }
  .quantity-adjustment p.small-text {
    font-size: 12px;
    margin-top: 4px;
    width: 135px;
    text-align: center;
  }
  .quantity-adjustment .update-quantity-button-wrapper .update-quantity-button {
    border-radius: 8px;
    border-color: rgba(0, 0, 0, 0.31);
  }
}
.my-quick-order.quick-order a {
  color: #669933;
}
.my-quick-order.quick-order a.quick-order-restriction-msg {
  color: #000;
}
@media only screen and (max-width: 959px) {
  .my-quick-order.quick-order {
    position: static;
  }
}
.my-quick-order.quick-order .small-width-add-button {
  display: none;
}
@media only screen and (max-width: 359px) {
  .my-quick-order.quick-order .large-width-add-button {
    display: none;
  }
  .my-quick-order.quick-order .small-width-add-button {
    display: inherit;
    padding: 15px 15px 0 0;
  }
  .my-quick-order.quick-order .small-width-add-button > button {
    width: 100%;
  }
}
.alert-message {
  vertical-align: middle;
  background-image: url(/assets/images/icons/attention-icon.png);
  background-repeat: no-repeat;
  padding: 0 0 0 24px;
}
.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;
}
.view-more-btn:before {
  background-image: url('/assets/images/view-more-arrow.png');
  background-repeat: no-repeat;
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  left: 0;
}
/*Responsive table*/
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 960px), (min-device-width: 768px) and (max-device-width: 1024px) {
  #my-rx-credentials {
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
    /*
		Label the data
		*/
  }
  #my-rx-credentials table,
  #my-rx-credentials thead,
  #my-rx-credentials tbody,
  #my-rx-credentials th,
  #my-rx-credentials td,
  #my-rx-credentials tr {
    display: block;
  }
  #my-rx-credentials thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #my-rx-credentials tr {
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  #my-rx-credentials td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  #my-rx-credentials td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  #my-rx-credentials td:nth-of-type(1):before {
    content: "Professional Designation";
  }
  #my-rx-credentials td:nth-of-type(2):before {
    content: "Document";
  }
  #my-rx-credentials td:nth-of-type(3):before {
    content: "Doc. Status";
  }
  #my-rx-credentials td:nth-of-type(4):before {
    content: "";
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
}
.recommended-flyout {
  padding: 8px 5px;
  font-size: 14px;
}
.recommended-flyout .flex-column {
  display: flex;
  flex-flow: column;
}
.recommended-flyout .sub-title {
  margin: 0;
  margin-bottom: 10px;
  padding: 0 5px;
}
.recommended-flyout .sub-title .sub-title-1 {
  color: #999;
  font-weight: 500;
  margin-bottom: 10px;
}
.recommended-flyout .sub-title .sub-title-2 {
  color: rgba(0, 0, 0, 0.87);
}
.recommended-flyout hr {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #e6e6e6;
  margin: 0 auto;
}
.recommended-flyout ul {
  padding: 0 6px;
}
.recommended-flyout ul li {
  line-height: 1.5;
  list-style: none;
}
.recommended-flyout ul li a {
  color: #693;
  text-decoration: none;
}
.recommended-flyout ul li a:hover {
  color: #000;
  text-decoration: underline;
}
.recommended-flyout .shop-all-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.1px;
  text-align: center;
  color: #fff;
  width: 138px;
  height: 40px;
  background-color: #79c143;
  margin: 30px auto 0 auto;
}
/*Auto complete*/
.nav-search-box .search-box-area {
  width: 100%;
}
.nav-search-box md-autocomplete.search-auto {
  width: 100%;
}
.nav-search-box md-autocomplete.search-auto button {
  position: absolute;
  right: 50px;
  top: 3px;
}
.nav-search-box md-autocomplete.search-auto input:not(.md-input) {
  font-size: 18px;
}
.nav-search-box .md-whiteframe-1dp,
.nav-search-box .md-whiteframe-z1 {
  box-shadow: none;
}
.nav-search-box .search-icon {
  position: absolute;
  right: 30px;
  top: 9px;
  border-left: 1px solid #b7b7b7;
  padding: 0 0 0 10px;
  width: 44px;
  height: 30px;
  min-height: 24px;
  min-width: auto;
  background-color: transparent;
  margin: 0;
  z-index: 99;
}
.nav-search-box .search-icon.md-button.md-primary:hover {
  background-color: transparent;
}
.nav-search-box .search-icon:hover {
  background-color: transparent;
}
.nav-search-box .search-icon md-icon {
  vertical-align: baseline;
}
.nav-search-box .search-icon .emersonicon.emersonicon-search:before {
  width: 24px;
  height: 24px;
  margin-top: -10px;
}
.md-autocomplete-suggestions li {
  height: auto;
}
.md-not-found .type-ahead-search-m > li {
  margin-top: 10px;
}
.no-results-found,
.md-not-found .type-ahead-search-m {
  padding: 10px 10px;
  font-size: medium;
  color: #666666;
}
.md-virtual-repeat-container.search-container {
  min-height: 900px;
  height: 100%;
  overflow-y: scroll;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller {
  overflow: visible;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions {
  padding-bottom: 40px;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li {
  line-height: 10px;
  margin: 0;
  padding: 0;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li:focus,
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li:hover {
  background-color: #fff;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li:first-child .seperate-line {
  border-top: 0;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left {
  border-right: solid 1px #e6e6e6;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .title {
  margin: 5px;
  padding: 8px 5px 5px 5px;
  color: #666666;
  cursor: default;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata {
  margin: 5px;
  padding: 0;
  color: #669933;
  line-height: 1;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata:focus,
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata:hover {
  background-color: #f5f5f5;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .item-metastat {
  padding: 0;
  margin: 0;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-list-search .left .item-metadata .item-metastat div {
  padding: 0;
  margin: 0;
  color: #669933;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .title {
  margin: 5px;
  padding: 8px 5px 5px 5px;
  color: #666666;
  cursor: default;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata {
  margin: 5px;
  padding: 0;
  color: #669933;
  line-height: 1;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata:focus,
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata:hover {
  background-color: #f5f5f5;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata .item-metastat {
  padding: 0;
  margin: 0;
}
.md-virtual-repeat-container.search-container .md-virtual-repeat-scroller .md-virtual-repeat-offsetter ul.md-autocomplete-suggestions li .show-recent-search .item-metadata .item-metastat div {
  padding: 0;
  margin: 0;
  color: #669933;
}
.seperate-line {
  border-top: solid 1px #e6e6e6;
}
.shipping-address-list {
  margin-top: 20px;
  margin-bottom: 30px;
}
.shipping-address-list.drop-ship-wrapper {
  margin-top: 15px;
}
.shipping-address-list .header-title.no-underline {
  margin-bottom: 0;
}
.shipping-address h2 {
  margin: 0;
}
.shipping-address .sub-tab {
  margin-bottom: 20px;
}
.shipping-address .add-more-btn {
  display: block;
}
.shipping-address-list .table td {
  padding: 15px;
}
.shipping-address-list-wrapper .sub-tab {
  width: 50%;
  box-sizing: border-box;
}
.shipping-address-list-wrapper .sub-tab.full {
  width: 100%;
}
.shipping-address-list-wrapper .sub-tab.full li {
  width: 50%;
}
.shipping-address-list-wrapper .sub-tab li {
  width: 100%;
  float: left;
}
.shipping-address-list-wrapper .sub-tab li a {
  padding: 7px;
}
.shipping-address-list-wrapper .find-shipping {
  width: 350px;
  float: right;
  margin-bottom: 0;
  margin-top: 0;
}
.shipping-address-list-wrapper .find-shipping .md-errors-spacer {
  min-height: 0;
}
.shipping-address-list-wrapper .find-shipping .input-message {
  clear: both;
  padding: 10px 0;
}
.default-shipping-address-table,
.shipping-address-table {
  position: relative;
  /*edit/delete*/
}
.default-shipping-address-table .table tbody tr,
.shipping-address-table .table tbody tr {
  border-bottom: solid 1px #d7d7d7;
}
.default-shipping-address-table .table tbody tr:last-child,
.shipping-address-table .table tbody tr:last-child {
  border-bottom: solid 1px #999999;
}
.default-shipping-address-table td:nth-of-type(3),
.shipping-address-table td:nth-of-type(3) {
  text-align: right;
  padding-right: 15px;
}
.default-shipping-address-table td:nth-of-type(3) .trashcan,
.shipping-address-table td:nth-of-type(3) .trashcan {
  margin-left: 20px;
}
.default-shipping-address-table .set-as-default-link,
.shipping-address-table .set-as-default-link {
  font-size: 14px;
  line-height: 2;
}
.shipping-address-table {
  margin-top: 40px;
}
.shipping-address-table.drop-ship-address-type {
  margin-top: 10px;
}
.default-shipping-address-table .table tbody tr:last-child {
  border-bottom: 0;
}
address-form {
  width: 100%;
}
@media (max-width: 959px) {
  .shipping-address-list-wrapper .find-shipping {
    width: 100%;
    float: none;
    padding-right: 40px;
    box-sizing: border-box;
  }
  .shipping-address-table {
    margin-top: 20px;
  }
  .shipping-address-table .required-wrapper {
    position: absolute;
    right: 0;
    top: 15px;
  }
  .sub-tab {
    margin: 10px 0 0;
  }
  .shipping-address-list .required-wrapper {
    font-size: 12px;
  }
  .shipping-address-list .search-address {
    margin: 20px 0 0 0;
  }
  .shipping-address-list .header-title.no-underline {
    margin-top: 10px;
  }
  .shipping-address.list-unstyled.unit li.left-divider {
    border-left: 0;
    margin-right: 0;
    float: right;
  }
  .shipping-address.list-unstyled.unit li.left-divider .add-address {
    margin-top: 5px;
  }
  .shipping-address {
    margin-top: 30px;
  }
  .shipping-address h2 {
    margin-top: 0;
  }
}
@media (max-width: 320px) {
  .shipping-address-list-wrapper .sub-tab li a {
    font-size: 13px;
  }
  .shipping-address {
    margin-top: 30px;
  }
  .shipping-address.list-unstyled.unit li {
    margin-right: 5px;
  }
  .shipping-address.list-unstyled.unit li .add-more-btn {
    min-width: 60px;
  }
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 959px) {
  .shipping-address-table {
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
    /*
    Label the data
    */
    /*edit/delete*/
  }
  .shipping-address-table table,
  .shipping-address-table tbody,
  .shipping-address-table td,
  .shipping-address-table th,
  .shipping-address-table thead,
  .shipping-address-table tr {
    display: block;
  }
  .shipping-address-table thead tr td {
    text-transform: uppercase;
    font-weight: 700;
  }
  .shipping-address-table thead tr td:nth-of-type(1) {
    display: none;
  }
  .shipping-address-table tr {
    margin-bottom: 15px;
    position: relative;
  }
  .shipping-address-table td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 0;
    padding-right: 65px;
  }
  .shipping-address-table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 0;
    padding-right: 10px;
    white-space: nowrap;
    display: none;
  }
  .shipping-address-table td:nth-of-type(1):before {
    content: "Name";
  }
  .shipping-address-table td:nth-of-type(2):before {
    content: "Address";
  }
  .shipping-address-table td:nth-of-type(3) {
    position: absolute;
    right: 0;
    top: 0;
  }
  .shipping-address-table td:nth-of-type(3) .trashcan {
    margin-left: 10px;
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  .default-shipping-address-table {
    /* Force table to not be like tables anymore */
    /* Hide table headers (but not display: none;, for accessibility) */
    /*edit/delete*/
  }
  .default-shipping-address-table table,
  .default-shipping-address-table tbody,
  .default-shipping-address-table td,
  .default-shipping-address-table th,
  .default-shipping-address-table thead,
  .default-shipping-address-table tr {
    display: block;
  }
  .default-shipping-address-table thead tr {
    display: none;
  }
  .default-shipping-address-table thead tr td {
    text-transform: uppercase;
    font-weight: 700;
  }
  .default-shipping-address-table thead tr td:nth-of-type(1) {
    display: none;
  }
  .default-shipping-address-table tr {
    margin-bottom: 15px;
    position: relative;
  }
  .default-shipping-address-table td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 0;
    padding-right: 40px;
  }
  .default-shipping-address-table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 0;
    padding-right: 10px;
    white-space: nowrap;
    display: none;
  }
  .default-shipping-address-table td:nth-of-type(3) {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.shipping-add-edit-save-btn {
  margin: 0 auto;
  display: block;
}
.close-pencil-btn {
  float: right;
  transform: rotate(-180deg);
}
.close-pencil-btn-td {
  padding-right: 0;
}
md-dialog.md-transition-in .checkout-actions-tab {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.text-12 {
  font-size: 12px;
}
.disable-shipping-address {
  background-color: #BEBEBE;
}
.shipping-warning {
  color: #e00603;
}
.lowercase {
  text-transform: none;
}
.shipping-option-row {
  border: 1px solid #d0d0d0;
  border-radius: 15px;
  padding: 20px;
}
.selected {
  border: 2px solid #79c143;
  border-radius: 15px;
  margin-bottom: 20px;
}
.ship-via-text {
  display: block;
  text-transform: none;
  font-weight: 300;
  font-size: 13px;
}
delivery-options md-radio-button .md-off {
  left: 10px;
}
delivery-options md-radio-button .md-on {
  left: 10px;
}
#removeItemsDialog {
  min-width: 309px;
}
#removeItemsDialog md-dialog-content {
  padding: 0;
}
#removeItemsDialog md-dialog-content > div {
  padding: 0 20px 20px 20px;
}
#removeItemsDialog md-dialog-content > div.padding-top {
  padding-top: 20px;
}
#removeItemsDialog .layout-column {
  display: flex;
}
#removeItemsDialog .close-button {
  background-color: #fff;
  border: none;
  font-size: 29px;
  width: 27px;
  height: 27px;
  line-height: 2px;
  margin-right: 4px;
  outline: none;
  color: rgba(0, 0, 0, 0.38);
}
#removeItemsDialog .close-button:hover {
  color: rgba(0, 0, 0, 0.54);
}
#removeItemsDialog md-progress-circular {
  margin-bottom: 10px;
}
#removeItemsDialog p {
  margin: 0;
  text-align: center;
}
.shipping-step {
  max-width: 796px;
  padding-top: 30px;
  color: #333333;
}
.shipping-step .loading-wrapper {
  margin-top: 30px;
}
.shipping-step hr {
  background-color: #333333;
  border-top: solid 1px #333333;
  margin-bottom: 0;
}
.shipping-step .step-heading {
  margin-bottom: 0;
}
.shipping-step .margin-bottom-10 {
  margin-bottom: 10px;
}
.shipping-step .star-text {
  margin-top: -10px;
  font-size: 14px;
  line-height: 2;
  text-align: right;
}
.shipping-step .address-form {
  color: #4399c5;
  padding-top: 15px;
}
.shipping-step .address-form .margin-right-35 {
  margin-right: 35px;
}
@media (max-width: 768px) {
  .shipping-step .address-form .margin-right-35 {
    margin-right: unset;
  }
}
.shipping-step .address-form .error-msg {
  color: rgba(0, 0, 0, 0.87);
}
.shipping-step .address-form .type-wrapper {
  padding-top: 20px;
}
.shipping-step .address-form .type-wrapper .type-options-wrapper {
  position: relative;
}
.shipping-step .address-form .type-wrapper .type-options-wrapper .info-icon-wrapper {
  padding: 15px 0 0 5px;
}
.shipping-step .address-form .type-wrapper .checkbox-set-default {
  padding-top: 30px;
  vertical-align: middle;
}
.shipping-step .address-form .type-wrapper .checkbox-set-default .md-label {
  padding-top: 5px;
}
.shipping-step .address-form .type-wrapper .checkbox-set-default span {
  color: #333333;
}
.shipping-step .address-form .select-exist-address {
  font-size: 16px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4399c5;
  margin: 0;
  border: 1px solid #4399c5;
  border-radius: 4px;
  background-color: white;
}
.shipping-step .address-form .select-exist-address span {
  padding-left: 5px;
}
.shipping-step .address-form .select-exist-address img {
  margin-left: 5px;
}
@media (max-width: 768px) and (min-width: 599px) {
  .shipping-step .address-form #btnAddWrapper {
    max-width: 37%;
  }
}
.shipping-step .address-form #btnAddWrapper .add-new-address-btn {
  color: #D3D3D3;
  border: 1px solid #D3D3D3;
}
.shipping-step .save-address-wrapper p,
.shipping-step .view-options p {
  max-width: 738px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  text-align: left;
  margin-bottom: 40px;
}
.shipping-step .save-address-wrapper button,
.shipping-step .view-options button {
  padding: 2px 40px;
  margin: 30px 0;
}
.shipping-step .save-address-wrapper .recommendation-address md-checkbox,
.shipping-step .view-options .recommendation-address md-checkbox {
  margin-bottom: 30px;
}
.shipping-step .save-address-wrapper .recommendation-address md-checkbox span,
.shipping-step .view-options .recommendation-address md-checkbox span {
  padding-left: 10px;
  font-size: 16px;
  text-align: left;
  color: #333333;
  vertical-align: middle;
}
.shipping-step .update-address {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.2px;
  text-align: left;
  padding-bottom: 30px;
}
.shipping-step .update-address a {
  color: #4399c5;
}
.shipping-step .delivery-options-wrapper {
  padding-top: 35px;
}
.shipping-step .delivery-options-wrapper .delivery-opt {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333333;
}
.shipping-step .delivery-options-wrapper .shipping-container {
  padding-top: 20px;
  margin-bottom: 25px;
}
.shipping-step .delivery-options-wrapper .shipping-container .shipping-option .md-label {
  color: #333333;
}
.shipping-step .delivery-options-wrapper .shipping-container .delivery-option {
  width: 330px;
}
.shipping-step .delivery-options-wrapper .shipping-container .delivery-option-cost {
  width: 50px;
  text-align: right;
}
.shipping-step .delivery-options-wrapper .shipping-container .delivery-option-free {
  width: 50px;
  text-align: right;
  color: #79c143;
  font-weight: bold;
}
.shipping-step .delivery-options-wrapper .shipping-container .warning-content {
  position: relative;
  padding-top: 10px;
  padding-left: 40px;
  font-size: 14px;
  line-height: 1.14;
  color: #333333;
}
.shipping-step .delivery-options-wrapper .shipping-container .warning-content:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background-image: url('/assets/images/flame.png');
  background-repeat: no-repeat;
  width: 27px;
  height: 25px;
}
.shipping-step .delivery-options-wrapper .shipping-cutoff-msg {
  font-weight: 300;
  margin-bottom: 30px;
  margin: 20px;
}
.shipping-step .delivery-options-wrapper .shipping-cutoff-msg ul {
  padding-left: 18px;
  font-size: 13px;
}
.shipping-step .delivery-options-wrapper .shipping-cutoff-msg ul li {
  line-height: 1.4em;
}
.shipping-step .delivery-options-wrapper .shipping-cutoff-msg p {
  font-size: 13px;
}
.shipping-step .shipping-cost-override {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.38;
  text-align: left;
  float: left;
}
.shipping-step .shipping-cost-override hr {
  width: 360px;
}
.shipping-step .shipping-cost-override span {
  font-size: 12px;
  line-height: 2.33;
  margin-bottom: 0;
}
.shipping-step .shipping-cost-override md-input-container {
  margin-top: 5px;
  margin-bottom: 40px;
}
.shipping-step .shipping-cost-override md-input-container md-label:not(.md-no-float):not(.md-container-ignore) {
  font-size: 16px;
  font-weight: normal;
  color: #333333;
  vertical-align: middle;
}
.shipping-step .shipping-cost-override md-input-container input {
  width: 66px;
}
.shipping-step .shipping-cost-override md-input-container input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4399c5;
}
.shipping-step .shipping-cost-override md-input-container input::-moz-placeholder {
  /* Firefox 19+ */
  color: #4399c5;
}
.shipping-step .shipping-cost-override md-input-container input:-ms-input-placeholder {
  /* IE 10+ */
  color: #4399c5;
}
.shipping-step .shipping-cost-override md-input-container input:-moz-placeholder {
  /* Firefox 18- */
  color: #4399c5;
}
.shipping-step md-select .md-select-value {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.shipping-step md-select:not([disabled]):focus .md-select-value {
  padding-bottom: 0;
  border-bottom-width: 1px;
}
.shipping-step .uppercase {
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
  font-size: 16px;
}
.shipping-step .margin-top-30 {
  margin-top: 30px;
}
#recommend-address {
  margin-bottom: 10px;
}
#recommend-address .top-border {
  border-top: solid 1px #d7d7d7;
}
#recommend-address md-dialog md-dialog-content {
  padding: 0;
  margin: 0;
  padding: 0 0 50px;
}
#recommend-address md-dialog md-dialog-content table {
  width: 100%;
  font-size: 16px;
  text-align: left;
  color: #333333;
  outline: none;
}
#recommend-address md-dialog md-dialog-content table.original-table {
  border-bottom: 0;
  padding-bottom: 8px;
}
#recommend-address md-dialog md-dialog-content table.original-table tr {
  border-bottom: 0 !important;
}
#recommend-address md-dialog md-dialog-content table tbody {
  background-color: #f2f2f2;
  outline: none;
  cursor: pointer;
}
#recommend-address md-dialog md-dialog-content table tbody:last-child {
  border-bottom: 0;
}
#recommend-address md-dialog md-dialog-content table tbody.highlight-table {
  background-color: #ffffff;
  outline: none;
}
#recommend-address md-dialog md-dialog-content table tbody tr {
  border-bottom: solid 1px #d7d7d7;
  padding: 0 0 0 55px;
  outline: none;
}
#recommend-address md-dialog md-dialog-content table tbody tr td .checked-mark {
  text-align: left;
  padding-top: 7px;
}
#recommend-address md-dialog md-dialog-content table tbody tr td.original-title-text {
  padding: 20px 0 0 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.2px;
}
#recommend-address md-dialog md-dialog-content .md-label {
  width: 100%;
}
#recommend-address md-dialog md-dialog-content .original-actions-tab {
  min-height: 75px;
}
#recommend-address.md-dialog-container {
  font-size: 16px;
  color: #333333;
}
#recommend-address.md-dialog-container .title-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.2px;
  margin-top: 0;
  padding-left: 105px;
}
#recommend-address.md-dialog-container .suggestion-text {
  padding-left: 80px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  text-align: left;
}
#recommend-address.md-dialog-container p {
  padding-left: 50px;
}
#recommend-address.md-dialog-container .selected-address-button {
  text-align: center;
}
#recommend-address.md-dialog-container .selected-address-button button {
  padding: 3px 40px;
}
#recommend-address.md-dialog-container md-dialog md-toolbar {
  min-height: 110px;
}
#recommend-address.md-dialog-container md-dialog md-toolbar .md-toolbar-tools {
  padding: 20px 0 0 30px;
  text-align: left;
}
#recommend-address.md-dialog-container md-dialog md-toolbar .md-toolbar-tools .title-text {
  padding: 0 23px;
}
#recommend-address .loading-wrapper {
  margin: 50px auto;
}
#quantity-reduce md-dialog md-toolbar {
  min-height: 170px;
}
#quantity-reduce md-dialog md-toolbar .tools-header {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.12;
  position: relative;
  margin-top: 20px;
}
#quantity-reduce md-dialog md-toolbar .sub-header {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.33;
  padding: 0 7%;
  position: relative;
}
#quantity-reduce hr {
  width: 100%;
  border: solid 1px #d7d7d7;
}
#quantity-reduce .tab-wrapper {
  left: 0;
  bottom: 0;
  position: relative;
  width: 100%;
  height: 75px;
  box-sizing: border-box;
}
#quantity-reduce .header-thead {
  padding: 15px 60px 5px 60px;
  font-size: 16px;
}
#quantity-reduce .header-thead .smaller {
  font-size: 0.75rem;
}
#quantity-reduce .border-bottom {
  border-bottom: solid 1px #d7d7d7;
}
#quantity-reduce .adjustment-wrapper tr td:last-child {
  padding-right: 0;
}
md-dialog md-dialog-actions.tab-wrapper-dialog-actions {
  padding: 0px;
  border-top-width: 0px ;
  border-top-style: none;
  min-height: 75px;
}
.tab-wrapper-button {
  padding: 10px 0px;
}
.myCart-wrapper .checkout-actions-tab ul li a.disabled,
.myCart-wrapper .checkout-actions-tab ul li a[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  border: 1px solid #BEBEBE;
  color: #E4E4E4;
  background-color: #999999;
}
.prop-65-warning {
  margin: 20px 0 30px;
}
.warning-icon {
  padding-left: 40px;
  background-image: url("/assets/images/icons/attention-icon-cart-process.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 20px 0;
  min-height: 30px;
}
.warning-icon .bigger {
  font-size: 24px;
  line-height: 1.3;
}
.warning-icon p {
  margin: 0;
}
.item-summary-table {
  margin: 30px 0;
}
.item-summary-table thead tr {
  border-bottom: 0;
}
.item-summary-table thead tr td {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.2px;
}
.item-summary-table tbody tr {
  border-bottom: solid 1px #d7d7d7;
}
.item-summary-table tbody tr td a {
  color: #333333;
  font-weight: bold;
}
.stock-status-change-warning .approval-input md-input-container {
  margin: 10px 40px 20px 40px;
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 599px) {
  .shipping-container {
    flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    /* mozilla */
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
  }
  .shipping-container .shipping-option .md-label {
    font-size: 12px !important;
  }
  .shipping-container .delivery-option {
    padding-right: 70px !important;
  }
}
@media only screen and (max-width: 959px) {
  .shipping-step .star-text {
    display: none;
  }
  .shipping-step .address-form .type-wrapper .checkbox-set-default {
    padding-top: 5px;
  }
  .shipping-step .address-form .type-wrapper .type-options-wrapper .info-icon-wrapper {
    left: 100%;
  }
  .shipping-step .delivery-options-wrapper .shipping-container .rectangle-wrapper .rectangle {
    text-align: left;
    margin-bottom: 10px;
    padding: 15px;
    height: auto;
  }
  .shipping-step .delivery-options-wrapper .shipping-container .rectangle-wrapper .rectangle p {
    float: right;
    position: absolute;
    right: 12px;
    top: 0;
  }
  .shipping-step .delivery-options-wrapper .shipping-container .rectangle-wrapper .rectangle p .dollar-sign {
    font-size: 12px;
    vertical-align: 15%;
  }
  .shipping-step .address-form .select-exist-address {
    width: 100%;
  }
  .shipping-step .address-form .select-exist-address img {
    float: right;
  }
  .shipping-step .view-options button {
    margin: 20px auto;
    width: 100%;
  }
  .delivery-options-wrapper .warning-content {
    display: none;
  }
  #existing-address md-list-item.bold-text {
    background-color: #ffffff;
  }
  #existing-address md-dialog {
    width: 100%;
  }
  #existing-address md-dialog md-toolbar {
    height: auto;
    min-height: 150px;
  }
  #existing-address md-dialog md-toolbar p {
    font-size: 12px;
    padding: 25px 0 15px 0;
    text-align: center;
  }
  #existing-address md-dialog md-toolbar p.info {
    font-size: 11px;
    padding: 0;
    margin: 5px 0;
  }
  #existing-address md-dialog md-toolbar.toolbar-height {
    min-height: 200px;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper {
    margin: 5px 0;
    font-size: 14px;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .shipping-title {
    display: inline;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .shipping-description {
    display: inline;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .shipping-description p {
    font-weight: 300;
    font-size: 12px;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper md-tabs-wrapper {
    margin-top: 5px;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper md-tabs-wrapper md-pagination-wrapper,
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper md-tabs-wrapper md-tabs-canvas {
    overflow: visible;
    border: none;
    height: 40px;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab {
    padding: 8px;
    font-size: 14px;
    text-transform: capitalize;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper md-tabs-wrapper .md-tab a.tooltip-container:hover {
    display: none;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .find-wrapper {
    margin: 0;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping {
    width: 100%;
    padding-top: 0;
    margin-top: 0;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping input {
    font-size: 10px;
  }
  #existing-address md-dialog md-toolbar .shipping-address-list-wrapper .find-wrapper .find-shipping .input-message {
    font-size: 10px;
  }
  #existing-address md-dialog md-toolbar .md-toolbar-tools .md-close.md-button {
    background-color: transparent;
  }
  #existing-address md-dialog md-toolbar .md-toolbar-tools .md-close.md-button span {
    color: #ccc;
    font-size: 20px;
  }
  #existing-address md-dialog md-dialog-content .loading-wrapper {
    margin: 50px auto;
    text-align: center;
  }
  #existing-address md-dialog md-dialog-content .loading-wrapper md-progress-circular {
    margin: auto;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr {
    position: relative;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr.bold-text {
    font-weight: bold;
    background-color: #fff;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr.bold-text td:last-child {
    display: none;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr td {
    padding: 8px;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr td:first-child {
    display: none;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr td:nth-of-type(3) {
    max-width: 85%;
    font-size: 12px;
  }
  #existing-address md-dialog md-dialog-content .address-info-content table tr td:last-child {
    position: absolute;
    top: 0;
    right: 5px;
  }
  #existing-address md-dialog .checkout-actions-tab {
    background-color: #fff;
  }
  #existing-address md-dialog .checkout-actions-tab .selected-ship-address-button button.md-button {
    padding: 0 30px;
  }
  #recommend-address md-dialog-content .loading-wrapper {
    margin: 50px auto;
    text-align: center;
  }
  #recommend-address md-dialog-content .loading-wrapper md-progress-circular {
    margin: auto;
  }
  #recommend-address.md-dialog-container md-dialog {
    width: 100%;
  }
  #recommend-address.md-dialog-container md-dialog md-toolbar .md-toolbar-tools {
    padding: 10px 0 0 0px;
  }
  #recommend-address.md-dialog-container md-dialog md-toolbar .md-toolbar-tools h2 {
    text-align: left;
  }
  #recommend-address.md-dialog-container md-dialog md-toolbar .md-toolbar-tools p {
    text-align: left;
    padding: 5px 20px;
  }
  #recommend-address.md-dialog-container md-dialog .title-text {
    font-size: 16px;
    padding-left: 20px;
  }
  #recommend-address.md-dialog-container md-dialog .suggestion-text {
    font-size: 14px;
    padding-left: 0;
  }
  #recommend-address.md-dialog-container md-dialog .suggestion-text br {
    display: none;
  }
  #recommend-address.md-dialog-container md-dialog .md-toolbar-tools .md-close.md-button {
    background-color: transparent;
  }
  #recommend-address.md-dialog-container md-dialog .md-toolbar-tools .md-close.md-button span {
    color: #ccc;
    font-size: 20px;
  }
  #recommend-address.md-dialog-container md-dialog table.original-table {
    border-bottom: solide 1px #d7d7d7;
  }
  #recommend-address.md-dialog-container md-dialog table.original-table tr {
    border-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 20px;
  }
  #recommend-address.md-dialog-container md-dialog table tbody.highlight-table {
    font-weight: bold;
    background-color: #fff;
  }
  #recommend-address.md-dialog-container md-dialog table tbody tr {
    padding-left: 20px;
  }
  #recommend-address.md-dialog-container md-dialog table tbody tr td {
    font-size: 14px;
  }
  #recommend-address.md-dialog-container md-dialog table tbody tr td.original-title-text {
    font-size: 16px;
    padding: 20px 0 0;
  }
  #quantity-reduce md-dialog {
    max-height: 100%;
  }
  #quantity-reduce md-dialog md-toolbar {
    min-height: 140px;
  }
  #quantity-reduce md-dialog md-toolbar .md-toolbar-tools .tools-header {
    font-size: 20px;
  }
  #quantity-reduce md-dialog md-toolbar .md-toolbar-tools .sub-header {
    font-size: 10px;
    font-weight: 300;
    padding: 0;
  }
  #quantity-reduce md-dialog md-toolbar .md-toolbar-tools .md-close.md-button {
    background-color: transparent;
  }
  #quantity-reduce md-dialog md-toolbar .md-toolbar-tools .md-close.md-button span {
    color: #ccc;
    font-size: 20px;
  }
  #quantity-reduce md-dialog .header-thead {
    padding: 5px 10px;
    font-size: 12px;
  }
}
.shipping-cost-override h5 {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
}
.shipping-cost-override hr {
  margin-bottom: 28px;
}
#shipping-address-selection {
  color: #999999;
  line-height: 2.3;
}
#new-address {
  padding-left: 15px;
}
#selectAddressBtn {
  text-align: center;
}
#btn-add {
  font-size: 16px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4399c5;
  margin: 0;
  border: 1px solid #4399c5;
  border-radius: 4px;
  background-color: inherit;
  width: 100%;
}
.shipping-card {
  width: 66%;
  margin: 35px 0;
  color: #333333;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 25px 16px;
}
.shipping-card .info-detail-wrapper div[layout="row"] {
  line-height: 1.7em;
}
.shipping-card .info-detail-wrapper .data-type {
  width: 20%;
}
.shipping-card .info-detail-wrapper .data-value {
  width: 50%;
  font-weight: 700;
}
@media (max-width: 768px) {
  .shipping-card .info-detail-wrapper .data-type {
    width: 40%;
  }
  .shipping-card .info-detail-wrapper .data-value {
    width: 60%;
  }
}
.ship-wrapper {
  margin-top: 45%;
}
.custom-radio-button {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
}
.custom-radio-button:before,
.custom-radio-button:after {
  content: '';
  display: block;
  border-radius: 50%;
}
.custom-radio-button:before {
  border: 1px solid #333;
  width: 100%;
  height: 100%;
}
.custom-radio-button:after {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 5px;
}
.checked-radio-btn .custom-radio-button:after {
  background: #4399c5;
}
.shipping-warning {
  color: #e00603;
}
.site-alert-messaging {
  position: relative;
}
.site-alert-messaging .site-alert-close-btn {
  position: absolute;
  right: 40px;
  top: 27px;
  background-color: transparent;
  border: 0;
  color: #ffffff;
}
#support-for-your-practice {
  margin-top: 30px;
}
#support-for-your-practice .emersonicon:before {
  width: 68px;
  height: 68px;
}
#support-for-your-practice .emersonicon-class {
  color: #000000;
}
.icon-list {
  margin: 20px 0 30px;
}
.icon-list .list-inline {
  text-align: center;
}
#support-for-your-practice .media-left {
  padding-right: 50px;
  border-right: dotted 1px #cccccc;
}
#support-for-your-practice .media-right {
  padding-left: 50px;
  padding-top: 20px;
}
.wellevate-tab .wellevate-banner {
  max-width: 991px;
  margin: 30px auto 40px;
  position: relative;
}
.wellevate-tab .wellevate-banner .wellevate-banner-bg {
  position: relative;
}
.wellevate-tab .wellevate-banner .wellevate-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}
.wellevate-tab .wellevate-banner .wellevate-content h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 0;
}
.wellevate-tab .wellevate-banner .wellevate-content p {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  max-width: 641px;
  margin: 0 auto !important;
}
.wellevate-tab .wellevate-banner .wellvate-logo {
  text-align: center;
}
.wellevate-tab .wellevate-banner .md-button {
  min-width: 150px;
}
#wellevate .media-body h3 {
  font-size: 18px;
  font-weight: 300;
}
.quality-tab .quality-section {
  max-width: 780px;
  margin: 30px auto 40px;
}
.quality-tab .quality-badge img {
  width: 100%;
  max-width: 344px;
}
.quality-tab .media .img {
  float: left;
  max-width: 242px;
  margin-right: 20px;
}
.quality-tab .media .img img {
  width: 100%;
}
.quality-tab .media .content {
  overflow: hidden;
}
.quality-tab .media .content ul {
  color: #333333;
}
@media (max-width: 599px) {
  .quality-tab .media .content ul {
    font-size: 14px;
  }
}
.quality-tab .button-wrapper {
  margin: 20px auto;
}
.quality-tab .md-button {
  min-width: 164px;
}
.quality-tab h2 {
  font-size: 24px;
  line-height: 1.2;
}
.education-tab .education-section {
  max-width: 850px;
  margin: 30px auto 40px;
}
.education-tab .education-section .layout-row:first-child {
  max-width: 641px;
  margin: 0 auto;
}
.education-tab .education-section .layout-row:first-child p {
  margin-top: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 0.2px;
}
.education-tab .education-section .unit p {
  min-height: 60px;
}
.education-tab h3 {
  font-size: 16px;
  font-weight: bold;
}
.education-tab p {
  font-size: 14px;
  line-height: 1.3;
}
.education-tab .thumbnail img {
  width: 100%;
  max-width: 230px;
}
.education-tab .unit {
  position: relative;
  padding-bottom: 55px;
}
.education-tab .unit .button-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#support-for-your-practice md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 640px;
}
.quality-symbol {
  text-align: right;
  padding-right: 30px;
  margin-top: 95px;
}
.quality-head,
.quality-head h2,
.quality-items {
  text-align: left;
}
.quality-head p {
  font-size: 18px;
  width: 80%;
  font-weight: 300;
}
.quality-head a.md-button {
  width: 170px;
  margin: 6px 16px 6px 0;
}
.quality-item {
  padding: 20px;
}
/*layout-sm*/
@media (max-width: 959px) {
  #support-for-your-practice .media-right {
    float: left;
    padding: 0;
  }
  #support-for-your-practice .wellevate-tab .media-left {
    border-right: 0;
    padding-right: 0;
  }
  #support-for-your-practice .wellevate-tab .media-left img {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .wellevate-tab .wellevate-banner .wellevate-banner-bg img {
    margin-left: -13%;
  }
}
/*layout-xs*/
@media (max-width: 599px) {
  #support-for-your-practice .tabs-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
  }
  #support-for-your-practice md-pagination-wrapper {
    width: 100%;
  }
  #support-for-your-practice .md-tab .ng-scope {
    line-height: normal;
  }
  #support-for-your-practice md-tabs-canvas {
    height: 130px;
  }
  #support-for-your-practice .brand-item-list .product-summary {
    width: 100%;
  }
  #support-for-your-practice .brand-item-list .product-summary .md-button {
    margin: 10px auto;
  }
  .quality-symbol {
    text-align: center;
    padding-right: 0;
    margin-top: 45px;
  }
  .quality-head {
    padding: 8px;
  }
  .quality-head p {
    font-size: 20px;
    width: 100%;
  }
  .quality-head a.md-button {
    width: 170px;
    margin: 6px 8px 6px 0;
  }
  .wellevate-tab .media-body h3 {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .wellevate-tab .wellevate-banner .wellevate-content {
    padding: 20px;
  }
  .quality-tab .quality-section {
    margin: 30px 20px;
  }
  .quality-tab .media .img {
    float: none;
    max-width: 242px;
    margin: 0 auto;
  }
  .quality-tab .media .img img {
    width: 100%;
  }
  .quality-tab .media .content {
    overflow: hidden;
  }
  .quality-tab .button-wrapper {
    margin: 20px auto;
  }
  .quality-tab .md-button {
    min-width: 164px;
  }
  .quality-tab h2 {
    font-size: 20px;
  }
  .education-tab .education-section {
    margin: 30px 20px;
  }
  .education-tab .education-section .layout-row:first-child p {
    font-size: 16px;
  }
  .education-tab .unit {
    position: relative;
    padding-bottom: 20px;
  }
  .education-tab .unit .button-wrapper {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 320px) {
  .wellevate-tab .media-body h3 {
    width: 89%;
    margin-left: auto;
    margin-right: auto;
  }
  div.quality-head.ng-scope.flex-xs-100.flex-70,
  div.quality-symbol.ng-scope.flex-xs-100.flex-30 {
    flex: 1 0 auto;
  }
}
.wellevate-iframe iframe {
  border: 4px solid white;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
@media (max-width: 599px) {
  .wellevate-iframe iframe {
    width: 100%;
  }
}
.tooltip-container {
  background: url('/assets/images/icons/info-icon.png') no-repeat;
  width: 19px;
  height: 19px;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
}
.tooltip-container.without-icon {
  width: 100%;
  height: auto;
  background: none;
  box-sizing: border-box;
}
.tooltip-container.without-icon.tooltip-down:after {
  display: none;
}
.tooltip-container.without-icon.tooltip-down .tooltip.tooltip-down {
  bottom: auto;
  left: 5%;
  width: 84%;
  top: 0;
  margin-top: 35px;
}
.tooltip-container:hover {
  background-position: 0 -19px;
}
.ee-angularjs-tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 7px 10px;
  width: 232px;
  left: -117px;
  bottom: 27px;
  height: auto;
  color: #000;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  z-index: 110;
}
.ee-angularjs-tooltip p {
  margin: 0;
  font-weight: normal;
}
.ee-angularjs-tooltip .title {
  font-weight: 600;
}
.tooltip-show {
  display: inherit;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip-down .tooltip-arrow {
  top: -5px;
  left: 50%;
  margin-left: -5px;
}
.tooltip-down:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 53%;
  width: 8px;
  height: 8px;
  background: #fff;
  border-right: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(-135deg);
  z-index: -1;
}
.tooltip-up .tooltip-arrow {
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}
.tooltip-right .tooltip-arrow {
  left: -5px;
  top: 50%;
  margin-top: -3px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #fff;
}
.tooltip-left .tooltip-arrow {
  right: -5px;
  top: 50%;
  margin-top: -3px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #222;
}
@media (max-width: 360px) {
  .tooltip.ee-angularjs-tooltip {
    width: 160px;
  }
  .tooltip-up .tooltip-arrow {
    margin-left: 23px;
  }
}
@media (max-width: 959px) {
  a.tooltip-container:hover .tooltip {
    display: block;
  }
}
.cartBtnTooltip {
  width: 30%;
  height: 100%;
  display: inline-block;
}
.cartBtnTooltip .tooltip {
  text-align: left;
  left: -61px;
  bottom: 47px;
}
.cartBtnTooltip .tooltip p {
  font-size: 12px;
}
.cartBtnTooltip .tooltip h3 {
  margin-top: 2px;
  font-size: 14px;
  margin-bottom: 2px;
}
#value-prop-bar {
  background-color: #F6f6f6;
  margin: 30px 0;
}
#value-prop-bar .value-prop-with-padding .media-body {
  padding-left: 0px;
}
#value-prop-bar .media-body {
  vertical-align: middle;
  padding-left: 10px;
}
#value-prop-bar .flex {
  -ms-flex: 1 1 auto;
}
#value-prop-bar h3 {
  font-size: 18px;
  font-weight: 500;
  color: #666;
  text-align: left;
  margin: 0;
  max-width: 200px;
  height: auto;
}
#value-prop-bar .layout-row {
  width: 100%;
}
#value-prop-bar .product-summary {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
#value-prop-bar .media-list {
  margin: 20px 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
#value-prop-bar .media-list .product-thumbnail {
  display: block;
}
#value-prop-bar .media {
  margin-top: 0;
  padding-right: 80px;
}
#value-prop-bar .media:first-child {
  margin-top: 0;
}
#value-prop-bar .dots-nav {
  z-index: 15;
  padding-left: 0;
  text-align: center;
  list-style: none;
}
#value-prop-bar .dots-nav .dots {
  padding-left: 0;
}
#value-prop-bar .dots li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: #999;
  border: 1px solid #fff;
  border-radius: 10px;
}
#value-prop-bar .dots li:hover {
  background-color: #000 \9;
  background-color: #444;
  border: 1px solid #fff;
}
#value-prop-bar .dots li.active {
  background-color: #000 \9;
  background-color: #444;
  border: 1px solid #fff;
}
#value-prop-bar .dots li:focus {
  outline: 0;
}
#value-prop-bar [ng\:cloak],
#value-prop-bar [ng-cloak],
#value-prop-bar [data-ng-cloak],
#value-prop-bar [x-ng-cloak],
#value-prop-bar .ng-cloak,
#value-prop-bar .x-ng-cloak,
#value-prop-bar .ng-hide:not(.ng-hide-animate),
#value-prop-bar .ng-hide,
#value-prop-bar [ng-cloak] {
  display: block !important;
}
@media (max-device-width: 1024px) {
  #value-prop-bar .dots li:hover {
    background-color: #000 \9;
    background-color: #999;
    border: 1px solid #fff;
  }
  #value-prop-bar .dots li.active {
    background-color: #000 \9;
    background-color: #444;
    border: 1px solid #fff;
  }
}
@media (max-width: 959px) {
  #value-prop-bar {
    padding-bottom: 10px;
  }
  #value-prop-bar .media {
    text-align: center;
    padding-right: 0;
  }
  #value-prop-bar .ng-scope {
    min-width: unset;
  }
  #value-prop-bar .media-list {
    margin: 10px 0 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #value-prop-bar .product-summary.media-body,
  #value-prop-bar .product-thumbnail.media-left {
    display: block;
    text-align: center;
  }
  #value-prop-bar h3 {
    text-align: center;
    margin: 5px;
    font-size: 21px;
    max-width: none;
    margin: 0 auto;
  }
  #value-prop-bar [ng\:cloak],
  #value-prop-bar [ng-cloak],
  #value-prop-bar [data-ng-cloak],
  #value-prop-bar [x-ng-cloak],
  #value-prop-bar .ng-cloak,
  #value-prop-bar .x-ng-cloak,
  #value-prop-bar .ng-hide:not(.ng-hide-animate),
  #value-prop-bar .ng-hide,
  #value-prop-bar [ng-cloak] {
    display: none !important;
  }
  #value-prop-bar .dots li {
    width: 20px;
    height: 20px;
    margin: 1px 10px;
  }
}
.welcome-splash {
  padding: 5px;
}
.welcome-splash .layout-column {
  display: flex;
  flex-flow: column;
}
.welcome-splash button.close-button {
  background: none;
  border: none;
  font-size: 28px;
  line-height: 19px;
  height: 28px;
  width: 28px;
}
.welcome-splash .welcome-splash-content-wrapper {
  padding: 25px 50px 40px 50px;
}
.welcome-splash .welcome-splash-content-wrapper h1 {
  text-transform: capitalize;
  font-weight: 400;
  margin-top: 0;
}
.welcome-splash .welcome-splash-content-wrapper p {
  text-align: center;
  width: 60%;
  font-size: 18px;
  margin-bottom: 45px;
}
.welcome-splash .welcome-splash-content-wrapper div.video-wrapper {
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .welcome-splash .welcome-splash-content-wrapper {
    padding: 25px;
  }
  .welcome-splash .welcome-splash-content-wrapper p {
    margin-top: 0;
    margin-bottom: 25px;
    width: 90%;
  }
  .welcome-splash .welcome-splash-content-wrapper div.video-wrapper {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 59%;
  }
  .welcome-splash .welcome-splash-content-wrapper div.video-wrapper iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
/* endinject */
