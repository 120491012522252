.adjustment-wrapper {
  .quantity-adjustment-wrapper {
    text-align: center;
    padding: 15px;
    position: relative;

    .quantity-adjustment {
      padding-top: 30px;
      text-align: center;
      display: inline-block;
      box-sizing: border-box;
      margin: 0 auto;
      overflow: hidden;

      &.disabled {
        opacity: .5;
        cursor: not-allowed;
        button {
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      button {
        font-size: 23px;
        font-weight: 300;
        text-align: center;
        color: #79c143;
        background-color: #ffffff;
        border: 1px solid #999999;
        box-sizing: border-box;
        float: left;
        &.rectangle-quantity {
          width: 25px;
          height: 29px;
          box-sizing: border-box;
          @media only screen and (max-width: 1024px) {
            padding: 0;
          }
          &:focus{
            outline: none;
          }
        }
        &.disableClick {
          cursor: not-allowed;
          border: 1px solid #BEBEBE;
          color: #E4E4E4;
          background-color: #d7d7d7;
        }
      }

      .quantity {
        text-align: right;
        padding-right: 5px;
        width: 44px;
        height: 29px;
        border: 1px solid #999999;
        border-left: 0px;
        border-right: 0px;
        font-size: 18px;
      	font-weight: bold;
      	line-height: 1.0;
      	color: #454553;
        box-sizing: border-box;
        display: inline-block;
        float: left;
        &:focus{
          outline: none;
        }
      }
      .disabled-increment {
        color: #333333;
        font-size: 22px;
        background-color: #f2f2f2;
      }
    }

    .unit-price {
    	font-size: 16px;
      padding-top: 17px;
    	line-height: 1.12;
    	color: #333333;
    }
    .update-quantity-button {
      opacity: 0;
      position: absolute;
      left: 77%;
      font-size: 14px;
      line-height: 14px;
      min-height: 29px;
      margin: 0;
      cursor: default;
      span {
        font-size: 14px;
        line-height: 14px;
      }
      &.active {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        -webkit-transition: opacity 0.5s ease-in-out;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }


  .warning-icon {
    position: relative;
    padding-left: 40px;
    font-size: 14px;
    line-height: 1.14;
    color: #333333;
    min-height: 30px;

    // &:before {
    //   position: absolute;
    //   content: '';
    //   top: -5px;
    //   left: 0;
    //   background-image: url('/assets/images/attention.png');
    //   background-repeat: no-repeat;
    //   width: 27px;
    //   height: 25px;
    //
    // }
  }

  .warning-content {
    position: relative;
    padding-left: 40px;
    font-size: 14px;
    line-height: 1.14;
    color: #333333;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background-image: url('/assets/images/flame.png');
      background-repeat: no-repeat;
      width: 27px;
      height: 25px;

    }
  }


  .sort-by {
    font-size: 18px;
  	line-height: 1.56;
  	text-align: left;
  	color: #4399c5;
    margin-bottom: -10px;
    md-select {
      min-width: 170px;
    }
  }

  .delete-item {
    text-align: center;
    width: 20px;
    height: 21px;
    color: #cccccc;
    border: none;
    float: right;
    background-color: #ffffff;
    &:focus{
      outline: none;
    }
  }

  .item-total-wrapper {
    text-align: right;
    color: #454553;
    .total-item {
      font-size: 18px;
    	line-height: 1.0;
      padding-top: 45px;
    }
    .total-after-discount {
      font-weight: bold;
      font-size: 18px;
      padding: 5px 0;
    }
    .discount-item {
      font-size: 12px;
    }
    .product-discount {
      text-decoration: line-through;
      font-weight: lighter;
      font-size: 16px;
    }
    .loading-wrapper {
      padding-top: 45px;
      &.no-padding {
        padding-top: 45px;
      }
    }
  }

  .brand-detail {
    margin: -30px 0 10px 0px;
    .cart-item-list-table {
      color: #333333;
      font-size: 12px;
    }
    thead {
      text-align: center;
      .text-total {
        text-align: right;
      }
    }
    tbody {
      tr {
        border-bottom: solid 1px #999999;
        &.remove-warning-wrapper {
          padding: 50px 0 50px 30px;
          button {
            font-size: 14px;
          	font-weight: 500;
          	line-height: 1.43;
          	letter-spacing: 0.1px;
            &.cancel-remove {
            	background-color: #fff;
            	border: solid 1px #79c143;
              color: #79c143;
            }
          }

        }

        &.product-warning-tr {
          border-bottom: 0 !important;
        }
      }
      .product-warning {
        border-bottom: 0 !important;
        td {
          padding: 0;
        }
        td:nth-of-type(1) {
          border-bottom: 0 !important;
        }
        td:nth-of-type(2) {
          border-top: 1px solid #999999;
        }

        &:last-child {
          border-bottom: solid 1px #999999 !important;
        }
      }
    }
  }

  .category-title {
    font-size: 14px;
    line-height: 1.14;
    text-align: left;
    font-weight: normal;
  }
  .product {
    padding: 10px 0 0 0;
    .order-name {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.12;
      text-align: left;
      color: @link-color-green;
      h3, a {
        color: @link-color-green;
      }

    }
    .productImg {
      text-align: center;
      img {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100px;
      }
    }
  }

  .empty-items-adjusted {
    font-size: 24px;
  }

  .empty-order {
    text-align: center;
    h1 {
      padding: 100px 0 80px 0;
    }
    a {
      padding: 15px 40px;
      margin-bottom: 150px;
    }
  }
}

.remove-warning-wrapper {
  button {
    width: 90%;
    max-width: 90%;
    padding: 0 1em;
  }
}

@media only screen and (max-width: 959px) {
  .item-total-wrapper {
    .loading-wrapper {
      padding-top: 0;
      &.no-padding {
        padding-top: 0;
      }
    }
  }
  .adjustment-wrapper {
    .quantity-adjustment-wrapper {
      .quantity-adjustment {
        padding-top: 0;
        display: block;
        overflow: hidden;
        position: relative;
        min-height: 120px;

        button {
          &.rectangle-quantity {
            float: none;
            display: block;
            width: 28px;
            height: 30px;
          }

          &.decrement-button {
            position: absolute;
            right: 0;
            top: 76px;
          }

          &.increment-button {
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .quantity {
          float: none;
          border-top: 0;
          text-align: center;
          margin: 10px 0;
          display: block;
          position: absolute;
          top: 26px;
          right: 0;
          width: 30px;
          font-size: 14px;
          -webkit-appearance: none;
        }
      }

      .unit-price-text {
        display: none;
      }

      .update-quantity-button {
        position: relative !important;
        left: 0;
      }
    }
    .item-total-wrapper {
      .total-item {
        padding-top: 10px;
        text-align: left;
      }
    }
  }
  .adjustment-wrapper {
    .brand-detail {
      tbody {
        tr.remove-warning-wrapper {
          padding: 30px 0;
        }
      }
    }
  }
}
