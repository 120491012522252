/*Input styling*/
md-input-container.md-default-theme .md-input,
md-input-container .md-input {
  color: rgba(0,0,0,0.87);
  border-color: rgba(0,0,0,0.12);
}
md-input-container label {

}
md-input-container .md-input {
  color: #666666;
}
md-input-container .md-placeholder,
md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  color: #D3D3D3;
}
md-input-container.md-input-focused label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-input-has-value label:not(.md-no-float) {
  color: #999999;
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon, md-checkbox[disabled].md-checked .md-icon {
    background-color: rgba(0,0,0,0.38);
}
md-checkbox .md-container {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto;
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon, md-checkbox[disabled].md-checked .md-icon {
    background-color: rgba(0,0,0,0.38);
}
md-checkbox.md-checked .md-icon:after {
    box-sizing: border-box;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 4.66667px;
    top: .22222px;
    display: table;
    width: 6.66667px;
    height: 13.33333px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: '';
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon:after, md-checkbox[disabled].md-checked .md-icon:after {
    border-color: rgb(238,238,238);
}
md-checkbox.md-default-theme[disabled] .md-icon:after, md-checkbox[disabled] .md-icon:after {
    border-color: rgba(0,0,0,0.38);
}
md-checkbox.md-default-theme[disabled].md-checked .md-icon, md-checkbox[disabled].md-checked .md-icon {
    background-color: rgba(0,0,0,0.38);
}
md-input-container {
  .input-message {
    font-size: 14px;
    color: #333333;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/*checkbox*/
md-checkbox .md-icon {
  border-radius: 0;
  width: 20px;
  height: 20px;
  border: 1px solid @secondary-color;
}
md-checkbox.md-checked .md-icon:after {
  left: 5.66667px;
  top: 1.22222px;
  color: #4399c5;
}
md-checkbox.md-checked .md-icon {
  border: 1px solid @secondary-color;
}
md-checkbox.md-square {
  .md-icon {
    border-radius: 0;
    border: 1px solid @secondary-color;
    width: 20px;
    height: 20px;

    &:after {
      left: 5.66667px;
      top: 1.22222px;
    }
  }
  .md-label {
    span {
      color: @secondary-color;
      font-weight: 300;
    }
  }
}
md-checkbox.md-circle {
  .md-container {
    width: 27px;
    height: 27px;
  }
  .md-icon {
    border-radius: 50%;
    border: 1px solid @secondary-color;
    width: 27px;
    height: 27px;

    &:after {
      left: 8.66667px;
      top: 4.22222px;
    }
  }
  .md-label {
    span {
      color: @secondary-color;
      font-weight: 300;
    }
  }
}
md-checkbox.medium {
  .md-container {
    width: 27px;
    height: 27px;
  }
  .md-icon {
    width: 27px;
    height: 27px;

    &:after {
      left: 8.66667px;
      top: 4.22222px;
    }
  }
  .md-label {
    margin-left: 40px;
  }
}

/*select*/
.md-select-menu-container.md-active {
  z-index: 300;
}

md-select.md-no-underline {
  .md-select-value {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 5px 10px;
    border-radius: 5px;
  }
}
md-select-menu.md-default-theme md-content md-option,
md-select-menu md-content md-option {
    color: rgba(33,33,33,0.87);
}
md-select-menu.md-default-theme md-content md-option[selected], md-select-menu md-content md-option[selected] {
        color: #666666;
}
md-option {
  color: #999999;
}
md-option:focus,
md-option:hover {
    background-color: #f5f5f5;
}

/*auto complete*/
.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 300;

  ul {
    li {
      &:focus,
      &:hover {
          background-color: #f5f5f5;
      }
      &.selected {
        color: #0e98df;
        background-color: #f5f5f5;
      }
    }
  }
}

md-input-container textarea.md-input {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
}

@media(max-width: 599px){
.md-select-menu-container.md-active {
  margin-top: 140px;
}
}
