.rxa-date-picker{
	input[type=date]{
		&::-webkit-calendar-picker-indicator{
			font-size: 10px;
			opacity:1;
			background-color:#ffffff;
			color: #0e98df;
			cursor: pointer;
		}
	}
}

._720kb-datepicker-calendar{
	top:33px;
}
._720kb-datepicker-calendar-day._720kb-datepicker-active{
	background: #ddd;
}
.rx-error{
	margin-top:10px;
	div{
		color:#f00;
		font-size:14px;
	}
}