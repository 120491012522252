.info-card {
  margin: 35px 0;
  border-top: 1px solid rgba(0,0,0,.12);
  border-bottom: 1px solid rgba(0,0,0,.12);
  padding: 25px 16px;

  .info-detail-wrapper {
    color: rgba(0,0,0,.77);

    div[layout="row"] {
      line-height: 1.7em;
      text-transform: capitalize;
    }

    .data-type {
      width: 115px;
    }

    .data-value {
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    font-size: 14px;
    width: unset;

    .info-detail-wrapper {
      .data-type {
        width: 80px;
      }
    }
  }
}
