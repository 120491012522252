#support-container {
	margin-top: 50px;
	margin-bottom:30px;
	h2 {
		font-size: 35px;
		font-weight: 400;
		text-align: left;
		margin-bottom: 0;	
		margin-top: 0;
	}

	h3 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;

    span { 
    	display: block;
    	 @media screen and (max-width: 959px) {
	      display: inline;
    	}	
    }
	}

	h4 {
		color: #333;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.4;
    height: 60px;
    @media screen and (max-width: 959px) {
        height: auto;
  	}		
	}

	p {
		color: #333;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.4;
	}

	section {
		padding: 5px;
	}

	section.support-1 {
		.support-1-content {
			padding: 10px;
			position: relative;
		}

		a.play {
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    background-image: url('../../assets/images/play-video.png');
      width: 59px;
    	height: 59px;
    	text-indent: -9999px;
    	margin: -30px 0 0 -30px;
		}
	}

	section.support-2 {
		text-align: center;
		margin-bottom:40px;
		a {
	    width: 160px;
		}
	}
}

#support-video {
	width:100%;
	height: 100%;
}