.practice-management-subpage-content {
	p {
		width: 75%;
	    color: #666;
	    text-align: justify;
	    margin: 0 auto 0;
	}
	.education-table-container ul {
		li{
			p{
				width:100%;
			}
			.files-list .file-name{
				@media screen and (max-width: 599px) {
					font-size:16px;
				}
			}
		}
	}
	.lt-container.education-table-container .table-right ul li{
		border-bottom: 1px solid #ddd;
	}
}   
.practice-management-subpage ul {
	.wrap-flex(wrap);
	li{
		padding:0 20px 20px 20px;
		height: auto;
		min-height: 53px;
		&:nth-child(odd){
			border-right: 1px solid #ddd;
		}
		> div{
			padding-top: 20px;
			border-top: 1px solid #ddd;	
		}
		&:nth-child(-n+2){
			> div{
				border-top: 0;
			}
		}
	}
}
.view-video,
.download-file{
	a:after{
		content: '';
		background-repeat: no-repeat;
		display: block;
	    position: absolute;
	    right: 0;
	    top: 0;
	}
}
.download-file{
	a:after{
		width: 25px;
		height: 26px;
		background-image: url(/assets/images/icons/download.png);
	}
}
.view-video{
	a:after{
		width: 25px;
		height: 20px;
		background-image: url(/assets/images/icons/play_video.png);
	}
}
.elementstd.file-name {
    position: relative;
    padding-right: 40px;
}
@media screen and (max-width: 599px) {
	.education-table-container ul{
		display: block;
		li{
			width: 100%;
			max-width: none;
			&:nth-child(odd){
				border-right: 0;
			}
			&:nth-child(2){
				> div{
					border-top: 1px solid #ddd;
				}
			}
		}
	}
}