.resubmit-failed-order {
  .loading {
    margin-top: 30px;
  }

  .page-msg {
    text-align: center;
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 18px;
    @media (min-width: 959px) {
      padding-left: 50px;
      padding-right: 400px;
    }
  }

  .payment-credit-card {
    min-height: unset !important;
  }

  .order-summary-wrapper {
    min-height: unset !important;
    margin-top: 45px;

    .order-summary-title h3 {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 28px;
      line-height: 1.2;

    }

    .order-summary-table {
      margin-top: -21px;
    }
  }

  @media(max-width: 959px) {
    padding: 25px;

    div.cart-summary-wrapper {
      margin-left: -25px;
      margin-right: -25px;
    }

    div.checkout-actions-tab ul {
      width: unset;
      border: none;

      li {
        width: unset;
        float: unset;
      }
    }
  }
}
