
page-level-alert-messaging {
  position: relative;
  /*top: 121px;*/
  height: 100px;
  width: 100%;
  z-index: 100;
}

#bannerMessage {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  color: #fff;
  font-size: 18px;
  text-align: center;
  //height: 71px !important;
  line-height: 33px;

  &.posRelative {
    position: relative;
  }

  p {
    color: #ffffff;
  }

  md-dialog {
    opacity: 1;
    background: #76BD23;// #333;
    max-height: initial;
    max-width: initial;
    box-shadow: none;
    width: 100%;
    min-width: initial;
    overflow: hidden;

    md-dialog-content {
      padding: 0;

      .ie-browser-message {
        margin-top: 14px;
      }
    }

    a {
      color: #333;
    }

    p {
      margin: 0;

      .error-icon {
        vertical-align: middle;
        margin-right: 12px;
      }
    }

    md-toolbar {
      position: absolute;
      right: 135px;
      top: 14px;
      width: 30px;
      z-index: 2;
      min-height: unset;

      .md-toolbar-tools {
        min-height: unset;
        height: unset;

        .md-button {
          background: none;
          border-radius: 0;
          color: #ffffff;
          font-size: 22px;
        }
      }
    }
  }

  .close {
    color: #eef2f5;
    font-size: 19px;
    position: absolute;
    top: 19px;
    right: 134px;
  }
}

.navbar-wrapper {
  background: rgba(255,255,255, .82);
  border-top: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  position: relative;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: 20;
}
.multi-cart-btn {
  min-height: 0;
  line-height: 24px;
  padding: 0 10px;
}

.navbar-wrapper.sticky-box {
  width: 100%;
}

.ee-navbar {
  position: relative;
}

.ee-navbar > li > a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  padding: 22px 30px 27px 0;
}

.navbar-default {
  border: 0;
  background-color: transparent;
  margin-bottom: 0;
}

.navbar-header {
  height: 96px;
  float: left;
}

.navbar-nav.ee-navbar {
  margin-top: 12px;
  float: left;
}

.navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
}

.navbar-collapse {
  width: auto;
  border-top: 0;
  box-shadow: none;
}

.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  content: " ";
  display: table;
}

.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}
/*search*/
.ee-search .icon {
  margin: 0;
}

i.s24,
md-icon[md-font-icon].s24 {
  font-size: 24px!important;
  width: 24px!important;
  height: 24px!important;
  min-width: 24px!important;
  min-height: 24px!important;
  line-height: 24px!important;
}

.icon-magnify:before {
  content: " ";
  display: block;
  background-image: url('/assets/images/search-icon.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 50px;
}

.ee-search {
  height: 50px;
  line-height: 56px;
  background: #FFF;
  border: 1px solid #ccc;
  border-top: 0;

  input {
    color: rgba(0,0,0,.54);
    padding: 6px 12px;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    height: 49px;
    line-height: 22px;
    margin-top: 0;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    outline: 0;
  }

  .md-search-icon {
    border-left: 1px solid #ccc;
    border-radius: 0;
    padding: 0 10px 0 15px;
    width: 50px;
    height: 20px;
    line-height: 20px;
  }
}

.ee-mobile-nav .toolbar-sub-menu.search {
  input {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    background-image: none;
    // border-radius: 5px;
    -webkit-appearance: none;
    border: 1px solid #ccc;
    font-size: 14px;
  }
}
/*Site Logo*/
.navbar-logo {
  height: auto;
  padding-top: 40px;
  margin-right: 55px;
  display: block;
}

.ee-mobile-nav {
  display: none;
}

.arrow-icon {
  display: inline-block;
  width: 10px;
  height: 6px;
  background-image: url('/assets/images/arrow.png');
  background-repeat: no-repeat;
  &.green {
    background-image: url('/assets/images/icons/arrow-icon-green.png');
    width: 12px;
    height: 6px;
  }
}

.reorder-flyout-image {
  width: 100%;
}
.reorder-flyout-text {
  margin-top: 40px;
  >p {
    font-size: 14px;
  }
}


/* Emerson Main Menu and Dropdown menu*/
.navbar-nav > li {
  float: left;
}

.navbar-nav > li a {
  text-transform: uppercase;
}

.shop-condition-links > a {
  text-transform: none !important;
}

.navbar-nav > li a center {
  text-transform: none;
  color: black;
  &:hover {
    text-decoration: none;
  }
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.ee-navbar-main-menu {
  float: left;
  margin-top: 12px;
  margin-left: -75px; // to offset the shop dropdown menu item's left padding
}

.ee-navbar-main-menu li.ee-main-menu-dropdown {
  a.ee-main-menu-dropdown-toggle {
    font-family: @font-family;
    font-weight: @font-w-normal;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    padding: 21px 30px 28px 0;
    display: block;
    color: #777;
    text-decoration: none;
    position: relative;
    z-index: 100;
    cursor: default;

    > span .arrow-icon {
      display: block;
      float: left;
      margin: 0 0 0 10px;
      padding: 0 0 0 15px;
      border-left: solid 1px #b7b7b7;
      background-position: center center;
      height: 24px;
    }

    .text {
      float: left;
    }

    > span {
      cursor: pointer;

      &:hover {
        color: #000000;
        background-color: transparent;
      }
    }
  }

  .select-menu {
    color: #000000 !important;
    background-color: transparent !important;
    font-weight: bold !important;
  }

  #shopMenuDropdownToggle {
    padding-left: 75px; // this gives user ability to diagonally approach menu items
  }

  &.active a.ee-main-menu-dropdown-toggle > span { //&:hover a.ee-main-menu-dropdown-toggle
    color: #000000;
    background-color: transparent;
  }

  .active-arrow {
    background-image: url('/assets/images/menu-triangle.png');
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 37px;
    height: 13px;
    position: absolute;
    bottom: -2px;
    z-index: 1001;
    margin-left: 10px;
  }

  #shopMenuActiveArrow {
      margin-left: 75px; // to offset the left shop dropdown menu item's padding
  }

  &:last-child a.ee-main-menu-dropdown-toggle {
    padding-right: 15px;
  }
}

.ee-navbar-main-menu li.ee-main-menu-dropdown.active > a {
  a.ee-main-menu-dropdown {
    font-family: @font-family;
    font-weight: @font-w-normal;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    padding: 21px 30px 21px 0;
    display: block;
    color: #777;
    text-decoration: none;
  }

  &.active a.ee-main-menu-dropdown,
  &:hover a.ee-main-menu-dropdown {
    color: #000000;
    background-color: transparent;
  }
}

.ee-main-menu-dropdown {
  .ee-navbar-sub-menu {
    .sub-menu-padding {
      padding: 0 0 0 25px;
    }
  }
}

.ee-main-menu-dropdown:nth-child(2) {
  .ee-navbar-sub-menu {
    height: 340px;
    box-sizing: border-box;

    p {
      font-size: 14px;
      line-height: 1.3;
    }

    .brand-item .brand-logo {
      max-width: 213px;
      max-height: 73px;
      height: 73px;
      margin: 0 auto 25px;
      padding-bottom: 10px;
      text-align: center;

      img {
        max-width: 213px;
        max-height: 73px;
      }
    }

    .brand-item .brand-summary {
      .btn-align-center {
        margin: 0 auto;
      }

      .md-button {
        width: 55%;
        display: table;
        height: 38px;

        span {
          display: table-cell;
          vertical-align: middle;
        }
      }

      p {
        margin: 20px 0;
      }

      .product-summary.media-body {
        a,
        h3 {
          padding: 0;
          font-size: 14px;
          line-height: 1.2;

          span {
            padding: 0 5px;
          }
        }
      }
    }

    .brand-item-list {
      margin-top: 30px;

      .md-padding {
        border-right: solid 1px #d7d7d7;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .brand-product.media {
      text-align: center;
      padding: 0;
      display: inline-block;
      margin: 0 !important;
    }

    .brand-item-list .product-summary a {
      margin: 0;
      padding: 5px;
    }

    .brand-item-list .product-summary.media-body {
      padding-left: 0;
    }

    .browse-menu {
      text-align: center;
      margin: 30px 0 0;
      padding: 0;

      li {
        display: inline-block;
        font-size: 16px;
        padding: 0 15px 0 0 !important;
        margin: 0 0 0 15px !important;

        &.browse {
          padding-right: 0 !important;
          margin-right: 0 !important;
        }

        &.line-divider {
          border-right: solid 1px #d7d7d7 !important;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.ee-main-menu-dropdown:nth-child(3) {
  .ee-navbar-sub-menu {
    height: 340px;
    box-sizing: border-box;

    .list-unstyled {
      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .mt-divider {
      margin-top: 35px;
    }

    h2 {
      margin-left: 25px;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  .ee-navbar-sub-menu {
    > li {
      > div {
        > div {
          border-right: solid 1px #d7d7d7;
          padding-right: 20px;

          &:last-child {
            border-right: 0;
            margin-right: 0;
            padding-right: 0;

            > div {
              .md-padding {
                padding: 0 25px;

                .ee-media {
                  width: 230px;

                  .image {
                    border-right: solid 1px #d7d7d7;
                    padding-right: 25px;
                    margin-right: -25px;
                  }

                  .body {
                    border-right: solid 1px #d7d7d7;
                    padding-right: 25px;
                    margin-right: -25px;
                    overflow: hidden;
                    height: 140px;

                    .header {
                      color: #79c143;
                    }
                  }
                }

                &:last-child {
                  .ee-media {
                    .image {
                      border-right: 0;
                    }

                    .body {
                      border-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 0;
    }

    .ee-media .body h3 {
      font-weight: 400;
    }
  }
}

.ee-main-menu-dropdown:nth-child(4) {
  .ee-navbar-sub-menu {
    height: 340px;
    box-sizing: border-box;

    .list-unstyled {
      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        a.md-button {
          margin: 0;
        }
      }
    }

    .mt-divider {
      margin-top: 35px;
    }

    h2 {
      margin-left: 25px;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  .ee-navbar-sub-menu {
    > li {
      > div {
        > div {
          border-right: solid 1px #d7d7d7;
          padding-right: 20px;

          &:last-child {
            border-right: 0;
            margin-right: 0;
            padding-right: 0;

            > div {
              .md-padding {
                padding: 0 25px;

                .ee-media {
                  width: 230px;

                  .image {
                    border-right: solid 1px #d7d7d7;
                    padding-right: 25px;
                    margin-right: -25px;
                  }

                  .body {
                    border-right: solid 1px #d7d7d7;
                    padding-right: 25px;
                    margin-right: -25px;
                    overflow: hidden;
                    height: 140px;

                    .header {
                      color: #79c143;
                    }
                  }
                }

                &:last-child {
                  .ee-media {
                    .image {
                      border-right: 0;
                    }

                    .body {
                      border-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    .ee-media .body h3 {
      font-weight: 400;
    }
  }
}

.ee-main-menu-dropdown:nth-child(5) {
  .ee-navbar-sub-menu {
    height: 340px;
    box-sizing: border-box;

    .list-unstyled {
      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      padding: 14px 2px 2px 2px;
    }
  }

  .ee-navbar-sub-menu {
    > li {
      > div {
        > div {
          border-right: solid 1px #d7d7d7;
          padding-right: 20px;
          margin-right: 20px;

          &:last-child {
            border-right: 0;
            margin-right: 0;
            padding-right: 0;

            > div {
              .md-padding {
                padding: 0 25px;
                border-right: solid 1px #d7d7d7;
                margin-top: 20px;
                text-align: center;
              }

              &:last-child {
                border-right: 0;
              }

              .md-padding.flex-30 {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }

    .media-body h3 {
      height: 100%;
      font-weight: 700;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      text-align: left;
    }
  }
}

.ee-navbar-main-menu li.ee-main-menu-dropdown {
  position: static;

  .ee-navbar-sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 1140px;
    padding: 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    //border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    &.add-min-height {
      min-height: 415px;
    }

    &.ee-shop-by-wrapper {}

    .ee-sub-menu-dropdown {
      width: 23%;
      position: relative;
      border-bottom: rgba(0,0,0,0.06) 1px solid;
      border-right: rgba(0,0,0,0.06) 1px solid;

      &.sub-menu-hide {
        min-height: 60px;
        border-right: rgba(0,0,0,0.06) 1px solid;
        box-sizing: border-box;
      }

      &:hover {
        color: #000000;
        text-decoration: none;

        a.ee-sub-menu-dropdown-toggle {
          color: #000000;
          background-color: rgba(229, 226, 226, 1);
        }
      }

      &.active {
        color: #000000;
        text-decoration: none;

        a.ee-sub-menu-dropdown-toggle {
          color: #000000;
          background-color: rgba(229, 226, 226, 1);
        }
      }

      &.inactive {
        text-decoration: none;
        color: @link-color-green;

        a.ee-sub-menu-dropdown-toggle {
          color: @link-color-green;
          background-color: rgba(229, 226, 226, 0.25);
        }
      }

      a.ee-sub-menu-dropdown-toggle {
        display: block;
        padding: 20px 10px 20px 27px;
        background-color: rgba(229, 226, 226, 0.25);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        color: @link-color-green;
        border-right: rgba(0,0,0,0.06) 1px solid;

        &:hover {
          color: #000000;
          text-decoration: none;
          background-color: rgba(229, 226, 226, 1);
        }

        .arrow-icon {
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          float: right;
          margin: 6px 0 0;
        }
      }
      /*sub menu dropdown inside each Shop by*/
      &.active .ee-sub-menu-items,
      &:hover .ee-sub-menu-items {
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
        padding: 25px 30px;
        margin: 0;
        width: 334%;
        max-width: 874px;
        min-height: 355px;
        max-height: 355px;
        border: 0 !important;
        border-radius: 0 !important;
        box-shadow: none;
        box-sizing: border-box;

        ul {
          li {
            border: 0;
            margin: 15px 20px 0 0;

            ul {
              list-style-type: none;
              padding-left: 15px;

              li {
                margin: 5px 0;
              }
            }
          }
        }

        .condition-columns {
          ul {
            margin: 0;
            -moz-column-count: 3;
            -moz-column-gap: 50px;
            -webkit-column-count: 3;
            -webkit-column-gap: 50px;
            column-count: 3;
            column-gap: 50px;
            -webkit-column-rule: 1px outset #d7d7d7;
            /* Chrome, Safari, Opera */
            -moz-column-rule: 1px outset #d7d7d7;
            /* Firefox */
            column-rule: 1px outset #d7d7d7;

            > li {
              margin: 0;
              padding: 0;

              a {
                display: block;
                margin-bottom: 15px;
              }
            }
          }
        }

        ul.categories {
          font-size: 15px;

          a {
            text-transform: capitalize;
          }

          .title {
            font-weight: bold;
          }

          .subcategories {
            margin-top: 10px;
          }
        }

        .addl-categories {
          font-size: 15px;

          h3 {
            text-align: center;
          }

          .condition-columns {

            ul {
              border-left: solid 1px #d7d7d7;
              padding-bottom: 5px;
              -webkit-column-count: 2;
              /* Chrome, Safari, Opera */
              -moz-column-count: 2;
              /* Firefox */
              column-count: 2;
              -webkit-column-gap: 132px;
              /* Chrome, Safari, Opera */
              -moz-column-gap: 132px;
              /* Firefox */
              column-gap: 132px;
              -webkit-column-rule: 0;
              /* Chrome, Safari, Opera */
              -moz-column-rule: 0;
              /* Firefox */
              column-rule: 0;
            }
          }

          ul {
            padding-left: 27px;

            li:first-child {
              margin-top: 0;
            }

            a {
              text-transform: capitalize;
            }
          }
        }

        .list-flex {
          li {
            margin-top: 0;
            margin-left: 0;
            padding-top: 0;
            padding-left: 0;

            .list-flex-content {
              padding-left: 0;
            }
          }
        }

        .featured-block.border-right{
          //justify-content: flex-start;
          li.list-flex-item {
            border-right: 1px solid #cccccc;

            &:last-child {
              border-right: 0;
            }
          }

        }

        .view-all-btn {
          margin-top: 25px;
        }
      }
    }

    .ee-sub-menu-dropdown:nth-child(1) {
      .ee-sub-menu-items {
        padding: 45px 30px;
      }
    }
  }

  .quality {
    text-align: center;
    img {
      margin-right: 10px;
      width: 93px;
      height: auto;
    }

    a {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      display: block;
    }
  }

  /*ee-main-menu-dropdown mouseover*/
  //&:hover { // this ruleset causes weird things to happen when it fights with angular's mouseenter/mouseleave events
  //  .ee-navbar-sub-menu {
  //    display: block;
  //    left: 0;
  //    right: 0;
  //    opacity: 1;
  //    margin: 0;
  //    z-index: 100;
  //    padding: 0;
  //    min-height: 415px;
  //  }
  //}

  .ee-sub-menu-items {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    //border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    .brand-item .brand-logo {
      max-width: 213px;
      max-height: 73px;
      height: 73px;
      margin: 0 auto 25px;
      padding-bottom: 10px;
      text-align: center;

      img {
        max-width: 213px;
        max-height: 73px;
      }
    }

    .brand-item .brand-summary {
      .btn-align-center {
        margin: 0 auto;
      }

      .md-button {
        width: 55%;
        display: table;
        height: 38px;

        span {
          display: table-cell;
          vertical-align: middle;
        }
      }

      p {
        margin: 20px 0;
      }

      .product-summary.media-body {
        a,
        h3 {
          padding: 0;
          font-size: 14px;
          line-height: 1.2;

          span {
            padding: 0 5px;
          }
        }
      }
    }

    .brand-product.media {
      text-align: center;
      padding: 0;
      display: inline-block;
      margin: 0 !important;
    }

    .brand-item-list .product-summary a {
      margin: 0;
      padding: 5px;
    }

    .brand-item-list .product-summary.media-body {
      padding-left: 0;
    }

    .header-title {
      width: 100%;
      text-align: center;
      margin-top: 0;
    }

    .browse-menu {
      text-align: center;
      margin: 30px 0 0;
      padding: 0;

      li {
        display: inline-block;
        font-size: 16px;
        padding: 0 15px 0 0 !important;
        margin: 0 0 0 15px !important;

        &.browse {
          padding-right: 0 !important;
          margin-right: 0 !important;
        }

        &.line-divider {
          border-right: solid 1px #d7d7d7 !important;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }

    .md-padding-top-n {
      padding-top: 0 !important;
    }

    .brand-item-list {
      margin-top: 45px;
    }
  }

  .ee-sub-menu-dropdown:nth-child(1) {
    .list-unstyled {
      margin: 0 20px 0 0;

    }

    .ee-sub-menu-items {
      padding: 45px 30px;
    }

    .ee-sub-menu-items.right-divider div {
      border-right: 1px solid #f6f4f4;
      margin-right: 20px;

      &:last-child {
        border-right: 0;
      }
    }
  }

  .ee-sub-menu-dropdown:nth-child(1):hover {
    .ee-sub-menu-items {
      padding: 45px 30px;
    }

    h3 {
      font-size: 16px;
    }

    .list-inline li {
      padding-left: 0;
      padding-right: 0;
    }

    .brand-item li {
      margin: 10px 0 !important;
      padding: 0;
    }
  }

  .ee-sub-menu-dropdown:nth-child(2):hover {
    .ee-sub-menu-items {
      top: -100%;
    }

    h3 {
      font-size: 16px;
    }

    .list-inline li {
      padding-left: 30px;
      padding-right: 30px;
    }

    .brand-item .brand-product li {
      margin: 10px 0;
      padding: 0;
    }
  }

  .ee-sub-menu-dropdown:nth-child(3):hover {
    .ee-sub-menu-items {
      top: -200%;
    }
  }

  .ee-sub-menu-dropdown:nth-child(4):hover {
    .ee-sub-menu-items {
      top: -300%;
    }
  }

  .ee-sub-menu-dropdown:nth-child(5):hover {
    .ee-sub-menu-items {
      top: -400%;
    }
  }

  .ee-sub-menu-dropdown:nth-child(6) {
    a.ee-sub-menu-dropdown-toggle {
      background: #79c143;
      color: #ffffff;
    }
  }

  .ee-sub-menu-dropdown:nth-child(6):hover {
    a.ee-sub-menu-dropdown-toggle {
      background: #79c143;
      color: #ffffff;
    }

    .ee-sub-menu-items {
      top: -500%;
      min-height: 355px;
      left: 101%;
    }
  }
}

.ee-navbar > li.ee-main-dropdown .ee-dropdown-menu .ee-sub-dropdown:hover .ee-sub-dropdown-menu .brand-item-list > li {
  margin: 0;
}

.ee-navbar-sub-menu {
  h2 {
    text-align: left;
    font-size: 20px;

    &:first-child {
      margin-top: 0;
      margin-left: 0;
    }
  }

  h3 {
    text-align: left;
    font-size: 18px;
  }
}

.right-border {
  .item {
    border-right: 1px solid #f6f4f4;
    padding-right: 8px;
    margin-right: 0;

    &:last-child {
      border-right: 0;
    }
  }
}
/*Toolbar*/
.ee-navbar-toolbar {
  float: right;

  li {
    a {
      display: block;
      padding: 0 10px;
      text-align: center;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  md-menu-bar {
    padding: 15px 0;
  }
}

form[name="loginForm"] {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.dropdown-toggle-wrapper {
  ul {
    margin: 5px 20px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

md-menu-bar button {
  outline: 0;
}

.ee-navbar-toolbar md-menu-bar button {
  height: 53px;
}

.ee-navbar-toolbar {
  .md-icon-button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    margin: 0;
    line-height: 1.2;
    border-radius: 0;
  }
}

.ee-navbar-toolbar {
  > .list-unstyled {
    > li {
      display: inline-block;
    }
  }

  .md-icon-button.md-button {
    background-color: transparent;
  }

  .toolbar-sub-menu {
    position: absolute;
    z-index: 100;
    display: block;
    background-color: #fff;
    margin: 17px 0 0;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: auto;
    min-width: 150px;

    &.support {
      text-align: left;
      right: 0;
    }

    &.login-container {
      right: 0;

      form[name="loginForm"] {
        padding: 0;
      }

      .log-in-btn-dd.md-button {
        padding: 10px;
      }
    }

    &.my-account {
      right: 0;
    }

    &.cart {
      min-width: 300px;
      width: 644px;
      right: 0;
      padding: 0;
      color: #333333;

      .cart-item {
        font-weight: 500;
        font-size: 28px;
        line-height: 0.6;
      }

      .sub-total {
        font-weight: 500;
        font-size: 28px;
        line-height: 0.6;

        .smaller {
          font-size: 16px;
        }
      }

      .divider {
        padding: 20px 10px;
        border-right: solid 1px #ebebeb;
        font-size: 16px;
        line-height: 1.5;

        &:last-child {
          border-right: 0;
        }
      }

      .free-shipping-wrapper {
        padding: 0;

        p {
          margin: 0;
          font-size: 12px;
          line-height: 1.3;

          strong {
            display: inline-block;
          }
        }

        .free-shipping-progress-bar {
          margin: 12px 0 5px;
          padding-right: 40px;
        }

        md-progress-linear.free-shipping {
          position: relative;

          &:after {
            content: '';
            display: block;
            width: 38px;
            height: 21px;
            background-image: url("/assets/images/delivery-truck-sprite.png");
            background-position: 0 0;
            background-repeat: no-repeat;
            position: absolute;
            right: -40px;
            top: -12px;
          }

          &.active:after {
            background-image: url("/assets/images/delivery-truck-sprite.png");
            background-position: 0 -21px;
          }
        }
      }

      .menu-items {
        a {
          text-align: left;
        }
      }
    }

    .menu-items {
      text-align: left;

      li {
        margin-bottom: 10px;

        a {
          padding: 0;
          text-align: center;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .md-button {
    overflow: visible;
  }

  .md-button.md-open:after,
  .md-button.md-open:before {
    border-color: hsla(0,0%,84%,0);
    border-bottom-color: #d7d7d7;
    border-width: 11px;
    margin-left: -11px;
    bottom: -35%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 101;
  }

  .md-button.md-open:after {
    border-color: hsla(0,0%,100%,0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }

  .md-button.md-open:before {
    border-color: hsla(0,0%,84%,0);
    border-bottom-color: #d7d7d7;
    border-width: 11px;
    margin-left: -11px;
  }

  .emersonicon.emersonicon-cart {
    .cart-item {
      position: absolute;
      top: 39%;
      font-size: 10px;
      text-align: center;
      width: 20px;
      left: 9px;
    }
  }
}
/*Toolbar Icon*/
.emersonicon {
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;

  &:before {
    content: '';
    display: block;
    width: 34px;
    height: 34px;
  }
}

.emersonicon-class {
  display: block;
  font-family: @font-family;
  font-weight: @font-w-normal;
  color: #999999;
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  text-transform: capitalize;
}

.md-icon-button.md-button,
.md-button.md-primary.md-icon-button {
  background-color: transparent;
}
.md-icon-button.md-button.md-open .emersonicon-class,
.md-icon-button.md-button:hover .emersonicon-class {
  color: #000000;
  text-decoration: none;
}

.emersonicon.emersonicon-wellevate:before {
  background-image: url('/assets/images/wellevate-logo-digital.svg');
  background-repeat: no-repeat;
  width:108px;
  height: 31px;
}

.md-icon-button.md-button.md-open,
.md-icon-button.md-button:hover {
  .emersonicon.emersonicon-wellevate:before {
    background-image: url('/assets/images/wellevate-logo-digital-blk.svg');
    background-repeat: no-repeat;
    width:108px;
    height: 31px;
  }
}

.emersonicon.emersonicon-support:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -2px;
}

.md-icon-button.md-button.md-open,
.md-icon-button.md-button:hover {
  .emersonicon.emersonicon-support:before {
    background-image: url('/assets/images/toolbar-icon-sprite.png');
    background-position: -7px -193px;
  }
}

.emersonicon.emersonicon-login:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -54px;
}

.md-icon-button.md-button.md-open,
.md-icon-button.md-button:hover {
  .emersonicon.emersonicon-login:before {
    background-image: url('/assets/images/toolbar-icon-sprite.png');
    background-position: -8px -241px;
  }
}

.emersonicon.emersonicon-cart:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -7px -99px;
}
.md-icon-button.md-button {
  .emersonicon.emersonicon-cart {
    span {
      &.cart-item {
        color: #ffffff;
      }
    }
  }
}
.md-icon-button.md-button.md-open,
.md-icon-button.md-button:hover {
  .emersonicon.emersonicon-cart:before {
    background-image: url('/assets/images/toolbar-icon-sprite.png');
    background-position: -8px -294px;
  }
}

.md-icon-button.md-button.md-active {
  .emersonicon.emersonicon-cart:before {
    background-image: url('/assets/images/toolbar-icon-sprite.png');
    background-position: -8px -294px;


  }
  .emersonicon.emersonicon-cart {
    span {
      &.cart-item {
        color: #ffffff;
      }
    }
  }
}

.emersonicon.emersonicon-dispensary:before {
  background-image: url('/assets/images/support-for-your-practice/wellevate/dispensary-icon.png');
}

.emersonicon.emersonicon-relationship:before {
  background-image: url('/assets/images/support-for-your-practice/wellevate/relationship-icon.png');
}

.emersonicon.emersonicon-safe:before {
  background-image: url('/assets/images/support-for-your-practice/wellevate/safe-icon.png');
}

.emersonicon.emersonicon-search:before {
  background-image: url('/assets/images/search-icon.png');
  background-position: 0 0;
  background-size: 100%;
  width: 29px;
  height: 30px;
  margin-top: 2px;
}

.md-icon-button.md-button.md-open,
.md-icon-button.md-button:hover {
  .emersonicon.emersonicon-search:before {
    background-image: url('/assets/images/search-icon-hover.png');
    background-position: 0 0;
  }
}

.navbar-default .navbar-nav.navbar-right .emersonicon:before {
  width: 34px;
  height: 34px;
}

.ee-navbar > .ee-container {
  position: relative;
}

.md-open-menu-container.md-active {
  background-color: #ffffff;
  border: solid 1px #d7d7d7;
}

.md-open-menu-container.md-active:after,
.md-open-menu-container.md-active:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.md-open-menu-container.md-active:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.md-open-menu-container.md-active:before {
  border-color: rgba(215, 215, 215, 0);
  border-bottom-color: #d7d7d7;
  border-width: 11px;
  margin-left: -11px;
}

.emersonicon.ee-account-profile-picture:before {
  background-image: url('/assets/images/toolbar-icon-sprite.png');
  background-position: -8px -150px;
}

.md-menu.md-open,
.md-menu:hover,
.md-icon-button.md-open {
  .emersonicon.ee-account-profile-picture:before {
    background-image: url('/assets/images/toolbar-icon-sprite.png');
    background-position: -8px -332px;
  }
}
/*brand*/
.brands-wrapper {
  .brand-logo {
    width: 100px;
    height: 85px;
    border: solid 1px #79c143;
  }
}

.ee-dropdown-menu {
  h2 {
    margin: 10px 0;
    font-size: 20px;
    text-align: left;
  }

  h2:first-child {
    margin-top: 0;
  }

  h3 {
    font-size: 16px;
  }
}

.ee-media {
  .image {}

  .body {
    h3 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.ee-dropdown-menu-padding {
  padding: 20px 0 !important;
}

.image-dummie {
  width: 110px;
  height: 70px;
  border: solid 1px #79c143;
}
/**/
.navbar-toggle {
  display: none;
}
@media (min-width: 768px) {
  .container-fluid > .navbar-collapse,
  .ee-container > .navbar-collapse,
  .ee-container > .navbar-header,
  .ee-container-fluid > .navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}
/*Log in Container*/
.login-container {
  width: 363px;
  position: relative;

  md-input-container {
    margin: 10px 0;
    padding: 0;
  }
}

.log-in-btn-dd {
  margin: 0;
  width: 90%;
  background-color: @primary-color;
}

.forgot-password-dd {
  margin: 0;
}

.register-btn-dd {
  display: block;
  margin-top: 10px;
}
/*Welcome User*/
.welcome-user {
  clear: both;
  position: absolute;
  right: 15px;
  top: 135px;
  width: 100%;
  max-width: 455px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  outline: none;

  .welcome-user-panel {
    max-height: 600px;

    .md-padding {
      padding: 12px 0 0;
    }
  }

  div.title {
    margin: 20px 0 0 15px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.2px;
  }

  md-tabs,
  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    &.unopen {
      min-height: 0;

      .md-tab.md-active {
        border-top: solid 3px transparent;

        .ng-scope {
          color: #999999;
        }
      }

      .list-item {
        height: 0;
        line-height: 0;
      }
    }
  }

  md-pagination-wrapper,
  md-tabs-canvas {
    height: 40px;
    width: 100% !important;
    padding: 0 20px;
    box-sizing: border-box;
  }

  md-tabs-content-wrapper {
    top: 40px;
  }

  //md-content {
    //padding-top: 20px;
  //}

  md-content.recent-order-content {
    padding: 20px 20px 0;
    box-sizing: border-box;
  }

  md-tabs[md-border-top] md-tabs-wrapper {
    width: 100%;
  }

  md-tabs[md-border-top] .md-tab {
    width: 50%;
    text-transform: capitalize;
  }

  md-tabs[md-border-top] .md-tab .ng-scope {
    padding: 5px;
    line-height: 1.2;
  }

  md-divider {
    border-top: 1px solid #e1e1e1;
  }

  md-list-item .md-no-style,
  md-list-item.md-no-proxy {
    padding: 0;
  }

  md-list-item .md-list-item-inner > md-checkbox,
  md-list-item > md-checkbox {
    margin-right: 10px;
    width: 27px;
  }

  md-checkbox .md-icon {
    border: 1px solid #999999;
  }

  md-checkbox.md-checked .md-icon:after {
    left: 8.66667px;
    top: 4.22222px;
    color: @primary-color;
  }

  md-tab-content {
    transition: none;
  }

  input.qty {
    width: 30px;
    text-align: center;
  }

  md-checkbox .md-container {
    width: 27px;
    height: 27px;
  }

  md-checkbox .md-icon {
    border-radius: 50%;
    width: 27px;
    height: 27px;
  }

  .qty-label {
    text-align: right;
    font-size: 12px;
    color: #999999;
    margin-right: 10px;
  }

  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 506px;
  }

  .view-all-btn {
    margin-top: 10px;
    display: block;
  }

  .ee-search {
    border-top: 1px solid #ccc;
    height: 48px;
    margin-bottom: 15px;

    input {
      height: 46px;
    }
  }

  .md-secondary-container {
    margin-top: 0;
    margin-bottom: 0;
    height: 52px;
  }

  .md-button.md-icon-button.md-secondary {
    width: 47px;
    border-left: 1px solid #e1e1e1;
    border-radius: 0;
    height: 50px;
  }
  /*button toggle*/
  .button-toggle {
    width: 21px;
    height: 21px;
    background-image: url('/assets/images/button-toggle-open.png');
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
    cursor: pointer;

    &.open {
      background-image: url('/assets/images/button-toggle-close.png');
      width: 29px;
      height: 29px;
      top: 0;
    }
  }

  .tabs-dummie {
    border-top: solid 1px @primary-color;
    margin: 15px 15px 10px;
    display: block;

    ul {
      padding: 0;
      margin: 5px 0 15px;

      li {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        display: inline-block;
        width: 133px;
        font-size: 14px;
        color: #999999;
        text-align: center;
      }
    }

    &.open {
      display: none;
    }
  }

  .list-item {
    &.nothing {
      line-height: 366px;
      text-align: center;
      border-bottom: solid 1px #e1e1e1;

      p {
        color: #999999;
        font-size: 18px;
      }
    }

    .item-row {
      padding: 15px 0 0 0;
      border-bottom: solid 1px #ccc;
      text-align: left;
      .item-quantity {
        text-align: right;
        input.qty {
          width: 30px;
          text-align: center;
        }
      }
    }
  }

  .md-button {
    margin: 20px 10px;
  }

  .autoheight {
    height: 460px;
  }

  .quick-order {
    background-color: #fff;
    box-shadow: none;
    width: 100%;
  }

  .quick-order-wrapper {
    padding-bottom: 62px;
    height: 384px;
    position: relative;

    .layout-row,
    .quick-order-items {
      padding: 0 20px;
      box-sizing: border-box;
    }

    md-input-container .md-placeholder,
    md-input-container label:not(.md-no-float):not(.md-container-ignore) {
      color: #333333;
    }

    md-input-container {
      margin: 18px 25px 18px 0;
    }

    md-input-container .md-input {
      color: @primary-color;
    }

    .add-to-cart-status-message-bottom {
      height: 62px;
      line-height: 62px;
      width: 100%;
      background-color: #333333;
      position: absolute;
      bottom: 0;
      text-align: center;

      .add-to-cart-status-message {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #ffffff;
      }
    }
  }
}
.app-tab-link-container{
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.app-tab-link{
  max-width: 45%;
  flex-grow: 1;
}
/*Welcome User Mobile view*/
@media (max-width: 959px) {
  .welcome-user {
    top: 81px;
    /*hide it for now, will work on it later*/
    display: none;
  }

  #bannerMessage {
    padding-top: 15px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    position: relative;

    img {
      float: left;
    }
  }

  #bannerMessage md-dialog md-toolbar {
    right: 35px;
  }

  .ee-search {
    border: none;

    md-autocomplete.search-auto-m {
      width: 100%;
    }
  }

  .dropdown.search div div.dropdown-display {
    height: 40px;
    margin-top: 5px;
  }

  .md-virtual-repeat-container {
    &.search-container-m {
      min-height: 900px;
      height: auto;
      overflow-y: scroll;

      .md-virtual-repeat-scroller {
        // width: 330px;
        // border-right: solid 1px #e6e6e6;
        overflow: visible;

        .md-virtual-repeat-offsetter {
          ul.md-autocomplete-suggestions {
            padding-bottom: 40px;

            li {
              line-height: 10px;
              margin: 0;
              padding: 0;

              &:focus,
              &:hover {
                background-color: #fff;
              }

              &:first-child {
                .seperate-line {
                  border-top: 0;
                }
              }

              .show-list-search {
                .left {
                  border-right: solid 1px #e6e6e6;
                  overflow: hidden;
                  padding: 0;
                  margin: 0;

                  .title {
                    margin: 5px;
                    padding: 8px 5px 5px;
                    color: #666666;
                    cursor: default;
                  }

                  .item-metadata {
                    margin: 5px;
                    padding: 5px;
                    color: @link-color-green;
                    line-height: 0.4;

                    &:focus,
                    &:hover {
                      background-color: #f5f5f5;
                    }

                    //&:hover {
                    //  .show-related-submenu {
                    //    display: block;
                    //  }
                    //}

                    .show-related-submenu {
                      // display: none;
                      // display: block;
                      //position: absolute;
                      //top: 0;
                      //right: 0;
                      //width: 50%;
                      //height: 100vh;
                      //padding: 10px 0 0 10px;
                      //margin: 0;

                      hr {
                        width: 90%;
                        display: block;
                        height: 1px;
                        border: 0;
                        border-top: 1px solid #ccc;
                      }

                      .sub-title {
                        margin: 0;
                        padding: 0 15px;
                        line-height: 2;

                        .sub-title-1 {
                          color: #999;
                          font-weight: 500;
                        }

                        .sub-title-2 {
                          color: #000;
                        }
                      }

                      ul {
                        padding: 0 15px;

                        li {
                          line-height: 1.5;
                        }
                      }

                      .shop-all-btn {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.43;
                        letter-spacing: 0.1px;
                        text-align: center;
                        color: #ffffff;
                        width: 138px;
                        height: 40px;
                        background-color: #79c143;
                        margin-left: 100px;
                        margin-top: 30px;
                      }
                    }

                    .item-metastat {
                      a {
                        text-decoration: none;
                        color: @link-color-green;

                        &:focus,
                        &:hover {
                          text-decoration: none;
                        }
                      }
                    }
                  }
                }
              }

              .show-recent-search {
                .title {
                  margin: 5px;
                  padding: 8px 5px 5px;
                  color: #666666;
                  cursor: default;
                }

                .item-metadata {
                  margin: 5px;
                  padding: 5px;
                  color: @link-color-green;
                  line-height: 1.1;

                  &:focus,
                  &:hover {
                    background-color: #f5f5f5;
                  }

                  .item-metastat {
                    a {
                      text-decoration: none;
                      color: @link-color-green;

                      &:focus,
                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .app-tab-link{
    max-width: 14em;
  }
}

/*END Welcome User*/
/*My Account*/
.my-account-list .list-unstyled {
  margin-top: 0;

  li {
    margin-bottom: 10px;
  }
}
/*Support*/
.support-list .list-unstyled {
  margin-top: 0;

  li {
    margin-bottom: 10px;
  }
}
/*Mobile menu*/
.mobile-menu-container {
  width: 100%;
  background-color: #ffffff;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 1px;
  position: absolute;
  padding: 10px 0 70px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  max-height: 1780px;
  z-index: 21;

  .user-support {
    float: none;
    width: 100%;
    .user-support-lists .wellevate-link{
      float:left;
      margin-left:10px;
      width:108px;
      a{
        width:108px;
      }
    }
  }

  .site-menu {
    float: none;
    width: 100%;
  }

  li {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  .mobile-menu-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      border-top: rgba(0, 0, 0, 0.06) 1px solid;

      &:last-child {
        border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
      }
      h3{
        height: auto;
      }
      .media-body{
        padding-left: 5px;
      }
      a {
        padding: 20px 0;
        margin: 0 15px;
        display: block;
        color: #333333;
        font-weight: 500;
        text-transform: uppercase;
        outline: none;
        &:hover {
          text-decoration: none;
        }
        &.md-open {
          text-decoration: none;
          color: #000000;
          font-weight: 500;
          border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
        }

        &.unopen {
          text-decoration: none;
          color: #777777;
          font-weight: 500;
        }

        .arrow-icon {
          float: right;
          margin-top: 7px;
          display: block;
        }

        .text {
          float: left;
        }

        &.md-open {
          .arrow-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
    .mobile-menu-sub-items {
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      max-height: 100%;

      .header-title {
        text-align: center;
        margin: 8px 0;
        font-size: 17px;
      }

      .mobile-menu-sub-items-div-wrapper {
        padding-right: 7px;
        padding-left: 0;
      }

      .divider {
        border-bottom: 1px solid rgba(0,0,0,0.06);
        margin-right: 8px;
        margin-left: 15px;
      }

      .browse {
        font-size: 14px;
        text-align: center;
        margin-bottom: 4px;
      }

      .browse-menu {
        margin: 0 0 15px 0;

        li > a {
          margin: 0;
          padding: 0;
        }
      }

      li {
        border-bottom: 0;
        border-top: 0;

        a {
          text-transform: capitalize;
          padding: 10px 0;
          color: @link-color-green;

          &.mobile-menu-sub-item-toggle {
            border-bottom: 1px solid rgba(0,0,0,.06);

            + div[ng-if], + ul[ng-if] {
              overflow: hidden;

              &.ng-enter, &.ng-leave {
                transition: max-height 0.22s ease-in;
              }

              &.ng-enter, &.ng-leave.ng-leave-active {
                max-height: 0;
              }

              &.ng-leave, &.ng-enter.ng-enter-active {
                max-height: 850px;
              }
            }
          }
        }

        &:last-of-type {
          > a.mobile-menu-sub-item-toggle {
            border-bottom: none;
          }
        }

        &.feature-product a {
          margin-left: 0;
        }

        .mobile-menu-sub-item-links {
          &:last-of-type>a {
            border-bottom: 1px solid rgba(0,0,0,.06);
          }

          &.padding-left {
            padding-left: 15px;
          }

          a {
            text-transform: uppercase;
            font-size: 13px;
          }
        }

        .mobile-menu-sub-item-header {
          padding: 10px 0;
          margin: 0 15px;
          color: #000;
          font-size: 13px;
          font-weight: 500;
          text-transform: uppercase;
          border-bottom: 1px solid rgba(0,0,0,.06);
        }
      }

      .brand-item-list .product-summary a {
        padding: 10px 0;
        margin: 0;
        height: 20px;
        display: inline-table;
      }
    }
  }

  .browse-menu {
    text-align: center;
    margin: 30px 0 0 -30px;
    padding: 0;

    li {
      display: inline;
      font-size: 16px;
      padding: 0 15px 0 0 !important;
      margin: 0 0 0 15px !important;

      &.browse {
        display: block;
        padding-right: 0 !important;
        margin-right: 0 !important;
      }

      &.line-divider {
        border-right: solid 1px #d7d7d7 !important;
      }

      a {
        display: inline-block;
        color: #79c143;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  .site-menu {
    .arrow-icon {
      float: right;
    }
  }
  /*user support tool*/
  .user-support {
    text-align: right;

    a.md-button:hover {
      background-color: transparent;
    }

    .md-button {
      overflow: visible;
    }

    .md-button.md-open:after,
    .md-button.md-open:before {
      border-color: hsla(0,0%,84%,0);
      border-bottom-color: #d7d7d7;
      border-width: 11px;
      margin-left: -11px;
      bottom: -50%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      z-index: 101;
    }

    .md-button.md-open:after {
      border-color: hsla(0,0%,100%,0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }

    .md-button.md-open:before {
      border-color: hsla(0,0%,84%,0);
      border-bottom-color: #d7d7d7;
      border-width: 11px;
      margin-left: -11px;
    }

    ul.user-support-lists {
      padding: 0;
      margin: 10px 0 20px;

      li {
        display: inline;

        .mobile-menu-sub-items {
          margin: 0;
          padding: 0;

          li {
            display: block;
            text-align: left;
            margin-bottom: 15px;

            a {
              line-height: 1.3;
	            letter-spacing: 0.4px;
              font-weight: 500;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .user-support-items {
        position: absolute;
        left: 0;
        z-index: 100;
        display: block;
        background-color: #fff;
        margin: 17px 0 0;
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #d7d7d7;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

        h3 {
          font-size: 18px;
        	font-weight: 500;
        	font-style: normal;
        	font-stretch: normal;
        	line-height: 1.04;
        	letter-spacing: 0.4px;
          margin: 0 0 20px 0;
        }
      }
    }

    .md-button.md-icon-button {
      padding: 0;
      height: 34px;
    }

    .md-menu:hover {
      .emersonicon.emersonicon-support:before {
        background-image: url('/assets/images/toolbar-icon-sprite.png');
        background-position: -7px -6px;
      }
    }
  }

  .ee-media {
    .image {
      width: 100%;
    }
  }
}

.ee-mobile-nav {
  .ee-mobile-nav-wrapper {
    height: 56px;
  }
}
.welcome-user-mobile {
  width: 100%;
  float: left;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #999;
  opacity: 1;
  z-index: 20;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

  .welcome-wrapper {
    border-top: #999 solid 1px;
    padding: 0 15px;
    height: 41px;
  }
  .border-welcome {
    border-bottom: @primary-color solid 1px;
  }

  &.expand {
    height: auto;
    min-height: 40px;
  }
  h3 {
    margin: 10px 0 0 0;
    font-weight: 400;
  }
  .action-menu-section {
    padding: 10px 15px;
    .actions-wrapper {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .button-toggle {
    width: 35px;
    height: 42px;
    background-image: url('/assets/images/button-toggle-open.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    cursor: pointer;
    outline: 0;

    &.open {
      background-image: url('/assets/images/button-toggle-open.png');
      transform: rotate(-180deg);
      width: 35px;
      height: 42px;
    }
  }
  .list-wrapper {
    padding: 15px;
    .qty-label {
      text-align: right;
      font-size: 14px;
      color: #eee;
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .list-item {
      // padding: 15px 0 0 0;
      .item-row {
        padding: 10px 0 0 15px;
        border-bottom: solid 1px #ccc;
        text-align: left;
        line-height: 1.45;
        .item-quantity {
          text-align: right;
          input.qty {
            width: 30px;
            text-align: center;
            // border: 1px solid #999999;
          }
        }
      }
      md-checkbox {
        padding-top: 5px;
      }
      md-checkbox .md-container {
        width: 27px;
        height: 27px;
      }

      md-checkbox .md-icon {
        border-radius: 50%;
        width: 27px;
        height: 27px;
        border: 1px solid #999999;
      }

      md-checkbox.md-checked .md-icon:after {
        left: 8.66667px;
        top: 4.22222px;
        color: @primary-color;
      }

    }
  }
  .action-buttons {
    padding-bottom: 15px;
  }
  .quick-order-wrapper-mobile {
    .quick-order {
      margin-top: -1px;
      background-color: #fff;
      box-shadow: none;
      width: 100%;
      .unit {
        span {
          color: #fff;
        }
      }
    }
    .autoheight {
      height: 300px;
    }
  }
}

.mobile-items-right-side {
  text-align: right;
  margin: 0 0 0 5px;
  padding-left: 5px;

  .cart {
    text-align: left;

    .media {
      overflow: hidden;
      margin: 0 0 15px;

      .unit {
        float: left;

        &:last-child {
          float: none;
          overflow: hidden;
          text-align: right;
        }
      }
    }

    .cart-item,
    .sub-total {
      font-weight: 700;
    }

    .free-shipping-wrapper {
      padding: 0;
      margin: 0 0 15px;

      p {
        margin: 0;
        font-size: 12px;
        line-height: 1.3;

        .shift-left {
          padding-left: 5px;
        }
      }

      .free-shipping-progress-bar {
        margin: 15px 0;
        padding-right: 40px;
      }

      md-progress-linear.free-shipping {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 38px;
          height: 21px;
          background-image: url("/assets/images/delivery-truck-sprite.png");
          background-position: 0 0;
          background-repeat: no-repeat;
          position: absolute;
          right: -40px;
          top: -15px;
        }

        &.active:after {
          background-image: url("/assets/images/delivery-truck-sprite.png");
          background-position: 0 -21px;
        }
      }
    }
  }

  li {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: inline;

    &.close-button-menu-toggle {
      height: 55px;
      display: block;
      line-height: 55px;
      a {
        color: #cccccc;
        font-size: 30px;
        font-weight: 300;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .md-button.md-icon-button {
    padding: 0;
  }

  .md-cart-icon,
  .md-search-icon {
    width: 34px;
    height: 34px;
    border-radius: 0;
    padding: 0;
    margin: 10px 0;
  }

  a.md-button:hover {
    background-color: transparent;
  }

  .toolbar-sub-menu {
    position: absolute;
    z-index: 100;
    display: block;
    background-color: #fff;
    margin: 14px 0 0;
    padding: 20px 5px;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    min-width: 150px;
    left: 0;
    margin: 0;

    .close-searchbox-button {
      margin-top: 5px;
      padding: 0;
      width: 40px;
      height: 40px;
      border: 1px solid #fff;
      background-color: #fff;
      color: #76bd23;
    }

    #searchInputWrapper {
      margin-left: 8px;
    }
  }

  .menu-items {
    li {
      display: block;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .emersonicon.emersonicon-cart {
    .cart-item {
      position: absolute;
      top: 39%;
      font-size: 10px;
      left: 37%;
      text-align: center;
      width: 15px;
    }
  }
}

.border-block {
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

.link-inline {
  a {
    display: inline-block !important;
  }
}
/*Nav breakpoint*/
@media (max-width: 1140px) {
  .navbar-logo {
    margin-right: 20px;

    img {
      width: 120px;
    }
  }
}

@media screen and (max-width: 959px) and (orientation: landscape) {
  .navbar-wrapper {
    padding-bottom: 10px;
  }

  .hero-container #herobrnds {
    margin-left: 234px;
    margin-top: 66px;
    font-size: 22px;

    span {
      font-size: 16px;
    }
  }
}

@media (max-width: 959px) {
  #navbar {
    &.homepageStyle {
      top: 0;

      &.navbar-wrapper {
        position: relative;
      }
    }
  }

  .navbar-logo {
    margin-right: 20px;
  }

  .navbar-wrapper {
    position: relative;
    top: 0 !important;
  }

  .navbar-toggle {
    display: block;
  }

  .ee-mobile-nav {
    display: block;
    height: 56px;
  }

  .navbar-header {
    display: none;
  }

  #menu_container_0,
  #menu_container_1 {
    width: 100%;

    a {
      color: #777777;
    }
  }

  .ee-mobile-nav {
    .navbar-logo {
      padding-top: 15px;
      margin-right: 0;

      img {
        width: 100%;
        max-width: 160px;
      }
    }
  }

  .mobile-menu-toggle {
    width: 36px;
    height: 24px;
    margin: 15px 0;
    display: block;
  }

  .ee-mobile-menu-icon {
    padding: 0 !important;
    margin: 20px 0 !important;
  }

  .ee-mobile-hamburger {
    width: 36px;
    height: 24px;
    background-image: url('/assets/images/hamburger-icon.svg');
    background-size: 36px 24px;
  }

  .mobile-menu-toggle.open .ee-mobile-hamburger {
    width: 36px;
    height: 24px;
    background-image: url('/assets/images/hamburger-icon-hover.svg');
    background-size: 36px 24px;
  }

  .mobile-menu-container .mobile-menu-wrapper .mobile-menu-sub-items li a.md-button {
    color: #ffffff;
    margin: 10px 0;
  }

  .mobile-menu-wrapper {
    h2 {
      font-size: 20px;
      line-height: normal;
      margin-bottom: 0;
    }

    li:nth-child(2) {
      .brand-product {
        .product-thumbnail {
          img {
            width: 100%;
          }
        }

        .media-body {
          margin-left: 10%;
        }
      }
    }

    li:nth-child(3) {
      .ee-media {
        h3 {
          text-align: center;
        }

        .body {
          overflow: hidden;
          padding-left: 10px;

          p {
            margin: 0 0 5px;
          }

          a {
            color: #79c143;
          }
        }

        .image {
          width: 40%;
          float: left;

          img {
            width: 100%;
          }

          a {}
        }

        .media-block {
          border: 1px solid #999;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;
          overflow: hidden;
        }
      }
    }

    li:nth-child(4) {
      .ee-media {
        .body {
          display: none;
        }

        .image {
          a {
            text-align: center;
          }
        }
      }
    }

    [compile*="wellevate.detail"] ul.ee-media li .body {
      display:block;
    }

    li:nth-child(5) {

      .media-block {
        border: 1px solid #999;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;

        .image {
          text-align: center;
        }

        p {
          margin: 10px 0;

          a {
            margin: 0;
            padding: 0;
            color: #79c143;
          }
        }
      }

      h2 {
        margin-bottom: 10px;
      }

      div.layout-xs-column.layout-row {
        display: block;
      }
    }
  }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  background: #ffffff;
}

.type-ahead-search.md-autocomplete-suggestions {
  padding-bottom: 40px;

  li {
    margin: 5px 0;
  }

  .title {
    margin: 20px 0 10px;
    color: #666666;
  }

  .item-metadata {
    color: @secondary-color;
    line-height: 1.1;

    .highlight {
      font-weight: 700;
    }
  }
}

.mobile-items-right-side {
  md-autocomplete button {
    position: absolute;
    right: 5px;
    top: 3px;
  }

  .md-whiteframe-1dp,
  .md-whiteframe-z1 {
    box-shadow: none;
  }
}
/*Dialog popup*/
.ee-navbar md-backdrop.md-dialog-backdrop {
  z-index: 101;
  background-color: #000000;
}

.ee-navbar .md-dialog-container {
  z-index: 120;
}

.ee-navbar md-dialog {
  max-width: 90%;
  max-height: 90%;
}

.ee-navbar md-dialog.login-dialog-wrapper {
  .md-dialog-content {
    padding: 0 15px 15px;
  }

  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }

  md-toolbar {
    min-height: 40px;
  }

  .md-toolbar-tools {
    height: 40px;
  }

  .md-toolbar-tools .md-close {
    top: 2px;
  }

  .forgot-password-btn-dd {
    padding: 0;
  }

  .fp-submit-btn-dd {
    display: block;
    width: 100%;
    margin: 0;
  }

  form[name="loginForm"] {
    padding: 0;
  }
}
@media (max-width: 320px) {
  #bannerMessage md-dialog-content {
    margin-left: 0;
  }

  #bannerMessage md-dialog md-toolbar {
    right: 15px;
  }
}

@media print {
  .banner-message {
    display : none;
  }
}

.ee-navbar-toolbar  .toolbar-sub-menu.cart {
  width:auto;
  .divider {
    display: table-cell;
  }
  .divider.price {
    max-width: 100%;
  }
}

.flex-8 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 8.5%;
  max-height: 100%;
  box-sizing: border-box;
}

.mobile-hamburger { display: block; }
.mobile-hamburger-ipad { display: none; }

@media screen and (width: 768px) and (height: 1024px)  and (orientation: portrait),
       screen and (width: 540px) and (height: 720px)  and (orientation: portrait),
       screen and (orientation: landscape){
  .mobile-hamburger { display: none; }
  .mobile-hamburger-ipad { display: block; }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #bannerMessage {
    md-dialog {
      max-height: none;
      max-width: none;
      min-width: 0;
    }
  }
}

@media screen and (max-width: 959px) and (orientation: landscape){
  .md-virtual-repeat-container {
    &.search-container-m {
      min-height: 725px;
    }
  }
}

.register-link {
  vertical-align: bottom;
}

#register-button {
  line-height: 1.1;
  background-color: #0281D7;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  text-transform: none;
}

#register-button:hover {
  color: #ffffff;
  background-color: #000000;
}
