.review-order-step {
  h1.title {
    text-transform: uppercase;
    border-bottom: solid 1px #333333;
    font-size: 16px;
    margin: 15px 0;
  }
  .opt-title {
    text-align: left;
    font-size: 16px;
  }
  .md-margin {
    margin: 10px 25px 15px 0px;
  }
  .md-margin-right {
    margin: 10px 0px 15px 0px;
  }
  .progress-loading-wrapper {
    margin: 40px 0 25px 0;

    .layout-column {
      display: flex;
    }
  }
}

.order-text {
  border: none !important;
  td {
    padding: 5px 5px 5px 0 !important;
    margin-top: 0;
    h3 {
      margin-top: 0;
    }
  }
}

.cart-checkout-wrapper {
  color: #333333;

  p {
    font-weight: 300;
  }
  .tooltip-container p {
    font-weight: normal;
  }
  .tooltip {
    float: left;

    .info-icon {
      width: 19px;
      height: 19px;
      display: block;
      border: 2px solid #4399c5;
      border-radius: 50%;
      position: relative;
      padding: 1px 6px;
      box-sizing: border-box;
      font-size: 12px;
      color: #4399c5;
      margin-right: 5px;
      font-weight: 700;
      font-size: 11px;
    }
  }
  .two-col {
    .unit {
      p.title {
        margin: 5px 15px 0 0;
        font-size: 12px;
      }
    }
  }
  .cvv-confirm {
    .tooltip-container {
      margin-left: 15px;
    }
    .tooltip {
      left: -45px;
      &.tooltip-up .tooltip-arrow {
        left: 21%;
      }
    }
    .cvv-confirm-input {
      width: 66px;
      margin-top: 3px;
      padding: 0;
    }
    p.title {
      margin: 5px 15px 0 0;
    }
  }
  .purchase-order-number {
    overflow: hidden;
    p.title {
      margin: 5px 15px 0 0;
      font-size: 12px;
    }
    .purchase-order-number-input {
      width: 165px;
      margin-top: 3px;
      padding: 0;
    }
  }

}

.checkout-step-wrapper {
  .discount-saving {
    p {
      font-size: 32px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      margin: 0 0 40px 0;

      strong {
        font-weight: 500;
      }
    }
  }
}

.order-summary-wrapper {
  max-width: 796px;

  .order-summary-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;

    h3,
    p {
      margin: 0;
    }
    h3 {
      letter-spacing: 0.2px;
    }
  }
  .order-summary-table {
    table {
      width: 100%;

      .order-summary-header {
        width: 71%;
      }

      th,
      td {
        padding: 2px 5px 2px 0;
        min-width: 83px;

        &:last-of-type,
        &.no-padding-right {
          padding-right: 0;
        }
      }

      p {
        margin: 0;
      }
      thead {
        display: table-header-group;

        tr {
          border-bottom: solid 1px #333333;
        }
      }

      tbody {
        display: table-row-group;

        tr {
          border-bottom: solid 1px #d7d7d7;

          &:first-of-type td {
            padding-top: 13px;
          }

          &:last-of-type td {
            padding-bottom: 13px;
          }

          td {
            display: table-cell;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.1;

            p {
              font-size: 16px;
              line-height: 1.1;
              font-weight: 300;

              &.product-item {
                font-weight: 700;
              }
            }
            &.product-discount {
              text-decoration: line-through;
            }
          }
          a {
            color: #333333;
            font-weight: 700;
          }
        }
      }
      .review-order-table {
        border-bottom: solid 1px #d7d7d7;

        tr {
          border-bottom: none;

          td {
            color: #7b7b7b;

            &.out-of-stock {
              color: red;
              font-weight: 400;
              font-size: 12px;
            }

            &.insufficient-quantity {
              color: #f00;
              font-weight: normal;
              font-size: 12px;
              margin-top: 11px;
            }
          }
        }

        td.text-header,
        .item-total {
          color: #333333;
          padding: 0;
        }

        .review-discount {
          text-decoration: line-through;
        }
        .review-header {
          line-height: 1.3px;
        }
      }
    }

    .insufficient-quantity {
      color: #f00;
      font-weight: normal;
      font-size: 12px;
      padding-top: 11px;
    }

    .review-order {
      margin-top: 40px;
    }

    .review-item-discount {
      min-width: 24px;
      width: 24px;
      position: absolute;
    }

    .review-item-discount-link {
      color: #5DADE2;
    }

    .review-qty {
      min-width: unset;
    }

    .order-item {
      padding-top: 14px;
    }

    .review-order-product {
      color: #333333;
    }

    td.product-item div {
      padding-top: 4px;
    }

    .discount {
      h3,
      p {
        margin: 0;
      }
    }

    .discount-number {
      margin: 3px 5px 0 0;
      font-size: 16px;
    }

    .discount-text {
      padding: 3px 5px;
    }

    .discount-highlight {
      background-color: #e9f6ff;
    }

    .total-summary {
      margin-top: 10px;
      display: table;

      tr {
        border-bottom: 0;

        td {
          font-weight: 300;
          font-size: 16px;
          line-height: 1.1;
          padding: 5px 15px 5px 0;
        }

        &.total {
          border-top: solid 1px #d7d7d7;

          td {
            font-weight: 700;
            padding-top: 10px;
          }
        }
      }
    }

    .csa-order-hold-codes {
      margin-bottom: 15px;
      .csa-order-hold-codes-list {
        md-checkbox .md-label {
          margin-left: 45px;
        }
      }
    }
  }
  .copy-mod {
    margin-bottom: 20px;

    h3,
    p {
      font-size: 16px;
    }
  }
  .disclaimer {
    margin: 30px 0 40px 0;
  }
}

.copy-mod {
  h3 {
    margin-bottom: 0;
  }
  p {
    margin: 0 0 15px 0;
  }
}

/* layout-xs */
@media (max-width: 480px) {
  .order-summary-wrapper {
    .order-summary-title {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 599px) {
  .order-summary-wrapper {
    table thead {
      display: table-header-group;
    }
  }
}

/*layout-md & layout-sm*/
@media (max-width: 959px) {
  .checkout-step-wrapper {
    margin-top: 0;

    md-input-container {
      margin: 18px 0 0 0;
    }
    .discount-saving {
      p {
        font-size: 20px;
        margin: 30px 0;
      }
    }
  }

  .review-order-step {

    .md-margin {
      margin: 10px 25px 0px 0px;
    }

    h1.title {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.2px;
      margin-top: 0;
    }   
    p {
      font-size: 12px;
    }
  }

  .order-summary-wrapper {

    .order-summary-title {
      h3 {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.2px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.8;
      }
    }

    .order-summary-table {
      table tbody tr td {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
      }
      thead th {
        font-size: 12px;
      }
      .total-summary {
        tr td {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.2;
          margin-bottom: 0;
          padding-bottom: 0;

          &.padding-bottom {
            padding-bottom: 5px;
          }
        }

        tr.total td {
          padding-top: 5px;
        }
      }

      .discount-number {
        font-size: 13px;
      }
    }

    .disclaimer {
      .copy-mod h3,
      .copy-mod p {
        font-size: 12px;
      }
    }

    .order-summary-list-mobile {
      border-top: 1px solid rgba(0,0,0,.87);

      .order-summary-item {
        border-bottom: 1px solid #d7d7d7;
        padding: 10px 0;
      }

      .item-summary-wrapper {
        color: rgba(0,0,0,.67);
        font-size: 13px;
        margin-bottom: 13px;

        div {
          line-height: 1.3em;
        }

        a {
          color: rgba(0,0,0,.87);
          font-size: 14px;
          font-weight: 500;
        }
      }

      .item-details-table {
        font-size: 12px;
        width: 100%;

        tr {
          border: none;

          &:first-of-type td {
            padding-top: 0;
          }

          &:last-of-type td {
            padding-bottom: 0;
          }
        }

        .less-padding-right {
          padding-right: 2px;
        }

        th,
        td {
          text-align: center;
          padding: 0 15px 0 0;
          min-width: unset;

          &:last-of-type {
            padding-right: 0;
          }
        }

        td {
          color: rgba(0,0,0,.67);
          font-size: 14px;
          font-weight: normal;

          // override clinical-updates table rule
          &:nth-child(4) {
            width: unset;
          }
        }

        .review-item-discount {
          right: 5px;
        }
      }

      .item-details-header-row {
        border: none;
      }
    }
  }
}

