.shipping-address-list {
  margin-top: 20px;
  margin-bottom: 30px;

  &.drop-ship-wrapper {
    margin-top: 15px;
  }

  .header-title.no-underline {
    margin-bottom: 0;
  }
}

.shipping-address {
  h2 {
    margin: 0;
  }

  .sub-tab {
    margin-bottom: 20px;
  }
  .add-more-btn {
    display: block;
  }
}

.shipping-address-list {
  tbody {
    tr {}
  }
  .table td {
    padding: 15px;
  }
}

.shipping-address-list-wrapper {
  .sub-tab {
    width: 50%;
    box-sizing: border-box;

    &.full {
      width: 100%;

      li {
        width: 50%;
      }
    }

    li {
      width: 100%;
      float: left;

      a {
        padding: 7px;
      }
    }
  }

  .find-shipping {
    width: 350px;
    float: right;
    margin-bottom: 0;
    margin-top: 0;

    .md-errors-spacer {
      min-height: 0;
    }

    .input-message {
      clear: both;
      padding: 10px 0;
    }
  }
}

.default-shipping-address-table,
.shipping-address-table {
  position: relative;
  .table tbody tr {
    border-bottom: solid 1px #d7d7d7;

    &:last-child {
      border-bottom: solid 1px #999999;
    }
  }
  /*edit/delete*/
  td:nth-of-type(3) {
    text-align: right;
    padding-right: 15px;

    .trashcan {
      margin-left: 20px;
    }
  }

  .set-as-default-link {
    font-size: 14px;
    line-height: 2.0;
  }
}

.shipping-address-table {
  margin-top: 40px;
  &.drop-ship-address-type {
    margin-top: 10px;
  }
}

.default-shipping-address-table {
  .table tbody tr {
    &:last-child {
      border-bottom: 0;
    }
  }
}

address-form {
  width: 100%;
}
@media (max-width: 959px) {
  .shipping-address-list-wrapper .find-shipping {
    width: 100%;
    float: none;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .shipping-address-table {
    margin-top: 20px;

    .required-wrapper {
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  .sub-tab {
    margin: 10px 0 0;
  }

  .shipping-address-list {
    .required-wrapper {
      font-size: 12px;
    }
    .search-address {
      margin: 20px 0 0 0;
    }
  }

  .shipping-address-list .header-title.no-underline {
    margin-top: 10px;
  }

  .shipping-address.list-unstyled.unit li.left-divider {
    border-left: 0;
    margin-right: 0;
    float: right;

    .add-address {
      margin-top: 5px;
    }
  }

  .shipping-address {
    margin-top: 30px;
  }

  .shipping-address h2 {
    margin-top: 0;
  }
  .shipping-address-table {

    &.drop-ship-address-type {

    }
  }
}
@media (max-width: 320px) {
  .shipping-address-list-wrapper .sub-tab li a {
    font-size: 13px;
  }
  .shipping-address {
    margin-top: 30px;
    &.list-unstyled.unit li {
      margin-right: 5px;

      .add-more-btn {
        min-width: 60px;
      }
    }
  }
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 959px) {
  .shipping-address-table {
    /* Force table to not be like tables anymore */
    table,
    tbody,
    td,
    th,
    thead,
    tr {
      display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      td {
        text-transform: uppercase;
        font-weight: 700;
      }

      td:nth-of-type(1) {
        display: none;
      }
    }

    tr {
      margin-bottom: 15px;
      position: relative;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 0;
      padding-right: 65px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 0;
      padding-right: 10px;
      white-space: nowrap;
      display: none;
    }
    /*
    Label the data
    */
    td:nth-of-type(1):before {
      content: "Name";
    }

    td:nth-of-type(2):before {
      content: "Address";
    }
    /*edit/delete*/
    td:nth-of-type(3) {
      position: absolute;
      right: 0;
      top: 0;

      .trashcan {
        margin-left: 10px;
      }
    }
  }

  .time-period {
    margin: 10px 0;
    padding: 0;
  }

  .time-period {
    margin: 10px 0;
    padding: 0;
  }

  .default-shipping-address-table {
    /* Force table to not be like tables anymore */
    table,
    tbody,
    td,
    th,
    thead,
    tr {
      display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      display: none;

      td {
        text-transform: uppercase;
        font-weight: 700;
      }

      td:nth-of-type(1) {
        display: none;
      }
    }

    tr {
      margin-bottom: 15px;
      position: relative;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 0;
      padding-right: 40px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 0;
      padding-right: 10px;
      white-space: nowrap;
      display: none;
    }
    /*edit/delete*/
    td:nth-of-type(3) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.shipping-add-edit-save-btn {
  margin: 0 auto;
  display: block;
}

.close-pencil-btn {
  float : right;
  transform: rotate(-180deg);
}

.close-pencil-btn-td{
  padding-right: 0;
}

md-dialog.md-transition-in .checkout-actions-tab {
  position : absolute;
  bottom : 0px;
  width : 100%;
}
.text-12 {
  font-size: 12px;;
}
.disable-shipping-address {
  background-color: #BEBEBE;
}
.shipping-warning {
  color: #e00603;
}