.promo-banner-side-col {
	margin: 40px 0 30px 0;
  text-align: center;
	.promo-banner {
		a {
			&.hover {
				cursor: pointer;
			}
		}
	}
}
