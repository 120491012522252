.my-orders-detail-wrapper {

  .summary {
    font-size: 16px;
    padding: 0 0 0 20px;
    font-size: 16px;
    line-height: 1.4;
    color: #454553;
  }

  .detail-summary {
    h4 {
      font-size: 12px;
      line-height: 1.3;
      color: #333333;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
      color: #454553;
    }
    .layout-padding {
      padding: 0;
      >.flex {
        padding: 15px 20px 15px 0;
      }
    }
  }
  .main-content {
    padding-bottom: 0;
  }
}
.dash-border-bottom {
  border-bottom: dashed 1px #000000;
}
.light-gray-border-top {
  border-top: solid 1px #999999;
}
.light-gray-border-bottom {
  border-bottom: solid 1px #999999;
}
.package-detail {
  margin: 20px 0 40px;
  .header-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  h4 {
    font-size: 12px;
    line-height: 1.3;
    color: #333333;
  }
  p {
    font-size: 16px;
    line-height: 1.4;
    color: #454553;
  }
  &:last-child {
    table.order-detail {
      border-bottom: solid 1px #999999;
    }
  }
}
.order-detail {
  margin: 30px 0;

  h5 {
    margin: 0 0 10px 0;
  }
  p {
    margin: 0 0 10px 0;
  }
  md-checkbox {
    margin: 0;
  }

  img {
    width: 75px;
  }

  &.table {
    tbody {
      tr {
        border-bottom: solid 1px #999999;
        td {
          padding: 15px 40px 15px 0;
          color: #333333;
        }
        td:nth-of-type(1) {
          padding: 15px;
        }
        td:nth-of-type(2) {
          padding-right: 67px;
        }
        td:nth-of-type(5) {
          padding-right: 0;
          span {
            width: 70px;
            float: right;
          }
        }

      }
      .item-detail {
        font-size: 14px;
        // color: @link-color-green;
      }
      a {
        color: @link-color-green;
        font-size: 16px;
        line-height: 1.1;
        text-decoration: none;
        cursor: default;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    thead {
      td:nth-of-type(5) {
        text-align: right;
        padding-right: 0;
      }
    }
  }

}
.package-detail {
  margin: 20px 0 40px;
  .header-title {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 28px;
    // font-weight: bold;
    color: #333333;
    border-bottom: solid 0 !important;

    > button {
      margin-top: 0;
      border-width: 2px;
      border-style: solid;
    }
  }
  .invoice-button-holder {
    padding: 10px 0 20px 0;
    > a {
      font-size: 18px;
      line-height: 21px;
    }
  }
  h4 {
    font-size: 12px;
    line-height: 1.3;
    color: #333333;
    margin: 10px 10px 0 0;
  }
  p {
    font-size: 16px;
    line-height: 1.4;
    color: #454553;
    margin: 10px 10px 0 0;
  }
  &:last-child {
    table.order-detail {
      border-bottom: solid 1px #999999;
    }
  }

  .disable-tracking-link{
    pointer-events:none;
    color:#666666;
  }
}
.back-btn {
  text-transform: uppercase;
  font-weight: bold;
}
.order-number-header {
  margin-top: 80px;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.5;
  color: #333333;
}

/*Responsive table*/
@media only screen and (max-width: 959px) {

  div.top-border.detail-summary.layout-xs-column.layout-sm-column.layout-row {
    display: block;
  }

  .order-number-header {
    margin: 20px 0 10px 0;
    font-size: 20px;
  }
  .my-orders-detail-wrapper {
    .main-content {
      padding-top: 20px;
    }
    .summary {
      padding-left: 0;
      font-size: 14px;
      border-top: 0;

      &.layout-margin {
        margin-left: 0;
        margin-right: 0;
        padding-top: 10px;
        padding-left: 0;

        * {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .detail-summary {

      .layout-padding > .flex {
        padding: 10px 0px 0px 0;

        p {
          font-size: 14px;
          color: #333333;
          font-weight: 300;

          &:last-child {
            margin-bottom: 0;
          }
        }
        h4 {
          font-size: 16px;
        }

        &:last-child {
          padding-bottom: 10px;
        }
      }
    }
  }


  .order-detail {
    border-top: 2px solid #eee;

    &.table {
      /* Hide table headers (but not display: none;, for accessibility) */
      thead {
        display: none !important;
      }

      tbody tr {
        margin: 0;
        padding: 15px 0;

        td {
          border-bottom: 0;
          font-size: 14px;
          flex: 1 0 auto !important;
        }
        td:nth-of-type(1) {
          padding: 0;
        }

      }

      td {
        border: none;
        position: relative;
        padding-left: 0;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #333333;
      }

      .track-order {
        display: none;
      }
      .prn {
        padding-right: 0 !important;
      }
      .pr10 {
        padding-right: 10px !important;
        padding-left: 15px;
      }
      img {
        width: 100%;
      }
    }
  }

  .time-period {
    margin: 10px 0;
    padding: 0;
  }

  .time-period {
    margin: 10px 0;
    padding: 0;
  }

  .view-more-btn {
    font-size: 14px;
    position: relative;
    &:before {
      top: 2px;
    }
  }

  .package-detail {
    p {
      font-size: 14px;
    }
  }

}

.reorder-tooltip {
  height: auto;
  font-size: 12px;
}