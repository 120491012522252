table {
  border-collapse: collapse;
}

.table {
  display: block;

  thead {
    display: block;

    tr {
      border-bottom: solid 2px #999999;

      td {
        font-size: 12px;
        color: #333333;
        line-height: 1;
        display: block;

        a {
          font-size: 12px;
          color: #333333;
          line-height: 1;
        }
      }
    }
  }

  td {
    padding: 15px 15px 15px 0;
    font-size: 18px;

    a {
      color: #4399c5;
    }
  }

  tbody {
    display: block;

    tr {
      border-bottom: dashed 1px #999999;

      td {
        display: block;
      }
    }
  }

  .sort-arrow-down,
  .sort-arrow-up {
    background-image: url('/assets/images/column-sort-arrow.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 12px;
    height: 16px;
    display: inline-block;
  }

  .sort-arrow-up {
    transform: rotate(-180deg);
    background-position: center top;
  }
}
@media only screen and (max-width: 959px) {
  .table {
    .label {
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 599px) {
  table {
    thead {
      display: none;
    }
  }
}
