.site-alert-messaging {
  position: relative;

  .site-alert-close-btn {
    position: absolute;
    right: 40px;
    top: 27px;
    background-color: transparent;
    border: 0;
    color: #ffffff;
  }
}

@media only screen and (max-width: 960px)  {

}
