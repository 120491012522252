.recommended-flyout {
  padding: 8px 5px;
  font-size: 14px;

  .flex-column {
    display: flex;
    flex-flow: column;
  }

  .sub-title {
    margin: 0;
    margin-bottom: 10px;
    padding: 0 5px;

    .sub-title-1 {
      color: #999;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .sub-title-2 {
      color: rgba(0,0,0,.87);
    }
  }

  hr {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #e6e6e6;
    margin: 0 auto;
  }

  ul {
    padding: 0 6px;

    li {
      line-height: 1.5;
      list-style: none;

      a {
        color: #693;
        text-decoration: none;

        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }

  .shop-all-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: center;
    color: #fff;
    width: 138px;
    height: 40px;
    background-color: #79c143;
    margin: 30px auto 0 auto;
  }

}
