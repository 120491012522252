// mobile css at the bottom
.cart-summary-dialog {
  max-width: 80%;
  min-width: 775px;
  color: rgba(0,0,0,.87);

  .layout-column {
    display: flex; // overwrites the global 'display: block' rule
  }

  md-dialog-content {
    padding: 5px 0;
  }

  .close-button {
    background-color: #fff;
    border: none;
    font-size: 29px;
    width: 27px;
    height: 27px;
    line-height: 2px;
    margin-right: 4px;
    outline: none;
    color: rgba(0,0,0,.38);

    &:hover {
      color: rgba(0,0,0,.54);
    }
  }

  md-dialog-actions {
    justify-content: center;
    padding: 0;
    padding-top: 5px;

    >.md-button {
      min-width: 185px;
    }

    a.md-button:hover {
      text-decoration: none;
    }

    .outline-button {
      background-color: #fff;
      color: hsla(88, 69%, 37%, 1);
      border: 1px solid;

      &:hover {
        background-color: rgba(0,0,0,.08);
      }
    }
  }

  .loading-div {
    progress-spinner {
      margin: 15px auto 0 auto;
    }

    p {
      text-align: center;
    }
  }

  .error-state-wrapper {
    padding: 24px;

    h2 {
      font-size: 26px;
      margin-bottom: 0;
    }

    h4 {
      margin-top: 10px;
    }
  }

  .cart-summary-row {
    padding: 0 26px;

    .cart-summary-info-wrapper {
      padding: 0 20px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      .free-shipping-wrapper {
        p.free-shipping-text {
          margin-top: 0;
        }

        md-progress-linear.free-shipping {
          width: 83%;

          &:after {
            top: -13px;
          }
        }
      }
    }

    .divider {
      border-right: 1px solid rgba(0,0,0,.08);
    }

    .cart-summary-number {
      font-weight: 500;
      font-size: 28px;
    }
  }

  .quantity-adjustment {
    margin-top: 2px;
  }

  @media (max-width: 768px) {
    max-width: 95%;
    min-width: 95%;
    font-size: 14px;

    md-dialog-actions {
      padding-left: 8px;
      padding-right: 8px;

      >.md-button {
        min-width: unset;
      }

      .outline-button {
        margin-left: 0;
      }
    }

    .cart-summary-row {
      padding: 0 8px;
      font-size: 16px;
      margin-top: -15px;

      .divider {
        border-right: none;
      }

      .cart-summary-info-wrapper {
        margin-bottom: 10px;
        padding: 0;

        .cart-summary-number {
          font-size: 16px;
          font-weight: 700;
        }

        .free-shipping-wrapper {
          p {
            font-size: 12px;
          }

          .free-shipping-progress-bar > md-progress-linear {
            width: 87%;
          }

          .nowrap {
            white-space: normal;
          }
        }
      }
    }
  }
}
