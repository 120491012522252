#order-filter-contianer {
	margin: 15px 0;

	h2 {
		margin: 10px 0;
	}
	.time-period {
		margin: 25px 0 10px 0;
	}
	md-input-container {
		margin: 18px 0 10px 0;
		height: 30px;
	}
	a {
		color: #4399c5;
	}
}

.time-period {
	li {
		display: inline-block;
		margin-bottom: 0;
    	list-style: none;
    	border-right: 1px solid #999999;

    	&:last-child {
    		border-right: 0;
    	}

    	a {
    		padding: 0 10px;
    	}
	}
}
.view-more-btn {
	margin: 20px 0;
	display: inline-block;
	padding-left: 15px;
	line-height: 1;

	&:before {
		background-image: url('/assets/images/view-more-arrow.png');
    	background-repeat: no-repeat;
    	content: '';
    	width: 12px;
    	height: 6px;
    	position: absolute;
    	left: 0;
	}
}

.my-credit-cards {
	.edit-delete-wrapper {
		padding-right: 0;

		button {
			line-height: 1;
      &.no-style {
        min-width: 30px;
        width: 30px;
        padding: 5px;
        margin: -5px 5px;
        box-sizing: border-box;
      }
		}
	}
}

/*Track order*/
.track-order-wrapper {
	position: relative;
	text-align: center;

	p {
		font-size: 12px;
		color: #333333;
	}

	.tracking-numbers {
		position: absolute;
		bottom: 2px;
		left: -10%;
		z-index: 20;
		background-color: #ffffff;
		text-align: left;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
		border: solid 1px #d7d7d7;
		padding: 20px;

		li {
			margin-bottom: 10px;
			padding: 0;
    		list-style: none;

    		a {
    			font-size: 15px;
    		}
		}
	}
}
.tracking-numbers {
	md-menu-item {
		height: 30px;
		min-height: 30px;
	}
	a {
    	font-size: 15px;
    	color: #4399c5;
    }
    p {
    	margin: 10px 15px;
    	font-size: 12px;
		color: #333333;
    }
}

/*Responsive table*/
@media only screen and (max-width: 959px) {
  div#order-filter-contianer.layout-xs-column.layout-sm-column.layout-row {
    display: block;
  }
	.my-credit-cards {
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead {
			display: none;
		}

		tr {
      margin-bottom:15px;
      position: relative;
    }

		td {
			/* Behave  like a "row" */
			border: none;
			position: relative;
			padding-left: 0;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

    md-input-container {
      margin: 18px 0 0 0;

      &.select-field {
        margin-bottom: 18px;
      }
    }

    &#default-credit-card-table {
      .credit-card-not-expired {
        display: none;
      }
      thead {
        display: none;
      }
    }

    .edit-delete-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 95px;
    }
	}

	.time-period {
		margin: 10px 0;
    	padding: 0;
	}

  md-dialog {
    credit-card-form {
      md-input-container {
        margin: 15px 0 0 0;
        &.select-field {
          margin-bottom: 10px;
        }
        &.md-block.flex-100 {
          margin-right: 0;
        }
      }
    }
  }
}

#delete-modal {
  md-dialog {
    md-dialog-actions {
      margin: 0 auto;
    }
  }
}

md-dialog.ccForm {
	md-dialog-actions{
		display:block;
		margin-bottom:27px;
	}
}