expansion-panel {
  &:last-of-type {
    .expansion-panel-row {
      border-bottom: 1px solid rgba(0, 0, 0, .12);
      &.expansion-panel-open {
        border-bottom: none;
      }
    }
  }

  .expansion-panel {
    border-top: 1px solid rgba(0, 0, 0, .12);

    .expansion-panel-row {
      padding: 9px 12px 9px 24px;
      background-color: rgba(0, 0, 0, .05);
      color: rgba(0, 0, 0, .54);
      cursor: pointer;

      .title {
        font-size: 17px;
      }

      .md-icon-button {
        color: rgba(0, 0, 0, .54);

        md-icon {
          -webkit-transition: transform 0.16s linear;
          transition: transform 0.16s linear;

          &.flipped {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }

    .expansion-panel-content {
      border-top: 1px solid rgba(0, 0, 0, .12);
      padding: 12px 24px;
      overflow: hidden;

      &.ng-enter, &.ng-leave {
        -webkit-transform: max-height 0.16s linear;
        transition: max-height 0.16s linear;
      }

      &.ng-enter,
      &.ng-leave.ng-leave-active {
        max-height: 0;
      }

      &.ng-leave,
      &.ng-enter.ng-enter-active {
        max-height: 500px;
      }
    }
  }
}
