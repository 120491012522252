.co-op-promo-banner {
	margin-top: 50px;
	margin-bottom: 50px;
  text-align: center;
	.promo-banner {
		max-width: 797px;
		width: 100%;

		.placeholder {
			border: solid 1px #d7d7d7;
			color: #d7d7d7;
			max-width: 797px;
			width: 100%;
			vertical-align: middle;
			text-align: center;
		}
	}
}
