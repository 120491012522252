.csa-stock-info-dialog {
  max-width: 50%;
  min-width: 650px;
  color: rgba(0,0,0,.87);

  .layout-column {
    display: flex; // overwrites the global 'display: block' rule
  }

  .close-button {
    background-color: #fff;
    border: none;
    font-size: 29px;
    width: 27px;
    height: 27px;
    line-height: 2px;
    margin-right: 4px;
    outline: none;
    color: rgba(0,0,0,.38);

    &:hover {
      color: rgba(0,0,0,.54);
    }
  }

  md-dialog-content {
    padding: 5px 0;
  }

  md-dialog-actions {
    justify-content: center;
    padding: 0;
    padding-top: 5px;

    >.md-button {
      min-width: 185px;
    }

    a.md-button:hover {
      text-decoration: none;
    }

    .outline-button {
      background-color: #fff;
      color: hsla(88, 69%, 37%, 1);
      border: 1px solid;

      &:hover {
        background-color: rgba(0,0,0,.08);
      }
    }
  }

  .loading-div {
    md-progress-circular {
      margin: 15px auto 0 auto;
    }

    p {
      text-align: center;
    }
  }

  .error-state-wrapper {
    padding: 24px;

    h2 {
      font-size: 26px;
      margin-bottom: 0;
    }

    h4 {
      margin-top: 10px;
    }
  }

  .stock-info-content {
    h4 {
      margin-top: -18px;
      margin-bottom: 15px;
      text-align: center;
    }

    .stock-info-details-wrapper {
      padding: 0 35px 10px 35px;
      font-size: 14px;
      color: rgba(0,0,0,.67);

      .stock-info-item-details {
        .data-label {
          color: rgba(0,0,0,.87);
          font-weight: 500;
        }
      }

      .stock-info-lot-details {
        display: inline-block;
        margin-right: 40px;

        &:not(:last-of-type) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
