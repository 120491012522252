.search-wrapper {

  .search-result-header {
    &:before,
    &:after {
      content: " "; /* 1 */
      display: table; /* 2 */
    }
    &:after {
      clear: both;
    }
    .text {
      float: left;
    }
    .feedback {
      text-align: right;
      overflow: hidden;
    }
  }
  .main-content {
    padding-top: 40px;
    margin-bottom: 30px;
  }
  .header {
    margin-left:30px;
    h2 {
        font-size: 18px;
        font-weight: 300;
        color: #333333;
        text-align: left;
    }
  }
  md-checkbox {
    min-width: 26px;
    min-height: 26px;
    .md-container {
      width: 26px;
      height: 26px;
    }
    .md-label {
      min-height: 26px;
      padding: .5rem;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #666666;
    }
    .md-icon {
      background-color: #ffffff;
    }
    &.md-checked .md-icon {
      &:after {
        left: 9.66px;
        top: 5.22px;
      }
    }
  }
  .result-header {
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #333333;
    font-weight: 400;
    margin:25px 0 23px 0;
  }
  .btn-filter {
    background-color: @btn-secondary-bg;
    min-width: 267px;
    color:#fff;
    padding: 0 15px;
    margin-top:0;
    margin-bottom: 15px;
    cursor: pointer;
    padding-right: 46px;
    position: relative;
    height: 50px;
    line-height: 50px;
    border: solid 1px @btn-secondary-bg;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.4px;
    text-align: left;
    &:after,
    &.btn-open:after {
      position: absolute;
      right: 0;
      top: 0;
      width: 46.5px;
      height: 48px;
      line-height: 48px;
      content: '–';
      background-color: #ffffff;
      color: @btn-secondary-bg;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      font-family: Open Sans;
      font-size: 19px;
      letter-spacing: -0.2px;
      text-align: center;
    }
    &.btn-close:after {
      content: '+';
      font-size: 24px;
    }
  }
  .btn-clear {
    background-color: #ffffff;
    border: solid 1px #cccccc;
    color: @link-color-secondary;
    display: block;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0;
    cursor: pointer;
  }
  .filter {
    font-size:14px;
    min-width:267px;
    ul {
      margin:0;
      padding:0;
      list-style-type:none;
    }
    .category {
      margin-bottom: 11px;
      outline: none;
      border: solid 1px #cccccc;
    }
    .category-title {
      background:#fff;
      color:#333;
      padding: 0 10px;
      padding-right: 46px;
      display:block;
      outline: none;
      position: relative;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: #666666;
      cursor: pointer;
      &:after {
        position: absolute;
        right: 0;
        top: 0;
        width: 46.5px;
        height: 34px;
        line-height: 34px;
        content: '+';
        background-color: #ffffff;
        color: @link-color-secondary;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        font-family: Open Sans;
        font-size: 19px;
        letter-spacing: -0.2px;
        text-align: center;
      }
      &.open {
        border-bottom: solid 1px #cccccc;
        &:after {
          content: '-';
        }
      }
    }

    .category-block {
      max-height: 328px;
      overflow: auto;
    }

    .category-list {
      background-color: #fcfcfc;

      &.ingredient-chip-list {
        padding: 10px;
      }

      li {
        &.excludable-list-item {
          margin-top: 4px;

          &:first-of-type {
            margin-top: 0;
          }

          button.delete-item {
            text-align: center;
            width: 21px;
            height: 21px;
            color: #ffffff;
            background-color: #d1cec9;
            display: inline-block;
            border-radius: 50%;
            border: none;
            font-size: 10px;
          }

          span.excludable-list-item {
            font-weight: 300;
            height: 26px;
          }

          .ingredient-chip {
            background-color: rgba(0,0,0,.06);
            overflow: hidden;
            padding: 4px 26px 4px 8px;
            border-radius: 4px;
            color: rgba(0,0,0,.54);
            position: relative;
            font-size: 13px;

            button.md-icon-button {
              position: absolute;
              right: -13px;
              top: -7px;

              md-icon {
                font-size: 20px;
                color: rgba(0,0,0,.38);
                &:hover {
                  color: rgba(0,0,0,.54);
                }
              }
            }
          }
        }

        &:first-child {
          md-checkbox {
            margin-top: 10px;
          }
        }

        &:last-child {
          md-checkbox {
            margin-bottom: 10px;
          }
        }

        md-checkbox {
          margin: 5px 10px;
        }
      }

      .sub-category-list {
        background-color: #f3f3f3;

        li {
          md-checkbox {
            margin-left: 30px;
          }
        }

        .attribute-list{
          background-color: #e9e9e9;
          li{
            md-checkbox{
              margin-left: 60px;
            }
          }
        }
      }
    }

    .category-view-all {
      a {
        display: block;
        height: 34px;
        line-height: 34px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        padding: 0 10px;
        color: @link-color-secondary;
        text-transform: uppercase;
        border-top: solid 1px #cccccc;
        box-sizing: border-box;
      }
    }
    .filter-wrapper {
      .filter-tabs {
        display: block;
        margin: 10px;
        border-radius: 3px;
        border: solid 1px #cccccc;
        overflow: hidden;
        li {
          width: 50%;
          background-color: #f5f5f5;
          float: left;

          a {
            padding: 10px;
            display: block;
            text-align: center;
          }

          &.active {
            background-color: @btn-secondary-bg;

            a {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .align-right {
    float: right;
  }
}
/* tooltips */
.product-condition-tooltip {
  .tooltip-container {
    background-size: 100%;
      &:hover {
        background-position: 0 0;
      }
    .tooltip {
      height: auto;
      font-size: 12px;
      bottom: 27px;
      left: -117px;
    }
  }
}
.eqp-gold .tooltip-container {
  background:#fff url('/assets/images/icons/e-q-p-gold-icon-no-label.png') no-repeat 0 0;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  background-size: 100%;
  &:hover {
    background-position: 0 0;
  }
  .tooltip {
    height: auto;
    font-size: 12px;
  }
}
.eqp-silver .tooltip-container {
  background:#fff url('/assets/images/icons/e-q-p-silver-icon-no-label.png') no-repeat 0 0;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  background-size: 100%;
  &:hover {
    background-position: 0 0;
  }
  .tooltip {
    height: auto;
    font-size: 12px;
  }
}
.warning-message .tooltip-container {
  background:#fff url('/assets/images/icons/attention-icon.png') no-repeat 0 0;
  width: 21px;
  min-width: 21px;
  height: 19px;
  min-height: 19px;
  background-size: 100%;
  &:hover {
    background-position: 0 0;
  }
  .tooltip {

  }
}
.short-shelf-life .tooltip-container {
  background:#fff url('/assets/images/icons/icon_shelf-life.png') no-repeat 0 0;
  width: 22px;
  min-width: 22px;
  height: 19px;
  min-height: 19px;
  background-size: 100%;
  background-position: center center;
  &:hover {
    background-position: center center;
  }
  .tooltip {

  }
}
.thermo-icon .tooltip-container {
  background:#fff url('/assets/images/icons/thermo-icon.png') no-repeat 0 0;
  width: 10px;
  min-width: 10px;
  height: 27px;
  min-height: 27px;
  background-size: 100%;
  bottom: 8px;
  &:hover {
    background-position: 0 0;
  }
  .tooltip {
    left: -122px;
    bottom: 36px;
  }
}
md-toolbar.eqp {
    display:inline;
}
md-toolbar.eqp .md-button.md-fab {
    border-radius:0;
    -moz-border-radius:0;
    -webkit-border-radius:0;
    width:28px;
    height:28px;
    overflow:hidden;
    box-shadow:none;
    background:none;
    padding:0;
    margin:0;
}
md-toolbar.eqp .md-button.md-icon-button md-icon, md-toolbar.eqp button.md-button.md-fab md-icon {
    background:#fff url('/assets/images/eqp-icon.png') no-repeat 0 0;
    width:30px;
    min-width:30px;
    height:30px;
    min-height:30px;
  background-size: 100%;
}
.filter-arrow-down,
.filter-arrow-up {
    background-image: url('/assets/images/column-sort-arrow.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 12px;
    height: 16px;
    display: inline-block;
}
.filter-arrow-up {
    transform: rotate(-180deg);
    background-position: center top;
}
.product-item-card {
  .product-body {
    flex: 1 0 auto;
  }
  .product-banner {
    /**
    * For IE 6/7 only
    * Include this rule to trigger hasLayout and contain floats.
    */
    *zoom: 1;
    margin-bottom: 20px;
    min-height: 106px;
    &:before,
    &:after {
      content: " "; /* 1 */
      display: table; /* 2 */
    }
    &:after {
      clear: both;
    }
    .brand-feature {

      .brand-feature-list {
        margin: 0;
        padding: 0;
        min-height: 23px;
        li {
          list-style-type: none;
          float: left;
          span {
            text-transform: uppercase;
            height: 23px;
            line-height: 25px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.2px;
            color: #ffffff;
            padding: 0 0 0 10px;
            width: auto;
          }
          &.featured {
            span {
              background: @link-color-secondary;
              background-position: left center;
              display: block;
              position: relative;
              padding-left: 20px;
              &:before {
                background: url('/assets/images/featured-star.png') no-repeat;
                width: 12px;
                height: 11px;
                position: absolute;
                content: '';
                top: 5px;
                left: 5px;
                z-index: 1;
              }
              &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 24px 24px 0 0;
                border-color: @link-color-secondary transparent transparent transparent;
                position: absolute;
                content: '';
                top: 0;
                right: -23px;
                z-index: 1;
              }
            }
          }
          &.new {
            span {
              background-color: #666666;
              display: block;
              padding-left: 23px;
              position: relative;
              &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 24px 24px 0 0;
                border-color: #666666 transparent transparent transparent;
                position: absolute;
                content: '';
                top: 0;
                right: -23px;
                z-index: 1;
              }
            }
            &.first-item {
              span {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
    .brand-banner {
      ul {
        height : 23.33px;
        margin: 10px 0;
        padding: 0;
        display: flex;
        align-items: center;

        li {
          list-style-type: none;
          float: left;
          margin-right: 5px;
        }
      }
      .brand-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.1;
        color: #666666;
      }
    }
    .eqp {

      .tooltip-container {
        min-width: 23px;
        min-height: 23px;
        width: 23px;
        height: 23px;
      }
    }
    .product-name {
      h3 {
        min-height: 37px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        margin: 10px 0 0 0;
      }
    }
  }
  .quick-view-overlay {
    position: absolute;
    width: 100%;
    height: 42px;
    z-index: 10;
    bottom: 0;


    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: 1.4px;
      text-align: center;
      color: #666666;
      background: #dddddd;
      opacity: .9;
      cursor: pointer;
      bottom: 0;
      width: 100%;
      padding: 12px;
      margin: 0;
      box-sizing: border-box;
      text-transform: uppercase;
      outline: none;
      }
  }
  .product-item {
    position: relative;
    height: auto;

    .product-thumbnail {
      width: 100%;
      img {
        max-width: 100%;
        margin: 0 auto;
        display: inherit;
      }
    }
    .product-summary {

      h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.1;
        a {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.1;
        }
      }
    }
    a {
      color: @link-color-green;
    }
  }
  .product-buttons {

    .price {
      font-size: 26px;
      p {
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }
  .product-details {
    margin: 10px 0;
    min-height: 30px;
    .product-conditions {
      /*text-align: right;*/
      ul {
        padding: 0;
      }
      li {
        list-style-type: none;
        display: inline-block;
        font-size: 12px;
        margin-left: 6px;
        float: right;
      }
    }
  }
  .product-bottom {
    bottom: 0;
    height: 70px;
    margin: 0;
  }
  .product-body {

  }
  .product-footer {
    margin: 10px 0 0 0;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.8;
      letter-spacing: 0.1px;
      color: #666666;
      margin: 5px 0 0 0;
    }
  }
}
.purchase-notification{
  position: absolute;
  width:100%;
  text-align: center;
  background:#FFFFFF;
  z-index: 1;
}
.bottom-line-divider {
    border-bottom: solid 1px #333333;
    margin-bottom: 30px;
}
.main-tab {
    width: 100%;
    background-color: #f6f6f6;
    text-align: center;
    overflow: hidden;
    h1 {
        padding: 22px 0;
        margin: 0;
        font-size: 18px;
        color: #333333;
        line-height: 1.2;
    }
}
.main-content {
    padding: 50px 0 50px 0;
}

/*Flex items*/
.list-flexbox-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border-left: 1px solid #b7b7b7;
  margin: 0 0 0 15px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
  .list-item {
    display: flex;
    padding: 0;
    width: 100%;
    border-top: 1px solid #b7b7b7;
    border-right: 1px solid #b7b7b7;
    border-bottom: 1px solid #b7b7b7;
    box-sizing: border-box;
    position: relative;
  }
  .list-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    width: 100%;
  }
  .list-content p {
    flex: 1 0 auto;
    font-size: 13px;
  }
}
.search-results {
  &.full-width {
    .list-flexbox-grid {
      margin: 0;
    }
  }
  &.non-loggedin {
    .product-item-card {
      .product-item {
        padding-bottom: 0;
      }
    }
  }

  .no-results{
    border: 0;
  }
}

.coop-banner{
  margin: 0;
  list-style: none;
  width:100%;
  text-align: center;
  border-right: 1px solid #b7b7b7;
  img{
    width: 100%;
  }
}

/*product detail modal*/
.show-product-detail-dialog {
  text-align: center;
  position: relative;
}
.product-detail-dialog {
  md-toolbar {
    padding: 0 29px;
    .md-toolbar-tools {
      margin: 0;
      text-align: left;
      border-bottom: solid 1px #b7b7b7;
      padding: 0;
    }

    h1 {
      margin: 30px 0;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: 0.3px;
      color: #333333;
    }
  }
  .item-code-text {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.1px;
    color: #333333;
    .item-code {
      font-size: 18.5px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: 0.2px;
    }
  }
  .brand-logo-wrapper {

  }
  .eqp-logo-wrapper {
    padding: 0 1em;
    text-align: center;
  }
  td.disclaimer-wrapper {
    .tooltip-container .tooltip {
      left: -122px !important;
    }
    .tooltip-up .tooltip-arrow {
      left: 65%;
    }
  }

}

.add-to-cart-quantity-button {
  width: 250px;
  float: left;
}
.warning-messages {
  //float: left;
  ul {
    li {
      list-style: none;
    }
  }
}

.md-select-value{
  border: none;
}

@media all and (min-width: 960px) {
  .search-results {
    &.full-width {
      .list-item {
        width: 25%;
      }
      .list-flexbox-grid {
        margin: 0;
      }
    }
  }
  .list-flexbox-grid {
    .list-item {
      width: 33.33%;
    }
  }
}
@media all and (max-width: 959px) {
  .search-wrapper {
  }
  .list-flexbox-grid {
    margin: 0;
    .list-item {

      width: 50%;
    }
  }
}
@media all and (max-width: 599px) {
  .btn-filter{
    width: 267px;
  }
  .list-flexbox-grid {

    .list-item {
      width: 100%;
    }
  }
  .search-wrapper .filter-container{
    display:table;

    .filter-by{
      display:table-footer-group;
    }

    .header{
      margin-left: 0px;
      display:table-header-group;
    }

    .align-right{
      float: none;
    }
    .dropdown-container{
      margin-bottom: 10px;
    }
  }
}

.add-to-cart-notice{
  width: 270px;
  margin-top: 34%;
  line-height: normal;
  z-index: 1;
  text-align: center;
  border-radius: 20px;
  background-color: #999;
  color: white;
  padding: 5px;
  bottom: 100%;
  opacity: 0;
  animation-name: popup;
  animation-duration: 4s;
}

.expandable-filter {

}

.filter-expand-button{
  float: right;
  width: 30px;
  height: 30px;
  line-height: 34px;
  text-align: center;
  color: #4399c5;
  cursor: pointer;
}

.category-list{
  md-checkbox{
    div.md-label{
      max-width: 150px;
    }
  }
}

.sub-category-list{
  md-checkbox{
    div.md-label{
      max-width: 150px;
    }
  }
}

@keyframes popup {
  0% {opacity: 0;}
  60% {opacity: 1;}
  100% {opacity: 0;}
}


