#education-emerson {

  ul {
    li.clinical-resources-layout  {
       @media screen and (max-width: 959px) {
          margin: 0;
        }
      h2 {
        font-size: 18px;
        font-weight: 500;
        color: #3592c7;
        border-bottom: 2px solid #c2c2c2;
        text-align: left;
        padding-bottom: 12px;

        @media screen and (max-width: 959px) {
          margin: 0;
        }
        a {
          color: #3592c7;
          font-weight: 500;
        }
        a.education-sub-nav-active{
          color:#000;
          font-weight: 500;
        }
      }
    }
  }

  .education-sub-nav {
    border-top: 1px solid #ddd;
    width: 75%;
    margin: 30px auto 0;
    ul {
      li {
      .list-flex-content {
        padding: 0;
      }
        h2 {
          border-bottom: none;
          margin: 0;
          padding: 0;
        }
      }
    }

    p {
      color: #666;
      text-align: justify;
      margin-top: 30px;
    }
  }
  .login-message {
    font-size: 16px;
    font-weight: 400;
  }
  
  .login-message,
    .alert-message {
      text-align: center;
      margin: 115px 0;
      img {
        vertical-align: bottom;
      }
      a{
        cursor:pointer;
      }
  }
}

#heroedu1{
  top:50%;
}

#heroedu2{
  top:50%;
}

#heroedu3{
  top:50%;
}

.education-sub-nav {
  .level-2-content {
    display: none;
  }
}
.resources-by-health, .resources-by-supplements, .clinical-practice-tools-head{
  font-weight: 300;
  font-size: 19px;
  width: 75%;
  margin: 0px auto 15px;
  padding-bottom: 15px;
}

.clinical-tools{
  border-bottom: 2px solid #ddd;

  h2{
    text-align:left;

    @media screen and (max-width: 599px) {
      margin-left: 10px;
    }
  }
  .clinical-practice-tools{
    border-top: 2px solid #ddd;
    margin: 30px auto 0;

    a {
      color: #3592c7;
    }

    ul {
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      li {
        list-style: none;
        min-height: 70px;
        position: relative;
        p {
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 12px;
          font-weight: 400;
        }
      }
      li:last-child {
        border-bottom: none;
      }
    }
    .table-left-part {
      border-right: 1px solid #ddd;

      ul {
        padding: 0px;
        margin-right: 20px;

        li {
          border-bottom: 1px solid #ddd;
          padding: 10px 0;

          @media screen and (max-width: 599px) {
            padding: 10px;
          }
        }
      }
    }

    .table-right-part {
      ul {
        padding: 0px;
        margin-left: 20px;
        li {
          border-bottom: 1px solid #ddd;
          padding: 10px;
        }
      }
    }

    li.last-child {
      color:red;
    }

  }
.last-clinical-tool{
    ul {
      li:last-child {
      border-bottom: none;
     }
    }
    border-bottom: 2px solid #ddd;
  }
}

.md-tabs-wrapper-margin-top {
  margin: 40px 0;
}

.education-md-tabs-wrapper-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.files-list{
  .file-name{
    padding: 0;
    color: #3592c7;
  }

  .download-img{
    height: 35px;
    max-width: 6%;
  }

}

.tabs-wrapper {
  ul.md-tabs-wrapper-margin-top {
    list-style: none;
    width: 95%;
    margin: 40px auto;
    margin-bottom: 0;
    padding-left: 0;
    text-align: center;

      li.eductaion-tabs {
        border-top: 1px solid #76bd23;
        padding-top: 10px;
        max-width: 174px;
        outline: none;

        &.active_tab {
          border-top: 3px solid #76bd23;
          position: relative;
          top: -2px;
          padding-top: 12px;
          a { color: #000; }

        }

        a {
          width: 64%;
          margin: 0 auto;
          display: block;
          white-space: normal;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          border-top: 0;
          color: #999;

          &:hover {
            text-decoration: none;
          }
        }

      }
  }

}
.clinical-resources-subpage-container{
  width: 100%;
  iframe{
    border : 0;
    height : 1100px;
    width : 100%;
    border-bottom: 1px solid #999;
  }

.tabs-wrapper {
  ul.md-tabs-wrapper-margin-top {
    list-style: none;
    width: 50%;
    margin: 40px auto;
    padding-left: 0;
    text-align: center;

    @media screen and (max-width: 599px) {
      width: 100%;
    }

    li.eductaion-tabs {
      border-top: 1px solid #76bd23;
      padding-top: 10px;
      max-width: 174px;
      outline: none;

      @media screen and (max-width: 599px) {
        padding: 10px 5px;
        max-width: none;
        border-bottom: 1px solid #76bd23;
      }

      &.active_tab {
        border-top: 3px solid #76bd23;

        a {
          color: #000;
          outline: none;
        }
      }

      a {
        width: 64%;
        margin: 0 auto;
        display: block;
        white-space: normal;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        border-top: 0;
        color: #999;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
}

@media screen and (max-width: 959px) {
  .education-md-tabs-wrapper-padding.tab-body-wrapper,
  .education-md-tabs-wrapper-padding .list-flex-item {
    padding-left: 0px;
    padding-right: 0px;
  }
  .tabs-wrapper ul.md-tabs-wrapper-margin-top {
    width: auto;
    margin: 40px auto 0px;
    max-width: 525px;

    li.eductaion-tabs {
      padding-bottom: 10px;
      max-width: none;
    }
  }
}

.clinical-page{
  height:450px;
}

.education-alert-message {
  vertical-align: middle;
  background-repeat: no-repeat;
  padding: 0 0 0 24px;
}
