#support-for-your-practice {
	margin-top: 30px;
}

#support-for-your-practice .emersonicon:before {
	width: 68px;
	height: 68px;
}

#support-for-your-practice .emersonicon-class {
	color: #000000;
}

.icon-list {
	margin: 20px 0 30px;

	.list-inline {
		text-align: center;
	}
}

#support-for-your-practice .media-left {
	padding-right: 50px;
	border-right: dotted 1px #cccccc;
}

#support-for-your-practice .media-right {
	padding-left: 50px;
	padding-top: 20px;
}

.wellevate-tab {
	.wellevate-banner {
		max-width: 991px;
		margin: 30px auto 40px;
		position: relative;

		.wellevate-banner-bg {
			position: relative;
		}

		.wellevate-content {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 1;

			h3 {
				font-size: 24px;
				line-height: 1.2;
				font-weight: 500;
        margin-top: 0;
			}

			p {
				font-size: 16px;
				line-height: 1.4;
				font-weight: 300;
        max-width: 641px;
        margin: 0 auto !important;
			}
		}

		.wellvate-logo {
			text-align: center;
		}

		.md-button {
			min-width: 150px;
		}
	}
}

#wellevate .media-body h3 {
	font-size: 18px;
	font-weight: 300;
}

.quality-tab {
	.quality-section {
		max-width: 780px;
		margin: 30px auto 40px;
	}

	.quality-badge {
		img {
			width: 100%;
			max-width: 344px;
		}
	}

	.media {
		.img {
			float: left;
			max-width: 242px;
			margin-right: 20px;

			img {
				width: 100%;
			}
		}

		.content {
			overflow: hidden;
			ul {
				color: #333333;
				@media (max-width: 599px){
					font-size : 14px;				
				}
			}
		}
	}

	.button-wrapper {
		margin: 20px auto;
	}

	.md-button {
		min-width: 164px;
	}

	h2 {
		font-size: 24px;
		line-height: 1.2;
	}
}

.education-tab {
	.education-section {
		max-width: 850px;
		margin: 30px auto 40px;

		.layout-row:first-child {
			max-width: 641px;
			margin: 0 auto;

			p {
        margin-top: 0;
				font-size: 18px;
				font-weight: 300;
				line-height: 1.3;
				letter-spacing: 0.2px;
			}
		}

		.unit {
			p {
				min-height: 60px;
			}
		}
	}

	h3 {
		font-size: 16px;
		font-weight: bold;
	}

	p {
		font-size: 14px;
		line-height: 1.3;
	}

	.thumbnail {
		img {
			width: 100%;
			max-width: 230px;
		}
	}

	.unit {
		position: relative;
		padding-bottom: 55px;

		.button-wrapper {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
}

#support-for-your-practice md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
	min-height: 640px;
}

.quality-symbol {
	text-align: right;
	padding-right: 30px;
	margin-top: 95px;
}

.quality-head,
.quality-head h2,
.quality-items {
	text-align: left;
}

.quality-head p {
	font-size: 18px;
	width: 80%;
	font-weight: 300;
}

.quality-head a.md-button {
	width: 170px;
	margin: 6px 16px 6px 0;
}

.quality-item {
	padding: 20px;
}
/*layout-sm*/
@media (max-width: 959px) {
	#support-for-your-practice {
		.media-right {
			float: left;
			padding: 0;
		}

		.wellevate-tab .media-left {
			border-right: 0;
			padding-right: 0;

			img {
				width: 100%;
			}
		}
	}
}
@media (max-width: 768px) {
	.wellevate-tab {
		.wellevate-banner {
			.wellevate-banner-bg {
				img {
					margin-left: -13%;
				}
			}
		}
	}
}
/*layout-xs*/
@media (max-width: 599px) {
	#support-for-your-practice {
		.tabs-wrapper {
			margin-left: -15px;
			margin-right: -15px;
			margin-top: 20px;
		}

		md-pagination-wrapper {
			width: 100%;
		}

		.md-tab .ng-scope {
			line-height: normal;
		}

		md-tabs-canvas {
			height: 130px;
		}

		.brand-item-list .product-summary {
			width: 100%;
		}

		.brand-item-list .product-summary .md-button {
			margin: 10px auto;
		}
	}

	.quality-symbol {
		text-align: center;
		padding-right: 0;
		margin-top: 45px;
	}

	.quality-head {
		padding: 8px;
	}

	.quality-head p {
		font-size: 20px;
		width: 100%;
	}

	.quality-head a.md-button {
		width: 170px;
		margin: 6px 8px 6px 0;
	}

	.wellevate-tab .media-body h3 {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
	}

	.wellevate-tab {
		.wellevate-banner {
			.wellevate-content {
				padding: 20px;
			}
		}
	}

	.quality-tab {
		.quality-section {
			margin: 30px 20px;
		}

		.media {
			.img {
				float: none;
				max-width: 242px;
				margin: 0 auto;

				img {
					width: 100%;
				}
			}

			.content {
				overflow: hidden;
			}
		}

		.button-wrapper {
			margin: 20px auto;
		}

		.md-button {
			min-width: 164px;
		}

		h2 {
			font-size: 20px;
		}
	}

	.education-tab {
		.education-section {
			margin: 30px 20px;

			.layout-row:first-child {
				p {
					font-size: 16px;
				}
			}
		}

		.unit {
			position: relative;
			padding-bottom: 20px;

			.button-wrapper {
				position: relative;
				bottom: 0;
				width: 100%;
			}
		}
	}
}
@media (max-width: 320px) {
	.wellevate-tab .media-body h3 {
		width: 89%;
		margin-left: auto;
		margin-right: auto;
	}

	div.quality-head.ng-scope.flex-xs-100.flex-70,
	div.quality-symbol.ng-scope.flex-xs-100.flex-30 {
		flex: 1 0 auto;
	}
}
.wellevate-iframe iframe {
	border : 4px solid white;
	box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
@media (max-width: 599px) {
	.wellevate-iframe iframe {
		width : 100%;
	}
}
