#tap-integrative {
  padding: 10px;
  margin-bottom: 30px;  
  
  .tap-integrative-header {
    padding-left: 10px;
     h3 {
      margin: 0 30%;
      @media screen and (max-width: 959px) {
        margin: 0 auto;
      }
    }
  }

  .tap-integrative-image {
    padding: 10px;
    img {
        width: 100%;
      }
  }
  .tap-integrative-content-level1 {
    padding: 10px;
    a.become-member {
      color: #3592c7;
      text-decoration: underline;
    }
  }

  .tap-integrative-header_level2 {
    h3 {
      margin: 30px auto; 
    }
  }

  .tap-content-header {
    margin-top: 15px;
    h5 {
      font-size: 18px;
      margin: 0 auto;
    }
  }

  .table-left, .table-right {
    p {
      text-align: center;
    }
  }

  .education-table-container {
    margin: 0 auto 0;
    ul li {
      list-style: none;
      height: 27px;
      padding: 20px;
    }  
  }

}

.tap-video {
  video {
    width: 100%;
    height: auto; 
  }
}

.tap-audio {

}

md-dialog.tap-media-dialog {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  width: 80%;
  height: auto;

} 