.eqp-partners{
	border-bottom: 1px solid #ddd;
	h2{
		text-align:left;
	  	font-weight: 400;
	  	padding-top: 40px;
	}
}
.eqp-icon-size{
  width: 7%;
}
.eqp-icon-height{
  height: 50%;
}
.eqp-gold-partners{
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	.view-more{
	  border-bottom: 1px solid #ddd;
	  padding: 20px 0 60px 0;
	  margin-bottom: 30px;
	  a{
	    color: #3592c7;
	    font-weight: 500;
	    font-size: 16px;
	    &:hover{
	      cursor: pointer;
	    }
	  }
	}
	h4{
	  margin:0;
	  font-weight: 400;
	}
	h3{
	  text-align:left;
	  font-weight:300;
	  padding-left: 50px;
	}
	h2{
	  text-align:left;
	}
}
.gold-partners-content{
	text-align:left;
	border-bottom: 1px solid #ddd;
	padding: 25px 0 25px 50px;
	.gold-partner-img{
	  padding-right:5px;
	  margin-right:5px;
	  img {
	    width:90%;
	  }
	}
	.gold-partners-details{
	  padding-left: 5px;
	  padding-right: 5px;
	  margin: 0 5px 0 5px;
	  .ngTruncateToggleText{
	  	font-size:15px;
	  	display:block;
  	    padding-top: 10px;
	  }
	}
	.gold-partners-head{
	  font-weight:500;
	}
	.gold-partners-description{
	  font-weight:300;
	  margin:0;
	}
	.gold-partners-products{
	  font-size: 16px;
	  font-weight: 500;
	  color: #3592c7;
	  padding-left:5px;
	  margin-left:5px;
	  a{
	    color: #3592c7;
	  }
	}
}
