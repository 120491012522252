#order-filter-contianer {
	margin: 15px 0;

	h2 {
		margin: 10px 0;
	}
	.time-period {
		margin: 25px 0 10px 0;
	}
	md-input-container {
		margin: 18px 0 10px 0;
		height: 30px;
	}
	a {
		color: #4399c5;
	}
}

.time-period {
	li {
		display: inline-block;
		margin-bottom: 0;
    	list-style: none;
    	border-right: 1px solid #999999;

    	&:last-child {
    		border-right: 0;
    	}

    	a {
    		padding: 0 10px;
    	}
	}
}
.view-more-btn {
	margin: 20px 0;
	display: inline-block;
	padding-left: 15px;
	line-height: 1;

	&:before {
		background-image: url('/assets/images/view-more-arrow.png');
    	background-repeat: no-repeat;
    	content: '';
    	width: 12px;
    	height: 6px;
    	position: absolute;
    	left: 0;
	}
}


