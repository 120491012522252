
.ask-our-expert-h2 {
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  color: #333333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 25px;
}

.info-expert {
  text-align: center;
}
.img-circle {
  border-radius: 50%;
  width: 67%;
  display: block;
  margin: 0 auto 10px;
}

.ask-our-expert-container {
  width: 75%;
  margin: 60px auto 0;
  div{
    text-align: center;
  }
  @media screen and (max-width: 959px) {
    width: 100%;
    margin: 57px auto 0;
  }
}

.ask-our-expert-img-href {
  text-decoration: none;
  color: #5DADE2;
  font-weight: 500;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;

  &:hover { text-decoration: none; }

}

.update-meet-our-team-header-div {
  width: 100%;
  padding-bottom: 40px;
  padding-top: 25px;
}

.meet-our-team-img {
  width: 100%;
}

.meet-our-team-content {
  margin-left: 30px;
  vertical-align: top;
  border-bottom: 1px solid #ddd;
}

.meet-our-team-h3 {
  margin-top: 0;
  margin-bottom : 0;
  font-size: 24px;
  font-weight: 500;
}

.meet-our-team-content-div {
  margin-bottom: 55px;
}

.meet-our-team-content-p {
  width: 100%;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.showmore-href {
  text-decoration: none;
  color: #5DADE2;
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  &:hover {
    text-decoration: none;
  }
}

.ask-quest-topic-td,
.ask-quest-date-td,
.ask-quest-subject-td {
  border:none;
  text-align: left;
  padding: 15px 0;
  color : #626567;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  width : 30%;
  @media screen and (max-width: 959px) {
    font-size: 13px;
    padding: 15px 4px;
  }
}

.ask-quest-date-td{
  width : 20%;
}
.ask-quest-subject-td {
  width : 50%;
}

.type-your-qust-input {
  width: 80%;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #C0C0C0;
  background: #F8F8F8;
  &:focus{
    height: 100px;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #669933;
    font-size: 25px;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #669933;
    font-size: 25px;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #669933;
    font-size: 25px;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #669933;
    font-size: 25px;
  }
  @media screen and (max-width: 959px) {
    width: 100%;
    height: 100%;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #669933;
      font-size: 10px;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #669933;
      font-size: 10px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #669933;
      font-size: 10px;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #669933;
      font-size: 10px;
    }
  }
}

.type-your-qust-container{
  background: #F8F8F8;
  width: 71%;
  margin: 50px auto;
  @media screen and (max-width: 959px) {
    width: 100%
  }
}

.type-your-qust-wrapper{
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding: 55px 0 65px;
  @media screen and (max-width: 959px) {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 36px 0 20px;
  }
}

.type-your-qust-submit{
  float: right;
  vertical-align: bottom;
  background-color: transparent;
  border: none;
  color: #669933;
  font-size: 18px;
  cursor: pointer;
  margin-right: 32px;
  margin-top: 7px;
  @media screen and (max-width: 959px) {
    float: none;
    font-size: 14px;
  }
}

.ask-our-expert-pagination-href {
  text-decoration: none;
  color: #5DADE2;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  &:hover { text-decoration: none; }
  &.disable{
      color:#ddd;
      cursor: default;
    }
}

.ask-our-expert-pagination-href-p {
  text-decoration: none;
  color: #5DADE2;
  &.active { color:#000}

}

.ask-our-expert-arrow-left{
  transform: rotate(90deg);
  width: 130%;
}

.ask-our-expert-arrow-right{
  transform: rotate(-90deg);
  width: 130%;
  cursor:pointer;
}

.ask-quest-img-td {
  vertical-align: top;
}

.ask-quest-td-img-more {
  width:30px;
   &.active { transform: rotate(-180deg); }
}

.ask-quest-span-text{
  font-weight: bold;
}

.ask-quest-table-div {
  padding: 0;
}

.disclaimer-content-p {
  color: #666;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}

.disclaimerContent-head {
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.disclaimerContent-dot {
  color: #666;
  text-decoration: none;
}

.expert-title {
  font-size: 14px;
  display: block;
  margin: 5px 0;
}
.expert-title-bold{
  font-weight : bold;
}
@media only screen and (max-width: 800px) {
  .author-table{
  .elem-box-sizing(border-box);
    *{
      .elem-box-sizing(border-box);
    }
    tr{
      display: block;
      position: relative;
    }
    td{
      display: inline-block;
    }
    .ask-quest-img-td {
        width: 100%;
        text-align: right;
      }
    }
  .author-table
  .ask-quest-date-td,
  .ask-quest-topic-td {
      width: 49%;
  }
  .ask-quest-subject-td {
    width: 100%;
    padding-top: 10px;
    p {
      margin-top: 0;
    }
  }
}

body.md-toast-animating {
  overflow: auto !important; //To override the md-toast-animating overflow hidden important of angular-material.css
}
