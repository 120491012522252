// mobile css at the bottom
item-review-card > .item-review-card {
  background-color: #f8f8f8;
  padding: 12px 26px 12px 6px;
  margin-top: 15px;
  min-height: 100px;

  &.item-removed-row {
    display: flex;
    flex-flow: column;
    justify-content: center;

    h2 {
      margin: 0;
      font-size: 30px;
    }
  }

  img {
    height: 130px;
  }

  h3 {
    font-weight: 500;
    font-size: 22px;
  }

  .header-item {
    margin: 0;
    margin-bottom: 10px;
    text-align: left;
  }

  .price-and-remove-wrapper {
    align-self: center;
    display: flex;
    flex-flow: column;
    align-items: center;

    .total-price-wrapper {
      margin-left: 15px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .header-item {
        font-size: 26px;
        margin-bottom: 2px;
      }

      a {
        font-size: 14px;
        color: rgba(0, 0, 0, .54);
      }
    }
  }

  .item-warnings {
    max-width: 370px;

    p {
      color: hsl(0, 55%, 40%);
      font-size: 14px;
      margin: 10px 0 0 0;
    }
  }

  .md-icon-button {
    color: rgba(0,0,0,.54);
  }

  @media (max-width: 768px) {
    padding: 8px 8px 4px 8px;

    .price-and-remove-wrapper {
      background-color: #f8f8f8;
      margin-top: 8px;
      display: block;
    }

    .image-wrapper > img {
      height: 125px;
      margin-left: -19px;
    }

    h3 {
      font-size: 18px;
    }

    &.item-removed-row h2 {
      font-size: 24px;
    }

    .total-price-wrapper[hide-xs] {
      display: none; // need this to overwrite "display: flex" on mobile
    }

    .total-price-wrapper[show-xs] {
      margin-top: 10px;

      .header-item {
        font-size: 27px;
        margin-bottom: 0;
        width: 135px;
        text-align: center;
      }
    }
  }
}
