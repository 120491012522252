.md-toolbar-tools {
  display: block;
  text-align: center;

  h2 {
    margin: 20px;
  }

  .md-close {
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      width: 30px;
        height: 30px;
        color: #000;
        display: block;
    }

  }
}

#brand-agreements {
  md-checkbox {
    margin-bottom: 0;
  }
  md-checkbox .md-icon {
    border: 0;
  }
  md-checkbox.md-default-theme[disabled].md-checked .md-icon,
  md-checkbox[disabled].md-checked .md-icon {
    background-color: #ffffff;
    border: 0;
  }
  md-checkbox.md-default-theme[disabled].md-checked .md-icon:after,
  md-checkbox[disabled].md-checked .md-icon:after {
    border-color: #333333;
  }
  td {
      padding: 20px 15px 20px 0;
  }

  a {
    outline: 0;
  }

}

.brand-agreement-dialog {
  md-dialog-actions .md-button {
    margin: 0;
      padding: 10px 15px;
      min-height: 0;
      min-width: 0;
  }
  .agreement-actions {
    min-height: 22px;
    text-align: center;
  }
  .md-toolbar-tools h2 {
    font-size: 32px;
      font-weight: 300;
      border-bottom: solid 1px #b7b7b7;
      margin: 0;
      padding: 30px 20px;
  }
  md-dialog-content {
    margin: 20px 80px;

  }
  .md-toolbar-tools {
      padding: 0px 80px;
  }
  .md-dialog-content {
      padding: 0;
  }
  md-dialog-actions {
    border-top: solid 1px #b7b7b7;
    margin: 0 80px;
    padding: 40px 0;
  }
  .md-button.btn-agree {
    background-color: #76bd23;
      color: #ffffff;
      border-radius: 0;
      min-width: 115px;
  }
  .md-dialog-content {
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .right-border {
    border-right: solid 1px #b7b7b7;
    margin-right: 15px !important;
  }
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 959px) {

  #brand-agreements {

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      position: relative;
      padding: 10px 0;
    }

    td {
      padding: 5px 15px 5px 0;
      flex: 1 0 auto !important;
    }

    .label {
      font-size: 12px;
      color: #999999;
    }

    td:nth-of-type(2) {

      a {
        width: 100%;
        display: block;
        position: relative;
        font-weight: 700;
        outline: 0;

        &:after {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          content: ">";
        }
      }
    }
    td:nth-of-type(3) {
      position: absolute;
        right: 0;
        top: 39px;
    }
    tr.false {
      td:nth-of-type(1),
      td:nth-of-type(3),
      td:nth-of-type(4) {
        display: none;
      }
    }
    tbody {
      border-top: solid 1px #999999;

      tr:last-child {
        border-bottom: solid 1px #999999;
      }
    }


  }

  .brand-agreement-dialog {
    md-dialog-actions {
      margin: 0;
      padding: 0;
      min-height: 30px;
      position: absolute;
      background-color: #ffffff;
        bottom: 0;
        width: 100%;

        .agreement-actions {
          width: 100%;

          .btn-agree,
          .btn-cancel {
            width: 50%;
            margin-right: 0 !important;
            border: 0;
            box-sizing: border-box;
            display: block;
            float: left;
          }
        }
    }
    .md-toolbar-tools h2 {
      padding: 15px 20px;
    }
    md-dialog md-toolbar,
    md-toolbar .md-toolbar-tools {
      min-height: 55px;
    }
  }

  .brand-agreement-dialog md-dialog {
    max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%;
  }
  .brand-agreement-dialog .md-toolbar-tools h2 {
    font-size: 20px;
  }
  .brand-agreement-dialog .md-toolbar-tools {
    padding: 0 20px;
  }
  .brand-agreement-dialog md-dialog-content {
      margin: 20px 20px 50px 20px;
  }

}
