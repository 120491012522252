/* Styling for Health Condition List */
ol.healthConditionList {
  list-style: none;
  padding: 0;

li{
	display: inline-block;
	padding: 0 30px;
	text-transform: uppercase;
	&:first-child { padding-left: 0; }
}

}

input[type="search"]{
  float: right;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  height: 100%;
  width: 80%;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  	color: #3592c7;
  }

  &::-moz-placeholder { /* Firefox 19+ */
  	color: #3592c7;
  }

  &:-ms-input-placeholder { /* IE 10+ */
  	color: #3592c7;
  }

  &:-moz-placeholder { /* Firefox 18- */
  	color: #3592c7;
  }

  &:focus{
  	border: 0;
  	outline: 0;
  	border-bottom: 1px solid #d7d7d7;
  }
}

.resource-h2::after { 
  content: ">";
  color: #c2c2c2;
  float: right;
  font-weight: 500;
}

.clinical-resource-sub-content-p {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size:18px;
  @media screen and (max-width: 959px) {
    margin-top:10px;
  }
}

.update-sub-head-h2 {
  font-weight: 400;
  font-size: 32px;
  padding-top: 10px;
  text-align: center;
}

.education-patners-sub-content-p {
  margin: 0;
}

.clinical-update-sub-page-head {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size:18px;
}
.clinical-tools .clinical-practice-tools a.practice-tools-pdf span{
  background:url("../../../assets/images/icons/download.png") no-repeat;
  background-position: right;
  display : block;
  width: 25px;
  height: 25px;
  float: right;
}

#resources-by-supplements{
  md-pagination-wrapper{
    width: 100% !important; /* To override md-pagination-wrapper width which is coming from angular material */
  }

  @media screen and (max-width: 959px) {
    md-tabs-canvas{
      width:60%;
    }
  }

  @media screen and (min-width: 959px) {
    md-tab-item.md-tab{
      width: 50%;
    }
  }

  @media screen and (max-width: 959px) and (orientation: landscape){
    md-tabs-canvas{
      width:67%;
    }
  }

  @media screen and (max-width: 599px) {
    md-tabs-content-wrapper{
      margin-top: 50px;
    }
  }
} 
@media only screen and (max-width:959px) {
  .clinical-tools .clinical-practice-tools a.practice-tools-pdf span{
    position: absolute;
    right: -4px;
    top: 3px;
    width: 22px;
    height: 27px;
    background-size: contain;
  }
}