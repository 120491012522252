.payment-info {
  max-width: 796px;
  padding-top: 30px;
  
  .paymeninfo-title {
    font-size: 16px;
    font-weight: 300;
    color: #333;
    margin: 0;
  }

  p {
    padding: 7px 0;
  }

  .please-enter-msg {
    margin-bottom: 10px;
  }

  .existing-card {
  	font-size: 16px;
  	font-weight: 500;
  	line-height: 0.9;
  	letter-spacing: 0.2px;
  	text-align: left;
  	color: #4399c5;
    margin: 0;
    border: 1px solid #4399c5;
    border-radius: 4px;
    background-color: inherit;

    .md-select-value {
        height: 36px;
        text-align: center;
        padding: 0 10px;
    }

    &:not([disabled]):focus .md-select-value {
      padding-bottom: 0;
      border-bottom-width: 0;
    }
  }

  .or-text {
    height: 38px;
    line-height: 38px;
  }

  #addNewCCBtn {
    font-size: 15px;
    box-shadow: none;
    width: 100%;
    text-align: center;
  }

  .info-section-title {
    font-weight: normal;
    font-size: 16px;
    margin: 25px 0 0 0;
  }

  .info-cards-wrapper {
    margin-bottom: 50px;

    .credit-info-card {
      margin-bottom: 10px;
    }

    md-input-container {
      margin-left: 15px;
    }

    .tooltip-wrapper a {
      margin-top: 28px;
    }

    .ccexpired-error {
      margin-left: 12px;
      margin-top: -25px;
    }
  }

  .actions-row {
    margin: 15px 0;
  }

  #isDefaultPaymentCheckbox {
    display: block;
    margin: -12px 0 40px 16px;
  }

  .disabled-existing-card {
    pointer-events: none;
  }

  .checkbox-card-type {
    span {
      padding-left: 10px;
      color: #333333;
      font-weight: 400;
    }
  }
  .checkbox-set-default {
    margin-left: 30px;
    padding-top: 30px;
    md-checkbox.md-square {
      .md-label span {
        font-size: 16px;
        font-weight: 300;
        line-height: 0.78;
        text-align: left;
        color: #333333;
      }
      .md-icon {
        border-radius: 0px;
        border: 1px solid #333333;
      }

    }
  }
  .info-required {
    float: right;
    font-size: 14px;
    line-height: 1.7;
  }
  .creditcard-img {
    float: right;
    width: 180px;
  }
}
.card-info {
  max-width: 796px;
  .info-title {
    padding: 20px 0 10px 0;
    font-size: 14px;
  	line-height: 2.0;
    .info-sub-title {
      font-size: 16px;
    	line-height: 1.5;

    }
  }
  .card-info-section {
    p {
      padding: 0 0 5px 0;
    }

    .select-exist-address {
      width: 265px;
      margin: 0 0 20px 0;
      background-color: #f6f6f6;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      font-size: 16.3px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 0.9;
      letter-spacing: 0.2px;
      text-align: left;
      color: #4399c5;
      img {
        margin-left: 5px;
        margin-top: 2px;
      }
    }

    md-select {
      .md-select-value {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
      }

      &:not([disabled]):focus .md-select-value {
        padding-bottom: 0;
      }
    }

    .margin-right-35 {
      margin-right: 35px;

      @media (max-width: 768px) {
        margin-right: unset;
      }
    }

    #nameOnCardInputContainer {
      margin-left: 20px;
    }

    .same-as-shipping{
      padding-top: 20px;
    }


    // .select-exist-address {
    // 	font-size: 16px;
    // 	font-weight: 500;
    // 	line-height: 0.9;
    // 	letter-spacing: 0.2px;
    // 	text-align: left;
    // 	color: #4399c5;
    //   .md-select-value {
    //       height: 39px;
    //       background-color: #f6f6f6;
    //       padding: 0 10px;
    //       box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    //
    //   }
    // }
    .sub-title {
      font-size: 16px;
      line-height: 1.5;
    }
    .tooltip-wrapper {
      a {
        margin-top: 30px;
        outline: none;
      }
    }

  }

  /*credit card*/
  md-dialog {
    credit-card-form {
      md-input-container {
        margin-right: 20px;
      }


    }
    &.ccForm {
    	.md-button.md-submit {
      	min-width: 200px;
      	display: block;
      	margin: 0 auto;
      }
      .mtn {
      	margin-top: 0 !important;
      }
    }
  }
  .save-card-wrapper {
    margin-bottom: 40px;
    // .md-label span {
    // padding-left: 15px;
    // }
    md-checkbox.md-square {
      .md-label span {
        // padding-left: 15px;
        font-size: 16px;
        font-weight: 300;
        line-height: 0.78;
        text-align: left;
        color: #333333;
      }
      .md-icon {
        border-radius: 0px;
        border: 1px solid #333333;
      }

    }
  }
}
@media only screen and (max-width: 960px)  {
  .payment-info {
    .disabled-existing-card {
      background: rgba(153, 153, 153, 0.11);
    }
    max-width: 100%;
    .checkbox-card-type {
      span {
        font-weight: 300;
      }
    }
    .checkbox-set-default {
      margin-left: 0;
      padding-top: 0;
    }
    .creditcard-img {
      float: right;
      width: 170px;
      padding-top: 12px;
    }

  }
  .card-info {
    max-width: 100%;
    .info-title {
      .info-sub-title-desc {
        float: right;
      }
    }
    .card-info-section {
      md-input-container {
        padding: 8px 0px;
      }
      .select-exist-address {
        width: 100%;
        padding-top: 3px;
        img {
          float: right;
          margin-top: -2px;
        }
      }

      #new-address{
        padding-left: 25px;
      }


      #nameOnCardInputContainer {
        margin-left: 0;
      }

    }
    .tooltip-wrapper {
      a {
        .tooltip {
          text-align: left;
          height: auto;
          font-size: 12px;
          left: -65px;
          bottom: 25px;
          min-height: 50px;
          width: 120px;
          span {
            word-break: break-all;
            white-space: initial;
          }
        }
      }
    }
  }
}
@media (max-width: 599px) {
.cvv{
  display: inline-block !important;
  width: 93%;
}
  .tooltip-mob {
    display: inline-block !important;
    width: 5% !important;
  }
  .card-info .tooltip-wrapper a .tooltip {
    left: -278px !important;
    bottom: 26px !important;
    width: 283px !important;
  }
   .tooltip-container .tooltip-up .tooltip-arrow {
        left: auto;
        right: 3%;
      }
}
.ccexpired-error,
.custom-message{
  color : #cc3366;
  font-size : 14px;
  padding:5px;
  font-weight: bold;
 }
.ccexpiry-month,.ccexpiry-year{
  display : inline-block;
  md-select{
    md-select-value{
      min-width:104px;
    }
  }
}
.selectpayment{
  font-size: 16px;
  text-align: left;
  padding: 10px 0;
}