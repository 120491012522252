.loading-spinner{
  width:100%;
  text-align: center;
  md-progress-circular{
    margin:auto;
  }
}

.eqp-logo-wrapper {
  div{
  position: absolute;
right: -2.5em;
top: 20px;
.eqp-seal{
width:50%
}
}
  padding: 0 1em;
  text-align: center;

}
.product-detail-wrapper {

  padding: 0 5%;

  color: #333333;
  text-align: left;
  .pos-relative{
    position: relative;
  }

  .full{
    width:100%;
    float:left;
  }

  .readyTop{
    text-align: left;
    width: 96%;
    float: left;
    bottom: 0px;
    position: relative;
    font-size: 1.3em;
    margin: 0px 1% 0 0;
    span{
      margin: 0 5px;
    }
    .warning-messages.warning-messages-pdp {
      div{
        padding: 0;
        p{
          color: #E00603;
          margin-top: 0px;
          font-size: 16px;
          span{
            color: #666666;
            font-size: 13px;
            margin: 0px;
          }
        }
      }
      .green-warning-messages{
        p{
        color: green;
        }
      }

      ul {
        padding:0;
        li {
          font-size: 16.5px;
          p{
            padding-left: 60px;
          }
         img {
          vertical-align: middle;
          margin: 5px 10px 5px 0;
          height: 38px;
          width: 38px;
        }
        }
      }
    }
    ul {
      padding:0;
      li {
        font-size: 16.5px;
        p{
          padding-left: 60px;
        }
       img {
        vertical-align: middle;
        margin: 5px 10px 5px 0;
        height: 38px;
        width: 38px;
      }
      }
    }
    h4{
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 16.5px;
      img {
        vertical-align: middle;
        margin: 5px 10px 5px 0;
        height: 38px;
        width: 38px;
      }
    }
  }


  .main-tab {
    width: 100%;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
    padding: 10px 0 10px 0;
    h1 {
      font-size: 31px;
      font-weight: 400;
      padding: 10px 0;
      color: #666;
    }
    .greenbdr{
      float: left;
      width: 11%;
      border: 2px solid #76bd23;
      padding: 0;
      margin: 0;
    }
    .nav-map-wrapper {
      color: #727272;
      padding-top: 5px;
      padding-bottom: 10px;
      .nav-map {
        font-size: 11px;
        line-height: 1.0;
        letter-spacing: 0.2px;
        color: #727272;
        padding: 0 2px;
        font-weight: 500;
        text-transform: capitalize;
        text-decoration: none;
      }
      span {
        font-size: 11px;
      }
      span.item-name {
        color: #666;
        font-weight: 500;
      }
    }
    .item-code-text {
      font-size: 14.2px;
      color: #666;
      line-height: 2.1;
      letter-spacing: 0.1px;
      white-space: nowrap;
      margin: 0px;
      padding-left:10px;
    }
    .item-code {
      font-size: 18.5px;
      font-weight: 500;
      line-height: 1.31;
      letter-spacing: 0.2px;
      padding-top: 0;
      display: block;
      color: #5d5d5d;
      text-transform: uppercase;
    }
    .share-link-section {
      float: right;
      padding-top: 15px;
      text-align: center;
      a {
        float: right;
        .share-link-wrapper {
          padding-top: 7px;
          width: 60px;
          height: 52px;
          border-radius: 10px;
          background-color: #fff;
          border: solid 1px #999999;
          text-align: center;
          position: relative;
          span {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 12.3px;
            font-weight: 300;
            line-height: 1.54;
            letter-spacing: 1.2px;
            color: @link-color-green;
            position: absolute;
            bottom: 5px;
            left: 15%;
          }
        }
      }
    }

  }

  .line-divider {
    border-bottom: solid 1px #b7b7b7;
    margin-bottom: 30px;
  }

  .main-content {
    padding: 0 0 20px 0;
    .left-content {
      padding-right: 15px;
      background-color: #fff;
      .item-image {
        padding: 10px 0;
        text-align: center;
        position: relative;
        .main-image-wrapper {
          .main-image {
            width:100%;
            overflow-x:hidden;
            position:relative;
            .large {
              width: 100%;
              max-width: 358px;
            }

          }
        }

        .thumbs-nail-wrapper {
          overflow-x: hidden;
          height:70px;
          margin:10px auto;
          .thumb-wrapper {
            float:left;
            display: inline-block;
            ul {
              padding: 0;
              margin: 0 5px;
              li {
                outline: none;
                list-style-type: none;
                border-radius: 10px;
                border: solid 1px #cccccc;
                height: 50px;
                &.thumbnail {
                  &:active, &:focus, &:hover {
                    border: solid 1px @secondary-color;
                  }
                  img {
                    height: 40px;
                    width: 40px;
                    padding: 5px;
                  }

                }
              }
            }
          }
        }

      }
      .item-warning {
        border-top: solid 1px #b7b7b7;
        padding: 30px 0 20px 0;
        .item-warning-block{
          & > div{
            max-width:50%;
          }
          .item-warning-icon{
            height:30px;
            position: relative;
            overflow: hidden;
            text-align: center;

            & > img{
              margin: 0 auto;
              display: block;
              position: relative;
              top: -25%;
              bottom: -25%;
            }

          }
        }
        .warning-text {
          padding-top: 5px;
          font-size: 12px;
          line-height: 1.33;
          color: #000000;
          text-align: center;
        }
      }
      .item-tags {
        padding-top: 20px;
        border-top: solid 1px #b7b7b7;
        &:after, &:before{
          content: " ";
          display: table;
        }
        &:after{
          clear: both;
        }
        li {
          padding: 1%;
          list-style-type: disc !important;
          float: left;
          width: 48%;
          &:last-child {
            margin: 0 0 2em;
          }
        }
        .md-chips {
          padding: 0 0 8px 0;
          display: inline-block;
          md-chip {
           color: #000000;
           background-color: #cccccc;
           border-radius: 10px;
           margin: 3px 10px 0 0;
           padding: 0 15px;
         }
        }
      }
      .item-tags.title {
        text-align: center;
        color: #000;
        font-weight: 500;
      }
      .item-feature {
        float: left;
        .item-feature-list {
          margin: 0;
          padding: 0;
          li {
            list-style-type: none;
            float: left;

            span {
              text-transform: uppercase;
              height: 23px;
              line-height: 23px;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 1.2px;
              color: #ffffff;
              padding: 0 0 0 10px;
              width: auto;
            }

            &.featured {
              span {
                background-color: @secondary-color;
                display: block;
                position: relative;

                &:after {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 24px 24px 0 0;
                  border-color: @secondary-color transparent transparent transparent;
                  position: absolute;
                  content: '';
                  top: 0;
                  right: -23px;
                  z-index: 1;
                }
              }
            }
            &.new {
              span {
                background-color: #666666;
                display: block;
                padding-left: 23px;
                position: relative;

                &:after {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 24px 24px 0 0;
                  border-color: #666666 transparent transparent transparent;
                  position: absolute;
                  content: '';
                  top: 0;
                  right: -23px;
                  z-index: 1;
                }
              }
              &.first-item {
                span {
                  padding-left: 10px;
                }
              }

            }
          }
        }
      }
    }
    .right-content {
      padding: 0 0 0 20px;
      position: relative;
      .chat-slot{
        margin-top:3px;
        margin-bottom: 6px;
        background-color:#fafafa;
        padding:10px;
        text-align: center;
        font-size: 13px;
        float: left;
        width: 100%;
        box-sizing: border-box;
        h3{
            font-size:16px;
            font-weight: bold;
            margin: 5px;
            padding: 0px;
        }
        p{
            margin: 8px 0;
            padding:0px;
        }

    }
      .product-select-item{
        color: #56aa31;
        min-width:50%;
        border: 1px solid #b7b7b7;
        border-radius: 14px;
        font-size: 14px;
        padding: 2px 20px 2px 7px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        background:transparent url('/assets/images/view-more-arrow.png') no-repeat right 7px center;
        &:focus{
          outline: none;
        }
        &.disabled{
          background:none;
        }
      }

      .brand-logo-wrapper {
        color: #333333;
        padding-right: 10px;
        font-size: 14px;
        text-align: center;
        .brandName-text {
          display: block;
          font-size: 18px;
          text-transform: capitalize;
        }
      }
      .item-description-wrapper {
        margin-top: 15px;
        .item-description-text {
          letter-spacing: 0.32px;
          font-stretch: normal;
          font-weight: normal;
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          padding-top: 25px;
          font-family: 'Roboto Regular',  sans-serif;
          &.item-desc-hidden {
            padding-bottom: 25px;
            max-width: 300px;
            white-space: nowrap;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            * {
              display: inline;
            }
            img,
            br {
              display: none;
            }
          }
        }
        .change-desc-limit {
          font-weight: 500;
          color: #4399c5;
          display: inline-block;
          vertical-align: middle;
        }
        .limit-less-btn {
          margin-bottom: 25px;
        }
        .tabs-container {
          margin-top: 15px;
          .tabs {
            padding: 0;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-top: solid 4px transparent;
            margin-bottom: 5px;
            li {
              display: block;
              width: 100%;
              border-top: solid 1px @primary-color;
              list-style: none;
            }
            a {
              width: 100%;
              height: 100%;
              display: block;
              font-weight: 500;
              letter-spacing: 0.2px;
              text-align: center;
              color: #999999;
              &:hover {
                text-decoration: none;
                cursor: pointer;
              }
              &:active, &:focus {
                color: #000;
              }
            }

          }
          .selected-section {
            border-top: solid 4px @primary-color;
            a {
              color: #000;
            }
          }
        }
        .specification-container {
          text-align: left;
          padding: 10px 0;
          margin-bottom: 10px;
          border-bottom: solid 1px #999999;
        }


      }
      .link-section {
        border-top: solid 1px #999;
        padding-top: 20px;
        margin-top: 20px;
        display: inline-block;
        a {
          color: @link-color-green;
          text-decoration: none;
          &:hover{
            color: @link-color-green;
            text-decoration: underline;
          }
        }
        .ask-dr-link {
          display: inline-block;
          a {
            text-decoration: none;
            color: #693;
            &:hover{
            color: black;
            text-decoration: underline;
            }
          }
        }
      }
      .item-price-wrapper {
        padding: 5px 0 10px 0;
        p {
          .your-price {
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            padding-left: 5px;
            vertical-align: -5px;
          }
          .list-title {
            padding-left: 5px;
          }
        }

      }
      .non-logged-in {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        text-align: left;
        text-transform: uppercase;
        color: #666666;
        p {
          a {
            color: @link-color-green;

          }
        }
        .icon-wrapper {
          padding-top: 15px;
          img {
            width: 46px;
            height: 39px;
          }
        }
      }
      .add-to-cart-section {
        background-color: @btn-primary-bg;
        border-radius: 10px;
        height: 40px;
        line-height: 40px;
        &.disabled {
          background-color: @disabled-color;
          color: #ffffff;
        }
        a {
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          color: #ffffff;
          &.add-to-cart {
            display: block;
            width: 100%;
            float: left;
          }
          &.quantity {
            display: block;
            width: 30px;
            height: 30px;
            line-height: 32px;
            float: left;
            border-left: 1px solid #ffffff;
            margin: 5px 5px 5px 0;
            padding: 0 10px 0 15px;
            box-sizing: border-box;
            .arrow-up {
              font-size: 10px;
              vertical-align: 2px;
              padding-right: 5px;
            }
          }
        }
      }
      .eqp-seal {
        width: 65px;
        height: 65px;
        margin-left: 53%;
      }
      .table {
        padding-bottom: 20px;
        tbody {
          tr {
            border-bottom: 0;
            &:nth-of-type(2) {
              border-bottom: solid 1px #999999;
              padding-bottom: 10px;
            }
            &:nth-of-type(3) {
              padding-top: 10px;
            }
            &:last-child {
              margin-top: 10px;
              padding-bottom: 10px;
              border-top: solid 1px #999999;
              border-bottom: solid 1px #999999;
            }
            &.serving-detail {
              td {

              }
              border-bottom: 0;
            }
            &.alternate-product-serving-detail {
              border-top: 0;
            }
            td {
              margin: 0;
              padding: 10px 0;
              p {
                padding: 0;
                margin: 0;
              }
              .serving-text {
                font-size: 12px;
                line-height: 1.83;
              }
              .serving-amount {
                display: block;
                font-size: 16px;
              }
              &.disclaimer-wrapper {
                text-align: right;
                font-size: 14px;
                line-height: 1.57;
                overflow: visible;

                .tooltip-container {
                  background: none;
                  width: 75px;
                  min-width: 75px;

                  .tooltip {
                    text-align: left;
                    height: auto;
                    font-size: 12px;
                    left: -90px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .view-similar-products-button.product-details{
    a.add-cart{
      color:#fff;
      text-decoration:none;
      &:hover{
        color:#fff;
      }
    }
  }

  .add-to-cart-unit-product-detail{
    position: relative;
    .multiple-of-msg{
      color:#333;
      top: initial;
      line-height: normal;
    }
    form{
      padding-right: 10px;
    }
    .view-similar-items-button-holder {
      margin-top: 16px;
      margin-right: 16px;
    }
    .add-to-cart-quantity-button {
      max-width: none;
      color: #ffffff;
      float: none;
      a.add-cart {
        background-image: none;
        width: 108px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
      .quantity {
        width: 70px;
      }
      .quantity.dropdown {
        div {
          .dropdown-list {
            width: 229px;
          }
        }
      }
      .quantity-adjustment-wrapper {
        .quantity-adjustment {
          margin: 0 auto;
          width: 155px;
        }
      }
    }
  }

  .adding-to-cart-progress-wrapper {
    margin-left: -15px;

    md-progress-circular {
      height: 40px !important;
      width: 40px !important;

      > svg {
        height: 40px !important;
        width: 40px !important;
        transform-origin: center !important;
      }
    }
  }

  .related-products {
    padding-top: 40px;
    h2 {
      font-size: 31px;
      font-weight: 300;
      text-align: left;
    }
    .search-results-container {
      padding-left: 0;
      margin-left: 0;
      .list-flexbox-grid .list-item {
        width: 25%;
      }
      .quantity {
        &:before {
        }
      }
    }
    .list-flexbox-grid {
      .list-item {
        width: 25%;
      }
    }
    &.non-loggedin {
      .product-item-card {
        .product-item {
          padding-bottom: 0;
        }
      }
    }
  }


  .header {
    border-bottom:1px solid #b8b8b8;
    margin-left:30px;

    h2 {
      font-size: 18px;
      font-weight: 300;
      color: #333333;
      text-align: left;
    }
  }

  .btn-filter {
    background:#85c766;
    color:#fff;
    padding:12px 13px;
    margin-top:0;
  }

  .filter {
    font-size:14px;
    width:267px;

    ul {
      margin:0;
      padding:0;
      list-style-type:none;
    }

    .category {
      margin-bottom:20px;
      -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.35);
      -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.35);
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.35);
      border-radius: 3px 3px 3px 3px;
      -moz-border-radius: 3px 3px 3px 3px;
      -webkit-border-radius: 3px 3px 3px 3px;
    }

    .category-title {
      background:#56a4c8;
      color:#fff;
      padding:12px 13px;
      display:block;
    }


  }


}

.filter-arrow-down,
.filter-arrow-up {
  background-image: url('/assets/images/column-sort-arrow.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 12px;
    height: 16px;
    display: inline-block;
}
.filter-arrow-up {
  transform: rotate(-180deg);
  background-position: center top;
}

.md-select-value{
  border: none;
}

.vendor-code {
  clear:both;
}
.supplement-container {
  .tooltip-container{
    width: auto;
    height: auto;
    background-position: top right;
    background-repeat: no-repeat;
    padding-right: 23px;
    &:hover{
      background-position: top -19px right;
    }
  }
}
.layout-column{
  display: block;
}

.thumbnail-layout-column {
  display: inline-block !important;
  position: absolute;
  top: 18.5em;
}

.item-image-layout-column {
  display: inline-block !important;
  position: relative;
  left: 4em;
}

.side-thumb {
  width: 3.5em !important;
}
@media all and (max-width: 959px) {
  .product-detail-wrapper {
    .related-products {

      .search-results-container {

        .list-flexbox-grid .list-item {
          width: 50%;
        }
      }
      .list-flexbox-grid {
        .list-item {
          width: 50%;
        }
      }
      &.non-loggedin {
        .product-item-card {
          .product-item {

          }
        }
      }
      .add-to-cart-quantity-button {

        a.add-cart {

        }
        .quantity {

        }
        .quantity.dropdown {
          div {
            .dropdown-list {

            }
          }
        }
        .quantity-adjustment-wrapper {
          .quantity-adjustment {

          }
        }
      }
    }
  }

  .product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper{
    .tooltip-container{
        &:hover {
          width: 75px;
        }
        .tooltip{
          text-align: left;
          height: auto;
          font-size: 12px;
          left: auto;
          right: 0;
        }
    }
  }
  .tooltip-container{
    .tooltip-up .tooltip-arrow {
      left: auto;
      right: 20%;
    }
  }

}
@media all and (max-width: 599px) {
  .product-detail-wrapper {
    .layout-column-xs, .layout-column {
      display: flex;
    }

    .item-title-box {

      h1#itemNameTitle {
        font-size: 19px;
        font-weight: 500;
        padding-top: 15px;
      }

      p.item-code-text {
        margin: 0;
        color: #606060;
        letter-spacing: 0;
        line-height: 1.5;
        font-size: 14px;

        > span.item-code {
          display: unset;
          font-size: 13px;
          font-weight: 700;
          color: #5d5d5d;
        }
      }
    }

    .main-content {
      padding-bottom: 0;
      border-bottom: none;

      .left-content {
        padding-right: 0;

        .item-image {
          text-align: unset;
          padding-bottom: 16px;

          .thumbs-nail-wrapper {
            height: unset;
            overflow: hidden;
            margin-bottom: 0;

            .thumb-wrapper ul {
              margin-left: 0;
            }
          }

          .selected-item-wrapper {
            margin-top: 6px;
          }

          #selectedItemNum {
            font-size: 14px;
            padding: 2px 20px 2px 7px;
            margin-bottom: 6px;
            width: 40%;
          }

          .item-price-wrapper {
            margin-bottom: 16px;

            div[layout="row"] {
              margin: 6px 0;

              p {
                color: rgba(0, 0, 0, .67);
                letter-spacing: 0.1px;
                margin: 0;
                min-width: 105px;
              }

              span {
                color: rgba(0, 0, 0, .67);
                font-size: 14px;
              }

              .your-price {
                font-weight: 700;
                color: rgba(0, 0, 0, .87);
                font-size: 20px;
              }
            }
          }

          .add-to-cart-quantity-button {
            height: 45px;
            line-height: 45px;
            border-radius: 4px;
          }
          md-input-container.product-card-qty{
            input {
              background-color: #fff;
              color: #000;
              padding: 0 4px;
              border-radius: 5px;
              height: auto;
              border: 1px solid #68a81f;
              margin-top: 4.5px;
              max-width: 39px;
              text-align: right;
              -moz-appearance:textfield;
            }
            input[disabled] {
              background-color: inherit;
              color: #fff;
              padding: 0 4px;
              border: 1px solid #dfdfdf;
              border-radius: 5px;
            }

          }

          a.add-cart {
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            .shopping-cart-icon {
              margin-right: 5px;
            }
          }
        }

        .brand-logo-row {
          margin-bottom: 2px;
        }

        .item-tags {
          padding-bottom: 15px;

          .md-chips md-chip {
            color: #fff;
            background-color: rgba(0, 0, 0, 0.33);
            font-weight: 300;
            letter-spacing: 0.6px;
            border-radius: 5px;
            font-size: 15px;
          }
        }

        .warning-messages.warning-messages-pdp {
          margin-left: 0;
          div{
            padding: 0;
            p{
              color: #E00603;
              margin-top: 0px;
              font-size: 16px;
              span{
                color: #666666;
                font-size: 13px;
                margin: 0px;
              }
            }
          }
          .green-warning-messages{
            p{
            color: green;
            }
          }

          ul {
            padding: 0;
            padding-top: 14px;
            margin: 0;
            list-style: none;
            border-top: 1px dashed #b7b7b7;

            &:first-of-type {
              border-top-style: solid;
              margin-top: 16px;
            }

            li>h4 {
              color: rgba(0,0,0,.67);
              margin: 0;
              font-size: 18px;
            }

            li.warning-text {
              margin-top: 6px;
              color: rgba(0,0,0,.67);
              line-height: 1.4em;
              font-size: 14px;
            }
          }
        }
      }

      .right-content {
        padding: 0;

        .non-logged-in {
          margin-bottom: 10px;
          clear: both;
        }
      }
    }

    .expansion-panel { // make expansion-panel ignore parent's the padding
      width: 113%;
      margin-left: -7%;

      .expansion-panel-content {
        padding: 12px 24px;

        .item-description-text {
          font-size: 16px;
          line-height: 24px;
          color: rgba(0,0,0,.67);
          font-family: 'Roboto Regular',  sans-serif;
          p, span, div {
            font-size: 16px;
            line-height: 24px;
            color: rgba(0,0,0,.67);
            font-family: 'Roboto Regular',  sans-serif;
          }

          p:first-of-type {
            margin-top: 0;
          }

          P:last-of-type {
            margin-bottom: 0;
          }
        }

        .supplement-container {
          .disclaimer-wrapper {
            border: 1px dashed rgba(0,0,0,.18);
            padding: 8px;
            margin-bottom: 16px;
            overflow: hidden;

            h3 {
              margin: 0;
              margin-bottom: 4px;
            }

            a {
              color: rgba(0,0,0,.67);
              font-weight: 700;
            }

            &.ng-enter,
            &.ng-leave {
              -webkit-transition: all 0.16s linear;
              transition: all 0.16s linear;
            }

            &.ng-enter,
            &.ng-leave.ng-leave-active {
              max-height: 0;
              opacity: 0;
            }

            &.ng-leave,
            &.ng-enter.ng-enter-active {
              max-height: 340px;
              opacity: 1;
            }
          }

          p, span, div {
            font-size: 14px;
            color: rgba(0,0,0,.67);
          }

          p:first-of-type {
            margin-top: 0;
          }

          P:last-of-type {
            margin-bottom: 0;
          }

          .tooltip-container {
            height: 19px;
            margin-bottom: 16px;
          }

          .text-right {
            text-align: right;
          }

          table {
            padding: 0;
            color: rgba(0,0,0,.67);

            &.serving-size-table {
              margin-bottom: 16px;

              thead {
                padding: 0;
                border-bottom: none;
              }

              tbody tr {
                border: none !important;
                td {
                  padding: 0;
                  text-align: left;
                  span {
                    font-size: 15px;
                  }
                }
              }
            }

            thead {
              padding-bottom: 6px;
              border-bottom: 1px dashed rgba(0,0,0,.18);
              font-size: 12px;

              tr {
                border-bottom: none;
              }
            }

            tbody {
              tr {
                border-bottom: none;

                &:nth-of-type(2) {
                  border-bottom: none;
                  padding-bottom: 0;
                }
                &:nth-of-type(3) {
                  padding-top: 0;
                }
                &.other-ingredients {
                  border-top: 1px dashed rgba(0,0,0,.18);
                  border-bottom: none;
                  padding-bottom: 0;

                  td {
                    padding-bottom: 0
                  }
                }

                td {
                  font-size: 14px;
                  color: rgba(0, 0, 0, .54);
                  padding: 12px 0;
                  padding-right: 6px;

                  &:last-of-type {
                    padding-right: 0;
                  }

                  span, p {
                    color: rgba(0, 0, 0, .54);

                    &.serving-text {
                      font-weight: 700;
                      font-size: 13px;
                      color: rgba(0,0,0,.67);
                    }
                  }
                }
              }
            }
          }
        }

        .specification-container {
          padding-bottom: 16px;
          border-bottom: 1px solid #b7b7b7;

          .ee-container {
            padding: 0;
          }
          p, span, div {
            font-size: 14px;
            color: rgba(0,0,0,.67);
          }

          p:first-of-type {
            margin-top: 0;
          }

          P:last-of-type {
            margin-bottom: 0;
          }
        }

        br {
          display: none;
        }
      }
    }

    .link-section {
      margin-top: 16px;
    }

    .related-products {
      padding-top: 0;
      h2 {
        font-size: 28px;
      }

      .search-results-container {

        .list-flexbox-grid .list-item {
          width: 100%;
        }
      }
      .list-flexbox-grid {
        .list-item {
          width: 100%;
        }
      }
    }
  }
  .product-detail-wrapper .main-content .right-content .table tbody tr td.disclaimer-wrapper{
    .tooltip-container{
        &:hover {
          width: 75px;
        }
        .tooltip{
          text-align: left;
          height: auto;
          font-size: 12px;
          left: auto;
          right: 0;
        }
    }
  }
  .tooltip-container{
    .tooltip-up .tooltip-arrow {
      left: auto;
      right: 20%;
    }
  }
  div.showDesc {
    h2 {
      font-size: 16px;
      text-align: left;
      font-weight: bold;
      margin-top: 21px;
      p.desc-dark-greycolor {
        line-height: 20px;
        font-family: 'Roboto Regular',  sans-serif;
        font-size: 16px;
      }
    }
  }
}

.warning-message-available-qnty {
  opacity: 1;
  margin-top: 2px;
  color: #c71554;
  font-weight: 500;
}

.warning-messages.warning-messages-pdp {

  ul {
    padding-inline-start: 0px;
    li>img {
      float:left;
      padding-right:10px;
    }
    li>h4 {
      color: rgba(0,0,0,0.67);
      font-size: 16px;
    }
  }
}
.add-to-cart-quantity-button.empty-qty-field,
.empty-qty-field .add-to-cart-quantity-button{
    background-color: #BEBEBE;
    color: #FFFFFF;
}
.add-cart[disabled]{
  pointer-events: none;
}
a.change-desc-limit {
  color: #666666;
  text-decoration: underline;
}
div.showDesc{
  padding:0;
}

@media only screen and (min-width: 768px) and (max-width: 1023px)
{
  .product-detail-wrapper {
    padding-top: 30px;
    .main-tab .item-code-text {
        line-height: 1.3;
        padding-top: 5px;
        white-space: normal;
    }
    .main-tab{
      padding-bottom: 10px;
    }
      .main-tab .greenbdr {
      width: 17%;
    }
    .breadcrumb-bdr{
      width: 430px;
    }
  }
}

#accordian-section{
 padding: 0 5%;
  .grey-accordian-section{
  background-color: #EFEFEF;
  padding: 15px 0 ;
  float: left;
  width: 100%;
  margin-bottom:30px;
  }

   .custom-accordian{
    width:100%;
    float: left;
    margin-bottom: 3px;
    .top-section{
      width:100%;
      float:left;
      padding:10px 20px;
      background-color: #fff;
      box-sizing: border-box;
      outline: none;
    span{
      display:inline;

    }
    .title{
      float:left;
      color: #666;
      font-family: 'Roboto Regular',  sans-serif;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
    }
    .expansion{
      float:right;
      font-family: 'Roboto Regular',  sans-serif;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  color: #999;
    }
  }
  .bottom-section{
    width:100%;
    float:left;
    padding:10px 20px;
    background-color: #fff;
    display: none;
    box-sizing: border-box;
    .accordianWarningInner {
      padding-top: 20px;
      .warningSubTitle {
        color: #666;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .warningMessageBottom {
      padding-bottom: 20px;
    }
    .table {
      padding-bottom: 20px;
      tbody {
        tr {
          border-bottom: 0;
          &:nth-of-type(2) {
            border-bottom: solid 1px #999999;
            padding-bottom: 10px;
          }
          &:nth-of-type(3) {
            padding-top: 10px;
          }
          &:last-child {
            margin-top: 10px;
            padding-bottom: 10px;
            border-top: solid 1px #999999;
            border-bottom: solid 1px #999999;
          }
          &.serving-detail {
            td {

            }
            border-bottom: 0;
          }
          &.alternate-product-serving-detail {
            border-top: 0;
          }
          td {
            margin: 0;
            padding: 10px 0;
            p {
              padding: 0;
              margin: 0;
            }
            .serving-text {
              font-size: 12px;
              line-height: 1.83;
            }
            .pdv {
              color: #333333;
              font-weight: normal;
            }
            .serving-amount {
              display: block;
              font-size: 16px;
            }
            &.disclaimer-wrapper {
              text-align: right;
              font-size: 14px;
              line-height: 1.57;
              overflow: visible;

              .tooltip-container {
                background: none;
                width: 75px;
                min-width: 75px;

                .tooltip {
                  text-align: left;
                  height: auto;
                  font-size: 12px;
                  left: -90px;
                }
              }
            }
          }

          @media (max-width: 959px) {
              &.ingredient-row {
                margin: 20px 0;
                td {
                  padding: 0;
                  font-size: smaller;
                }
              }
              td.disclaimer-wrapper {
                > p {
                  text-align: left;
                  a {
                    padding-right: 0;
                    width: auto;
                    min-width: auto;
                  }
                  .tooltip-container {
                    .tooltip {
                      left: 0;
                    }
                  }
                }
              }
}

        }
      }
    }
  }
  .bottom-section.show{
    display: block;
  }
   }

}
@media only screen and (min-width: 320px) and (max-width: 767px){

  .product-detail-wrapper {
    padding-top: 30px;
  .main-tab .greenbdr {
    width: 17%;
    }
    .item-title-box p.item-code-text{
    margin-top: 10px;
    padding-left: 0;
  }
  .readyTop{
    margin:10px 0 20px;
    width:100%;
    position: relative;
  }
  div.showDesc{
    padding:0px;
  }
}
}
.dark-greycolor{
  p, span, div{
    color: #666666 !important;
  }
  color: #666666 !important;
}
.mobaccord{
  clear: both;
  margin-top:10px;
}
.breadcrumb-bdr{
    border-bottom: 1px solid #ececec;
    padding-top: 3px;
    width: 570px
}
div.showDesc p.dark-greycolor {
    margin-top: 0;
}
.feature-label{
  margin-right:5px;
  float: left;
  padding: 5px;
  background: #76bd23;
  color: white;
  border-radius: 5px;
  width: 75px;
  text-align: center;
  text-transform:uppercase;
  font-size: 10px;
  line-height: 1.0;
  letter-spacing: 0.2px;
  text-decoration: none;
}
div.showDesc {
  color: #666;
  font-size: 16px;
  h2 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-top: 21px;
    p.desc-dark-greycolor {
        margin-bottom: 0;
        line-height: 20px;
        font-family: 'Roboto Regular',  sans-serif;
        color: #666;
    }
  }
  span.brandName-text {
    a {
      text-decoration: underline;
      color:#666;
    }
  }
  div.item-description-text {
    margin-top: 15px;
    br:first-child {
      display: none;
    }
    p:first-child{
      margin-top: 0;
    }
    div:first-child br:first-child {
      display: none;
    }
  }
}
.youmayalsolike-container{
    .flex-margin{
      border: 0;
      padding: 0 0 112.4px 0;
      margin: 0px;
    }
    .greenbdr{
      width: 8%;
      border: 2px solid #76bd23;
      padding: 0;
      margin: 0;
    }
}
.alt-related-products {
  h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.17;
    color: #707070;
    font-family: roboto;
    text-align: left;
    margin-bottom: 1%;
  }
  .list-flexbox-grid {
    .list-item-box {
      width: 266px;
      height: 100%;
      border: 2px solid #fafafa;
      display: inline-block;
      margin-right: 14px;
    }
}
}
.nav-btn {
  display: flex;
  &.not-visible {
    visibility: hidden;
  }
}
.nav-space {
  width: 65px;
}
.prev-disabled,
.prev-enabled,
.next-disabled,
.next-enabled {
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 45px;
  height: 45px;
  position: relative;
  top: 190px;
  margin:10px;
}
.prev-disabled {
  background-image: url(/assets/images/prev_disabled.png);
}
.prev-enabled {
  background-image: url(/assets/images/prev_enabled.png);
}
.next-enabled {
  background-image: url(/assets/images/next_enabled.png);
  margin:10px 10px 10px -5px;
}
.next-disabled {
  background-image: url(/assets/images/next_enabled.png);
  opacity: 0.25;
  margin-left: -5px;
}

.alternate-product-list-holder {
  display: flex;
  // note: the zoom will allow to always show 4 images
  @media screen and (max-width: 1500px) { zoom: 0.9; }
  @media screen and (max-width: 1400px) { zoom: 0.8; }
  @media screen and (max-width: 1300px) { zoom: 0.8; }
  @media screen and (max-width: 1200px) { zoom: 0.7; }
  @media screen and (max-width: 1100px) { zoom: 0.6; }
  @media screen and (max-width: 1000px) { zoom: 0.5; }
  > #alternate-product-list {
    width: 100%;
  }
}

#alternate-product-list{
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  text-align: center;
  margin: 0 auto;
  &.small-width {
    justify-content: start;
  }
}

#alternate-product-list-mobile{
  display: none;
}

@media screen and (max-width: 959px) {
  .alternate-product-list-holder {
    display: none;
  }

  #alternate-product-list-mobile{
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .alt-slide-view::-webkit-scrollbar {
    display: none;
  }

  .alt-slide-view{
    width: 100%;
    border: 0;
    margin: 0;
    overflow-x: scroll;
    overflow-y: auto;
    -ms-overflow-style: none;
  }

  .alt-slide-item{
    width: 16.6em;
    display: inline-block;
    border: 0;
    margin: 0;
  }
}






.print-only-logo {
  display: none;
}

.printable-description {
  display: none;
}

@media print {
  .product-details-page {
    zoom: 0.8;

    .ee-container {
      max-width: none;
    }

    .product-detail-wrapper .main-content {
      padding-bottom: 20px;
    }

    .desc-dark-greycolor {
      margin: 0 !important;
    }

    h2 {
      margin: 0 !important;
    }

    ee-product-actions {
      margin-top: 20px;
    }
  }

  .youmayalsolike-container {
    display: none;
  }

  .nav-map-wrapper {
    display: none;
  }

  .print-only-logo {
    display: block;
    > img {
      padding: 40px 0;
    }
  }

  // row with title and item codes
  .product-detail-wrapper {
    .item-title-box {
      display: table;
      width: 100%;

      > * {
        display: table-cell !important;
        vertical-align: top;
      }

      // row with title
      > .item-title {
        width: 100%;
        padding-top: 0;
        > h1#itemNameTitle {
          font-size: 19px;
          font-weight: 500;
          padding-top: 10px;
        }
        > .breadcrumb-bdr {
          display: none;
        }
      }

      // row with item codes
      > .item-codes {
        > * {
          display: flex;
          flex-direction: row;
          > div:first-child {
            padding-right: 30px !important;
          }
          .item-code-text {
            padding: 0 !important;
            > * {
              display: none;
            }
            > .item-code {
              display: block !important;
            }
          }
        }
      }
    }
  }

  // row with images and description and action items
  .main-content {
    > div {
      display: table;
      > * {
        display: table-cell;
        vertical-align: top;
        padding-top: 35px !important;

        &.left-content {
          width: 30%;
          padding-right: 40px !important;
          padding-right: 5% !important;
        }

        &.right-content {
          > div {
            display: block;
            max-width: 100%;
            width: 100%;
            > * {
              display: block;
              max-width: 100%;
              width: 100%;
              > * {
                float: none;
                max-width: 100%;
                width: 100%;
                color: black !important;
                &.dark-greycolor p {
                  color: black !important;
                }
              }
              .item-description-text, .change-desc-limit {
                display: none;
              }
              .printable-description {
                display: block;
              }
            }
          }
        }

        .layout-margin, .layout-margin > * {
          margin: 0 !important;
        }
      }
    }
  }

  .hidden-print {
    display: none !important;
  }
}