.action-bar {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #333333;
	padding: 0 10px;
  color: #ffffff;
	.items-selected {
		padding: 30px 0 0 16%;
		position: absolute;
		left: 0;
    text-transform: uppercase;
	}
  a {
    color: #ffffff;
    background-color: #79c143;
    padding: 10px 20px;
    min-width: 200px;
    display: table-cell;
    text-align: center;
    &.add-to-cart-btn {
      background-color: #4399c5;
      img {
        padding-right: 8px;
      }
    }
    &:hover {
      text-decoration: none;
      color: #ffffff;
    }
  }

}

.order-list td:last-child {
  padding-right:0;
}
my-orders-actions-menu {
  .actions-wrapper {
    margin-top: 20px;
    margin-bottom: 30px;
    @media screen and (max-width: 959px) {
      clear:both;
    }
    .actions-list li {
      width: 100%;
    }
  }
}


@media (max-width: 959px) {
	.interier-wrapper {
		padding-top: 0;
	}
	.action-bar {
		.items-selected {
			display: none;
		}
		a {
			padding: 5px 20px;
		  min-width: 150px;
			font-size: 14px;
		}
	}
  .order-list {
    tr {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}

/*Responsive table*/

@media only screen and (max-width: 959px) {


}
.reorder-products-quick-order .quick-order{
  position: relative;
}