.featured-block {
  &.border-right {
    li {
      &.list-flex-item {
        border-right: 1px solid #ccc;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

.mob{
  margin-top: 20%;
}

.tab-body-wrapper {
  padding-top: 30px ;
  padding-bottom: 10px;
}

h2 {
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  color: #333333;
}

.list-inline {
  li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#discover-emerson {
  md-tabs[md-border-top] {
    .md-tab .ng-scope {
      white-space: normal;
      padding: 10px 40px;
      line-height: normal;
    }
  }
}

.media-body {
  text-align: left;
  padding-left: 33px;

  h3 {
    font-size: 20px;
    line-height: 1.2;
    color: #333333;
    font-weight: 400;
    margin-top: 0;
    height: 52px;
  }

  a {
    line-height: 1.1;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }
}

.brand-product-item-layout {
  .logo {
    text-align: center;
    border-bottom: solid 1px #d7d7d7;
    margin-bottom: 15px;
    height: 85px;
    padding-bottom: 10px;
    box-sizing: border-box;

    .responsive-img {
      max-width: auto;
      max-height: 73px;
    }

    .isEqp {
      display: inline-block;
      height: 66px;
      padding: 13px 0;
      box-sizing: border-box;
      margin-left: 10%;

      .eqp-icon-size {
        display: block;
        width: 100%;
        max-width: 62px;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }
  }

  .content {
    flex: 1 0 auto;
    max-width: 600px;
    margin: 0 auto;

    .product-thumbnail {
      width: 100px;
    }

    .layout-with-button {
      flex: 1 0 auto;
      position: relative;
      padding: 0 10px 0 10px;
      -webkit-align-self: center;
      align-self: stretch;

      &.layout-column {
        display: flex;
        flex: 1 0;
      }

      p {
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .button-bottom-wrapper {
        text-align: center;
        width: 100%;
      }
    }
  }
}

.feature-product {
  .list-flex-content {
    max-width: 260px;
    margin: 0 auto;
    padding: 0 0.5em;

    p {
      margin-top: 0;
    }
  }

  .h3,
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.1;
  }

  .brandname {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    color: #666666;
    display: table;
    margin: 15px auto 10px;
    min-height: 36px;

    .eqp-silver {
      padding-left: 45px;
      background-image: url("../../assets/images/icons/e-q-p-silver-icon-no-label.png");
      background-repeat: no-repeat;
      background-size: 36px 36px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      min-height: 36px;
      display: table-cell;
    }

    .eqp-gold {
      padding-left: 45px;
      background-image: url("../../assets/images/icons/e-q-p-gold-icon-no-label.png");
      background-repeat: no-repeat;
      background-size: 36px 36px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      min-height: 36px;
    }

    &.text-left {
      margin-left: 0;
      margin-right: 0;
    }
  }
  img {
    max-height: 100px;
  }
}

.condition-item-list {
  text-align: center;
  border-right: 1px solid #ccc;

  .condition-item {
    height: 100%;
    .media-list{
      padding-left: 0;
      list-style: none;
      height: 100%;
      margin-bottom: 0;
      .condition {
        height: 100%;
        .body {
          text-align: center;
          padding-left: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          display: -webkit-flex; /* Safari */
          -webkit-flex-direction: column; /* Safari */
          display: -moz-flex; /* mozilla */
          -moz-flex-direction: column; /* mozilla */
          h3 {
            font-size: 22px;
            text-align: center;
            height: auto;
            width: 100%;
            color: #333333;
            font-weight: normal;
          }
          p {
            font-size: 18px;
            width: 100%;
            color: #666666;
            font-weight: normal;
            &:last-child{
            margin-top: auto;
            }
          }
          a {
            text-transform: uppercase;
            width: 100%;
            font-weight: normal;
            font-size: 15px;
            text-align: center;
          }
        }
      }
    }
  }
  &:last-child {
    border-right: none;
  }
}

.carousel .controls .pagination {
  .page {
    width: 184px;
    display: block;
    padding: 0;
    float: left;
    border-top: solid 3px transparent;

    span {
      color: #999999;
      font-weight: 500;
      padding: 5px 0;
      display: block;
      width: 100%;
      height: 100%;
      border-top: solid 1px @primary-color;
    }

    &.active {}
  }

  .active {
    border-top: solid 3px @primary-color;

    span {
      background-color: transparent;
      color: #000000;
    }
  }
}

#discover-emerson .wrapper .item {
  display: none;
}

.ng-hide,
[ng-cloak] {
  display: none !important;
}

.ng-show {
  display: block !important;
}

#discover-emerson .brand-item .product-thumbnail img {
  height: 73px;
}

#discover-emerson .brand-item .product-thumbnail img.eqp {
  width: 43px;
  height: auto;
  display: block;
  margin: 17px auto 0;
}
@media (max-width: 959px) {
  #discover-emerson .brand-product.media,
  .condition {
    max-width: 300px;
    margin: 0 auto;
  }

  .condition-item-list {
    border-right: none;
  }

  .featured-block {
    max-width: 400px;
    margin: 0 auto;

    li {
      &.list-flex-item {
        margin-bottom: 20px;

        &.border-right {
          border-right: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 959px) and (orientation: landscape) {}
/*layout-xs*/
@media (max-width: 599px) {
  #discover-emerson {
    .tabs-wrapper {
      margin-left: -15px;
      margin-right: -15px;
      margin-top: 20px;
    }

    md-tabs-canvas {
      height: 130px;
    }

    md-pagination-wrapper,
    md-tabs-canvas {
      width: 100%;
    }
  }

  .media-body {
    h3 {
      height: initial;
    }
  }

  .icon-list {
    text-align: center;
  }

  #support-for-your-practice .media-right {
    text-align: center;

    h3 {
      text-align: center;
      margin-top: 30px;
    }

    .emersonicon-class {
      width: 50%;
      margin: 10px auto;
    }
  }

  .wellevate-mobile {
    width: 300px;
    height: auto;
  }
}
@media (max-width: 599px) and (orientation: landscape) {
  .brand-item-list {
    .media-list {
      width: 38%;
      margin: 0 auto;
    }
  }

  li.brand-product {
    width: 50%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 959px) {
  .education-md-tabs-wrapper-padding .featured-block {
      max-width: none;
      margin: 0px;
  }
}

.tabs-wrapper md-tabs.md-dynamic-height md-tabs-content-wrapper md-content{
  overflow: hidden;
}
