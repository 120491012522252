.thank-you-order-wrapper {

  .thank-you-mod {
    text-align: center;
    padding: 50px 0 50px 0;

    h1 {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.1;
      margin: 0 0 10px 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3;
      margin: 0;
    }
  }

  .order-print {
    cursor: pointer;
  }
  .printorder-img {
    margin-left: 90%;
  }
  .order-status {
    margin: 0 10px 10px;
  }
}

.place-order-detail {
  overflow: hidden;
  margin: 20px 0 0;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      float: left;
      margin: 0 20px 0 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
    }
  }
}

.order-thankyou-page {
  .discount-saving {
    p {
      font-size: 24px;
    }
  }
}

@media print {
  .summary-side-col {
    float: right !important;
    height: auto !important;
    margin: 0 !important;
    margin-top: 10% !important;
    width: 23% !important;
  }

  .printorder-img {
    margin: 14px 0 -14px 264px !important;
  }

  .print-order-mode {
    display: none !important;
  }

  .navbar-wrapper,
  div#navbar {
    border: 0;
  }

  .site-alert-messaging {
    display: none;
  }

  .place-order-detail {
    margin: 0;
    display: block !important;

    ul li {
      font-size: 12px;
    }
  }

  .order-summary-table {
    width: 76% !important;
  }

  table.table.hide-xs {
    display: block !important;
  }

  .price-summary .unit {
    width: 50%;
  }

  .order-summary-wrapper .disclaimer {
    width: 130%;
  }

  .printorder-img {
    margin-bottom: 10px;
    margin-left: 85% !important;
  }

  .expirationDate {
    padding-left: 0 !important;
  }
}

@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px) {
  .printorder-img {
    margin-left: 83% !important;
  }
}

@media (max-width: 959px) {
  .order-thankyou-page {
    padding: 15px;
    margin-top: -25px;
  }

  .ee-container.thank-you-order-wrapper {
    padding: 0;

    .thank-you-mod {
      padding: 10px 45px 0px 10px;

      h1 {
        font-size: 24px;
      }
      h2 {
        font-size: 12px;
      }
    }
    .cart-summary-wrapper {
      border: solid 1px #d7d7d7;
      box-shadow: none;
    }
  }

  .thank-you-no-sm {
    display: none;
  }

  .navbar-wrapper {
    border-bottom: 0 !important;
  }
}

