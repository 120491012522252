.checkout-process {
	border-bottom: 1px solid #76bd23;
	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
			text-align: left;
			border-right: 1px solid #d7d7d7;
			padding: 0;


			&:last-child {
				border-right: 0;
			}

			a {
				padding: 15px 0;
				margin: 0 15px;
				text-decoration: none;
				color: #cccccc;
				font-size: 18px;
				line-height: 1;
				display: block;
				text-transform: uppercase;
				pointer-events: none;
       			cursor: default;

				&.active {
					color: #000000;
					border-bottom: 4px solid #76bd23;
					font-weight: 700;
				}
			}

      .clickable {
        font-weight: 700;
        color: #000000;
        pointer-events: auto;
   			cursor: pointer;
      }

			&:first-child {
				a {
					padding-left: 0;
					margin-left: 0;
				}

			}
		}
	}
}

/*layout-sm width < 959px*/
@media only screen and (max-width: 959px) {
  .checkout-process {
    border-top: solid 1px #76bd23;
    border-bottom: 1px solid #d7d7d7;

    ul {

      li {
        border-right: 0;
        a {
          font-size: 15px;
          margin: 0 10px 0 0;
          padding-bottom: 0;
          padding-top: 10px;
          border-top: 2px solid transparent;

          &.active {
            border-bottom: 0;
            border-top: 2px solid #76bd23;
          }
        }
      }
    }
    .checkout-process-title-mobile {
      text-align: center;

      h3 {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        text-transform: uppercase;
        margin: 10px;
      }
    }
  }
}
