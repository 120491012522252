.apply-promo-code [ng-messages] {
  font-size: 12px;
  color: @invalid-color;
}

.expirationDate {
  padding-left: 30px;
}

.summary-side-col {
	float: right;
	width: 262px;
	height: auto;
	margin: 30px 0 30px 30px;

	.cart-summary-wrapper {
		background-color: #f6f6f6;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
		padding: 0;

		.item-total {
			padding: 15px 0;
			border-bottom: solid 1px #d7d7d7;

			span {
				padding: 0 13px;
				font-size: 16px;
				line-height: 1.4;
				color: #333333;
        margin-left: 10px;
			}
		}


    #total-items{
      padding: 15px 0;
      border-bottom: solid 1px #d7d7d7;
    }

    .item-summary{
    margin-left: 10px;
    }

    .price-summary {
			padding: 15px 0;
			border-bottom: solid 1px #d7d7d7;
			overflow: hidden;

			.promo-discount,
			.sub-total {
				margin-bottom: 0;
			}
			.sub-total {
				margin-bottom: 0;
				font-weight: 700;
			}
		}
		.media {
			padding: 0 13px;
			float: none;
			overflow: hidden;
			margin-bottom: 10px;
			.unit {
				float: left;
				margin: 0;
				font-size: 16px;

				&:last-child {
					overflow: hidden;
					float: none;
					text-align: right;
				}
			}
			h2.unit {
				font-weight: 700;
			}
		}
		.free-shipping-wrapper {
			padding: 15px 0;
			border-bottom: solid 1px #d7d7d7;
			p {
				margin: 0 15px;
				font-size: 16px;
				line-height: 1.3;
				strong {
					display: inline-block;
				}
        .shift-left {
          font-size: 15px;
        }
			}
			.free-shipping-progress-bar {
				margin: 18px 15px 15px 15px;
				padding-right: 40px;
			}
			md-progress-linear.free-shipping {
				position: relative;

				&:after {
					content: '';
    				display: block;
    				width: 38px;
    				height: 21px;
    				background-image: url(/assets/images/delivery-truck-sprite.png);
    				background-position: 0 0;
    				background-repeat: no-repeat;
    				position: absolute;
    				right: -40px;
    				top: -15px;
				}
				&.active:after {
					background-image: url(/assets/images/delivery-truck-sprite.png);
					background-position: 0 -21px;
				}
			}

		}
		.discounts-wrapper {
			padding-bottom: 15px;
			border-bottom: solid 1px #d7d7d7;
			p {
				margin: 0 15px;
				font-size: 16px;
				line-height: 1.3;

				&.smaller {
					font-size: 14px;
					line-height: 1.2;
				}
			}
			.two-col {
				overflow: hidden;

				.unit {
					float: left;

					&.text-right {
						text-align: right;
						float: right;
						p {
							margin: 15px 15px 0 0;
							.edit-link {
								font-size: 12px;
								line-height: 1.6;

								&.error-edit-link {
									color: #cc3366;
								}
							}
						}
					}

					h3 {
						margin: 15px 2px 10px 15px;
						font-size: 16px;
					}
				}
			}

			.discount-code {
				margin: 10px 0;

				&:last-child {
					margin-bottom: 0;
				}

				.discount-code-text {
					cursor: pointer;
					&:hover {
						color: #4399c5;
					}
				}
			}
			.edit {
				font-size: 14px;
				font-weight: 500;
				line-height: 1.6;
			}
			.apply-promo-code {
				.apply-promo-code-input {
					margin: 15px;

					md-input-container {
						margin: 0;
						width: 100%;
						position: relative;
					}
					.remove-promo {
						position: absolute;
						border: 0;
    					background-color: transparent;
    					color: #999999;
    					right: 0;
    					top: 5px;
    					outline: none;
    					&:hover {
    						color: #000000;
    					}
					}
				}
			}
			.promo-detail-wrapper {
				position: absolute;
  			top: 7px;
        right: 20px;
				.promo-tooltip {
					float: left;
					.discount-info-icon {
						width: 17px;
  					height: 17px;
  					display: block;
  					border: 2px solid #4399c5;
  					border-radius: 50%;
  					position: relative;
  					padding: 1px 6px;
 			 			box-sizing: border-box;
  					font-size: 10px;
            font-weight: bold;
  					color: #4399c5;
  					margin-right: 5px;
					}
				}
				.promo-detail {
					overflow: hidden;
					font-size: 14px;
					line-height: 1.6;
					font-weight: bold;
				}
			}
			.add-promo-code {
        color: #0e98df;
        margin: 10px 0;
        display: block;
      }
		}
	}

}


md-tooltip {
	background-color: #fff;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    width: auto;
    overflow: visible;

	&.discount-code-tooltip {


		h3 {
			font-size: 14px;
			line-height: 1.3;
			margin: 0;
			font-weight: 700;
		}
		p {
			font-size: 14px;
			line-height: 1.3;
			margin: 0;
		}
	}
}

.summary-mod {
	border-bottom: solid 1px #d7d7d7;
	h3 {
		margin: 15px 15px 0 15px;
		font-size: 16px;
	}
	.sub-title {
		margin: 5px 15px 15px 15px;
		font-size: 14px;
		line-height: 1.3;
	}
	p {
		margin: 15px;

		&:last-child {
			margin: 5px 15px 15px 15px;
		}
	}
	.two-col {
		p {
			margin: 15px 15px 0 15px;
		}
	}
	.detail {
		font-size: 14px;
		line-height: 1.3;
		margin: 5px 15px 15px 15px;
	}
	.edit-link {
		font-size: 14px;
		line-height: 1.6;
	}
}

.two-col {
	overflow: hidden;

	.unit {
		float: left;

		&.text-right {
			text-align: right;
		}

		&:last-child {
			float: none;
			overflow: hidden;
		}
	}
}
.total-summary {
  position: relative;
  display : block;
}
.cart-summary-wrapper {
  .expandable-button {
    padding: 0;
    margin: 0;
    vertical-align: middle;
    line-height: 100%;
    border-left: 1px solid #d7d7d7;

    a {
      background-image: url(/assets/images/down-arrow.png);
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      // background-position: center center;
      background-size: auto;
      width: 100%;
      height: 100%;
      padding: 10%;
      padding-top: 100%;
      display: block;
      box-sizing: border-box;
      background-color: transparent;
    }
  }
  &.expandable {
    .expandable-button {
      a {
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
      }
    }
  }

  md-progress-linear.apply-promo {
    top: 31px;
    height: 1px;
    position: absolute;

    div {
      height: 1px;
    }
  }

}


/*layout-sm*/
@media (max-width: 959px) {
  .cart-checkout-wrapper {
    padding: 0;
  }
	.summary-side-col {
		float: none;
    width: 100%;
    margin: 0;

    .cart-summary-wrapper {
      background-color: #ffffff;
      box-shadow: 0 4px 2px 0 rgba(22, 22, 22, 0.25);

      .total-summary {
        border-bottom: solid 1px #d7d7d7;

        .expandable-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .item-total {
          border-bottom: 0;
          margin: 0;
          padding: 20px 10px;
          border-right: 1px solid #d7d7d7;
          text-align: center;

          span {
            padding: 0;
          }
        }
        .price-summary {
          border-bottom: 0;
          margin: 0;
          padding: 20px 10px;
          .sub-total {
            padding: 0;
          }
          .promo-discount {
            padding: 8px 0 0 0;
          }
        }

      }
      .expandable-mod {
        display: none;
      }


      &.expandable {
        .expandable-mod {
          display: block;
        }
      }
      &.expandable-disabled {
        .expandable-mod {
          display: none;
          &.mobile-promo{
            display: block;
          }
        }
      }

      .free-shipping-wrapper {
        margin: 0 10px;


        .free-shipping-progress-bar {
          margin: 15px 40px 15px 0;
        }
        p {
          margin: 0;
          .shift-left {
            font-size: 12px;
          }
        }
      }
      .discounts-wrapper {
        p {
          margin: 0 10px;
        }
        .apply-promo-code {
          .apply-promo-code-input {
            margin: 0 10px;
          }
        }
        .promo-mobile-input{
          margin-bottom: 10px;
        }
      }

    }

	}
  .summary-side-col {
    .promo-banner-side-col {
      display: block;
    }
  }


}
/*layout-sm*/
@media (max-width: 599px) {
  .summary-side-col {
    .cart-summary-wrapper {
      .item-total span {
        font-size: 15px;
      }
      .total-summary .price-summary {
        font-size: 15px;
      }
      .free-shipping-wrapper {
        margin: 0 10px;
        .free-shipping-progress-bar {
          margin: 15px 40px 15px 0;
        }
        p {
          margin: 0;
          font-size: 12px;
          .shift-left {
            padding-left: 5px;
          }
        }
      }
      .discounts-wrapper {
        p.smaller {
          font-size: 12px;
        }
        p {
          font-size: 15px;
        }
        .apply-promo-code .apply-promo-code-input md-input-container {
          font-size: 15px;
        }
      }
    }
  }
}

@media print {
  .expandable-mod {
    display: block !important;
  }
}

.apply{
  min-width: 130px !important;
 background-color:  #0e98df;
}

.promo-dis{
  padding: 10px 16px;
  width: 70%;
  margin-left: 20px;
  .promo-chip{
    background-color: rgba(153, 153, 153, 0.28);
    padding: 4px 26px 4px 8px;
    border-radius: 4px;
    color: rgba(0,0,0,.54);
    position: relative;
    font-size: 13px;
    .promo-tip{
      position: absolute;
      right: 32px;
      top: 2px;
    }
    .promo-btn{
      position: absolute;
      right: -13px;
      top: -8px;
      color: #999999;
    }
  }
}

.apply-promo-code {
  .apply-promo-code-input {
    md-input-container .md-input::-ms-clear {
      display: none;
    }
  }
}

#discount-debugger-dialog {
	md-dialog {
		md-toolbar {
			.md-toolbar-tools {
				min-height: 80px;
			}

			min-height: 80px;
			border-bottom: solid 1px #ddd;
			/*box-shadow: 0px 10px 20px 0px #f5f5f5;*/
		}

		md-list-item {
			color: #777;
			line-height: 16px;
			&:hover {
				color: black;
				background-color: #ffffee;
			}
		}
	}
}
