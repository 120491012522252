.my-quick-order.quick-order{
  // z-index: 0;
  a {
    color: #669933;
    &.quick-order-restriction-msg{
      color: #000;
    }
  }

  @media only screen and (max-width: 959px) {
  	position: static;
  }

  .small-width-add-button {
    display: none;
  }

  // note: 359px is the minimum width where item code, quantity and add button looks fine
  @media only screen and (max-width: 359px) {
    .large-width-add-button {
      display: none;
    }
    .small-width-add-button {
      display: inherit;
      padding: 15px 15px 0 0;
      > button {
        width: 100%;
      }
    }
  }
}

.alert-message {
  vertical-align: middle;
  background-image: url(/assets/images/icons/attention-icon.png);
  background-repeat: no-repeat;
  padding: 0 0 0 24px;
}
