#removeItemsDialog {
  min-width: 309px;

  md-dialog-content {
    padding: 0;

    > div {
      padding: 0 20px 20px 20px;

      &.padding-top {
        padding-top: 20px;
      }
    }
  }

  .layout-column {
    display: flex;
  }

  .close-button {
    background-color: #fff;
    border: none;
    font-size: 29px;
    width: 27px;
    height: 27px;
    line-height: 2px;
    margin-right: 4px;
    outline: none;
    color: rgba(0,0,0,.38);

    &:hover {
      color: rgba(0,0,0,.54);
    }
  }

  md-progress-circular {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    text-align: center;
  }
}
