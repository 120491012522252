@alert-background-color: #4399c5;

.page-level-alert {
  position: relative;

  .page-level-alert-close-btn {
    position: absolute;
    right: 40px;
    top: 27px;
    background-color: transparent;
    border: 0;
    color: #ffffff;
  }
}
.alert-message-wrapper {
  position: relative;
  text-align: center;

  .alert-messaging {
    padding: 10px 25px 10px 35px;
    font-size: 18px;
  	line-height: 1.33;
  	text-align: center;
  	color: #ffffff;
    background-color: @alert-background-color;
    margin: 0;
    box-sizing: border-box;
    min-height: 79px;

    p {
      position: relative;
      padding: 0 0 0 45px;
      color: #ffffff;
      text-align: left;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        background-image: url('/assets/images/attention.png');
        background-repeat: no-repeat;
        width: 27px;
        height: 25px;

      }
    }

    p a{
      color: white;
      font-weight: bold;
    }

    .alert-me {
      position: relative;
      max-width: 1040px;
      margin: 0 auto;
      display: inline-block;
      padding-right: 40px;

      ul {
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
        }
      }
    }

    button {
      color: #eef2f5;
      text-align: center;
      width: 17px;
    	height: 14px;
      border: none;
      margin-left: 75px;
      right: 0;
      top: 17px;
      position: absolute;
      background-color: @alert-background-color;
      &:focus{
        outline: none;
      }
      &.page-level-alert-close-btn {
        height: 24px;
      }
    }

  }
  .ngTruncateToggleText {
      color: #d7d7d7;
      font-size: 90%;
      font-style: italic;
  }
}
.stick-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .alert-me {
    margin: 0 auto;
    max-width: 1050px;
  }
}
hero {
  .page-level-alert {
    top: 136px;
    position: absolute;
    z-index: 100;
    width: 100%;
  }
  .alert-message-wrapper  {
    .alert-messaging {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 960px)  {
  .alert-message-wrapper {
    z-index: 999;

    .alert-messaging {
      font-size: 14px;

    }

  }
  hero .page-level-alert {
    top: 0;
  }
}
