@font-family: 'Roboto', sans-serif;
@font-w-normal: 400;
@font-w-bold: 700;
@primary-color: #76bd23;
@secondary-color: #4399c5;
@warn-color: rgb(255,87,34);
@btn-primary-color: #ffffff;
@btn-primary-bg: #76bd23;
@btn-primary-bg-hover: #000000;
@btn-primary-radius: 0;
@btn-secondary-bg: #4399c5;
@btn-falt-color: #669933;
@link-color-green: #669933;
@link-color-secondary: #4399c5;
@disabled-color: #FFFFFF;
@disabled-link-color: #BEBEBE;
@disabled-color-btn-bg: #BEBEBE;
@disabled-color-flat-btn: #BEBEBE;
@disabled-color-dark: #E4E4E4;
@disabled-color-dark-btn-bg: #999999;
@invalid-color: #cc3366;
@error-color: #cc3366;
@warn-color: #cc3366;
@banner-btn-color : orange;

body,
html {
  font-family: 'Roboto', sans-serif;
}

p {
  color: #333333;
  margin: 1em 0;
  line-height: 1.3;
}

.primary-color {
  color: @primary-color;
}

.secondary-color {
  color: @secondary-color;
}

a,
a.primary {
  color: @link-color-green;
  text-decoration: none;

  &:hover {
    color: #000000;
    text-decoration: underline;
  }
  &.disabled {
    color: @disabled-link-color;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }
}

a.secondary {
  color: @link-color-secondary;
  &.disabled {
    color: @disabled-link-color;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }
}

.ee-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  .ee-container {
    max-width: 1140px;
    width: 100%;
  }
}

sup {
  font-size: 0.6em !important;
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  bottom: 1ex;
}

.strong,
strong {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.thing-form {
  margin: 20px 0;
}

.word-break {
  word-wrap: break-word;
}

.top-border {
  border-top: solid 1px #333333;
}

.uppercase {
  text-transform: uppercase;
}

#banner {
  border-bottom: none;
}

#banner h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
  margin-top: 150px;
}

.navbar-text {
  margin-left: 15px;
}

.mt120 {
  padding-top: 120px !important;
}

.pt120 {
  padding-top: 120px !important;
}

.nowrap {
  white-space: nowrap;
}

.btn-primary:hover:not(:disabled) {
  background-color: #000000;
  border-color: #000000;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;

  &.unit {
    li {
      display: inline-block;
      margin-right: 20px;

      &.left-divider {
        border-left: solid 1px #b7b7b7;
        padding-left: 20px;
      }
    }
  }
}

.interier-wrapper {
  .header-title {
    border-bottom: 1px solid #000000;
    font-size: 16px;
  }

  a {
    color: #4399c5;
  }

  h4 {
    margin: 10px 0;
    font-weight: 400;
  }

  p {
    margin: 10px 0;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}
/*layout-sm*/
@media (max-width: 959px) {
  .page-wrapper {
    padding-top: 0;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.button-center {
  .md-button {
    margin: 0 auto;
  }
}
.button-center-wrapper {
  margin: 0 auto;
}
/*layout-sm*/
@media (max-width: 959px) {
  .text-right-sm {
    text-align: right !important;
  }

  .text-left-sm {
    text-align: left !important;
  }

  .text-center-sm {
    text-align: center !important;
  }
}
/*layout-xs*/
@media (max-width: 599px) {
  .text-right-xs {
    text-align: right !important;
  }

  .text-left-xs {
    text-align: left !important;
  }

  .text-center-xs {
    text-align: center !important;
  }
}
/*media*/
.media-list {
  padding-left: 0;
  list-style: none;
}

.media,
.media-body {
  zoom: 1;
  overflow: hidden;
}

.media:first-child {
  margin-top: 0;
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
/*button*/
.md-button {
  letter-spacing: 0.2px;
}

.md-button,
.md-button.md-primary {
  background-color: @btn-primary-bg;
  color: @btn-primary-color;
  border-radius: @btn-primary-radius;
  padding: 0 15px;

  &.md-icon-button {
    background-color: #ffffff;
  }

  &[disabled] {
    background-color: @disabled-color-btn-bg;
    color: @disabled-color;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    color: @btn-primary-color;
    background-color: @btn-primary-bg-hover;
  }
  md-progress-circular {
    path {
      stroke: #ffffff;
    }
  }
}

.md-button.white {
  background-color: #ffffff;
  color: @primary-color;
  border-radius: @btn-primary-radius;
  padding: 0 15px;
  border: 1px solid @primary-color;
  &[disabled] {
    background-color: @disabled-color-btn-bg;
    color: @disabled-color;
    border: 1px solid @disabled-color-btn-bg;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    color: @btn-primary-color;
    background-color: @btn-primary-bg-hover;
    border: 1px solid @btn-primary-bg-hover;
  }
}

a.md-button,
a.md-button.md-primary {
  white-space: normal;
  line-height: 1.1;
  padding: 10px;

  &:hover {
    color: @btn-primary-color;
    background-color: @btn-primary-bg-hover;
  }
}

.md-button.md-no-bg {
  background-color: transparent;
  color: @link-color-green;
  border-radius: 0;
  box-shadow: none !important;

  &:hover {
    color: #000000;
    background-color: transparent;
    text-decoration: underline;
  }
}

.md-button.md-secondary {
  color: @btn-primary-color;
  background-color: @btn-secondary-bg;

  &[disabled] {
    background-color: @disabled-color-btn-bg;
    color: @disabled-color;
    pointer-events: none;
    cursor: default;
  }
  md-progress-circular {
    path {
      stroke: #ffffff;
    }
  }
}

.md-button.cancel-remove,
.md-button.md-cancel {
  background-color: #ffffff;
  color: @primary-color;
  border-radius: @btn-primary-radius;
  padding: 0 15px;
  border: 1px solid @primary-color;
  &[disabled] {
    background-color: @disabled-color-btn-bg;
    color: @disabled-color;
    border: 1px solid @disabled-color-btn-bg;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    color: @btn-primary-color;
    background-color: @btn-primary-bg-hover;
    border: 1px solid @btn-primary-bg-hover;
  }
}
.md-button.confirm-remove {
  background-color: @btn-primary-bg;
  color: @btn-primary-color;
  border-radius: @btn-primary-radius;
  padding: 0 15px;

  &.md-icon-button {
    background-color: #ffffff;
  }

  &[disabled] {
    background-color: @disabled-color-btn-bg;
    color: @disabled-color;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    color: @btn-primary-color;
    background-color: @btn-primary-bg-hover;
  }
}

.md-flat.md-button {
  background-color: transparent;
  color: @btn-falt-color;

  &:hover {
    background-color: rgba(158,158,158,0.2);
    color: #000000;
  }

  &[disabled] {
    background-color: transparent;
    color: @disabled-color-btn-bg;
    pointer-events: none;
    cursor: default;
  }
}

.md-button.add-more-btn {
  background-color: #f6f6f6;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #666666;
  margin: 0;
}

.md-button.no-style {
  min-width: auto;
  width: auto;
  background-color: transparent;
  padding: 0 10px;
  margin: 0;
  min-height: auto;
  height: auto;
}

.upload-button {
    background-color: @secondary-color;
    border: 1px solid @secondary-color;
    border-radius: 0;
    cursor: pointer;
    color: #fff;
    padding: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    min-height: 36px;
    min-width: 140px;
    line-height: 36px;
    display: inline-block;

    &:hover {
      color: @btn-primary-color;
      background-color: @btn-primary-bg-hover;
      border: 1px solid @btn-primary-bg-hover;
    }
    &[disabled],
    &.disabled {
      background-color: @disabled-color-btn-bg;
      color: @disabled-color;
      border: 1px solid @disabled-color-btn-bg;
      cursor: default;
      &:hover {
        color: #ffffff;
        background-color: @disabled-color-btn-bg;
        color: @disabled-color;
        border: 1px solid @disabled-color-btn-bg;
        cursor: default;
      }
    }

    input {
      left: -9999px;
      position: absolute;
    }
}

md-dialog-content .md-button {
  text-align: center;
  margin: 0 auto;
  display: block;
}

button[disabled],
html input[type="button"][disabled],
input[type="reset"][disabled],
input[type="submit"][disabled] {
  background-color: @disabled-color-btn-bg;
  color: @disabled-color;
}
/*toast*/
.md-toast-content {
  background: #333 url('/assets/images/checkmark-green.png') no-repeat 20px 27px;
  color: #fff;
  width: 217px;
  height: 61px;
  padding: 4px 44px !important;
}

/*datepicker*/
.md-datepicker-button {
  display: none !important;
}

._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: 0;
}

._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  left: 0;
}

.md-datepicker-input-container {
  margin-left: 0 !important;
}

.md-datepicker-calendar-pane.md-pane-open {
  z-index: 201;
}

.md-datepicker-triangle-button.md-button.md-icon-button {
  display: none;
}

.md-datepicker-input {
  width: 100%;
  max-width: initial;
}

._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
  width: 100%;
}
/*tab*/
.md-tab {
  text-transform: capitalize;
  touch-action: initial !important;
}

md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 430px;
}

md-tabs[md-border-top] md-tabs-wrapper {
  width: 554px;
  margin: 0 auto;
}

md-tabs[md-border-top] {
  .md-tab {
    width: 174px;
    padding: 0;
    border-top: solid 3px transparent;

    .ng-scope {
      padding: 10px 5px;
      display: block;
      border-top: solid 1px @primary-color;
      color: #999999;
    }

    &.md-active {
      border-top: solid 3px @primary-color;

      .ng-scope {
        color: #000000;
      }
    }
  }
}

.sub-tab {
  display: inline-block;
  border: solid 1px #cccccc;
  clear: both;
  padding: 0;
  margin: 10px 0;
  border-radius: 3px;

  li {
    display: inline-block;
    text-align: center;

    a {
      padding: 10px;
      margin: 0;
      background-color: #f5f5f5;
      color: #999999;
      display: block;
    }

    &.active {
      a {
        background-color: @secondary-color;
        color: #ffffff;
      }
    }
  }
}
@media (max-width: 599px) {
  md-pagination-wrapper,
  md-tabs-canvas {
    height: 145px;
    border-bottom: solid 1px @primary-color;
  }

  #discover-emerson, #support-for-your-practice{
    md-pagination-wrapper,
    md-tabs-canvas {
      border-bottom: none;
    }
  }

  md-tabs[md-border-top] {
    .md-tab {
      width: 100%;
      clear: both;
      font-size: 16px;
      text-transform: capitalize;

      .ng-scope {
        padding: 10px 5px;
      }
    }
  }

  md-tabs[md-border-top] md-tabs-wrapper {
    width: 100%;
  }

  p {
    font-size: 14px;
  }
}
/* tooltips */
md-tooltip {
  margin-top: 0;
}

md-tooltip {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  border-radius: 4px;
}

md-tooltip .md-content {
  height: auto;
}

md-toolbar.info {
  display: inline;
}

md-toolbar.info .md-button.md-fab {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  width: 13px;
  height: 13px;
  overflow: hidden;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
}

md-toolbar.info .md-button.md-icon-button md-icon,
md-toolbar.info button.md-button.md-fab md-icon {
  background: #fff url('/assets/images/icons/info-icon.png') no-repeat 0 0;
  width: 19px;
  min-width: 19px;
  height: 19px;
  min-height: 19px;
}

md-toolbar.info .md-button.md-icon-button:hover md-icon,
md-toolbar.info button.md-button.md-fab:hover md-icon {
  background-position: 0 -19px;
}

md-tooltip.info {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  border-radius: 4px;
}

md-tooltip.info:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 30px;
  margin-left: -30px;
}

md-tooltip.info .md-content.md-show,
md-tooltip.info .md-content.md-show-add-active {
  width: 232px;
  text-align: center;
  height: 79px;
}
/*progess bar*/
md-progress-linear .md-container,
md-progress-linear.md-default-theme .md-container {
  background-color: #cccccc;
}

md-progress-linear .md-bar,
md-progress-linear.md-default-theme .md-bar {
  background-color: @primary-color;
}

md-progress-linear.md-default-theme.md-warn .md-bar,
md-progress-linear.md-warn .md-bar {
  background-color: @warn-color;
}

md-progress-linear.free-shipping {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 38px;
    height: 21px;
    background-image: url("/assets/images/delivery-truck-sprite.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    position: absolute;
    right: -40px;
    top: -19px;
  }

  &.active:after {
    background-image: url("/assets/images/delivery-truck-sprite.png");
    background-position: 0 -21px;
  }
}
/*progess Circular*/
md-progress-circular {
  position: relative;
  display: block;
}

md-progress-circular.md-mode-indeterminate svg {
  -webkit-animation: indeterminate-rotate 1568.63ms linear infinite;
  animation: indeterminate-rotate 1568.63ms linear infinite;
}

md-progress-circular svg {
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
}

md-progress-circular path,
md-progress-circular.md-default-theme path {
  stroke: @secondary-color;
}

md-input-container.select-field.flex-100 {
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  md-input-container.select-field {
    margin-bottom: 20px;
  }
}
/*Fixed Safari bug on the small screen size*/
table.layout-column {

  tbody.flex-100 {
    display: block;
  }
}

@media (max-width: 959px) {
  .content-wrapper {
    margin: 0 15px;
  }
  .layout-sm-column {
    display: block;
  }

  table.layout-column {

    tbody.flex-100 {
      display: block;
    }
  }

  .layout-sm-column {
    display: block;
  }

  tbody.ng-scope.flex-100 {
    display: block;
  }

  td.flex-sm-100 {
    display: block;
  }

  ul.list-flex{
    display: block;
  }
}
@media (max-width: 599px) {
  .layout-xs-column {
    display: block;
  }

  td.flex-xs-100 {
    display: block;
  }
}
/*end fixed Safari bug*/
/*Flex items*/
.list-flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 0;
}

.list-flex-item {
  display: flex;
  padding: 0.5em;
}

.list-flex-item.col {
  margin-left: 0;
}

.row .list-flex-item.col {
  margin-left: 0;
}

.list-flex-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 100%;
}

.list-flex-content p {
  flex: 1 0 auto;
}

img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}

img.align-center {
  display: block;
  margin: 0 auto;
}

.right-divider {
  border-right: solid 1px #b7b7b7;
}

.left-divider {
  border-left: solid 1px #b7b7b7;
}

@media screen and (min-device-width: 320px) and (max-device-width: 700px) {
  md-tabs {
    * {
      touch-action: initial !important;
    }
  }
  .slider {
    * {
      touch-action: initial !important;
    }
  }

}
