.add-to-cart-quantity-button {
  //background-color: @btn-primary-bg;
  //border-radius: 10px;
  width: 103px;
  height: 40px;
  line-height: 30px;
  float: right;
  position: relative;

  &.disabled {
    background-color: @disabled-color-btn-bg;
    color: @disabled-color;
    a {
      text-decoration: none;
    }
  }
  .disabled-overlay {
    width: 100%;
    height: 30px;
    position: absolute;
    z-index: 1;
  }
  .quantity {
    display: block;
    width: 63px;
    height: 30px;
    line-height: 32px;
    color: #ffffff;
    float: left;
    border-left: 1px solid #ffffff;
    margin: 5px 0 5px 0;
    padding: 0 5px 0 5px;
    box-sizing: border-box;
    position: relative;

    &.dropdown {
      min-width: auto;
      background-color: transparent;
      border-radius: 0;
      border: 0;
      border-left: 1px solid #ffffff;

      div {
        float: none;
        .dropdown-display {
          background: transparent;
          border: 0;
          padding: 0;
          color: #ffffff;
          text-align: center;
          outline: none;
          display: block;

          .placeholder {
            display: inline-block;
            position: relative;

            &:before {
              background-image: url(/assets/images/arrow-quantity.png);
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              top: 0;
              left: -11px;
              content: " ";
              width: 7px;
              height: 33px;
            }
          }

          &:after {
            content: '';
          }
        }
        .dropdown-list {
          position: absolute;
          bottom: -5px;
          right: -5px;
          width: 158px;
          height: 90px;

          &.close {
            -webkit-transform: scale(1, 0);
            -moz-transform: scale(1, 0);
            -o-transform: scale(1, 0);
            -ms-transform: scale(1, 0);
            transform: scale(1, 0);
          }
          &.dropdown-open {
            -webkit-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            transform: scale(1, 1);
          }


           > div div{
            padding: 15px 20px 5px 20px;

            &:hover {
              background: transparent;
            }
           }
          .quantity-adjustment-wrapper {
            background-color: @btn-primary-bg;
            border-radius: 10px;
            height: 90px;
            .quantity-adjustment {

              .unit {
                float: left;
              }
              .rectangle-quantity {
                width: 30px;
                height: 30px;
                font-size: 16px;
              }
              .quantity-input {
                border: 0;
                border-bottom: 1px solid #619a36;
                background: transparent;
                width: 45px;
                height: 25px;
                margin: 5px;
                padding: 0;
                text-align: center;
                color: #ffffff;
                font-size: 16px;
              }
            }
            .add-to-cart {
              margin: 0;
              padding: 0;
              text-align: center;

              a {
                color: #ffffff;
                padding: 10px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}


