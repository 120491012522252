.brands-menu {
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    font-size: 16px;
    padding: 0 15px 0 0 !important;
    margin: 0 0 0 15px !important;

    //&.browse {
    //  padding-right: 0 !important;
    //  margin-right: 0 !important;
    //}

    &.line-divider {
      border-right: solid 1px #d7d7d7 !important;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

#herobrnds{
  top:45%;
}

.hero-desktop {
  display: block;
}

.brands-content{
  text-align: left;
  font-weight:400;
  font-size: 32px;
  margin: 25px 0;
}


.brands-columns {
  margin-bottom: 70px;
  ul {
    margin: 0;
    -moz-column-count: 3;
    -moz-column-gap: 50px;
    -webkit-column-count: 3;
    -webkit-column-gap: 50px;
    column-count: 3;
    column-gap: 50px;
    //-webkit-column-rule: 1px outset #d7d7d7;
    /* Chrome, Safari, Opera */
    //-moz-column-rule: 1px outset #d7d7d7;
    /* Firefox */
    //column-rule: 1px outset #d7d7d7;

    > li {
      margin: 0;
      padding: 0;

      a {
        color:#55a3c8;
        font-weight: 500;
        display: block;
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-device-width: 1000px) and (orientation:landscape){
  .hero-landscape {
    display: block;
    padding-top:50px;
    width:100%;
  }
  .hero-portrait {
    display: none;
  }

  .hero-desktop {
    display: none;
  }

}

@media screen and (max-device-width: 1000px) and (orientation:portrait){
  .hero-portrait {
    display: block;
    width:100%;
  }

  .hero-landscape {
    display: none;
  }

  .hero-desktop {
    display: none;
  }
}
