table {
  tr {
    &.credential-on-edit {
      border-bottom: 0;
    }

    &.dropzone-being-edited {
      padding: 15px 0;
    }
  }
}

.rx-dropzone-container {
	margin-left : 50px;
	.dropzone {
		float : none;
		width : 100%;
	}

	button {
		width: 120px;
		margin: 20px auto 0;
		display: block;
	}
}