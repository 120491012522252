// mobile css at the bottom
quantity-adjustment > .quantity-adjustment {
  button {
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    color: #79c143;
    background-color: #fff;
    border: 1px solid #999;
  }

  button.rectangle-quantity {
    width: 25px;
    height: 29px;
    box-sizing: border-box;

    &.disableClick {
      color: rgba(0,0,0,.38);
      //cursor: not-allowed;
    }
  }

  button.update-quantity-button {
    opacity: 0;
    margin-left: 8px;
    min-height: unset;
    height: 29px;
    font-size: 14px;
    line-height: 29px;

    &.active {
      opacity: 1;
    }
  }

  input.quantity {
    text-align: right;
    width: 40px;
    padding-right: 5px;
    height: 29px;
    border: 1px solid #999999;
    border-left: 0;
    border-right: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.0;
    color: #454553;
    box-sizing: border-box;
    -webkit-appearance: none;
  }

  p.small-text {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 15px;
  }

  .updating-quantity-progress-wrapper {
    width: 88px;
    margin-left: 8px;

    .updating-quantity-progress {
      height: 29px !important;
      width: 29px !important;
      margin: 0 auto;

      > svg {
        width: 29px !important;
        height: 29px !important;
        -webkit-transform-origin: center !important;
        -moz-transform-origin: center !important;
        -ms-transform-origin: center !important;
        -o-transform-origin: center !important;
        transform-origin: center !important;
      }
    }
  }

  @media (max-width: 599px) {
    div.layout-xs-column {
      align-items: center;
      display: flex;
      flex-flow: column;
    }

    button.rectangle-quantity, input {
      display: inherit;
    }

    button.rectangle-quantity {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      border-color: rgba(0,0,0,.31);

      &.decrement-button {
        padding: 0 12px;
        line-height: 31px;
        font-size: 31px;
      }

      &.increment-button {
        padding: 0 10px;
        font-size: 23px;
      }
    }

    input.quantity {
      height: 35px;
      width: 55px;
      padding: 0;
      text-align: center;
      font-size: 16px;
      margin: 0 5px;
      border: 1px solid rgba(0,0,0,.31);
      border-radius: 4px;
    }

    p.small-text {
      font-size: 12px;
      margin-top: 4px;
      width: 135px;
      text-align: center;
    }

    .update-quantity-button-wrapper .update-quantity-button {
      border-radius: 4px;
      border-color: rgba(0,0,0,.31);
    }
  }
}
