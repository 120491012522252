
.author-href { 
  width: 30%; 
  color: 
  #5DADE2; 
  font-weight: 500;
  &:hover { 
    text-decoration: none; 
    cursor: pointer;
  } 
}

.update-element-bottom-div{
  border-bottom: 3px solid #ddd;
  padding: 40px 0;
}

.author-table-div {
  padding: 0;
}
.active-page-number{
    &.active{
      color : #000;
    }
}
.prev-page-button, .next-page-button{
  cursor: pointer;
  &.disable{
      color:#ddd;
      cursor: default;
    } 
}

.author-table-div .author-th {
  text-transform: none;
}