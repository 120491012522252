.hero-quality {
  margin-top: -135px;
  width: 100%;
  overflow:hidden;
  height : 400px;
}

.hero-unit-quality {
    position: relative;

    h1 {
      font-size: 40px;
      font-weight: 300;
      line-height: 1.3;
      color: #333;
      line-height: 1;
      position: absolute;
      top: 50%;
      right: 50px;
      width: 60%;
      text-align: center;

      @media screen and (max-width: 959px) {
        width: 90%;
        font-size: 24px;
        left: 0;
        margin: 0 auto;
      }
    }

    h2 {
      font-size: 28px;
      font-weight: 300;
      line-height: 1.3;
      color: #333;
    }
  }