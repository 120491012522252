#value-prop-bar {
	background-color: #F6f6f6;
	margin: 30px 0;
  .value-prop-with-padding{
    .media-body{
        padding-left: 0px;
    }
  }
	.media-body {
		vertical-align:middle;
    padding-left: 10px;
	}
  .flex {
    -ms-flex:1 1 auto;
  }
	h3 {
    font-size: 18px;
    font-weight: 500;
    color: #666;
    text-align: left;
    margin: 0;
    max-width: 200px;
    height: auto;
  }
 	.layout-row{
    	width: 100%;
  }
	.product-summary{
	   &:extend(.flex-row);  
	   .align-row(center); 
	}
}
#value-prop-bar .media-list {
	margin: 20px 0;
  &:extend(.flex-row);  
  .align-row(center); 
  .product-thumbnail{
    display: block;
  }
}
#value-prop-bar .media {
    margin-top: 0;
    padding-right: 80px;
    &:first-child {
    	margin-top: 0;
    }
}

#value-prop-bar .dots-nav {
  text-align: center;
  z-index: 15;
  padding-left: 0;
  text-align: center;
  list-style: none;
}
#value-prop-bar .dots-nav .dots {
  padding-left: 0;
}
#value-prop-bar .dots li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: #999;
  border: 1px solid #fff;
  border-radius: 10px;
}
#value-prop-bar .dots li:hover {
  background-color: #000 \9;
  background-color: #444;
  border: 1px solid #fff;
}

#value-prop-bar .dots li.active {
  background-color: #000 \9;
  background-color: #444;
  border: 1px solid #fff;
}

#value-prop-bar .dots li:focus {
  outline:0;
}

#value-prop-bar [ng\:cloak],
#value-prop-bar [ng-cloak],
#value-prop-bar [data-ng-cloak],
#value-prop-bar [x-ng-cloak],
#value-prop-bar .ng-cloak,
#value-prop-bar .x-ng-cloak,
#value-prop-bar .ng-hide:not(.ng-hide-animate),
#value-prop-bar .ng-hide,
#value-prop-bar [ng-cloak] {
	display:block !important;
}

@media (max-device-width: 1024px) {
  #value-prop-bar .dots li:hover {
    background-color: #000 \9;
    background-color: #999;
    border: 1px solid #fff;
  }
  #value-prop-bar .dots li.active {
    background-color: #000 \9;
    background-color: #444;
    border: 1px solid #fff;
  }
}

@media (max-width: 959px) {
	#value-prop-bar {
		padding-bottom:10px;
	}
	#value-prop-bar .media {
		text-align:center;
		padding-right:0;
	}

  #value-prop-bar .ng-scope {
    min-width:unset;
  }

	#value-prop-bar .media-list{
		margin:10px 0 0;  
    .position-row(center);
	}

	#value-prop-bar .product-summary.media-body,
	#value-prop-bar .product-thumbnail.media-left {
		display:block;
    text-align:center;
	}
  
	#value-prop-bar h3 {
		text-align:center;
		margin:5px;
		font-size:21px;
    max-width: none;
		margin:0 auto;
	}

	#value-prop-bar [ng\:cloak],
	#value-prop-bar [ng-cloak],
	#value-prop-bar [data-ng-cloak],
	#value-prop-bar [x-ng-cloak],
	#value-prop-bar .ng-cloak,
	#value-prop-bar .x-ng-cloak,
	#value-prop-bar .ng-hide:not(.ng-hide-animate),
	#value-prop-bar .ng-hide,
	#value-prop-bar [ng-cloak] {
		display:none !important;
	}

  #value-prop-bar .dots li {
    width:20px;
    height:20px;
    margin:1px 10px;
  }
}
