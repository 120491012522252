.shipping-step {
  max-width: 796px;
  padding-top: 30px;
  color: #333333;

  .loading-wrapper{
    margin-top: 30px;
  }


  hr {
    background-color: #333333;
    border-top: solid 1px #333333;
    margin-bottom: 0;
  }

  .step-heading {
    margin-bottom: 0;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .star-text {
    margin-top: -10px;
    font-size: 14px;
    line-height: 2.0;
    text-align: right;
  }

  .address-form {
    color: #4399c5;
    padding-top: 15px;

    .margin-right-35 {
      margin-right: 35px;

      @media (max-width: 768px) {
        margin-right: unset;
      }
    }

    .error-msg {
      color: rgba(0,0,0,.87);
    }

    .type-wrapper {
      padding-top: 20px;

      .type-options-wrapper {
        position: relative;

        .info-icon-wrapper {
          padding: 15px 0 0 5px;
        }
      }

      .checkbox-set-default {
        padding-top: 30px;
        vertical-align: middle;

        .md-label {
          padding-top: 5px;
        }

        span {
          color: #333333;
        }
      }
    }

    .select-exist-address {
      font-size: 16px;
      font-weight: 500;
      line-height: .9;
      letter-spacing: .2px;
      text-align: left;
      color: #4399c5;
      margin: 0;
      border: 1px solid #4399c5;
      border-radius: 4px;
      background-color: white;

      span {
        padding-left: 5px;
      }

      img {
        margin-left: 5px;
      }
    }

    #btnAddWrapper {
      @media(max-width: 768px) and (min-width: 599px) {
        max-width: 37%;
      }
      .add-new-address-btn{
        color: #D3D3D3;
        border: 1px solid #D3D3D3;
      }
    }
  }

  .save-address-wrapper,
  .view-options {
    p {
      max-width: 738px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
      text-align: left;
      margin-bottom: 40px;
    }

    button {
      padding: 2px 40px;
      margin: 30px 0;
    }

    .recommendation-address {
      md-checkbox {
        margin-bottom: 30px;

        span {
          padding-left: 10px;
          font-size: 16px;
          text-align: left;
          color: #333333;
          vertical-align: middle;
        }
      }
    }
  }

  .update-address {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.0;
    letter-spacing: 0.2px;
    text-align: left;
    padding-bottom: 30px;

    a {
      color: #4399c5;
    }
  }

  .delivery-options-wrapper {
    padding-top: 35px;
    .delivery-opt {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      color: #333333;
    }

    .shipping-container {
      padding-top: 20px;
      margin-bottom: 25px;

      .shipping-option {
        .md-label {
        color: #333333;
        }
      }
      .delivery-option {
        width:330px;
      }

      .delivery-option-cost{
        width:50px;
        text-align:right;
      }

      .delivery-option-free{
        width:50px;
        text-align:right;
        color: #79c143;
        font-weight: bold;
      }

      .warning-content {
        position: relative;
        padding-top: 10px;
        padding-left: 40px;
        font-size: 14px;
        line-height: 1.14;
        color: #333333;

        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          background-image: url('/assets/images/flame.png');
          background-repeat: no-repeat;
          width: 27px;
          height: 25px;
        }
      }
    }

    .shipping-cutoff-msg {
      font-weight: 300;
      margin-bottom: 30px;
      margin: 20px;
      ul {
        padding-left: 18px;
        font-size: 13px;
        li {
          line-height: 1.4em;
        }
      }
      p {
        font-size: 13px;
      }
    }
  }

  .shipping-cost-override {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.38;
    text-align: left;
    float: left;

    p {}

    hr {
      width: 360px;
    }

    span {
      font-size: 12px;
      line-height: 2.33;
      margin-bottom: 0;
    }



    md-input-container {
      margin-top: 5px;
      margin-bottom: 40px;
      md-label:not(.md-no-float):not(.md-container-ignore) {
        font-size: 16px;
        font-weight: normal;
        color: #333333;
        vertical-align: middle;
      }
      input {
        width: 66px;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #4399c5;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #4399c5;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #4399c5;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: #4399c5;
        }
      }
    }
  }

  md-select {
    .md-select-value {
      border-bottom: 1px solid rgba(0,0,0,.12);
    }

    &:not([disabled]):focus .md-select-value {
      padding-bottom: 0;
      border-bottom-width: 1px;
    }
  }

  .uppercase {
    text-transform: uppercase;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }
}

#recommend-address {
  margin-bottom: 10px;

  .top-border {
    border-top: solid 1px #d7d7d7;
  }

  md-dialog md-dialog-content {
    padding: 0;
    margin: 0;
    padding: 0 0 50px;

    table {
      width: 100%;
      font-size: 16px;
      text-align: left;
      color: #333333;
      outline: none;

      &.original-table {
        border-bottom: 0;
        padding-bottom: 8px;

        tr {
          border-bottom: 0 !important;

          td {

          }
        }
      }

      tbody {
        background-color: #f2f2f2;
        outline: none;
        cursor: pointer;

        &:last-child {
          border-bottom: 0;
        }

        &.highlight-table {
          background-color: #ffffff;
          outline: none;
        }

        tr {
          border-bottom: solid 1px #d7d7d7;
          padding: 0 0 0 55px;
          outline: none;

          td {

            .checked-mark {
              text-align: left;
              padding-top: 7px;
            }

            &.original-title-text {
              padding: 20px 0 0 0;
              font-size: 24px;
              font-weight: 500;
              line-height: 1.0;
              letter-spacing: 0.2px;
            }
          }
        }
      }
    }

    .md-label {
      width: 100%;
    }

    .original-actions-tab {
      min-height: 75px;
    }
  }

  &.md-dialog-container {
    font-size: 16px;
    color: #333333;

    .title-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.0;
      letter-spacing: 0.2px;
      margin-top: 0;
      padding-left: 105px;
    }

    .suggestion-text {
      padding-left: 80px;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.33;
      text-align: left;
    }

    p {
      padding-left: 50px;
    }

    .selected-address-button {
      text-align: center;

      button {
        padding: 3px 40px;
      }
    }

    md-dialog md-toolbar {
      min-height: 110px;

      .md-toolbar-tools {
        padding: 20px 0 0 30px;
        text-align: left;
        .title-text {
          padding: 0 23px;
        }
      }
    }
  }

  .loading-wrapper {
    margin: 50px auto;
  }
}
#quantity-reduce {
  md-dialog md-toolbar {
    min-height: 170px;
    .tools-header {
      font-size: 32px;
    	font-weight: 500;
    	line-height: 1.12;
      position: relative;
      margin-top: 20px;
    }
    .sub-header {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.33;
      padding: 0 7%;
      position: relative;
    }
  }
  hr {
    width: 100%;
    // background-color: #d7d7d7;
  	border: solid 1px #d7d7d7;
  }
  .tab-wrapper {
    left: 0;
    bottom: 0;
    position: relative;
    width: 100%;
    height: 75px;
    box-sizing: border-box;

  }
  .header-thead {
    padding: 15px 60px 5px 60px;
    font-size: 16px;

    .smaller {
      font-size: .75rem;
    }
  }
  .border-bottom {
    border-bottom: solid 1px #d7d7d7;
  }
  .adjustment-wrapper {
    tr {
      td {
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

md-dialog md-dialog-actions.tab-wrapper-dialog-actions {
  padding: 0px;
  border-top-width: 0px ;
  border-top-style: none;
  min-height: 75px;
}

.tab-wrapper-button {
  padding: 10px 0px;
}

.myCart-wrapper {
  .checkout-actions-tab {
    ul li {
      a {
        &.disabled,
        &[disabled] {
          pointer-events: none;
          cursor: not-allowed;
          border: 1px solid @disabled-color-btn-bg;
          color: @disabled-color-dark;
          background-color: @disabled-color-dark-btn-bg;
        }
      }
    }
  }
}

.prop-65-warning {
  margin: 20px 0 30px;
}

.warning-icon {
  padding-left: 40px;
  background-image: url("/assets/images/icons/attention-icon-cart-process.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 20px 0;
  min-height: 30px;

  .bigger {
    font-size: 24px;
    line-height: 1.3;
  }

  p {
    margin: 0;
  }
}

.item-summary-table {
  margin: 30px 0;

  thead {
    tr {
      border-bottom: 0;

      td {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.2px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: solid 1px #d7d7d7;

      td {
        a {
          color: #333333;
          font-weight: bold;
        }
      }
    }
  }
}
.stock-status-change-warning {
  .approval-input {
    md-input-container {
      margin: 10px 40px 20px 40px;
    }
  }
}
/*Responsive table*/
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 599px){
  .shipping-container {
    flex-wrap: wrap;
    -moz-flex-wrap: wrap; /* mozilla */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */

    .shipping-option .md-label {
      font-size: 12px !important;
    }

    .delivery-option {
      padding-right: 70px !important;
    }
  }
}
@media only screen and (max-width: 959px) {
  .shipping-step {
    .star-text {
      display: none;
    }

    .address-form .type-wrapper {
      .checkbox-set-default {
        padding-top: 5px;
      }

      .type-options-wrapper {
        .info-icon-wrapper {
          left: 100%;
        }
      }
    }

    .delivery-options-wrapper {
      .shipping-container {
        .rectangle-wrapper {
          .rectangle {
            text-align: left;
            margin-bottom: 10px;
            padding: 15px;
            height: auto;

            p {
              float: right;
              position: absolute;
              right: 12px;
              top: 0;

              .dollar-sign {
                font-size: 12px;
                vertical-align: 15%;
              }
            }
          }
        }
      }
    }

    .address-form {
      .select-exist-address {
        width: 100%;

        img {
          float: right;
        }
      }
    }

    .view-options button {
      margin: 20px auto;
      width: 100%;
    }
  }

  .delivery-options-wrapper {
    .warning-content {
      display: none;
    }
  }

  #existing-address {

    md-list-item {
      &.bold-text {
        background-color: #ffffff;
        .trashcan {

        }
      }
    }

    md-dialog {
      width: 100%;

      md-toolbar {
        height: auto;
        min-height: 150px;

        p {
          font-size: 12px;
          padding: 25px 0 15px 0;
          text-align: center;

          &.info {
            font-size: 11px;
            padding: 0;
            margin: 5px 0;
          }
        }

        &.toolbar-height {
          min-height: 200px;
        }

        .shipping-address-list-wrapper {
          margin: 5px 0;
          font-size: 14px;

          .shipping-title {
            display: inline;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
          }

          .shipping-description {
            display: inline;

            p {
              font-weight: 300;
              font-size: 12px;
            }
          }

          md-tabs-wrapper {
            margin-top: 5px;

            md-pagination-wrapper,
            md-tabs-canvas {
              overflow: visible;
              border: none;
              height: 40px;
            }

            .md-tab {
              padding: 8px;
              font-size: 14px;
              text-transform: capitalize;

              //.addr-text { // removing this allows the tab "Addresses" to be displayed on mobile devices
              //  display: none;
              //}

              a.tooltip-container {
                &:hover {
                  display: none;
                }
              }
            }
          }

          .find-wrapper {
            margin: 0;

            .find-shipping {
              width: 100%;
              padding-top: 0;
              margin-top: 0;

              input {
                font-size: 10px;
              }

              .input-message {
                font-size: 10px;
              }
            }
          }
        }

        .md-toolbar-tools .md-close.md-button {
          background-color: transparent;

          span {
            color: #ccc;
            font-size: 20px;
          }
        }
      }

      md-dialog-content {
        .loading-wrapper {
          margin: 50px auto;
          text-align: center;

          md-progress-circular {
            margin: auto;
          }
        }

        .address-info-content {
          table {
            tr {
              // border: transparent 4px solid;
              position: relative;

              &.bold-text {
                font-weight: bold;
                background-color: #fff;
                td:last-child {
                  display: none;
                }
              }

              td {
                padding: 8px;
              }

              td:first-child {
                display: none;
              }

              td:nth-of-type(2) {
                // padding-bottom: 2px;
              }

              td:nth-of-type(3) {
                // padding-top: 0;
                max-width: 85%;
                font-size: 12px;
              }

              td:last-child {
                position: absolute;
                top: 0;
                right: 5px;
              }
            }
          }
        }
      }

      .checkout-actions-tab {
        background-color: #fff;

        .selected-ship-address-button button.md-button {
          padding: 0 30px;
        }
      }
    }
  }

  #recommend-address {
    md-dialog-content {
      .loading-wrapper {
        margin: 50px auto;
        text-align: center;

        md-progress-circular {
          margin: auto;
        }
      }
    }

    &.md-dialog-container {
      md-dialog {
        width: 100%;

        md-toolbar {
          .md-toolbar-tools {
            padding: 10px 0 0 0px;

            h2 {
              text-align: left;
            }
            p {
              text-align: left;
              padding: 5px 20px;
            }
          }
        }

        .title-text {
          font-size: 16px;
          padding-left: 20px;
        }

        .suggestion-text {
          font-size: 14px;
          padding-left: 0;

          br {
            display: none;
          }
        }

        .md-toolbar-tools .md-close.md-button {
          background-color: transparent;

          span {
            color: #ccc;
            font-size: 20px;
          }
        }

        table {
          &.original-table {
            border-bottom: solide 1px #d7d7d7;

            tr {
              border-bottom: 0 !important;
              padding-bottom: 0;
              padding-left: 20px;
            }
          }

          tbody {
            // border: solid 4px transparent;
            &.highlight-table {
              font-weight: bold;
              background-color: #fff;
              // border: solid 4px #79c143;
            }

            tr {
              padding-left: 20px;
              td {
                font-size: 14px;
                &.original-title-text {
                  font-size: 16px;
                  padding: 20px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }

  #quantity-reduce {
    md-dialog {
      max-height: 100%;
      md-toolbar {
        min-height: 140px;

        .md-toolbar-tools {
          .tools-header {
            font-size: 20px;
          }

          .sub-header {
            font-size: 10px;
            font-weight: 300;
            padding: 0;
          }
        }

        .md-toolbar-tools .md-close.md-button {
          background-color: transparent;

          span {
            color: #ccc;
            font-size: 20px;
          }
        }
      }
      .header-thead {
        padding: 5px 10px;
        font-size: 12px;
      }
    }
  }
}
.shipping-cost-override{
  h5{
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.38;
    text-align: left;
  }
  hr{
    margin-bottom: 28px;
  }
}

#shipping-address-selection{
  color:#999999;
  line-height: 2.3;
}

#new-address{
padding-left: 15px;
}

#selectAddressBtn {
  text-align: center;
}

#btn-add{
  font-size: 16px;
  font-weight: 500;
  line-height: .9;
  letter-spacing: .2px;
  text-align: center;
  color: #4399c5;
  margin: 0;
  border: 1px solid #4399c5;
  border-radius: 4px;
  background-color: inherit;
  width: 100%;
}

.shipping-card{
  width: 66%;
  margin: 35px 0;
  color: #333333;
  border-top: 1px solid rgba(0,0,0,.12);
  border-bottom: 1px solid rgba(0,0,0,.12);
  padding: 25px 16px;
  .info-detail-wrapper{
    div[layout="row"]{
      line-height: 1.7em;
    }
    .data-type{
      width: 20%;
    }
    .data-value{
      width:50%;
      font-weight:700;
    }
  }
  @media (max-width: 768px){
    .info-detail-wrapper{
      .data-type{
        width:40%;
      }
      .data-value{
        width:60%;
      }
    }
  }
}

.ship-wrapper{
  margin-top: 45%;
}

.custom-radio-button {
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
  }
  &:before {
    border: 1px solid #333;
    width: 100%;
    height: 100%;
  }
  &:after {
    position: absolute;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.checked-radio-btn .custom-radio-button:after {
  background: #4399c5;
}
.shipping-warning {
  color: #e00603;
}