.support-highlights {

  .hero-image-wrapper {
    height: 668px;
    overflow: hidden;
    margin-top: -137px;

    .hero-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 668px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .hero-image-text {
      position: absolute;
      right: 13%;
      top: 230px;

      h2 {
        font-size: 60px;
        margin-bottom: 20px;
        color: rgba(0,0,0,.56);
        text-align: center;
      }

      p {
        margin: 0;
        text-align: center;
        color: rgba(0,0,0,.56);
        font-weight: 300;
        font-size: 44px;
      }

      @media(max-width: 1300px) {
        right: 4%;
      }

      @media(max-width: 1161px) {
        h2 {
          font-size: 48px;
        }
        p {
          font-size: 34px;
        }
      }
    }

    @media(max-width: 599px) {
      height: 500px;

      .hero-image {
        height: 500px;
      }

      .hero-image-text {
        top: 185px;
      }
    }
  }

  .hero-image-mobile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 668px;
    background-color: rgba(255,255,255,0.37);
    z-index: 6;
  }

  .page-content-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 10px;

    >div {
      max-width: 1140px;
      padding: 0 15px;
      margin-bottom: 40px;
    }

    .video-description-wrapper {
      flex: 40;
      padding: 0 15px;

      h2 {
        font-size: 28px;
      }

      p {
        text-align: center;
      }
    }

    div.video-wrapper {
      flex: 60;
    }
  }

  @media(max-width: 959px) {
    .page-content-wrapper {
      margin-top: 33px;

      .video-description-wrapper {
        flex: none;

        h2 {
          font-size: 26px;
          margin-top: 0;
          margin-bottom: 15px;
        }
      }
    }

    .hero-image-wrapper .hero-image-text {
      z-index: 10;

      h2 {
        font-size: 38px;
        color: rgba(0,0,0,.85);
      }

      p {
        font-size: 30px;
        color: rgba(0,0,0,.85);
      }
    }

    .layout-sm-column {
      align-items: center;
    }

    div.video-wrapper {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 59%;
      flex: none;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .support-highlights {
    .page-content-wrapper {
      align-items: stretch;
    }
  }
  .support-highlights .page-content-wrapper {
    margin-left : 62px;
  }
}
