.view-more-btn {
  margin: 20px 0;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;

  &:before {
    background-image: url('/assets/images/view-more-arrow.png');
    background-repeat: no-repeat;
    content: '';
    width: 12px;
    height: 6px;
    position: absolute;
    left: 0;
  }
}

#my-credentials {
  width: 100%;
  tr {
    &.table-wrapper {
      border-bottom: 0;

      > td {
        padding-right: 0;
      }
    }
  }

  .dropzone {
    float: none;
    margin: 0 0 0 auto;
  }

  .dropzone-wrapper {
    padding-right: 0;
  }

  tbody {
    position: relative;

    &.being-edited {
      border-bottom: dashed 1px #999999;

      .credential-items {
        border-bottom: 0;
      }

      .dropzone-being-edited {
        border: 0;
      }
    }
  }

  .action-label {
    border-bottom: 0;
  }

  .delete-alert-box {}

  .credential-status {
    position: relative;
    padding-right: 0;
  }

  .credential-status-label {
    padding-right: 50px;
  }

  .credential-action {
    position: absolute;
    right: 0;
    top: 8px;
  }

  tr {
    &.on-adding:last-child {
      border-bottom: 0;
    }
  }
}

.add-another-btn {
  margin: 30px 0;
}
.md-dialog-container {
    &#add-credential {
      md-dialog {
        md-dialog-actions{
          display: block;
          width: auto;
          margin:0px auto 15px auto;
        }
      }
    }
  }

/*Responsive table*/
@media only screen and (max-width: 959px) {
  #my-credentials {
    .edit-delete-wrapper {
      padding: 10px 0;

      md-icon {
        min-width: 20px;
        width: 20px;
      }
    }

    .dropzone {
      width: 100%;
      box-sizing: border-box;
      margin: auto;
    }
  }
  .md-dialog-container {
    &#add-credential {
      md-dialog {
        md-dialog-actions{
          display: block;
          width: auto;
          margin:0px auto 15px auto;
        }
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  #my-credentials {
    thead {
      display: none;
    }
  }

  #my-credentials-edit {
    border-top: solid 1px #999999;
    /* Force table to not be like tables anymore */
    table,
    tbody,
    td,
    th,
    thead,
    tr {
      display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      display: none;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 0;
      position: relative;
    }

    tbody {
      position: relative;

      &.being-edited {
        .credential-items {
          border-bottom: 0;
        }

        .dropzone-being-edited {
          border: 0;
        }
      }
    }

    .credential-items {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: dashed 1px #999999;

      td {
        padding-right: 30px;
      }

      .label {
        font-size: 14px;
      }

      .edit-delete-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 0;
      }
    }

    .action-label {
      border: 0;
    }

    .delete-alert-box {
      border: 0;
    }
    /*
		Label the data
		*/
    /*td:nth-of-type(1):before { content: "Type"; }
		td:nth-of-type(2):before { content: "Document"; }
		td:nth-of-type(3):before { content: ""; }
    */
  }

  .time-period {
    margin: 10px 0;
    padding: 0;
  }
  .md-dialog-container {
    &#add-credential {
      md-dialog {
        max-height: 90%;
        min-height: 90%;
        md-dialog-actions{
          display: block;
          width: auto;
          margin: 0px auto 15px auto;
        }
      }
    }
  }
}
#add-credential {
      md-dialog {
        &._md.md-transition-in {
            transform: none;
        }
     }
}

.dropzone-container {
  float : right;
  width : 50%;
  
  .dropzone {
    float : none;
    width : 100%;
  }

  button{
    width: 120px;
    margin: 20px auto 0;
    display: block;
  }
}

@media screen and (max-device-width: 750px) and (min-device-width: 320px){
  .dropzone-container {
    padding-left : 15px;
    float : none;
    width: auto;
  }
}