.view-more-btn {
	margin: 20px 0;
	display: inline-block;
	padding-left: 15px;
	line-height: 1;

	&:before {
		background-image: url('/assets/images/view-more-arrow.png');
    	background-repeat: no-repeat;
    	content: '';
    	width: 12px;
    	height: 6px;
    	position: absolute;
    	left: 0;
	}
}


/*Responsive table*/
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 960px (Angular Material sm)
and also iPads specifically.
*/
@media only screen and (max-width: 960px), (min-device-width: 768px) and (max-device-width: 1024px)  {

	#my-rx-credentials {
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { 
      border: 1px solid #ccc;
      margin-bottom:15px;
    }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50%; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}
		
		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "Professional Designation"; }
		td:nth-of-type(2):before { content: "Document"; }
		td:nth-of-type(3):before { content: "Doc. Status"; }
		td:nth-of-type(4):before { content: ""; }
	}

	.time-period {
		margin: 10px 0;
    	padding: 0;
	}
}