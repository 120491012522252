.alternate-sizes-dialog {
  width: 80%;
  max-width: 400px;
  min-width: 768px;
  color: rgba(0, 0, 0, .87);
  padding: 30px;

  .layout-column {
    display: flex; // overwrites the global 'display: block' rule
  }

  md-dialog-content {
    padding: 0;

    .header {
      list-style-type: none;
      text-align: left;
      padding: 0px;
      margin-bottom: 5px;

      h2 {
        text-align: left;
        font-weight: 300;
        width: 95%;
        white-space: nowrap;
        margin: 10px 0 10px 0;
      }

      ul {
        padding: 5px;
      }

      li.title-wrapper {
        width: 125px;
        border-bottom: 4px solid #76bd23;
        text-align: left;
        overflow-x: visible;
      }

      li.header-text {
        font-size: 18px;
        text-align: left;
        margin: 15px 0px;
      }

    }



    .image-wrapper {
      width: 130px;
      text-align: center;

      img {
        max-width: 130px;
      }
    }
  }

  .close-button-wrapper {
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;

    .close-button {
      background-color: #fff;
      border: none;
      font-size: 42px;
      width: 42px;
      height: 42px;
      line-height: 2px;
      margin-right: 4px;
      outline: none;
      color: rgba(0, 0, 0, .38);

      &:hover {
        color: rgba(0, 0, 0, .54);
      }
    }
  }


  @media (max-width: 768px) {
    max-width: 400px;
    min-width: 95%;
    font-size: 14px;
    padding: 10px;
  }
}
