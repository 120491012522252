.alternate-sizes-item {
  padding: 12px 26px 12px 6px;
  margin-top: 15px;
  min-height: 100px;

  .image-wrapper img {
    height: 130px;
  }

  h3 {
    font-weight: 500;
    font-size: 22px;
  }

  .header-item {
    margin: 0;
    margin-bottom: 10px;
    text-align: left;
  }

  .quantity-wrapper .decrement-button,
  .quantity-wrapper .increment-button
  {
    border-radius: 25px;
    width: 30px;
    height: 30px;
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    color: #79c143;
    background-color: #ffffff;
    border: 1px solid #999;
    margin: 0px 5px 0px 5px;
  }

  .quantity-wrapper input {
      background-color: #fff;
      color: #000;
      padding: 0 4px;
      border-radius: 5px;
      height: auto;
      border: 1px solid #68a81f;
      margin-top: 4.5px;
      margin-bottom: 2px;
      max-width: 39px;
      text-align: right;
      -moz-appearance:textfield;
  }

  .quantity-wrapper .disable-click {
    cursor: not-allowed;
    border: 1px solid #bebebe;
    color: #e4e4e4;
  }

  .add-to-cart-row {
    padding-top: 10px;
    text-align: left;
    button {
      margin: 0px;
    }
  }

  .unit-price-wrapper {
    margin-left: 15px;
    font-size: 14px;

    .bold {
      font-weight: bold;
    }
  }

  .added-to-cart-success-msg {
    margin-left: 15px;
    font-size: 13px;
    color: rgba(0,0,0,.67);

    md-icon {
      color: #76bd23;
    }
  }

  .quantity-wrapper {

    .quantity {
      text-align: right;
      width: 40px;
      padding-right: 5px;
      height: 29px;
      border: 1px solid #999999;
      border-left: 0;
      border-right: 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      color: #454553;
      box-sizing: border-box;
      -webkit-appearance: none;

      @media (max-width: 599px) {
        height: 35px;
        width: 55px;
        padding: 0;
        text-align: center;
        font-size: 16px;
        margin: 0 5px;
        border: 1px solid rgba(0, 0, 0, 0.31);
        border-radius: 4px;
      }
    }
  }

  .md-icon-button {
    color: rgba(0,0,0,.54);
  }

  @media (max-width: 768px) {
    padding: 8px 8px 4px 8px;

    .image-wrapper > img {
      height: 125px;
      margin-left: -19px;
    }

    h3 {
      font-size: 18px;
    }

  }
}
