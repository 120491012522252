.custom-404{
  h2{
    font-weight:400;
    margin: 10px 0;
  }

  h4{
    margin-top: 0;
    text-align: center;
    font-weight: 300;
  }
  a:hover {
    text-decoration: none;
  }

  .redirect-info-container {
    max-width: 1140px;
    margin: auto;
  }

  .custom-order-404-info {
    max-width: 420px;
    margin: auto;
    margin-bottom: -50px;
    padding: 0 10px 0 10px;
    text-align: center;
    font-size: 16px;
    line-height: 120%;
    z-index: 1;
    position: relative;
  }

  .redirect-info-list {
    margin: 0;
    padding: 0 10px 0 10px;
    list-style-type: none;
    font-size: 16px;
    line-height: 120%;
    li {
      margin-bottom: 15px;
    }
  }

  .generic-redirect-image {
    max-width: 100%;
  }

  .redirect-image {
    max-width: 100%;
  }

  .redirect-image-container {
    text-align: center;
    .redirect-image {
      width: 500px;
      max-width: 100%;
      display: inline-block;
    }
  }
}


