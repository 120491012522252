/*dialog*/
md-backdrop.md-dialog-backdrop {
  background-color: #000000;
  z-index: 200;
}

.md-dialog-container {
  z-index: 201;
}

md-dialog {
  background-color: #ffffff;
  min-width: 80%;
  max-width: 1140px;

  .sub-title {
    font-size: 14px;
    font-weight: 300;
    color: #333333;
    margin-top: 0;
  }

  .uppercase {
    text-transform: uppercase;
  }

  a.md-button {
    background-color: transparent;
    color: #76bd23;

    &:hover {
      background-color: transparent;
      color: #000000;
      text-decoration: underline;
    }
  }

  md-dialog-actions {
    padding: 20px;
    border-top: 0;
    border-top-width: 0;
  }

  .md-button {
    &.left-border {
      border-left: solid 1px #b7b7b7;
    }
  }

  .md-toolbar-tools h2 {
    margin: 0;
    font-size: 32px;
    padding: 20px 20px 0;
    font-size: 300;
  }

  .md-toolbar-tools p {
    font-size: 16px;
    padding: 5px 80px;
    margin: 0 auto;
  }

  .md-toolbar-tools {
    .tooltip {
      p {
        padding: 5px;
      }
    }
  }

  .md-toolbar-tools .md-close.md-button {
    top: 0;
    background-color: #76bd23;
    border-radius: 0;
    margin-right: 0;
    right: 0;
    min-height: 34px;
    height: 34px;
    width: 34px;
    padding: 10px;

    span {
      color: #ffffff;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      line-height: 1;
    }
  }

  md-toolbar {
    min-height: 130px;

    .md-toolbar-tools {
      min-height: 130px;
    }
  }

  md-dialog-content {
    padding: 20px 60px;

    .md-dialog-content {
      padding: 0;

      .layout-row {
        td {
            display: block;
        }
      }
    }
  }

  md-input-container {
    margin: 15px 0 0;
  }
}

md-backdrop.md-select-backdrop {
  z-index: 203;
}

@media (max-width: 959px) {
  md-dialog {

    .md-toolbar-tools h2 {
      margin: 0;
      font-size: 20px;
      padding: 20px 20px 0;
    }

    .md-toolbar-tools p {
      font-size: 12px;
      padding: 5px 0;
    }

    md-dialog-content {
      padding: 10px 20px 20px;
    }

    md-toolbar {
      min-height: 125px;
      height: 125px;

      .md-toolbar-tools {
        min-height: 125px;
        height: 125px;
      }
    }

    &.ccForm {
      md-dialog-content {
        padding: 20px;
      }
    }
  }
}
@media (max-width: 599px) {
  md-dialog {
    .md-toolbar-tools {
      padding: 0 5px;
    }
  }
}
