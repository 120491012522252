.mail-container{
    background-color: #EFEFEF;
    float:left;
    width:100%;

.align-center {
    text-align: center;
}
.error{
    border:1px solid #EC1C24;
}

.join-our-mailing-list {	
    height: 51px;	
    padding-top:30px;	
    color: #000000;	
    font-family: Roboto;	
    font-size: 24px;	
    font-weight: 500;	
    line-height: 32px;
    text-align: center;	
    margin-bottom: 0;
 
}
.info-msg {
    height: 41px;	
    color: #000000;	
    font-family: 'Roboto', sans-serif;	
    font-size: 18px;	
    line-height: 28px;
    text-align: center;
    margin-bottom: 11px;
    margin-top:8px;
}
.input-box {
     height: 45px;	
     width: 382px;
    color: #666666;	
    font-family: 'Roboto', sans-serif;	
    font-size: 16px;
    line-height: 21px;	
    text-align: center;
    display:inline;
}
input:hover{
    border-color: #115EA5;
    border-width: 2px;
}
button:hover{
    border:2px solid #F6921E;
}

.inputbox-error {
    border: 2px solid #EC1C24;
}

.signup-button{
    height: 38px;	
    width: 160px;
    margin-top:32px;
    margin-bottom:39px;
    background-color: black;
    border-radius: 9px;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;	
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
}

.error-msg-mail {
    margin-top:1px;
    margin-bottom:0;
    color: #EC1C24;	
    font-family: 'Roboto', sans-serif;
    font-size: 14px;	
    line-height: 19px;	
    text-align: center;
    display:none;
}
.error-msg-mail.has-error{
    display:block;
}
.thankyou-msg {
    margin-top:41px;
    margin-bottom:49px;
    color: #000000;	
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}
button:disabled,
button[disabled]{
  background-color: #666666;
}




@media screen and (min-width: 768px) and (max-width: 1024px){
    .join-our-mailing-list{
        font-size: 20px;
        line-height: 26px;
    }

    .info-msg{
      font-size: 16px;
      height: 38px;
      margin-bottom: 26px;
    }

    .signup-button {
   
        width: 160px;
     
        font-size: 16px;
        
        line-height: 21px;
        height:38px;
        margin-top:33px;
       
    }
    .thankyou-msg {
        font-size: 16px;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px){
    .join-our-mailing-list{
        font-size: 20px;
        line-height: 26px;
        height:38px;
        margin-bottom:1px;
        padding-top:20px;
    }
    .info-msg{
        font-size: 14px;
        line-height: 22px; 
        margin: 0 48px 25px 49px;
      }

      .signup-button {
    
        width: 262px;    
        font-size: 14px;  
        line-height: 19px;

        margin-top:32px;
        margin-bottom:39px;
       
    }
    .input-box{
        height:38px;
        width: 263px;
        font-size: 14px;
        line-height: 19px;
        margin-top: 25px;
    } 
    .thankyou-msg {
        font-size: 14px;
        line-height: 22px; 
        margin: 25px 48px 0 49px;
    }
}
}