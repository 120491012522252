
// @link-color-green : #669933 ;

/*Auto complete*/
.nav-search-box {

  .search-box-area {
    width: 100%;
  }

	md-autocomplete.search-auto {
		width: 100%;
    button {
  		position: absolute;
      	right: 50px;
      	top: 3px;
  	}
    input:not(.md-input) {
  		font-size: 18px;
  	}
	}

	.md-whiteframe-1dp, .md-whiteframe-z1 {
		box-shadow: none;
	}

	.search-icon {
		position: absolute;
		right: 30px;
		top: 9px;
		border-left: 1px solid #b7b7b7;
		padding: 0 0 0 10px;
		width: 44px;
  	height: 30px;
		min-height: 24px;
		min-width: auto;
		background-color: transparent;
		margin: 0;
    z-index:99;

    &.md-button.md-primary:hover {
      background-color: transparent;
    }

		&:hover {
			background-color: transparent;
		}

		md-icon {
			vertical-align: baseline;
		}

		.emersonicon.emersonicon-search:before {
			width: 24px;
			height: 24px;
      margin-top: -10px;
		}
	}
}
.md-autocomplete-suggestions li {
  height: auto;
}
.md-not-found .type-ahead-search-m{
  > li{
    margin-top: 10px;
  }

}
.no-results-found,
.md-not-found .type-ahead-search-m{
  padding: 10px 10px;
  font-size: medium;
  color: #666666;

}
.md-virtual-repeat-container {
  &.search-container {
    min-height: 900px;
    height: 100%;
    overflow-y: scroll;
    .md-virtual-repeat-scroller {
      // width: 330px;
      // border-right: solid 1px #e6e6e6;
      overflow: visible;
      .md-virtual-repeat-offsetter {
        ul.md-autocomplete-suggestions {
        	padding-bottom: 40px;

      		li {
            line-height: 10px;
            margin: 0;
            padding: 0;
            &:focus, &:hover {
              background-color: #fff;
            }
            &:first-child {
              .seperate-line {
                border-top: 0;
              }
            }
            .show-list-search {
              .left {
                border-right: solid 1px #e6e6e6;
                overflow: hidden;
                padding: 0;
                margin: 0;
                .title {
                  margin: 5px;
                  padding: 8px 5px 5px 5px;
            			color: #666666;
                  cursor: default;
            		}
            		.item-metadata {
                  margin: 5px;
                  padding: 0;
              		color: @link-color-green;
              		line-height: 1;
                  &:focus, &:hover {
                    background-color: #f5f5f5;
                  }

                  .item-metastat {
                    padding: 0;
                    margin: 0;

                    div {
                      padding: 0;
                      margin: 0;
                      color: @link-color-green;
                    }

                  }
              	}
              }
            }
            .show-recent-search {
              .title {
                margin: 5px;
                padding: 8px 5px 5px 5px;
          			color: #666666;
                cursor: default;
          		}
              .item-metadata {
                margin: 5px;
                padding: 0;
            		color: @link-color-green;
            		line-height: 1;
                &:focus, &:hover {
                  background-color: #f5f5f5;
                }
                .item-metastat {
                  padding: 0;
                  margin: 0;

                  div {
                    padding: 0;
                    margin: 0;
                    color: @link-color-green;
                  }

                }
              }
            }
      		}
        }
      }
    }

  }

}
.seperate-line {
  // padding-top: 8px;
  border-top: solid 1px #e6e6e6;
}


// .type-ahead-search.md-autocomplete-suggestions {
// 	padding-bottom: 40px;
//
// 		li {
// 			margin: 5px 0;
// 		}
// 		.title {
// 			margin: 20px 0 10px 0;
// 			color: #666666;
// 		}
// 		.item-metadata {
// 		color: @link-color-green;
// 		line-height: 1.1;
//
// 		.highlight {
// 			font-weight: 700;
// 		}
// 	}
// }
