.require-tla-csa{
	padding: 5px;
}
#csa-options{
	margin-top: 10px;
	width : 98%;
	border-top : 20px solid #76bd23;
	border-bottom : 20px solid #76bd23;
	border-left : 6px solid #76bd23;
	border-right : 6px solid #76bd23;
	max-width : 796px;
}
.csa-panel-section .section-title{
	font-weight : bold;
	padding-top : 7px;
	padding-bottom : 7px;
	padding-left : 5px;
	background-color : #c2eb93;
}
.csa-panel-section .section-sub-title{
	font-weight : bold;
	padding-left : 5px;
	padding-top : 7px;
	padding-bottom : 7px;
}
.csa-panel-section .section-sub-title-value{
	padding-left : 5px;
	padding-top : 7px;
	padding-bottom : 7px;
}
.csa-panel-section .section-content{
	padding : 5px;
}
.csa-panel-section .order-origin{
	line-height : 30px;
	vertical-align : middle;
}
.hold-codes{
	column-count : 2;
	padding : 8px;
	font-size : 12px;
}
#edit-order-comment{
	textarea {
		width : 99%
	}
	md-checkbox{
		padding : 5px;
	}
}
.order-comments-select{
	width : 35%;
	max-width : 796px;
}
.order-comments{
	font-size : 12px;
}
#csa-panel{
	clear : both;
}