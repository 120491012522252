.myAccount-wrapper {
  h2 {
    font-size: 32px;
    font-weight: 300;
    color: #333333;
    text-align: left;
  }

  .header-title {
    border-bottom: 1px solid #000000;
    font-size: 16px;
  }

  .sub-title {
    margin-top: 30px;
  }

  p {
    color: #333333;
  }
  /*tab*/
  md-tabs[md-border-top] md-tabs-wrapper {
    margin: 0;
  }
}

.bottom-line-divider {
  border-bottom: solid 1px #333333;
  margin-bottom: 30px;
}

.main-tab {
  width: 100%;
  background-color: #f6f6f6;
  text-align: center;
  overflow: hidden;

  h1 {
    padding: 22px 0;
    margin: 0;
    font-size: 18px;
    color: #333333;
    line-height: 1.2;
  }
}

.main-content {
  padding: 50px 0;
}

.actions-wrapper {
  h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.1;
    color: #666666;
  }
}

.actions-list {
  overflow: hidden;
  margin-left: 0;
  padding-left: 0;

  li {
    width: 25%;
    float: left;
    margin-bottom: 10px;
    padding-left: 0;
    list-style: none;
    padding-right: 20px;
    box-sizing: border-box;
  }

  a.active {
    color: #000000;
    text-decoration: none;
  }
}

md-icon.trashcan {
  background-image: url('/assets/images/delete.png');
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-image: url('/assets/images/delete-hover.png');
  }
}

md-icon.pencil {
  background-image: url('/assets/images/edit.png');
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    background-image: url('/assets/images/edit-hover.png');
  }
}
