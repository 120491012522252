paging {
md-dialog .pagination md-input-container {
  margin-top: 0;
}

.pagination {
  margin-top: 10px;

  display: block;
  .page {
    .md-button {
      min-width: 45px;
    }
  }

  .md-fab{
    line-height: 36px;
    width: 36px;
    height: 36px;
    margin: 0 5px;
    z-index: 2;
  }

}
}