.cart-item {
  max-width: 796px;

  .cart-item-list-table {
    color: #333333;
    font-size: 12px;
    padding-bottom: 20px;

    @media (max-width: 599px) {
      min-height: unset;
    }
    @media (max-width: 1024px) {
      max-width: 704px;
    }
  }

  .uppercase-title {
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: left;
    color: #333333;
    text-transform: uppercase;
  }

  .quantity-adjustment-wrapper {
    text-align: center;

    .quantity-adjustment {
      padding-top: 30px;
      text-align: center;
      display: inline-block;
      box-sizing: border-box;
      margin: 0 auto;
      position: relative;

      &.disabled {
        opacity: .5;
        cursor: not-allowed;
        button {
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      button {
        font-size: 23px;
        font-weight: 300;
        text-align: center;
        color: #79c143;
        background-color: #ffffff;
        border: 1px solid #999999;
        box-sizing: border-box;
        float: left;

        &.rectangle-quantity {
          width: 25px;
          height: 29px;
          box-sizing: border-box;

          &:focus {
            outline: none;
          }
        }
        &.disableClick {
          cursor: not-allowed;
          border: 1px solid #BEBEBE;
          color: #E4E4E4;
          background-color: #d7d7d7;
        }
      }

      .quantity {
        text-align: right;
        padding-right: 5px;
        width: 40px;
        height: 29px;
        border: 1px solid #999999;
        border-left: 0;
        border-right: 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.0;
        color: #454553;
        box-sizing: border-box;
        display: inline-block;
        float: left;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }

        &:focus {
          outline: none;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      }
    }

    .update-quantity-button {
      opacity: 0;
      position: absolute;
      right: -100px;
      font-size: 14px;
      line-height: 14px;
      min-height: 29px;
      margin: 0;
      cursor: default;
      &[disabled]{
        background: #BEBEBE;
        color: #fff;
        pointer-events: none;
      }
      span {
        font-size: 14px;
        line-height: 14px;
      }

      &.active {
        opacity: 1;
        transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -webkit-transition: opacity .5s ease-in-out;
        overflow: hidden;
        cursor: pointer;
      }
    }

    .unit-price-text {
      .unit-price {
        font-size: 16px;
        //padding-top: 17px;
        line-height: 1.12;
        color: #333333;
        //margin-bottom:0px;
        padding: 3px 0px;
        margin: 10px auto;
      }
      .override-unit-price{
        font-size: 12px;
        text-transform: capitalize;
        &.md-button{
            padding: 0 5px;
            min-width: 10px;
            min-height: 10px;
            line-height: 20px;
        }
        &.apply.md-button{
          margin-right: 0px;
        }
      }
      .edit-unit-price{
        display: block;
        width: 90px;
        font-size: 14px;
        margin: 10px auto;
        float:none;
      }
    }
    .out-of-stock {
      color: red;
      font-size: 10px;
    }
  }

  .warning-content {
    padding: 10px 0;
    text-align: left;
    font-size: 14px;
    line-height: 1.14;
    text-align: left;
    color: #333333;

    .warning-text {
      padding: 5px 0 0 10px;
      width: 100%;

      .warning-m-view {
        display: none;
      }
    }
  }

  .sort-by {
    font-size: 18px;
    line-height: 1.56;
    text-align: left;
    color: #4399c5;
    margin-bottom: -10px;
    float: left;
    max-width: 170px;

    md-select {
      min-width: 180px;
    }
  }

  .delete-item {
    text-align: center;
    width: 20px;
    height: 21px;
    color: #ff0000;
    border: none;
    float: right;
    background-color: #ffffff;
    background-image: url('/assets/images/light-gray-trash-can-icon.png');
    background-repeat: no-repeat;
    background-position: center;

    &:focus {
      outline: none;
    }
  }

  .item-total-wrapper {
    text-align: right;
    color: #454553;

    .item-total {
      font-size: 18px;
      line-height: 1.33;
      padding-top: 45px;

      .total-cost-text {
        display: none;
      }
    }
    .loading-wrapper {
      padding-top: 45px;
      &.no-padding {
        padding-top: 45px;
      }
    }

    .total-after-discount {
      font-weight: bold;
      font-size: 18px;
      padding: 5px 0;
    }

    .discount-item {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
    	color: #666666;
    }

    .product-discount {
      text-decoration: line-through;
      font-weight: 300;
      font-size: 16px;
      color: #666666;
    }
  }

  .brand-detail {
    margin: -30px 0 10px;

    thead {
      text-align: center;

      .text-total {
        text-align: right;
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #999999;
      }
    }
  }

  .product {
    padding: 10px 0 0;

    .product-description {
      font-size: 16px;

      .order-name {
        font-weight: bold;
        line-height: 1.12;
        text-align: left;
        color: @link-color-green;

        a {
          color: @link-color-green;
        }
      }

      p.category-title {
        font-size: 14px;
        line-height: 1.14;
        text-align: left;
        font-weight: normal;
      }

      p.item-code {
        font-size: 14px;
        line-height: 1.14;
        text-align: left;
        font-weight: normal;
      }
      // .unit-price-view {
      //   display: none;
      // }
    }

    .productImg {
      text-align: center;

      img {
        margin: 0 auto;
        max-width: 100%;
        word-break: break-all;
        font-size: 14px;
      }
    }
  }

  .sorted-by {
    margin-bottom: 30px;

    table {
      margin-bottom: 15px;

      thead {
        tr {
          margin-top: 5px;
          border-top: solid 2px #999999;
        }

        .text-quantity {
          text-align: center;
        }

        .text-total {
          text-align: right;
          padding-right: 0;
        }
      }

      tbody {
        tr {
          border-bottom: solid 1px #999999;

          .contentWarning {
            border-top: solid 1px #444;
          }
        }
      }
    }
  }

  .brand-detail,
  .sorted-by {
    tbody {
      tr {
        &.product-warning-tr {
          border-bottom: 0 !important;
        }

        &.remove-warning-wrapper {
          padding: 50px 0 50px 30px;

          button {
            padding: 0 45px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.43;
            letter-spacing: 0.1px;
          }
        }

        button.cancel-remove {
          background-color: #fff;
          border: solid 1px #79c143;
          color: #79c143;
          margin-right: 0;
        }

        &.product-warning {
          border-bottom: 0 !important;

          td {
            padding: 0;
          }

          td:nth-of-type(1) {
            border-bottom: 0 !important;
          }

          td:nth-of-type(2) {
            border-top: 1px solid #999999;
          }

          &:last-child {
            border-bottom: solid 1px #999999 !important;
          }
        }
      }
    }
  }

  .empty-order {
    text-align: center;

    h1 {
      padding: 100px 0 80px;
      margin: 0;
    }

    a {
      padding: 15px 40px;
      margin: 0 0 80px;
    }
  }

  .loading-wrapper {
    text-align: center;
    margin: 30px 0;
    padding: 40px 0;
    &.no-padding {
      padding: 0;
      margin: 0;
    }
  }

  .mobile-express-checkout{
      md-progress-circular path,
      md-progress-circular.md-default-theme path{
      stroke: #ffffff;
      }
  }
}

@media only screen and (max-width: 599px) {
  .cart-item {
    .quantity-adjustment-wrapper {
      text-align: right;
      .out-of-stock {
        margin: 0;
        margin-bottom: 10px;
      }
      .quantity-adjustment {
        text-align: right;
        padding-top: 0;
        button.rectangle-quantity, input.quantity {
          display: block;
          float: none;
          margin: 10px auto;
          padding: 0;
          width: 60px;
          text-align: center;
          &:first-child {
            margin-top: 0;
          }
        }
        input.quantity {
          border-left: 1px solid #999;
          border-right: 1px solid #999;
        }
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .cart-item-list-table {
    margin: 0 15px;
  }

  .cart-item {
    md-progress-circular {
      margin: auto;
    }

    .brand-detail {
      padding: 0;

      table {
        thead {
          tr:first-child {
            display: none;
          }
        }

        tbody {
          &:last-child {
            tr {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .sort-by {
      margin-left: 15px;
    }

    .sorted-by-wrapper {
      border-top: solid 1px #d7d7d7;
      padding: 5px 0;
    }

    .sorted-by {
      padding: 0 15px;
      border-top: solid 1px #d7d7d7;

      table {
        thead {
          tr {
            border-top: 0;
            border-bottom: solid 1px #d7d7d7;
            position: relative;

            td {
              padding-top: 0;
            }
          }

          .brand-sub-total {
            float: left;
          }

          .items-quantity {
            position: absolute;
            right: 0;
            top: 4%;
          }

          .text-quantity,
          .text-total {
            display: none;
          }
        }

        tbody {
          tr {
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      &:last-child {
        border-bottom: solid 1px #d7d7d7;

        table {
          tbody {
            tr {
              &.product {
                border-bottom: 0;
              }
            }
          }
        }
      }

      .cart-item-list-table {
        margin: 0;
      }
    }

    .brand-detail,
    .sorted-by {
      margin: 10px 0;
      border-top: solid 1px #d7d7d7;
      border-bottom: solid 1px #d7d7d7;

      table {
        tbody {
          tr {
            border-bottom: solid 1px #b7b7b7;
            position: relative;

            &.product {
              min-height: 130px;
              padding-bottom: 0;
              margin-bottom: 0;

              .productImg {
                img {
                  font-size: 10px;
                }
              }
            }

            br {
              display: none;
            }

            .product-description {
              font-size: 16px;

              p {
                padding: 2px 0;
                margin: 2px 0;

                &:last-child {
                  padding-bottom: 15px;
                }

                &.category-title {
                  font-size: 14px;
                  padding-bottom: 0;
                }
              }

              .item-total {
                font-size: 14px;
              }
            }

            &.remove-warning-wrapper {
              padding: 10px 0;
              border-bottom: solid 1px #b7b7b7;
            }
          }

          .warning-content {
            .warning-text {
              padding: 0;

              .warning-m-view {
                display: inline-block;
              }

              .warning-d-view {
                display: none;
              }
            }
          }
        }
      }

      .quantity-adjustment-wrapper {
        .unit-price-text {
          display: none;
        }

        .update-quantity-button {
          position: relative;
          right: 0;
        }
      }
    }

    .sorted-by {
      border-top: 0;
      border-bottom: 0;
    }

    .item-total-wrapper {
      text-align: left;
      font-size: 14px;
      .delete-item {
        float: left;
      }

      .item-total {
        position: absolute;
        bottom: 18%;
        left: 66%;
        font-weight: 700;

        .total-cost-text {
          display: block;
          font-weight: 300;
          line-height: 0.89;
          text-align: right;
          color: #333333;
          padding-bottom: 2px;
        }
      }

      .product-discount {
        text-decoration: line-through;
        font-size: 14px;
      }

      .discount-item,
      .total-after-discount {
        display: none;
      }
      .loading-wrapper {
        padding-top: 0;
        &.no-padding {
          padding-top: 0;
        }
      }
    }

    .empty-order {
      h1 {
        font-size: 20px;
        line-height: 0.81;
        letter-spacing: 0.3px;
        text-align: center;
        color: #333333;
        padding: 100px 0 80px;
      }

      a {
        width: 100%;
        padding: 15px 40px;
        margin: 0;
      }
    }
  }

  .bottom-line-divider {
    border-bottom: solid 1px #d7d7d7;
  }

  .empty-order {
    padding: 0 15px;
  }
}

/*more less links*/
.ngTruncateToggleText {
  font-weight: 500;
  color: #4399c5;

  &:hover {
    cursor: pointer;
  }
}
