.myAccount-wrapper {
  .primary-title {
    margin-bottom: 15px;
  	h2 {
  		font-size: 30px;
  		font-weight: 300;
  		color: #333333;
  		text-align: left;
    	clear:both;
    	margin: 0;
  	}
    .required-wrapper {
      font-size: 14px;
    	line-height: 2.0;
    	text-align: right;
    	color: #333333;
      margin-top: 20px;
    }
    .md-button {
      margin-top: -10px;
    }
  }
	.header-title {
		border-bottom: 1px solid #000000;
		font-size: 16px;

		&.no-underline {
			border-bottom: 0;
		}
    &.header-for-credit-card-list {
      margin-top: 40px;
      margin-bottom: 0;
    }
	}
	.sub-title {
		margin-top: 30px;
	}
	p {
		color: #333333;
	}
	/*tab*/
	md-tabs[md-border-top] md-tabs-wrapper {
		margin: 20px 0;
	}
  md-tabs[md-border-top] .md-tab {
    width: 277px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.2px;
    .ng-scope {
      color: #999999;
    }
  }

  md-checkbox {
    border-collapse: separate;
    border-spacing: 0;
  }
  md-tab-content {
    min-height: 600px;
    padding-right: 3px;
  }

}
.bottom-line-divider {
	border-bottom: solid 1px #333333;
	margin-bottom: 30px;
}
.main-tab {
	width: 100%;
	background-color: #f6f6f6;
	text-align: center;
	overflow: hidden;

	h1 {
		padding: 22px 0;
		margin: 0;
		font-size: 18px;
		color: #333333;
		line-height: 1.2;
	}
}
.main-content {
	padding: 50px 0 50px 0;
	//position: relative;

  .md-button {
    color: #ffffff;
    a:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
.welcome-user-module {
  overflow: hidden;
  margin: 10px 0;
  display: block;

  .welcome-user-header {
    display: inline-block;
  	font-size: 32px;
  	font-weight: 500 !important;
    word-break: break-all;
    box-sizing: border-box;
    width: 70%;
    vertical-align: top;

    .text-medium {
      font-size: 16px;
    }
    .not-you-sign-out {
      margin-top: 10px;
      font-weight: 300;
    }
    .sign-out {
      color: #669933;
      &:hover {
        color: #000000;
        text-decoration: underline;
      }
    }
    .welcome-name {
      display: inline;
      font-size: 32px;
      font-weight: 500;
    }
  }
}
.actions-wrapper {
	margin-top: 20px;
  margin-bottom: 40px;

	h3 {
		font-size: 15px;
		font-weight: 500;
		line-height: 1.1;
		color: #666666;
    letter-spacing: 1.4px;
    text-transform: uppercase;
	}
}
.actions-list-wrapper {

	margin-right: 265px;

	.actions-list {
		overflow: hidden;
		margin-left: 0;
	    padding-left: 0;


		li {
			width: 33.33%;
			float: left;
			margin-bottom: 10px;
	    	padding-left: 0;
	    	list-style: none;
	    	padding-right: 20px;
	    	box-sizing: border-box;
	    	position: relative;

	    	a {
	    		position: relative;
	    		/*padding-left: 10px;*/
          padding-left: 0;
	    		display: inline-block;
	    		text-decoration: none;
          letter-spacing: 0.2px;
          color: @link-color-secondary;
	    	}
	    	a:before {
	    		/*content: '-';
	    		margin-right: 5px;
	    		display: block;
	    		position: absolute;
	    		left: 0;
	    		text-decoration: none;*/
	    	}

	    	&:nth-of-type(4) {
	    		clear: left;
	    	}
	    	&:nth-of-type(7) {
	    		clear: left;
	    	}
		}

		a.active {
			color: #000000;
			text-decoration: none;
		}

	}
}

.tabs-section {
  padding: 20px 0 24px 0;
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 0;
    li {
      display: inline-block;
      width: 277px;
      height: 40px;
      font-size: 18px;
      border-top: solid 1px @primary-color;
      list-style: none;
      padding: 0;
      margin: 0;
      a {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 18px;
        font-weight: normal;
        line-height: normal;
	      letter-spacing: 0.4px;
        text-align: center;
        padding-top: 11px;
        color: #999999;
        -webkit-transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
        transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
        &:hover {
          cursor: pointer;
        }
        &:active, &:focus {
          background-color: #ccc;
          color: #666;
        }
      }
      &.active {
        border-top: solid 4px @primary-color;
        a {
          color: #000;
        }
      }
    }
  }

}

.my-orders-list.actions-list {
	li {
		float: none;
	}
}

md-icon.trashcan {
	background-image: url('/assets/images/delete.png');
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		background-image: url('/assets/images/delete-hover.png');
	}
  &.gray {
    background-image: url('/assets/images/icons/delete-gray-color.png');
    background-size: 12px 16px;
  }
}
md-icon.pencil {
	background-image: url('/assets/images/edit.png');
	background-repeat: no-repeat;
	background-position: center;

	&:hover {
		background-image: url('/assets/images/edit-hover.png');
	}
}

.autoheight {
  height: 350px;
  // height: auto;
}

.quick-order {
  right: 0;
  width: 422px;
	background-color: #f6f6f6;
	z-index: 2;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
	float: right;
	border-radius: 0;
	box-sizing: border-box;
  .quick-order-mod {
    padding: 20px;
    padding-top: 0;

    form {
      margin-bottom: 10px;
    }

    .unit {
      align-self: flex-end;

      &:last-child {
        margin-right: 0;
      }

      button {
        margin-right: 0;
        margin-bottom: 0;
        margin-top: 0;
      }

      a.add-to-cart-btn{
        @media screen and (max-width: 599px) {
          margin-left:0px;
        }
      }
    }
    .product-detail {
      clear: both;
      padding: 20px 0;
      .displayItem-name {
        padding-right: 15px;
      }
      .product-image-wrapper {
        text-align: center;
        .product-image {
          width: 100%;
        }
      }
      .divider-line {
        padding-left: 10px;
        border-left: solid 1px #ccc;
      }
    }
    .product-code-input {
      width: 105px;
      margin-right: 25px;
    }
    .quantity-input {
      width: 50px;
      margin-right: 15px;
    }

    .warning-message-wrapper {
    // position: relative;
    padding-left: 45px;
    font-size: 14px;
    line-height: 1.14;
    color: #333333;
    text-align: left;

    &:before {
      position: absolute;
      content: '';
      top: 14px;
      left: 7px;
      background-image: url('/assets/images/attention.png');
      background-repeat: no-repeat;
      width: 27px;
      height: 25px;
      }
    }
    md-progress-circular {
      margin-top: 5px;
    }
  }

  .added-to-cart {
    background-color: #000;
    color: #fff;
    width: 100%;
    text-align: center;
    height: 50px;
    .added-to-cart-text {
      margin: auto;
    }
  }

  .probidden-to-add {
    background-color: #4399c5;
    color: #fff;
    width: 100%;
    text-align: center;
    min-height: 50px;
    padding: 8px;
    .probidden-to-add-text {
      margin: auto;
      padding: 5px 10px;
    }
    .error-icon {
      width: 18px;
    }
  }

  .md-button.add-to-cart-btn {
    background-image: url('/assets/images/bag-icon.png');
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 37px;
    border-radius: 10px;
    margin-top: 25px;

    .ng-scope {
      font-size: 15px;
      letter-spacing: 1.6px;
      color: #ffffff;
      font-weight: 300;
    }
  }

	h3 {
		margin: 0;
    font-size:14px;
	}
	md-input-container {
    font-size:14px;
    margin: 25px 0 0 0;
    height: 30px;

    &.md-input-has-value {
      label {
        font-size: 12px;
      }
    }

    .md-input {
      color: #666666;
      font-size: 18px;
      letter-spacing: 0.2px;
    }

    label {
      font-size: 12px;
      line-height: 1.3;
      color: #333333;
    }

    &.quantity-input {
      .md-input {
        color: @link-color-green;
      }
    }
	}
}
.md-row-3 {
  max-width: 33.33%;
}

email-preferences {
  .unit {
    float: left;

    &.first-unit {
      width: 49%;
    }
    &.second-unit {
      width: 49%;
      padding-left: 5%;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 959px) {
	.myAccount-wrapper {
    padding-top:0;

	    .main-content {
	      padding-top:18px;
	    }

      .primary-title {
          margin-bottom: 15px;

        	h2 {
            font-size:24px;
          }
      }

	    h2 {
	      font-size:24px;

	      span {
	        font-size:16px;
	      }
	    }

	    .settings {
	      margin-left:-15px;
	      margin-right:-15px;
	    }

		md-tabs[md-border-top] md-tabs-wrapper {
			width: 100%;
		}
	    md-tabs[md-border-top] md-tab-content {
	      margin:0 15px;
	    }
		md-pagination-wrapper {
			width: 100% !important;
	      	height:100px;
		}
		md-tabs.settings {
			md-tabs-wrapper {
				height: 100px;
			}
			md-tabs-canvas {
				height: 100px;
        border-bottom: solid 1px @primary-color;
			}
		}
    md-tabs[md-border-top] .md-tab {
      width: 100%;
    }

    .edit-account-info {
      .checkout-actions-tab ul li {
        margin: 0px auto;
        float: none;
      }
    }

	}
  .welcome-user-module {
    overflow: hidden;
    margin: 10px 0;

    .welcome-user-header {
      float: left;
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-right: 10px;
      font-size: 18px;
      line-height: 1;

      .text-smaller {
        font-size: 12px;
        font-weight: normal;
      }
      .text-medium {
        font-size: 14px;
      }

      .not-you-sign-out {
        margin-top: 10px;
      }
      .welcome-name {
        display: block;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.3;
      }
    }
  }
	.quick-order {
		width: 100%;
		float: left;
	}

  .actions-wrapper {
    background-color: #f6f6f6;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    margin-top: 15px !important;

    h3 {
      padding:15px 11px 0 11px;
      text-transform: capitalize;
      color: #999999;
    }

    .dropdown {
      float:none !important;
      width:100% !important;
      padding: 10px 10px 15px 10px;
      box-sizing: border-box;

      > div {
        float: none;

        div{

          &.dropdown-display {
            float: none;
            border: 0;
            border-bottom: 1px solid #d1d0d4;
            background-color: transparent;
            border-radius: 0;
            padding: 0 0 10px 0;

            .placeholder {
              color: @secondary-color;
            }
            &:after {
              content: '\25BC';
              color: @secondary-color;
              top: 2px;
              speak: none;
              font-size: 13px;
              -webkit-transform: scaleY(0.5) scaleX(1);
              transform: scaleY(0.5) scaleX(1);
            }
          }
          &.dropdown-list {
            top: -30px;
            .active {
              background-color: #f5f5f5;
              color: @secondary-color;
              span {
                color: @secondary-color;
              }
            }
            span {
              color: #333333;
            }
          }
        }
      }
    }
  }

  .tabs-section {
    ul {
      width: 100%;
      li {
        width: 100%;
        &:last-child {
          border-bottom: solid 1px @primary-color;
        }
      }
    }

  }

  .state, .country {
    margin-top:-3px;
    padding-top:0;
  }
  .main-content {
    h2 {
      font-size: 24px;
    }
    h {
      font-size: 20px;
    }
  }
  email-preferences {
    .unit {
      float: none;

      &.first-unit {
        width: 100%;
      }
      &.second-unit {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 599px) {
  .layout-gt-xs-row {
    .md-row-3 {
      max-width: 100%;
    }
  }
  email-preferences .unit {
  flex: 1 0 auto !important;

    &:last-child {
      margin-left: 0;
    }
  }
  .quick-order .quick-order-mod .probidden-to-add{
    position: relative;
  }
  .quick-order .quick-order-mod {
    padding: 0 8px 16px 8px;
  }
}

@media (max-width: 500px) {
  .suffix {
    width:94%;
    margin-top:-3px;
    padding-top:0;
  }

  .states {
    width:50%;
  }
  .myAccount-wrapper {
    .primary-title {
      .required-wrapper {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 360px) {
  .welcome-user-module {
    .welcome-user-header {
      .welcome-name {
        font-size: 15px;
      }
      .text-smaller {
        font-size: 11px;
      }
      .text-medium {
        font-size: 12px;
      }
    }
  }
}
