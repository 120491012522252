/*
Valiable created on main.less

@font-family: 'Roboto', sans-serif;
@font-w-normal: 400;
@font-w-bold: 700;
@primary-color: #76bd23;
@secondary-color: #4399c5;
@warn-color:rgb(255,87,34);
@btn-primary-color: #ffffff;
@btn-primary-bg: #76bd23;
@btn-primary-bg-hover: #000000;
@btn-primary-radius: 0;
@btn-secondary-bg: #4399c5;
@btn-falt-color: #76bd23;
@link-color-green : #79c143;
@link-color-secondary : #4399c5;
@disabled-color: #FFFFFF;
@disabled-color-bg: #BEBEBE;
@disabled-color-dark: #E4E4E4;
@disabled-color-dark-bg: #999999;
@invalid-color: #cc3366;
*/
body,
html {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
input::-webkit-input-placeholder {
  color: #a7a7a7;
}
input::-moz-placeholder {
  color: #717171;
}
input:-ms-input-placeholder,
#input_13:-ms-input-placeholder {
  color: #a7a7a7;
}

/*sign up tab*/
.process-tab-wrapper {
  .process-tab {
    border-bottom: 1px solid #76bd23;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        text-align: center;
        border-right: 0;
        padding: 0;

        &:first-child a {
          padding-left: 0;
          margin-left: 0;
        }

        a {
          padding: 15px 0;
          margin: 0 15px;
          text-decoration: none;
          color: #cccccc;
          font-size: 18px;
          line-height: 1;
          display: block;
          text-transform: uppercase;
          pointer-events: none;
          cursor: default;

          &.active {
            color: #000000;
            border-bottom: 4px solid #76bd23;
            font-weight: 700;
          }
        }
      }
    }
  }

  &.sign-up-process-tabs-wrapper {
    .process-tab {
      ul {
        li {
          width: 261px;
        }
      }
    }
  }
}
/*tab with border bottom*/
md-tabs[md-border-bottom] md-tabs-wrapper {
  width: 554px;
  margin: 0 auto;
  border: 0;
  border-bottom: solid 1px @primary-color;
}

md-tabs[md-border-bottom] {
  .md-tab {
    width: 174px;
    padding: 0;
    border-bottom: solid 4px transparent;
    line-height: 26px;

    .ng-scope {
      padding: 10px 5px;
      display: block;
      color: #999999;
    }

    &.md-active {
      border-bottom: solid 4px @primary-color;

      .ng-scope {
        color: #000000;
      }
    }
  }
}

.welcome-user {}
/*Global Input Styling*/
md-radio-button {
  outline: 0;

  .md-label {
    color: @secondary-color;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .md-off {
    border-width: 1px;
  }

  &.md-checked .md-on {
    background-color: @secondary-color;
  }
}

.form-contrainer {
  min-height: 300px;
  border-bottom: solid 1px #333333;
  padding-bottom: 30px;
}

md-input-container {
  &.email {
    clear: both;
    width: 30%;
    display: block;
  }
  .md-input{
    &[type="search"]{
      float: left;
      width: 100%;
      height: auto;
    }
  }
}

md-select-menu {
  md-content {
    background-color: #ffffff;
  }
}

md-input-container .md-select-placeholder span {
  color: #D3D3D3;
}

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  color: #666666;
}

md-select {
  .md-select-value {
    border-color: rgba(0, 0, 0, 0.12);
  }
  &:not([disabled]):focus {
    .md-select-value {
      padding-bottom: 5px;
    }
  }
}

.md-button.md-icon-button.md-datepicker-button {
  background-color: @primary-color;
}

.md-datepicker-calendar-pane {
  background-color: #ffffff;
}

.registration-wrapper {
  md-select:not([disabled]):focus {
    .md-select-value {
      padding-bottom: 1px;
      border-bottom-width: 1px;
    }
  }
  md-select-value {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  [name="selectState"]{
    padding-right: 20px;
  }
  .content {
    border-bottom: solid 1px #333333;
    margin-bottom: 30px;

    hr {
      border-top: 1px solid #d7d7d7;
    }

    .message-wrapper {
      width: 75%;
      margin: 30px auto 60px;

      p {
        text-align: justify;
        line-height: 1.5;
      }

      li {
        text-align: left;
      }
    }
    &.signup-error{
      min-height:300px;
    }
  }

  md-input-container {
    .md-input,
    md-select-value{
      border-color: rgba(0, 0, 0, 0.22);
    }
    .md-select-placeholder span,
    .md-placeholder,
    label:not(.md-no-float):not(.md-container-ignore) {
      color: #a0a0a0;
    }
  }
  .section {
    padding-top: 44px;
  }

  a {
    color: @secondary-color;
  }

  md-tabs[md-border-bottom] md-tabs-wrapper {
    width: 100%;
    background-color: #f6f6f6;
  }

  md-tabs[md-border-bottom] .md-tab {
    font-size: 18px;
    font-weight: 500;
    width: 261px;
  }

  md-tabs[md-border-bottom] .md-tab.md-active .ng-scope {
    color: #333333;
  }

  md-tab-data,
  md-tabs-canvas {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
  }

  md-tab-content {
    padding: 30px 0 20px;
  }

  md-tabs[md-border-bottom] .md-tab .ng-scope {
    text-align: left;
  }

  .has-account {
    margin-bottom: 40px;
    cursor: pointer;
  }
  /*register input stlying*/
  md-input-container {
    margin-right: 35px;
  }

  .customer-type {
    md-radio-button {
      display: inline-block;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  md-input-container.md-input-focused label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-input-has-value label:not(.md-no-float) {
    color: #333333;
  }

  .header-title {
    border-bottom: 1px solid #000000;
    font-size: 16px;
    padding: 0 5px;

    &.no-underline {
      border-bottom: 0;
    }
  }

  .sub-title {
    margin-top: 30px;
  }

  p {
    color: #333333;
  }

  .form-step-wrapper {
    background: transparent;
    position: static;
    width: auto;
    text-align: left;
    .form-step-inner{
      border-bottom: 1px solid #000000;
      padding-bottom: 61px;
      display: block;
      button {
        min-width: 170px;
        &[disabled] {
          color: #b1b1b1;
          background-color: #E4E4E4;
        }
      }
    }
  }
  .form-contrainer{
    padding-bottom: 0;
    border-bottom:0;
  }
}

.form-step-wrapper {
  position: fixed;
  display: block;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #333333;
  text-align: center;
  z-index: 10;
  @media (max-width: 599px) {
    text-align:center;
  }
  .form-step-inner {
    display: inline-block;
    button[disabled] {
      color: @disabled-color-dark;
      background-color: @disabled-color-dark-btn-bg;
    }
  }
 }

md-input-container.md-input-invalid .md-char-counter,
md-input-container.md-input-invalid [data-ng-message],
md-input-container.md-input-invalid [ng-message],
md-input-container.md-input-invalid [x-ng-message],
md-input-container.md-input-invalid data-ng-message,
md-input-container.md-input-invalid ng-message,
md-input-container.md-input-invalid x-ng-message {
  color: @invalid-color;
}

credential-form {
  display: block;
  padding-bottom: 40px;
}

.title-for-dropzone {
  max-width: 653px;
  width: 80%;
  margin: 10px auto;
}
.dropzone {
  border: dashed 1px @secondary-color;
  text-align: center;
  padding-bottom: 40px;
  max-width: 653px;
  width: 90%;
  float: right;

  .computer-icon {
    background: url('/assets/images/icons/computer-icon.png') no-repeat 0 0;
    width: 47px;
    height: 41px;
    margin: 55px auto 0;
  }

  &.disabled,
  &.dragover {
    background-color: #f6f6f6;
    border: dashed 1px #000000;

    .computer-icon {
      background: url('/assets/images/icons/computer-icon-disabled.png') no-repeat 0 0;
      width: 47px;
      height: 41px;
      margin: 55px auto 0;
    }

    p {
      color: #999999;

      &.title {
        color: #898989;
      }
    }

    .md-button {
      background-color: @disabled-color-btn-bg;
      color: @disabled-color;
    }

    &.hover {
      .computer-icon {
        background-position: 0 0;
      }
    }
  }

  p {
    font-size: 14px;
    color: #999;
  }

  p.title {
    color: #666666;
    font-size: 18px;
  }

  .md-button {
    min-width: 140px;
  }
}

.finished {
  padding: 30px 0;

  hr {
    margin: 50px auto;
    width: 75%;
  }

  h2 {
    margin: 30px 0 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.12;
  }

  h3 {
    margin: 0 0 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.0;
  }

  h4 {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
  }
}

.circular-loading-overlay {
  background-color: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;

  .loading-wrapper {
    background-color: #ffffff;
    width: 80%;
    height: 300px;
    max-width: 800px;
    margin: 0 auto;

    .loading {
      padding: 11% 5%;
      box-sizing: border-box;

      .loading-content {
        p {
          font-size: 20px;
          color: #666666;
        }

        md-progress-circular {
          margin: 0 auto;
        }
      }
    }
  }
}
.certification-desc {
  ul{
    padding-left: 28px;
  }
}
@media (max-width: 959px) {
  .registration-wrapper {
    md-pagination-wrapper,
    md-tabs-canvas {
      height: 47px;
    }

    .section {
      padding-top: 15px;
    }
  }

  credential-form {
    position: relative;
    // left: -150px;
    // width: 300px;
  }

  .process-tab-wrapper {
    .process-tab {
      ul {
        li {
          width: 50%;
          font-size: 15px;

          a {
            font-size: 15px;
          }
        }
      }
    }

    &.sign-up-process-tabs-wrapper {
      .process-tab {
        ul {
          li {
            width: 50%;
          }
        }
      }
    }
  }
}
@media (max-width: 588px) {
  .registration-wrapper {
    md-pagination-wrapper,
    md-tabs-canvas {
      height: 47px;
    }

    md-tabs[md-border-bottom] .md-tab {
      width: 100%;
      clear: both;
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  .dropzone {
    float: none;
    width: initial;
  }
  .title-for-dropzone {
    width: 100%;
  }

  credential-form {
    position: relative;
    // left: -130px;
    // width: 260px;
  }
}
@media (max-width: 320px) {
  .process-tab-wrapper .process-tab ul li a {
    font-size: 12px;
  }
}

.md-select-menu-container md-option .md-text {
  white-space : normal;
}

@media (max-width: 599px) {
  .registration-wrapper {
    md-input-container {
      width: 100%;
      margin-right: 0;
      &.prof-info-ext {
        margin-left: 0%;
      }
    }
    .form-step-wrapper .form-step-inner button {
      min-width: 140px;
    }
    .checkout-actions-tab{
      position: static;
    }
  }
}

@media (min-width: 650px) {
  .signup-inline {
    display : flex;
  }
  #certification-section .layout-gt-xs-row{
    width : 100%;
  }
}

.form-contrainer md-input-container [type='search'].md-input{
  height : 30px;
}

#resale-message {
  font-size: 18px;
  width: 70%;
  
  p {
    font-size: 18px;
  }

  h3 {
    color: #0081D8;
    text-align: left;
    font-style: italic;
    font-weight: bold;
  }

  div {
    text-align: left;
  }

  .md-button {
    background-color: #0081D8;
    margin: 0px;
  }

  #resale-link {
    padding-top: 15px;
    a {
      color: gray;
      font-size: 12px;
    }
  }

  img {
    height: 60px;
    padding-bottom: 10px;
  }
}

#resale-header {
  p {
    color: gray;
    font-size: 12px;
    text-align: center;
  }
}

.small-gray-text {
  color: gray;
  font-size: 12px;
}

.ga-button-wrapper{
  display: inline-flex;
  outline: none;
}

#resale-confirmation-button{
  background-color: #F6921E;
  color: #ffffff;
  border-radius: 12px;
}
