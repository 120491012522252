.tooltip-container {
  background: url('/assets/images/icons/info-icon.png') no-repeat;
  width: 19px;
  height: 19px;
  display: inline-block;
  position: relative;
  vertical-align: bottom;

  &.without-icon {
    width: 100%;
    height: auto;
    background: none;
    box-sizing: border-box;
    &.tooltip-down {
      &:after {
        display: none;
      }
      .tooltip.tooltip-down {
        bottom: auto;
        left: 5%;
        width: 84%;
        top: 0;
        margin-top: 35px;
      }
    }
  }
}

.tooltip-container:hover {
  background-position: 0 -19px;
}

.ee-angularjs-tooltip {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 7px 10px;
  width: 232px;
  left: -117px;
  bottom: 27px;
  height: auto;
  color: #000;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.29);
  border: solid 1px #d7d7d7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  z-index: 110;

  p {
    margin: 0;
    font-weight: normal;
  }

  .title {
    font-weight: 600;
  }
}

.tooltip-show {
  display: inherit;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
.tooltip-down {

  .tooltip-arrow {
    top: -5px;
    left: 50%;
    margin-left: -5px;
    // border-left: 5px solid transparent;
    // border-right: 5px solid transparent;
    // border-bottom: 5px solid #fff;
    // border-style: solid;
  }
}
.tooltip-down:after {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 53%;
  width: 8px;
  height: 8px;
  background: #fff;
  border-right: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(-135deg);
  z-index: -1;
}

.tooltip-up .tooltip-arrow {
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}

.tooltip-right .tooltip-arrow {
  left: -5px;
  top: 50%;
  margin-top: -3px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #fff;
}

.tooltip-left .tooltip-arrow {
  right: -5px;
  top: 50%;
  margin-top: -3px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #222;
}
@media (max-width: 360px) {
  .tooltip.ee-angularjs-tooltip {
    width: 160px;
  }

  .tooltip-up .tooltip-arrow {
    margin-left: 23px;
  }
}

@media (max-width: 959px) {
  a.tooltip-container {
    &:hover{
    .tooltip{
        display: block;
      }
    }
  }
}

.cartBtnTooltip {
  width:30%;
  height:100%;
  display: inline-block;
}

.cartBtnTooltip .tooltip {
  text-align: left;
  left: -61px;
  bottom: 47px;
}

.cartBtnTooltip .tooltip p {
  font-size: 12px;
}

.cartBtnTooltip .tooltip h3 {
  margin-top: 2px;
  font-size: 14px;
  margin-bottom: 2px;
}
