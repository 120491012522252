
/*Emerson Element */

.emerson-elememt-container {
  h2 {
    text-align: left;
    font-size: 32px;
    font-weight: 300
  }

.element-list {
  border-top: 1px solid #ddd;

    div {
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      padding: 10px;

      &:nth-child(2n){
        border-right: 0;
      }
    }
  }
}

.elements-table {
  width: 90%;
  border-bottom: 1px solid #000;
  margin: 0 auto;
}

.elements-th {
  border:none;
  text-align: left;
  padding: 20px 0;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
  color: #626567;
  font-weight: 300;
  font-size: 35px;
}

.elements-td {
  border: none;
  text-align: left;
  padding: 15px 0;
  color: #5DADE2;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  font-weight: 500; 
  &:hover { 
    text-decoration: none; 
    cursor: pointer;
  }   
}

.elements-row-tr {
  border-bottom:1px solid #ddd;
  &:first-child { border-top:2px solid #ddd; }
}

.elements-row-tr:hover{
  background-color: #f5f5f5;
}

tr td:nth-child(4){ 
  width: 30%; 
  color: #5DADE2; 
  font-weight: 500; 
}

.update-element-bottom-div{
  border-bottom: 3px solid #ddd;
  padding: 40px 0;
}

.elements-p{
  margin-top: 0;
  margin-bottom: 10px; 
  font-size: 12px;
  font-weight: 400;
}

.elements-download-icon {
  float : right;
}

.elements-download-content {
  float : left;
  width :  85%;
}

.elements-download-table {
  display : block;
}