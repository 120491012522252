footer {
	height:auto;
	background-color: #666666;
	width: 100%;
    float: left;

	h3 {
		font-size: 16px;
		line-height: 1.1;
		font-weight: bold;
		color: #FFFFFF;
	}
	a {
		font-size: 16px;
    color: #666666;

	}
  img {
    height:28px;
    width:228px;
  }
}
.disclaimer{
  font-size: 12px;
  color: #666666;
  margin-top:12px;

}
#footer p.emerson-logo-footer{
	margin-bottom: 0px;
	margin-top: 65px;
}
footer.footer {
  text-align: center;
  padding: 30px 0;
  margin-top: 70px;
  border-top: 1px solid #E5E5E5;
}
.ee-list-unstyled {
	margin: 30px 0 30px 0;
	padding-left: 0;
	li {
		list-style: none;
		padding: 0;
		margin: 15px 0;
	}
}
.social-media-list {
	ul {
		margin: 13px 0 19px 0;
	}
	li {
		display: inline-block;
	}
}
hr {
	border-top: 1px solid #999999;
}
.ee-footer-section {
	text-align: center;
	margin: 0px 120px;
	padding: 10px 0;
	ul {
		li {
			display: inline-block;
			margin: 0px 5px 10px 5px;
			a {
				font-size: 16px;
        color:#FFFFFF;
			}
		}
	}
	p {
		color:#FFFFFF;
		font-size: 14px;
		line-height: 19px;
		margin-bottom: 25px;
	}
	span {
		color: #FFFFFF;
		line-height: 21px;
	}
	.footerLinks{
		margin: 16px 0;
		line-height: 21px;
	}
}

.social-media-list .ee-icon {
	a img{
		width: 23px;
		height: 24px;
	}
	margin: 0 9px;
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
	a {
		display: block;
    	width: 100%;
		height: 100%;	
	}
}

.social-media-list .ee-icon-blog {
	a img{
		width: 47px;
		height: 23px;
		vertical-align: bottom;
	}
	margin: 0 9px;
	opacity: 0.5;
	padding-top: 10px;

	&:hover {
		opacity: 1;
	}
	a {
		display: block;
    	width: 100%;
		height: 100%;
	}
}


/*chat*/
.ee-chat {
	position: fixed;
	bottom: 0;
	right: 30px;
  height:28px;
  width:165px;
  background:url('/assets/images/chat.png') no-repeat;
  z-index: 2;
}

#footer.padding {
	padding-bottom: 50px;
}
#ee-chat.movePos {
	bottom: 64px !important;
}

#supportkey{
  font-size: 13px;
  color: #666;
}

/*layout-sm*/
@media (min-width: 768px) and (max-width: 1023px) {
	.ee-footer-section {
		margin-right: 0px;
		margin-left: 0px;
        ul{
			li {
				a {
					font-size: 14px;
				}
			}
		}
		p {
			font-size: 13px;
			line-height: 18px;
		}
		.footerLinks{
			line-height: 28px;
		}
}
	.social-media-list {
		ul {
			margin: 15px 0 20px 0;
		}
		margin-left: -13px;
	}

  	.social-media-list .ee-icon {
		margin: 0 8px;

	  }
	  .social-media-list .ee-icon-blog {
		margin: 0 8px;

  	}
  	#footer.padding {
		padding-bottom: 90px;
	}
	#footer {
		.copyright {
			margin-bottom: 70px;
		}

	    p.emerson-logo-footer{
	    	margin: 1em 0;
	    }
	}

  .ee-chat {
  	position: fixed;
  	bottom: 0;
  	right: 30px;
    height:41px;
    width:165px;
    background:url('/assets/images/chat-mobile.png') no-repeat 100% 0;
  }  

}

@media (min-width: 320px) and (max-width: 767px){
	.ee-footer-section {
		margin-right: 0px;
		margin-left: 0px;
        ul{
			li {
				a {
					font-size: 14px;
				}
			}
		}
		.footerLinks{
			margin: 0 0 27px 0;
			line-height: 28px;
		}
		p {
			font-size: 13px;
			line-height: 18px;
		}
	}
	.social-media-list{
		ul {
			margin: 15px 0 28px 0;
		}
	}
	.social-media-list .ee-icon {
		margin: 0 8px;
		a img{
			width: 21px;
			height: 22px;
		}

	}
	.social-media-list .ee-icon-blog {
		margin: 0 8px;
		a img{
			width: 44px;
			height: 21px;
		}
	}
}