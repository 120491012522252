.address_val_modal .btn-primary{
  background-color: #79c143 !important;
  border: 1px solid #79c143 !important;
  color: #fff !important;
  outline: none;
}
.address_val_modal .btn-primary.skip_btn{
  background-color: #fff !important;
  color: #79c143 !important;
  border: 1px solid #79c143 !important;
 ;
  outline: none;
}
.address_val_modal .radio input[type=radio]:checked + .radio-label:before {
  background-color: #0063a5 !important;
  box-shadow: inset 0 0 0 4px #fff !important;
}
.address_val_modal .radio input[type=radio] + .radio-label:before {
  border: 1px solid #000000 !important;
}

.address_val_modal .flexrow {
  margin-left: 0px !important;
}

.express-checkout-wrapper {
	background-color: #f2f2f2;
	padding: 18px 0;
  margin-bottom: 10px;

  .vertical-divider {
    border-right: 1px solid rgba(0,0,0,.09);
    height: 88px;
    margin-right: 20px;
    padding-left: 20px;
  }

	.md-button {
		min-width: 240px;
    @media (max-width: 959px) {
      min-width: 160px;
      span {
        max-width: 80%;
        display: inline-block;
        word-wrap: break-word;
      }
    }
	}

	h2,
	p {
    font-size: 14px;
		line-height: 1.3;
		color: rgba(0,0,0,.67);
		font-weight: 500;
	}
  p {
    margin: 0;
    font-weight: normal;

    &.text-capitalize {
      text-transform: capitalize;
    }

    a {
      font-weight: 500;
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: left;
  }

	.uppercase {
		text-transform: uppercase;
	}

  .default-settings-section {
    height: auto;
    -webkit-align-self : flex-start;
    align-self: flex-start;
  }

	.default-setting {
		color: #000000;
		font-size: 15px;
		font-weight: 500;
		line-height: 1.6;
		margin-bottom: 5px;
	}
  md-input-container {
    padding: 0 12px 0 0;
		margin-top: 14px;
    md-select {
      md-select-value {
        span {
          font-size: 14px;
        	line-height: 2.0;
        	letter-spacing: normal;
        	text-align: left;
        	color: #4399c5;
        }
      }
    }
  }

  .shipping-address {
    margin-top: 0;
    word-wrap: break-word;
  }

  @media (max-width: 600px) {
    .default-settings-section {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .express-checkout-wrapper {
    margin-top: 10px;
 }
}

// remove this rule to see default settings for express checkout on mobile devices
//@media only screen and (max-width: 960px)  {
//	.express-checkout-wrapper {
//		display: none;
//	}
//}


