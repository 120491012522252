.progress-spinner {
  @offset: 180;
  @duration: 1.4s;

  display: flex;
  flex-flow: column;
  align-items: center;

  .progress-spinner-message-wrapper {
    p {
      margin: 5px 0;
    }
  }

  .spinner {
    animation: rotator @duration linear infinite;
    width: 45px;
    height: 45px;
  }


  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

// Spinner for IE
  @keyframes ie-rotator {
    0% {
      transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }

  .path {
    stroke-dasharray: @offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    animation: dash @duration ease-in-out infinite,
    colors (@duration*4) ease-in-out infinite;
    -webkit-animation: dash @duration ease-in-out infinite,
    colors (@duration*4) ease-in-out infinite;
  }
// Replace Animation on IE 11
  @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .spinner{
      animation: ie-rotator @duration linear infinite;
    }
    *::-ms-backdrop, .path {
      stroke-dasharray: 180%;
    }
  }

  @keyframes colors {
    0% {
      stroke: #4399c5;
    }
    100% {
      stroke: #4399c5;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: @offset;

    }
    50% {
      stroke-dashoffset: @offset/4;
      transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: @offset;
      transform: rotate(450deg);
      -ms-transform: rotate(450deg);
      -webkit-transform: rotate(450deg);
    }
  }
}

.progress-spinner-icon {
  .spinner {
    height: 25px;
    width: 25px;
  }
}

.progress-spinner-btn {
  .spinner {
    height: 15px;
    width: 15px;
  }
}

.quick-order-spinner {
  .spinner{
    height: 30px;
    width: 30px;
  }
}

#index-spinner {
  .spinner {
    height: 96px;
    width: 96px;
  }
}

.loading-overlay {
  .progress-spinner {
    display: block;
  }
}
