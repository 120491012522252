.add-to-cart-quantity-button {
  max-width: none;
  color: #ffffff;
  position: static;
  background-color: @btn-primary-bg;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  a.add-cart {
    background-image: url(/assets/images/add-to-cart-icon.png);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 100%;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    height: 30px;
    float: right;
    margin-left: -10px;
  }
  .quantity {
    width: 63px;
  }
  .quantity.dropdown {
    div {
      .dropdown-list {
        width: 158px;
      }
    }
  }
  .quantity-adjustment-wrapper {
    .quantity-adjustment {
      margin: 0 auto;
    }
  }
}

.cart-and-price{
  padding-top: 10px;
  border-top: 1px solid #e2e2e2;
  position: relative;
}
.product-price {
    font-size: 21px;
    color: #666;
    font-weight: 500;
}
.product-select-item {
  box-sizing: border-box;
  width:100%;
  color: #56aa31;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  font-size: 13px;
  padding: 2px 20px 2px 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background:transparent url('/assets/images/view-more-arrow.png') no-repeat right 7px center;
  &:focus{
    outline: none;
  }
  &.disabled{
    background:none;
  }
}
.backorder-text{
  color: #999999;
}

.backorder-link{
  color: #0e98df !important;
  cursor: pointer;
  font-size: 15px;
}

select.product-select-item::-ms-expand {
    display: none;
}
md-input-container.product-card-qty{
  margin: 0;
  line-height: normal;
  position: static;
  input {
    background-color: #fff;
    color: #000;
    padding: 0 1px;
    border-radius: 5px;
    height: 26px;
    border: 1px solid #68a81f;
    margin-right: 5px;
    width: 32px;
    text-align: center;
    font-size: 14px;
    -moz-appearance:textfield;
  }
  input[disabled] {
    background-color: inherit;
    color: #fff;
    padding: 0 4px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
  }
}
.purchases {
  .md-input-messages-animation {
    padding: 0;
    position: absolute;
    z-index: 2;
    overflow: visible;
    top: 100%;
    width: 100%;
    left: 0;
    >div{
      background: #fff;
    }
  }
}
.multiple-of-msg {
    background: #fff;
    color: #333;
    z-index: 2;
    width: 100%;
    margin-top: 15px;
    left: 0;
    line-height: normal;
    position: absolute;
    top: 100%;
}
product-card {
  .multiple-of-msg{
    box-shadow: 0 0 3px #adadad;
    padding: 10px;
  }
}

.out-of-stock {
  margin-left: 25px;
  margin-top: 11px;
  position: relative;
}

.out-of-stock-message{
  p{
    margin:0;
  }
}

.product-select-item span.md-select-icon,
.display-sd {
  display:none;
}

.quick-view-product-select-item{
  width: 40%;
  color: #56aa31;
  border: 1px solid #b7b7b7;
  border-radius: 18px;
  font-size: 13px;
  padding: 2px 20px 2px 7px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background:transparent url('/assets/images/view-more-arrow.png') no-repeat right 7px center;
  &:focus{
    outline: none;
  }
  &.disabled{
    background:none;
  }
}

.quick-view-product-select-item span.md-select-icon,
.display-sd {
  display:none;
}

md-select.quick-view-product-select-item::-ms-expand {
  display: none;
}

.product-details {
  .csa-stock-info-btn {
    background: none;
    margin-left: 14px;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    border: 2px solid hsl(210, 60%, 70%);
    border-radius: 5px;
    padding: 5px 12px;
    min-width: 83px;
  }

  .min-height-30 {
    min-height: 30px
  }
}

.quick-view-close-btn.popup-close.text-right > md-icon {
  margin: 2px 5px 0 0;
}

//To work select in android chrome
@media (max-width: 1024px) {
  .display-sd {
    display:block;
  }
  .hide-small-device {
    display:none;
  }
  .md-select-menu-container.md-active {
      margin-top: 0px;
  }
  md-select:not([disabled]):focus .md-select-value {
    border: 0;
  }
}
.main-content .restricted-message a{
  color: #669933;
}

.youmayalsolike-container{
  .alt-product-image{
    height: 225px;
    width: 16.5em;
    padding: 49px 45px;
    .alt-show-product-detail-dialog{
      text-align: center;
      position: relative;
    .alt-quick-view-overlay {
      position: relative;
      width:239px;
      height: 35px;
      top: -25px;
      right: 33px;
      padding: 8px 69px 8px 81px;
      background-color: rgba(221, 221, 221, 0.9);
      z-index: 10;
      bottom: 0;
      p {
        line-height: 19px;
        font-family: roboto;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #767676;
        opacity: .9;
        cursor: pointer;
        bottom: 0;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        text-transform: uppercase;
        outline: none;
        }
      }
    }
  }

  .alt-product-body{
    background-color: #fafafa;
     width: 16.5em;
    flex: 1 0 auto;
  }
  .alt-brand-banner{
    padding: 26px 0 0 0;
    display: block;
    .alt-eqp-gold .tooltip-container {
      background:#fff url('/assets/images/icons/e-q-p-gold-icon-no-label.png') no-repeat 0 0;
      width: 1.438em;
      height: 22px;
      border-radius: 19px;
      object-fit: contain;
      background-size: 100%;
      margin-left: -12px;
      &:hover {
        background-position: 0 0;
      }
      .tooltip {
        height: auto;
        font-size: 12px;
      }
    }
    .alt-eqp-silver .tooltip-container {
      background:#fff url('/assets/images/icons/e-q-p-silver-icon-no-label.png') no-repeat 0 0;
      width: 1.438em;
      height: 22px;
      border-radius: 19px;
      object-fit: contain;
      background-size: 100%;
      margin-left: -12px;
      &:hover {
        background-position: 0 0;
      }
      .tooltip {
        height: auto;
        font-size: 12px;
      }
    }
    .alt-brand-name{
      font-size: 14px;
      font-family: roboto;
      line-height: 1.14;
      color: #666;
      padding-top: 5px;
    }
    ul{
      li{
        list-style-type: none;
        float: left;
        margin-right: 5px;
      }
    }
  }
  .alt-product-name {
    min-height: 75px;
    h4 {
      font-size: 22px;
      font-family: roboto;
      font-weight: 500;
      line-height: 1.14;
      color: #6e993c;
      margin-left: 27.8px;
      margin-bottom: 0;
    }
  }
  .alt-product-price{
    padding: 25px 0 0 27.8px;
    font-family: roboto;
    font-size: 20px;
    line-height: 1.2;
    color: #666;
    visibility: visible;
    min-height: 40px;
  }
  .alt-div-30{
    width:30%;
    padding: 5% 1px 17.2px 27.8px;
    box-sizing: border-box;
  }
  .alt-div-70{
    margin: 5% 6% 17.4px 25%;
    box-sizing: border-box;
  }
  .alt-increment{
    visibility: visible;
    input{
      float: left;
      width:55px;
      border: transparent;
      height:37px;
      padding: 0 5px;
    }
    .btns{
      width:10px;
      float:left;
      background-color: #707070;
      height: 37px;
      box-sizing: border-box;
      padding-right: 2px;
      a{
        width:100%;
        float: right;
        width: 16px;
        padding: 1px;
        box-sizing: border-box;
        color:#666;
        height: 10px;
        text-decoration: none;
      }

    }
  }
  .alt-btn-slot{
    visibility: visible;
    width: 100%;
    box-sizing: border-box;
    min-height: 40px;
    padding: 0 30.8px 25px 27.8px;
    .add-to-cart-button{
        width: 217.4px;
        height: 37.6px;
        text-transform: uppercase;
        outline: none;
    }
    .view-similar-btn {
        width:100%;
        color:#fff;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        padding:8px 20px;
        float: left;
        box-sizing: border-box;
        text-align: center;
        border-radius: 10px;
        height:36px;
        background-color: #76BD23;
        border: none;
    }
  }
  .alt-product-item{
    border-radius: 5px;
    color: #666;
    width: 100px;
    height: 37.6px;
    border: solid 1px #707070;
  }
.quantity-adjustment button.rectangle-quantity,
.quantity-adjustment input.quantity{
  width: 37px;
  height: 37px;
}
.product-select-item {
  background: transparent url(/assets/images/view-more-arrow-grey.png) no-repeat right 7px center !important;
}
.backorder-text{
  margin: -20px 0 0 25.3px;
  padding: 0;
}
.alt-product-price {
  float: left;
  width: 100%;
  height: 40px;
  ul {
    padding: 0;
  }
  .product-conditions li {
    list-style-type: none;
    font-size: 12px;
    margin-left: 6px;
    float: right;
  }
  .alt-product-price-pos-lt {
    float:left;
  }
  .alt-product-price-pos-rt {
    float: right;
    margin-right: 20px
  }
}
.alt-drop-down{
  clear: both;
  min-height: 65px;
}
.thermo-icon .tooltip-container {
  background: #fafafa url(/assets/images/icons/heat-sensitive.png) no-repeat 0 0;
  width: 10px;
  min-width: 10px;
  height: 27px;
  min-height: 27px;
  background-size: 100%;
  top: 0;
}
.short-shelf-life .tooltip-container {
    background: #fafafa url(/assets/images/icons/short-shell.png) no-repeat 0 0;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    background-size: 100%;
    background-position: center center;
}
.product-condition-tooltip h3,
.alt-related-products h3 {
  font-size: 1.17em;
}
.alt-related-products h3.alt-title {
  font-size: 30px;
}
.alt-brand-feature {
  margin: 8px;
  .feature-label{
    margin-right:5px;
    float: left;
    padding: 5px;
    background: #76bd23;
    color: white;
    border-radius: 3px;
    width: 75px;
    text-align: center;
    text-transform:uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.0;
    letter-spacing: 0.1px;
    text-decoration: none;
  }
}
.add-to-cart-notice {
  margin-top: -10px;
}
.padTop{
  margin-top: 25px;
  padding-top: 20px;
  text-align: center;
}

}
