hr.gray {
  border-top: solid 1px #b7b7b7;
  margin: 20px 0;
}
.md-button.md-icon-button {
  &.md-icon-not-rounded {
    border-radius: 0;
  }
  &.md-icon-feedback {
    width: 78px;
    height: 100%;
    line-height: 16px;

    .emersonicon-class {
      color: @secondary-color;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
.emersonicon.emersonicon-feedback:before {
  background-image: url('/assets/images/feedback-icon.png');
  background-position: 0 0;
  width: 29px;
  height: 29px;
}
.md-icon-button.md-button:hover,
.md-icon-button.md-button.md-open {
  .emersonicon.emersonicon-feedback:before {
    background-image: url('/assets/images/feedback-icon.png');
    background-position: 0 0;
  }
}
#feedback-modal {
  .mtn {
    margin-top: 0 !important;
  }
  md-toolbar {
    min-height: 86px;
  }
  .md-toolbar-tools {
    min-height: 50px;
    height: 87px;

    .md-toolbar {
       border-bottom: solid 1px #b7b7b7;
    }
    h2 {
      font-size: 30px;
      line-height: 1.2;
      letter-spacing: 0.3px;
      color: #333333;
      text-align: left;
      font-weight: 400;
      padding: 30px 0 20px 0;
    }
  }
  md-dialog-content {
    padding: 20px;

    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3;
      color: #333333;
    }
  }
  .form-inline {
    md-radio-button {
      float: left;
      margin-right: 20px;

      .md-label {
        color: #333333;
        font-size: 16px;
        font-weight: 300;
      }
      .md-off {
        border: solid 1px rgba(218, 218, 218, 0.6);
      }
    }
  }
  md-input-container {
    width: 100%;
  }
  .right-divider {
    border-right: solid 1px #b7b7b7;
    padding-right: 20px;
    margin-right: 20px;
  }
  md-dialog-actions {
    .md-button {
      min-width: 157px;
    }
  }
}

@media (max-width: 959px) {
  #feedback-modal {
    .md-toolbar-tools {
      h2 {
        font-size: 24px;
      }
    }
    md-dialog-content {
      p {
        font-size: 14px;
      }
    }
    .right-divider {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }
}
